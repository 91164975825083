// @flow

import type { RouteName } from '../routes'
import type { Locale } from '../types/common'

export opaque type PrefixedRouteName = string

const prefixSeparator = '\u25B2'

export const prefixRouteName = (name: RouteName, lang: Locale): PrefixedRouteName => {
  if (name.indexOf(prefixSeparator) > -1) {
    throw Error('route name already contains a prefix separator')
  }
  return `${lang}${prefixSeparator}${name}`
}

export const unPrefixRouteName = (name: PrefixedRouteName | RouteName): RouteName => {
  if (name) {
    const matches = name.match(`^.+${prefixSeparator}(.*)$`)
    if (matches && matches.length > 1) {
      return ((matches[1]: any): RouteName)
    }
  }
  return ((name: any): RouteName) // asume regular route name
}
