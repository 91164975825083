import { COLORS } from '../theme/colors'

export const BADGE_COLOR_MAP = {
  grey: {
    outline: {
      color: COLORS.surface_gray_90,
      borderColor: COLORS.surface_gray_20,
      backgroundColor: 'transparent',
    },
    full: {
      color: COLORS.surface_gray_90,
      borderColor: COLORS.surface_gray_05,
      backgroundColor: COLORS.surface_gray_05,
    },
  },
  blue: {
    outline: {
      color: COLORS.primary_70,
      borderColor: COLORS.primary_25,
      backgroundColor: 'transparent',
    },
    full: {
      color: COLORS.primary_70,
      borderColor: COLORS.primary_05,
      backgroundColor: COLORS.primary_05,
    },
  },
  pink: {
    outline: {
      color: COLORS.accent_pink_90,
      borderColor: COLORS.accent_pink_25,
      backgroundColor: 'transparent',
    },
    full: {
      color: COLORS.accent_pink_90,
      borderColor: COLORS.accent_pink_00,
      backgroundColor: COLORS.accent_pink_00,
    },
  },
  yellow: {
    outline: {
      color: COLORS.accent_yellow_90,
      borderColor: COLORS.accent_yellow_25,
      backgroundColor: 'transparent',
    },
    full: {
      color: COLORS.accent_yellow_90,
      borderColor: COLORS.accent_yellow_00,
      backgroundColor: COLORS.accent_yellow_00,
    },
  },
  green: {
    outline: {
      color: COLORS.support_success_70,
      borderColor: COLORS.support_success_25,
      backgroundColor: 'transparent',
    },
    full: {
      color: COLORS.support_success_70,
      borderColor: COLORS.support_success_00,
      backgroundColor: COLORS.support_success_00,
    },
  },
  red: {
    outline: {
      color: COLORS.support_error_70,
      borderColor: COLORS.support_error_25,
      backgroundColor: 'transparent',
    },
    full: {
      color: COLORS.support_error_70,
      borderColor: COLORS.support_error_00,
      backgroundColor: COLORS.support_error_00,
    },
  },
  orange: {
    outline: {
      color: COLORS.support_warning_90,
      borderColor: COLORS.support_warning_25,
      backgroundColor: 'transparent',
    },
    full: {
      color: COLORS.support_warning_90,
      borderColor: COLORS.support_warning_00,
      backgroundColor: COLORS.support_warning_00,
    },
  },
  purple: {
    outline: {
      color: COLORS.support_help_70,
      borderColor: COLORS.support_help_25,
      backgroundColor: 'transparent',
    },
    full: {
      color: COLORS.support_help_70,
      borderColor: COLORS.support_help_00,
      backgroundColor: COLORS.support_help_00,
    },
  },
}
