import React from 'react'
import { HBox } from '../../HBox'
import { DesktopMenuProps, LoggedInDesktopMenuProps, LoggedOutDesktopMenuProps } from '../HNavBar.types'
import { LoggedInDesktopMenu } from './LoggedInDesktopMenu'
import { LoggedOutDesktopMenu } from './LoggedOutDesktopMenu'

export const DesktopMenu: React.FC<DesktopMenuProps> = (props) => {
  const { loggedInMenuItems, userProfile, loggedOutMenuItems, loggedIn } = props

  const loggedInMenuProps: LoggedInDesktopMenuProps = {
    menuItems: loggedInMenuItems,
    userProfile,
  }

  const loggedOutMenuProps: LoggedOutDesktopMenuProps = {
    menuItems: loggedOutMenuItems,
  }
  return (
    <HBox
      flexDirection='column'
      alignItems='flex-end'
      justifyContent='center'
      xs={{
        display: 'none',
      }}
      lg={{
        display: 'flex',
        height: '100%',
      }}
    >
      {
        loggedIn
          ? (<LoggedInDesktopMenu {...loggedInMenuProps} />)
          : (<LoggedOutDesktopMenu {...loggedOutMenuProps} />)
      }
    </HBox>
  )
}
