import createAppThunk from '../createAppThunk'
import {
  SaveJobSearchPreferenceThunkParams,
  JobSearchPreferenceAPIKeys,
  SaveJobSearchPreferenceParams,
  JobSearchPreferencesValues,
  JobSearchPreferencesFetchResponse,
  JobSearchPreferenceStepAction,
} from './types'
import onboarding from '../../actions/api/clients/onboarding'
import { JobTitle } from 'user-profile-data'
import { SaveJobTitlesAPIParams } from 'onboarding-flow'
import camelCase from 'lodash/camelCase'
import { initialState } from './jobSearchPreferencesSlice'

export const getJobSearchPreferencesValues = (jobSearchPreferencesResponse: JobSearchPreferencesFetchResponse): JobSearchPreferencesValues => {
  if (!jobSearchPreferencesResponse.steps) return initialState.jobSearchPreferencesValues

  return Object.fromEntries(
    Object.entries(jobSearchPreferencesResponse.steps).map(([key, step]) => [camelCase(key), step.value])
  ) as JobSearchPreferencesValues
}

type JobTitleIds = number[]

const dataPointStepAction = (dataPointValue: JobSearchPreferencesValues[keyof JobSearchPreferencesValues] | JobTitleIds): JobSearchPreferenceStepAction => {
  if (Array.isArray(dataPointValue)) {
    const stepAction = dataPointValue.length > 0 ? 'filled' : 'cleared'
    return stepAction
  }

  // we currently can't remove a value for WorkingHours and MinimumSalary
  return 'filled'
}

/**
 * FIXME(TPRE-1478)
 * separate thunk is needed due to saveJobTitlesPreferencesThunk and saveJobSearchPreferences
 * expecting different payload structures
 */
export const saveJobTitlesPreferencesThunk = createAppThunk<boolean, { jobTitles: JobTitle[] }>(
  'jobSearchPreferences/saveJobTitles',
  (params, { getState, extra: { requestConfig }, rejectWithValue }) => {
    const userId = getState().user.user?.id

    const jobTitleIds: JobTitleIds = params.jobTitles.map(jobTitle => jobTitle.title_id)

    const stepAction = dataPointStepAction(jobTitleIds)

    const callParams: SaveJobTitlesAPIParams = {
      step_name: 'job_title',
      step_value: jobTitleIds,
      step_action: stepAction,
    }
    return onboarding.save(userId!, callParams, requestConfig).catch(rejectWithValue)
  }
)

export const saveJobSearchPreferencesThunk = createAppThunk<boolean, SaveJobSearchPreferenceThunkParams<Exclude<JobSearchPreferenceAPIKeys, 'job_title'>>>(
  'jobSearchPreferences/save',
  (params, { getState, extra: { requestConfig }, rejectWithValue }) => {
    const userId = getState().user.user?.id

    const stepAction = dataPointStepAction(params.searchPreferenceValue)

    /**
     * in the onboarding modal, each step has a status - 'filled', 'skipped' etc.
     * for our needs we need to ensure that the step is marked as either 'filled' or 'cleared' for empty data points to avoid triggering
     * certain CRM flows, please do not change this unless the endpoint gets replaced or modified at some point
     */
    const callParams: SaveJobSearchPreferenceParams<JobSearchPreferenceAPIKeys> = {
      step_name: params.searchPreferenceName,
      step_value: params.searchPreferenceValue,
      step_action: stepAction,
    }
    return onboarding.save(userId!, callParams, requestConfig).catch(rejectWithValue)
  }
)

export const getJobSearchPreferencesThunk = createAppThunk(
  'jobSearchPreferences/get',
  async (_, { getState, extra: { requestConfig }, rejectWithValue }) => {
    const userId = getState().user.user?.id
    const locale = getState().intlData.locale

    let response: JobSearchPreferencesFetchResponse | ReturnType<typeof rejectWithValue>

    try {
      response = await onboarding.get(userId!, requestConfig, locale)
    } catch (error) {
      return rejectWithValue(error)
    }

    if ('steps' in response && 'minimum_salary' in response.steps) {
      const { status, value } = response.steps.minimum_salary
      response.steps.minimum_salary.value = {
        ...value,
        status,
      }
    }

    return getJobSearchPreferencesValues(response)
  }
)
