// @flow

import isString from 'lodash/isString'

const botList: Array<string> = [
  'googlebot',
  'AdsBot',
  'bingbot',
  'YandexBot',
  'Slurp',
  'DuckDuckBot',
  'Baiduspider',
  'Exabot',
  'facebot',
  'facebookexternalhit',
  'ia_archiver',
  'Cesar Jobsuchmaschine',
]

const isBot = (userAgent: ?string) => isString(userAgent)
  ? botList.findIndex((bot) => userAgent.match(RegExp(bot, 'i')) !== null) > -1
  : false

export default isBot
