import React, { useState } from 'react'
import { IconButton } from '@mui/material'
import { HTooltip } from '../HTooltip'
import { Information16 } from '@carbon/icons-react'
import styled from '@emotion/styled'
import { HDrawer } from '../HDrawer'
import { HTypography } from '../HTypography'

export const InfoIcon = styled(Information16)`
  width: 14px;
  height: 14px;
  font-size: 20px;
  flex-shrink: 0;
  color: #000;
`

export const HApplicationCardTooltip:
React.FC<{message: string, isMobile: boolean, tooltipTestId?: string}> =
({ tooltipTestId, message, isMobile }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

  const manageMobileMenu = (): void => {
    setIsMobileMenuOpen(old => !old)
  }

  return (
    <>
      {isMobileMenuOpen &&
            <HDrawer
              open={isMobileMenuOpen}
              onOpen={manageMobileMenu}
              onClose={manageMobileMenu}
            >
              <HTypography>{message}</HTypography>
            </HDrawer>
      }
      <HTooltip disableTouchListener={isMobile} data-test-id={tooltipTestId} placement='right' title={message}>
        <IconButton onClick={isMobile ? manageMobileMenu : undefined} style={{ height: 'fit-content', padding: 0 }} >
          <InfoIcon />
        </IconButton>
      </HTooltip>
    </>
  )
}
