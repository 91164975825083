// @flow
import { Cookies } from 'react-cookie'
import getState from './getState'

// generic for all times
import app from '../contexts/app'
import user from '../contexts/user'
import job from '../contexts/job'
import consent from '../contexts/dps_consents.ts'
import gtmPreview from '../contexts/gtm_preview.ts'

// context dependant on the event with varied options
import jobFeed, { type JobFeedType } from '../contexts/job_feed'
import cpc, { type CPCType } from '../contexts/cpc'
import jobSearchResult, {
  jobResultBuilder,
  type SingleJobResultType,
  type JobSearchResultType,
} from '../contexts/job_search_result'

// context dependent on the ContextData options
import page, { type PageType } from '../contexts/page'
import jobSuggestion, { type JobSuggestionType } from '../contexts/job_suggestion'
import apply, { type ApplyType } from '../contexts/apply'
import applicationFlowVersion, { type ApplicationFlowType } from '../contexts/application_flow_version'
import screeningQuestionSubmit, { type ScreeningQuestionType } from '../contexts/screening_question'
import requestFeedbackClick, { type RequestFeedbackType } from '../contexts/request_feedback'
import authStrategy, { type AuthStrategyType } from '../contexts/auth_strategy'
import autosuggestion, { type AutoSuggestionType } from '../contexts/keyword_autosuggestion'

// generic return states
import type { ApplicationState } from '../../types/applicationState'
import type { TrackingSchema } from '../contexts/types'

export type ExtraContextParam =
  CPCType |
  JobFeedType |
  SingleJobResultType |
  JobSearchResultType |
  PageType |
  JobSuggestionType |
  ApplyType |
  ApplicationFlowType |
  ScreeningQuestionType |
  RequestFeedbackType |
  AuthStrategyType |
  AutoSuggestionType

const availableContexts = {
  cpc_context: cpc,
  job_feed_context: jobFeed,
  job_search_result_context: jobSearchResult,
  single_job_search_result_context: jobResultBuilder,
  page_context: page,
  job_suggestion_context: jobSuggestion,
  apply_context: apply,
  application_flow_version_context: applicationFlowVersion,
  screening_question_submit_context: screeningQuestionSubmit,
  request_feedback_context: requestFeedbackClick,
  auth_strategy_context: authStrategy,
  autosuggestion_context: autosuggestion,
}

// this check is needed to due locally initiallizing cookies
if (typeof window === 'undefined') {
  console.error(`${__filename} should not be imported on the server`) // eslint-disable-line no-console
  process.exit(1)
}

const cookies = new Cookies()

// TODO: `data` argument could be removed once all context values
// are directly mapable from `state`.
const buildContexts = (
  state: ApplicationState = getState(),
  pageName: ?string = null,
  extraContexts?: ExtraContextParam[]
): Array<TrackingSchema> => {
  // TODO: functions in here should have accept `state` instead of `data` and split
  // between contexts that would always be added to every page and contexts that are needed
  // only for specific pages.
  const eventContexts = [
    ...app(),
    ...user(state, cookies),
    ...consent(state),
    ...job(state),
    ...gtmPreview(),
  ]

  extraContexts?.forEach((extra: ExtraContextParam) =>
    availableContexts[extra.key](state, cookies, ({ ...extra.options, pageName }: any)).forEach(
      (builtContext: TrackingSchema) => eventContexts.push(builtContext)
    )
  )

  // TODO: this function shouldn't be necessary when the `data` argument is removed
  // all contexts should be deliberately added.
  const includedEventContexts = []
  for (let i = 0; i < eventContexts.length; i++) {
    const eventContextData = eventContexts[i].data

    for (const key in eventContextData) {
      if (
        Object.prototype.hasOwnProperty.call(eventContextData, key) &&
        eventContextData[key] !== undefined
      ) {
        includedEventContexts.push(eventContexts[i]) // include the eventContext only if it has data
        break
      }
    }
  }

  return includedEventContexts
}

export default buildContexts
