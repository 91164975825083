// @flow

import React from 'react'
import { injectIntl, type IntlShape } from 'react-intl'
import styled from 'styled-components'
import { $sizes, $colors } from './dependencies/variables'

type Props = {
  intl: IntlShape
}

const NoScriptNotificationContent = styled.div`
  text-align: center;
  padding: ${$sizes.base};
  background-color: ${$colors.primary[100]};
  color: white;
  font-weight: 500;
  font-stretch: normal;
`

const _NoScriptNotification = ({ intl }: Props) => (
  <noscript>
    <NoScriptNotificationContent>
      {intl.formatMessage({ id: 'noscript_notification' })}
    </NoScriptNotificationContent>
  </noscript>
)

const NoScriptNotification = injectIntl(_NoScriptNotification)

export default NoScriptNotification
