// @flow

import * as React from 'react'

import { Route as _Route, useRouteMatch } from 'react-router'
import { replace as reduxReplace } from 'connected-react-router'
import isFunction from 'lodash/isFunction'

import { parseQueryString } from '../../helpers/url/query'
import { routeTransition, routeDataSync } from '../../actions/actionCreators'
import { LOCATION_CHANGE } from '../../constants/actions'
import MainLayout from '../../layouts/MainLayout'

import { trackPageView } from './PageViewEvent'
import ScrollToTop from './ScrollToTop'
import { unPrefixRouteName } from '../../routing/routeNamePrefix'
import { GrowthBook } from '@growthbook/growthbook-react'

import type { Store } from 'redux'
import type { Cookies } from 'react-cookie'
import type { History } from 'history'
import type { PrefixedRouteName } from '../../routing/routeNamePrefix'
import type { RouteLocation } from '../../types/common'
import type { ApplicationState } from '../../types/applicationState'
import type { Action } from '../../store/configureStore'
import { runningInBrowser } from '../../actions/actions/support/base'
import { routeNames, getRoutesForLocale } from '../../routes'
import { userPool } from '../../actions/user/cognito'
import { resolveUser } from '../../actions/user'

type RouteOptions = {|
  component: $FlowTODO,
  country: string,
  exact?: bool,
  layout: ?React.ComponentType<any>,
  locale: string,
  name: PrefixedRouteName,
  path: string,
  routePathFromRoutesMap: string,
|}

type RouteProps = {|
  ...$Exact<RouteOptions>,
  cookies: Cookies,
  growthbook: GrowthBook,
  history: History,
  scrollTop?: bool,
  store: Store<ApplicationState, Action>
|}

export type LayoutProps = {
  location: RouteLocation
}

const ComponentWrapper = (
  Component: $FlowTODO,
  Layout: ?React$ComponentType<LayoutProps>,
  routeProps: RouteProps
) => (props) => {
  const { store, name, scrollTop } = routeProps
  const { loadData, clientLoadData } = Component
  const LayoutComponent = Layout || MainLayout
  const layoutProps: LayoutProps = { location: props.location }

  const { params } = useRouteMatch()
  const state = store.getState()
  const route = {
    name,
    routePathFromRoutesMap: routeProps.routePathFromRoutesMap,
  }

  React.useEffect(() => {
    const routeData = {
      location: {
        ...props.location,
        pathname: encodeURI(props.location.pathname),
        query: parseQueryString(props.location.search),
      },
      params,
      route,
    }
    const serverPath = state.routing.serverRoute
    const clientPath = state.routing.clientRoute
    const nextClientPath = props.location.pathname + props.location.search
    const isInitialRender = !(clientPath !== serverPath && clientPath !== null && nextClientPath !== clientPath)

    const callDataLoaders = async () => {
      store.dispatch(routeTransition({ serverRoute: null, clientRoute: nextClientPath }))
      store.dispatch(routeDataSync(routeData))

      let replaced = false
      const replace = (...args) => {
        replaced = true
        store.dispatch(reduxReplace(...args))
      }

      if (!isInitialRender) {
        store.dispatch({ type: LOCATION_CHANGE })

        if (isFunction(loadData)) {
          await loadData(
            store.dispatch,
            store.getState(),
            routeData,
            replace,
            undefined,
            routeProps.growthbook
          )

          // short-circuit if a redirect occurred
          if (replaced) { return }
        }

        if (isFunction(clientLoadData)) {
          await clientLoadData(
            store.dispatch,
            store.getState(),
            routeData,
            replace,
            props.cookies,
            routeProps.growthbook
          )

          // short-circuit if a redirect occurred
          if (replaced) { return }
        }
      }

      trackPageView({
        isInitialRender,
        location: props.location,
        routeName: unPrefixRouteName(name),
        state: store.getState(),
        dispatch: store.dispatch,
      })

      window.growthbook?.refresh()
    }

    callDataLoaders()
  }, [])

  return (
    <>
      <ScrollToTop scrollTop={scrollTop} />

      <LayoutComponent {...layoutProps}>
        <Component {...props} />
      </LayoutComponent>
    </>
  )
}

/*
  A safe guard to ensure that we resolve user if the user is not initialised but the user is logged in.
  TODO: Remove once all pages have been migrated to JSP-N
*/
const resolveUserMissingFromState = (store: Store<ApplicationState, Action>, cookies: Cookies) => {
  if (!runningInBrowser) return

  const state = store.getState()

  const shouldResolveTheUser = !!userPool.getCurrentUser() && !state.user.user

  if (shouldResolveTheUser) {
    store.dispatch(resolveUser())
  }
}

export const shouldBeOnNextJS = (props: RouteProps, hostname: string, growthbook: GrowthBook) => {
  const state = props.store.getState()
  const routeName = unPrefixRouteName(props.name)

  const isCareerPage = !hostname.startsWith('www.')
  const isSearchPage = routeName === routeNames.jobSearchKeyword

  const isHomePage = routeName === routeNames.home

  const isPartOfJDPExperiment = routeName === routeNames.jobDetails && props.cookies.get('tp_jdp_app')?.startsWith('next_app') && !state.request.isBot

  return Boolean((isSearchPage && !isCareerPage) ||
    isPartOfJDPExperiment ||
    isHomePage)
}

const Route = (props: RouteProps) => {
  const { path, component: Component, layout: Layout } = props

  if (runningInBrowser && shouldBeOnNextJS(props, window.location?.hostname)) {
    const routeName = unPrefixRouteName(props.name)

    // Remove this part after release of jobseeker-portal-proxy
    if (routeName === routeNames.home && !Component) {
      const state = props.store.getState()

      const { locale } = state.intlData
      const { country } = state.locality
      window.onbeforeunload = null
      window.location.href = getRoutesForLocale(locale).country(country).dashboard
    } else {
    // Clear potential onbeforeunlaod functions on components that haven't been unmounted
      window.onbeforeunload = null
      window.location.reload()
    }

    // explicitly return to avoid unnecessary rendering
    return null
  }

  // Remove this part after release of jobseeker-portal-proxy
  if (!Component) {
    return null
  }

  resolveUserMissingFromState(props.store, props.cookies)

  return (
    <_Route
      exact
      path={path}
      component={ComponentWrapper(Component, Layout, props)}
    />
  )
}

export default Route
