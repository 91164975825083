// @flow

import * as actions from '../constants/actions'

export type RequestState = {
  deviceType?: ?string,
  host?: ?string,
  hostWithPort?: ?string,
  isBot?: bool,
  isMobile?: bool,
  isVulnerabilityScanner?: bool,
  isWebView?: bool,
  origin: ?string,
  originAddress?: ?string,
  originCountry?: ?string,
  protocol?: ?string
}

const initialState: RequestState = {}

const reducer = (state: RequestState = initialState, action: $FlowTODO): RequestState => {
  switch (action.type) {
    case actions.SET_REQUEST_DETAILS:
      return {
        ...state,
        ...action.details,
      }
    default:
      return state
  }
}

export default reducer
