
import React from 'react'

export const CloseIcon: React.FC<{
  width?: number | string
  height?: number | string
}> = ({ width = '24', height = '24' }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21 4.05L19.95 3L12 10.95L4.05 3L3 4.05L10.95 12L3 19.95L4.05 21L12 13.05L19.95 21L21 19.95L13.05 12L21 4.05Z" fill="#4C526B"/>
  </svg>
)
