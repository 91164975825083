export const CAF_STEP_SUMMARY = 'summary'
export const CAF_STEP_FULL_NAME = 'fullName'
export const CAF_STEP_MOTIVATION = 'motivation'
export const CAF_STEP_DOCUMENT_UPLOAD = 'documentUpload'
export const CAF_STEP_CRM_CONSENT = 'crmConsent'
export const CAF_STEP_SCREENING_QUESTIONS = 'screeningQuestions'
export const CAF_STEP_RECRUITER_SCREENING_QUESTIONS = 'recruiterScreeningQuestions'
export const CAF_STEP_STANDARDISED_SCREENING_QUESTIONS = 'standardisedScreeningQuestions'
export const CAF_STEP_SUBMITTED_APPLICATION = 'submittedApplication'
export const CAF_STEP_ADD_PHONE_NUMBER = 'addPhoneNumber'
export const CAF_STEP_PENDING_CV = 'pendingCv'
export const CAF_STEP_PENDING_CV_DEADLINE_MISSED = 'pendingCvDeadlineMissed'
export const CAF_STEP_LOCATION = 'location'

export const CAF_VARIANT_JOHN = 'john'
export const CAF_VARIANT_ANNA_WINTOUR = 'annaWintour'

export const CAF_LOCAL_DATA_PREFIX = 'heyjobs_caf-'
export const MOTIVATION_LETTER_MIN_LENGTH = 120
export const UI_CAF_ANIMATION_CLASS_NAME = 'animate'

export const UI_CAF_STEP_ENTER_TRANSITION_TIME_MS = 500
export const UI_CAF_AUTO_NAVIGATION_DELAY_MS = 200
export const UI_CAF_STEP_DIRECTION_FORWARD = 'forward'
export const UI_CAF_STEP_DIRECTION_BACKWARD = 'backward'
export const UI_CAF_MAXIMUM_WIDTH = '740px'

export const NO_CV_ERROR_CODE = 'base_user_doesnt_have_a_cv'
