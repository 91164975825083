import * as actions from '../constants/actions'
import * as constants from '../constants/index'
import { PENDING, FULFILLED, REJECTED } from './promiseUtil'

const initialState = {
  job: null,
  jobLoading: true,
  similarJobs: null,
  similarJobsLoading: true,
  smartLinkout: {
    isOpen: false,
    showEmailTaken: false,
  },
  screeningQuestions: null,
  isGoogleOneTapOpen: false,
}

const reducer = (state: CurrentJobState = initialState, action: Action): CurrentJobState => {
  switch (action.type) {
    case actions.CLEAR_CURRENT_JOB:
      return {
        ...initialState,
      }
    case PENDING(actions.FETCH_CURRENT_JOB):
      return {
        ...state,
        job: null,
        screeningQuestions: null,
        jobLoading: true,
      }
    case FULFILLED(actions.FETCH_CURRENT_JOB_OPAQUELY):
    case FULFILLED(actions.FETCH_CURRENT_JOB):
      return {
        ...state,
        job: action.payload,
        jobLoading: false,
      }
    case REJECTED(actions.FETCH_CURRENT_JOB):
      return {
        ...state,
        job: null,
        jobLoading: false,
      }
    case PENDING(actions.FETCH_CURRENT_JOB_SCREENING_QUESTIONS):
      return {
        ...state,
        screeningQuestions: initialState.screeningQuestions,
      }
    case FULFILLED(actions.FETCH_CURRENT_JOB_SCREENING_QUESTIONS):
      return {
        ...state,
        screeningQuestions: action.payload,
      }
    case REJECTED(actions.FETCH_CURRENT_JOB_SCREENING_QUESTIONS):
      return {
        ...state,
        screeningQuestions: initialState.screeningQuestions,
      }
    case PENDING(actions.FETCH_SIMILAR_JOBS):
      return {
        ...state,
        similarJobs: null,
        similarJobsLoading: true,
        similarJobsLoadingError: false,
      }
    case FULFILLED(actions.FETCH_SIMILAR_JOBS):
      return {
        ...state,
        similarJobs: action.payload,
        similarJobsLoading: false,
        similarJobsLoadingError: false,
      }
    case REJECTED(actions.FETCH_SIMILAR_JOBS):
      return {
        ...state,
        similarJobs: null,
        similarJobsLoading: false,
        similarJobsLoadingError: true,
      }

    case actions.SET_CURRENT_JOB:
      return {
        ...state,
        job: action.job,
        jobLoading: false,
      }
    case constants.TOGGLE_MOBILE_SMART_LINKOUT_FORM:
      return {
        ...state,
        smartLinkout: {
          ...state.smartLinkout,
          isOpen: action.value,
        },
      }
    case constants.ON_MOBILE_SMART_LINKOUT_SUBMIT_CLICK:
      return {
        ...state,
        smartLinkout: {
          ...state.smartLinkout,
        },
      }
    case constants.ON_SMART_LINKOUT_TAKEN_EMAIL_ERROR:
      return {
        ...state,
        smartLinkout: {
          ...state.smartLinkout,
          showEmailTaken: true,
        },
      }
    case constants.GOOGLE_ONE_TAP_OPEN_STATUS:
      return {
        ...state,
        isGoogleOneTapOpen: action.googleOneTapOpenStatus,
      }
    default:
      return state
  }
}

export default reducer
