import { createSlice } from '@reduxjs/toolkit'
import { PENDING } from '../../reducers/promiseUtil'
import { LOGOUT } from '../../constants/actions.js'
import { ParsedCv, ParseError } from './types'
import { getParsedCvThunk, updateParsedCvThunk } from './parsedCvThunk'
import ApiError from '../../actions/api/errors/ApiError'

export type ParsedCvState = {
  parsedCvValues: ParsedCv | null
  isLoading: boolean
  error: ParseError | ApiError | null
}

const initialState: ParsedCvState = {
  parsedCvValues: null,
  isLoading: false,
  error: null,
}

const parsedCvSlice = createSlice({
  name: 'parsedCv',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getParsedCvThunk.fulfilled, (state, action) => {
      state.isLoading = false
      state.parsedCvValues = action.payload
    })
    builder.addCase(getParsedCvThunk.pending, (state) => {
      state.error = null
      state.isLoading = true
    })
    builder.addCase(getParsedCvThunk.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.payload as ApiError
    })

    builder.addCase(updateParsedCvThunk.fulfilled, (state, action) => {
      state.isLoading = false
      state.parsedCvValues = action.payload
      state.error = null
    })
    builder.addCase(updateParsedCvThunk.pending, (state) => {
      state.error = null
      state.isLoading = true
    })
    builder.addCase(updateParsedCvThunk.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.payload as ApiError
    })

    builder.addCase(PENDING(LOGOUT), (state) => {
      state.parsedCvValues = null
      state.isLoading = false
      state.error = null
    })
  },
})

export default parsedCvSlice.reducer
