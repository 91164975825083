// @flow

import type { TrackingSchema } from './types'
import getState from '../shared/getState'

type AssignmentDefinition = {
  group_name: string,
  name: string,
  source?: string
}

const getLegacyAssignmentDefinitions = (
  definitions,
  assignments
): Array<AssignmentDefinition> => {
  return definitions.map((definition) => {
    return {
      name: definition.experimentName,
      group_name: assignments[definition.experimentName]?.group_name,
      source: 'legacy',
    }
  })
}

const getGrowthbookAssignmentDefinitions = (
  definitions,
  assignments
): Array<AssignmentDefinition> => {
  return Object.keys(definitions.features).map((experimentName) => {
    return {
      name: experimentName,
      group_name: assignments[experimentName]?.group_name,
      source: assignments[experimentName]?.source,
    }
  })
}

export const SCHEMA_NAME = 'iglu:de.heyjobs/ab_testing_context/jsonschema/1-0-1'

export default (eventName: ?string): TrackingSchema[] => {
  const state = getState()
  const {
    assignments,
    definitions,
    growthbook: growthbookDefinitions,
  } = state.abTesting

  const assignmentDefinitions: Array<AssignmentDefinition> = [
    ...getLegacyAssignmentDefinitions(definitions, assignments),
    ...getGrowthbookAssignmentDefinitions(growthbookDefinitions, assignments),
  ].filter((ad) => !!ad.group_name)

  return assignmentDefinitions.map((ex) => ({
    schema: SCHEMA_NAME,
    data: {
      experiment_name: ex.name,
      group_name: ex.group_name,
      source: ex.source,
      // NOTE: we no longer support this
      is_conversion_event: false,
    },
  }))
}
