import * as actions from '../constants/actions'

const initialState = {
  routeName: null,
  routeNames: null,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_PAGE_LOAD_ROUTE_DATA:
      return {
        ...initialState,
        routeName: action.routeName,
        routeNames: action.routeNames,
      }
    default:
      return state
  }
}

export default reducer
