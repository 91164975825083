// @flow

import { from } from 'rxjs'

import find from 'lodash/find'
import mapValues from 'lodash/mapValues'
import zipObject from 'lodash/zipObject'
import intersection from 'lodash/intersection'
import lodashMap from 'lodash/map'
import isEmpty from 'lodash/isEmpty'

import * as API from '../../actions/api/clients'
import { textCompare } from '../../helpers/textCompare'

import {
  DEFAULT_SEARCH_PAGE_SIZE,
  MAXIMUM_SEARCH_PAGE,
} from '../../constants/base'
import { getPublicationData } from '../../actions/utils/jobPublicationData'

import transformToSearchAPIOptions from './transformToSearchAPIOptions'

import type { Observable } from 'rxjs'
import type { APIRequestConfig } from '../../actions/api/types'
import type {
  JobSearchRequestOptions,
  JobSearchResponse,
  HistogramResult,
} from '../../actions/api/clients/search'
import type { JobSearchActionOptions, SearchState } from './types'
import type { ApplicationState } from '../../types/applicationState'

type HistogramQuery = 'count(employment_type)' |
  'count(string_custom_attribute["employment_types"])' |
  'count(company_display_name)' |
  'count(string_custom_attribute["allow_easy_apply"])' |
  'count(base_compensation, [bucket(0, MAX)])' | 'count(company)'
type FilterValuePath = 'histogram' | 'translated_values'

const filtersValuePaths = {
  companiesFilters: 'histogram',
  allowEasyApplyFilters: 'histogram',
  baseCompensationFilters: 'histogram',
  employmentTypesFilters: 'translated_values',
  workingHoursFilters: 'translated_values',
}

const WORKING_HOURS_FILTER_QUERY = 'count(employment_type)'
const EMPLOYMENT_TYPES_FILTER_QUERY = 'count(string_custom_attribute["employment_types"])'
const COMPANIES_FILTER_QUERY = 'count(company_display_name)'
const ALLOW_EASY_APPLY_QUERY = 'count(string_custom_attribute["allow_easy_apply"])'
const BASE_COMPENSATION_QUERY = 'count(base_compensation, [bucket(0, MAX)])'
const COMPANIES_COUNT_FILTER_QUERY = 'count(company)'

const filterExtractor = (histogramQuery: HistogramQuery): Function =>
  (histogramResults: ?HistogramResult[]) =>
    histogramResults
      ? histogramResults.find(
        (t) => t.histogram_query === histogramQuery
      )
      : []

const getWorkingHoursFilters = filterExtractor(WORKING_HOURS_FILTER_QUERY)
const getEmploymentTypesFilters = filterExtractor(EMPLOYMENT_TYPES_FILTER_QUERY)
const getCompaniesFilters = filterExtractor(COMPANIES_FILTER_QUERY)
const getAllowEasyApplyFilters = filterExtractor(ALLOW_EASY_APPLY_QUERY)
const getBaseCompensation = filterExtractor(BASE_COMPENSATION_QUERY)

const filterEntries = (path: FilterValuePath, filters: $FlowTODO) =>
  !isEmpty(filters) ? Object.entries(filters[path]) : []

const filterSorter = (locale: string) => (path: FilterValuePath, filters: $FlowTODO) =>
  filterEntries(path, filters).sort(textCompare(locale))

const findHistogram = (histograms: ?HistogramResult[], queryValue: HistogramQuery) => find(histograms, {
  histogram_query: queryValue,
})

const filterSelected = (
  histograms: ?HistogramResult[],
  queryValue: HistogramQuery,
  keyPath: FilterValuePath,
  selectedFilters: string[]
) => {
  if (histograms) {
    const histogram = findHistogram(histograms, queryValue)

    const values = (histogram && histogram[keyPath]) || {}
    return histograms
      ? intersection(
        Object.keys(values),
        selectedFilters
      )
      : []
  }
  return []
}

const fetchFilterState = async (
  options: JobSearchRequestOptions,
  requestConfig: APIRequestConfig,
  state: ApplicationState
) => {
  const response = await API.search.search(options, requestConfig).toPromise()
  const histogramResults = response.histogram_query_results
  const localisedSortFilter = filterSorter(state.intlData.locale)
  const workingHoursFilters = localisedSortFilter(
    filtersValuePaths.workingHoursFilters,
    getWorkingHoursFilters(histogramResults)
  )
  const employmentTypesFilters = localisedSortFilter(
    filtersValuePaths.employmentTypesFilters,
    getEmploymentTypesFilters(histogramResults)
  )
  const companiesFilters = localisedSortFilter(
    filtersValuePaths.companiesFilters,
    getCompaniesFilters(histogramResults)
  )

  return {
    workingHoursFilters,
    employmentTypesFilters,
    companiesFilters,
  }
}

const _resolveAndBuildSearchState =
  (jobSearchConfig) => async (requestConfig: APIRequestConfig, state: ApplicationState) => {
    const histogramResults = jobSearchConfig.histogram_query_results
    const {
      companies,
      workingHours,
      employmentTypes,
      easyApply,
      salary,
    } = jobSearchConfig.searchOptions

    // We need to allow only valid search params
    const validCompanies = filterSelected(
      histogramResults,
      COMPANIES_FILTER_QUERY,
      filtersValuePaths.companiesFilters,
      companies
    )
    const validWorkingHours = filterSelected(
      histogramResults,
      WORKING_HOURS_FILTER_QUERY,
      filtersValuePaths.workingHoursFilters,
      workingHours
    )
    const validEmploymentTypes = filterSelected(
      histogramResults,
      EMPLOYMENT_TYPES_FILTER_QUERY,
      filtersValuePaths.employmentTypesFilters,
      employmentTypes
    )

    const hasEmploymentTypeFilters = !isEmpty(employmentTypes)
    const hasWorkingHoursFilters = !isEmpty(workingHours)
    const hasCompanyFilters = !isEmpty(companies)

    const filterRequests = {}
    if (hasEmploymentTypeFilters) {
      filterRequests.employmentTypesFilters = fetchFilterState({
        ...transformToSearchAPIOptions(jobSearchConfig.searchOptions),
        employment_types: [],
      }, requestConfig, state)
    }
    if (hasWorkingHoursFilters) {
      filterRequests.workingHoursFilters = fetchFilterState({
        ...transformToSearchAPIOptions(jobSearchConfig.searchOptions),
        working_hours_types: [],
      }, requestConfig, state)
    }
    if (hasCompanyFilters) {
      filterRequests.companiesFilters = fetchFilterState({
        ...transformToSearchAPIOptions(jobSearchConfig.searchOptions),
        company_display_names: [],
      }, requestConfig, state)
    }

    const filtersUsed = !!Object.keys(filterRequests).length || easyApply || salary

    const resolvedFilters = mapValues(zipObject(
      Object.keys(filterRequests),
      (await Promise.all(Object.values(filterRequests)))
    ), (o, k) => o[k])

    const localisedSortFilter = filterSorter(state.intlData.locale)
    const totalSize = jobSearchConfig.total_size || 0
    const totalPages = Math.min(
      Math.ceil(totalSize / DEFAULT_SEARCH_PAGE_SIZE),
      MAXIMUM_SEARCH_PAGE
    )

    const companyCount = findHistogram(histogramResults, COMPANIES_COUNT_FILTER_QUERY)?.histogram?.value

    const data = {
      filtersUsed,
      searchOptions: {
        ...jobSearchConfig.searchOptions,
        companies: validCompanies,
        workingHours: validWorkingHours,
        employmentTypes: validEmploymentTypes,
      },
      currentPage: jobSearchConfig.currentPage,
      numJobs: totalSize,
      numCompanies: companyCount || 0,
      jobs: lodashMap(jobSearchConfig.matching_jobs, (item) => ({
        ...item,
        job: {
          ...item.job,
          publicationData: getPublicationData(item.job),
        },
      })),
      requestId: jobSearchConfig.metadata.request_id,
      totalPages,
      spellCorrection: jobSearchConfig.spell_correction || {},
      ...resolvedFilters,
      error: false,
      errors: null,
    }

    if (!hasWorkingHoursFilters) {
      data.workingHoursFilters = localisedSortFilter(
        filtersValuePaths.workingHoursFilters,
        getWorkingHoursFilters(histogramResults)
      )
    }

    if (!hasEmploymentTypeFilters) {
      data.employmentTypesFilters = localisedSortFilter(
        filtersValuePaths.employmentTypesFilters,
        getEmploymentTypesFilters(histogramResults)
      )
    }

    if (!hasCompanyFilters) {
      data.companiesFilters = localisedSortFilter(
        filtersValuePaths.companiesFilters,
        getCompaniesFilters(histogramResults)
      )
    }

    data.salaryFilters = getBaseCompensation(histogramResults)?.histogram
    data.easyApplyFilters = getAllowEasyApplyFilters(histogramResults)?.histogram

    return data
  }

const resolveAndBuildSearchState =
  (requestConfig: APIRequestConfig, state: ApplicationState, options: JobSearchActionOptions, page: number) =>
    (searchAPIResponse: JobSearchResponse): Observable<SearchState> => {
      const jobSearchConfig = {
        ...searchAPIResponse,
        searchOptions: options,
        currentPage: page,
      }
      const promise = _resolveAndBuildSearchState(jobSearchConfig)(requestConfig, state)
      return from(promise)
    }

export default resolveAndBuildSearchState
