import React from 'react'
import { HBox, HDivider, HTypography } from 'talent-ui'
import { mainContentDE } from './content'
import { List, Subheading, Paragraph } from './styles'
import { TERMS_AND_CONDITIONS_VERSION, TERMS_AND_CONDITIONS_DATE } from './constants'

const TermsAndConditionsDe: React.FC = () => (
  <HBox>
    {/* Title */}
    <HTypography
      variant='h5'
      weight='semibold'
      sm={{ variant: 'h3' }}
      md={{ variant: 'h2' }}
      style={{ marginBottom: 16 }}
    >
      Allgemeine Geschäftsbedingungen für Jobsuchende (AGB)
    </HTypography>

    <HTypography
      variant='body2'
      color='surface_gray_90'
    >
      <Paragraph
        text='Bezüglich der Nutzung der HeyJobs Plattform sowie der damit zusammenhängenden Leistungen gelten die nachfolgenden Allgemeinen Geschäftsbedingungen für Jobsuchende (AGB):'
      />
      <Paragraph
        text='Hinweis im Sinne des Gleichbehandlungsgesetzes:'
      />
      <Paragraph
        text='Aus Gründen der leichteren Lesbarkeit wird im Folgenden eine geschlechtsspezifische Differenzierung, wie z.B. Nutzer/in, verzichtet. Entsprechende Begriffe gelten im Sinne der Gleichbehandlung für alle Geschlechter.'
      />
      <Paragraph
        weight='semibold'
        text={`Version ${TERMS_AND_CONDITIONS_VERSION} vom ${TERMS_AND_CONDITIONS_DATE}`}
      />

      <HDivider />

      {mainContentDE.map(({ text, component }, index) => {
        return (
          <React.Fragment key={index}>
            <Subheading text={text} />
            <List>
              {component}
            </List>
          </React.Fragment>
        )
      })}
    </HTypography>
  </HBox>
)

export default TermsAndConditionsDe
