import React from 'react'
import { AdvancedFilterIcon } from './AdvancedFilterIcon'
import { Filter, FilterProps } from './Filter'
import styled from '@emotion/styled'
import { HBox } from '../HBox'

export interface HQuickFilterProps {
  filters: FilterProps[]
  advancedFilterIconClick: () => void
  active: boolean
  disabledView?: boolean
  dataTestId?: string
  buttonLabel?: string
}

const FiltersContainer = styled.div`
  overflow: scroll;
  display: flex;
  height: ${({ theme }) => theme.spacing(8)};
  align-items: center;
  scrollbar-width: none;     /* Firefox */
  -ms-overflow-style: none;  /* IE 10+ */
  &::-webkit-scrollbar {     /* WebKit */
    display: none;
  }
`

export const HQuickFilter: React.FC<HQuickFilterProps> =
({
  filters,
  advancedFilterIconClick,
  active = false,
  dataTestId = 'quick-filter',
  disabledView = false,
  buttonLabel,
}) => {
  return (
    <HBox display='flex' alignItems='center' data-test-id={dataTestId}>
      <AdvancedFilterIcon
        data-test-id="mobile-advanced-filters-button"
        disabledView={disabledView}
        active={active}
        onClick={advancedFilterIconClick}
        ariaLabel={buttonLabel}
      />
      <FiltersContainer>
        {filters.map((filter) => (
          <Filter
            data-test-id={`quick-filter-${filter.text.toLowerCase().split(' ').join('-')}`}
            key={filter.text}
            disabled={disabledView || filter.disabled}
            onClick={filter.onClick}
            text={filter.text}
            selected={filter.selected}
          />
        )) }
      </FiltersContainer>
    </HBox>
  )
}
