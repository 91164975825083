import React, { useMemo } from 'react'
import { HBox, HNavigationFunnelTopBar } from 'talent-ui'
import { useOnboarding, useOnboardingFlow } from '../../common/use-onboarding-flow'
import { Container } from '../../common/container'
import BackStepButton from '../../common/back-step-button'
import { NoDataVariant } from './no-data-variant'
import { VariantWithData } from './variant-with-data'
import { OnBoardingSearchParams, OnboardingStep } from '../../types/flow'

const stepsConsideredInSearch: readonly (keyof OnBoardingSearchParams)[] = Object.freeze([
  'location',
  'working_hours_type',
  'employment_type',
  'job_title',
] as const)

export const maximumAmountOfDataPoints = stepsConsideredInSearch.length

const hasValue = (value: OnBoardingSearchParams[keyof OnBoardingSearchParams]) => {
  if (Array.isArray(value)) {
    return value.length > 0
  }

  return Boolean(value)
}

const VisibleVariant = () => {
  const { stepsData } = useOnboarding()

  const dataUsedInSearch: OnBoardingSearchParams = useMemo(() => ({
    location: stepsData.location?.value?.[0],
    working_hours_type: stepsData.working_hours_type?.value,
    job_title: stepsData.job_title?.value?.[0],
    employment_type: stepsData.employment_type?.value,
  }), [
    stepsData.location?.value,
    stepsData.working_hours_type?.value,
    stepsData.job_title?.value,
    stepsData.employment_type?.value,
  ])

  // Data points are the ones we exctract from steps and use in the search params.
  const dataPoints = stepsConsideredInSearch.reduce((count, key) => hasValue(dataUsedInSearch[key]) ? count + 1 : count, 0)

  if (dataPoints > 1) {
    return (
      <VariantWithData
        jobData={dataUsedInSearch}
        dataPoints={dataPoints}
      />
    )
  }

  return (
    <NoDataVariant
      messageId='onboarding_flow_not_enough_information'
      restartButtonLabel='onboarding_flow_add_more_information'
    />
  )
}

const OnboardingSuccess: OnboardingStep<Partial<{}>> = () => {
  const { intl } = useOnboarding()
  const { goBack, onStepClose } = useOnboardingFlow()

  return (
    <>
      <HNavigationFunnelTopBar
        roundelLogo
        onClick={() => onStepClose('success')}
        progress={100}
        text={intl.formatMessage({ id: 'onboarding_flow_general_header' })}
      />
      <Container>
        <HBox
          px={6}
          flex='1'
          display='flex'
          flexDirection='column'
        >
          <BackStepButton onClick={goBack} />
          <VisibleVariant />
        </HBox>
      </Container>
    </>
  )
}

export default OnboardingSuccess
