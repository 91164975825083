
import { useEffect } from 'react'
import {
  COGNITO_SIGN_IN,
  COGNITO_SIGN_UP,
  LOGOUT,
  PASSWORDLESS_SIGN_IN,
  PASSWORDLESS_SIGN_UP,
} from '../../constants'
import {
  onCognitoSignIn,
  onCognitoSignUp,
  onLogout,
  onSignIn,
  onSignUp,
} from '../../actions/user/userHooks'
import { useDispatch } from 'react-redux'
import * as Sentry from '@sentry/browser'

const CHANNEL_NAME = 'heyjobs-data'

export const BroadcastChannelListener = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    const channel = new BroadcastChannel(CHANNEL_NAME)

    channel.onmessage = (event) => {
      const { type, payload } = event.data

      switch (type) {
        case COGNITO_SIGN_IN:
          onCognitoSignIn(payload, dispatch)
          break
        case COGNITO_SIGN_UP:
          onCognitoSignUp(payload, dispatch)
          break
        case PASSWORDLESS_SIGN_IN:
          onSignIn(payload, dispatch)
          break
        case PASSWORDLESS_SIGN_UP:
          onSignUp(payload, dispatch)
          break
        case LOGOUT:
          onLogout(dispatch)
          break
        default:
          Sentry.captureMessage(`Unknown broadcast channel event type: ${type}`)
          break
      }
    }

    return () => {
      channel.close()
    }
  }, [dispatch])

  return null
}

export const postMessageToBroadcastChannel = (
  { type, payload }: { type: string, payload: unknown }
) => {
  const channel = new BroadcastChannel(CHANNEL_NAME)
  channel.postMessage({ type, payload })
}
