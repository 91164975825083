import { ApplicationState } from 'src/app/types/applicationState'
import type { TrackingSchema } from './types'

export const SCHEMA_NAME = 'iglu:de.heyjobs/dps_consents/jsonschema/1-0-0'

export default (state: ApplicationState): Array<TrackingSchema> => {
  return [{
    schema: SCHEMA_NAME,
    data: {
      stringified_JSON: JSON.stringify(state.cookieConsent.services),
    },
  }]
}
