import React from 'react'
import { HSvgIcon, HSvgIconProps } from '../HSvgIcon'

export const CareerChangeIcon: React.FC<HSvgIconProps> = (props) => {
  return (
    <HSvgIcon xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none" {...props}>
      <path d="M21.973 2.68768H13.5554C13.1856 2.68768 12.8811 2.9922 12.8811 3.36196V10.3658L11.4673 8.95196C10.9996 8.48431 10.4341 8.25592 9.77071 8.27768C8.06326 8.34293 6.43194 10.1918 6.24706 10.4093L7.28022 11.2902C7.63912 10.8769 8.87892 9.66974 9.82509 9.63711C10.0861 9.61536 10.3036 9.71324 10.4994 9.909L12.5983 12.008L9.77071 14.8356L10.7277 15.7926L12.8702 13.6502V22.0678C12.8702 22.4375 13.1747 22.7421 13.5445 22.7421H21.9621C22.3319 22.7421 22.6364 22.4375 22.6364 22.0678V3.36196C22.6364 2.9922 22.3319 2.68768 21.9621 2.68768H21.973ZM21.2987 21.3826H14.2405V13.574C15.1649 14.3244 16.5026 15.1727 17.9491 15.1727C18.2971 15.1727 18.656 15.1183 19.0149 15.0096L18.6016 13.7154C17.0138 14.2266 15.0562 12.5952 14.2514 11.7361V10.7573C14.5777 10.9313 14.9474 11.04 15.3498 11.04C16.6658 11.04 17.7316 9.97425 17.7316 8.65832C17.7316 7.34238 16.6658 6.27659 15.3498 6.27659C14.9474 6.27659 14.5777 6.38534 14.2514 6.55935V4.04712H21.3096V21.3826H21.2987ZM14.3058 8.65832C14.3058 8.09279 14.7626 7.63602 15.3281 7.63602C15.8936 7.63602 16.3504 8.09279 16.3504 8.65832C16.3504 9.22384 15.8936 9.68061 15.3281 9.68061C14.7626 9.68061 14.3058 9.22384 14.3058 8.65832Z" fill="#313545"/>
      <path d="M8.11762 14.9117L10.8365 12.1928L9.87944 11.2358L6.68206 14.4332C6.55155 14.5637 6.4863 14.7377 6.4863 14.9117C6.4863 15.0857 6.56243 15.2706 6.68206 15.3902L9.37917 18.0873C10.2383 18.9465 10.358 21.2303 10.3362 22.046L11.6956 22.0895C11.7065 21.7306 11.7718 18.555 10.3471 17.1303L8.12849 14.9117H8.11762Z" fill="#313545"/>
      <path d="M3.45204 18.0438L2.63638 19.1314C3.24541 19.5881 3.88706 19.7621 4.50696 19.7621C6.37755 19.7621 8.06324 18.1852 8.16112 18.0982L7.22583 17.1085C7.22583 17.1085 4.98548 19.1966 3.45204 18.0438Z" fill="#313545"/>
      <path d="M6.09479 7.08136L7.06271 8.04927L9.6402 5.47179L7.06271 2.88342L6.09479 3.85134L7.04096 4.78663H3.44118V6.14606H7.04096L6.09479 7.08136Z" fill="#313545"/>
    </HSvgIcon>
  )
}
