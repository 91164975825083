// @flow

import * as actions from '../constants/actions'
import { FULFILLED, REJECTED, PENDING } from './promiseUtil'
import type { GCTSJob } from '../types/gctsJob'

export type VisitedJobsState = {|
  error: bool,
  jobs: GCTSJob[],
  loading: bool,
|}

const initialState: VisitedJobsState = {
  jobs: [],
  loading: false,
  error: false,
}

const reducer = (state: VisitedJobsState = initialState, action: $FlowTODO) => {
  switch (action.type) {
    case PENDING(actions.GET_VISITED_JOBS):
      return {
        ...state,
        loading: true,
      }
    case FULFILLED(actions.GET_VISITED_JOBS): {
      const localStorageJobs = JSON.parse(window.localStorage.getItem('VISITED_JOBS'))

      const publishedJobs = action.payload.matching_jobs
        .filter(job => job.career_page_status === 'published')
        .reduce((acc, job) => { acc[job.job.requisition_id] = job; return acc }, {})

      const jobs = localStorageJobs
        .map(j => publishedJobs[j.id] ? { ...j, ...publishedJobs[j.id] } : null)
        .filter(j => !!j)

      return {
        jobs,
        loading: false,
        error: false,
      }
    }
    case REJECTED(actions.GET_VISITED_JOBS):
      return {
        ...initialState,
        error: true,
      }
    default:
      return state
  }
}

export default reducer
