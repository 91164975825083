import React from 'react'
import Loadable from 'react-loadable'
import get from 'lodash/get'
import first from 'lodash/first'
import slice from 'lodash/slice'
import truncate from 'lodash/truncate'
import compose from 'lodash/flowRight'
import { getRoutesForLocale, extractSplatFromPath } from '../../routes'
import * as searchUrl from '../../helpers/url/searchUrl'
import * as actionCreators from '../../actions/actionCreators'
import * as jobSearchActionCreators from '../../actions/search'
import config from '../../../config'
import {
  MAXIMUM_FILTER_OPTIONS,
  MAXIMUM_SEARCH_QUERY_LENGTH,
} from '../../constants/base'
import { PageLoader } from '../../components/shared/loader'
import canonicalRedirect from '../../components/shared/canonicalRedirect'
import {
  withUniversalDataLoader,
  withClientDataLoader,
  type DataLoader,
} from '../../components/containers/withDataLoader'
import { autoLogoutAndOpenLoginModal } from '../../helpers/autoLogoutAndOpenLoginModal'
import { GrowthBook } from '@growthbook/growthbook-react'
import { PENDING } from '../../reducers/promiseUtil'
import { JOB_SEARCH } from '../../constants'
import { GCTSTracking } from '../../actions/search'

import type { RouteData, Locale, Country } from '../../types/common'
import type { Dispatch } from '../../store/configureStore'
import type { ApplicationState } from '../../types/applicationState'
import type { JobSearchOptions } from '../../actions/search/index'

export const isInvalidQueryLength = (keyword: ?string, location: ?string) => {
  const locationLength = (keyword && keyword.length) || 0
  const keywordLength = (location && location.length) || 0
  const isInvalidKeyword = keywordLength > MAXIMUM_SEARCH_QUERY_LENGTH
  const isInvalidLocation = locationLength > MAXIMUM_SEARCH_QUERY_LENGTH

  return isInvalidKeyword || isInvalidLocation
}

export const isInvalidCount = (count: number) => count > MAXIMUM_FILTER_OPTIONS

export const tryParseOrRedirect = (
  routeData: RouteData,
  state: ApplicationState,
  replace: Function
) => {
  try {
    const extractSplat = extractSplatFromPath(
      state.intlData.locale,
      state.locality.country
    )
    const searchSplat = extractSplat(routeData.location.pathname)

    return searchUrl.parse(
      searchSplat,
      routeData.location.query,
      state.intlData.locale,
      state.locality.country,
      state.intlData?.messages.home_page_search_box_radius_none
    )
  } catch (e) {
    const company = state.company.current
    const country = company?.country_code.toLowerCase() || state.locality.country // eslint-disable-line camelcase
    const locale = state.intlData.locale

    replace(getRoutesForLocale(locale).country(country).index)

    return null
  }
}

const truncateSearchText = (text?: string) =>
  truncate(text, { length: MAXIMUM_SEARCH_QUERY_LENGTH, omission: '' })

const redirectToCorrectLocale = (
  replace: Function,
  searchOptions: JobSearchOptions,
  locale: Locale,
  country: Country,
  hasCompany: bool
) => replace(searchUrl.build(searchOptions, locale, country, hasCompany))

export const redirectToAllowedFilters = (
  replace,
  searchOptions,
  locale,
  country,
  hasCompany
) => {
  const allowedSearchOptions = {
    searchOptions,
    companies: slice(searchOptions.companies, 0, MAXIMUM_FILTER_OPTIONS),
    employmentTypes: slice(
      searchOptions.employmentTypes,
      0,
      MAXIMUM_FILTER_OPTIONS
    ),
    workingHours: slice(searchOptions.workingHours, 0, MAXIMUM_FILTER_OPTIONS),
    keyword: truncateSearchText(searchOptions.keyword),
    location: truncateSearchText(searchOptions.location),
  }

  return replace(
    searchUrl.build(allowedSearchOptions, locale, country, hasCompany)
  )
}

const getCorrectPathWithOptions = ({ searchOptions, locale, country, subdomain, pathname, search, replace }) => {
  let builtSearchPath = searchUrl.build(
    searchOptions,
    locale,
    country,
    !!subdomain
  )

  if (builtSearchPath.indexOf('?') > -1) {
    builtSearchPath = builtSearchPath.split('?')[0] + search
  } else {
    builtSearchPath += search
  }

  if (decodeURI(builtSearchPath) !== decodeURI(pathname + search)) {
    return builtSearchPath
  }
  return null
}

export const dataLoader = (isClientDataLoader): DataLoader => async (
  dispatch: Dispatch,
  state: ApplicationState,
  routeData: RouteData,
  replace: Function,
  _cookies: $FlowTODO,
  growthbook: GrowthBook
) => {
  const { locale } = state.intlData
  const { country } = state.locality
  const seoOptions = {
    search_query: routeData.location.pathname,
    fallback_search_query: getRoutesForLocale(locale).country(country).index,
  }
  const allowedLocales = config.countries.locales[country]
  const isSupportedLocale = allowedLocales.includes(locale)
  const subdomain = get(state, 'company.current.subdomain')

  const company = state.company.current
  if (company && company.use_parent_company_name && company.parent) {
    const newHost = state.request.hostWithPort
      ? state.request.hostWithPort.replace(subdomain, company.parent.subdomain)
      : ''
    const newPathname = `//${newHost}${routeData.location.pathname}`
    replace({ pathname: newPathname, search: routeData.location.search })
  }

  const actions = []

  // canonical redirect
  const redirectProps = canonicalRedirect(state, routeData)
  if (redirectProps) {
    replace(redirectProps)
    return
  }

  if (state.seo.pathname !== routeData.location.pathname && !routeData.location.state?.disableSeoFetching) {
    if (subdomain) {
      seoOptions.subdomain = subdomain
    }

    actions.push(actionCreators.fetchSeoDetails(seoOptions))
  }

  let searchOptions = tryParseOrRedirect(routeData, state, replace)
  if (!searchOptions) return

  const {
    companies,
    employmentTypes,
    workingHours,
    keyword,
    location,
  } = searchOptions

  const { pathname, search } = routeData.location

  const correctPath = getCorrectPathWithOptions({ searchOptions, locale, country, subdomain, pathname, search })
  if (correctPath) {
    replace({
      pathname: correctPath,
      state: {
        redirectCode: 302,
      },
    })
    return
  }

  const isInvalidQuery = isInvalidQueryLength(keyword, location)
  const hasMoreAllowedCompanies = isInvalidCount(companies.length)
  const hasMoreAllowedWorkingHours = isInvalidCount(workingHours.length)
  const hasMoreAllowedEmploymentTypes = isInvalidCount(employmentTypes.length)

  if (
    isInvalidQuery ||
    hasMoreAllowedCompanies ||
    hasMoreAllowedWorkingHours ||
    hasMoreAllowedEmploymentTypes
  ) {
    redirectToAllowedFilters(
      replace,
      searchOptions,
      locale,
      country,
      !!subdomain
    )
    return
  }

  if (!isSupportedLocale) {
    redirectToCorrectLocale(
      replace,
      searchOptions,
      first(allowedLocales),
      country,
      !!subdomain
    )
    return
  }

  // if we're searching for jobs via a company subdomain add that to the search params
  if (state.company.current) {
    searchOptions = {
      ...searchOptions,
      subdomain: state.company.current.subdomain,
    }
  }

  const isBot = state.request.isBot
  const searchEngineExperimentVariant = jobSearchActionCreators.getSearchEngineExperimentVariant(isBot, growthbook)

  searchOptions = {
    ...searchOptions,
    experimentVariant: searchEngineExperimentVariant,
  }

  actions.push(
    jobSearchActionCreators.search(searchOptions)
  )

  const runningServerside = typeof window === 'undefined'
  if (isBot) {
    // execute either on server-side or client-side (universal)
    if (!isClientDataLoader) {
      await Promise.all(actions.map(dispatch))
      if (runningServerside) {
        // instruct clients to cache for 24 hours
        dispatch(actionCreators.setCacheControl('public, max-age=86400, must-revalidate'))
      }
    }
  } else {
    // for non-bot users make search request only on client-side
    if (isClientDataLoader) {
      await Promise.all(actions.map(dispatch))
      dispatch(GCTSTracking.impressionGCTSEvent())
    } else {
      // show loader from the server request
      dispatch({
        type: PENDING(JOB_SEARCH),
        payload: { searchOptions },
      })
    }
  }

  // if we're searching for jobs via a company subdomain and if user was routed here from a email verification sign in link
  if (state.company.current) {
    if (routeData.location.query && routeData.location.query.show_crm_modal) {
      if (routeData.location.query.email_verified) {
        /* on navigation to career page after email verification -
         * full page reload is needed, at this point all state is lost. So to avoid
         * incorrect modal message appearing, we explicitly pass if the user
         * has just verified their email */
        /* Without this setTimeout, the modal was appearing, then flickering into view again when the jobSearchPresentation
         * component rendered */
        setTimeout(
          () => dispatch(actionCreators.showCrmOptionsModal(true)),
          100
        )
      } else {
        setTimeout(() => dispatch(actionCreators.showCrmOptionsModal()), 100)
      }
    }
  }

  return Promise.resolve()
}

const LoadableSearch = Loadable({
  loader: () => import('./Search'),
  loading: () => <PageLoader isSearchLoader />,
})
const SearchPage = compose(
  withUniversalDataLoader(dataLoader(false)),
  withClientDataLoader(dataLoader(true)),
  autoLogoutAndOpenLoginModal
)(LoadableSearch)

export default SearchPage
