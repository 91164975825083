// @flow
import type { Cookies } from 'react-cookie'

import buildPageViewTrackingEventData from '../events/pageview/buildPageViewTrackingEventData'

import type { TrackingSchema } from './types'
import type { ApplicationState } from '../../types/applicationState'
import { type ExtraContextParam } from '../shared/buildContexts'

type Options = {
  link_out_source?: string,
  pageName?: ?string,
  // FIXME(TPRE-1529): this is a hack to allow us to send a custom page name without updating other places
  // which have broken semantics
  pageTypeOverride?: string
}

export type PageType = {
  key: 'page_context',
  options?: Options
}

export const SCHEMA_NAME = 'iglu:de.heyjobs/page_context/jsonschema/1-0-1'

export default (state: ApplicationState, cookies: Cookies, options?: Options): Array<TrackingSchema> => {
  if (options?.pageName) {
    const pageviewData = buildPageViewTrackingEventData(
      state.pageLoadRouteData.routeName,
      state
    )
    return [{
      schema: SCHEMA_NAME,
      data: {
        page_type: options?.pageTypeOverride || options?.pageName,
        link_out_source: options?.link_out_source || pageviewData?.link_out_source || null,
      },
    }]
  }
  return []
}

const findPageContext = (extraContexts: ExtraContextParam[]) =>
  extraContexts.find(({ key }) => key === 'page_context')

export const addDefaultPageContext = (extraContexts: ExtraContextParam[]) => {
  const hasPageContext = findPageContext(extraContexts)

  if (!hasPageContext) {
    // We always want to send the page context with every event.
    return [
      ...extraContexts,
      {
        key: 'page_context',
        options: {},
      },
    ]
  }

  return extraContexts
}
