// @flow
import styled, { type StyledComponent } from 'styled-components'
import {
  $large,
  $small,
} from '../../components/shared/dependencies/variables'
import { $largerThan } from '../../components/shared/dependencies/mixins'

const FooterContentWrapper: StyledComponent<{}, *, *> = styled.aside`
  margin: 0 auto 48px;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing(0, 6)};
  gap: ${({ theme }) => theme.spacing(6, 0)};
  
  ${$largerThan($small)} {
    width: 49rem;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-bottom: 64px;
    padding: ${({ theme }) => theme.spacing(0, 12)};
  }
  
  ${({ theme }) => theme.breakpoints.up('lg')} {
    width: 65rem;
    display: flex;
    flex-direction: row;
    margin: 0 auto 72px;
    padding: 0
    align-items: stretch;
    justify-content: space-between;
  }

  ${$largerThan($large)} {
    width: 76rem;
  }
`

export default FooterContentWrapper
