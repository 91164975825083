import { CognitoAccessToken, CognitoIdToken, CognitoRefreshToken, CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js'
import { Cookies } from 'react-cookie'
import { buildCognitoUserData, localStorageUserPool, userPool } from '../actions/user/cognito'
import cookies from '../cookies'
import localStorageUtils from './localStorageUtils'
import logger from '../../logging'
import { runningInBrowser } from '../actions/actions/support/base'
import * as Sentry from '@sentry/browser'

const log = logger('cognito-helper')

export enum PasswordErrorType {
  NotAuthorized = 'NotAuthorizedException',
  InvalidPassword = 'InvalidPasswordException',
  LimitExceeded = 'LimitExceededException'
}

export const COGNITO_PREFIX = 'CognitoIdentityServiceProvider'

export type AuthenticationMethods = 'magic_link' | 'email_password' | 'social'

export const getCurrentUserAuthenticationMethod = () => {
  const user = userPool.getCurrentUser()

  if (!user) {
    return 'magic_link'
  }

  const username = user.getUsername()

  return (username.includes('google') || username.includes('facebook')) ? 'social' : 'email_password'
}

export const getRedirectPath = (path: string): string => {
  if (path.includes('/auth')) {
    return path.replace('auth', 'jobs')
  }

  return path
}

const getUserSession = async () => {
  const user = userPool.getCurrentUser()

  if (!user) {
    return null
  }

  return new Promise<CognitoUserSession>((resolve, reject) => {
    return user.getSession((error: Error | null, session: CognitoUserSession | null) => {
      if (error || !session) return reject(error)

      resolve(session)
    })
  })
}

export const getRefreshToken = async () => {
  const user = await getUserSession()

  return user?.getRefreshToken().getToken()
}

export const getIdToken = async () => {
  const user = await getUserSession()

  return user?.getIdToken().getJwtToken()
}

export const refreshSession = async () => {
  const refreshToken = await getRefreshToken()

  return new Promise<CognitoUserSession>((resolve, reject) => {
    const currentUser = userPool.getCurrentUser()

    if (!refreshToken || !currentUser) {
      reject(null)
      return
    }

    if (currentUser != null) {
      const cognitoRefreshToken = new CognitoRefreshToken({ RefreshToken: refreshToken })
      currentUser.refreshSession(cognitoRefreshToken, (error: Error | null, session: CognitoUserSession | null) => {
        if (error) {
          reject(error)
          return
        }

        if (session) {
          resolve(session)
          return
        }

        reject(null)
      })
    }
  })
}

export const getExpiryDateFromToken = (idToken: string) => {
  try {
    const IdToken = new CognitoIdToken({ IdToken: idToken })

    return new Date(IdToken.getExpiration() * 1000)
  } catch (err) {
    const currentUser = userPool.getCurrentUser()

    currentUser?.signOut()

    throw err
  }
}

export const getErrorMessageForCognito = (code: string) => {
  if (code === PasswordErrorType.NotAuthorized) {
    return 'cognito_password_is_not_correct'
  } else if (code === PasswordErrorType.InvalidPassword) {
    return 'cognito_password_invalid'
  } else if (code === PasswordErrorType.LimitExceeded) {
    return 'cognito_password_retry_limit'
  }
}

const getTokenFromLocalStorage = (token: 'refreshToken' | 'idToken' | 'accessToken') => {
  const user = localStorageUserPool.getCurrentUser()

  if (!user) {
    return null
  }
  return localStorageUtils.getItem(`${COGNITO_PREFIX}.${userPool.getClientId()}.${user.getUsername()}.${token}`)
}

const getRefreshTokenFromLocalStorage = () => {
  return getTokenFromLocalStorage('refreshToken')
}

const getIdTokenFromLocalStorage = () => {
  return getTokenFromLocalStorage('idToken')
}

const getAccessTokenFromLocalStorage = () => {
  return getTokenFromLocalStorage('accessToken')
}

const cleanTokensFromLocalStorage = () => {
  const user = localStorageUserPool.getCurrentUser()

  if (!user) {
    return null
  }

  user.signOut()
}

export const migrateLocalStorageSessionToCookies = () => {
  /* eslint-disable camelcase */
  const refresh_token = getRefreshTokenFromLocalStorage()
  const id_token = getIdTokenFromLocalStorage()
  const access_token = getAccessTokenFromLocalStorage()

  if (refresh_token && id_token && access_token) {
    const movedSession = setUserSessionWithTokens({
      refresh_token,
      id_token,
      access_token,
    })

    cleanTokensFromLocalStorage()

    return movedSession
  }
  /* eslint-enable camelcase */
}

// eslint-disable-next-line camelcase
export const setUserSessionWithTokens = (tokens: { id_token: string; refresh_token: string, access_token: string }) => {
  const IdToken = new CognitoIdToken({ IdToken: tokens.id_token })

  const userData = buildCognitoUserData({ Username: IdToken.payload['cognito:username'] })

  const cognitoUser = new CognitoUser(userData)

  const userSession = new CognitoUserSession({
    IdToken,
    AccessToken: new CognitoAccessToken({ AccessToken: tokens.access_token }),
    RefreshToken: new CognitoRefreshToken({ RefreshToken: tokens.refresh_token }),
  })

  cognitoUser.setSignInUserSession(userSession)

  return true
}

const COGNITO_COOKIES_PER_SESSION = 5

export const limitCognitoCookiesToOneSession = (cookieJar?: Cookies) => {
  try {
    if (!cookieJar) {
      log.error('Cookie jar is undefined')
      return
    }
    const cookieString = window?.document?.cookie

    const cognitoCookieCount = cookieString.split(COGNITO_PREFIX).length - 1

    if (cognitoCookieCount < COGNITO_COOKIES_PER_SESSION) {
      return
    }

    const lastAuthUser = cookieJar.get(`${COGNITO_PREFIX}.${userPool.getClientId()}.LastAuthUser`)

    Object.keys(cookieJar.getAll({ doNotParse: true }) as Record<string, string>).forEach(cookie => {
      if (!cookie.includes(COGNITO_PREFIX)) {
        return
      }

      const cookieBelongsToActiveUser = cookie.includes(`${COGNITO_PREFIX}.${userPool.getClientId()}.LastAuthUser`) || (lastAuthUser && cookie.includes(lastAuthUser))

      if (!cookieBelongsToActiveUser) {
        cookies.remove(cookieJar)(cookie, {
          domain: cookies.globalize(window.location.hostname),
        })
      }
    })
  } catch (err) {
    log.error('Error limiting cognito cookies', err instanceof Error
      ? {
          datadogContext: {
            error: err.message,
          },
        }
      : null)
  }
}

export const verifyTokenIsValid = async (cookieJar?: Cookies) => {
  try {
    if (!runningInBrowser) {
      return
    }

    if (!cookieJar) {
      log.error('Cookie jar is undefined')
      return
    }

    const session = await getUserSession().catch(err => {
      // If there is no session, we reject with null
      if (!err) return null

      throw err
    })

    if (!session) {
      return
    }

    const token = session.getIdToken()
    const tokenUsername = token.payload['cognito:username']

    const lastAuthUser = cookieJar.get(`${COGNITO_PREFIX}.${userPool.getClientId()}.LastAuthUser`)

    if (tokenUsername && lastAuthUser && tokenUsername !== lastAuthUser) {
      log.error('Found a user with a mismatched LastAuthUser and token', {
        datadogContext: {
          tokenUsername,
          lastAuthUser,
        },
      })

      Sentry.captureMessage('Found a user with a mismatched LastAuthUser and token', {
        contexts: {
          extraData: {
            tokenUsername,
            lastAuthUser,
          },
        },
      })
    }
  } catch (err) {
    log.error('Error verifying the cognito token is valid', err instanceof Error
      ? {
          datadogContext: {
            error: err.message,
          },
        }
      : null, err)
  }
}
