// @flow
import { datadogLogs } from '@datadog/browser-logs'
import config from '../config'
import type { LogLevel, LoggerFunction, LoggerInterface } from './types'

if (typeof window === 'undefined') {
  console.error(`${__filename} should not be imported on the server`) // eslint-disable-line no-console
  process.exit(1)
}

export const configureDataDogLogger = () => {
  if (config.datadog.clientToken) {
    datadogLogs.init({
      clientToken: config.datadog.clientToken,
      forwardErrorsToLogs: false,
      env: config.env,
      service: 'jobseeker-portal',
      version: config.scm.commitHash,
      sampleRate: 100,
    })

    datadogLogs.setGlobalContext({
      service: 'jobseeker-portal',
      referrer: document.referrer,
      environment: config.env,
    })
  }
}

const precedence = ['debug', 'info', 'warn', 'dataCapture', 'error']

const level = config.log.client.level
const levelIndex = precedence.indexOf(level)
const levelToConsoleMethodMap = {
  debug: 'log',
  info: 'info',
  warn: 'warn',
  dataCapture: 'info',
  error: 'error',
}

const createLogger = (ns: string, level: LogLevel): LoggerFunction => {
  if (precedence.indexOf(level) >= levelIndex) {
    level = level === 'dataCapture' ? 'info' : level

    let logger = datadogLogs.getLogger(ns)
    if (!logger) {
      logger = datadogLogs.createLogger(ns, level, 'http')
      logger.setContext({ namespace: ns })
    }

    return (message: string, ...args: any[]): void => {
      const namespacedMessage = `[${ns}]: ${message}`

      if (args.length > 0) {
        // FIXME(TAL-744): 2nd param to datadog is a context object and some callers are passing
        // unvetted `error` objects which have leaked into the logs.
        //
        // Only allow processing the remaining arguments if the first argument is an explicit
        // and the caller has verified the shape of the remaining arguments.
        if (!args[0]?.datadogContext) {
          args = []
        }
      }

      // NOTE: never log to console in production
      if (!config.etc.isProduction) {
        console[levelToConsoleMethodMap[level]](namespacedMessage, ...args) // eslint-disable-line no-console
      }

      if (config.datadog.clientToken) {
        return logger[level](namespacedMessage, ...args)
      }
    }
  }
  return () => {}
}

export default (ns: string): LoggerInterface => ({
  debug: createLogger(ns, 'debug'),
  info: createLogger(ns, 'info'),
  warn: createLogger(ns, 'warn'),
  // Alias for 'info' that takes precendence over it to send logs to datadog
  dataCapture: createLogger(ns, 'dataCapture'),
  error: createLogger(ns, 'error'),
})
