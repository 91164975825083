// @flow
import React from 'react'
import Loadable from 'react-loadable'
import compose from 'lodash/flowRight'
import { PageLoader } from '../../components/shared/loader'
import * as actionCreators from '../../actions/actionCreators'
import * as jobSearchActionCreators from '../../actions/search'
import * as jobActions from '../../actions/job'
import {
  withUniversalDataLoader,
  withClientDataLoader,
} from '../../components/containers/withDataLoader'
import { getRoutesForLocale } from '../../routes'
import logger from '../../../logging'
import { GrowthBook } from '@growthbook/growthbook-react'

import type { RouteData } from '../../types/common'
import type { Dispatch } from '../../store/configureStore'
import type { ApplicationState } from '../../types/applicationState'

const log = logger('out')

export const dataLoader = async (
  dispatch: Dispatch,
  state: ApplicationState,
  routeData: RouteData,
  replace: Function
) => {
  const jobId = routeData.params.id
  let job = state.currentJob.job

  try {
    if (!job || job.id !== jobId) {
      const result = await dispatch(jobActions.fetchCurrentJob(jobId))
      job = result.value
    }
  } catch (e) {
    // Swallow 404 errors
    if (e.res && e.res.status === 404) {
      dispatch(actionCreators.setResponseStatus(404))
    } else {
      throw e
    }
  }

  if (!job) return

  if (!job.link_out_type || !job.link_out_url) {
    const { locale } = state.intlData
    const { country } = state.locality
    return replace(getRoutesForLocale(locale).country(country).job({ id: job.id }))
  }
}

export const clientDataLoader = async (
  dispatch: Dispatch,
  state: ApplicationState,
  routeData: RouteData,
  replace: Function,
  cookies: $FlowTODO,
  growthbook: GrowthBook
) => {
  const job = state.currentJob.job
  const requestId = routeData.location?.query?.ri || state.routing?.location?.query?.ri

  if (job) {
    dispatch(jobSearchActionCreators.GCTSTracking.viewLinkOutJobGCTSEvent({
      relatedJobs: [{ id: job.id }],
      requestId,
      details: {
        jobId: routeData.params.id,
      },
    }))
  }

  if (job && state.user.isAuthenticated) {
    try {
      await dispatch(actionCreators.sendLinkOutJobEmail(job.id))
    } catch (error) {
      log.error('failed to trigger link out email', { error })
    }
  }

  // this is used to inform the redirect script all request have been sent
  window.outPageEventSent = true
}

const LoadableOut = Loadable<$FlowTODO, $FlowTODO>({
  loader: () => import('./Out'),
  loading: () => <PageLoader />,
})

const Out = compose(
  withClientDataLoader(clientDataLoader),
  withUniversalDataLoader(dataLoader)
)(LoadableOut)

export default Out
