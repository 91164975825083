import React from 'react'
import styled from '@emotion/styled'
import { Information16 } from '@carbon/icons-react'

import { JobCardButtonProps } from './HJobCard.types'
import { HBox } from '../HBox'
import { HButton } from '../HButton'
import { HTooltip } from '../HTooltip'

export const InfoIcon = styled(Information16)`
  width: 14px;
  height: 14px;
  font-size: 20px;
  flex-shrink: 0;
  color: #000;
`

export const JobCardButton: React.FC<JobCardButtonProps> = (props) => {
  const {
    tooltipContent,
    variant = 'contained',
    stickToBottom,
    ...buttonProps
  } = props
  return (
    <HBox display="flex" gap={8} data-test-id='job-card-button' marginTop={stickToBottom ? 'auto' : 'inherit'}>
      <HButton
        {...buttonProps}
        variant={variant}
        color="primary"
      />
      {tooltipContent &&
        <HTooltip placement='right' title={tooltipContent} >
          <InfoIcon />
        </HTooltip>
      }
    </HBox>
  )
}
