import React from 'react'
import { AddAlt16 } from '@carbon/icons-react'
import { HBox, HChip } from 'talent-ui'
import { popularJobTitles, PopularJobTitlesType } from './job-titles-list'
import { useTranslate } from './hooks/use-translate'
import { JobTitle } from './types/job-titles'

export const PopularJobTitles = ({ onSelectJobTitle, excludedTitles }: { onSelectJobTitle: (jobTitle: JobTitle) => void, excludedTitles: number[] }) => {
  const formatMessage = useTranslate()

  const localisePopularJobTitle = (popularJobTitle: PopularJobTitlesType): JobTitle => ({
    title_id: popularJobTitle.title_id,
    title: formatMessage(popularJobTitle.key),
  })

  const filteredPopularJobs = popularJobTitles
    .map(popularJobTitle => localisePopularJobTitle(popularJobTitle))
    .filter((popularJobTitle) => !excludedTitles.includes(popularJobTitle.title_id))

  return (
    <HBox
      display='flex'
      flexWrap='wrap'
    >
      {
        filteredPopularJobs.map(jobTitle => (
          <HBox
            key={jobTitle.title}
            mb={2}
            mr={2}
            md={{ mb: 4 }}
          >
            <HChip
              onClick={() => onSelectJobTitle(jobTitle)}
              color='blue'
              placeholder={jobTitle.title}
              variant='filled'
              icon={<AddAlt16 />}
              size='large'
              data-test-id={jobTitle.title_id}
            />
          </HBox>
        ))
      }
    </HBox>
  )
}
