// @flow
import * as actions from '../constants/actions'
import { FULFILLED, REJECTED, PENDING } from './promiseUtil'
import xor from 'lodash/xor'
import get from 'lodash/get'
import omit from 'lodash/omit'
import type { GCTSJob } from '../types/gctsJob'
import type { JobSearchOptions } from '../actions/search/index'

export type Fallback = {
  error: bool,
  jobs?: [],
  loading: bool
}

export type MobileFiltersBucket = {
  companies: string[],
  easyApply: bool,
  employmentTypes: string[],
  salary: bool,
  workingHours: string[]
}

export type MobileFiltersSectionExpanded = {
  companies: bool,
  employmentTypes: bool,
  workingHours: bool
}

export type SpellCorrection = {
  corrected: bool,
  corrected_text: string
}

type EasyApplyFilters = { false: string, true: string }

export type JobSearchState = {
  companiesFilters?: string[] | null,
  currentPage: number,
  easyApplyFilters: ?EasyApplyFilters,
  employmentTypesFilters: string[][],
  error: bool,
  fallback: Fallback,
  filtersUsed: bool,
  isRateLimited: bool,
  jobTypesForOtherLocales?: null,
  jobs: GCTSJob[],
  keywordSuggestions?: string[] | null,
  loading: bool,
  mobileFiltersBucket: MobileFiltersBucket,
  mobileFiltersSectionExpanded: MobileFiltersSectionExpanded,
  nextUrl?: null,
  numCompanies: number,
  numJobs: number,
  openMobileFilters: bool,
  openMobileSearch: bool,
  previousUrl?: null,
  requestId?: null,
  salaryFilters: Object,
  searchModified: bool,
  searchOptions: JobSearchOptions,
  spellCorrection: ?SpellCorrection,
  totalPages: number,
  workingHoursFilters: string[][],
}

const initialState: JobSearchState = {
  searchOptions: {
    page: 1,
    workingHours: [],
    employmentTypes: [],
    companies: [],
  },
  nextUrl: null,
  previousUrl: null,
  numJobs: 0,
  numCompanies: 0,
  jobs: [],
  loading: false,
  error: false,
  fallback: {
    loading: false,
    error: false,
    jobs: [],
  },
  // Keyword search
  isRateLimited: false,
  filtersUsed: false,
  jobTypesForOtherLocales: null,
  openMobileSearch: false,
  openMobileFilters: false,
  currentPage: 1,
  totalPages: 0,
  keywordSuggestions: [],
  mobileFiltersBucket: {
    workingHours: [],
    employmentTypes: [],
    companies: [],
    salary: false,
    easyApply: false,
  },
  mobileFiltersSectionExpanded: {
    workingHours: false,
    employmentTypes: false,
    companies: false,
  },
  workingHoursFilters: [],
  employmentTypesFilters: [],
  companiesFilters: [],
  requestId: null,
  spellCorrection: {},
  easyApplyFilters: null,
  salaryFilters: null,
  searchModified: false,
}

const reducer = (state: JobSearchState = initialState, action: any) => {
  switch (action.type) {
    case actions.JOB_SEARCH_MODIFY:
      return {
        ...state,
        searchModified: true,
      }
    case FULFILLED(actions.JOB_SEARCH):
      return {
        ...state,
        ...action.payload,
        isRateLimited: false,
        loading: false,
      }
    case PENDING(actions.JOB_SEARCH):
      return {
        ...state,
        ...action.payload,
        loading: true,
        error: false,
      }
    case REJECTED(actions.JOB_SEARCH):
      return {
        ...state,
        ...action.payload,
        isRateLimited: get(action.payload, 'errors[0].code') === 'search_rate_limit_reached',
        loading: false,
        error: true,
      }
    case actions.TOGGLE_MOBILE_SEARCH_VIEW:
      return {
        ...state,
        openMobileSearch: !state.openMobileSearch,
      }
    case actions.KEYWORD_SEARCH_SUGGESTIONS_TOGGLE_FILTERS_SIDEBAR:
      return {
        ...state,
        openMobileFilters: !state.openMobileFilters,
        mobileFiltersBucket: {
          ...state.mobileFiltersBucket,
          employmentTypes: state.searchOptions.employmentTypes,
          workingHours: state.searchOptions.workingHours,
        },
      }
    case actions.KEYWORD_SEARCH_MOBILE_ADD_TO_FILTERS_BUCKET:
      return {
        ...state,
        mobileFiltersBucket: {
          ...state.mobileFiltersBucket,
          [action.name]: xor(state.mobileFiltersBucket[action.name], [
            action.filters,
          ]),
        },
      }
    case actions.KEYWORD_SEARCH_MOBILE_EMPTY_FILTERS_BUCKET:
      return {
        ...state,
        mobileFiltersBucket: initialState.mobileFiltersBucket,
      }
    case actions.KEYWORD_SEARCH_TOGGLE_MOBILE_FILTERS_SECTION_EXPANDED:
      return {
        ...state,
        mobileFiltersSectionExpanded: {
          ...state.mobileFiltersSectionExpanded,
          [action.name]: !state.mobileFiltersSectionExpanded[action.name],
        },
      }
    case FULFILLED(actions.KEYWORD_SEARCH_SUGGESTIONS):
    case REJECTED(actions.KEYWORD_SEARCH_SUGGESTIONS):
      return {
        ...state,
        keywordSuggestions: [...action.payload],
      }
    case actions.KEYWORD_SEARCH_UPDATE_FILTERS:
      return {
        ...state,
        workingHoursFilters: action.filters.workingHoursFilters,
        employmentTypesFilters: action.filters.employmentTypesFilters,
        companiesFilters: action.filters.companiesFilters,
      }
    case actions.LOCATION_CHANGE: {
      return {
        ...state,
        ...omit(initialState, 'workingHoursFilters', 'employmentTypesFilters', 'companiesFilters', 'easyApplyFilters', 'salaryFilters', 'isRateLimited', 'searchModified'),
      }
    }
    default:
      return state
  }
}

export default reducer
