import React, { PropsWithChildren } from 'react'
import styled from '@emotion/styled'
import { MenuItem } from '../HNavBar.types'
import { COLORS } from '../../theme'
import { HTypography } from '../../HTypography'

const TypographyLink = styled(HTypography)`
  display: inline-flex;
  gap: 8px;
  height: 32px;
  padding: 0 16px 0 0px;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  &:hover, &:active {
    cursor: pointer;
    color: ${COLORS.primary_50} !important;
  }
`

export const LoggedInMobileMenuItem: React.FC<PropsWithChildren<MenuItem>> =
(props) => {
  const { clickAction, children, active, component = 'div', componentProps, ...others } = props
  return (
    <TypographyLink
      variant='button'
      weight='medium'
      color={active ? 'primary_50' : 'surface_gray_60'}
      onClick={clickAction}
      component={component}
      {...others}
      {...componentProps}
    >
      {children}
    </TypographyLink>
  )
}
