import styled from '@emotion/styled'
import { HBox, HBoxProps } from '../HBox'
import { COLORS, globalStyles } from '../theme'

type JobCardContainerProps = HBoxProps & {highlight?: boolean}

const ignoreProps = ['highlight', 'isUnavailable']

const shouldForwardProp = (prop: string): boolean => !ignoreProps.includes(prop)

export const JobCardContainer = styled(HBox, {
  shouldForwardProp,
})<JobCardContainerProps>`
  background-color: ${COLORS.surface_white};
  border-radius: 4px;
  box-shadow: ${globalStyles.shadow1.boxShadow};
  height: 100%;
  cursor: pointer;
  border: ${({ highlight }) => highlight ? 'none' : `1px solid ${COLORS.surface_gray_10}`};

  :hover {
    box-shadow: ${globalStyles.shadow3.boxShadow};
    border: ${({ highlight }) => highlight ? 'none' : `1px solid ${COLORS.surface_gray_20}`};

    .job-card-title {
      text-decoration: underline;
    }
  }
`

export const JobCardOpacityWrapper =
  styled(HBox, {
    shouldForwardProp,
  }) <{ isUnavailable?: boolean }>`
  opacity: ${({ isUnavailable }) => isUnavailable ? '0.4' : '1'};
`
