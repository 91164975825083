/* eslint-disable flowtype/require-types-at-top */
// @flow

// pathr - A HOC to inject localized path generation functions
import memoize from 'lodash/memoize'
import { connect, shallowEqual, useSelector } from 'react-redux'
import { getRoutesForLocale } from '../../routes'

import type { ApplicationState } from '../../types/applicationState'
import type { Locale, Country } from '../../types/common'

const generateRoutes = (locale: Locale, country: Country) =>
  getRoutesForLocale(locale).country(country)

// $FlowFixMe
export type RouteObject = ReturnType<typeof generateRoutes>

const memoizedGenerateRoutes = memoize(generateRoutes)

const pathr = connect((state: ApplicationState) => ({
  cpathr: memoizedGenerateRoutes(state.intlData?.locale, state.locality.country),
}))

export const usePathR = () => {
  const { locale, country } = useSelector((state: ApplicationState) => ({
    country: state.locality.country,
    locale: state.intlData.locale,
  }), shallowEqual)

  return memoizedGenerateRoutes(locale, country)
}

export default pathr
