import * as actions from '../constants/actions'
import { cleanOnLocationChange } from './utils/cleanOn'

export type TermsAndConditionsModalState = {
    visible: boolean
}

const initialState: TermsAndConditionsModalState = {
  visible: false,
}

const reducer = (state: TermsAndConditionsModalState = initialState, action: any) => {
  switch (action.type) {
    case actions.SHOW_TERMS_AND_CONDITIONS_MODAL:
      return {
        ...state,
        visible: true,
      }
    case actions.HIDE_TERMS_AND_CONDITIONS_MODAL:
      return {
        ...state,
        visible: false,
      }
    default:
      return state
  }
}

export default cleanOnLocationChange(reducer)
