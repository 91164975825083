import { Menu, styled, MenuProps } from '@mui/material'
import React, { PropsWithChildren } from 'react'

import { HApplicationMenuProps } from './HApplicationCard.types'
import { globalStyles } from '../theme'
import { HDrawer } from '../HDrawer'

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    {...props}
  />
))(() => ({
  '& .MuiPaper-root': {
    boxShadow: globalStyles.shadow3.boxShadow,
  },
  '& .MuiList-padding': {
    padding: '6px 16px',
  },
}))

export const HResponsiveMenu: React.FC<
PropsWithChildren<HApplicationMenuProps>> = ({
  anchorEl,
  isOpen,
  isMobile,
  children,
  handleClick,
  handleClose,
  testId = '',
}) => {
  const dataTestId = `application-card-menu-${testId}`

  if (isMobile) {
    return (
      <HDrawer
        open={isOpen}
        onOpen={handleClick}
        onClose={handleClose}
        data-test-id={dataTestId}
        slotProps={{
          backdrop: {
            // @ts-expect-error - data-test-id is not in the type definitions.
            'data-test-id': dataTestId + '-backdrop',
          },
        }}
      >
        <>{children}</>
      </HDrawer>
    )
  }

  return (
    <StyledMenu
      anchorEl={anchorEl}
      open={isOpen}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      data-test-id={dataTestId}
      slotProps={{
        backdrop: {
          // @ts-expect-error - data-test-id is not in the type definitions.
          'data-test-id': dataTestId + '-backdrop',
        },
      }}
    >
      {children}
    </StyledMenu>
  )
}
