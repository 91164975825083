import * as React from 'react'
import clsx from 'clsx'
import styled from '@emotion/styled'
import { COLORS, globalStyles } from '../theme'
import { Close16 } from '@carbon/icons-react'
import { SnackbarProps } from './HSnackbar.types'
import { HIcon } from '../HIcon'
import { HBox } from '../HBox'
import { HTypography } from '../HTypography'
import { HButton } from '../HButton'

const COLOR_MAP: any = {
  default: {
    border: COLORS.primary_30,
    background: COLORS.primary_00,
    text: 'primary_70',
  },
  neutral: {
    border: COLORS.surface_gray_40,
    background: COLORS.surface_gray_00,
    text: 'surface_gray_80',
  },
  success: {
    border: COLORS.support_success_30,
    background: COLORS.support_success_00,
    text: 'support_success_70',
  },
  error: {
    border: COLORS.support_error_30,
    background: COLORS.support_error_00,
    text: 'support_error_70',
  },
  warning: {
    border: COLORS.support_warning_30,
    background: COLORS.support_warning_00,
    text: 'support_warning_70',
  },
  help: {
    border: COLORS.support_help_30,
    background: COLORS.support_help_00,
    text: 'support_help_70',
  },
}

const IconContainer = styled.div`
    height: 22px;
    display: flex;
    align-items: center;
  `

/**
 * @prop caption - title text
 * @prop text - body text
 */
const Snackbar = React.forwardRef((props: SnackbarProps, ref: any) => {
  const {
    className,
    icon,
    color = 'default',
    actionBottom,
    actionBottomProps,
    text,
    caption,
    onCloseClick,
    closeButtonLabel,
    ...others
  } = props

  const actionBottomAIcon = actionBottom && icon

  return (
    <div className={clsx(className, color)} {...others} ref={ref}>
      <HBox
        pl={actionBottomAIcon ? 3 : actionBottom ? 6 : 3}
        pr={3}
        pt={3}
        pb={actionBottom ? 2 : 3}
      >
        <HBox
          display='flex'
          alignItems={caption ? 'flex-start' : 'center'}
          justifyContent='space-between'
        >
          <HBox display='flex' alignItems={caption ? 'flex-start' : 'center'}>
            {icon && (
              <IconContainer>
                <HBox mr={2}>
                  <HIcon
                    Icon={icon}
                    color={COLOR_MAP[color].text}
                    size={20}
                  ></HIcon>
                </HBox>
              </IconContainer>
            )}
            <HBox display='flex' flexDirection='column' gap={8}>
              {caption && (
                <HTypography
                  variant={text ? 'body1' : 'body2'}
                  weight='regular'
                  color={COLOR_MAP[color].text}
                >
                  {caption}
                </HTypography>
              )}
              {text && (
                <HTypography
                  variant='body2'
                  weight='regular'
                  color={COLOR_MAP[color].text}
                >
                  {text}
                </HTypography>
              )}
            </HBox>
          </HBox>

          <HBox ml={2}>
            <HIcon
              role="button"
              onClick={onCloseClick}
              className='btn-close'
              Icon={Close16}
              size={20}
              color={COLOR_MAP[color].text}
              aria-label={closeButtonLabel || 'Close the snackbar'}
              data-test-id="snackbar-close-button"
            />
          </HBox>
        </HBox>
        <div>
          {actionBottom && (
            <HButton space={{ ml: icon ? 5 : -2, mt: 2 }} variant='text' color='primary' {...actionBottomProps}>
              {actionBottom}
            </HButton>
          )}
        </div>
      </HBox>
    </div>
  )
})

Snackbar.displayName = 'Snackbar'

export const HSnackbar = styled(Snackbar)`
  border-left: 4px solid ${({ color = 'default' }) => COLOR_MAP[color].border};
  background-color: ${({ color = 'default' }) => COLOR_MAP[color].background};
  box-shadow: ${globalStyles.shadow4.boxShadow};
  border-radius: 4px;

  .btn-close {
    cursor: pointer;
  }
`
