
const invalidDOMProps: Set<string> = new Set([
  'selectors',
  'as',
  'weight',
  'align',
  'truncated',
  'italic',
  'underline',
  'uppercase',
  'cursor',
  'breakWord',
  'lineClamp',
  'space',
  'xs',
  'sm',
  'md',
  'lg',
  'xl',
  'color',
  'componentProps',
])

export const isValidProp = (prop: string): boolean => {
  return !invalidDOMProps.has(prop)
}
