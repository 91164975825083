// @flow

import { $sizes } from '../components/shared/dependencies/variables'

export const ROOT_PATH = ''
export const PLATFORM = 'web'
export const JOBS_POLL_INTERVAL = 10000000
export const ESC_KEY_CODE = 27
export const REDIRECT_EXPIRED_JOBS_AFTER_IN_DAYS = 7
export const DEFAULT_CURRENCY = 'EUR'
export const MAX_REFERENCES = 5
export const DEFAULT_COUNTRY_CODE = 'DE'
export const PHONE_NUMBER_FORMAT_API = 'E.164'

// NOTE: Keyword search
// Should be moved to separate file
export const AVAILABLE_AUTOCOMPLETE_TYPE = {
  JOB_TITLE: 'job_keyword_search_autocomplete_type_job',
  COMPANY_NAME: 'job_keyword_search_autocomplete_type_company',
}

// Common ID selectors.
export const GOOGLE_MAPS_ELEMENT_ID = 'google-maps'
export const VIEW_JOB_IN_SERP_ID = 'view-job-container'

export const MAX_RECOMMENDATION_TITLE_LENGTH_DESKTOP = 35
export const MAX_RECOMMENDATION_TITLE_LENGTH_MOBILE = 45

export const MAXIMUM_JOB_RECOMMENDATION_COMPANY_NAME_LENGTH = 25
export const MAX_DOCUMENT_NAME_LENGTH = 200

export const MAXIMUM_FILTER_OPTIONS = 20
export const DEFAULT_THROTTLING_TIME_MS = 50
export const MAXIMUM_SEARCH_QUERY_LENGTH = 255
export const MAXIMUM_JOB_SHOWN_AGE = 7
export const VALID_THROUGH_DAYS = 30
export const NUMBER_OF_LINES_BEFORE_EXPAND_JOB_DESCRIPTION = 3

export const CUSTOM_RANKING_ORDER_BY = 'custom_ranking desc'

export const DEFAULT_SEARCH_MODE = 'JOB_SEARCH'
export const DEFAULT_RANKING_IMPORTANCE_LEVEL = 'MEDIUM'

// 200 ct in the ranking formula is the maximum CPC
export const DEFAULT_RANKING_EXPRESSION = '((cpc / 200) * 4) + ranking_boost_factor * 3'

export const GCTS_PAGINATION_LIMIT = 5000
export const DEFAULT_SEARCH_PAGE_SIZE = 25
export const DEFAULT_ORDER_BY_OPTION = 'relevance'
export const MAXIMUM_SEARCH_PAGE = Math.ceil(GCTS_PAGINATION_LIMIT / DEFAULT_SEARCH_PAGE_SIZE)
export const DEFAULT_MOBILE_INITIAL_FILTERS_ITEMS = 10
export const DEFAULT_RADIUS_OPTION = '25'
export const MAXIMUM_RADIUS_OPTION = '50'
export const AVAILABLE_RADIUS_OPTIONS = [
  'none',
  '2',
  '5',
  '10',
  '15',
  '20',
  '25',
  '50',
]

export const SEARCH_RETRY_AFTER_RATE_LIMITING_MS = 5000
export const SIDE_COLUMN_WIDTH = 336
export const COLUMN_MAX_WIDTH = 700
export const TWO_COLUMN_WIDTH = COLUMN_MAX_WIDTH + SIDE_COLUMN_WIDTH + 32 + $sizes.numbers.lg * 2 // left pane + right pane + space between + paddings

export const DEFAULT_LANGUAGES_COUNT = 5

export const MAXIMUM_SITEMAP_URLS = 5000

export const VISITED_JOBS = 'VISITED_JOBS'
export const MAX_COUNT_VISITED_JOBS = 10

export const REGISTER_META_SOURCE_JOB = 'job'
export const REGISTER_META_SOURCE_SEARCH = 'search'

export const ONE_MB = 1024 * 1024
export const UPLOAD_FILE_SIZE_LIMIT = 10
export const UPLOAD_FILE_MIME_TYPES = [
  'image/jpeg',
  'image/png',
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
  'application/rtf',
  'text/plain',
]
export const UPLOAD_FILE_MIME_TYPE_EXTENSIONS = {
  'image/jpeg': 'jpeg',
  'image/png': 'png',
  'application/pdf': 'pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    'docx',
  'application/msword': 'doc',
  'application/rtf': 'rtf',
  'text/plain': 'txt',
}

export const ACCEPT_MIME_TYPES_EXT = [
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
  '.docx',
  '.doc',
  '.dot',
  '.dotx',
  '.docm',
  '.dotm',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
  'application/vnd.ms-word.document.macroEnabled.12',
  'application/vnd.ms-word.document.macroenabled.12',
  'application/vnd.ms-word.template.macroEnabled.12',
  'application/vnd.ms-word.template.macroenabled.12',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  '.ppt',
  '.pptx',
  // images
  'image/png',
  '.png',
  'image/jpg',
  '.jpg',
  'image/jpeg',
  '.jpeg',
  'image/webp',
  '.webp',
  'image/bmp',
  '.bmp',
  'image/gif',
  '.gif',
  'image/tiff',
  '.tif',
  '.tiff',
  // adobe suite
  'image/vnd.adobe.photoshop',
  '.psd',
  'application/postscript',
  'application/illustrator',
  '.ai',
  // pdfs
  'application/pdf',
  '.pdf',
  // open office docs
  '.odp',
  '.odt',
  // other
  'application/rtf',
  'text/rtf',
  '.rtf',
  'text/plain',
  '.txt',
]

export const CLIENT_COMPANY_FLAG = 'is_client'
export const NON_CLIENT_COMPANY_FLAG = 'no_client'

export const UI_ERROR_ENTER_TRANSITION_TIME_MS = 200
export const UI_TEXT_AREA_ROWS = 12
export const UI_BUTTON_LOADER_SIZE = 24

export const DEFAULT_SUBSCRIBE_CONSENT_TYPE = 'job_recommendations_whatsapp_number_confirmed'
export const DEFAULT_UNSUBSCRIBE_CONSENT_TYPE = 'job_recommendations'

export const MINIMUM_RELEVANT_RANKING_BOOST_FACTOR = 0.3

export const COOKIE_CONSENT_INITIALIZED_EVENT = 'UC_UI_INITIALIZED'
export const COOKIE_CONSENT_CHANGED_EVENT = 'UC_UI_VIEW_CHANGED'
export const COOKIE_CONSENT_EXPLICIT_EVENT = 'explicit'
export const GTM_CONSENT_EVENT = 'consent_status'

export const DEFAULT_COUNTRY_OPTION = 'de'
export const AVAILABLE_COUNTRY_OPTIONS = {
  Germany: 'de',
  Austria: 'at',
}

export const DEVICES = {
  MOBILE: 'MOBILE',
  TABLET: 'TABLET',
  LAPTOP: 'LAPTOP',
  LARGE_DISPLAY: 'LARGE_DISPLAY',
}

export const AUTOCOMPLETE_SEARCH_ENGINE_EXPERIMENT = 'inm_autocomplete_search_engine'
export const LOCATION_AUTOCOMPLETE_SEARCH_ENGINE_EXPERIMENT = 'inm_loc-autocomplete_search_engine_1'
export const HEYJOBS_ENGINE = 'heyjobs-engine'
export const INM_SIMILAR_JOBS_EXPERIMENT = 'inm-similar-jobs'
