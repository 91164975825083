import React from 'react'
import { HBox, HDivider, HTypography } from 'talent-ui'
import { List, ContentContainer, ListItem, UrlWrapper } from './styles'
import { HEYASSESSMENT_CONDITIONS_VERSION, HEYASSESSMENT_CONDITIONS_DATE } from './constants'

export const HeyAssessmentAGB: React.FC = () => (
  <ContentContainer
    p={6}
    mx='auto'
    mt={5}
  >
    <div>
      <HTypography
        variant='h5'
        weight='semibold'
        sm={{ variant: 'h3' }}
        md={{ variant: 'h2' }}
        style={{ marginBottom: 16 }}
      >
        Sonderbedingungen HeyJobs
        Bewerbungsassistent
      </HTypography>

      <HTypography
        variant='body2'
        weight='regular'
        color='surface_gray_90'
      >
        <HTypography
          space={{ mb: 4 }}
        >
          Stand September 2024:
        </HTypography>

        <HTypography
          space={{ mb: 4 }}
        >
          Diese Sonderbedingungen der HeyJobs GmbH, eingetragen im Handelsregister des
          Amtsgerichts Berlin-Charlottenburg unter HRB 175212, gelten für die Nutzung des
          Angebots “HeyJobs Bewerbungsassistent” durch Jobsuchende und gelten ergänzend zu
          den Allgemeinen Geschäftsbedingungen für Jobsuchende unter
          {' '}
          <UrlWrapper
            text='https://www.heyjobs.co/de-de/agb'
            href='https://www.heyjobs.co/de-de/agb'
          />
          .
        </HTypography>

        <HTypography
          weight='semibold'
        >
          {`Version ${HEYASSESSMENT_CONDITIONS_VERSION} vom ${HEYASSESSMENT_CONDITIONS_DATE}`}
        </HTypography>

        <HBox
          mt={4}
          mb={8}
        >
          <HDivider />
        </HBox>

        <List>
          <ListItem>
            HeyJobs Bewerbungsassistent ist ein optionales Angebot, das den Bewerbungsprozess für
            Jobsuchende beschleunigt. Deine Nutzung von HeyJobs Bewerbungsassistent ist freiwillig.
            Wenn du das Angebot HeyJobs Bewerbungsassistent nicht nutzt, hat deine Bewerbung die
            gleichen Chancen
          </ListItem>
          <ListItem>
            HeyJobs Bewerbungsassistent ist ein automatisiertes optionales Interview-Angebot in der
            Form eines Chats. Wenn du HeyJobs Bewerbungsassistent nutzt, chattest du mit einer KI,
            die sich noch in der Testphase befindet. Mit der Nutzung von HeyJobs Bewerbungsassistent
            bist du mit der Nutzung von KI einverstanden und weißt, dass in dieser Testphase die KI
            unter Umständen nicht immer zu 100% fehlerfrei funktioniert. HeyJobs
            Bewerbungsassistent basiert auf Technologien von Drittanbietern. Zusätzlich zu diesen
            Sonderbedingungen unterliegt die Nutzung von HeyJobs Bewerbungsassistent daher den
            Nutzungsbedingungen dieser Drittanbieter:
            {' '}
            <UrlWrapper
              text='www.langdock.com'
              href='www.langdock.com'
            />
            ,
            {' '}
            <UrlWrapper
              text='www.superchat.com'
              href='www.superchat.com'
            />
            {' '}
            <UrlWrapper
              text='www.whatsapp.com'
              href='www.whatsapp.com'
            />
            {' '}
            <UrlWrapper
              text='www.mailchimp.com'
              href='www.mailchimp.com'
            />
            {' '}
            und
            {' '}
            <UrlWrapper
              text='www.zapier.com'
              href='www.zapier.com'
            />
            {' '}
            . Wir weisen insbesondere
            darauf hin, dass Informationen, die bei der Nutzung von HeyJobs Bewerbungsassistent
            anfallen, mit diesen Drittanbietern geteilt werden können. Die durch dich in HeyJobs
            Bewerbungsassistent bereitgestellten Inhalte und die auf deren Grundlage in HeyJobs
            Bewerbungsassistent erstellten Inhalte werden pseudonymisiert, sodass Personen nicht
            identifiziert werden können. Wir werden die durch dich in HeyJobs Bewerbungsassistent
            bereitgestellten Inhalte und die auf deren Grundlage in HeyJobs Bewerbungsassistent
            erstellten Inhalte so lange speichern wie die zugrundeliegende Bewerbung.
          </ListItem>
          <ListItem>
            Du bist für alle Inhalte, die du in HeyJobs Bewerbungsassistent bereitstellst, verantwortlich
            und musst sicherstellen, dass du das Recht hast, diese Inhalte bereitzustellen, und dass
            diese keine Verstöße gegen geltendes Recht darstellen, beleidigend oder diskriminierend
            sind. Werden HeyJobs oder der Arbeitgeber, bei dem du dich beworben hast, aufgrund von
            Inhalten, die du in HeyJobs Bewerbungsassistent bereitgestellt hast, von Dritten in Haftung
            genommen, wirst du HeyJobs bzw. den Arbeitgeber von der Haftung freistellen. HeyJobs
            lehnt alle ausdrücklichen oder stillschweigenden Garantien in Bezug auf den HeyJobs
            Bewerbungsassistent-Output ab, einschließlich jeglicher stillschweigenden Garantien, dass
            der HeyJobs Bewerbungsassistent-Output nicht die Rechte eines Dritten oder ein
            anwendbares Gesetz verletzen werden. Wenn du HeyJobs Bewerbungsassistent nutzt, bist
            du allein dafür verantwortlich, dass die Eingabe- und Ausgabedaten mit den geltenden
            Gesetzen übereinstimmen und nur für legale und rechtmäßige Zwecke, einschließlich
            kommerzieller Zwecke, auf dein eigenes Risiko verwendet werden.
          </ListItem>

          <ListItem>
            HeyJobs darf alle Inhalte, die du in HeyJobs Bewerbungsassistent zur Verfügung stellst und
            die im HeyJobs Bewerbungsassistent auf deren Grundlage erstellt werden, verwenden um
            deine Bewerbung und dein Bewerberprofil anzureichern und dem Arbeitgeber, bei dem du
            dich beworben hast, zur Verfügung stellen. Du kannst dieser Nutzung jederzeit mit Wirkung
            für die Zukunft widersprechen. Alle Informationen dazu findest du in der
            Datenschutzerklärung von HeyJobs unter
            {' '}
            <UrlWrapper
              text='https://www.heyjobs.co/recruiting/datenschutzerklaerung'
              href='https://www.heyjobs.co/recruiting/datenschutzerklaerung'
            />
          </ListItem>
          <ListItem>
            Im Fall von Widersprüchen zwischen diesen Sonderbedingungen und den Allgemeinen
            Geschäftsbedingungen für Jobsuchende im Hinblick auf HeyJobs Bewerbungsassistent
            haben diese Sonderbedingungen vorrangig Geltung. HeyJobs behält sich das Recht vor,
            nach eigenem Ermessen das HeyJobs Bewerbungsassistent-Feature zu ändern, zu
            ersetzen, auszusetzen oder einzustellen oder die Sonderbedingungen jederzeit zu ändern,
            entweder durch Veröffentlichung einer Mitteilung auf seinen Webseiten oder seinem Dienst
            oder durch Versendung einer E-Mail. HeyJobs kann auch den Zugang zu Teilen oder der
            Gesamtheit von HeyJobs Bewerbungsassistent ohne Ankündigung oder Haftung
            einschränken.
            Es liegt in deiner Verantwortung, diese Sonderbedingungen regelmäßig zu überprüfen. Die
            Parteien vereinbaren, dass jede fortgesetzte Nutzung von HeyJobs Bewerbungsassistent
            durch dich, die nach der Veröffentlichung von Änderungen der Sonderbedingungen erfolgt,
            die Annahme dieser Änderungen darstellt.
          </ListItem>
        </List>
      </HTypography>
    </div>
  </ContentContainer>
)
