export const COLORS = {
  primary_90: '#163060',
  primary_70: '#2551A1',
  primary_50: '#3C69BE',
  primary_30: '#83A6E9',
  primary_25: '#C3D5F8',
  primary_15: '#E5EFFF',
  primary_05: '#F4F9FE',
  primary_00: '#FAFCFF',

  accent_yellow_90: '#6D5104',
  accent_yellow_70: '#C59001',
  accent_yellow_50: '#E6AF14',
  accent_yellow_30: '#F7CD55',
  accent_yellow_25: '#FEDD84',
  accent_yellow_15: '#FFF2C7',
  accent_yellow_05: '#FFF8E0',
  accent_yellow_00: '#FFFCF0',

  accent_pink_90: '#721E46',
  accent_pink_70: '#A32C65',
  accent_pink_50: '#C84182',
  accent_pink_30: '#CE6D9C',
  accent_pink_25: '#DA9BB9',
  accent_pink_15: '#E5C6D5',
  accent_pink_05: '#F2E1E9',
  accent_pink_00: '#FDF4F9',

  surface_gray_100: '#181A22',
  surface_gray_90: '#313545',
  surface_gray_80: '#4C526B',
  surface_gray_60: '#707485',
  surface_gray_40: '#9397A9',
  surface_gray_20: '#BBBFCF',
  surface_gray_10: '#DADDE8',
  surface_gray_05: '#EDEEF3',

  surface_gray_00: '#FBFBFC',
  surface_white: '#FFFFFF',
  surface_off_white_001: '#F8F8FA',

  support_success_90: '#003621',
  support_success_70: '#005A37',
  support_success_50: '#10774F',
  support_success_30: '#319971',
  support_success_25: '#7EC3A9',
  support_success_15: '#C0DBD1',
  support_success_05: '#DEF0E9',
  support_success_00: '#F0F8F6',

  support_warning_90: '#743617',
  support_warning_70: '#A64E22',
  support_warning_50: '#BB6F48',
  support_warning_30: '#DE8E65',
  support_warning_25: '#ECAC8B',
  support_warning_15: '#F7CAB3',
  support_warning_05: '#FEE7DB',
  support_warning_00: '#FEF4EF',

  support_help_90: '#282560',
  support_help_70: '#443EA0',
  support_help_50: '#615BBC',
  support_help_30: '#8A85CD',
  support_help_25: '#ACA9DC',
  support_help_15: '#D7D5F4',
  support_help_05: '#EDECFF',
  support_help_00: '#F5F4FF',

  support_error_90: '#550A0A',
  support_error_70: '#7A0F0F',
  support_error_50: '#A83939',
  support_error_30: '#C86767',
  support_error_25: '#EB8B8B',
  support_error_15: '#E8B3B3',
  support_error_05: '#F4DDDD',
  support_error_00: '#F9EFEF',

  inherit: 'inherit',
}
