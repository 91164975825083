export const ONBOARDING_FLOW_EXIT_EVENT = 'onboarding_flow_exit'
export const ONBOARDING_FLOW_STEP_COMPLETED_EVENT = 'onboarding_flow_step_completed'
export const ONBOARDING_FLOW_SKIP_EVENT = 'onboarding_flow_skipped'
export const WELCOME_SCREEN_SHOWN_EVENT = 'welcome_screen_shown'
export const ONBOARDING_FLOW_STARTED_EVENT = 'onboarding_flow_started'

export const OnboardingSchemas = {
  [ONBOARDING_FLOW_EXIT_EVENT]: 'iglu:de.heyjobs/onboarding_flow_exit/jsonschema/1-0-1',
  [ONBOARDING_FLOW_STEP_COMPLETED_EVENT]: 'iglu:de.heyjobs/onboarding_flow_step_completed/jsonschema/1-0-1',
  [ONBOARDING_FLOW_SKIP_EVENT]: 'iglu:de.heyjobs/onboarding_flow_skipped/jsonschema/1-0-1',
  [WELCOME_SCREEN_SHOWN_EVENT]: 'iglu:de.heyjobs/welcome_screen_shown/jsonschema/1-0-0',
  [ONBOARDING_FLOW_STARTED_EVENT]: 'iglu:de.heyjobs/onboarding_flow_started/jsonschema/1-0-0'
}
