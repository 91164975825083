import React from 'react'
import styled from '@emotion/styled'
import { HBox } from '../../HBox'
import { HTypography } from '../../HTypography'
import { MainFooterLink } from './FooterLink'
import { PopularJobsProps } from './HMainFooter.types'
import { getSeoLinks } from './seo-links'

const PopularJobsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    grid-template-columns: 1fr 1fr;
  }
`

export const MiddleSection = ({
  title,
  pageSettings,
  isVisible = true,
}: PopularJobsProps): React.ReactElement | null => {
  const links = getSeoLinks(pageSettings)

  if (isVisible) {
    return (
      <>
        <HBox mb={2}>
          <HTypography
            variant='overline2'
            weight='semibold'
            color='surface_gray_80'
          >
            {title.toUpperCase()}
          </HTypography>
        </HBox>
        <PopularJobsContainer
          data-test-id='popular-jobs-container'
        >
          {links.map(({ href, title }) => {
            return (
              <MainFooterLink
                key={title}
                label={title}
                componentProps={{
                  href,
                }}
              />
            )
          })}
        </PopularJobsContainer>
      </>
    )
  }

  return null
}
