// @flow

import API from '../clientBuilder'
import { buildUrlFragment } from '../../../helpers/url/query'
import type { APIRequestConfig } from '../types'

export default {
  get: (codename: string, config: APIRequestConfig) =>
    API.get.promise(`/v2/job_types/${codename}`, null, config),
  list: (options: $FlowTODO, config: APIRequestConfig) =>
    API.get.promise(`/v2/job_types${buildUrlFragment(options)}`, null, config),
  suggestions: (data: $FlowTODO, config: APIRequestConfig) =>
    API.post.promise('/v2/job_types/suggestions', data, config),
}
