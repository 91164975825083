// @flow

import isPromise from 'is-promise'

export function createPromise () {
  return (ref: $FlowTODO) => (next: $FlowTODO) => (action: $FlowTODO) => {
    let promise

    // is there a 'rx' dummy promise?
    if (action.rx) {
      const dummy = action.rx

      //  is the promise implicitly defined?
      if (isPromise(dummy)) {
        promise = dummy
      } else if (typeof dummy === 'function') { // is the promise returned by an async function?
        promise = dummy()

        // is the return of action.payload a promise?
        if (!isPromise(promise)) {
          // if not, move on to the next middleware.
          return next({
            ...action,
            rx: promise,
          })
        }
      }
    }

    next(action) // forward the action to be handled by other middleware

    return promise
  }
}

const epicPromiseMiddleware = ({ dispatch }: $FlowTODO = {}) => {
  return createPromise()({ dispatch })
}

export default epicPromiseMiddleware
