import { createContext, useContext } from 'react'
import { type AnimationDirection } from './components/AnimationContainer'
import { IntlShape } from 'react-intl'
import { Job } from '../../types/job'
import { ScreeningQuestion } from '../../types/screeningQuestion'

export type CAFContextProps = {
  direction: AnimationDirection,
  translationFormat: '' | '_formal',
  intl: IntlShape,
  submitFailed: boolean,
  job: Job,
  screeningQuestions: ScreeningQuestion[]
  onContinue: (trackingLabel: string) => Promise<void>
  onBack: () => void
}

export const CAFContext = createContext<CAFContextProps>({
  direction: 'forward',
  translationFormat: '',
  intl: null as never,
  submitFailed: false,
  job: {} as never,
  screeningQuestions: [],
  onContinue: () => { throw new Error('context not initialized') },
  onBack: () => { throw new Error('context not initialized') },
})

export const useCAFContext = () => useContext(CAFContext)
