import { RawParsedCv } from '../../../store/parsedCv/types'
import API from '../clientBuilder'
import type { APIRequestConfig } from '../types'

export default {
  get: (documentId: string, config: APIRequestConfig): Promise<RawParsedCv> => {
    return API.get.promise(`/v2/talent_platform/parsed_cv/${documentId}`, null, config)
  },
  update: (documentId: string, data: RawParsedCv, config: APIRequestConfig): Promise<RawParsedCv> => {
    return API.put.promise(`/v2/talent_platform/parsed_cv/${documentId}`, data, config)
  },
}
