// @flow
import * as React from 'react'
import AbTestingVariant, { AbTestingOriginal } from './abTestingVariant'

const findByPropName = (propName: string): Function => (comp: React.Element<any>): bool => comp.props.variantName === propName
export const findComponentByNameProps = (name: string, components: React.Node): ?React.Node => {
  let result = null
  React.Children.forEach(components, (component) => {
    if (findByPropName(name)(component)) {
      result = component
    }
  })

  return result
}

export const getVariantChildren = (
  components: React.Node,
  VariantComponent: React.StatelessFunctionalComponent<any> = AbTestingVariant,
  VariantComponentName: string = 'AbTestingVariant'
): React.Node => {
  const variants = []
  React.Children.forEach(components, (component) => {
    const hasSameDisplayName = component && component.type && component.type.displayName === VariantComponentName // for browser
    const hasSameType = component && component.type === VariantComponent // for server

    if (hasSameDisplayName || hasSameType) {
      variants.push(component)
    }
  })

  return variants
}

export const getVariantChild = (components: React.Node): React.Node => {
  const variants = getVariantChildren(components)
  const variantCount = React.Children.count(variants)

  if (variantCount !== 1) {
    // console.wa Error(`Expected single variant, but found ${variantCount}`)
  }

  return variants
}

export const getOriginalChild = (components: React.Node): React.Node => {
  const variants = getVariantChildren(components, AbTestingOriginal, 'AbTestingOriginal')
  const variantCount = React.Children.count(variants)

  if (variantCount !== 1) {
    // throw new Error(`Expected single original, but found ${variantCount}`)
  }

  return variants
}
