import * as sessionStorage from './sessionStorageUtils'
import logger from '../../logging'

const log = logger('postSignupUtils')
interface SearchWithKeyword {
  keyword: string,
}

interface SearchWithLocation {
  location: string
}

type RegistrationSource = {
  source: 'job' | 'search' | 'conversational_application_flow',
  data : { jobId: string } | ((SearchWithKeyword | SearchWithLocation | (SearchWithLocation & SearchWithKeyword)))
}

export const AuthOrigins = { CAF: 'conversational_application_flow' } as const

export type PostSignupData = {
  registrationSource: RegistrationSource,
  bookmarkJobId: string,
  tracking?: {
    authLocation: string
  }
}

// HACK: using this for safety since TS is not checking flow files
export const postSignupIdentifiers = {
  bookmarkJobId: 'bookmarkJobId',
  registrationSource: 'registrationSource',
  tracking: 'tracking',
  jobSearchOptions: 'jobSearchOptions',
}
// TODO: update the name of the functions, these functions are also used in signin.
export const updatePostSignupDataInSessionStorage = (data:Partial<PostSignupData>) => {
  try {
    const currentData = getPostSignupDataFromSessionStorage()
    sessionStorage.setItem(sessionStorage.identifiers.postSignupData, JSON.stringify({ ...currentData, ...data }))
  } catch (error) {
    log.error('Failed to save post signup data in session storage:', error)
  }
}

export function removePostSignupDataFromSessionStorage (key?: keyof PostSignupData) {
  try {
    const currentData = getPostSignupDataFromSessionStorage()
    if (!currentData) return
    if (!key) {
      sessionStorage.removeItem(sessionStorage.identifiers.postSignupData)
      return
    }
    delete currentData[key]
    sessionStorage.setItem(sessionStorage.identifiers.postSignupData, JSON.stringify(currentData))
  } catch (error) {
    log.error('Failed to remove post signup data from session storage:', error)
  }
}

export function getPostSignupDataFromSessionStorage (key?: keyof PostSignupData): any {
  try {
    const data = sessionStorage.getItem(sessionStorage.identifiers.postSignupData)
    if (!data) return {}
    const currentData = JSON.parse(data) as PostSignupData
    if (!key) return currentData
    return currentData[key]
  } catch (error) {
    log.error('Failed to get post signup data from session storage:', error)
  }
}
