// @flow

/* eslint no-console: 0 */

import config from '../../../config'
import debug from '../shared/debug'

import { dataLayer as pushToDataLayer } from 'data-layer'

const datalayer = (...args: any[]) => {
  try {
    debug.datalayer.push(args)

    if (config?.snowplow.enableLogging) {
      console.log('tracking - datalayer', ...args)
    }

    pushToDataLayer(...args)
  } catch (e) {
    console.error('tracking - datalayer - error', e)
  }
}

export default datalayer
