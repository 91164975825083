import React, { PropsWithChildren } from 'react'
import { HNavigationFunnelTopBar, HBox, HTypography } from 'talent-ui'
import { Container } from './container'
import { BottomNavigation } from './bottom-navigation'
import { useOnboarding } from './use-onboarding-flow'
import BackStepButton from './back-step-button'
import { FormattedMessage } from 'react-intl'

export type StepContainerProps = PropsWithChildren<{
  stepHeader?: string,
  title?: string,
  progress?: number,
  validToContinue?: boolean,
  onClose?: () => void,
  onBack?: () => void,
  onSkip?: () => void
  onContinue?: () => void
}>

const StepContainer: React.FC<StepContainerProps> = ({
  children,
  stepHeader,
  title,
  progress,
  validToContinue,
  onClose,
  onSkip,
  onBack,
  onContinue
}) => {
  const { intl } = useOnboarding()

  return (
    <HBox
      height='100%'
      display='flex'
      flexDirection='column'
    >
      <HNavigationFunnelTopBar
        roundelLogo
        onClick={onClose}
        progress={progress}
        smTextVariant='body1'
        text={intl.formatMessage({ id: stepHeader || 'onboarding_flow_general_header' })}
      />
      <Container>
        <HBox
          px={6}
          md={{ px: 10 }}
          lg={{ px: 6 }}
        >
          {onBack &&
            <BackStepButton onClick={onBack} />}

          <HBox
            mb={8}
            mt={!onBack ? 10 : 0}
          >
            <HTypography
              md={{ variant: 'h3' }}
              xs={{ variant: 'h4' }}
            >
              <FormattedMessage id={title} />
            </HTypography>
          </HBox>

          {children}
        </HBox>
      </Container>
      <BottomNavigation
        withShadow
        fullScreen
        secondaryAction={
          onSkip
            ? {
                text: intl.formatMessage({ id: 'onboarding_flow_skip_step_button' }),
                onClick: onSkip
              }
            : undefined
}
        primaryAction={
          onContinue
            ? {
                text: intl.formatMessage({ id: 'continue' }),
                onClick: onContinue,
                disabled: !validToContinue
              }
            : undefined
}
      />
    </HBox>
  )
}

export default StepContainer
