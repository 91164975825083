// @flow

import includes from 'lodash/includes'
import pickBy from 'lodash/pickBy'
import keys from 'lodash/keys'
import languageFlag from '../../../assets/images/footer/lang-icon.svg'
import config from '../../config'
import { type Country } from '../types/common'

const pickCountriesForLocale = (locale: string): string[] =>
  keys(pickBy(config.countries.locales, (l) => includes(l, locale)))

const CountryCodeToNames: {[key: Country]: string} = Object.freeze({
  at: 'austria',
  de: 'germany',
})

export const countryCodeToName = (countryCode: Country) => CountryCodeToNames[countryCode] || 'germany'

export const LOCALES_CONFIG = Object.freeze({
  en: {
    name: 'en',
    flag: languageFlag,
    changeLangId: 'Button_ChangeLang_en',
    availableFor: pickCountriesForLocale('en'),
  },
  de: {
    name: 'de',
    flag: languageFlag,
    changeLangId: 'Button_ChangeLang_de',
    availableFor: pickCountriesForLocale('de'),
  },
})

export const COUNTRIES_CONFIG = Object.freeze({
  de: {
    available: ['de', 'en'],
    default: 'de',
  },
  at: {
    available: ['de', 'en'],
    default: 'de',
  },
  ch: {
    available: ['de'],
    default: 'de',
  },
  us: {
    available: ['en'],
    default: 'en',
  },
  ca: {
    available: ['en'],
    default: 'en',
  },
  gb: {
    available: ['en'],
    default: 'en',
  },
})
