/* istanbul ignore file */
export class BrazeQueueManager {
    private static instance: BrazeQueueManager
    private initialized: boolean = false
    private readonly callQueue: Array<() => void> = []

    static getInstance (): BrazeQueueManager {
      if (!BrazeQueueManager.instance) {
        BrazeQueueManager.instance = new BrazeQueueManager()
      }
      return BrazeQueueManager.instance
    }

    executeCall (fn: () => void) {
      if (this.initialized) {
        fn()
      } else {
        this.callQueue.push(fn)
      }
    }

    setInitialized () {
      this.initialized = true
    }

    processQueue () {
      while (this.callQueue.length > 0) {
        const fn = this.callQueue.shift()
        fn?.()
      }
    }
}

export const executeBrazeCall = (fn: () => void) => {
  BrazeQueueManager.getInstance().executeCall(fn)
}
