// @flow
import * as actions from '../constants/actions'
import { REJECTED, FULFILLED, PENDING } from './promiseUtil'

export type ConsentState = {
  alreadySubscribed: bool,
  displayThankYou: bool,
  emailConfirmationFailed: bool,
  emailConfirmed: bool,
  errorSubscribing: bool,
  subscribedEmail: bool,
  subscribingEmail: bool,
  visible: bool
}

const initialState: ConsentState = {
  visible: false,
  displayThankYou: false,
  emailConfirmationFailed: false,
  emailConfirmed: false,
  alreadySubscribed: false,
  subscribingEmail: false,
  subscribedEmail: false,
  errorSubscribing: false,
}

const reducer = (state: ConsentState = initialState, action: any): ConsentState => {
  switch (action.type) {
    case actions.SHOW_CRM_OPTIONS_MODAL:
      return {
        ...state,
        visible: true,
        emailConfirmed: action.emailConfirmed || state.emailConfirmed,
      }
    case actions.HIDE_CRM_OPTIONS_MODAL:
      return {
        ...state,
        visible: false,
      }
    case FULFILLED(actions.CREATE_RECOMMENDATION_CONSENT):
    case FULFILLED(actions.CREATE_WHATS_APP_RECOMMENDATION_CONSENT):
    case FULFILLED(actions.CREATE_CONSENT):
      return {
        ...state,
        visible: false,
      }
    case actions.DISPLAY_CRM_THANK_YOU_MODAL:
      return {
        ...state,
        displayThankYou: true,
      }
    case actions.HIDE_CRM_THANK_YOU_MODAL:
      return {
        ...state,
        displayThankYou: false,
      }
    case REJECTED(actions.CONFIRM_EMAIL):
      return {
        ...state,
        emailConfirmationFailed: true,
        emailConfirmed: false,
      }
    case FULFILLED(actions.CONFIRM_EMAIL):
      return {
        ...state,
        emailConfirmed: true,
      }
    case PENDING(actions.UNSUBSCRIBE_EMAILS):
      return {
        ...state,
        unsubscribingEmail: true,
      }
    case REJECTED(actions.UNSUBSCRIBE_EMAILS):
      return {
        ...state,
        unsubscribingEmail: false,
        errorUnsubscribing: true,
      }
    case FULFILLED(actions.UNSUBSCRIBE_EMAILS):
      return {
        ...state,
        unsubscribedEmail: true,
        unsubscribingEmail: false,
      }
    case PENDING(actions.SUBSCRIBE_EMAILS):
      return {
        ...state,
        subscribingEmail: true,
      }
    case REJECTED(actions.SUBSCRIBE_EMAILS):
      return {
        ...state,
        subscribingEmail: false,
        errorSubscribing: true,
      }
    case FULFILLED(actions.SUBSCRIBE_EMAILS):
      return {
        ...state,
        subscribedEmail: true,
        subscribingEmail: false,
      }
    case actions.USER_ALREADY_UNSUBSCRIBED:
      return {
        ...state,
        alreadyUnsubscribed: true,
      }
    case actions.USER_ALREADY_SUBSCRIBED:
      return {
        ...state,
        alreadySubscribed: true,
      }
    case actions.ERROR_UNSUBSCRIBING:
      return {
        ...state,
        errorUnsubscribing: true,
      }
    case actions.ERROR_SUBSCRIBING:
      return {
        ...state,
        errorSubscribing: true,
      }
    default:
      return state
  }
}

export default reducer
