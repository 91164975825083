// @flow
import * as actions from '../constants/actions'
import omit from 'lodash/omit'
import { FULFILLED, REJECTED, PENDING } from './promiseUtil'

type SeoMeta = {
  content: string,
  name: string
}

export type SeoState = {|
  content: ?string,
  error: bool,
  hasSEO: bool,
  isFallbackPage: bool,
  isLoading: bool,
  metas: SeoMeta[],
  pathname: string,
  seoDescription : string,
  seoFooter: string,
  seoTitle: string,
  urls: ?string
|}

const initialState: SeoState = {
  seoFooter: '',
  seoTitle: '',
  seoDescription: '',
  metas: [],
  isLoading: false,
  hasSEO: false,
  error: false,
  pathname: '',
  content: null,
  isFallbackPage: false,
  urls: null,
}

const reducer = (state: SeoState = initialState, action: any): SeoState => {
  switch (action.type) {
    case FULFILLED(actions.FETCH_SEO_DETAILS): {
      if (!action.payload) { // no content
        return {
          ...initialState,
          pathname: action.meta[0].search_query,
        }
      }

      const {
        title: seoTitle,
        seo_footer: seoFooter,
        description,
        keywords,
        is_fallback_seo_page: isFallbackPage,
      } = action.payload

      const seoMetas = { description, keywords }
      const metas = []
      Object.entries(seoMetas).forEach(
        ([name, content]) => {
          if (typeof content === 'string') {
            metas.push({ name, content })
            return metas
          }
          return metas
        }
      )

      return {
        ...state,
        pathname: action.meta[0].search_query,
        hasSEO: true,
        seoTitle: seoTitle || '',
        seoDescription: description || '',
        seoFooter: seoFooter || '',
        urls: action.payload?.urls,
        content: action.payload?.content,
        isFallbackPage,
        metas,
      }
    }
    case REJECTED(actions.FETCH_SEO_DETAILS):
      return {
        ...initialState,
        pathname: action.meta[0].search_query,
        error: true,
      }
    case PENDING(actions.FETCH_SEO_DETAILS):
      return {
        ...state,
        isLoading: true,
      }
    case actions.LOCATION_CHANGE: {
      return {
        ...state,
        ...omit(initialState, 'urls'),
      }
    }
    default:
      return state
  }
}

export default reducer
