import * as actions from '../constants/actions'

export default (state = {}, action) => {
  switch (action.type) {
    case actions.SET_TRANSLATIONS:
      return {
        locale: action.locale,
        messages: action.messages,
      }
    default:
      return state
  }
}
