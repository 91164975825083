// @flow

import get from 'lodash/get'

export const orderIdApplicationSubmission = (state: Object) => {
  const orderId = sessionStorage.getItem('orderIdApplicationSubmission')
  const applicationId = get(state, 'currentJob.job.application.id')

  if (orderId && orderId === applicationId) {
    return orderId
  }

  sessionStorage.setItem('orderIdApplicationSubmission', applicationId)
  return applicationId
}

export const orderIdApplicationStarted = (state: Object) => {
  const orderId = sessionStorage.getItem('orderIdApplicationStarted')
  const jobId = get(state, 'currentJob.job.id')
  const userDeviceToken = get(state, 'session.device.token')

  if (orderId && orderId.startsWith(jobId)) {
    return orderId
  }

  const newOrderId = jobId + userDeviceToken
  sessionStorage.setItem('orderIdApplicationStarted', newOrderId)
  return newOrderId
}
