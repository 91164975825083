import { COOKIE_CONSENT_CHANGED_EVENT, COOKIE_CONSENT_EXPLICIT_EVENT, COOKIE_CONSENT_INITIALIZED_EVENT, GTM_CONSENT_EVENT } from './constants'

export const hasExplicitConsent = () =>
  !!window.dataLayer.find((d) => d.event === GTM_CONSENT_EVENT && d.type === COOKIE_CONSENT_EXPLICIT_EVENT)

export const cookieConsentInitializationHandler = () => {
  const handleConsent = () => {
    if (!hasExplicitConsent()) return

    while (window.dataLayerBeforeConsent.length) {
      const eventArgs = window.dataLayerBeforeConsent.pop()

      window.dataLayer.push(eventArgs)
    }
  }

  // NOTE: Handles new explicit consents
  window.addEventListener(COOKIE_CONSENT_CHANGED_EVENT, handleConsent)

  // NOTE: Handles previous explicit consents
  window.addEventListener(COOKIE_CONSENT_INITIALIZED_EVENT, handleConsent)
}
