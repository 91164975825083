import React from 'react'
import { HSvgIcon, HSvgIconProps } from '../HSvgIcon'

export const HIconPlane: React.FC<HSvgIconProps> = (props) => (
  <HSvgIcon viewBox="0 0 187 149" fill="none" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M150.582 5.58409C150.373 6.22131 149.687 6.56854 149.05 6.35965C148.263 6.10158 147.06 6.5073 145.935 7.6851C144.825 8.84758 144.293 10.2563 144.453 11.1649C144.57 11.8252 144.129 12.4551 143.469 12.5718C142.809 12.6885 142.179 12.2478 142.062 11.5874C141.711 9.59986 142.806 7.44536 144.179 6.00769C145.538 4.58534 147.677 3.35399 149.806 4.05208C150.444 4.26097 150.791 4.94688 150.582 5.58409Z" fill="#C84182"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M174.533 75.048C174.356 74.2053 174.896 73.3787 175.739 73.2019C176.78 72.9834 177.857 71.7603 178.352 69.7281C178.84 67.7224 178.527 65.8143 177.765 64.907C177.211 64.2475 177.297 63.264 177.957 62.7103C178.616 62.1566 179.6 62.2422 180.153 62.9017C181.82 64.8865 181.986 67.9855 181.381 70.4661C180.784 72.9203 179.196 75.6628 176.379 76.2538C175.536 76.4306 174.71 75.8908 174.533 75.048Z" fill="#F4F9FE"/>
    <path d="M168.506 40.0326C168.506 40.8937 167.808 41.5917 166.947 41.5917C166.086 41.5917 165.388 40.8937 165.388 40.0326C165.388 39.1715 166.086 38.4734 166.947 38.4734C167.808 38.4734 168.506 39.1715 168.506 40.0326Z" fill="#C84182"/>
    <path d="M156.949 77.2857C156.949 77.7786 156.549 78.1781 156.056 78.1781C155.564 78.1781 155.164 77.7786 155.164 77.2857C155.164 76.7928 155.564 76.3933 156.056 76.3933C156.549 76.3933 156.949 76.7928 156.949 77.2857Z" fill="#C84182"/>
    <path d="M118.513 14.6532C118.513 15.5143 117.815 16.2123 116.954 16.2123C116.093 16.2123 115.395 15.5143 115.395 14.6532C115.395 13.7921 116.093 13.094 116.954 13.094C117.815 13.094 118.513 13.7921 118.513 14.6532Z" fill="#F4F9FE"/>
    <path d="M186.384 33.0565C186.384 33.9176 185.686 34.6157 184.825 34.6157C183.964 34.6157 183.266 33.9176 183.266 33.0565C183.266 32.1954 183.964 31.4973 184.825 31.4973C185.686 31.4973 186.384 32.1954 186.384 33.0565Z" fill="#F4F9FE"/>
    <path d="M40.8963 141.379H152.445" stroke="#4C526B" strokeWidth="2" strokeLinecap="round"/>
    <path d="M15.2401 141.379H33.0879" stroke="#4C526B" strokeWidth="2" strokeLinecap="round"/>
    <path d="M29.7415 148H62.0141" stroke="#4C526B" strokeWidth="2" strokeLinecap="round"/>
    <path d="M152.243 31.1646L37.4223 73.1774L35.3903 107.993L146.843 41.826L152.243 31.1646Z" fill="#F4F9FE"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M153.077 30.4222C153.381 30.7647 153.445 31.2584 153.238 31.6671L147.838 42.3285C147.742 42.5176 147.594 42.6755 147.412 42.7836L35.9595 108.951C35.6048 109.161 35.1627 109.159 34.8102 108.945C34.4577 108.73 34.2525 108.339 34.2765 107.927L36.3085 73.1109C36.3344 72.6671 36.6213 72.2811 37.0388 72.1284L151.86 30.1155C152.29 29.9581 152.773 30.0797 153.077 30.4222ZM38.493 73.9719L36.626 105.961L145.994 41.0309L149.969 33.1827L38.493 73.9719Z" fill="#4C526B"/>
    <path d="M35.3774 107.946L51.9007 84.0537L61.8909 92.4264L35.3774 107.946Z" fill="#FAFCFF"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M51.7514 82.949C52.0623 82.907 52.3766 82.998 52.617 83.1995L62.6073 91.5722C62.8854 91.8053 63.0328 92.1591 63.0023 92.5207C62.9719 92.8823 62.7674 93.2065 62.4543 93.3898L35.9408 108.909C35.4894 109.174 34.915 109.089 34.5593 108.705C34.2037 108.322 34.1623 107.742 34.4598 107.312L50.9831 83.42C51.1615 83.1619 51.4404 82.9909 51.7514 82.949ZM52.1219 85.6954L39.1578 104.441L59.9602 92.2646L52.1219 85.6954Z" fill="#4C526B"/>
    <path d="M152.235 31.1646L5.33267 47.6503L37.2929 73.1981L152.235 31.1646Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M153.321 30.9099C153.45 31.4596 153.148 32.0192 152.618 32.2131L37.6757 74.2466C37.3098 74.3804 36.9005 74.3136 36.5961 74.0703L4.63587 48.5225C4.28357 48.2408 4.13553 47.7748 4.26073 47.3415C4.38594 46.9082 4.75976 46.5929 5.20797 46.5426L152.11 30.057C152.672 29.994 153.191 30.3603 153.321 30.9099ZM8.13032 48.4596L37.4989 71.9358L143.12 33.3108L8.13032 48.4596Z" fill="#4C526B"/>
    <path d="M152.254 31.1845L104.275 126.454L51.9796 84.0252L152.254 31.1845Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M153.036 30.3892C153.382 30.729 153.469 31.2536 153.25 31.6869L105.271 126.956C105.119 127.258 104.839 127.474 104.509 127.545C104.179 127.616 103.834 127.533 103.572 127.321L51.2765 84.8921C50.9854 84.656 50.8324 84.2894 50.8692 83.9164C50.906 83.5434 51.1277 83.2138 51.4593 83.039L151.734 30.1983C152.163 29.9722 152.689 30.0493 153.036 30.3892ZM53.9948 84.2247L103.902 124.715L149.689 33.7975L53.9948 84.2247Z" fill="#4C526B"/>
  </HSvgIcon>
)
