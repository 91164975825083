import { styled } from '@mui/material/styles'
import { globalStyles } from '../theme'
import { Dialog } from '@mui/material'

const PREFIX = 'HModal'

export const classes = {
  fullScreen: `${PREFIX}-fullScreen`,
  fullScreenWithTopBackdrop: `${PREFIX}-fullScreenWithTopBackdrop`,
  tabletScreen: `${PREFIX}-tabletScreen`,
  root: `${PREFIX}-root`,
  baseHeader: `${PREFIX}-baseHeader`,
  plainHeader: `${PREFIX}-plainHeader`,
  headerWithTitle: `${PREFIX}-headerWithTitle`,
  headerWithExit: `${PREFIX}-headerWithExit`,
  headerWithBackIcon: `${PREFIX}-headerWithBackIcon`,
  fullScreenHeader: `${PREFIX}-fullScreenHeader`,
  plainHeaderWithBack: `${PREFIX}-plainHeaderWithBack`,
  contentContainer: `${PREFIX}-contentContainer`,
  baseFooter: `${PREFIX}-baseFooter`,
  plainFooter: `${PREFIX}-plainFooter`,
  confirmationFooter: `${PREFIX}-confirmationFooter`,
  navigationFooter: `${PREFIX}-navigationFooter`,
  fullScreenFooter: `${PREFIX}-fullScreenFooter`,
  rowFooter: `${PREFIX}-rowFooter`,
  icon: `${PREFIX}-icon`,
}

export const HModalContainer = styled(Dialog)(({ theme }) => ({
  [`& .${classes.fullScreen}`]: {
    boxShadow: globalStyles.shadow5.boxShadow,
    height: '100dvh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  [`& .${classes.fullScreenWithTopBackdrop}`]: {
    boxShadow: globalStyles.shadow5.boxShadow,
    marginTop: 24,
    height: 'calc(100% - 24px)',
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
  [`& .${classes.tabletScreen}`]: {
    zIndex: 10001,
    width: '100%',
    boxShadow: globalStyles.shadow5.boxShadow,
    display: 'flex',
    margin: '0 24px',
    flexDirection: 'column',
    justifyContent: 'space-between',
    maxWidth: '532px',
    [theme.breakpoints.up('md')]: {
      minWidth: '480px',
    },
  },
  [`& .${classes.root}`]: {
    zIndex: 10001,
    width: '100%',
    boxShadow: globalStyles.shadow5.boxShadow,
    display: 'flex',
    margin: '0 24px',
    flexDirection: 'column',
    justifyContent: 'space-between',
    maxWidth: '380px',
    [theme.breakpoints.up('md')]: {
      minWidth: '480px',
    },
  },
  [`& .${classes.baseHeader}`]: {
    height: '72px',
    display: 'flex',
    alignItems: 'center',
    padding: '24px 24px 16px',
    [theme.breakpoints.up('sm')]: {
      padding: '24px 32px 16px',
    },
  },
  [`& .${classes.plainHeader}`]: {
    height: '56px',
    [theme.breakpoints.up('sm')]: {
      height: '48px',
    },
  },
  [`& .${classes.headerWithTitle}`]: {
    justifyContent: 'space-between',
  },
  [`& .${classes.headerWithExit}`]: {
    justifyContent: 'flex-end',
  },
  [`& .${classes.headerWithBackIcon}`]: {
    height: '72px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  [`& .${classes.fullScreenHeader}`]: {
    justifyContent: 'space-between',

    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(8, 10, 6),
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(8, 6, 6),
    },
  },
  [`& .${classes.plainHeaderWithBack}`]: {
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '24px 24px 16px',
    [theme.breakpoints.up('sm')]: {
      padding: '24px 32px 16px',
    },
  },
  [`& .${classes.contentContainer}`]: {
    padding: '16px 24px',
    [theme.breakpoints.up('sm')]: {
      padding: '16px 32px',
    },
  },
  [`& .${classes.baseFooter}`]: {
    height: '80px',
    display: 'flex',
    alignItems: 'center',
    padding: '16px 24px 24px',
    [theme.breakpoints.up('sm')]: {
      padding: '16px 32px 24px',
    },
    [theme.breakpoints.down('md')]: {
      height: 'auto',
      flexDirection: 'column-reverse',
      gap: '16px',
      paddingTop: '8px',
    },
    '& > button': {
      margin: '0 !important', // remove unnecessary margins for action buttons that came from paper
    },
  },
  [`& .${classes.plainFooter}`]: {
    height: '32px',
    [theme.breakpoints.up('md')]: {
      height: '48px',
    },
  },
  [`& .${classes.confirmationFooter}`]: {
    justifyContent: 'center',
  },
  [`& .${classes.navigationFooter}`]: {
    justifyContent: 'space-between',
  },
  [`& .${classes.fullScreenFooter}`]: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    boxShadow: globalStyles.shadow3.boxShadow,
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(6, 10),
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(6, 6),
    },
  },
  [`& .${classes.rowFooter}`]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  [`& .${classes.icon}`]: {
    cursor: 'pointer',
  },
}))
