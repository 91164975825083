// @flow

import { useState, useEffect } from 'react'

export const useIsMounted = () => {
  const [isMounted, setIsMounted] = useState<bool>(false)

  useEffect(() => {
    setIsMounted(true)
  })

  return isMounted
}
