// @flow

import config from '../config'

import type { Cookies } from 'react-cookie'

const identifiers = Object.freeze({
  RECRUITER: 'recruiter',
  DEVICE_TOKEN: 'heyjobs_js_token',
  RECRUITER_DEVICE_TOKEN: 'heyjobs_rp_token',
  TRACKING_TOKEN: 'heyjobs_js_tracking_token',
  RECRUITER_TRACKING_TOKEN: 'heyjobs_rp_tracking_token',
  COOKIE_CONSENT: 'heyjobs_cookie_consent',
  MARKETING_PARAMETERS: 'heyjobs_mkt_params',
  AB_TESTING_COOKIE: 'hj_exp',
  TRACKING_DEDUPLICATION_STORE_PREFIX: 'hj_cpc',
  PREFERRED_COUNTRY: 'hj_country',
  JOBG8_CLICK_ID: 'jg_clickid',
  TALENTCOM_CLICK_ID: 'clickid',
  GOOGLE_SIGNOUT_STATUS: 'g_state',
  GOOGLE_ONE_TAP_LOGIN: 'g_1tap_login',
  IS_AUTHENTICATED: 'heyjobs_tp_is_authenticated',
})

type CookieIdentifier = $Values<typeof identifiers> | string // eslint-disable-line flowtype/require-types-at-top

const getDefaultCookieSettings = () => ({
  path: '/',
  expires: new Date(Date.now() + 31104000000),
  secure: typeof jest === 'undefined' ? config.cookies.secure : true, // enforce https-only depending on the environment
  sameSite: 'lax',
})

const cookieDomainWideOptions = () => ({
  domain: config.cookies.domain,
})

const save = (cookieJar: Cookies) => (identifier: CookieIdentifier, value: string | Object, settings: Object = {}) =>
  cookieJar.set(identifier, value, { ...getDefaultCookieSettings(), ...settings })
const load = (cookieJar: Cookies) => (identifier: CookieIdentifier): ?(string | Object) => cookieJar.get(identifier)

const remove = (cookieJar: Cookies) => (identifier: CookieIdentifier, settings: Object = {}) =>
  cookieJar.remove(identifier, { ...getDefaultCookieSettings(), ...settings })

const loadAll = (cookieJar: Cookies) => (settings: Object = {}) => cookieJar.getAll({ ...getDefaultCookieSettings(), ...settings })

const wide = {
  save: (cookieJar: Cookies) => (identifier: CookieIdentifier, value: string | Object, settings: Object = {}) =>
    save(cookieJar)(identifier, value, { ...cookieDomainWideOptions(), ...settings }),
  remove: (cookieJar: Cookies) => (identifier: CookieIdentifier, settings: Object = {}) =>
    remove(cookieJar)(identifier, { ...cookieDomainWideOptions(), ...settings }),
}

const globalize = (hostname: ?string) => {
  if (hostname) {
    const matches = hostname.match(/([^.]+\.[a-z0-9]+)$/i)
    if (matches && matches.length > 1) {
      return `.${matches[1]}`
    }
    return hostname
  }
  return config.cookies.domain
}

export default {
  save,
  load,
  loadAll,
  remove,
  wide,
  identifiers,
  globalize,
}
