/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react'
import { HTypography } from '../HTypography/HTypography'
import { HLocationAutocompleteOption } from './types'
import styled from '@emotion/styled'
import parse from 'autosuggest-highlight/parse'
import { HIcon } from '../HIcon'
import { Location16 } from '@carbon/icons-react'
import { HBox } from '../HBox'

const AutocompleteOption = styled.li`
  min-height: unset;
  padding: 8px 24px;
`

interface RenderOptionProps {
  props: React.HTMLAttributes<HTMLLIElement>
  option: HLocationAutocompleteOption
  state: { inputValue: string }
}

export function LocationAutocompleteOption ({
  props: { className, ...props },
  option,
  state: { inputValue },
}: RenderOptionProps): JSX.Element {
  const { description, matched_substrings } = option
  const parts = parse(description, matched_substrings?.map(
    (item) => [item.offset, item.offset + item.length]),
  )

  return (
    <AutocompleteOption {...props}>
      <HBox display='flex' alignItems='center' gap={8}>
        <HIcon Icon={Location16} color='surface_gray_80'/>
        <HTypography
          data-test-id='location-autocomplete-option'
          component='span'
          variant='body2'
          weight='regular'
          color='surface_gray_80'
          truncated
          translate='no'
          selectors={[
            {
              selector: 'span.bold',
              props: {
                weight: 'semibold',
              },
            },
          ]}
        >
          {parts.map((part, index) => (
            <React.Fragment key={index}>
              {inputValue && !part.highlight
                ? <span className='bold'>{part.text}</span>
                : <span>{part.text}</span>
              }
            </React.Fragment>
          ))}
        </HTypography>
      </HBox>
    </AutocompleteOption>
  )
}
