import styled from '@emotion/styled'
import React, { PropsWithChildren } from 'react'
import { HBox } from '../HBox'

const StyledBox = styled(HBox)`
  row-gap: 4px;
  .MuiButton-label {
    justify-content: flex-start;
  }
`

export const MenuContent: React.FC<PropsWithChildren<unknown>> =
  ({ children }) => {
    return (
      <StyledBox display='inline-flex' flexDirection='column'>
        {children}
      </StyledBox>
    )
  }
