import React from 'react'
import { MobileMenuProps, LogoProps, MenuItem } from '../HNavBar.types'
import { MenuToggleButton } from '../MenuToggleButton'
import { MobileMenuItems } from '../MobileMenu/MobileMenuItems'
import { HModal } from '../../HModal'
import { HLogo } from '../../HLogo'
import { HBox } from '../../HBox'
import { HRoundIconButton } from '../../HButton'
import { HIcon } from '../../HIcon'
import { Search24 as SearchIcon } from '@carbon/icons-react'

export interface InternalMobileMenuProps extends MobileMenuProps, LogoProps {
  searchButton?: MenuItem
}

export const MobileMenu: React.FC<InternalMobileMenuProps> =
 (props) => {
   const [open, setOpen] = React.useState(false)
   const {
     userProfile,
     logoLink,
     logoClickAction, Link,
     searchButton,
     buttonLabel,
   } = props

   return (
     <>
       <HBox
         display='flex'
         alignItems='center'
         gap={24}
       >
         {searchButton && (
           <HRoundIconButton
             {...searchButton.componentProps}
             color='neutral'
           >
             <HIcon Icon={SearchIcon} size={24} />
           </HRoundIconButton>
         )}
         <MenuToggleButton
           buttonLabel={buttonLabel}
           open={open}
           IndicatorComponent={userProfile?.IndicatorComp}
           onClick={() => {
             setOpen(!open)
           }}
         />
       </HBox>
       <HModal
         open={open}
         closeIcon={true}
         modalVariant='fullScreen'
         headerVariant='none'
         footerVariant='none'
         handleClose={() => setOpen(false)}
         hideBackdrop={true}
       >
         <HBox
           xs={{
             mx: 0,
           }}
           sm={{
             mx: -2,
           }}
           display='flex'
           flexDirection='column'
           height='100%'
         >
           <HBox
             display='flex'
             alignItems='center'
             justifyContent='space-between'
             pb={4}
             mb={8}
             height='fit-content'
           >
             <Link
               href={logoLink}
               onClick={logoClickAction}
               data-test-id='logo-image'
             >
               <HLogo mt={0} variant='pink_yellow' />
             </Link>
             <MenuToggleButton
               open={open}
               onClick={() => setOpen(!open)}
             />
           </HBox>
           <MobileMenuItems {...props} />
         </HBox>
       </HModal>
     </>
   )
 }
