import React from 'react'
import styled from '@emotion/styled'
import { HTypography } from '../../HTypography'
import { MainFooterLinkProps } from './HMainFooter.types'

export const TypographyLink = styled(HTypography)`
  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 12px;
  }

  :hover, :focus {
    text-decoration: underline;
  }
`

export const FooterSEOLink = styled(HTypography)`
  padding-top: 4px;
  padding-bottom: 4px;
`

export const MainFooterLink: React.FC<MainFooterLinkProps> = ({
  component = 'a',
  label,
  componentProps,
}) => {
  return (
    <HTypography
      variant='body2'
      component={component}
      weight='regular'
      color='surface_gray_80'
      target='_blank'
      rel='noreferrer'
      breakWord
      space={{ py: 1 }}
      selectors={
        [{
          selector: ':hover, :focus',
          props: {
            underline: true,
          },
        }]
      }
      {...componentProps}
    >
      {label}
    </HTypography>
  )
}
