// @flow

import { reducer as formReducer } from 'redux-form'
import app from './app'
import user from './user'
import session from './session'
import translations from './translations'
import loginModal from './loginModal'
import linkoutModal from './linkoutModal'
import onboardingSlice from '../store/onboarding/onboardingSlice.ts'
import authStrategySlice from '../store/auth/strategySlice.ts'
import successModal from './successModal'
import navbar from './navbar'
import passwordlessSignIn from './passwordlessSignIn'
import currentJob from './currentJob'
import abTesting from './abTesting'
import applications from './applications'
import bookmarks from './bookmarks'
import company from './company'
import consent from './consent'
import cookieConsent from './cookieConsent'
import counts from './counts'
import documents from './documents'
import jobAlertModal from './jobAlertModal.ts'
import jobAlertSearchQuery from './jobAlertSearchQuery'
import jobApplication from './jobApplication'
import jobSearchPreferencesSlice from '../store/jobSearchPreferences/jobSearchPreferencesSlice.ts'
import jobSearch from './jobSearch'
import jobType from './jobType'
import locality from './locality'
import modal from './modal'
import pageLoadRouteData from './pageLoadRouteData'
import parsedCv from '../store/parsedCv/parsedCvSlice.ts'
import recommendationsModal from './recommendationsModal'
import recommendedJobs from './recommendedJobs'
import request from './request'
import resendConfirmationEmailModal from './resendConfirmationEmailModal'
import routing from './routing'
import seo from './seo'
import serverResponse from './serverResponse'
import snackbar from './snackbar.ts'
import termsAndConditionsModal from './termsAndConditionsModal.ts'
import visitedJobs from './visitedJobs'

export default {
  abTesting,
  app,
  applications,
  bookmarks,
  company,
  consent,
  cookieConsent,
  counts,
  currentJob,
  documents,
  form: formReducer,
  intlData: translations,
  jobAlertModal,
  jobApplication,
  jobSearch,
  jobAlertSearchQuery,
  jobSearchPreferences: jobSearchPreferencesSlice,
  jobType,
  locality,
  loginModal,
  termsAndConditionsModal,
  snackbar,
  modal,
  navbar,
  pageLoadRouteData,
  parsedCv,
  passwordlessSignIn,
  recommendationsModal,
  recommendedJobs,
  request,
  resendConfirmationEmailModal,
  routing,
  seo,
  serverResponse,
  session,
  successModal,
  user,
  visitedJobs,
  linkoutModal,
  onboardingModal: onboardingSlice,
  authStrategy: authStrategySlice,
}
