// @flow

import * as actions from '../constants/actions'
import { FULFILLED, REJECTED, PENDING } from './promiseUtil'

import type { JobAlertSearchQueriesPayload } from '../actions/api/clients/jobAlertSearchQueries'

export type JobAlertSearchQueriesState = {
  errors: $FlowTODO,
  loading?: bool,
  searchQueries?: JobAlertSearchQueriesPayload
}

type Action = { payload: JobAlertSearchQueriesPayload, type: string }

const initialState: JobAlertSearchQueriesState = {
  errors: null,
  loading: false,
}

export default (
  state: JobAlertSearchQueriesState,
  action: Action
): JobAlertSearchQueriesState => {
  state = state || initialState

  switch (action.type) {
    case PENDING(actions.JOB_ALERT_SEARCH_QUERIES_GET):
    case PENDING(actions.JOB_ALERT_SEARCH_QUERIES_CREATE):
    case PENDING(actions.JOB_ALERT_SEARCH_QUERIES_DELETE):
      return {
        ...state,
        loading: true,
        error: null,
      }
    case FULFILLED(actions.JOB_ALERT_SEARCH_QUERIES_GET):
    case FULFILLED(actions.JOB_ALERT_SEARCH_QUERIES_CREATE):
      return {
        ...state,
        searchQueries: action?.payload,
        loading: false,
        error: null,
      }
    case FULFILLED(actions.JOB_ALERT_SEARCH_QUERIES_DELETE):
      return {
        ...state,
        loading: false,
        error: null,
      }
    case REJECTED(actions.JOB_ALERT_SEARCH_QUERIES_GET):
    case REJECTED(actions.JOB_ALERT_SEARCH_QUERIES_CREATE):
    case REJECTED(actions.JOB_ALERT_SEARCH_QUERIES_DELETE):
      return {
        ...state,
        loading: false,
        error: true,
      }
    case FULFILLED(actions.LOGOUT):
      return {
        ...state,
        searchQueries: [],
      }
    default:
      return state
  }
}
