// @flow

import * as actions from '../constants/actions'
import { cleanOnLocationChange } from './utils/cleanOn'
import { FULFILLED, REJECTED } from './promiseUtil'
import { type LocationData } from '../actions/api/types'

export type JobApplicationState = {|
  application: $FlowTODO,
  applicationError: $FlowTODO,
  loading: bool,
  showAlreadyAppliedError: $FlowTODO,
  showLogin: bool,
  userLocation: ?LocationData,
  userLocationHint: ?string
|}

const initialState: JobApplicationState = {
  application: null,
  loading: true,
  showLogin: false,
  applicationError: null,
  showAlreadyAppliedError: {},
  userLocation: null,
  userLocationHint: null,
}

const reducer = (state: JobApplicationState = initialState, action: $FlowTODO) => {
  switch (action.type) {
    case actions.CLEAR_CURRENT_JOB:
      return {
        ...state,
        application: null,
        loading: false,
      }
    case FULFILLED(actions.FETCH_CURRENT_JOB):
      return {
        ...state,
        application: null,
        loading: false,
      }
    case REJECTED(actions.FETCH_CURRENT_JOB):
      return {
        ...state,
        application: null,
        loading: false,
      }
    case actions.SHOW_LOGIN_APPLICATION_FORM:
      return {
        ...state,
        showLogin: true,
      }
    case actions.SHOW_JOB_APPLICATION_ERROR:
      return {
        ...state,
        applicationError: {
          ...action.error,
          notified: false,
        },
      }
    case actions.SHOW_JOB_APPLICATION_ERROR_NOTIFIED:
      return {
        ...state,
        applicationError: {
          ...state.applicationError,
          notified: true,
        },
      }
    case actions.HIDE_JOB_APPLICATION_ERROR:
      return {
        ...state,
        applicationError: null,
      }
    case actions.JOB_APPLICATION_SHOW_USER_LOCATION_HINT:
      return {
        ...state,
        userLocationHint: action.userLocationHint,
        userLocation: null,
      }
    case actions.JOB_APPLICATION_CLEAR_USER_LOCATION_HINT:
      return {
        ...state,
        userLocationHint: null,
      }
    case actions.JOB_APPLICATION_CLEAR_USER_LOCATION:
      return {
        ...state,
        userLocation: null,
      }
    case FULFILLED(actions.JOB_APPLICATION_SET_USER_LOCATION):
      return {
        ...state,
        userLocation: action.payload,
      }
    case REJECTED(actions.JOB_APPLICATION_SET_USER_LOCATION):
      return {
        ...state,
        userLocation: null,
        userLocationHint: 'get_place_geometry_by_address_status_not_ok',
      }
    case actions.SHOW_ALREADY_APPLIED_TO_JOB_ERROR:
      return {
        ...state,
        showAlreadyAppliedError: {
          [action.job]: !state.showAlreadyAppliedError[action.job],
        },
      }
    default:
      return state
  }
}

export default cleanOnLocationChange(reducer)
