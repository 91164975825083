// @flow
import flow from 'lodash/flow'

const encodeNonAscii = (text: string): string => encodeURIComponent(text)
const encodeSpaces = (text: string): string => text.replace(/%20/g, '+')

const urlEncoder = (url: string): string =>
  flow([encodeNonAscii, encodeSpaces])(url)

export default urlEncoder
