// @flow
import { load as loadMarketingParameters } from '../../helpers/marketingParamStore'
import { CLIENT_COMPANY_FLAG, NON_CLIENT_COMPANY_FLAG } from '../../constants/base'
import shouldCPCBeTracked from '../misc/shoudCPCBeTracked'

import type { Job } from '../../types/job'
import type { TrackingSchema } from './types'
import type { ApplicationState } from '../../types/applicationState'
import type { Cookies } from 'react-cookie'

export type CPCType = {
  key: 'cpc_context',
  options: {
    isOut: bool
  }
}

// https://heyjobs.atlassian.net/wiki/spaces/Product/pages/2709061776/TP+-+FAQ+-+CPC+Revenue+Tracking

export const getJobCPC = (job: Job) => {
  return job.cpc
}

export const SCHEMA_NAME = 'iglu:de.heyjobs/cpc_context/jsonschema/1-2-0'

const cpc = (state: ApplicationState, cookieJar: Cookies, options: { isOut: bool }): Array<TrackingSchema> => {
  const { job } = state.currentJob
  const { isOut } = options
  const clientStatus = isOut ? NON_CLIENT_COMPANY_FLAG : CLIENT_COMPANY_FLAG

  if (job) {
    const {
      source,
      medium,
      campaign,
      term,
      content,
    } = loadMarketingParameters(cookieJar)() || {}

    const isTrackable = shouldCPCBeTracked({
      job,
      requiredClientStatus: clientStatus,
    })

    if (isTrackable) {
      const category = job.company.client_status === CLIENT_COMPANY_FLAG ? 'client' : 'partner'
      const cpc = getJobCPC(job)

      return [{
        schema: SCHEMA_NAME,
        data: {
          cpc_value: cpc,
          source,
          medium,
          campaign,
          term,
          content,
          category,
        },
      }]
    }
  }

  return []
}

export default cpc
