import React, { useCallback } from 'react'

import { LocationSelector as BaseLocationSelector, LocationSelectorProps } from 'location-utils'
import { unPrefixRouteName } from '../../../routing/routeNamePrefix'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../../types/applicationState'
import { autoSuggestionLocationClick } from '../SearchAutocomplete/tracking/events'
import { removeTrackingData } from '../SearchAutocomplete/tracking/utils'
import config from '../../../../config'

const LocationSelector: React.FC<Omit<LocationSelectorProps, 'routeName' | 'removeTrackingData' | 'autoSuggestionLocationClick' | 'hookConfig'>> = (props) => {
  const routeName = useSelector((state: ApplicationState) => state.routing.route.name)

  const removeLocationTrackingData = useCallback(() => {
    removeTrackingData('locationSuggestionId')
  }, [])

  return (
    <BaseLocationSelector
      {...props}
      hookConfig={{
        apiKey: config.google.mapsApiKey || '',
      }}
      routeName={unPrefixRouteName(routeName)}
      removeTrackingData={removeLocationTrackingData}
      autoSuggestionLocationClick={autoSuggestionLocationClick}
    />
  )
}

export default LocationSelector
