/* istanbul ignore file */
// @flow

import React from 'react'
import Loadable from 'react-loadable'
import compose from 'lodash/flowRight'
import * as actionCreators from '../../actions/actionCreators'
import * as jobSearchActionCreators from '../../actions/search'
import * as jobActions from '../../actions/job'
import { PageLoader } from '../../components/shared/loader'
import { withUniversalDataLoader, withClientDataLoader } from '../../components/containers/withDataLoader'
import {
  shouldRedirectToSearchPage,
  shouldJobBeAccessible,
  similarJobsOptions,
  redirectToSearchPage,
} from './jobUtils'
import isPreviewMode from '../../helpers/url/isPreviewMode'
import shouldBeOutPage from '../../helpers/redirects/shouldBeOutPage'
import { getRankingOptions } from '../../epics/searchEpic/rankingOptions'
import isBot from '../../components/shared/isBot'
import canonicalRedirect from '../../components/shared/canonicalRedirect'
import localStorageUtils from '../../helpers/localStorageUtils'
import { generateRoutes } from '../../routes'
import logger from '../../../logging'
import {
  VISITED_JOBS,
  MAX_COUNT_VISITED_JOBS,
  INM_SIMILAR_JOBS_EXPERIMENT,
} from '../../constants/base'
import { autoLogoutAndOpenLoginModal } from '../../helpers/autoLogoutAndOpenLoginModal'
import * as queryHelpers from '../../helpers/url/query'
import { GrowthBook } from '@growthbook/growthbook-react'

import type { Dispatch } from '../../store/configureStore'
import type { RouteData } from '../../types/common'
import type { Job } from '../../types/job'
import type { ApplicationState } from '../../types/applicationState'
import datalayer from '../../tracking/clients/datalayer'
import { resolveHighlightedJobExperiment } from '../../helpers/highlightedJobExperiment.ts'

const log = logger('job-details')
const highlightedJobLogger = logger('highlighted-job')

const redirectToOutPage = (replace, job, locale, country, requestId) => {
  const routes = generateRoutes(locale)
  const trackingParams =
    requestId
      ? {
          ri: requestId,
        }
      : {}

  replace(routes.country(country).out({ id: job.id, ...trackingParams }))
}

const handleRedirects = (
  replace: Function,
  dispatch: Dispatch,
  state: ApplicationState,
  job: Job,
  routeData: RouteData,
  highlightJob: boolean = false
): bool => {
  const { locale } = state.intlData
  const { country } = state.locality
  const viewedByBot = !!state.request.isBot

  const shouldRedirectToSearch = shouldRedirectToSearchPage(job, viewedByBot, routeData)
  if (shouldRedirectToSearch) {
    redirectToSearchPage(replace, job, locale, country)
    return true
  }

  if (highlightJob) {
    highlightedJobLogger.dataCapture('before redirect', { datadogContext: { job: job.id } })
    redirectToSearchPage(replace, job, locale, country, true, routeData.location.query)
    return true
  }

  const shouldNotFoundBeDisplayed = shouldJobBeAccessible(job, viewedByBot, routeData)
  if (shouldNotFoundBeDisplayed) {
    dispatch(actionCreators.setResponseStatus(404))
    return true
  }

  const isAuthenticated = state.user.isAuthenticated
  const requestId = queryHelpers.first(routeData.location.query.ri)

  if (shouldBeOutPage({
    type: 'redirect',
    isAuthenticated,
    cpc: job.cpc,
    linkOutType: job.link_out_type,
    clientStatus: job.company.client_status,
    routeData,
  })) {
    redirectToOutPage(replace, job, locale, country, requestId)
    return true
  }

  return false
}

const jobDetailDataLoader = async (
  dispatch: Dispatch,
  state: ApplicationState,
  routeData: RouteData,
  replace: Function,
  cookies: any,
  growthbook: GrowthBook
) => {
  const jobId = routeData.params.id

  const { job: currentJob } = state.currentJob

  const countryCode = state.locality?.country
  // canonical redirect
  const redirectProps = canonicalRedirect(state, routeData)
  if (redirectProps) {
    replace(redirectProps)
    return
  }

  try {
    if (jobId && (!currentJob || currentJob.id !== jobId)) {
      dispatch(actionCreators.clearCurrentJob())
      const { value: job } = await dispatch(jobActions.fetchCurrentJob(jobId, countryCode))
      const highlightJob = resolveHighlightedJobExperiment(growthbook, job, routeData.location.query)
      if (handleRedirects(replace, dispatch, state, job, routeData, highlightJob)) {
        return
      }

      if (!isPreviewMode(routeData)) {
        dispatch(actionCreators.setCacheControl('public, max-age=3600, must-revalidate'))
      }
    }
  } catch (e) {
    // Swallow 404 errors
    if (e.res && e.res.status === 404) {
      dispatch(actionCreators.setResponseStatus(404))
    } else {
      throw e
    }
  }
}

const saveVisitedJob = (job: any, user: $FlowTODO, dispatch: Dispatch) => {
  if (user) {
    dispatch(actionCreators.saveVisitedJob(job.id))
  }

  const visitedJobs = JSON.parse(localStorageUtils.getItem(VISITED_JOBS) || '[]')
  const jobIndex = visitedJobs.findIndex((j) => j.id === job.id)
  if (jobIndex > -1) {
    visitedJobs.splice(jobIndex, 1)
  }
  visitedJobs.unshift({ id: job.id, date: Date.now() })

  localStorageUtils.setItem(
    VISITED_JOBS,
    JSON.stringify(visitedJobs.splice(0, MAX_COUNT_VISITED_JOBS))
  )
}

const jobDetailClientDataLoader = async (
  dispatch: Dispatch,
  state: ApplicationState,
  routeData: RouteData,
  replace: Function,
  _cookies: $FlowTODO,
  growthbook: GrowthBook
) => {
  const jobId = routeData.params.id
  const jobState = state.currentJob
  const job = jobState.job
  const user = state.user.user
  const viewedByBot = isBot(window.navigator.userAgent)

  if (!job) {
    // job couldn't be loaded, abort
    return
  }

  saveVisitedJob(job, user, dispatch)

  if (handleRedirects(replace, dispatch, state, job, routeData)) {
    return
  }

  if (job.company_tracker_url) {
    datalayer({
      event: 'agencyTrackerEvent',
      agency_tracker_url: job.company_tracker_url,
    })
  }

  if (job.company_tracker_data) {
    try {
      datalayer({
        event: 'agencyTrackerScriptEvent',
        agency_tracker_url: job.company_tracker_url,
        agency_tracker_data: JSON.parse(job.company_tracker_data),
      })
    } catch (err) {
      log.error('Failed to parse company tracker data', {
        datadogContext: {
          job_uid: job.id,
          company_tracker_url: job.company_tracker_url,
          company_tracker_data: job.company_tracker_data,
          err,
        },
      }, err)
    }
  }

  if (job?.location?.city && !viewedByBot) {
    try {
      const company = state.company.current
      const experimentVariant = growthbook.evalFeature(INM_SIMILAR_JOBS_EXPERIMENT)?.value

      const options = {
        ...similarJobsOptions({
          job,
          company,
          experimentVariant,
        }),
        ...getRankingOptions(state),
      }
      const { value: similar } = await dispatch(actionCreators.fetchSimilarJobs(options))
      if (user) {
        await dispatch(actionCreators.checkBookmarkJobs(
          similar.map(similarJob => similarJob.job.requisition_id))
        )
      }
    } catch (error) {
      log.error('failed to retrieve similar jobs', { error })
    }
  }

  // preloading state to make the application flow go faster
  if (jobState.applicationDocumentsLoaded) {
    await dispatch(actionCreators.fetchApplicationDocuments())
  }

  if (routeData.location.query.ri) {
    dispatch(jobSearchActionCreators.GCTSTracking.viewJDPGCTSEvent({
      relatedJobs: [{ id: job.id }],
      requestId: routeData.location.query.ri,
      details: {
        jobId,
      },
    }))
  }
}

const LoadableJobDetail = Loadable<$FlowTODO, $FlowTODO>({
  loader: () => import('./JobDetail'),
  loading: () => <PageLoader />,
})

const JobDetailPage = compose(
  withClientDataLoader(jobDetailClientDataLoader),
  withUniversalDataLoader(jobDetailDataLoader),
  autoLogoutAndOpenLoginModal
)(LoadableJobDetail)

export default JobDetailPage
