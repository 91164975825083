import compose from 'lodash/flowRight'
import React from 'react'
import { injectIntl } from 'react-intl'
import MainHeader from '../../layouts/partials/MainHeaderNew'
import { withRouter } from '../../routing/withRouter'
import { ResetPassword } from './ResetPassword'
import { ResetPasswordProps } from './ResetPassword.types'

const ResetPasswordWrapper: React.FC<ResetPasswordProps> = ({ intl, location, replace }) => {
  return (
    <div
      data-test-id='reset-password'
    >
      <MainHeader location={location} />
      <ResetPassword
        location={location}
        intl={intl}
        replace={replace}
      />
    </div>
  )
}

export default compose(injectIntl, withRouter)(ResetPasswordWrapper)
