import { styled } from '@mui/material/styles'
import { globalStyles } from '../theme'
import { Dialog } from '@mui/material'

const PREFIX = 'HDialog'

export const classes = {
  root: `${PREFIX}-root`,
  titleContainer: `${PREFIX}-titleContainer`,
  contentContainer: `${PREFIX}-contentContainer`,
  actionsContainer: `${PREFIX}-actionsContainer`,
}

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.root}`]: {
    boxShadow: globalStyles.shadow5.boxShadow,
    minHeight: '138px',
    width: '296px',
    padding: '24px',
    margin: 0,
    [theme.breakpoints.up('sm')]: {
      width: '452px',
      padding: '24px 32px',
    },
  },
  [`& .${classes.titleContainer}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: '8px',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '16px',
    },
  },
  [`& .${classes.contentContainer}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: '32px',
  },
  [`& .${classes.actionsContainer}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& > *': {
      marginLeft: '16px',
    },
  },
}))
