import clsx from 'clsx'
import React, { PropsWithChildren } from 'react'
import { HButton } from '../HButton/Button'
import { globalStyles } from '../theme'
import styled from '@emotion/styled'

type IColor = 'primary' | 'destructive' | 'neutral'

interface HRoundIconButtonProps {
  color: IColor
  elevated?: boolean
  disabled?: boolean
  className?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
}

export type IHRoundIconButtonProps = HRoundIconButtonProps

const StyledIconButton = styled(HButton)`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  padding: 0;
  &.MuiButton-label {
    display: 'flex';
  }
  &.elevated {
    box-shadow: ${globalStyles.shadow2.boxShadow};
  }
  svg {
    display: block;
  }
`

export const HRoundIconButton: React.FC<
PropsWithChildren<IHRoundIconButtonProps>> = ({
  color,
  disabled,
  elevated,
  children,
  ...others
}) => {
  const className = clsx(
    elevated && 'elevated',
    others.className,
  )
  return (
    <StyledIconButton
      color={color}
      disableElevation
      disabled={disabled}
      variant='text'
      disableTouchRipple
      {...others}
      className={className}
    >
      {children}
    </StyledIconButton>
  )
}
