import React from 'react'
import { useIntl } from 'react-intl'
import { HBox } from 'talent-ui'
import { employmentTypes } from './employment-types'
import { useOnboardingFlow } from '../../common/use-onboarding-flow'
import { EmploymentType } from './employment-type'
import StepContainer from '../../common/step-container'
import { OnboardingStep } from '../../types/flow'

export type EmploymentStepProps = {
  value?: string[]
}

const OnboardingEmploymentType: OnboardingStep<EmploymentStepProps> = ({ value }) => {
  const { saveStepData, setStepState, goNext, onStepClose, stepsProgress, goBack } = useOnboardingFlow()

  const intl = useIntl()

  const [selectedEmploymentTypes, setSelectedEmploymentTypes] = React.useState<string[]>(value || [])

  const manageTypeChecked = (employmentType: string) => {
    setSelectedEmploymentTypes(oldEmploymentTypes => {
      const index = oldEmploymentTypes.indexOf(employmentType)
      if (index > -1) {
        const newEmploymentTypes = [...oldEmploymentTypes]
        newEmploymentTypes.splice(index, 1)
        return newEmploymentTypes
      }

      return [...oldEmploymentTypes, employmentType]
    })
  }

  const onContinue = () => {
    setStepState('employment_type', { value: selectedEmploymentTypes })

    saveStepData({
      step_name: 'employment_type',
      step_action: 'filled',
      step_value: selectedEmploymentTypes
    })

    goNext()
  }

  const onSkip = () => {
    setStepState('employment_type', { value: selectedEmploymentTypes })

    saveStepData({
      step_name: 'employment_type',
      step_action: 'skipped'
    })

    goNext()
  }

  const onGoBack = () => {
    setStepState('employment_type', { value: selectedEmploymentTypes })

    goBack()
  }

  return (
    <StepContainer
      title='onboarding_flow_employment_type_step_title'
      validToContinue={selectedEmploymentTypes.length > 0}
      progress={stepsProgress}
      onClose={() => onStepClose('employment_type')}
      onSkip={onSkip}
      onContinue={onContinue}
      onBack={onGoBack}
    >
      <HBox
        display='flex'
        flexDirection='column'
        gap={8}
        mb={2}
        md={{
          flexDirection: 'row',
          gap: 16,
          maxWidth: '100%',
          flexWrap: 'wrap'
        }}
      >
        {employmentTypes.map((employmentType) => {
          const checked = selectedEmploymentTypes.includes(employmentType)
          return (
            <EmploymentType
              key={employmentType}
              manageTypeChecked={manageTypeChecked}
              checked={checked}
              employmentType={employmentType}
              intl={intl}
            />
          )
        })}
      </HBox>
    </StepContainer>
  )
}

export default OnboardingEmploymentType
