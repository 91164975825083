import React from 'react'

export const SvgPinkAndYellowRoundel: React.FC<{ width?: number }> =
({ width }) =>
  (
    <svg width={width} height='100%' viewBox='0 0 56 56' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='28' cy='28' r='28' fill='#E6AF14' />
      <path fillRule='evenodd' clipRule='evenodd' d='M33.4069 14.0551C33.4069 12.3596 34.6972 11.4384 36.3554 11.4384C38.0147 11.4384 39.304 12.3596 39.304 14.0551C39.304 15.7874 38.0147 16.6725 36.3554 16.6725C34.6972 16.6725 33.4069 15.7874 33.4069 14.0551Z' fill='#C84182' />
      <path fillRule='evenodd' clipRule='evenodd' d='M27.9676 28.0805L25.1413 18.9203H19.2443L25.3412 34.9115C25.3412 34.9115 26.7511 31.6186 27.9676 28.0805Z' fill='white' />
      <path fillRule='evenodd' clipRule='evenodd' d='M33.0664 18.9204L30.7071 27.0934C30.1373 29.434 28.1586 36.7972 25.362 38.9755C24.6813 39.7292 23.4528 39.8615 21.9721 39.5246H21.898V43.948C23.1143 44.2054 24.1837 44.3166 25.1784 44.3166C29.1594 44.3166 30.9289 42.9895 32.5503 38.1609L38.9641 18.9204H33.0664Z' fill='#C84182' />
    </svg>
  )
