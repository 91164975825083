import { Menu24 as MenuIcon } from '@carbon/icons-react'
import React, { MouseEventHandler } from 'react'
import { HBox } from '../HBox'
import { HRoundIconButton } from '../HButton'
import { HIcon } from '../HIcon'
import { CloseIcon } from '../HAssets/CloseIcon'
import { AnimatedBox, useBookmarkAnimation } from './bookmarkAnimationContext'

interface MenuToggleButtonProps {
  open: boolean
  onClick: MouseEventHandler<HTMLButtonElement>
  IndicatorComponent?: React.ElementType
  buttonLabel?: string
}

export const MenuToggleButton: React.FC<MenuToggleButtonProps> = ({
  open,
  onClick,
  IndicatorComponent,
  buttonLabel,
}) => {
  const isBookmarkAnimationActive = useBookmarkAnimation()

  return (
    <>
      <AnimatedBox
        className={isBookmarkAnimationActive ? 'blinking' : ''}
        alignItems='center'
        justifyContent='flex-end'
        position='relative'
        borderRadius='50%'
      >
        <HRoundIconButton
          aria-label={buttonLabel}
          color='neutral'
          data-test-id={`main-menu-trigger-mobile-${open ? 'close' : 'open'}`}
          onClick={onClick}
        >
          <HIcon
            Icon={open ? CloseIcon : MenuIcon}
          />
        </HRoundIconButton>
        {IndicatorComponent && (
          <HBox position='absolute' right={0} top={0}>
            <IndicatorComponent keyType='menuIndicator' keyName='top_menu_bar' strokeWidth={3} />
          </HBox>
        )}
      </AnimatedBox>
    </>
  )
}
