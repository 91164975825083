import React, { useState, useEffect, useRef } from 'react'
import { HAutocomplete, IHTextFieldProps, HIcon } from 'talent-ui'
import { LocationData, NormalizedMapsPrediction } from '../utils/types'
import renderOption from './render-option'
import { Location16, CloseOutline16 } from '@carbon/icons-react'
import { LocationAutoCompleteHook, LocationHookConfig, Overrides } from '../utils/use-normalized-maps-api'

export interface LocationSelectorProps {
  hookConfig: LocationHookConfig
  onChange?: (event: React.ChangeEvent<{}>, value: LocationData) => void
  onInputChange?: (event: React.ChangeEvent<{}>, value: string)=> void
  HInputProps?: IHTextFieldProps
  error?: boolean
  caption?: string
  label?: string
  locale: string
  country?: string
  freeSolo?: boolean
  shouldResetValue?: boolean
  showSecondaryOptionText?: boolean
  overrides?: Overrides
  value?: string,
  filterOptions?: (options: NormalizedMapsPrediction[]) => NormalizedMapsPrediction[]
  routeName: string;
  autoSuggestionLocationClick: (
    suggestion: NormalizedMapsPrediction,
    suggestionList: NormalizedMapsPrediction[],
    sourcePage:string
  ) => void
  removeTrackingData: () => void
}

export const LocationSelectorInput: React.FC<Omit<LocationSelectorProps, 'locale' | 'overrides' | 'hookConfig'> & ReturnType<LocationAutoCompleteHook>> = ({
  onChange,
  HInputProps,
  country,
  onInputChange,
  shouldResetValue,
  showSecondaryOptionText,
  fetchThrottled,
  filterOptions,
  getPlaceDetail,
  routeName,
  autoSuggestionLocationClick,
  removeTrackingData,
  ...others
}) => {
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState<NormalizedMapsPrediction[]>([])

  const isRendered = useRef(true)
  const lastAutocompleteSearch = useRef('')

  useEffect(() => {
    removeTrackingData()
  }, [removeTrackingData])

  const fetchAutocompleteOptions = (value: string) => {
    lastAutocompleteSearch.current = value
    fetchThrottled({
      query: value,
      country_code: country
    }, (results) => {
      if (isRendered.current && results && lastAutocompleteSearch.current === value) {
        setOptions(results)
      }
    })
  }

  useEffect(() => {
    return () => {
      isRendered.current = false
    }
  }, [])

  return (
    <HAutocomplete
      enableSearch
      options={options}
      getOptionLabel={(option) => {
        // The option might not respect the NormalizedMapsPrediction type. This was covered using option.toString() which would warn about returning [object Object].
        if (typeof option === 'string') return option

        return option.structured_formatting?.main_text ?? option.description
      }}
      HInputProps={{
        endAdornment: inputValue?.length > 0
          ? (
            <HIcon
              Icon={CloseOutline16}
              color='primary_50'
              data-test-id='location-selector-clear-btn'
              onClick={() => {
                setInputValue('')
                setOptions([])
                removeTrackingData()
              }}
            />
            )
          : (
            <span />
            ),
        ...HInputProps
      }}
      renderOption={(props, prediction, state) => {
        return renderOption(props, prediction, state, !!showSecondaryOptionText)
      }}
      icon={Location16}
      data-test-id='location'
      onChange={(event, prediction) => {
        if (prediction?.place_id && getPlaceDetail) {
          const hasCallBack = typeof onChange === 'function'

          getPlaceDetail(prediction,
            (result) => {
              if (hasCallBack) {
                onChange(event, result)
                if (shouldResetValue) {
                  setInputValue('')
                  setOptions([])
                }
              }
            }
          )
        }

        if (typeof prediction !== 'string' && !shouldResetValue) {
          autoSuggestionLocationClick(prediction, options, routeName)
        }
      }}
      onInputChange={(event, value, reason) => {
        removeTrackingData()

        if (shouldResetValue && reason === 'reset') {
          return
        }

        setInputValue(value)
        fetchAutocompleteOptions(value)
        onInputChange?.(event, value)
      }}
      value={inputValue}
      inputValue={inputValue}
      isOptionEqualToValue={(option, value) => {
        if (typeof option === 'string') return option === value

        return option.description === value || option.structured_formatting?.main_text === value
      }}
      filterOptions={filterOptions}
      {...others}
    />
  )
}
