// @flow

import React from 'react'

import type { DataLoader } from '../components/containers/withDataLoader'

export type DataLoaderContextValue = {
  current: ?{
    loader: DataLoader
  }
}

const DataLoaderContext = React.createContext<DataLoaderContextValue>({
  current: null,
})

export default DataLoaderContext
