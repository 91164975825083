import React from 'react'
import { HSvgIcon, HSvgIconProps } from '../HSvgIcon'

export const HIconSaveJob: React.FC<HSvgIconProps> = ({ width = '29px', height = '32px', ...props }) => (
  <HSvgIcon
    viewBox="0 0 29 32"
    fill="none"
    width={width}
    height={height}
    {...props}
  >
    <path d="M18 11V28.0625L12.675 25.37L12 25.0325L11.325 25.37L6 28.0625V11H18ZM18 9.5H6C5.60218 9.5 5.22064 9.65804 4.93934 9.93934C4.65804 10.2206 4.5 10.6022 4.5 11V30.5L12 26.75L19.5 30.5V11C19.5 10.6022 19.342 10.2206 19.0607 9.93934C18.7794 9.65804 18.3978 9.5 18 9.5Z" fill="#313545" />
    <circle cx="20" cy="9" r="9" fill="#C84182" />
    <path d="M17.912 13V11.93H19.832V6.97H19.742L18.222 8.77L17.402 8.08L19.132 6.02H21.122V11.93H22.722V13H17.912Z" fill="white" />
  </HSvgIcon>
)
