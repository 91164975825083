import { useMapsApi } from '../..'
import { useCallback } from 'react'
import { NormalizedMapsPrediction, PlacePredictionsRequestOptions } from './types'

export type FetchThrotlled = (config: PlacePredictionsRequestOptions, callback: (predictions: NormalizedMapsPrediction[] | null) => void) => void

export type LocationHookConfig = {
  apiKey?: string
}

export type LocationAutoCompleteHook = (locale: string, hookConfig: LocationHookConfig, overrides?: Overrides) => {
  fetchThrottled: FetchThrotlled
  getPlaceDetail?: (prediction: NormalizedMapsPrediction, callback: (result: any) => void) => void
}

export enum MapsLocationsTypes {
  Cities = '(cities)',
  Regions = '(regions)',
}

export type Overrides = {
  types?: MapsLocationsTypes[],
}

export const useNormalizedMapsAPI: LocationAutoCompleteHook = (locale, hookConfig, overrides) => {
  const mapsApi = useMapsApi({ locale, apiKey: hookConfig.apiKey ?? '' })

  const fetchThrottled = useCallback<FetchThrotlled>((config, callback) => {
    const mapsApiConfig = {
      input: config.query,
      types: overrides?.types ?? [MapsLocationsTypes.Regions],
      componentRestrictions: { country: config.country_code ?? null }
    }

    return mapsApi?.fetchThrottled(mapsApiConfig, (predictions) => {
      callback(predictions ?? null)
    })
  }
  , [mapsApi, overrides?.types])

  return {
    getPlaceDetail: mapsApi?.getPlaceDetail,
    fetchThrottled: fetchThrottled
  }
}
