import type { Cookies } from 'react-cookie'

import type { TrackingSchema } from './types'
import type { ApplicationState } from '../../types/applicationState'

type Options = {
  application_uid: string,
  job_total_questions: string,
  job_uid: string,
  question_rank: string,
  question_uid: string,
}

export type ScreeningQuestionType = {
  key: 'screening_question_submit_context',
  options?: Options
}

export const SCHEMA_NAME = 'iglu:de.heyjobs/screening_question_submit_context/jsonschema/1-0-0'

export default (state: ApplicationState, cookies: Cookies, options?: Options): Array<TrackingSchema> => {
  if (options?.question_uid) {
    return [{
      schema: SCHEMA_NAME,
      data: options,
    }]
  }
  return []
}
