// @flow

import pickBy from 'lodash/pickBy'
import isNumber from 'lodash/isNumber'
import isEmpty from 'lodash/isEmpty'
import isBoolean from 'lodash/isBoolean'
import { DEFAULT_SEARCH_PAGE_SIZE } from '../../constants/base'

import type { JobSearchRequestOptions } from '../../actions/api/clients/search'
import type { JobSearchActionOptions } from './types'
import * as sessionStorage from '../../helpers/sessionStorageUtils.ts'

const transformToSearchAPIOptions = (options: JobSearchActionOptions): JobSearchRequestOptions => {
  const {
    page,
    workingHours,
    employmentTypes,
    companies,
    subdomain,
    location,
    distance,
    keyword,
    country,
    spellcheck,
    orderBy,
    searchMode,
    customRankingInfo,
    easyApply,
    salary,
    experimentVariant,
  } = options
  const isSortedByDate = orderBy === 'date'

  return pickBy({
    location: location && location.trim(),
    query: keyword && keyword.trim(),
    page,
    distance_in_miles: (distance && distance !== 'none' && parseInt(distance) / 1.609) || null,
    working_hours_types: workingHours,
    company_display_names: companies,
    employment_types: employmentTypes,
    allow_easy_apply: easyApply === 'true' ? 'true' : undefined,
    base_compensation: salary === 'true',
    country_code: country,
    region_code: country,
    company_subdomain: subdomain,
    page_size: DEFAULT_SEARCH_PAGE_SIZE,
    offset: page === 1 ? 0 : (page - 1) * DEFAULT_SEARCH_PAGE_SIZE,
    disable_spell_check: spellcheck === 'false',
    order_by: isSortedByDate ? 'postingPublishTime desc' : orderBy,
    search_mode: searchMode,
    custom_ranking_info: customRankingInfo,
    experiment_variant: experimentVariant,
    m_search_mode: sessionStorage.getItem(sessionStorage.identifiers.marketingSearchMode),
  },
  // isEmpty(1) is true
  (x) => (isNumber(x) ? x !== undefined : !isEmpty(x) || isBoolean(x))
  )
}

export default transformToSearchAPIOptions
