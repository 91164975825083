import React, { createContext, createElement, useCallback, useEffect, useMemo } from 'react'
import { HModalWrapper } from 'talent-ui'

import snakeCase from 'lodash/snakeCase'
import { Theme, useMediaQuery } from '@mui/material'
import { trackCompletedStep, trackSkippedStep } from './common/tracking'

import { ONBOARDING_FLOW_EXIT_EVENT, WELCOME_SCREEN_SHOWN_EVENT } from '../tracking/events'
import { TOnboardingContext, OnboardingProps } from './types/types'
import { SaveStepParams } from './types/api-types'
import { ContainerState, OnboardingFlowComponents, OnboardingFlowContextProps, Steps, StepsOrder, StepState } from './types/flow'
import { getStepsProgress } from './utils/get-steps-progress'
import { useOnboarding } from './common/use-onboarding-flow'

export const FlowContext = createContext<OnboardingFlowContextProps>({} as never)

export const FlowProvider = FlowContext.Provider

export const OnboardingContext = createContext<TOnboardingContext>({} as never)

const OnboardingFlow: React.FC<OnboardingProps> = ({
  onClickClose,
  logStringCustomAttribute
}) => {
  useEffect(() => {
    logStringCustomAttribute('custom_onboarding_shown', 'true')
  }, [logStringCustomAttribute])

  const {
    signupSource,
    stepsData,
    initialStepIndex,
    initialTotalStepCount,
    isReEntry,
    saveOnBoardingJobTitles,
    saveOnboardingStep,
    fireSnowplowEvent,
    cleanSeeOnboardingModal
  } = useOnboarding()

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  const [state, setState] = React.useState<ContainerState>({
    step: StepsOrder[initialStepIndex],
    stepIndex: initialStepIndex,
    stepsProgress: getStepsProgress(initialStepIndex, StepsOrder.length),
    stepState: stepsData || {},
    history: [StepsOrder[initialStepIndex]],
    stepsSeen: 1,
    totalStepCount: initialTotalStepCount
  })

  const totalStepCount = state.totalStepCount

  const isFirstStep = state.step === StepsOrder[0]

  const onRestartFlow = useCallback(() => {
    setState(prev => ({
      step: StepsOrder[0],
      stepIndex: 0,
      stepsProgress: 0,
      stepState: prev.stepState,
      history: [StepsOrder[0]],
      stepsSeen: 1,
      totalStepCount: StepsOrder.length
    }))
  }, [])

  const goNext = useCallback((): void => {
    setState((prev) => {
      const index = prev.stepIndex + 1
      const step = StepsOrder[index]

      return {
        step,
        stepIndex: index,
        stepsProgress: getStepsProgress(index, StepsOrder.length),
        stepState: prev.stepState,
        history: [...prev.history, step],
        stepsSeen: prev.stepsSeen + 1,
        totalStepCount: prev.totalStepCount
      }
    })
  }, [])

  const goBack = useCallback((): void => {
    setState((prev) => {
      const index = prev.stepIndex - 1

      return {
        step: StepsOrder[index],
        stepIndex: index,
        stepsProgress: getStepsProgress(index, StepsOrder.length),
        stepState: prev.stepState,
        history: prev.history.slice(0, -1),
        stepsSeen: prev.stepsSeen - 1,
        totalStepCount: prev.totalStepCount
      }
    })
  }, [])

  const setStepState = useCallback(<K extends keyof StepState>(step: K, value: StepState[K]): void => {
    setState((prev) => {
      return {
        ...prev,
        stepState: { ...prev.stepState, [step]: value }
      }
    })
  }, [])

  const saveStepData = useCallback((params: SaveStepParams): void => {
    if (params.step_action === 'filled') {
      trackCompletedStep(
        {
          state,
          totalStepCount,
          isReEntry,
          workingHours: params.step_name === 'working_hours_type'
            ? params.step_value
            : undefined,
          fireSnowplowEvent
        })
    } else if (params.step_action === 'skipped') {
      trackSkippedStep(state, totalStepCount, fireSnowplowEvent)
    }
    if (params.step_name === 'job_title' && params.step_action === 'filled') {
      saveOnBoardingJobTitles(params)

      return
    }

    saveOnboardingStep(params)
  }, [state, totalStepCount, isReEntry, saveOnboardingStep, saveOnBoardingJobTitles, fireSnowplowEvent])

  const onStepClose = useCallback((stepName: Steps) => {
    if (stepName !== 'success') {
      saveStepData({
        step_name: stepName,
        step_action: 'exited'
      })
    }

    fireSnowplowEvent(ONBOARDING_FLOW_EXIT_EVENT, {
      action: isFirstStep && isMobile ? 'swipe' : 'button_click',
      total_steps_count: totalStepCount,
      current_step: state.stepsSeen,
      flow_step_name: snakeCase(state.step)
    })

    onClickClose()
  }, [saveStepData, fireSnowplowEvent, onClickClose, isFirstStep, isMobile, state.step, state.stepsSeen, totalStepCount])

  useEffect(() => {
    if (!isReEntry) {
      fireSnowplowEvent(WELCOME_SCREEN_SHOWN_EVENT, {
        sign_up_source: signupSource
      })
    }
  }, [isReEntry, fireSnowplowEvent, signupSource])

  useEffect(() => {
    const trackExitEventAndStoreState = () => {
      fireSnowplowEvent(ONBOARDING_FLOW_EXIT_EVENT, {
        action: 'tab_closed',
        total_steps_count: totalStepCount,
        current_step: state.stepsSeen,
        flow_step_name: snakeCase(state.step)
      })

      cleanSeeOnboardingModal()

      if (state.step !== 'success') {
        saveStepData({ step_name: state.step, step_action: 'exited' })
      }
    }

    window.addEventListener('beforeunload', trackExitEventAndStoreState)

    return () => window.removeEventListener('beforeunload', trackExitEventAndStoreState)
  }, [state.history.length, state.step, state.stepsSeen, totalStepCount, saveStepData, cleanSeeOnboardingModal, fireSnowplowEvent])

  const stepProps = state.stepState[state.step]

  const baseProps = useMemo(() => ({
    userSignupSource: signupSource,
    totalStepCount
  }), [signupSource, totalStepCount])

  const flowContext = useMemo(() => ({
    ...state,
    goBack,
    goNext,
    setStepState,
    saveStepData,
    onStepClose,
    closeOnBoardingWithNoTracking: onClickClose,
    baseProps,
    onRestartFlow
  }), [
    state,
    goBack,
    goNext,
    setStepState,
    saveStepData,
    onStepClose,
    onClickClose,
    onRestartFlow,
    baseProps
  ])

  return (
    <HModalWrapper
      open
      modalVariant={!isFirstStep ? 'fullScreen' : 'default'}
      title=''
      data-test-id='onboarding-modal'
      onClose={() => onStepClose(state.step)}
      onOpen={() => { }}
      forceModal={!isFirstStep}
    >
      <FlowProvider
        value={flowContext}
      >
        {createElement(OnboardingFlowComponents[state.step].Component, stepProps)}
      </FlowProvider>
    </HModalWrapper>
  )
}

export default OnboardingFlow
