import React from 'react'

import {
  HAccordion,
  HAccordionSummary,
  HAccordionDetails,
} from './HAccordion'
import { AccordionProps } from '@mui/material/Accordion'

interface Section {
  name: string
  headerText: string
  details: React.ReactNode
  handleExpand?: () => void
}

interface HControlledAccordionProps {
  sections: Section[]
  'data-test-id'?: string
}

interface AccordionSectionProps {
  expanded: boolean
  onChange: AccordionProps['onChange']
  section: Section
  'data-test-id': string
}

const AccordionSection: React.FC<AccordionSectionProps> = ({
  expanded,
  onChange,
  section,
  ...props
}) => {
  return (
    <HAccordion
      expanded={expanded}
      onChange={onChange}
      data-test-id={props['data-test-id']}
    >
      <HAccordionSummary
        sectionName={section.name}
        data-test-id={`${props['data-test-id']}-header`}
      >
        {section.headerText}
      </HAccordionSummary>
      <HAccordionDetails
        data-test-id={`${props['data-test-id']}-details`}
      >
        {section.details}
      </HAccordionDetails>
    </HAccordion>
  )
}

export const HControlledAccordion: React.FC<HControlledAccordionProps> = ({
  sections,
  ...props
}) => {
  const [expandedIndex, setExpandedIndex] = React.useState<number>(-1)
  const dataTestIdPrefix = props['data-test-id'] || 'controlled-accordion'

  // Accordion's `onChange` gives us an `isExpanding` value
  const handleChange = (index: number): AccordionProps['onChange'] => (
    event,
    isExpanding,
  ) => {
    setExpandedIndex(isExpanding ? index : -1)
    isExpanding && sections[index].handleExpand?.()
  }

  return (
    <>
      {sections.map((section, index) => (
        <AccordionSection
          key={`${section.name}-${index}`}
          expanded={expandedIndex === index}
          onChange={handleChange(index)}
          section={section}
          data-test-id={`${dataTestIdPrefix}-${section.name}`}
        />
      ))}
    </>
  )
}
