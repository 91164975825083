import { PlacesService } from 'google.maps'
import { LocationData, NormalizedMapsPrediction } from '../utils/types'

export const getPlaceDetail = (
  placesApi: PlacesService,
  prediction: NormalizedMapsPrediction,
  sessionToken: google.maps.places.AutocompleteSessionToken | undefined,
  callback: (result: LocationData) => void
) => {
  placesApi.getDetails({
    placeId: prediction.place_id!,
    sessionToken,
    fields: ['address_components', 'geometry.location']
  }, (addressData) => {
    // It can't be the case that we have no place_id and onChange function,
    // because we only accept onChange function when it's not an experiment selector and it uses the maps api
    const lat = addressData?.geometry?.location?.lat()
    const lng = addressData?.geometry?.location?.lng()
    const city = addressData?.address_components?.find(item => item.types.includes('locality'))?.long_name
    const region = addressData?.address_components?.find(item => item.types.includes('administrative_area_level_1'))?.long_name
    const countryComponent = addressData?.address_components?.find(item => item.types.includes('country'))

    const result: LocationData = {
      address: prediction.structured_formatting.main_text,
      city: city || '',
      country: countryComponent?.long_name || '',
      country_code: countryComponent?.short_name || '',
      region,
      lat: lat || 0,
      lng: lng || 0
    }
    callback(result)
  })
}
