import React, { useCallback } from 'react'
import { FetchLocationAutocompleteAPICall, useInternalEngineLocationAutocomplete } from '../utils/use-internal-engine-location-autocomplete'
import { LocationSelectorInput, LocationSelectorProps } from './location-selector-input'
import { FetchThrotlled } from '../utils/use-normalized-maps-api'

const MIN_LOCATION_LENGTH = 2

export const InternalLocationSelector: React.FC<Omit<LocationSelectorProps, 'onChange' | 'hookConfig'> & {fetchLocationAutocompleteAPICall: FetchLocationAutocompleteAPICall}> = ({
  fetchLocationAutocompleteAPICall,
  ...others
}) => {
  const { fetchThrottled } = useInternalEngineLocationAutocomplete(fetchLocationAutocompleteAPICall)

  const wrappedFetchThrottled = useCallback<FetchThrotlled>((config, callback) => {
    if (config.query.length < MIN_LOCATION_LENGTH) {
      return
    }

    return fetchThrottled(config, callback)
  }, [fetchThrottled])

  return (
    <LocationSelectorInput
      {...others}
      fetchThrottled={wrappedFetchThrottled}
      filterOptions={(options) => options}
      showSecondaryOptionText
    />
  )
}
