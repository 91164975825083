// @flow
// this is the single spot you need to change to change the global font-family for the site
// it is used here as a style applied to all elements, and also in the material-ui theme
import { createGlobalStyle } from 'styled-components'
import { $primaryFont } from '../shared/dependencies/variables'

const GlobalStyle = createGlobalStyle`
  /* start global style reset --- */
  div,p,a,span,em,strong,img,h1,h2,h3,h4,h5,h6,ul,ol,li,dl,dt,dd,table,td,tr,form,fieldset,label,select,summary,input,textarea {
    padding: 0;
    margin: 0;
    outline: none;
    list-style: none;
  }
  * {
    font-family: ${$primaryFont};
    user-select: text;
    box-sizing: border-box;
  }
  html,body                      { -ms-overflow-style: -ms-autohiding-scrollbar; height: 100%; font-size: 100%; }
  body                           { padding: 0; margin: 0; font-size: 14px; background: #ffffff; color: #000; scroll-behavior: smooth; }
  select, input, textarea        { font-size: 12px; vertical-align: middle; }
  textarea                       { vertical-align: top; }
  :focus                         { outline: none; }
  input[type=submit]             { cursor: pointer; }
  input[type=button], button     { cursor: pointer; }
  img, fieldset                  { border: 0; }
  table                          { border-collapse: collapse; border-spacing: 0; }
  a:link, a:visited              { text-decoration: none; }
  a:hover                        { text-decoration: none; }
  /* --- end global style reset */

  /* --- start global util styles */

  .unselectable, .unselectable * {
    user-select: none;
  }
  .clearfix:after {
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }

  /* prevent scrolling underneath overlays */
  body.with-overlay {
    overflow: hidden;
  }

  /* ~~~~~~~~ end of section of styles that *should* be in this file, the rest should be refactored out ~~~~~~~~~~~ */


  /* continue button component */
  @media screen and (min-width: 540px) {
    .continue-button:hover {
      background: #d24e53;
    }

    .continue-button-secondary:hover {
      background: #f6f6f6;
    }
  }

  /* the rest of these are all nav bar related */
  .selectable * {
    user-select: text;
  }

  .admin-logo .logo-image {
    vertical-align: middle;
  }

  html.non-touch-device .hovered-link:hover {
    background: rgba(0, 0, 0, 0.03);
  }
`

export default GlobalStyle
