// @flow
import React from 'react'
import debounce from 'lodash/debounce'
import { fullHexToRgba } from '../../../functions_/shared/utils'
import { $xSmall, $medium, $xLarge } from './variables'
import { DEVICES } from '../../../constants'

export const $largerThan = ($breakPoint: number): string =>
  `@media (min-width: ${$breakPoint}px)`

export const $smallerThan = ($breakPoint: number): string =>
  `@media (max-width: ${$breakPoint}px)`

export const $betweenWidth = ($minGridBreakPoint: number, $maxGridBreakPoint: number): string =>
  `@media (min-width: ${$minGridBreakPoint}px) and (max-width :${$maxGridBreakPoint}px)`

export const $skeletonBackground = ($baseColor: string, $shineColor: string, $shinePosition?: number) =>
  `linear-gradient(90deg, ${$baseColor} 0px, ${fullHexToRgba(
    $baseColor,
    0.78
  )} 40px, ${$baseColor} 80px)`

export const $IEOnly = '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)'

export const getWindowSize = (interval: number = 500) => {
  const [windowSize, setWindowSize] = React.useState([0, 0])

  React.useLayoutEffect(() => {
    function updateWindowSize () {
      setWindowSize([window.innerWidth, window.innerHeight])
    }
    const debouncedWindowSizeUpdate = debounce(updateWindowSize, interval, { trailing: true })

    window.addEventListener('resize', debouncedWindowSizeUpdate)
    updateWindowSize()
    return () => window.removeEventListener('resize', debouncedWindowSizeUpdate)
  }, [])

  // returns as [width, height]
  return windowSize
}

function deviceFinder (minWidth, maxWidth) {
  const [width] = getWindowSize()
  return width >= minWidth && width < maxWidth
}

export const isMobile = () => deviceFinder(0, $xSmall)
export const isTablet = () => deviceFinder($xSmall, $medium)
export const isLaptop = () => deviceFinder($medium, $xLarge)
export const isLargeDisplay = () => deviceFinder($xLarge, Number.MAX_SAFE_INTEGER)

export const getCurrentDeviceType = () => {
  /*
   * > Only call Hooks at the top level. Don’t call Hooks inside loops, conditions, or nested functions.
   * https://reactjs.org/docs/hooks-overview.html#rules-of-hooks
   */
  const _isMobile = isMobile()
  const _isTablet = isTablet()
  const _isLaptop = isLaptop()
  const _isLargeDisplay = isLargeDisplay()

  if (_isMobile) {
    return DEVICES.MOBILE
  }

  if (_isTablet) {
    return DEVICES.TABLET
  }

  if (_isLaptop) {
    return DEVICES.LAPTOP
  }

  if (_isLargeDisplay) {
    return DEVICES.LARGE_DISPLAY
  }
}
