// @flow

import * as React from 'react'
import Loadable from 'react-loadable'
import { PageLoader } from '../../shared/loader'
import {
  withClientDataLoader,
} from '../../containers/withDataLoader'
import compose from 'lodash/flowRight'
import { redirectIfUnauthenticated } from '../../../routing/redirectIfUnauthenticated'

import { applicationDetailDataLoader } from '../../../pages/ApplicationDetails'

const LoadableApplications = Loadable<{}, {}>({
  loader: () => import('./ApplicationStatusUpdate'),
  loading: () => <PageLoader />,
})

const ApplicationStatusUpdatePage = compose(
  withClientDataLoader(applicationDetailDataLoader),
  redirectIfUnauthenticated
)(LoadableApplications)
ApplicationStatusUpdatePage.displayName = 'ApplicationStatusUpdatePage'

export default ApplicationStatusUpdatePage
