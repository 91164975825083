import React from 'react'
import { Redirect, RedirectProps } from 'react-router-dom'

export const RedirectTo = ({ to }: RedirectProps) => {
  return (
    <Redirect
      exact
      to={to}
    />
  )
}

export default RedirectTo
