// @flow

import * as actions from '../constants/actions'
import config from '../../config'

import type { Country } from '../types/common'

export type LocalityState = {
  country: Country
}

const initialState: LocalityState = {
  country: config.countries.default,
}

export default (state: LocalityState = initialState, action: $FlowTODO) => {
  switch (action.type) {
    case actions.SET_COUNTRY:
      return {
        country: action.country || state.country,
      }
    default:
      return state
  }
}
