import styled from '@emotion/styled'
import { HTypography } from '../HTypography'
import { COLORS, globalStyles } from '../theme'
import { SelectIconString } from './selectedIcon'
import React from 'react'

export const Caption = styled(HTypography)`
  margin-top: 6px;
`

export const CheckboxContainer = styled.div`
  display: inline-flex;
  position: relative;
  input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    :checked + .box-container .box {
      background-color: ${COLORS.primary_50};
      background-image: ${SelectIconString};
      background-repeat: no-repeat;
      background-position: center;
      border-color: ${COLORS.primary_50}
    }
    :focus-visible + .box-container {
      outline: 1px solid ${COLORS.primary_25};
    }
    :disabled + .box-container {
      opacity: 0.4;
    }
    :active + .box-container, :hover + .box-container {
      .pressed {
        box-shadow: 0px 0px 0px 8px ${COLORS.primary_05};
        border-radius: 50%;
        background-color: ${COLORS.primary_05};
      }
    }
    :active + .box-container.error, :hover + .box-container.error {
      .pressed {
        box-shadow: 0px 0px 0px 8px ${COLORS.support_error_05};
        border-radius: 50%;
        background-color: ${COLORS.support_error_05};
      }
    }
  }
  .pressed {
    margin-top: 1px;
    height: 16px;
    .box {
      border: 1px solid ${COLORS.surface_gray_20};
      border-radius: 4px;
      box-shadow: ${globalStyles.shadow1.boxShadow};
      width: 16px;
      height: 16px;
      background-color: white;
    }
  }
  .box-container.error .box {
    border-color: ${COLORS.support_error_50};
  }
  .box-container {
    display: flex;
  }
  .text-container {
    margin-left: 12px;
  }
`
