import * as actions from '../constants/actions'
import { cleanOnLocationChange } from './utils/cleanOn'

export type SnackBarState = {
  termsAndConditions: boolean;
  login: boolean;
  criticalAuthError: boolean;
};

const initialState: SnackBarState = {
  termsAndConditions: false,
  login: false,
  criticalAuthError: false,
}

const reducer = (state: SnackBarState = initialState, action: any) => {
  switch (action.type) {
    case actions.SHOW_SNACK_BAR:
      return {
        ...state,
        [action.payload]: true,
      }
    case actions.HIDE_SNACK_BAR:
      return {
        ...state,
        [action.payload]: false,
      }
    default:
      return state
  }
}

export default cleanOnLocationChange(reducer)
