// @flow

import * as utils from './utils/reduxUtils'
import * as actions from '../constants/actions'

export const setCookieConsentService = utils.create(
  actions.SET_COOKIE_CONSENT_SERVICE,
  'service'
)

export const userExplicitConsent = utils.create(
  actions.USER_EXPLICIT_CONSENT
)
