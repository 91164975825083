import React from 'react'
import { useTranslate } from '../../../hooks/useTranslate'
import { HBox, HTypography, COLORS } from 'talent-ui'

type FileDropZoneProps = {
  lokaliseKey: string
  dataTestId?: string
}

const FileDropZone = ({ lokaliseKey, dataTestId }: FileDropZoneProps) => {
  const translate = useTranslate()

  return (
    <HBox
      pt={6}
      pb={6}
      pl={2}
      style={{
        border: `1px solid ${COLORS.primary_50}`,
        borderRadius: '4px',
        backgroundColor: COLORS.primary_15,
      }}
      data-test-id={dataTestId}
    >
      <HTypography italic>{translate(lokaliseKey)}</HTypography>
    </HBox>
  )
}

export default FileDropZone
