import React, { ReactElement } from 'react'
import { HBox } from 'talent-ui'
import { useAppSelector } from '../dependencies'
import {
  CompleteYourProfileIndicator as SharedCompleteYourProfileIndicator,
  ValidMenuIndicatorPositions,
  ProfileCompletionIndicatorProps,
} from 'user-profile-data'

type ProfileCompletionMenuIndicatorProps = {
  top: number
  right: number
  strokeWidth?: number
  children: React.ReactNode
  shouldShowIndicator?: boolean
  position: ValidMenuIndicatorPositions
}

export const CompleteYourProfileIndicator = ({ strokeWidth, ...hookProps }: ProfileCompletionIndicatorProps): ReactElement => {
  const profileCompletionStatus = useAppSelector(state => state.user.user?.profile_completion_status)

  return (
    <SharedCompleteYourProfileIndicator
      strokeWidth={strokeWidth}
      profileCompletionStatus={profileCompletionStatus}
      {...hookProps}
    />
  )
}

export const CompleteYourProfileMenuIndicatorWrapper = ({
  top,
  right,
  strokeWidth,
  children,
  position,
  shouldShowIndicator = true,
}: ProfileCompletionMenuIndicatorProps): ReactElement => {
  const currentPath = useAppSelector((state) => state.routing.clientRoute)
  const isProfilePath = currentPath?.includes('/profile')

  return (
    <HBox
      position='relative'
    >
      {children}
      <HBox
        position='absolute'
        top={top}
        right={right}
      >
        {(shouldShowIndicator && !isProfilePath) &&
          <CompleteYourProfileIndicator
            keyType='menuIndicator'
            keyName={position}
            strokeWidth={strokeWidth}
          />}
      </HBox>
    </HBox>
  )
}
