// @flow
import * as events from '../tracking/eventNames' // eslint-disable-line no-unused-vars
import type { AbTestingDefinition } from './abTestingTypes'

export const ORIGINAL_VARIANT = 'original'

export const QUICK_SEARCH_VARIANT = 'QUICK_SEARCH_VARIANT'
export const QUICK_SEARCH_EXPERIMENT_2 = 'QUICK_SEARCH_EXPERIMENT_2'

export const DUMMY_EXPERIMENT = 'DUMMY_EXPERIMENT'
export const VARIED_VARIANT = 'varied'

export const VISITED_JOBS_EXPERIMENT = 'VISITED_JOBS_EXPERIMENT'
export const VISITED_JOBS_VARIANT = 'VISITED_JOBS_VARIANT'

// NOTE: (experimentIndicativeIndex) shouldn't change as long as
// the experiment is running.

/** Example
 * {
 *    experimentName: MOBILE_DESCRIPTION_EXPERIMENT,
 *    experimentID: 'MSE',
 *    experimentIndicativeIndex: 1,
 *    experimentVariants: [
 *      { name: ORIGINAL_VARIANT, weight: 50 },
 *      { name: MOBILE_DESCRIPTION_VARIANT, weight: 50 }
 *    ],
 *    experimentConversionEvents: []
 *  }
 */

const dummyExperiment = {
  experimentName: DUMMY_EXPERIMENT,
  experimentID: 'DE',
  experimentIndicativeIndex: 3,
  experimentVariants: [
    { name: ORIGINAL_VARIANT, weight: 50 },
    { name: VARIED_VARIANT, weight: 50 },
  ],
  experimentConversionEvents: [],
}

const visitedJobsExperiment = {
  experimentName: VISITED_JOBS_EXPERIMENT,
  experimentID: 'VJE',
  experimentIndicativeIndex: 4,
  experimentVariants: [
    { name: ORIGINAL_VARIANT, weight: 50 },
    { name: VISITED_JOBS_VARIANT, weight: 50 },
  ],
  experimentConversionEvents: [],
}

const abTestingDefinitions: Array<AbTestingDefinition> = [
  dummyExperiment,
  visitedJobsExperiment,
]

export default abTestingDefinitions
