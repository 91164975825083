// @flow

import * as React from 'react'
import Loadable from 'react-loadable'
import type { RouteData } from '../../types/common'
import type { Dispatch } from '../../store/configureStore'
import type { ApplicationState } from '../../types/applicationState'
import { PageLoader } from '../../components/shared/loader'
import { withClientDataLoader } from '../../components/containers/withDataLoader'
import { getBookmarks, clearBookmarks } from '../../actions/actionCreators'
import { DEFAULT_SEARCH_PAGE_SIZE } from '../../constants/base'
import { compose } from 'redux'
import { redirectIfUnauthenticated } from '../../routing/redirectIfUnauthenticated'

export const dataLoader = async (
  dispatch: Dispatch,
  state: ApplicationState,
  routeData: RouteData,
  replace: Function
) => {
  const isAuthenticated = state.user.isAuthenticated
  const country = state.locality.country
  const currentPage = parseInt(routeData.location.query.page) || 1
  const bookmarkedOptions = {
    country_code: country,
    region_code: country,
    offset: (currentPage - 1) * DEFAULT_SEARCH_PAGE_SIZE,
    page: currentPage,
    page_size: DEFAULT_SEARCH_PAGE_SIZE,
  }

  if (isAuthenticated) {
    await dispatch(getBookmarks(bookmarkedOptions))
  } else {
    dispatch(clearBookmarks())
  }
}

const loadableBookmarked = Loadable<$FlowTODO, $FlowTODO>({
  loader: () => import('./Bookmarked'),
  loading: () => <PageLoader />,
})

const BookmarkedPage = compose(
  withClientDataLoader(dataLoader),
  redirectIfUnauthenticated
)(loadableBookmarked)

export default BookmarkedPage
