// @flow
import React from 'react'
import { injectIntl, FormattedMessage, type IntlShape } from 'react-intl'
import styled from 'styled-components'
import highFiveImage from 'assets/images/general/high-five.svg'
import dataGuardShield from 'assets/images/general/data-guard-shield.png'
import {
  $small,
  $medium,
  $sizes,
  $primaryBorderRadius,
} from '../../components/shared/dependencies/variables'
import { $largerThan } from '../../components/shared/dependencies/mixins'
import FooterContentWrapper from './FooterContentWrapper'

type BadgesProps = {
  byeJobBoardsVariant?: bool
}

type BadgeProps = {
  description: string,
  imageAlt: string,
  imageHref?: string,
  imageSrc: string,
  intl: IntlShape,
  title?: string
}

const BadgeContainer = styled.div`
  padding: ${$sizes.lg};
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;

  border-radius: ${$primaryBorderRadius};
  border: 1px solid #DADDE8;

  background-color: #FFFFFF;
  z-index: 2;

  ${$largerThan($small)} {
    padding: ${$sizes.xl};

  }

  ${$largerThan($medium)} {
    width: auto;
    margin-bottom: 0;
    flex-grow: 0;
    flex-basis: 61%;

    &:last-child {
      flex-basis: 36%;
    }
  }
`

const BadgeImage = styled.img`
  line-height: 1;

  display: block;
  flex-basis: 75px;
  flex-grow: 0;
  height: 75px;
  width: 75px;
  margin-right: ${$sizes.xl};

  ${$largerThan($small)} {
    flex-basis: 95px;
    height: 95px;
    width: 95px;
  }
`

const BadgeText = styled.div`
  h4 {
    font-size: 14px;
    margin: 0 0 8px;
  }

  p {
    font-size: 12px;
    margin: 0;
  }

  ${$largerThan($small)} {
    h4 {
      font-size: 16px;
    }

    p {
      font-size: 14px;
    }
  }
`

const Badge = injectIntl(({ imageSrc, imageAlt, imageHref, title, description, intl }: BadgeProps) => {
  const BadgeImageComp = () =>
    <BadgeImage
      src={imageSrc}
      alt={intl.formatMessage({ id: imageAlt })}
      height='95px'
      width='95px'
    />

  const BadgeWithLink = () => (
    <a
      href={imageHref}
      target='_blank'
      rel='noreferrer'
    >
      <BadgeImageComp />
    </a>
  )
  return (
    <BadgeContainer>
      {imageHref ? <BadgeWithLink /> : <BadgeImageComp />}
      <BadgeText>
        {title && <h4><FormattedMessage id={title} /></h4>}
        <p><FormattedMessage id={description} /></p>
      </BadgeText>
    </BadgeContainer>
  )
})

const Badges = (props: BadgesProps) => (
  <FooterContentWrapper>
    {
      props.byeJobBoardsVariant
        ? (
          <Badge
            imageSrc={highFiveImage}
            imageAlt='high_five_image_description'
            title='footer_bye_jobboards_title'
            description='footer_bye_jobboards_description'
          />)
        : (
          <Badge
            imageSrc={highFiveImage}
            imageAlt='high_five_image_description'
            title='footer_about_heyjobs_title'
            description='footer_about_heyjobs_description'
          />)
    }
    <Badge
      imageSrc={dataGuardShield}
      imageAlt='gdpr_shield_image_description'
      description='footer_gdpr_description'
      imageHref='https://www.dataguard.de/go/dataguard-seal/'
    />
  </FooterContentWrapper>
)

export default Badges
