// @flow
import * as actions from '../constants/actions'
import { cleanOnLocationChange } from './utils/cleanOn'
import { PENDING, FULFILLED, REJECTED } from './promiseUtil'

export type PasswordlessSignInState = {
  confirmEmailFailed: bool,
  confirmingEmail: bool,
  error: any,
  failed: bool,
  navigating: bool,
  success: bool
}

const initialState: PasswordlessSignInState = {
  success: false,
  failed: false,
  error: null,
  confirmingEmail: false,
  confirmEmailFailed: false,
  navigating: false,
}

const reducer = (state: PasswordlessSignInState = initialState, action): PasswordlessSignInState => {
  switch (action.type) {
    case PENDING(actions.PASSWORDLESS_SIGN_IN):
      return initialState
    case FULFILLED(actions.PASSWORDLESS_SIGN_IN):
      return {
        ...state,
        success: true,
        failed: false,
        error: null,
      }
    case REJECTED(actions.PASSWORDLESS_SIGN_IN):
      return {
        ...state,
        success: false,
        failed: true,
        error: action.payload,
      }
    case PENDING(actions.CONFIRM_EMAIL):
      return {
        ...state,
        error: null,
        confirmingEmail: true,
      }
    case FULFILLED(actions.CONFIRM_EMAIL):
      return {
        ...state,
        error: null,
        confirmingEmail: false,
      }
    case REJECTED(actions.CONFIRM_EMAIL):
      return {
        ...state,
        confirmEmailFailed: true,
        error: action.payload,
        confirmingEmail: false,
      }
    case actions.HANDLING_LOGIN:
      return {
        ...state,
        navigating: true,
      }
    default:
      return state
  }
}

export default cleanOnLocationChange(reducer)
