import React, { PropsWithChildren } from 'react'
import styled from '@emotion/styled'
import { MenuItem } from '../HNavBar.types'
import { HTypography } from '../../HTypography'
import { COLORS } from '../../theme'
import { AnimatedBox, useBookmarkAnimation } from '../bookmarkAnimationContext'

const TypographyLink = styled(HTypography)`
  display: inline-flex;
  gap: 8px;
  height: 100%; 
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  &:hover, &:active {
    cursor: pointer;
    color: ${COLORS.primary_50} !important;
  }
`

const Bar = styled.div`
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
  position: absolute;
  left: 0;
  bottom: -22px;
  width: 100%;
`

const ActiveBar = styled(Bar)`
  border-top: 2px solid ${COLORS.primary_50};
`
const TransparentBar = styled(Bar)`
  border-top: 2px solid transparent;
`

export const LoggedInDesktopMenuItem: React.FC<PropsWithChildren<MenuItem>> =
(props) => {
  const {
    clickAction,
    children,
    active,
    component = 'div',
    componentProps,
    itemType,
    ...others
  } = props

  const isBookmarkAnimationActive = useBookmarkAnimation()
  const isSavedJobsButton = itemType === 'saved_jobs'

  return (
    <AnimatedBox
      className={isBookmarkAnimationActive && isSavedJobsButton ? 'blinking' : ''}
      display='flex'
      height='100%'
      flexDirection='column'
      position='relative'
      px={2}
      borderRadius={1}
    >
      <TypographyLink
        variant='button'
        weight='medium'
        color={active ? 'primary_50' : 'surface_gray_60'}
        onClick={clickAction}
        component={component}
        {...others}
        {...componentProps}
      >
        {children}
      </TypographyLink>
      {active ? (<ActiveBar/>) : (<TransparentBar/>)}
    </AnimatedBox>
  )
}
