import * as React from 'react'
import clsx from 'clsx'
import { PaginationItem, PaginationItemProps } from './HPaginationItem'
import styled from '@emotion/styled'
import usePagination from '@mui/material/usePagination'

interface PaginationProps extends React.HTMLProps<HTMLElement> {
  count: number
  siblingCount?: number
  page?: number
  makeUrl?: (page: number) => string
  onPageChange?: (event: React.ChangeEvent<unknown>, page: number) => void
  paginationItemProps?: Partial<PaginationItemProps>
}

const Pagination: React.FC<PaginationProps> = ({
  className,
  makeUrl,
  siblingCount = 0,
  page = 1,
  count,
  onPageChange,
  paginationItemProps = {},
  ...others
}: PaginationProps) => {
  const { items } = usePagination({
    count: count,
    siblingCount: siblingCount,
    page: page,
    onChange: onPageChange,
  })
  return (
    <nav className={clsx(className)} {...others}>
      <ul>
        {items.map((item, index) => (
          <li key={index}>
            <PaginationItem
              makeUrl={makeUrl}
              {...item}
              {...paginationItemProps}
            />
          </li>
        ))}
      </ul>
    </nav>
  )
}

export const HPagination = styled(
  ({ size, ...otherProps }: PaginationProps) => <Pagination {...otherProps} />,
)`
  ul {
    display: flex;
    align-items: center;
    height: 32px;
  }
`
