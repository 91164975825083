// @flow

import API from '../clientBuilder'
import { buildUrlFragment } from '../../../helpers/url/query'
import type { APIRequestConfig } from '../types'

export default {
  get: (options: $FlowTODO, config: APIRequestConfig) =>
    API.get.promise(`/v2/seo_page${buildUrlFragment(options)}`, null, config),

  list: (options: $FlowTODO, config: ?APIRequestConfig) =>
    API.get.promise(`/v2/seo_pages/indexable${buildUrlFragment(options)}`, null, config),
}
