import * as React from 'react'
import styled from '@emotion/styled'
import { Close24 } from '@carbon/icons-react'
import { globalStyles } from '../theme'
import { NavigationFunnelTopBarProps } from './HNavigationFunnelTopBar.types'
import HHidden from '../HHidden'
import { HPlaceholder } from '../HPlaceholder'
import { HBox } from '../HBox'
import { HLogo } from '../HLogo'
import { HTypography } from '../HTypography'
import { HLinearProgress } from '../HProgressbar'
import { HRoundIconButton } from '../HButton'

interface CompanyLogoProps {
  size: 'XS' | 'M'
  src?: string
  srcSet?: string
  altText?: string
}

const CompanyLogoImage = styled.img`
  max-width: 100%;
`

const CompanyLogo =
  ({ size, src, srcSet, altText }: CompanyLogoProps): JSX.Element => {
    if (src) {
      return (
        <HPlaceholder size={size}>
          <CompanyLogoImage src={src} srcSet={srcSet} alt={altText} />
        </HPlaceholder>
      )
    } else {
      return <HPlaceholder size={size} />
    }
  }

const NavigationFunnelTopBar =
  React.forwardRef<HTMLDivElement, NavigationFunnelTopBarProps>(
    (props, ref) => {
      const {
        text,
        smTextVariant = 'body2',
        logoImageSrc,
        logoImageSrcSet,
        logoImageAltText,
        logoText,
        /* when true, we show the roundel logo instead of
           Placeholder and bottom text & logo
           https://heyjobs.atlassian.net/browse/TPGE-737
        */
        roundelLogo = false,
        progress,
        href,
        onClick,
        'data-test-id': dataTestId,
        subheaderText,
        Link,
        onLinkClick,
        ...others
      } = props

      const buttonProps: any = {
        ...others,
        href,
        onClick,
        color: 'neutral',
        variant: 'text',
        ...(dataTestId ? { 'data-test-id': `${dataTestId}-close-icon` } : {}),
      }

      return (
        <div {...others} ref={ref} data-test-id={dataTestId}>
          <HBox className='navigation-container'>
            <HBox display='flex' alignItems='center'>
              {/*
                Note: We decided to go with condition displaying of variant
                using the Hidden component based on screen size because
                we couldn't conditionally define the size of variant to
                be passed to the components
              */}
              <HHidden mdUp>
                {
                  !roundelLogo
                    ? <CompanyLogo size='XS' src={logoImageSrc} srcSet={logoImageSrcSet} altText={logoImageAltText} />
                    : <HLogo variant='pink_yellow_roundel' size={'small'} width={40} />
                }
              </HHidden>
              <HHidden mdDown>
                {
                  !roundelLogo
                    ? <CompanyLogo size='M' src={logoImageSrc} srcSet={logoImageSrcSet} altText={logoImageAltText} />
                    : <HLogo variant='pink_yellow_roundel' size={'medium'} width={56} />
                }
              </HHidden>
              <HBox alignItems='left' mx={3} overflow='hidden' className='navigation-title'>
                <HBox height={!roundelLogo ? '18px' : 'auto'}>
                  <HHidden mdUp>
                    <HTypography
                      variant={smTextVariant}
                      color='surface_gray_80'
                      weight='medium'
                      truncated={true}
                    >
                      {text}
                    </HTypography>
                  </HHidden>
                  <HHidden mdDown>
                    <HTypography
                      variant='h6'
                      color='surface_gray_80'
                      weight='medium'
                      truncated={true}
                    >
                      {text}
                    </HTypography>
                  </HHidden>
                </HBox>
                {!subheaderText && !roundelLogo &&
                  <HBox mt={2}>
                    <HHidden mdUp>
                      <HLogo variant='pink_yellow' text={logoText} textPosition='besides' size={'small'} width={40} />
                    </HHidden>
                    <HHidden mdDown>
                      <HLogo variant='pink_yellow' text={logoText} textPosition='besides' size={'medium'} width={65} />
                    </HHidden>
                  </HBox>
                }
                {subheaderText && href && Link &&
                  <HBox mt={2}>
                    <Link
                      to={href}
                      data-test-id='top-bar-subheader-text'
                      onClick={() => onLinkClick?.()}
                    >
                      <HTypography color='primary_50'>
                        {subheaderText}
                      </HTypography>
                    </Link>
                  </HBox>
                }
              </HBox>
              <HBox marginLeft='auto'>
                <HRoundIconButton {...buttonProps}>
                  <Close24 />
                </HRoundIconButton>
              </HBox>
            </HBox>
          </HBox>
          <HBox>
            <HLinearProgress
              variant='determinate'
              rounded={false}
              value={progress}
            ></HLinearProgress>
          </HBox>
        </div>
      )
    },
  )

NavigationFunnelTopBar.displayName = 'NavigationFunnelTopBar'

export const HNavigationFunnelTopBar = styled(NavigationFunnelTopBar)`
  .navigation-container {
    box-shadow: ${globalStyles.shadow2.boxShadow};
    height: 64px;
    padding: 12px 16px;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    .navigation-container {
      height: 88px;
      padding: 16px 48px;

      .navigation-title {
        margin-left: 16px;
      }
    }
  }
`
