import { ArrowRight16 } from '@carbon/icons-react'
import React from 'react'
import { HBox, HButton, IHButtonProps, globalStyles } from 'talent-ui'

interface BottomNavigationProps {
  secondaryAction?: Partial<IHButtonProps>,
  primaryAction?: Partial<IHButtonProps>,
  withShadow?: boolean,
  fullScreen?: boolean // determines if the modal is full screen or not
}

export const BottomNavigation: React.FC<BottomNavigationProps> = ({
  secondaryAction,
  primaryAction,
  withShadow,
  fullScreen = false
}) => {
  return (
    <HBox
      display='flex'
      justifyContent='space-between'
      boxShadow={withShadow ? globalStyles.shadow3.boxShadow : ''}
      xs={{
        pt: 3,
        pb: 4,
        px: 4
      }}
      md={{
        mt: 8
      }}
      lg={{
        pt: 4,
        pb: 6,
        px: fullScreen ? 6 : 8,
        mt: 14,
        maxWidth: 740,
        width: '100%',
        mx: 'auto',
        boxShadow: 'none !important'
      }}
    >
      {secondaryAction
        ? (
          <HButton
            variant='text'
            color='neutral'
            data-test-id='skip'
            {...secondaryAction}
          />
          )
        : <div />}
      {primaryAction
        ? (
          <HButton
            variant='contained'
            color='primary'
            endIcon={<ArrowRight16 />}
            data-test-id='continue'
            {...primaryAction}
          />)
        : <div />}
    </HBox>
  )
}
