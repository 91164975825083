// @flow

import * as React from 'react'
import compose from 'lodash/flowRight'
import isEmpty from 'lodash/isEmpty'
import { connect } from 'react-redux'
import {
  findComponentByNameProps,
  getVariantChildren,
  getOriginalChild,
} from './abTestingComponentsUtils'
import * as actionCreators from '../../actions/actionCreators'
import { fireSnowplowEvent } from '../../tracking/external/trackingClient'
import { getUserGroup } from './abTestingGetUserGroup'
import { withCookies, type Cookies } from 'react-cookie'
import { ORIGINAL_VARIANT } from '../abTestingDefinitions'
import { withRouter } from '../../routing/withRouter'

import type { User } from '../../actions/api/clients/users'
import type { ApplicationState } from '../../types/applicationState'
import type { AbTestingDefinition } from '../abTestingTypes'

type PassedProps = {
  children: React.Node,
  experimentName: string,
  trackingContext?: Object
}

type AbTestingExperimentProps = PassedProps & {
  definitions: AbTestingDefinition[],
  forcedExperimentName: string,
  forcedExperimentUserGroup: string,
  host: string,
  initializeAbTests: (experimentData: {[key: string]: string}) => void,
  isInitialized: bool,
  previousAssignments: { [key: string]: { group_name: string, source: string} },
  trackingToken: string,
  user: ?User
}

type AbTestingExperimentPropsWithCookies = AbTestingExperimentProps & {
  cookies: Cookies
}

const MINIMUM_VARIANTS_COUNT = 2

export const _AbTestingExperiment = React.memo<AbTestingExperimentProps>(withCookies(
  (props: AbTestingExperimentPropsWithCookies) => {
    const [isMounted, setIsMounted] = React.useState<bool>(false)
    const [groupName, setGroupName] = React.useState<string>('')
    const { experimentName, cookies, definitions } = props

    React.useEffect(() => {
      if (props.trackingToken && props.isInitialized) {
        const assignedGroupName = getUserGroup(cookies)(props, definitions)
        setGroupName(assignedGroupName)
        setIsMounted(true)

        const contextData = props.trackingContext

        fireSnowplowEvent(
          'experiment_event',
          {
            experiment_id: experimentName,
            group_name: assignedGroupName,
          },
          [
            {
              key: 'page_context',
              options: {
                pageName: contextData?.page_type,
              },
            },
            {
              key: 'job_suggestion_context',
              options: {
                suggested_job_new_flag: contextData?.suggested_job_new_flag,
                suggested_job_position: contextData?.suggested_job_position,
                suggested_job_uid: contextData?.suggested_job_uid,
              },
            },
            {
              key: 'apply_context',
              options: {
                apply_click_type: contextData?.apply_click_type,
              },
            },
            {
              key: 'application_flow_version_context',
              options: {
                screeing_question_id: contextData?.screeing_question_id,
                screening_question_is_mandatory: contextData?.screening_question_is_mandatory,
                screening_question_type: contextData?.screening_question_type,
                step_number: contextData?.step_number,
                total_number_of_steps: contextData?.total_number_of_steps,
              },
            },
          ]
        )
      }
    }, [props.trackingToken, props.isInitialized])

    if (!isMounted) {
      const OriginalByName = findComponentByNameProps(ORIGINAL_VARIANT, props.children)
      const OriginalByComponent = getOriginalChild(props.children)
      const Original = !isEmpty(OriginalByName)
        ? OriginalByName
        : !isEmpty(OriginalByComponent)
            ? OriginalByComponent
            : props.children

      return (<>{Original}</>)
    }

    const Variants = getVariantChildren(props.children)
    const hasEnoughTestingVariants = React.Children.count(Variants) >= MINIMUM_VARIANTS_COUNT

    if (hasEnoughTestingVariants) {
      return findComponentByNameProps(
        groupName,
        Variants
      )
    } else {
      return (
        <>{props.children}</>
      )
    }
  }
))

const mapStateToProps = (state: ApplicationState, ownProps) => ({
  user: state.user.user,
  previousAssignments: state.abTesting.assignments,
  trackingToken: state.session?.device.tracking_token, // eslint-disable-line camelcase
  forcedExperimentName: ownProps.location.query.experimentId,
  forcedExperimentUserGroup: ownProps.location.query.experimentVariant,
  host: state.request.host,
  isInitialized: state.app.abTests,
  definitions: state.abTesting.definitions,
})

const mapDispatchToProps = (dispatch) => ({
  initializeAbTests: (experimentData: {[key: string]: string}) => {
    dispatch(
      actionCreators.initializeAbTests(experimentData)
    )
  },
})

const AbTestingExperiment = compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(_AbTestingExperiment)

export default AbTestingExperiment
