import SwipeableDrawer, {
  SwipeableDrawerProps as DProps,
} from '@mui/material/SwipeableDrawer'
import { DistributiveOmit } from '@mui/types'
import React, { PropsWithChildren } from 'react'
import styled from '@emotion/styled'
import { COLORS, globalStyles } from '../theme'
import { HBox } from '../HBox'
import { ChoiceContent, ChoiceDrawerProps } from './ChoiceContent'
import { InfoContent, InfoDrawerProps } from './InfoContent'
import { MenuContent } from './MenuContent'

type disableProps = 'anchor' | 'children'

export type IHDrawerProps = PropsWithChildren<
DistributiveOmit<DProps, disableProps>>

interface IDrawerComposition {
  Choice: React.FC<ChoiceDrawerProps>
  Menu: React.FC<PropsWithChildren<unknown>>
  Info: React.FC<InfoDrawerProps>
}

type IHDrawer = React.FC<IHDrawerProps> & IDrawerComposition

const Drawer: React.FC<IHDrawerProps> = ({
  open,
  onClose,
  onOpen,
  children,
  ...others
}) => {
  return (
    <SwipeableDrawer
      anchor='bottom'
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      {...others}
    >
      <div className='holder' />
      <HBox mt={2}>{children}</HBox>
    </SwipeableDrawer>
  )
}

const backDropStyles = {
  InfoContent: {
    background: COLORS.surface_gray_90,
    opacity: '0.16 !important',
  },
  MenuContent: {
    background: COLORS.surface_gray_90,
    opacity: '0.4 !important',
  },
  ChoiceContent: {
    background: 'transparent',
  },
}

// @ts-expect-error - There is no way to set Choice Menu and Info for HDrawer
const HDrawer: IHDrawer = styled(Drawer)`
  > .MuiBackdrop-root {
    ${({ children }) => {
    // @ts-expect-error - https://github.com/heyjobs/talent-ui/pull/22
    return backDropStyles[children.type.name]
  }}
  }
  > .MuiPaper-root {
    padding: 8px 24px 32px 24px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    ${globalStyles.shadow4};
    .holder {
      border: 2px solid ${COLORS.surface_gray_10};
      border-radius: 24px;
      width: 40px;
      margin: auto;
      background-color: ${COLORS.surface_gray_10};
    }
  }
`

HDrawer.Choice = ChoiceContent
HDrawer.Menu = MenuContent
HDrawer.Info = InfoContent

export { HDrawer }
