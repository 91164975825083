import React from 'react'
import styled from '@emotion/styled'
import { COLORS } from '../../theme'
import { MiddleSection } from './MiddleSection'
import { MainFooterProps } from './HMainFooter.types'
import { RightSection } from './RightSection'
import { LeftSection } from './LeftSection'
import Grid from '@mui/material/Grid'
import { Breadcrumbs } from './Breadcrumbs'

const FooterContainer = styled.footer`
  background-color: ${COLORS.surface_gray_00};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing(6, 6, 8)};
  
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: ${({ theme }) => theme.spacing(10)};
  }
  ${({ theme }) => theme.breakpoints.up('lg')} {
    padding: ${({ theme }) => theme.spacing(10, 18)};
  }
  ${({ theme }) => theme.breakpoints.up('xl')} {
    padding: ${({ theme }) => theme.spacing(10, 33)};
  }
  ${({ theme }) => theme.breakpoints.down('lg')} {
    .footer-grid {
      gap: ${({ theme }) => theme.spacing(8)};
    }

    .section-left {
      order: 3;
    }
  }
`

export const HMainFooter: React.FC<MainFooterProps> = ({
  popularJobs,
  rightSection,
  leftSection,
  breadcrumbs,
}) => {
  return (
    <FooterContainer data-test-id='footer-main'>
      {breadcrumbs && <Breadcrumbs {...breadcrumbs} />}
      <Grid container className='footer-grid'>
        <Grid item xs={12} md={12} lg={3} className='section-left'>
          <LeftSection {...leftSection} />
        </Grid>
        <Grid item xs={12} md={12} lg={7}>
          <MiddleSection {...popularJobs} />
        </Grid>
        <Grid item xs={12} md={12} lg={2}>
          <RightSection {...rightSection} />
        </Grid>
      </Grid>
    </FooterContainer>
  )
}
