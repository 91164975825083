import logger from '../../logging'

const log = logger('gtm-preview-context')

export const isValidGtmSSDebugValue = (gtmDebugValue: string): boolean => {
  if (!/^(?:[A-Za-z0-9+/=]{4})*$/.test(gtmDebugValue)) return false

  try {
    const decodedValue = Buffer.from(gtmDebugValue, 'base64').toString()
    return decodedValue.startsWith('env') && decodedValue.length === 50
  } catch (e) {
    log.error('Error decoding gtmDebugValue', {
      datadogContext: {
        error: e,
        gtmDebugValue,
      },
    })
    return false
  }
}
