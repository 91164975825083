// @flow

import { identifiers, setItem, getItem, removeItem } from '../../../helpers/localStorageUtils'

const localStorageIdentifiers = identifiers.SEE_ONBOARDING_MODAL

export const setSeeOnboardingModal = () => {
  setItem(localStorageIdentifiers, 'true')
}

export const seeOnBoardingModal = (): boolean => {
  return getItem(localStorageIdentifiers) === 'true'
}

export const clearSeeOnboardingModal = () => {
  removeItem(localStorageIdentifiers)
}
