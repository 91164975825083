// @flow
/* istanbul ignore file */

import * as React from 'react'
import { connect } from 'react-redux'
import { COOKIE_CONSENT_INITIALIZED_EVENT, COOKIE_CONSENT_CHANGED_EVENT } from '../constants/base'
import { COOKIE_BANNER_INITIALIZED_EVENT } from '../tracking/eventNames'
import { fireSnowplowEvent } from '../tracking/external/trackingClient'
import { setCookieConsentService } from '../actions/cookieConsent'
import { getCookieConsentServices } from './misc/getCookieConsentServices.ts'

import type { CookieConsentServiceType } from '../reducers/cookieConsent'
import type { ApplicationState } from '../types/applicationState'
import { syncGoogleConsentModeSignalsWithBraze } from './misc/google-consent-mode-utils.ts'

type UserCentricsProps = {|
  allowService: (service: CookieConsentServiceType) => void,
  allowedService: {[key: string]: ?bool },
  isUserAuthenticated: bool
|}
export interface UserCentricsService {
  categorySlug: 'marketing' | 'functional',
  consent: {
    history: {
      action: string,
      language: string,
      status: bool,
      timestamp: number,
      type: string
    }[],
    status: bool
  },
  id: string,
  name: string,
  version: string
}

const _UserCentrics = (props: UserCentricsProps) => {
  const { allowedService, allowService } = props

  const updateServices = React.useCallback(() => {
    const services: UserCentricsService[] = getCookieConsentServices()
    let shouldReload = false

    services.forEach(service => {
      const hasConsent = service.consent.status
      const hasPreviousPreviousConsent = allowedService[service.name]

      allowService({ consent: hasConsent, name: service.name })

      if (hasPreviousPreviousConsent && !hasConsent) {
        shouldReload = true
      }
    })

    if (shouldReload) {
      window.location.reload()
    }
  }, [allowedService, allowService])

  const onInit = React.useCallback(() => {
    const ucSettings = localStorage.getItem('uc_settings') || '{}'
    const parsedSettings = JSON.parse(ucSettings)
    const controllerId = parsedSettings?.controllerId

    if (controllerId) {
      fireSnowplowEvent(COOKIE_BANNER_INITIALIZED_EVENT, {
        service_name: 'Usercentrics',
        service_device_id: controllerId,
      })
    }
    updateServices()
  }, [updateServices])

  const onChange = React.useCallback((event) => {
    if (window.UC_UI && event.detail.view === 'NONE') {
      if (props.isUserAuthenticated) {
        const services: UserCentricsService[] = getCookieConsentServices()
        syncGoogleConsentModeSignalsWithBraze(allowedService, services)
      }
      updateServices()
    }
  }, [updateServices, props.isUserAuthenticated, allowedService])

  React.useEffect(() => {
    if (window.UC_UI && window.UC_UI_IS_RENDERED) {
      onInit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    window.addEventListener(COOKIE_CONSENT_INITIALIZED_EVENT, onInit)
    window.addEventListener(COOKIE_CONSENT_CHANGED_EVENT, onChange)

    return () => {
      window.removeEventListener(COOKIE_CONSENT_INITIALIZED_EVENT, onInit)
      window.removeEventListener(COOKIE_CONSENT_CHANGED_EVENT, onChange)
    }
  }, [onChange, onInit])

  return null
}

const redux = [
  (state: ApplicationState) => ({
    allowedService: state.cookieConsent.services,
    isUserAuthenticated: state.user.isAuthenticated,
  }),
  (dispatch) => ({
    allowService: (service) => {
      dispatch(setCookieConsentService(service))
    },
  }),
]

const UserCentrics = connect(...redux)(_UserCentrics)
export default UserCentrics
