import React from 'react'

export const SvgGray: React.FC<{width?: number}> = ({ width }) => (
  <svg
    width={width}
    height='100%'
    viewBox='0 0 116 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    aria-label='Heyjobs'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M67.4712 23.6885C69.8888 23.6885 71.3744 21.8578 71.3744 18.4381C71.3744 15.0187 69.8888 13.188 67.4712 13.188C65.0188 13.188 63.5336 15.0187 63.5336 18.4381C63.5336 21.8578 65.0188 23.6885 67.4712 23.6885ZM67.4712 27.8331C61.5648 27.8331 58.007 23.9299 58.007 18.438C58.007 12.9461 61.5648 9.04321 67.4712 9.04321C73.3429 9.04321 76.9006 12.9461 76.9006 18.438C76.9006 23.9299 73.3429 27.8331 67.4712 27.8331Z'
      fill='#707485'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M91.9411 18.4379C91.9411 14.9838 90.5595 13.1875 88.3143 13.1875C86.0694 13.1875 84.6875 14.9838 84.6875 18.4379C84.6875 21.8919 86.0694 23.6883 88.3143 23.6883C90.5595 23.6883 91.9411 21.8919 91.9411 18.4379ZM97.4676 18.4378C97.4676 24.2405 94.4627 27.8329 89.7649 27.8329C87.2092 27.8329 85.3438 26.451 84.6528 25.5186H84.5841V27.2803H79.0575V2.58374H84.5841V11.3569H84.6528C85.3438 10.4246 87.2092 9.04269 89.7649 9.04269C94.4627 9.04269 97.4676 12.6351 97.4676 18.4378Z'
      fill='#707485'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M115.682 22.0646C115.682 25.795 112.85 27.8329 107.945 27.8329C101.624 27.8329 99.2062 25.0004 99.0334 21.4083H104.215C104.387 23.1697 105.562 24.0334 107.738 24.0334C109.292 24.0334 110.155 23.5155 110.155 22.5137C110.155 20.9938 108.497 20.7867 105.7 20.1648C102.695 19.5085 99.3789 18.4031 99.3789 14.7076C99.3789 11.4953 102.142 9.04297 107.116 9.04297C112.781 9.04297 114.991 11.9098 115.164 15.0184H109.983C109.81 13.6022 109.05 12.8422 107.254 12.8422C105.7 12.8422 104.905 13.3951 104.905 14.2587C104.905 15.5019 106.011 15.5363 108.739 16.1235C112.228 16.8835 115.682 18.0579 115.682 22.0646Z'
      fill='#9397A9'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M27.2589 16.5438H34.4687C34.4687 14.083 32.9779 12.8349 30.8635 12.8349C28.7487 12.8349 27.2589 14.083 27.2589 16.5438ZM34.2254 21.9517H39.737C38.7669 25.5213 35.9932 27.8782 31.1755 27.8782C25.2828 27.8782 21.7126 23.9965 21.7126 18.4502C21.7126 13.0432 25.2132 9.02197 30.8635 9.02197C36.9295 9.02197 40.0144 13.4589 40.0144 19.906H27.2589C27.2589 22.4024 29.0957 23.7195 31.0718 23.7195C32.9779 23.7195 33.8793 22.8181 34.2254 21.9517Z'
      fill='#9397A9'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M52.8681 5.00112C52.8681 3.40669 54.0815 2.54028 55.641 2.54028C57.2014 2.54028 58.4138 3.40669 58.4138 5.00112C58.4138 6.63023 57.2014 7.46257 55.641 7.46257C54.0815 7.46257 52.8681 6.63023 52.8681 5.00112Z'
      fill='#707485'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M47.7529 18.1909L45.095 9.57642H39.5493L45.283 24.6149C45.283 24.6149 46.6089 21.5181 47.7529 18.1909Z'
      fill='#9397A9'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M52.548 9.57642L50.3292 17.2624C49.7933 19.4636 47.9325 26.3881 45.3026 28.4366C44.6624 29.1455 43.5071 29.2698 42.1146 28.9529H42.045V33.1128C43.1887 33.3549 44.1944 33.4595 45.1299 33.4595C48.8737 33.4595 50.5378 32.2115 52.0626 27.6705L58.0943 9.57642H52.548Z'
      fill='#707485'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.57385 2.58398V12.2583H13.9551V2.58398H19.4871V27.2879H13.9551V17.3375H5.57385V27.2879H0.0418243V2.58398H5.57385Z'
      fill='#9397A9'
    />
  </svg>
)
