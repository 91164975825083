import React from 'react'
import { HBox, HBoxProps } from '../HBox'
import { HIcon } from '../HIcon'
import { HTypography } from '../HTypography'
import { CarbonIconType, Checkmark16 } from '@carbon/icons-react'
import { COLORS } from '../theme'

interface JobCardTagProps extends HBoxProps {
  Icon: CarbonIconType | React.ElementType
  text: string
  highlight?: boolean
}

export const JobCardTag: React.FC<JobCardTagProps> = ({
  Icon, text, highlight, ...others
}) => {
  const parentProps: HBoxProps = {
    display: 'flex',
    gap: 8,
    px: 1,
    py: 0.5,
    borderRadius: 0.5,
    bgcolor: highlight ? COLORS.support_success_05 : undefined,
    ...others,
  }
  return (
    <HBox {...parentProps}>
      <HIcon Icon={Icon} size={18} color='surface_gray_90' />
      <HTypography variant='body2' weight='regular' color='surface_gray_90'>{text}</HTypography>
      { highlight && <HIcon Icon={Checkmark16} size={18} color='support_success_70' /> }
    </HBox>
  )
}
