// @flow

import { runningInBrowser } from '../../actions/actions/support/base'
import { buildCanonicalUrl } from '../../helpers/metatags/canonicalTags'

import type { RouteData } from '../../types/common'
import type { ApplicationState } from '../../types/applicationState'

const canonicalRedirect = (
  state: ApplicationState,
  routeData: RouteData
) => {
  if (runningInBrowser) {
    return
  }

  const isBot = state.request.isBot
  const { pathname, search, query } = routeData.location

  if (isBot) {
    const canonicalUrl = buildCanonicalUrl('', pathname)
    const requestedUrl = `${pathname}${search || ''}`
    const isFirstPage = !query?.page || query?.page === '1'

    if (canonicalUrl !== requestedUrl && isFirstPage) {
      return {
        pathname: canonicalUrl,
        state: {
          redirectCode: 301,
        },
      }
    }
  }
  return null
}

export default canonicalRedirect
