// @flow

import * as React from 'react'
import { useLocation } from 'react-router-dom'
import getPageType from '../../tracking/external/getPageType'
import { fireSnowplowPageView } from '../../tracking/external/trackingClient'
import { trackBrazePageViewEvent, trackAdditionalBrazeEvents } from '../../tracking/clients/braze'
import logger from '../../../logging'
import buildPageViewTrackingEvent from '../../tracking/external/pageViewTrackingEventBuilder'
import type { RouteName } from '../../routes'
import type { RouteLocation } from '../../types/common'
import type { ApplicationState } from '../../types/applicationState'
import type { Dispatch } from '../../store/configureStore'

type PageViewEventProps = {|
  dispatch: Dispatch,
  isInitialRender?: bool,
  location: RouteLocation,
  routeName: RouteName,
  state: ApplicationState
|}

const log = logger('router-page-view')
export const trackPageView = (props: PageViewEventProps) => {
  const isInitialRender = props.isInitialRender
  // Always fire Braze pageview event
  trackBrazePageViewEvent(props.routeName, props.state)

  // Track additional braze events: onpage_nps, homepage_ces, serp_ces, inbox_ces
  trackAdditionalBrazeEvents(props.dispatch, props.routeName, props.state)

  // Skip the first Snowplow page view event because we fire this in bundledTrackingClient
  if (!isInitialRender) {
    if (props.routeName) {
      const pageType = getPageType(props.routeName)
      const contextData = buildPageViewTrackingEvent(props.routeName, props.state)

      fireSnowplowPageView(pageType, contextData)
    } else {
      // FIXME: check (route) value
      log.info(`no route name for ${props.routeName}`, { route: props.location.pathname })
    }
  }
}

const PageViewEvent = (props: PageViewEventProps) => {
  const { pathname } = useLocation()

  React.useEffect(() => {
    trackPageView(props)
  }, [pathname])

  return null
}

export default PageViewEvent
