import pickBy from 'lodash/pickBy'
import { CAFFormFields, CAFLocalStorage, CAFLocalStorageKeys, CAFLocalStorageValues } from '../shared/types'
import { CAF_LOCAL_DATA_PREFIX } from '../constants'
import { CAFFormKeys } from './types'
import buildQuestionFieldNameImpl from './buildQuestionFieldName'

type LocalStorageMethodsReturnType = {
  getApplicationLocalStorage: () => CAFLocalStorage | null
  setApplicationLocalStorage: (key: CAFLocalStorageKeys, item: CAFLocalStorageValues) => void
  removeApplicationLocalStorage: () => void
}

type BuildFieldNameProps = {
  // eslint-disable-next-line camelcase
  question_type: string,
  uid: string
}

// FIXME: decide on key structure, do we still need any of the structure from previous impl?
// https://github.com/heyjobs/jobseeker-portal/blob/master/src/app/components/conversationalFlow/shared.js#L46
export const buildQuestionFieldName = ({
  uid,
}: BuildFieldNameProps): CAFFormKeys => {
  return buildQuestionFieldNameImpl({ uid })
}

export const cafLocalDataMethods = (jobId: string): LocalStorageMethodsReturnType => {
  const applicationLocalStorageKey = CAF_LOCAL_DATA_PREFIX + jobId

  const getApplicationLocalStorage = (): CAFLocalStorage | null => {
    const localStorageItem = localStorage.getItem(applicationLocalStorageKey)
    if (!localStorageItem) return null

    return JSON.parse(localStorageItem)
  }

  const setApplicationLocalStorage = (key: CAFLocalStorageKeys, item: CAFLocalStorageValues) => {
    const localStorageItem = localStorage.getItem(applicationLocalStorageKey)
    const cafLocalData = localStorageItem ? JSON.parse(localStorageItem) : {}

    cafLocalData[key] = item

    localStorage.setItem(applicationLocalStorageKey, JSON.stringify(cafLocalData))
  }

  const removeApplicationLocalStorage = () => localStorage.removeItem(applicationLocalStorageKey)

  return { getApplicationLocalStorage, setApplicationLocalStorage, removeApplicationLocalStorage }
}

export const clearCAFlocalData = () => {
  Object.keys(localStorage)
    .filter(item => item.startsWith(CAF_LOCAL_DATA_PREFIX))
    .forEach(CAFItem => localStorage.removeItem(CAFItem)
    )
}

export const canFinishApplication = (applicationStatus: string): boolean =>
  ['jobseeker_interested', 'pending_cv'].includes(applicationStatus)

type ScreeningQuestionAnswer = {
  // eslint-disable-next-line camelcase
  question_id: string
  value: string
}

export const getScreeningQuestionsAnswers = (formValues: CAFFormFields): ScreeningQuestionAnswer[] => {
  const screeningQuestions = pickBy(formValues, (value, key: string) => key.toLowerCase().includes('screeningquestion') && !!value)

  const answers = []

  for (const [key, value] of Object.entries(screeningQuestions)) {
    const questionId = key.toLowerCase().split('screeningquestion-')[1]

    answers.push({
      question_id: questionId,
      value: value! as string,
    })
  }

  return answers
}
