import React, { useMemo, useState } from 'react'
import { HAutocomplete, HBox } from 'talent-ui'
import { useTranslate } from './hooks/use-translate'
import SelectComponentWrapper from './select-component-wrapper'
import { PopularJobTitles } from './popular-job-titles'
import { FetchJobTitles, JobTitle } from './types/job-titles'
import { useJobTitlesRequest } from './hooks/use-job-titles-request'

type JobTitlesPreferencesProps = {
  selectedJobTitles: JobTitle[]
  setSelectedJobTitles: React.Dispatch<React.SetStateAction<JobTitle[]>>
  variant: 'onboarding' | 'profile',
  showError: boolean
  validationState: JobTitlesValidationState
  setValidationState: React.Dispatch<React.SetStateAction<JobTitlesValidationState>>
  fetchJobTitles: FetchJobTitles
}

export type JobTitlesValidationState = 'valid' | 'invalid' | 'dirty'

const variants = {
  onboarding: {
    texts: {
      select: 'onboarding_flow_job_titles_select_section_title',
      selected: 'onboarding_flow_job_titles_selected_section_title'
    },
    margins: {
      dividerTop: 6,
      selectorTop: 8,
      autocompleteTop: 4
    }
  },
  profile: {
    texts: {
      select: 'profile_job_titles_select',
      selected: 'profile_job_location_selected'
    },
    margins: {
      dividerTop: 6,
      selectorTop: 6,
      autocompleteTop: 4
    }
  }
}

export const JobTitlesPreferences = ({
  selectedJobTitles,
  setSelectedJobTitles,
  variant,
  showError,
  setValidationState,
  validationState,
  fetchJobTitles
}: JobTitlesPreferencesProps) => {
  const formatMessage = useTranslate()
  const [inputValue, setInputValue] = useState('')
  const [isAutocompleteOpen, setIsAutocompleteOpen] = useState(false)

  const { texts, margins } = variants[variant]

  const onDeleteJobTitle = (deletedJobTitle: JobTitle) => {
    setSelectedJobTitles((oldJobTitles) =>
      oldJobTitles.filter(existingJobTitle => existingJobTitle.title_id !== deletedJobTitle.title_id))
  }

  const onSelectJobTitle = (jobTitle: JobTitle) => {
    setSelectedJobTitles((oldJobTitles) => {
      setInputValue('')
      setValidationState('valid')
      setIsAutocompleteOpen(false)

      return [...oldJobTitles, jobTitle]
    })
  }

  const excludedJobTitleIds = useMemo(() => selectedJobTitles.map(j => j.title_id), [selectedJobTitles])
  const fetchedJobTitles = useJobTitlesRequest({ query: inputValue, excludeTitleIds: excludedJobTitleIds, fetchJobTitles })

  const autocompleteSuggestions = fetchedJobTitles.filter((jobTitle) => !excludedJobTitleIds.includes(jobTitle.title_id))

  return (
    <SelectComponentWrapper
      selectedItems={selectedJobTitles}
      onDeleteItem={onDeleteJobTitle}
      texts={texts}
      margins={margins}
      getChipPlaceholder={(jobTitle) => (jobTitle.title)}
      getChipTestId={(jobTitle) => (jobTitle.title_id.toString())}
    >
      <PopularJobTitles
        onSelectJobTitle={onSelectJobTitle}
        excludedTitles={excludedJobTitleIds}
      />
      <HBox
        md={{ mt: margins.autocompleteTop }}
        mt={6}
        mb={8}
      >
        <HAutocomplete
          HInputProps={{
            placeholder: formatMessage('onboarding_flow_job_titles_search_placeholder'),
            onKeyDown: (e) => {
              const shouldAddJobTitleOnEnterPress = e.key === 'Enter' && autocompleteSuggestions.length > 0 && !!inputValue

              if (shouldAddJobTitleOnEnterPress) {
                onSelectJobTitle(autocompleteSuggestions[0])
              }
            }
          }}
          freeSolo
          open={isAutocompleteOpen}
          onOpen={() => setIsAutocompleteOpen(true)}
          onClose={() => setIsAutocompleteOpen(false)}
          options={autocompleteSuggestions}
          getOptionLabel={(option: JobTitle) => option.title ?? ''}
          renderOption={(_, option) => (
            <li
              onClick={() => onSelectJobTitle(option)}
              key={option.title}
              data-test-id={`job-title-select-option-${option.title_id}`}
            >
              {option.title}
            </li>
          )}
          inputValue={inputValue}
          onInputChange={(_, newVal) => {
            setInputValue(newVal)

            const isFieldDirty = newVal.length > 0

            if (!isFieldDirty) {
              setValidationState('valid')
              return
            }

            if (validationState !== 'invalid') {
              setValidationState('dirty')
            }
          }}
          error={showError}
          caption={showError ? formatMessage('profile_job_titles_search_error') : undefined}
          data-test-id='job-titles-autocomplete'
        />
      </HBox>
    </SelectComponentWrapper>
  )
}
