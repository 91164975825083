import styled from '@emotion/styled'
import { ButtonBase } from '@mui/material'
import { COLORS, globalStyles } from '../theme'

export const HFakeInput = styled(ButtonBase)`
  border: 1px solid ${COLORS.surface_gray_20};
  padding: ${({ theme }) => theme.spacing(3)};
  border-radius: ${({ theme }) => theme.spacing(1)};
  box-shadow: ${globalStyles.shadow1.boxShadow};
  background-color: ${COLORS.surface_white};

  display: flex;
  justify-content: space-between;

  font-family: inherit;

  .MuiTouchRipple-child {
    color: ${COLORS.primary_30};
  }
`
