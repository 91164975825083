import { FormControl } from '@mui/material'
import styled from '@emotion/styled'
import { COLORS, globalStyles } from '../theme'

export const HSelectFormControl = styled(FormControl)`
  .MuiMenu-paper {
    box-shadow: ${globalStyles.shadow3.boxShadow};
    margin-top: 4px;
  }
  .MuiSelect-select {
    background-color: ${COLORS.surface_white};
  }
  .MuiSelect-root {
    padding: 10px 12px;
  }
  .MuiSelect-icon {
    color: ${COLORS.surface_gray_90};
    width: 20px;
    height: 20px;
    top: unset;
    right: 12px;
  }
  .MuiSelect-selectMenu {
    font-size: 14px;
    line-height: 18px;
    color: ${COLORS.surface_gray_90};
  }
  .MuiFormLabel-root {
    color: ${COLORS.surface_gray_60};
  }
  .MuiFormLabel-root.Mui-focused {
    color: ${COLORS.primary_50};
  }
  .MuiFormLabel-root.Mui-error {
    color: ${COLORS.support_error_50};
  }
  .MuiInputBase-root.Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${COLORS.primary_50} !important;
      box-shadow: ${globalStyles.shadow2.boxShadow};
    }
  }
  .MuiInputBase-root.Mui-error {
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${COLORS.support_error_50} !important;
    }
  }
  .MuiInputBase-root {
    height: 40px;
    .MuiSelect-select {
      font-size: 14px;
      line-height: 18px;
      color: ${COLORS.surface_gray_90};
    }
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${COLORS.surface_gray_20};
      box-shadow: ${globalStyles.shadow1.boxShadow};
      legend {
        span {
          padding-left: 4px;
          padding-right: 4px;
        }
      }
    }
  }
  .MuiInputLabel-outlined {
    transform: translate(14px, 10px) scale(1);
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(13px, -7px) scale(0.85);
  }
`

export const SelectWrapper = styled.div`
  .Mui-disabled {
    opacity: 0.4;
  }
`
