// @flow

import { SubmissionError } from 'redux-form'
import ApiError from '../../actions/api/errors/ApiError'

export const handleExpectedErrors = (error: Error, errorCodesToIgnore: string[]) => {
  if (error instanceof ApiError) {
    if (error.errors.filter((error) => !errorCodesToIgnore.includes(error.code)).length > 0) {
      throw error
    }
    throw new SubmissionError(error)
  }
  throw error
}
