import React from 'react'
import styled from '@emotion/styled'
import { COLORS } from '../theme'
import { HBox } from '../HBox'
import { HDivider } from '../HDivider'
import { HFilterSection } from './HFilterSection'
import { HFilterSwitch } from './HFilterSwitch'
import { HAdvancedFilterItemView } from './HAdvancedFilter.types'
import type { HAdvancedFilterProps } from './HAdvancedFilter.types'
import { HAdvancedFilterHeader } from './HAdvancedFilterHeader'

const HAdvancedFilterStyled = styled(HBox)`
  ${({ theme }) => theme.breakpoints.up('lg')} {
    border: 1px solid ${COLORS.surface_gray_10};
    border-radius: ${({ theme }) => theme.spacing(1)};
  }
`

export const HAdvancedFilter = ({
  filters,
  HeaderPros,
  disabledView = false,
}: HAdvancedFilterProps): JSX.Element => {
  return (
    <HAdvancedFilterStyled
      data-test-id="desktop-advanced-filters-container"
      lg={{
        paddingY: 4,
        minWidth: 280,
        maxWidth: 280,
      }}
    >
      <HBox
        lg={{
          display: 'block',
        }}
        display='none'
      >
        <HAdvancedFilterHeader
          {...HeaderPros}
          disabledView={disabledView}
        />
      </HBox>

      <HBox
        display="flex"
        flexDirection="column"
        lg={{ paddingY: 4, paddingX: 6 }}
        md={{ paddingTop: 0 }}
        data-test-id="advanced-filters"
      >
        {filters.map((filter, index) => (
          <React.Fragment key={index}>
            {filter.view === HAdvancedFilterItemView.Switch && (
              <HFilterSwitch
                {...filter.HFilterSwitchProps}
              />
            )}

            {filter.view === HAdvancedFilterItemView.Section && (
              <HFilterSection
                {...filter.HFilterSectionProps}
                disabled={disabledView}
              />
            )}

            {index !== filters.length - 1 && <HDivider />}
          </React.Fragment>
        ))}
      </HBox>
    </HAdvancedFilterStyled>
  )
}
