// @flow
import type { Cookies } from 'react-cookie'

import type { ApplicationState } from '../../types/applicationState'
import type { TrackingSchema } from './types'

type Options = {
  is_auth_flow?: bool,
  screeing_question_id?: string,
  screening_question_is_mandatory?: bool,
  screening_question_type?: string,
  step_number?: number,
  total_number_of_steps?: number
}
export type ApplicationFlowType = {
  key: 'application_flow_version_context',
  options?: Options
}

export const SCHEMA_NAME = 'iglu:de.heyjobs/application_flow_version_context/jsonschema/1-0-2'

export default (state: ApplicationState, cookies: Cookies, options?: Options): Array<TrackingSchema> => {
  const job = state.currentJob.job
  const isQuickApply = job ? !!job.allow_quick_apply : null

  return [{
    schema: SCHEMA_NAME,
    data: {
      is_quick_apply: isQuickApply,
      step_number: options?.step_number,
      total_number_of_steps: options?.total_number_of_steps,
      screeing_question_id: options?.screeing_question_id,
      screening_question_type: options?.screening_question_type,
      screening_question_is_mandatory: options?.screening_question_is_mandatory,
      is_auth_flow: options?.is_auth_flow,
    },
  }]
}
