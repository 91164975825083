import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { AsyncThunkAction, PayloadAction, unwrapResult } from '@reduxjs/toolkit'
import { FetchJobTitlesParams, JobTitle } from 'user-profile-data'
import { fetchJobTitles } from '../../../actions/api/clients/jobTitles'
import { ThunkAPIConfig } from '../../../store/types'

type FetchJobTitlesThunk = AsyncThunkAction<JobTitle[], FetchJobTitlesParams, ThunkAPIConfig>

export const useJobTitlesRequest = () => {
  // FIXME(TPRE-1530): createAppThunk is incorrectly typed
  const dispatch = useDispatch<(action: FetchJobTitlesThunk) => Promise<PayloadAction<JobTitle[]>>>()

  const getJobTitles = useCallback(async (params: FetchJobTitlesParams) => {
    const action = await dispatch(fetchJobTitles(params))
    const payload = unwrapResult(action)

    return payload
  }, [dispatch])

  return getJobTitles
}
