import React from 'react'

import { GlassesIcon } from '../HAssets/Glasses'
import { HBox } from '../HBox'
import { HIcon } from '../HIcon'
import { HTypography } from '../HTypography'
import { COLORS, globalStyles } from '../theme'
import { JobCardHighlight } from './HJobCard.types'
import styled from '@emotion/styled'

const BorderedContainer = styled.div`
  border: 1px solid ${COLORS.primary_30};
  border-top: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`

const StyledContainer = styled(HBox)`
  box-shadow: ${globalStyles.shadow1.boxShadow};

  :hover {
    box-shadow: ${globalStyles.shadow3.boxShadow};

    .highlight-job-card-children-container {
      border: 1px solid ${COLORS.primary_50};
      border-top: 0;
    }
    
    .job-card-title {
      text-decoration: underline;
    }
  }
`

export const HighlightedContainer: React.FC<JobCardHighlight> =
 (props) => {
   const { text, children } = props
   return (
     <StyledContainer
       borderRadius={1}
       overflow='hidden'
       height='fit-content'
       className='highlight-job-card-container'
     >
       <HBox p={2} bgcolor={COLORS.primary_50} display='flex' gap={8} alignItems='center' justifyContent='center'>
         <HIcon Icon={GlassesIcon} size={16}/>
         <HTypography color='surface_white' variant='body2' weight='medium'>
           {text}
         </HTypography>
       </HBox>
       <BorderedContainer className='highlight-job-card-children-container'>
         {children}
       </BorderedContainer>
     </StyledContainer>
   )
 }
