// @flow

import datalayer from '../clients/datalayer'
import { snowplowTrackSelfDescribingEvent } from '../clients/snowplow'

import getState from '../shared/getState'

import getPageType from './pageview/getPageType'
import buildContexts, { type ExtraContextParam } from '../shared/buildContexts'
import buildExperimentContextAndData from '../contexts/ab_testing'
import { addDefaultPageContext } from '../contexts/page'

import {
  KEYWORD_SEARCH_LOGIN_BUTTON_CLICK_EVENT,
  APPLY_BUTTON_CLICK_EVENT,
  EXPERIMENT_EVENT,
  USER_LOGGED_IN_EVENT,
  AUTOSUGGEST_CLICK_EVENT,
  ONBOARDING_FLOW_EXIT_EVENT,
  ONBOARDING_FLOW_STEP_COMPLETED_EVENT,
  ONBOARDING_FLOW_SKIP_EVENT,
  KEYWORD_SEARCH_GCTS_EVENTS,
} from '../../tracking/eventNames'
import { unPrefixRouteName } from '../../routing/routeNamePrefix'

import includes from 'lodash/includes'

import type { TrackingSchema } from '../contexts/types'
import type { EventName } from '../types'

const v101SnowplowEvents: EventName[] = [
  APPLY_BUTTON_CLICK_EVENT,
  USER_LOGGED_IN_EVENT,
  ONBOARDING_FLOW_EXIT_EVENT,
  ONBOARDING_FLOW_STEP_COMPLETED_EVENT,
  ONBOARDING_FLOW_SKIP_EVENT,
]

const v102SnowplowEvents: EventName[] = [
  AUTOSUGGEST_CLICK_EVENT,
  EXPERIMENT_EVENT,
  KEYWORD_SEARCH_GCTS_EVENTS,
]

const v200SnowplowEvents: EventName[] = [
  KEYWORD_SEARCH_LOGIN_BUTTON_CLICK_EVENT,
]

const getSnowplowSchemaVersion = (eventName: EventName) => {
  if (includes(v101SnowplowEvents, eventName)) return '1-0-1'
  if (includes(v102SnowplowEvents, eventName)) return '1-0-2'
  if (includes(v200SnowplowEvents, eventName)) return '2-0-0'

  return '1-0-0'
}

const getSnowplowSchemaForEvent = (eventName: EventName) =>
  `iglu:de.heyjobs/${eventName}/jsonschema/${getSnowplowSchemaVersion(
    eventName
  )}`

const trackEvent = (
  eventName: EventName,
  eventData: $FlowTODO = {},
  extraContexts?: ExtraContextParam[] = [],
  builtContext?: TrackingSchema
) => {
  const state = getState()

  const prefixedCurrentRouteName = state.routing.route.name

  // Fall back to the initally loaded route if we don't have a route set for some reason.
  const currentRouteName = prefixedCurrentRouteName
    ? unPrefixRouteName(prefixedCurrentRouteName)
    : state.pageLoadRouteData.routeName

  const pageName = getPageType(
    currentRouteName,
    state.pageLoadRouteData.routeNames
  )

  const withDefaultPageContext = addDefaultPageContext(extraContexts)
  const baseEventContexts = buildContexts(state, pageName, withDefaultPageContext)
  const experimentContexts = buildExperimentContextAndData(eventName)

  const snowplowEventContexts = [...baseEventContexts, ...experimentContexts]

  if (builtContext && builtContext.schema) {
    snowplowEventContexts.push(builtContext)
  }

  const snowplowEventData = {
    data: eventData,
    schema: getSnowplowSchemaForEvent(eventName),
  }

  // Send Snowplow event.
  snowplowTrackSelfDescribingEvent(snowplowEventData, snowplowEventContexts)

  // Google Tag Manager generic event
  datalayer({
    event: 'genericJobSeekerEvent',
    eventCategory: eventName,
    eventAction: 'click',
    eventLabel: window.location.href,
    eventValue: 0,
  })
}

export default trackEvent
