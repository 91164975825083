
export enum Country {
  de = 'de',
  at = 'at',
}

export enum Locale {
  de = 'de',
  en = 'en',
}

export enum RequestStatus {
  pending = 'pending',
  fulfilled = 'fulfilled',
  rejected = 'rejected',
}

type CountryName = 'Germany' | 'Austria'

export const DEFAULT_COUNTRY_CODE = Country.de
export const DEFAULT_LOCALE = 'de'
export const MINIMUM_RELEVANT_RANKING_BOOST_FACTOR = 0.3

export const DEFAULT_CURRENCY = 'EUR'

export const AvailableCountries: Record<CountryName, COUNTRY> = {
  Germany: 'de',
  Austria: 'at',
}

export const CountryCodeToCountry: Record<COUNTRY, CountryName> = {
  de: 'Germany',
  at: 'Austria',
}

// Search
export const DEFAULT_SEARCH_PAGE_SIZE = 25

export const DEFAULT_ORDER_BY_OPTION = 'relevance'

export const DEFAULT_SEARCH_MODE = 'JOB_SEARCH'

export const CUSTOM_RANKING_ORDER_BY = 'custom_ranking desc'

export const DEFAULT_RANKING_IMPORTANCE_LEVEL = 'MEDIUM'

export const DEFAULT_RANKING_EXPRESSION = '((cpc / 200) * 4) + ranking_boost_factor * 3'

// GCTS
export const KEYWORD_SEARCH_GCTS_EVENTS = 'keyword_search_gcts_events'

export const FALLBACK_SEARCH_PAGE_SIZE = 10
export const GOOGLE_ONE_TAP_SCRIPT_ID = 'g_one_tap'

export const PRIVACY_POLICY_VERSION = '1.0.2'
export const PRIVACY_POLICY_DATE = '10.11.2022'

export const PROFILE_COMPLETENESS = 'profile_completeness'

export const TERMS_AND_CONDITIONS_CONSENT_EVENT = 'terms_and_conditions_consent'
export const TERMS_AND_CONDITIONS_VERSION = '1.1.0'
