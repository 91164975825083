import React from 'react'

export const DataLayer = `
  window.dataLayer = window.dataLayer || [];
  window.dataLayerBeforeConsent = window.dataLayerBeforeConsent || [];

  function gtag() {
    dataLayer.push(arguments);
  }
  gtag("consent", "default", {
    ad_user_data: "denied",
    ad_personalization: "denied",
    ad_storage: "denied",
    analytics_storage: "denied",
    wait_for_update: 2000
  });
  gtag("set", "ads_data_redaction", true);
`

export const DataLayerScript = () => (
  <script
    data-test-id='data-layer-script'
    id='data-layer-script'
    type='text/javascript'
    dangerouslySetInnerHTML={{ __html: DataLayer }}
  />
)

export const GTMScript = (tagManagerKey: string) => tagManagerKey &&
  `
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});
  var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;
  j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})
  (window,document,'script','dataLayer','${tagManagerKey}');
`

export const GtmNoScript = ({ tagManagerKey }: {tagManagerKey: string | undefined}) => {
  return (
    <noscript>
      {tagManagerKey && (
        <iframe
          src={`//www.googletagmanager.com/ns.html?id=${tagManagerKey}`}
          height='0'
          width='0'
          style={{ display: 'none', visibility: 'hidden' }}
        />
      )}
    </noscript>
  )
}
