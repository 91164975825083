import styled from '@emotion/styled'
import { COLORS, globalStyles } from '../theme'
import { Button, ButtonProps } from '@mui/material'
import { compose, margin, MarginProps } from '@mui/system'
import { isValidProp } from '../utils/isValidProp'
import { DefaultTheme } from '@mui/styles'

const createSpacingStyles = (
  props: MarginProps,
  theme: DefaultTheme,
): Record<string, string> => {
  const composed = compose(
    margin,
  )
  const themedProps = { ...props, theme }
  return composed({ ...themedProps })
}

export type SpacingProps = MarginProps & {
  xs?: MarginProps
  sm?: MarginProps
  md?: MarginProps
  lg?: MarginProps
  xl?: MarginProps
}

type StyledButtonProps = ButtonProps & { space?: SpacingProps }

export const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => isValidProp(prop.toString()),
})<StyledButtonProps>`
    padding-top: 8px;
    padding-bottom: 8px;
    height: 40px;
    min-width: 0;
    text-transform: none;
    white-space: nowrap;

    > .MuiButton-endIcon {
      margin-right: 0;
    }
    > .MuiButton-startIcon {
      margin-left: 0;
    }

    :focus-visible:after {
      content: '';
      display: block;
      position: absolute;
      top: -1px;
      bottom: -1px;
      left: -1px;
      right: -1px;
      border: 1px solid ${COLORS.primary_25};
    }
    :disabled {
      opacity: 0.4;
    }

    &.contained-primary {
      box-shadow: ${globalStyles.shadow1.boxShadow};
      background-color: ${COLORS.primary_50};
      color: ${COLORS.surface_white};
      &:hover {
        background-color: ${COLORS.primary_70};
        box-shadow: ${globalStyles.shadow2.boxShadow};
      }
      &:focus {
        box-shadow: ${globalStyles.shadow1.boxShadow};
      }
      &:disabled {
        background-color: ${COLORS.primary_50};
        color: ${COLORS.surface_white};
      }
    }
    &.contained-destructive {
      box-shadow: ${globalStyles.shadow1.boxShadow};
      background-color: ${COLORS.support_error_50};
      color: ${COLORS.surface_white};
      &:hover {
        background-color: ${COLORS.support_error_70};
        box-shadow: ${globalStyles.shadow2.boxShadow};
      }
      &:focus {
        box-shadow: ${globalStyles.shadow1.boxShadow};
      }
      &:disabled {
        background-color: ${COLORS.support_error_50};
        color: ${COLORS.surface_white};
      }
    }
    &.contained-neutral {
      box-shadow: ${globalStyles.shadow1.boxShadow};
      color: ${COLORS.surface_gray_80};
      background-color: ${COLORS.surface_gray_05};
      &:hover {
        color: ${COLORS.surface_gray_90};
        background-color: ${COLORS.surface_gray_10};
        box-shadow: ${globalStyles.shadow2.boxShadow};
      }
      &:focus {
        box-shadow: ${globalStyles.shadow1.boxShadow};
      }
      &:disabled {
        color: ${COLORS.surface_gray_80};
        background-color: ${COLORS.surface_gray_05};
      }
    }
    &.contained-special {
      box-shadow: ${globalStyles.shadow1.boxShadow};
      background-color: ${COLORS.accent_pink_50};
      color: ${COLORS.surface_white};
      &:hover {
        background-color: ${COLORS.accent_pink_70};
        box-shadow: ${globalStyles.shadow2.boxShadow};
      };
      &:focus {
        box-shadow: ${globalStyles.shadow1.boxShadow};
      };
      &:disabled {
        background-color: ${COLORS.accent_pink_50};
        color: ${COLORS.surface_white};
      }
    }
    &.outlined-primary {
      color: ${COLORS.primary_50};
      border-color: ${COLORS.primary_50};
      &:hover {
        background-color: ${COLORS.primary_05};
        color: ${COLORS.primary_70};
        border-color: ${COLORS.primary_70};
      };
      &:disabled {
        color: ${COLORS.primary_50};
        border-color: ${COLORS.primary_50};
      }
    }
    &.outlined-destructive {
      color: ${COLORS.support_error_50};
      border-color: ${COLORS.support_error_50};
      &:hover {
        background-color: ${COLORS.support_error_00};
        color: ${COLORS.support_error_70};
        border-color: ${COLORS.support_error_70};
      };
      &:disabled {
        color: ${COLORS.support_error_50};
        border-color: ${COLORS.support_error_50};
      }
    }
    &.outlined-neutral {
      color: ${COLORS.surface_gray_80};
      border-color: ${COLORS.surface_gray_80};
      &:hover {
        color: ${COLORS.surface_gray_90};
        border-color: ${COLORS.surface_gray_90};
        background-color: ${COLORS.surface_gray_05};
      };
      &:disabled {
        color: ${COLORS.surface_gray_80};
        border-color: ${COLORS.surface_gray_80};
      }
    }
    &.outlined-special {
      border-color: ${COLORS.accent_pink_50};
      color: ${COLORS.accent_pink_50};
      &:hover {
        background-color: ${COLORS.accent_pink_05};
        border-color: ${COLORS.accent_pink_70};
        color: ${COLORS.accent_pink_70};
      };
      &:disabled {
        border-color: ${COLORS.accent_pink_50};
        color: ${COLORS.accent_pink_50};
      }
    }

    &.text-primary {
      color: ${COLORS.primary_50};
      &:hover {
        color: ${COLORS.primary_70};
        background-color: ${COLORS.primary_05};
      }
      &:disabled {
        color: ${COLORS.primary_50};
      }
    }
    &.text-destructive {
      color: ${COLORS.support_error_50};
      &:hover {
        color: ${COLORS.support_error_70};
        background-color: ${COLORS.support_error_05};
      };
      &:disabled {
        color: ${COLORS.support_error_50};
      }
    }
    &.text-special {
      color: ${COLORS.accent_pink_50};
      &:hover {
        color: ${COLORS.accent_pink_70};
        background-color: ${COLORS.accent_pink_05};
      };
      &:disabled {
        color: ${COLORS.accent_pink_50};
      }
    }
    &.text-neutral {
      color: ${COLORS.surface_gray_80};
      &:hover {
        color: ${COLORS.surface_gray_90};
        background-color: ${COLORS.surface_gray_05};
      };
      &:disabled {
        color: ${COLORS.surface_gray_80};
      }
    }

    ${({ theme, space }) => space ? createSpacingStyles(space, theme) : ''}
    ${({ theme, space }) => space?.xs && theme.breakpoints.up('xs')} {
      ${({ theme, space }) =>
    space?.xs && createSpacingStyles(space?.xs, theme)}
    }
    ${({ theme, space }) => space?.sm && theme.breakpoints.up('sm')} {
      ${({ theme, space }) =>
    space?.sm && createSpacingStyles(space?.sm, theme)}
    }
    ${({ theme, space }) => space?.md && theme.breakpoints.up('md')} {
      ${({ theme, space }) =>
    space?.md && createSpacingStyles(space?.md, theme)}
    }
    ${({ theme, space }) => space?.lg && theme.breakpoints.up('lg')} {
      ${({ theme, space }) =>
    space?.lg && createSpacingStyles(space?.lg, theme)}
    }
    ${({ theme, space }) => space?.xl && theme.breakpoints.up('xl')} {
      ${({ theme, space }) =>
    space?.xl && createSpacingStyles(space?.xl, theme)}
    }
`
