// @flow

import config from '../../../config'

import type { TrackingSchema } from './types'

export const SCHEMA_NAME = 'iglu:de.heyjobs/app_context/jsonschema/1-0-1'

export default (): Array<TrackingSchema> => {
  return [{
    schema: SCHEMA_NAME,
    data: {
      version: config.scm.commitHash || 'unknown',
      appName: 'jobseeker-portal',
    },
  }]
}
