import React from 'react'
import styled from '@emotion/styled'
import { HBox } from '../HBox'
import { COLORS, globalStyles } from '../theme'

const ButtonMenuContainer = styled(HBox)`
  box-shadow: ${globalStyles.shadow3.boxShadow};
  border-radius: 4px;
  background-color: ${COLORS.surface_white};
`

interface HMenuProps extends React.HTMLProps<HTMLDivElement> {
  as?: undefined
  children: React.ReactNode[]
  spacing?: number
  spacingX?: number
  spacingY?: number
}

export const HMenu = React.forwardRef<HTMLUListElement, HMenuProps>(
  (props, ref) => {
    const {
      children,
      spacing = 1,
      spacingX = 4,
      spacingY = 4,
      ...others
    } = props

    return (
      <ButtonMenuContainer {...others} px={spacingX} py={spacingY} ref={ref}>
        {children.map((Child, i) => {
          return (
            <HBox key={i} mt={i === 0 ? 0 : spacing}>
              {Child}
            </HBox>
          )
        })}
      </ButtonMenuContainer>
    )
  },
)

HMenu.displayName = 'HMenu'
