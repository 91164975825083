
// @flow

import React from 'react'

export type ViewerContextParams = {|
  isMobile: bool
|}

const ViewerContext = React.createContext<ViewerContextParams>({ isMobile: false })

export default ViewerContext
