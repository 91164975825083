// @flow

// -------------------------------------------------------------------------------------------------
// This is a seperate bundle which provides an api for firing snowplow and GTM events,
// but also fires them when it first runs on pageload. The purpose of this is to get the
// tracking code downloaded and running before the user can navigate away. When we had all tracking
// bundled in with the much larger app bundle, this was much more likely to happen
// -------------------------------------------------------------------------------------------------

import 'promise-polyfill/src/polyfill'

import debug from './shared/debug'

// events

import trackPageView from './events/pageview'
import trackEvent from './events/event'
import trackStructuredEvent from './events/structuredEvent'

import { cookieConsentInitializationHandler } from 'data-layer'

// misc

import setUserId from './misc/setUserId'
import setGTMUserDataForTikTok from './misc/setGTMUserDataForTikTok'
import getPageType from './events/pageview/getPageType'
import buildPageViewTrackingEventData from './events/pageview/buildPageViewTrackingEventData'
import getSnowplowPageViewId from './misc/getSnowplowPageViewId'
import buildUserConsentTrackingEventData from './misc/buildUserConsentTrackingEventData'

// the public api

export const HeyJobsTC = {
  // TODO: unify these names
  fireSnowplowPageView: trackPageView,
  fireSnowplowEvent: trackEvent,
  fireSnowplowStructuredEvent: trackStructuredEvent,
  setUserId,
  setGTMUserDataForTikTok,
  getPageType,
  buildPageViewTrackingEventData,
  getSnowplowPageViewId,
  buildUserConsentTrackingEventData,
  debugEvents: debug,
}

cookieConsentInitializationHandler()
