import { Location16 } from '@carbon/icons-react'
import { Divider } from '@mui/material'
import React from 'react'
import styled from '@emotion/styled'
import { HAutocompleteModal } from '../HAutocompleteModal/HAutocompleteModal'
import { COLORS } from '../theme'
import { HLocationRadiusSelect } from './HLocationRadiusSelector'
import { LocationAutocompleteOption } from './LocationAutocompleteOption'
import { HLocationAutocompleteProps } from './types'

const maxVisibleElements = 6
const visibleElementsHeight = 36
const optionListVerticalPadding = 16
const optionListHeight =
  (maxVisibleElements * visibleElementsHeight) + optionListVerticalPadding

const StyledDivider = styled(Divider)`
  height: 20px;
  background-color: ${COLORS.surface_gray_10};
  top: 50%;
  transform: translateY(-50%);
  margin-left: 10px;
`

export const HLocationAutocomplete: React.FC<HLocationAutocompleteProps> = ({
  inputValue,
  hideIcon,
  options,
  actionButtonText,
  placeholder,
  icon = Location16,
  onClearInput,
  onInputChange,
  HInputProps,
  showRadiusSelector = true,
  onRadiusChange,
  radiusNoneLabel,
  radiusValue,
  fullWidth,
  onChange,
}) => {
  const endAdornment = (
    <>
      {showRadiusSelector && inputValue?.length > 0 && (
        <>
          <StyledDivider orientation="vertical" flexItem />
          <HLocationRadiusSelect
            radiusNoneLabel={radiusNoneLabel}
            value={radiusValue}
            onChange={(value) => {
              onRadiusChange?.(value)
            }}
          />
        </>
      )}
    </>
  )

  return (
    <HAutocompleteModal
      actionButtonText={actionButtonText}
      onClearInput={onClearInput}
      AutoCompleteProps={{
        openOnFocus: true,
        inputValue,
        onChange: (event, value) => {
          onChange?.(value?.description ?? value)
        },
        ListboxProps: {
          style: {
            maxHeight: optionListHeight,
          },
        },
        options: options,
        enableSearch: true,
        freeSolo: true,
        fullWidth,
        getOptionLabel: (option) => {
          return option?.description ?? option
        },
        HInputProps: {
          ...HInputProps,
          placeholder,
          endAdornment: endAdornment,
          dataTestId: 'location-autocomplete-input',
        },
        onInputChange,
        icon: (!hideIcon && icon),
        renderOption: (props, option, state) =>
          LocationAutocompleteOption({
            props,
            option,
            state,
          }),
      }}
    />
  )
}
