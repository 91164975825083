// @flow

import type { ApplicationState } from '../types/applicationState'
import { runningInBrowser } from './actions/support/base'
import config from '../../config'
import localStorageUtils from '../helpers/localStorageUtils'

export type APIHTTPHeaders = {|
  'Authorization'?: string,
  'Device-Token'?: string,
  'User-Agent'?: string,
  'X-Application-Path'?: string,
  'X-Auth-Token'?: string,
  'X-Client-Address'?: string,
  'X-Client-Country'?: string,
  'X-Device-Type'?: string,
  'X-Locale': string,
  'X-Unregistered-Jobseeker-Id'?: string,
|}

export const getReqHeaders = (state: ApplicationState): APIHTTPHeaders => {
  const headers: APIHTTPHeaders = {
    'X-Locale': (state.intlData && state.intlData.locale) || config.locale.default,
  }

  if (state.routing.location?.pathname) {
    headers['X-Application-Path'] = state.routing.location.pathname
  }

  if (state.request.deviceType) {
    headers['X-Device-Type'] = state.request.deviceType
  }

  if (state.session.device.token) {
    headers['Device-Token'] = state.session.device.token
  }

  const unregisteredJobseekerId = localStorageUtils.getItem(localStorageUtils.identifiers.UNREGISTERED_JOBSEEKER_ID)

  if (unregisteredJobseekerId) {
    headers['X-Unregistered-Jobseeker-Id'] = unregisteredJobseekerId
  }

  if (!runningInBrowser) {
    headers['User-Agent'] = `jobseeker-portal/${config.scm.commitHash || 'unknown'}`

    if (state.request.originAddress) {
      headers['X-Client-Address'] = state.request.originAddress
    }

    if (state.request.originCountry) {
      headers['X-Client-Country'] = state.request.originCountry
    }
  }

  return headers
}
