// @flow
import * as actions from '../constants/actions'
import { cleanOnLocationChange } from './utils/cleanOn'
import { FULFILLED, REJECTED } from './promiseUtil'
import {
  updatePostSignupDataInSessionStorage,
  postSignupIdentifiers,
  removePostSignupDataFromSessionStorage,
} from '../helpers/postSignupUtils.ts'

export type LoginModalState = {
  emailDoesNotExist: bool,
  emailSent: bool,
  error: ?bool,
  initialEmail: string,
  isBookmarksPage: bool,
  isRegisterRetry: bool,
  isRetry: bool,
  isVerificationSigninLink: bool,
  postRegisterAction: string,
  redirect: ?string,
  reservedEmail: string,
  reservedName: string,
  source: string,
  visible: bool
}

const initialState: LoginModalState = {
  visible: false,
  redirect: null,
  isRetry: false,
  isRegisterRetry: false,
  emailSent: false,
  emailDoesNotExist: false,
  initialEmail: '',
  error: null,
  isVerificationSigninLink: false,
  reservedEmail: '',
  reservedName: '',
  isBookmarksPage: false,
  postRegisterAction: '',
  source: '',
}

const reducer = (state: LoginModalState = initialState, action) => {
  switch (action.type) {
    case actions.SHOW_LOGIN_MODAL:
      updatePostSignupDataInSessionStorage({ tracking: { authLocation: action.options.source } })

      return {
        ...initialState,
        visible: true,
        redirect: action.options.redirect,
        isRetry: !!action.options.isRetry,
        isRegisterRetry: !!action.options.isRegisterRetry,
        initialEmail: action.options.initialEmail || '',
        error: action.options.error || null,
        isVerificationSigninLink: !!action.options.isVerificationSigninLink,
        isUserVerified: !!action.options.isUserVerified,
        isBookmarksPage: !!action.options.isBookmarksPage,
        reservedEmail: state.reservedEmail,
        reservedName: state.reservedName,
        activeTab: action.options.activeTab,
        source: action.options.source,
      }
    case actions.HIDE_LOGIN_MODAL:
      removePostSignupDataFromSessionStorage(postSignupIdentifiers.tracking)

      return {
        ...state,
        visible: false,
        emailSent: false,
        emailDoesNotExist: false,
        initialEmail: '',
        error: null,
        isVerificationSigninLink: false,
        source: null,
      }
    case FULFILLED(actions.REQUEST_SIGN_IN_EMAIL):
      return {
        ...state,
        emailSent: true,
        isRetry: false,
        error: null,
      }
    case REJECTED(actions.REQUEST_SIGN_IN_EMAIL):
      return {
        ...state,
        error: action.payload.errors,
        isRetry: true,
      }
    case FULFILLED(actions.REGISTER_MODAL_SIGNUP):
      return {
        ...state,
        source: 'register_modal_signup',
        emailSent: true,
        isRegisterRetry: false,
        error: null,
      }
    case REJECTED(actions.REGISTER_MODAL_SIGNUP):
      return {
        ...state,
        error: action.payload.errors,
        isRegisterRetry: true,
      }
    case FULFILLED(actions.GET_USER_STATUS_BY_LOGIN_TOKEN):
      return {
        ...state,
        isUserVerified: true,
      }
    case REJECTED(actions.GET_USER_STATUS_BY_LOGIN_TOKEN):
      return {
        ...state,
        isUserVerified: false,
      }
    case actions.SET_POST_REGISTER_ACTION:
      return {
        ...state,
        postRegisterAction: action.actionName,
      }
    case actions.CLEAN_POST_REGISTER_ACTION:
      return {
        ...state,
        postRegisterAction: '',
      }
    case actions.RESERVE_ENTERED_DATA:
      return {
        ...state,
        reservedEmail: action.email,
        reservedName: action.name || state.reservedName,
      }
    case actions.SET_REDIRECT_PATH:
      return {
        ...state,
        redirect: action.redirect,
      }
    case actions.SHOW_JOB_ALERT_MODAL:
      return {
        ...state,
        source: 'job-alert-modal',
        redirect: action.options.redirect,
      }
    default:
      return state
  }
}

export default cleanOnLocationChange(reducer)
