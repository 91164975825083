export const employmentTypes = Object.freeze([
  'trainee',
  'permanent_employment',
  'mini_job',
  'temporary_help',
  'internship',
  'working_student',
  'self_employment',
  'dual_study',
  'apprenticeship'
] as const)

export type TEmploymentType = typeof employmentTypes[number];
