import React, { useEffect, useMemo } from 'react'
import { COLORS, HBox, HButton, HTypography } from 'talent-ui'
import { useOnboarding, useOnboardingFlow } from '../../common/use-onboarding-flow'
import { FormattedMessage, IntlShape } from 'react-intl'
import { Building24, CarbonIconType, Portfolio24 } from '@carbon/icons-react'
import { GlobalLoader } from 'talent-ui/src/HAssets/GlobalLoader'
import { maximumAmountOfDataPoints } from './onboarding-success'
import { NoDataVariant } from './no-data-variant'
import { SuccessMessage } from './success-message'
import { useMediaQuery } from '@mui/material'
import { OnBoardingSearchParams } from '../../types/flow'
import { WorkingHours } from '../../types/api-types'
import { FullTimePartTime } from 'user-profile-data'

const CountryCodeToNames: {[key: string]: string} = Object.freeze({
  at: 'austria',
  de: 'germany'
})

export const countryCodeToName = (countryCode: string) => CountryCodeToNames[countryCode] || 'germany'

const onBoardingWorkingHoursToSearchParams = (workingHours: WorkingHours, intl: IntlShape) => {
  if (workingHours === FullTimePartTime) {
    return [
      intl.formatMessage({ id: 'job_keyword_search_filters_working_hours_full_time' }),
      intl.formatMessage({ id: 'job_keyword_search_filters_working_hours_part_time' })
    ]
  }

  return [intl.formatMessage({ id: `job_keyword_search_filters_working_hours_${workingHours}` })]
}

const CountIcon: React.FC<{count: number, text: string, Icon: CarbonIconType, textId: string}> = ({ textId, count, Icon, text }) => (
  <HBox
    display='flex'
    alignItems='center'
  >
    <HBox
      bgcolor={COLORS.primary_15}
      borderRadius='50%'
      width='48px'
      height='48px'
      display='flex'
      justifyContent='center'
      alignItems='center'
      mr={4}
    >
      <Icon color={COLORS.primary_50} />
    </HBox>
    <HBox
      display='flex'
      flexDirection='column'
      justifyContent='center'
      gap={8}
    >
      <HTypography
        variant='h5'
        weight='medium'
        color='surface_gray_90'
        lg={{ variant: 'h4' }}
      >
        <FormattedMessage
          id={textId}
          values={{ n: count }}
        />
      </HTypography>
      <HTypography
        variant='body2'
        color='surface_gray_60'
        lg={{ variant: 'body1' }}
      >
        {text}
      </HTypography>
    </HBox>
  </HBox>
)

export const VariantWithData: React.FC<{jobData: OnBoardingSearchParams, dataPoints: number}> = ({
  jobData, dataPoints
}) => {
  const { country, intl, search, redirectToAllowedFilters, searchData } = useOnboarding()
  const { onRestartFlow, closeOnBoardingWithNoTracking } = useOnboardingFlow()

  const { numJobs, isJobLoading, numCompanies } = searchData

  const useFullWidthButton = useMediaQuery((theme: any) => theme.breakpoints.down('lg'))

  const onboardingData = useMemo(() => ({
    title: jobData.job_title?.title,
    location: jobData.location?.address,
    employmentTypes: jobData.employment_type?.map((employmentType) => intl.formatMessage({
      id: `onboarding_flow_employment_type_${employmentType}`
    })) || [],
    workingHours: jobData.working_hours_type
      ? onBoardingWorkingHoursToSearchParams(jobData.working_hours_type, intl)
      : []
  }), [jobData, intl])

  useEffect(() => {
    search(onboardingData)
  }, [onboardingData, search])

  const onShowMatchingJobs = () => {
    closeOnBoardingWithNoTracking()

    redirectToAllowedFilters(onboardingData)
  }

  if (isJobLoading) {
    return (
      <HBox
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
      >
        <GlobalLoader />
      </HBox>
    )
  } else if (numJobs === 0) {
    return (
      <NoDataVariant
        messageId='onboarding_flow_success_step_no_matched_jobs'
        restartButtonLabel='onboarding_flow_success_update_your_pref'
      />
    )
  }

  const location = jobData.location?.address
  const countryText = intl.formatMessage({ id: countryCodeToName(country) })

  const hasEveryValue = dataPoints === maximumAmountOfDataPoints
  const isAlmostThere = dataPoints === 2

  return (
    <>
      <SuccessMessage
        hasEveryValue={hasEveryValue}
        keyword={jobData.job_title?.title}
        location={location}
        isAlmostThere={isAlmostThere}
      />
      <CountIcon
        count={numJobs}
        text={`${intl.formatMessage({ id: 'text_in' })} ${(location ? (location + ', ') : '') + countryText}`}
        Icon={Portfolio24}
        textId='onboarding_flow_success_number_jobs'
      />
      {numCompanies > 0 && (
        <HBox mt={6}>
          <CountIcon
            count={numCompanies}
            text={intl.formatMessage({ id: 'are_hiring' })}
            Icon={Building24}
            textId='onboarding_flow_success_number_companies'
          />
        </HBox>
      )}
      <HBox
        marginTop='auto'
        mb={8}
        lg={{
          mt: 14
        }}
      >
        {
          isAlmostThere && (
            <HBox mb={6}>
              <HButton
                fullWidth={useFullWidthButton}
                onClick={onRestartFlow}
                variant='contained'
                color='primary'
                text={intl.formatMessage({ id: 'onboarding_flow_add_more_information' })}
                data-test-id='onboarding-flow-add-more-information-button'
              />
            </HBox>
          )
        }
        <HButton
          fullWidth={useFullWidthButton}
          onClick={onShowMatchingJobs}
          variant={isAlmostThere ? 'outlined' : 'contained'}
          color='primary'
          text={intl.formatMessage({ id: 'show_me_matching_jobs' })}
          data-test-id='onboarding-flow-show-matching-jobs-button'
        />
      </HBox>
    </>
  )
}
