// @flow

import datalayer from '../clients/datalayer'
import type { Job } from '../../types/job'
import type { ClientStatus } from '../../types/company'
import { CLIENT_COMPANY_FLAG } from '../../constants/base'
import shouldCPCBeTracked from '../misc/shoudCPCBeTracked'

import type { ApplicationState } from '../../types/applicationState'
import type { Cookies } from 'react-cookie'
import { getJobCPC } from '../contexts/cpc'

type PurchaseParams = {
  clientStatus: ClientStatus
}

export const getJobCPCInCents = (job: Job) => {
  const cpc = getJobCPC(job)

  return cpc && (cpc / 100) // convert from cents, divide only if not 0, null or undefined
}

export const shouldTrack = (job: Job, clientStatus: ClientStatus) => {
  const hasCPCBiggerThanZero = typeof job.cpc === 'number' ? job.cpc > 0 : false
  const isTrackable = shouldCPCBeTracked({
    job,
    requiredClientStatus: clientStatus,
  })

  return isTrackable && hasCPCBiggerThanZero
}

export const getTransactionData = (job: Job) => ({
  event: 'transaction',
  transactionId: new Date().getTime(), // just a timestamp,
  transactionAffiliation: 'talent-platform',
  transactionTotal: getJobCPCInCents(job),
  transactionTax: 0,
  transactionShipping: 0,
  transactionProducts: [{
    sku: job.id,
    name: job.title,
    category: job.company.client_status === CLIENT_COMPANY_FLAG ? 'client' : 'partner',
    price: getJobCPCInCents(job),
    quantity: 1,
  }],
})

const purchase = ({ clientStatus }: PurchaseParams) => (state: ApplicationState, cookieJar: Cookies) => {
  const { job } = state.currentJob

  if (job && shouldTrack(job, clientStatus)) {
    datalayer(getTransactionData(job))
  }
}

export default purchase
