import React from 'react'
import { COLORS } from '../theme'

export interface IHIconProps extends React.HTMLProps<HTMLSpanElement> {
  color?: keyof typeof COLORS
  Icon: any
  size?: number
}

export const HIcon: React.FC<IHIconProps> = ({
  children,
  color,
  Icon,
  style,
  size,
  ...props
}) => {
  const inlineStyles = {
    display: 'flex',
    color: color ? COLORS[color] : COLORS.inherit,
    ...style,
  }
  const svgProps = size ? { width: size, height: size } : {}

  return (
    <span style={inlineStyles} {...props}>
      <Icon style={svgProps} />
    </span>
  )
}
