import React from 'react'
import styled from 'styled-components'
import { COLORS, HBox, HTypography } from 'talent-ui'
import { IWeight } from 'talent-ui/src/HTypography/index.types'

const ContentContainer = styled(HBox)`
  border: 1px solid ${COLORS.surface_gray_10};
  max-width: 700px;
`

const List = styled.ol`
  padding-left: 16px;

  li {
    list-style: number;
  }
`

interface TermsAndConditionsType extends React.PropsWithChildren {
  text?: string | React.ReactNode,
  weight?: IWeight,
  style?: {},
  href?: string
}

const ListItem: React.FC<TermsAndConditionsType> = ({
  weight = 'regular',
  style = { marginTop: 16, marginBottom: 16, overflowWrap: 'break-word' as const },
  ...rest
}) =>
  <li>
    <HTypography
      weight={weight}
      style={style}
    >
      {rest.children}
    </HTypography>
  </li>

const UrlWrapper: React.ElementType<TermsAndConditionsType> = ({
  text,
  href,
  weight = 'semibold',
  style = { display: 'inline' },
}) =>
  <HTypography
    weight={weight}
    style={style}
    selectors={[
      {
        selector: 'a',
        props: {
          color: 'primary_50',
        },
      },
    ]}
  >
    <a
      target='_blank'
      rel='noopener noreferrer'
      href={href}
    >
      {text}
    </a>
  </HTypography>

export {
  ListItem,
  UrlWrapper,
  ContentContainer,
  List,
}
