import React from 'react'
import { Switch, SwitchProps } from '@mui/material'
import { SwitchContainer, classes } from './Switch.styles'

export interface HSwitchProps extends SwitchProps {
  disabled: boolean
  checked: boolean
}

export const HSwitch: React.FC<HSwitchProps> = ({
  disabled,
  checked,
  onChange,
  ...others
}) => {
  return (
    <SwitchContainer
      className={classes.container}
      tabIndex={!disabled ? 0 : undefined}
    >
      <Switch
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          checked: classes.checked,
          thumb: classes.thumb,
          track: classes.track,
        }}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        inputProps={{ 'aria-label': 'switch' }}
        tabIndex={0}
        {...others}
      />
    </SwitchContainer>
  )
}
