// @flow

import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import UploadReferencesSection from './UploadReferencesSection'
import UploadCvSection from './UploadCvSection'
import { HBox, HTypography } from 'talent-ui'
import { useMediaQuery } from '@mui/material'
import uniqueId from 'lodash/uniqueId'

type DocumentUploadProps = {
  'data-test-id'?: string,
  beforeCVUpload?: (file: File) => void,
  beforeReferenceUpload?: (files: File[]) => void,
  onCvRemove?: () => void,
  onCvUploadSuccess?: () => void,
  onReferenceRemove?: () => void,
  onReferenceUploadSuccess?: () => void,
  showCvOptionalText?: boolean,
  showReferencesOptionalText?: boolean
}

export const generateTemporaryIdForReferenceFile = (file: File) =>
  `${file.name}_${file.size}_${file.lastModified}_${uniqueId()}`

const DocumentUpload = (props: DocumentUploadProps) => {
  const matchesSm = useMediaQuery(theme => theme.breakpoints.down('md'))

  const cvKeySuffix = props.showCvOptionalText ? '_optional' : ''
  const referencesKeySuffix = props.showReferencesOptionalText ? '_optional' : ''

  return (
    <>
      <HBox
        data-test-id={props['data-test-id']}
      >
        <HBox>
          <HBox
            mt={matchesSm ? 8 : 10}
            mb={matchesSm ? 2 : 4}
          >
            <HTypography
              variant='overline1'
              uppercase
            >
              <FormattedMessage id={`profile_cv_label${cvKeySuffix}`} />
            </HTypography>
          </HBox>

          <UploadCvSection
            onUploadSuccess={props.onCvUploadSuccess}
            onCvRemove={props.onCvRemove}
            beforeUpload={props.beforeCVUpload}
          />
        </HBox>
        <HBox>
          <HBox
            mt={matchesSm ? 6 : 8}
            mb={matchesSm ? 2 : 4}
          >
            <HTypography
              variant='overline1'
              uppercase
            >
              <FormattedMessage id={`profile_references_label${referencesKeySuffix}`} />
            </HTypography>
          </HBox>
          <UploadReferencesSection
            showUpload
            onUploadSuccess={props.onReferenceUploadSuccess}
            onReferenceRemove={props.onReferenceRemove}
            beforeUpload={props.beforeReferenceUpload}
          />
        </HBox>
      </HBox>
    </>
  )
}

export default DocumentUpload
