import React from 'react'
import { IntlShape } from 'react-intl'
import { TEmploymentType } from './employment-types'
import { HBox, HFunnelSelect } from 'talent-ui'

export const EmploymentType: React.FC<{
    checked: boolean,
    employmentType: TEmploymentType,
    intl: IntlShape,
    manageTypeChecked: (employmentType: string) => void
  }> = ({ checked, employmentType, intl, manageTypeChecked }) => {
    return (
      <HBox>
        <HFunnelSelect
          id={employmentType}
          iconId={employmentType}
          variant='icon'
          icon='checkbox'
          text={intl.formatMessage({ id: `onboarding_flow_employment_type_${employmentType}` })}
          onClick={() => manageTypeChecked(employmentType)}
          selected={checked}
          data-test-id={employmentType}
        />
      </HBox>
    )
  }
