// @flow

import React from 'react'
import Loadable from 'react-loadable'
import isEmpty from 'lodash/isEmpty'
import { PageLoader } from '../../components/shared/loader'
import {
  withClientDataLoader,
} from '../../components/containers/withDataLoader'
import {
  getJobType,
  updateQuizAnswers,
  getJobsCount,
} from '../../actions/jobTypeQuiz'

import type { RouteData } from '../../types/common'
import type { Dispatch } from '../../store/configureStore'
import type { ApplicationState } from '../../types/applicationState'
import { getRoutesForLocale } from '../../routes'

export const clientDataLoader = async (
  dispatch: Dispatch,
  state: ApplicationState,
  routeData: RouteData,
  replace: Function
) => {
  const jobTypeCodename = routeData.params.codename
  const answers = routeData.location.query

  if (isEmpty(answers)) {
    const { locale } = state.intlData
    const { country } = state.locality
    const routes = getRoutesForLocale(locale)
    const countryRoutes = routes.country(country)

    return replace(countryRoutes.index)
  }

  const { value } = await dispatch(getJobType(jobTypeCodename))
  const searchOptions = {
    location: answers.location,
    keyword: value.localized_name,
    country: state.locality.country,
  }

  dispatch(updateQuizAnswers(answers))
  dispatch(getJobsCount(searchOptions, jobTypeCodename))
}

const LoadableJobTypeQuiz = Loadable<$FlowTODO, $FlowTODO>({
  loader: () => import('./JobType'),
  loading: () => <PageLoader />,
})

const JobTypeQuiz = withClientDataLoader(clientDataLoader)(LoadableJobTypeQuiz)

export default JobTypeQuiz
