// @flow

import React from 'react'
import EuroSymbol from '@mui/icons-material/EuroSymbol'

const CustomizableSvg = (props: any) => (
  <svg
    style={props.style}
    className={props.className}
    viewBox={props.viewBox}
    height={props.height}
    width={props.width}
  >
    {props.children}
  </svg>
)

const MaterialSvg = (props: any) => (
  <CustomizableSvg
    viewBox='0 0 24 24'
    {...props}
  >
    {props.children}
  </CustomizableSvg>
)

export const EnvelopeIcon = (props: any) => (
  <CustomizableSvg
    viewBox='0 0 24 24'
    {...props}
  >
    <g
      id='Symbols'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
    >
      <g id='ic_mail_grey'>
        <g id='Shape'>
          <polygon points='0 0 24 0 24 24 0 24' />
          <path
            d='M20,4 L4,4 C2.9,4 2.01,4.9 2.01,6 L2,18 C2,19.1 2.9,20 4,20 L20,20 C21.1,20 22,19.1 22,18 L22,6 C22,4.9 21.1,4 20,4 L20,4 Z M20,8 L12,13 L4,8 L4,6 L12,11 L20,6 L20,8 L20,8 Z'
            fill={(props.style && props.style.fill) || '#000000'}
          />
        </g>
      </g>
    </g>
  </CustomizableSvg>
)

export const EuroIcon = (props: any) => (
  <MaterialSvg {...props}>
    <EuroSymbol />
  </MaterialSvg>
)

export const BriefcaseIcon = (props: any) => (
  <MaterialSvg {...props}>
    <path
      d='M0 0h24v24H0z'
      fill='none'
    />
    <path d='M20 6h-4V4c0-1.11-.89-2-2-2h-4c-1.11 0-2 .89-2 2v2H4c-1.11 0-1.99.89-1.99 2L2 19c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V8c0-1.11-.89-2-2-2zm-6 0h-4V4h4v2z' />
  </MaterialSvg>
)

export const MapMarkerIcon = (props: any) => (
  <MaterialSvg {...props}>
    <path d='M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z' />
    <path
      d='M0 0h24v24H0z'
      fill='none'
    />
  </MaterialSvg>
)

export const ClockIcon = (props: any) => (
  <MaterialSvg {...props}>
    <path d='M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z' />
    <path
      d='M0 0h24v24H0z'
      fill='none'
    />
    <path d='M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z' />
  </MaterialSvg>
)

export const CalendarIcon = (props: any) => (
  <MaterialSvg {...props}>
    <path d='M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z' />
    <path
      d='M0 0h24v24H0z'
      fill='none'
    />
  </MaterialSvg>
)

export const CloseIcon = (props: any) => (
  <svg {...props}>
    <g
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
      fillOpacity='0.3'
    >
      <g fill='#000000'>
        <polygon points='19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12' />
      </g>
    </g>
  </svg>
)

export const AttachFileIcon = (props: any) => (
  <MaterialSvg {...props}>
    <path d='M16.5 6v11.5c0 2.21-1.79 4-4 4s-4-1.79-4-4V5c0-1.38 1.12-2.5 2.5-2.5s2.5 1.12 2.5 2.5v10.5c0 .55-.45 1-1 1s-1-.45-1-1V6H10v9.5c0 1.38 1.12 2.5 2.5 2.5s2.5-1.12 2.5-2.5V5c0-2.21-1.79-4-4-4S7 2.79 7 5v12.5c0 3.04 2.46 5.5 5.5 5.5s5.5-2.46 5.5-5.5V6h-1.5z' />
    <path
      d='M0 0h24v24H0z'
      fill='none'
    />
  </MaterialSvg>
)

export const InsertDriveFileIcon = (props: any) => (
  <MaterialSvg {...props}>
    <path d='M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z' />
    <path
      d='M0 0h24v24H0z'
      fill='none'
    />
  </MaterialSvg>
)

export const ClearIcon = (props: any) => (
  <MaterialSvg {...props}>
    <path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z' />
    <path
      d='M0 0h24v24H0z'
      fill='none'
    />
  </MaterialSvg>
)

export const CachedIcon = (props: any) => (
  <MaterialSvg {...props}>
    <path d='M19 8l-4 4h3c0 3.31-2.69 6-6 6-1.01 0-1.97-.25-2.8-.7l-1.46 1.46C8.97 19.54 10.43 20 12 20c4.42 0 8-3.58 8-8h3l-4-4zM6 12c0-3.31 2.69-6 6-6 1.01 0 1.97.25 2.8.7l1.46-1.46C15.03 4.46 13.57 4 12 4c-4.42 0-8 3.58-8 8H1l4 4 4-4H6z' />
    <path
      d='M0 0h24v24H0z'
      fill='none'
    />
  </MaterialSvg>
)

export const WarningIcon = (props: any) => (
  <MaterialSvg {...props}>
    <path
      d='M0 0h24v24H0z'
      fill='none'
    />
    <path d='M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z' />
  </MaterialSvg>
)

export const FlashOnIcon = (props: any) => (
  <MaterialSvg {...props}>
    <path
      d='M0 0h24v24H0z'
      fill='none'
    />
    <path d='M7 2v11h3v9l7-12h-4l4-8z' />
  </MaterialSvg>
)

export const LockIcon = (props: any) => (
  <MaterialSvg {...props}>
    <path
      d='M0 0h24v24H0z'
      fill='none'
    />
    <path d='M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z' />
  </MaterialSvg>
)

export const MailBoxIcon = (props: any) => (
  <CustomizableSvg
    width='296'
    height='281'
    viewBox='0 0 296 281'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M68.4203 127.389C68.7438 122.841 72.6808 119.416 77.2139 119.741L224.956 130.32C229.489 130.645 232.901 134.596 232.578 139.145L225.549 237.984C225.226 242.533 221.289 245.958 216.756 245.633L69.014 235.054C64.4809 234.729 61.0683 230.778 61.3918 226.229L68.4203 127.389Z'
      fill='#F4F8FF'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M66.3693 127.24C66.7736 121.554 71.6949 117.273 77.3613 117.679L225.103 128.259C230.77 128.665 235.035 133.603 234.631 139.289L227.602 238.129C227.198 243.815 222.277 248.096 216.61 247.69L68.8685 237.111C63.2021 236.705 58.9364 231.766 59.3408 226.08L66.3693 127.24ZM77.0684 121.797C73.6686 121.554 70.7158 124.122 70.4732 127.534L63.4447 226.374C63.2021 229.786 65.7615 232.749 69.1614 232.992L216.903 243.572C220.303 243.815 223.256 241.247 223.498 237.835L230.527 138.995C230.77 135.584 228.21 132.621 224.81 132.377L77.0684 121.797Z'
      fill='#4C526B'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M233.771 136.713L143.515 179.921C142.828 180.25 142.016 180.171 141.404 179.716L67.503 124.753L69.9529 121.436L142.86 175.66L232 132.986L233.771 136.713Z'
      fill='#4C526B'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M203.15 160.772C203.63 161.805 203.184 163.033 202.154 163.515L160.936 182.794C159.907 183.275 158.683 182.828 158.203 181.795C157.723 180.761 158.168 179.533 159.198 179.052L200.416 159.773C201.446 159.291 202.67 159.738 203.15 160.772Z'
      fill='#4C526B'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M61.801 267.18C61.801 266.055 62.7096 265.142 63.8304 265.142H291.943C293.064 265.142 293.972 266.055 293.972 267.18C293.972 268.305 293.064 269.217 291.943 269.217H63.8304C62.7096 269.217 61.801 268.305 61.801 267.18Z'
      fill='#4C526B'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M28.4766 278.042C28.4766 276.917 29.3853 276.004 30.5061 276.004H72.7682C73.889 276.004 74.7977 276.917 74.7977 278.042C74.7977 279.167 73.889 280.079 72.7682 280.079H30.5061C29.3853 280.079 28.4766 279.167 28.4766 278.042Z'
      fill='#4C526B'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 267.181C0 266.056 0.908625 265.144 2.02947 265.144H50.1431C51.2639 265.144 52.1726 266.056 52.1726 267.181C52.1726 268.306 51.2639 269.218 50.1431 269.218H2.02947C0.908625 269.218 0 268.306 0 267.181Z'
      fill='#4C526B'
    />
    <path
      d='M186.343 100.225L184.645 95.5695L192.735 72.0239L178.502 77.2513L176.785 72.5433L197.818 64.8184L199.438 69.2619L191.215 93.0965L206.502 87.4819L208.219 92.1899L186.343 100.225Z'
      fill='#C84182'
    />
    <path
      d='M227.301 70.6225L228.281 66.1521L246.226 51.8467L232.558 48.8282L233.55 44.307L253.748 48.7677L252.812 53.0349L234.62 67.4988L249.301 70.7409L248.309 75.2621L227.301 70.6225Z'
      fill='#C84182'
    />
    <path
      d='M254.691 20.0099L254.496 16.7927L263.903 3.63439L254.067 4.2331L253.87 0.979371L268.406 0.0946001L268.592 3.16554L259.048 16.479L269.613 15.8359L269.81 19.0896L254.691 20.0099Z'
      fill='#C84182'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M19.2831 98.875C18.2831 99.383 17.062 98.9813 16.5558 97.9778C15.9303 96.738 14.0299 95.7933 11.3081 95.8557C8.62166 95.9174 6.32818 96.9575 5.44407 98.2255C4.8015 99.1471 3.53611 99.3714 2.61774 98.7266C1.69937 98.0818 1.47579 96.812 2.11836 95.8904C4.05239 93.1166 7.89288 91.8599 11.2152 91.7836C14.5022 91.7082 18.4853 92.7844 20.1771 96.1381C20.6834 97.1416 20.2831 98.367 19.2831 98.875Z'
      fill='#E8EEF8'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M124.764 49.0462C124.534 47.9454 125.237 46.8658 126.334 46.6348C127.689 46.3494 129.09 44.7518 129.734 42.0973C130.37 39.4774 129.963 36.9851 128.971 35.8C128.25 34.9386 128.362 33.654 129.22 32.9307C130.079 32.2074 131.359 32.3193 132.079 33.1807C134.249 35.7733 134.464 39.8212 133.678 43.0613C132.9 46.2669 130.833 49.8492 127.167 50.6211C126.07 50.8521 124.994 50.147 124.764 49.0462Z'
      fill='#E8EEF8'
    />
    <path
      d='M56.877 28.5814C56.877 30.4683 55.3527 31.998 53.4724 31.998C51.5921 31.998 50.0678 30.4683 50.0678 28.5814C50.0678 26.6945 51.5921 25.1649 53.4724 25.1649C55.3527 25.1649 56.877 26.6945 56.877 28.5814Z'
      fill='#E8EEF8'
    />
    <path
      d='M97.4718 85.6581C97.4718 86.7829 96.5635 87.6947 95.443 87.6947C94.3225 87.6947 93.4142 86.7829 93.4142 85.6581C93.4142 84.5333 94.3225 83.6215 95.443 83.6215C96.5635 83.6215 97.4718 84.5333 97.4718 85.6581Z'
      fill='#E8EEF8'
    />
    <path
      d='M296 49.9246C296 51.0494 295.092 51.9612 293.971 51.9612C292.851 51.9612 291.942 51.0494 291.942 49.9246C291.942 48.7998 292.851 47.888 293.971 47.888C295.092 47.888 296 48.7998 296 49.9246Z'
      fill='#E8EEF8'
    />
  </CustomizableSvg>
)

export const MessagePreLoadingPlaceholder = (props: any) => (
  <CustomizableSvg
    width='175'
    height='157'
    viewBox='0 0 175 157'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.1123 35.6957C12.4158 36.0483 11.5654 35.7695 11.2128 35.0731C10.7772 34.2126 9.45358 33.5569 7.55789 33.6003C5.68688 33.643 4.08952 34.3649 3.47375 35.245C3.02622 35.8846 2.1449 36.0403 1.50528 35.5928C0.865659 35.1452 0.709942 34.2639 1.15748 33.6243C2.50448 31.6992 5.1793 30.827 7.49325 30.774C9.78253 30.7217 12.5567 31.4686 13.735 33.7962C14.0876 34.4927 13.8088 35.3431 13.1123 35.6957Z'
      fill='#F2E1E9'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M91.8768 18.7187C91.7165 17.9547 92.2059 17.2054 92.9699 17.0451C93.9138 16.8471 94.8898 15.7382 95.3385 13.8959C95.7814 12.0776 95.4976 10.3478 94.8069 9.52527C94.3049 8.92744 94.3826 8.03585 94.9804 7.53385C95.5782 7.03185 96.4698 7.10954 96.9718 7.70738C98.4828 9.50675 98.6329 12.3162 98.0852 14.565C97.5432 16.7898 96.1038 19.2761 93.5505 19.8118C92.7865 19.9721 92.0371 19.4828 91.8768 18.7187Z'
      fill='#F2E1E9'
    />
    <path
      d='M35.387 3.10755C35.387 4.41714 34.3253 5.47877 33.0158 5.47877C31.7062 5.47877 30.6445 4.41714 30.6445 3.10755C30.6445 1.79796 31.7062 0.736328 33.0158 0.736328C34.3253 0.736328 35.387 1.79796 35.387 3.10755Z'
      fill='#F2E1E9'
    />
    <path
      d='M174.096 77.6222C174.096 78.9318 173.034 79.9934 171.725 79.9934C170.415 79.9934 169.354 78.9318 169.354 77.6222C169.354 76.3126 170.415 75.251 171.725 75.251C173.034 75.251 174.096 76.3126 174.096 77.6222Z'
      fill='#F2E1E9'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M126.211 30.7715C124.809 30.7715 123.672 31.9084 123.672 33.3109C123.672 34.7133 124.809 35.8503 126.211 35.8503C127.614 35.8503 128.751 34.7133 128.751 33.3109C128.751 31.9084 127.614 30.7715 126.211 30.7715ZM121.672 33.3109C121.672 30.8038 123.704 28.7715 126.211 28.7715C128.718 28.7715 130.751 30.8038 130.751 33.3109C130.751 35.8179 128.718 37.8503 126.211 37.8503C123.704 37.8503 121.672 35.8179 121.672 33.3109Z'
      fill='#F2E1E9'
    />
    <path
      d='M56.5018 25.1068C56.5018 25.8875 55.8689 26.5203 55.0883 26.5203C54.3076 26.5203 53.6748 25.8875 53.6748 25.1068C53.6748 24.3262 54.3076 23.6934 55.0883 23.6934C55.8689 23.6934 56.5018 24.3262 56.5018 25.1068Z'
      fill='#F2E1E9'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M48.0898 155.462C48.0898 154.758 48.6604 154.188 49.3641 154.188H149.489C150.193 154.188 150.763 154.758 150.763 155.462C150.763 156.166 150.193 156.736 149.489 156.736H49.3641C48.6604 156.736 48.0898 156.166 48.0898 155.462Z'
      fill='#4C526B'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M20.0557 155.462C20.0557 154.758 20.6262 154.188 21.33 154.188H41.7186C42.4223 154.188 42.9929 154.758 42.9929 155.462C42.9929 156.166 42.4223 156.736 41.7186 156.736H21.33C20.6262 156.736 20.0557 156.166 20.0557 155.462Z'
      fill='#4C526B'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22.1611 48.6992C24.0813 46.779 26.6857 45.7002 29.4013 45.7002H99.1593C101.872 45.7002 104.474 46.7766 106.393 48.6931C108.313 50.6097 109.394 53.2097 109.398 55.9223V99.3096C109.398 102.025 108.32 104.63 106.399 106.55C104.479 108.47 101.875 109.549 99.1593 109.549H56.6583L35.2626 130.944C34.8443 131.363 34.2151 131.488 33.6686 131.261C33.122 131.035 32.7656 130.502 32.7656 129.91V109.563H29.4013C26.6857 109.563 24.0813 108.485 22.1611 106.564C20.2409 104.644 19.1621 102.04 19.1621 99.3242V55.9394C19.1621 53.2238 20.2409 50.6194 22.1611 48.6992ZM29.4013 48.6257C27.4616 48.6257 25.6013 49.3962 24.2297 50.7678C22.8581 52.1394 22.0876 53.9997 22.0876 55.9394V99.3242C22.0876 101.264 22.8581 103.124 24.2297 104.496C25.6013 105.867 27.4616 106.638 29.4013 106.638H34.2283C35.0362 106.638 35.6911 107.293 35.6911 108.101V126.379L55.0181 107.052C55.2924 106.777 55.6644 106.623 56.0524 106.623H99.1593C101.099 106.623 102.959 105.853 104.331 104.481C105.702 103.11 106.473 101.249 106.473 99.3096V55.9272C106.47 53.9896 105.698 52.1324 104.327 50.7635C102.955 49.3945 101.097 48.6257 99.1593 48.6257H29.4013Z'
      fill='#4C526B'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M111.842 60.4598C111.842 59.652 112.497 58.9971 113.305 58.9971H142.281C147.671 58.9971 151.658 63.9555 151.658 69.6019V114.947C151.658 120.595 147.654 125.552 142.281 125.552H139.356V146.82C139.356 147.431 138.977 147.977 138.404 148.19C137.832 148.404 137.188 148.239 136.788 147.778L117.493 125.508H79.1642C73.7781 125.508 69.7734 120.553 69.7734 114.903V113.645C69.7734 112.837 70.4283 112.183 71.2362 112.183C72.044 112.183 72.6989 112.837 72.6989 113.645V114.903C72.6989 119.346 75.7739 122.582 79.1642 122.582H118.161C118.585 122.582 118.989 122.767 119.266 123.087L136.43 142.898V124.089C136.43 123.281 137.085 122.626 137.893 122.626H142.281C145.655 122.626 148.732 119.391 148.732 114.947V69.6019C148.732 65.1555 145.668 61.9225 142.281 61.9225H113.305C112.497 61.9225 111.842 61.2677 111.842 60.4598Z'
      fill='#4C526B'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M31.8516 62.0868C31.8516 61.2789 32.5065 60.624 33.3143 60.624H94.0865C94.8944 60.624 95.5493 61.2789 95.5493 62.0868C95.5493 62.8946 94.8944 63.5495 94.0865 63.5495H33.3143C32.5065 63.5495 31.8516 62.8946 31.8516 62.0868Z'
      fill='#C84182'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M114.679 76.9637C114.679 76.1559 115.334 75.501 116.141 75.501H140.789C141.597 75.501 142.252 76.1559 142.252 76.9637C142.252 77.7716 141.597 78.4265 140.789 78.4265H116.141C115.334 78.4265 114.679 77.7716 114.679 76.9637Z'
      fill='#E6AF14'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M31.8516 76.7147C31.8516 75.9068 32.5065 75.252 33.3143 75.252H86.4801C87.288 75.252 87.9429 75.9068 87.9429 76.7147C87.9429 77.5225 87.288 78.1774 86.4801 78.1774H33.3143C32.5065 78.1774 31.8516 77.5225 31.8516 76.7147Z'
      fill='#C84182'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M114.679 91.5907C114.679 90.7828 115.334 90.1279 116.141 90.1279H137.598C138.406 90.1279 139.061 90.7828 139.061 91.5907C139.061 92.3985 138.406 93.0534 137.598 93.0534H116.141C115.334 93.0534 114.679 92.3985 114.679 91.5907Z'
      fill='#E6AF14'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M31.8516 91.3416C31.8516 90.5338 32.5065 89.8789 33.3143 89.8789H92.3641C93.1719 89.8789 93.8268 90.5338 93.8268 91.3416C93.8268 92.1495 93.1719 92.8044 92.3641 92.8044H33.3143C32.5065 92.8044 31.8516 92.1495 31.8516 91.3416Z'
      fill='#C84182'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M114.679 106.218C114.679 105.41 115.334 104.755 116.141 104.755H140.09C140.898 104.755 141.553 105.41 141.553 106.218C141.553 107.025 140.898 107.68 140.09 107.68H116.141C115.334 107.68 114.679 107.025 114.679 106.218Z'
      fill='#E6AF14'
    />
  </CustomizableSvg>
)

export const AnimatedThreeDotsLoader = () => (
  <CustomizableSvg
    version='1.1'
    x='0px'
    y='0px'
    viewBox='-25 0 100 100'
    enableBackground='new 0 0 0 0'
  >
    <circle
      fill='#3C69BE'
      stroke='none'
      cx='6'
      cy='50'
      r='6'
    >
      <animate
        attributeName='opacity'
        dur='1s'
        values='0;1;0'
        repeatCount='indefinite'
        begin='0.1'
      />
    </circle>
    <circle
      fill='#3C69BE'
      stroke='none'
      cx='26'
      cy='50'
      r='6'
    >
      <animate
        attributeName='opacity'
        dur='1s'
        values='0;1;0'
        repeatCount='indefinite'
        begin='0.2'
      />
    </circle>
    <circle
      fill='#3C69BE'
      stroke='none'
      cx='46'
      cy='50'
      r='6'
    >
      <animate
        attributeName='opacity'
        dur='1s'
        values='0;1;0'
        repeatCount='indefinite'
        begin='0.3'
      />
    </circle>
  </CustomizableSvg>
)

export const SubmitStepIcon = () => {
  return (
    <svg
      width='169'
      height='204'
      viewBox='0 0 169 204'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.86357 119.315C5.86357 119.762 5.50389 120.124 5.0602 120.124C4.61652 120.124 4.25684 119.762 4.25684 119.315C4.25684 118.868 4.61652 118.506 5.0602 118.506C5.50389 118.506 5.86357 118.868 5.86357 119.315Z'
        fill='#FAFCFF'
      />
      <path
        d='M150.05 119.315C150.05 119.762 149.69 120.124 149.247 120.124C148.803 120.124 148.443 119.762 148.443 119.315C148.443 118.868 148.803 118.506 149.247 118.506C149.69 118.506 150.05 118.868 150.05 119.315Z'
        fill='#FAFCFF'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M145.724 45.4103C145.211 45.6717 144.584 45.465 144.325 44.9487C144.004 44.3107 143.029 43.8246 141.633 43.8567C140.254 43.8885 139.078 44.4237 138.624 45.0761C138.295 45.5503 137.646 45.6658 137.174 45.334C136.703 45.0022 136.589 44.3488 136.918 43.8746C137.91 42.4473 139.881 41.8007 141.585 41.7614C143.271 41.7226 145.315 42.2764 146.182 44.002C146.442 44.5184 146.237 45.1489 145.724 45.4103Z'
        fill='#E6EFFF'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M58.0263 37.4541C57.8849 36.776 58.3165 36.1109 58.9903 35.9686C59.8227 35.7928 60.6833 34.8085 61.0791 33.1732C61.4697 31.5591 61.2194 30.0237 60.6102 29.2936C60.1675 28.7629 60.2361 27.9715 60.7633 27.5259C61.2905 27.0803 62.0768 27.1493 62.5195 27.6799C63.8519 29.2771 63.9844 31.7709 63.5013 33.7671C63.0234 35.7419 61.7539 37.9489 59.5022 38.4244C58.8285 38.5667 58.1677 38.1323 58.0263 37.4541Z'
        fill='#E6EFFF'
      />
      <path
        d='M127.433 23.5662C127.433 24.7287 126.497 25.671 125.342 25.671C124.187 25.671 123.251 24.7287 123.251 23.5662C123.251 22.4038 124.187 21.4614 125.342 21.4614C126.497 21.4614 127.433 22.4038 127.433 23.5662Z'
        fill='#E6EFFF'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M91.9281 15.9215C90.6968 15.9215 89.6986 16.9262 89.6986 18.1656C89.6986 19.405 90.6968 20.4097 91.9281 20.4097C93.1595 20.4097 94.1576 19.405 94.1576 18.1656C94.1576 16.9262 93.1595 15.9215 91.9281 15.9215ZM87.915 18.1656C87.915 15.9347 89.7118 14.1262 91.9281 14.1262C94.1445 14.1262 95.9413 15.9347 95.9413 18.1656C95.9413 20.3965 94.1445 22.2049 91.9281 22.2049C89.7118 22.2049 87.915 20.3965 87.915 18.1656Z'
        fill='#E6EFFF'
      />
      <path
        d='M45.7801 4.35404C45.7801 5.04697 45.2221 5.60871 44.5336 5.60871C43.8452 5.60871 43.2871 5.04697 43.2871 4.35404C43.2871 3.6611 43.8452 3.09937 44.5336 3.09937C45.2221 3.09937 45.7801 3.6611 45.7801 4.35404Z'
        fill='#E6EFFF'
      />
      <path
        d='M34.4501 40.5049C34.4501 41.1978 33.892 41.7596 33.2035 41.7596C32.5151 41.7596 31.957 41.1978 31.957 40.5049C31.957 39.812 32.5151 39.2502 33.2035 39.2502C33.892 39.2502 34.4501 39.812 34.4501 40.5049Z'
        fill='#E6EFFF'
      />
      <path
        d='M22.3499 20.5784C22.3499 21.066 21.9572 21.4613 21.4728 21.4613C20.9884 21.4613 20.5957 21.066 20.5957 20.5784C20.5957 20.0908 20.9884 19.6956 21.4728 19.6956C21.9572 19.6956 22.3499 20.0908 22.3499 20.5784Z'
        fill='#E6EFFF'
      />
      <path
        d='M163.114 14.1263C163.114 14.6138 162.721 15.0091 162.236 15.0091C161.752 15.0091 161.359 14.6138 161.359 14.1263C161.359 13.6387 161.752 13.2434 162.236 13.2434C162.721 13.2434 163.114 13.6387 163.114 14.1263Z'
        fill='#E6EFFF'
      />
      <path
        d='M117.135 0.510338C117.135 0.79219 116.908 1.02068 116.628 1.02068C116.348 1.02068 116.121 0.79219 116.121 0.510338C116.121 0.228486 116.348 0 116.628 0C116.908 0 117.135 0.228486 117.135 0.510338Z'
        fill='#E6EFFF'
      />
      <path
        d='M30.0771 70.2524L16.8506 70.2526V203.102H113.165V190.046'
        fill='#F4F9FE'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.8508 69.3549L30.0773 69.3547C30.3687 69.3547 30.6418 69.498 30.8086 69.7385L113.897 189.532C114.001 189.683 114.057 189.862 114.057 190.046V203.102C114.057 203.598 113.658 204 113.165 204H16.8508C16.3583 204 15.959 203.598 15.959 203.102V70.2525C15.959 69.7568 16.3583 69.3549 16.8508 69.3549ZM17.7426 71.1501V202.205H112.274V190.329L29.6123 71.15L17.7426 71.1501Z'
        fill='#4C526B'
      />
      <path
        d='M126.543 57.6855L30.2285 57.6857V190.536H126.543V177.479'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M30.2277 56.7883L126.542 56.7881C126.779 56.7881 127.006 56.8827 127.173 57.051C127.34 57.2193 127.434 57.4477 127.434 57.6857V190.536C127.434 191.032 127.035 191.433 126.542 191.433H30.2277C29.7352 191.433 29.3359 191.032 29.3359 190.536V57.6859C29.3359 57.1901 29.7352 56.7883 30.2277 56.7883ZM31.1195 58.5835V189.638H125.651V58.5834L31.1195 58.5835Z'
        fill='#4C526B'
      />
      <path
        d='M2.10674 132.407C2.10674 132.854 1.74706 133.216 1.30337 133.216C0.85968 133.216 0.5 132.854 0.5 132.407C0.5 131.961 0.85968 131.599 1.30337 131.599C1.74706 131.599 2.10674 131.961 2.10674 132.407Z'
        fill='#FAFCFF'
      />
      <path
        d='M146.294 132.407C146.294 132.854 145.935 133.216 145.491 133.216C145.047 133.216 144.688 132.854 144.688 132.407C144.688 131.961 145.047 131.599 145.491 131.599C145.935 131.599 146.294 131.961 146.294 132.407Z'
        fill='#FAFCFF'
      />
      <path
        d='M40.0371 137.377C40.0371 136.138 41.0353 135.133 42.2666 135.133H91.9885C93.2198 135.133 94.218 136.138 94.218 137.377C94.218 138.617 93.2198 139.621 91.9884 139.621H42.2666C41.0353 139.621 40.0371 138.617 40.0371 137.377Z'
        fill='#C3D5F8'
      />
      <path
        d='M40.0371 147C40.0371 145.761 41.0353 144.756 42.2666 144.756H114.503C115.734 144.756 116.732 145.761 116.732 147C116.732 148.24 115.734 149.245 114.503 149.245H42.2666C41.0353 149.245 40.0371 148.24 40.0371 147Z'
        fill='#E6EFFF'
      />
      <path
        d='M40.0371 127.503C40.0371 126.264 41.0353 125.259 42.2666 125.259H82.8211C84.0524 125.259 85.0506 126.264 85.0506 127.503C85.0506 128.742 84.0524 129.747 82.8211 129.747H42.2666C41.0353 129.747 40.0371 128.742 40.0371 127.503Z'
        fill='#C3D5F8'
      />
      <path
        d='M40.0371 156.874C40.0371 155.635 41.0353 154.63 42.2666 154.63H114.503C115.734 154.63 116.732 155.635 116.732 156.874C116.732 158.114 115.734 159.118 114.503 159.118H42.2666C41.0353 159.118 40.0371 158.114 40.0371 156.874Z'
        fill='#E6EFFF'
      />
      <path
        d='M40.0371 166.748C40.0371 165.509 41.0353 164.504 42.2666 164.504H114.503C115.734 164.504 116.732 165.509 116.732 166.748C116.732 167.988 115.734 168.993 114.503 168.993H42.2666C41.0353 168.993 40.0371 167.988 40.0371 166.748Z'
        fill='#E6EFFF'
      />
      <path
        d='M40.0371 176.622C40.0371 175.383 41.0353 174.378 42.2666 174.378H114.503C115.734 174.378 116.732 175.383 116.732 176.622C116.732 177.861 115.734 178.866 114.503 178.866H42.2666C41.0353 178.866 40.0371 177.861 40.0371 176.622Z'
        fill='#E6EFFF'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M143.907 83.2229C144.255 82.8724 144.82 82.8724 145.168 83.2229L152.105 90.2049L166.609 75.6063C166.957 75.2557 167.521 75.2557 167.87 75.6063C168.218 75.9568 168.218 76.5252 167.87 76.8757L152.735 92.1091C152.387 92.4596 151.822 92.4596 151.474 92.1091L143.907 84.4924C143.559 84.1418 143.559 83.5735 143.907 83.2229Z'
        fill='#C84182'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M143.907 113.254C144.255 112.904 144.82 112.904 145.168 113.254L152.105 120.236L166.609 105.638C166.957 105.287 167.521 105.287 167.87 105.638C168.218 105.988 168.218 106.557 167.87 106.907L152.735 122.141C152.387 122.491 151.822 122.491 151.474 122.141L143.907 114.524C143.559 114.173 143.559 113.605 143.907 113.254Z'
        fill='#C84182'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M143.907 143.285C144.255 142.935 144.82 142.935 145.168 143.285L152.105 150.267L166.609 135.669C166.957 135.318 167.521 135.318 167.87 135.669C168.218 136.019 168.218 136.588 167.87 136.938L152.735 152.172C152.387 152.522 151.822 152.522 151.474 152.172L143.907 144.555C143.559 144.204 143.559 143.636 143.907 143.285Z'
        fill='#C84182'
      />
      <path
        d='M71.5914 96.9633C73.4219 94.7476 74.695 92.121 75.3028 89.3058C75.9107 86.4907 75.8356 83.5696 75.0838 80.7899C74.3319 78.0101 72.9255 75.4533 70.9835 73.3357C69.0415 71.2182 66.621 69.6022 63.9268 68.6244C61.2325 67.6467 58.3438 67.3359 55.5049 67.7184C52.666 68.1009 49.9604 69.1655 47.6171 70.822C45.2737 72.4785 43.3614 74.6783 42.0421 77.2353C40.7227 79.7922 40.035 82.6312 40.0371 85.512C40.0381 89.7004 41.5056 93.7546 44.1822 96.9633L44.1567 96.9846C44.2459 97.0929 44.3479 97.1855 44.44 97.2913C44.5547 97.4241 44.6776 97.5478 44.797 97.677C45.154 98.066 45.5204 98.4403 45.9062 98.7927C46.0229 98.8992 46.1439 98.9994 46.2632 99.1026C46.6712 99.4564 47.0899 99.7915 47.5254 100.104C47.5817 100.145 47.6315 100.193 47.6881 100.233V100.217C50.6743 102.331 54.2365 103.465 57.8879 103.465C61.5393 103.465 65.1016 102.331 68.0877 100.217V100.233C68.1444 100.193 68.1942 100.144 68.2504 100.104C68.6855 99.7912 69.1047 99.4561 69.5127 99.1023C69.632 98.9998 69.753 98.899 69.8697 98.7924C70.2553 98.4399 70.6219 98.0656 70.9789 97.6768C71.0978 97.5478 71.2204 97.4239 71.3359 97.291C71.4275 97.1852 71.5299 97.0927 71.6192 96.9843L71.5914 96.9633ZM57.8868 75.2533C59.0215 75.2533 60.1308 75.5917 61.0743 76.2258C62.0178 76.8599 62.7532 77.7611 63.1874 78.8155C63.6217 79.8699 63.7353 81.0302 63.5139 82.1496C63.2926 83.2689 62.7461 84.2972 61.9437 85.1042C61.1413 85.9112 60.119 86.4608 59.0061 86.6834C57.8932 86.9061 56.7396 86.7918 55.6912 86.3551C54.6428 85.9183 53.7467 85.1787 53.1163 84.2297C52.4859 83.2808 52.1494 82.1651 52.1494 81.0238C52.1494 79.4934 52.7539 78.0256 53.8298 76.9434C54.9058 75.8612 56.3651 75.2533 57.8868 75.2533ZM47.696 96.9633C47.718 95.2795 48.3983 93.6722 49.5897 92.489C50.781 91.3059 52.3876 90.6421 54.0619 90.6413H61.7117C63.3859 90.6422 64.9925 91.306 66.1839 92.4891C67.3752 93.6722 68.0555 95.2795 68.0775 96.9633C65.2813 99.4975 61.6508 100.9 57.8868 100.9C54.1228 100.9 50.4922 99.4975 47.696 96.9633Z'
        fill='#E6EFFF'
      />
    </svg>
  )
}
