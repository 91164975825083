import { Wikis16 as LangChangeIcon } from '@carbon/icons-react'
import React from 'react'
import { LoggedOutMobileMenuProps } from '../HNavBar.types'
import { LoggedOutMenuItems } from '../DesktopMenu/LoggedOutMenuItems'
import { HBox } from '../../HBox'
import { HDivider } from '../../HDivider'
import { HTypography } from '../../HTypography'
import styled from '@emotion/styled'

const AlignedText = styled(HTypography)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`

export const LoggedOutMobileMenu: React.FC<LoggedOutMobileMenuProps> =
  (props) => {
    const {
      MobileMenuLabel,
      Component,
      componentProps,
    } = props.menuItems.languageChange

    return (
      <HBox
        height='100%'
        display='flex'
        flexDirection='column'
        alignItems='flex-start'
        justifyContent='flex-start'
        width='100%'
      >
        <HBox
          display='flex'
          gap={16}
          flexDirection='column'
          justifyContent='center'
          alignItems='flex-start'
        >
          <LoggedOutMenuItems {...props} platform='mobile' />
        </HBox>
        <HBox
          width='100%'
          py={8}
        >
          <HDivider variant='fullWidth' />
        </HBox>
        <AlignedText
          color='surface_gray_60'
          weight='medium'
          variant='button'
          selectors={[{ selector: ':hover', props: { color: 'surface_gray_80' } }]}
          component={Component}
          {...componentProps}
          data-test-id='lang-switch-mobile'
        >
          <LangChangeIcon />
          {MobileMenuLabel}
        </AlignedText>
      </HBox>
    )
  }
