import { createSlice } from '@reduxjs/toolkit'
import camelCase from 'lodash/camelCase'
import { getJobSearchPreferencesThunk, saveJobSearchPreferencesThunk, saveJobTitlesPreferencesThunk } from './jobSearchPreferencesThunk'
import { JobSearchPreferencesThunkError, JobSearchPreferencesValues, SalaryPreferences } from './types'
import { PENDING } from '../../reducers/promiseUtil'
import { LOGOUT } from '../../constants/actions.js'
import { ApiErrors } from '../onboarding/onboardingSlice'

export type JobSearchPreferencesState = {
  jobSearchPreferencesValues: JobSearchPreferencesValues,
  currentStatus: 'initial' | 'loading' | 'completed' | 'rejected',
  error: JobSearchPreferencesThunkError | ApiErrors | null | undefined,
  isFetched: boolean
}

export const initialState: JobSearchPreferencesState = {
  currentStatus: 'initial',
  jobSearchPreferencesValues: {
    location: [],
    workingHoursType: undefined as never,
    minimumSalary: {
      salary_value: '',
      salary_period: 'hour',
    },
    employmentType: [],
    jobTitle: [],
  },
  error: null,
  isFetched: false,
}

const JobSearchPreferencesSlice = createSlice({
  name: 'jobSearchPreferences',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getJobSearchPreferencesThunk.fulfilled, (state, action) => {
      state.jobSearchPreferencesValues = action.payload
      state.currentStatus = 'completed'
      state.isFetched = true
    })

    builder.addCase(getJobSearchPreferencesThunk.pending, (state) => {
      state.currentStatus = 'loading'
    })

    builder.addCase(getJobSearchPreferencesThunk.rejected, (state, action) => {
      const error = action.payload as JobSearchPreferencesThunkError

      // TODO(TPRE-1395): implement error handling
      state.currentStatus = 'rejected'
      state.error = error
    })

    builder.addCase(saveJobSearchPreferencesThunk.fulfilled, (state, action) => {
      state.currentStatus = 'completed'

      const key = camelCase(action.meta.arg.searchPreferenceName)

      if (key === 'minimumSalary') {
        state.jobSearchPreferencesValues[key] = {
          ...action.meta.arg.searchPreferenceValue as SalaryPreferences,
          status: 'filled',
        }
      } else {
        // @ts-expect-error TS can't be sure which type will be assigned to which key
        state.jobSearchPreferencesValues[key] = action.meta.arg.searchPreferenceValue
      }
    })

    builder.addCase(saveJobSearchPreferencesThunk.pending, (state) => {
      state.currentStatus = 'loading'
    })

    builder.addCase(saveJobSearchPreferencesThunk.rejected, (state, action) => {
      const error = action.payload as JobSearchPreferencesThunkError

      state.currentStatus = 'rejected'
      state.error = error
    })
    builder.addCase(saveJobTitlesPreferencesThunk.fulfilled, (state, action) => {
      state.jobSearchPreferencesValues.jobTitle = action.meta.arg.jobTitles
    })
    builder.addCase(saveJobTitlesPreferencesThunk.rejected, (state, action) => {
      const error = action.payload as ApiErrors

      state.error = error
    })

    builder.addCase(PENDING(LOGOUT), (state) => {
      state.currentStatus = initialState.currentStatus
      state.jobSearchPreferencesValues = initialState.jobSearchPreferencesValues
      state.error = initialState.error
      state.isFetched = initialState.isFetched
    })
  },
})

export default JobSearchPreferencesSlice.reducer
