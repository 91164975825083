// @flow

import { ofType } from 'redux-observable'
import { of, from } from 'rxjs'
import {
  map,
  switchMap,
  filter,
  withLatestFrom,
  catchError,
} from 'rxjs/operators'
import * as API from '../actions/api/clients'
import * as actions from '../constants/actions'
import { FULFILLED, REJECTED } from '../reducers/promiseUtil'
import { AUTOCOMPLETE_SEARCH_ENGINE_EXPERIMENT, HEYJOBS_ENGINE, MAXIMUM_SEARCH_QUERY_LENGTH } from '../constants/base'
import { getReqHeaders } from '../actions/requestHeaders'
import { GrowthBook } from '@growthbook/growthbook-react'

import type { Observable } from 'rxjs'
import type { ApplicationState } from '../types/applicationState'
import type { JobSearchCompleteRequestOptions } from '../actions/api/clients/search'

type Action = $FlowTODO

const REDUX_FORM_CHANGE = '@@redux-form/CHANGE'
const KEYWORD_SEARCH_FORM = 'KEYWORD_SEARCH_FORM'
const HOME_PAGE_SEARCH_FORM = 'HOME_PAGE_SEARCH'

const fetchSuggestions = (query: string, country: string, companySubdomain: ?string, searchEngine: ?string, state: ApplicationState) => {
  const options: JobSearchCompleteRequestOptions = {
    query,
    country_code: country,
  }

  if (companySubdomain) {
    options.company_subdomain = companySubdomain
    options.type = 'JOB_TITLE'
  }

  if (searchEngine && searchEngine !== 'unknown') {
    options.experiment_variant = searchEngine
  }

  return API.search.complete(options, { headers: getReqHeaders(state) })
}

const suggestKeywords = (growthbook: GrowthBook) => ([action, state]: [Action, ApplicationState]): Observable<Action> => {
  const { payload } = action
  const { country } = state.locality
  const companySubdomain =
    state.company.current && state.company.current.subdomain

  const trimmed = payload && payload.trim()

  const minimumLength = 2

  if (
    trimmed &&
      trimmed.length >= minimumLength &&
      trimmed.length < MAXIMUM_SEARCH_QUERY_LENGTH
  ) {
    const searchEngine = growthbook.evalFeature(AUTOCOMPLETE_SEARCH_ENGINE_EXPERIMENT)?.value
    const isHeyJobsEngine = searchEngine === HEYJOBS_ENGINE

    return fetchSuggestions(trimmed, country, companySubdomain, searchEngine, state).pipe(
      map(res => ({
        type: FULFILLED(actions.KEYWORD_SEARCH_SUGGESTIONS),
        payload: isHeyJobsEngine ? res : (res?.slice(0, 5) ?? []),
      }))
    )
  } else {
    return from([{
      type: FULFILLED(actions.KEYWORD_SEARCH_SUGGESTIONS),
      payload: [],
    }])
  }
}

export default (action$: Observable<Action>, state$: Observable<ApplicationState>, growthbook: GrowthBook): Observable<Action> =>
  action$.pipe(
    ofType(REDUX_FORM_CHANGE),
    filter(
      (action) =>
        [KEYWORD_SEARCH_FORM, HOME_PAGE_SEARCH_FORM].includes(action.meta.form) &&
        action.meta.field === 'keyword'
    ),
    withLatestFrom(state$),
    switchMap(suggestKeywords(growthbook)),
    catchError(error => of({
      type: REJECTED(actions.KEYWORD_SEARCH_SUGGESTIONS),
      payload: [],
      error,
    }))
  )
