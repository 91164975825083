import React from 'react'
import { SvgPinkAndWhite, SvgPinkAndYellow, SvgGray, SvgPinkAndYellowRoundel } from './svg'

interface LogoBuilderProps {
  variant: string
  width?: number
}

export const LogoBuilder: React.FC<LogoBuilderProps> = (props) => {
  const { variant } = props

  switch (variant) {
    case 'pink_white':
      return <SvgPinkAndWhite width={props.width} />
    case 'pink_yellow':
      return <SvgPinkAndYellow width={props.width} />
    case 'pink_yellow_roundel':
      return <SvgPinkAndYellowRoundel width={props.width} />
    default:
      return <SvgGray width={props.width} />
  }
}
