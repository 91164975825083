const isFeedbackParamKey = 'jr_feedback'

/** https://heyjobs.atlassian.net/wiki/spaces/Product/pages/3279749256/2024-Q2+-+PAD-+Recommendation+Feedback+-+Solution+Design#CRM-High-Level-Technical-concept */
const feedbackQueryParams = [
  isFeedbackParamKey,
  'job_recommendation_id',
  'job_id',
  'uid',
] as const

export type CrmFeedbackQueryParams = typeof feedbackQueryParams[number]

export const isNegativeCrmFeedback = (queryParams?: Record<string, string | unknown>) => {
  return queryParams?.[isFeedbackParamKey] === 'n'
}

/**
 * Gets query params to forward if the queryparams are for CRM feedback.
 * @param {Record<string, string | unknown>} queryParams - The query parameters.
 * @returns {string[] | undefined} - If it is a CRM feedback link, it returns an array of which
 *   query parameters should be maintained during a redirect.
 */
export const getCrmFeedbackParamsToForward = (queryParams?: Record<string, string | unknown>) => {
  if (queryParams?.[isFeedbackParamKey]) {
    const paramsToForward = Object.keys(queryParams)
      .filter((key) => feedbackQueryParams.includes(key as CrmFeedbackQueryParams))

    return paramsToForward
  }
}

export * from './toaster'
