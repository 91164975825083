import React from 'react'
import styled from '@emotion/styled'
import { HButton, IHButtonProps } from '../HButton'
import { HBox } from '../HBox'
import { globalStyles } from '../theme'

interface HBottomNavigationProps {
  leftActionProps?: IHButtonProps
  rightActionProps?: IHButtonProps
}

const StyledContainer = styled(HBox)`
  box-shadow: ${globalStyles.shadow3.boxShadow};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  height: 68px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 16px 48px;
    height: 72px;
  }
`

const capitalizeFirstLetter = (text?: string): string => {
  return text ? text.charAt(0).toUpperCase() + text.slice(1) : ''
}

export const HBottomNavigation: React.FC<HBottomNavigationProps> = (props) => {
  const { leftActionProps, rightActionProps } = props
  return (
    <StyledContainer>
      <HBox display='flex' alignItems='center' justifyContent='flex-start'>
        {leftActionProps &&
         // eslint-disable-next-line max-len
         (<HButton {...{ ...leftActionProps, text: capitalizeFirstLetter(leftActionProps.text) } }/>)}
      </HBox>
      <HBox display='flex' alignItems='center' justifyContent='flex-end'>
        {rightActionProps &&
        // eslint-disable-next-line max-len
        (<HButton {...{ ...rightActionProps, text: capitalizeFirstLetter(rightActionProps.text) }}/>)}
      </HBox>
    </StyledContainer>
  )
}
