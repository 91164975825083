// @flow
import * as React from 'react'
import styled, { keyframes, type StyledComponent } from 'styled-components'
import { $sizes, $colors, $fontWeights } from './dependencies/variables'
import { UI_ERROR_ENTER_TRANSITION_TIME_MS } from '../../constants/base'

type FieldErrorProps = {
  children?: React.Node,
  errorMessage?: string,
  id: string,
  show: bool
}

const fadeInAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

const fadeOutAnimation = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-5px);
  }
`

const FieldErrorContainer: StyledComponent<{}, *, *> = styled.div`
  visibility: hidden;
  height: 0px;
  margin-top: ${$sizes.base};
  margin-bottom: ${$sizes.lg};
  color: ${$colors.primary[100]};
  text-align: left;
  font-size: 12px;
  font-weight: ${$fontWeights.bold};
  transition: height .1s ease-out, opacity .2s ease-in-out;

  &.fadein {
    animation: ${fadeInAnimation} .3s cubic-bezier(0.4, 0, 1, 1) forwards;
  }
  &.fadeout {
    animation: ${fadeOutAnimation} .1s cubic-bezier(0.4, 0, 1, 1) forwards;
  }
`

const FieldError = (props: FieldErrorProps) => {
  const conRef = React.useRef<HTMLDivElement | null>(null)

  React.useEffect(() => {
    const element = conRef.current

    if (element === null) {
      return
    }
    if (props.show) {
      setTimeout(() => {
        element.style.visibility = 'visible'
        element.style.height = `${element.scrollHeight}px`
      }, UI_ERROR_ENTER_TRANSITION_TIME_MS)
    } else {
      element.classList.remove('fadein')
      element.classList.add('fadeout')

      setTimeout(() => {
        element.classList.remove('fadeout')
        element.style.height = '0'
        element.style.visibility = 'hidden'
        element.classList.add('fadein')
      }, UI_ERROR_ENTER_TRANSITION_TIME_MS)
    }
  }, [props.show])

  return (
    <FieldErrorContainer
      ref={conRef}
      className='fadein'
      data-test-id={`field-error-message-${props.id}`}
    >
      {props.children || props.errorMessage || null}
    </FieldErrorContainer>
  )
}

export default FieldError
