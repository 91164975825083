// @flow

import * as React from 'react'

import type { CVShape, CVError } from '../../../types/application'
import { HBox, HFile } from 'talent-ui'
import { injectIntl, type IntlShape } from 'react-intl'
import StyledErrorMessage from '../StyledErrorMessage'

// Take a document to create a handler function
type RemoveCvFunctionType = (doc: CVShape) => () => void

type UploadedDocumentsProps = {
  documents: Array<CVShape>,
  errors: Array<CVError>,
  intl: IntlShape,
  removeCv?: RemoveCvFunctionType
}

const UploadedDocuments = (props: UploadedDocumentsProps) => {
  const fileNameWithoutSuffx = (doc) => {
    const fileName = doc.file?.name || doc.file?.id
    // FIXME(TPRE-2189): cleanup, remove _temp suffix
    return fileName ? fileName.replace(/_cc(?:_temp)?\./, '.') : ''
  }

  return (
    <>
      {props.documents.map((doc) => {
        const error = doc.errors?.[0] ?? null

        return doc.file?.name && !doc.uploading && (
          <HBox
            mb={2}
            data-test-id='uploaded-document'
            key={doc.file.id || doc.file.temporaryId}
          >
            <HFile
              variant={props.removeCv ? 'active' : 'plain'}
              error={!!error}
              onRemoveClick={props.removeCv && props.removeCv(doc)}
              file={fileNameWithoutSuffx(doc)}
              filePath={doc.file?.url}
              loading={doc.converting || doc.uploading}
            />
            {error && (
              <StyledErrorMessage
                dataTestId='uploaded-document-error'
                errorMessage={props.intl.formatMessage(
                  { id: error?.key },
                  { ...error.values }
                )}
              />
            )}
          </HBox>
        )
      })}
    </>
  )
}

export default injectIntl(UploadedDocuments)
