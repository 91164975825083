import { Theme, useMediaQuery } from '@mui/material'
import { Location16 } from '@carbon/icons-react'
import React from 'react'
import { HPlaceholder } from '../HPlaceholder'
import { HTypography } from '../HTypography'
import { TApplicationHeaderProps } from './HApplicationCard.types'
import { HBox } from '../HBox'
import { HIcon } from '../HIcon'
import { hStyled } from '../theme'

const TitleContainer = hStyled(HBox)`
  &:hover {
    cursor: pointer;
  }
`

export const ApplicationCardHeader: React.FC<TApplicationHeaderProps> = ({
  isSmall,
  title,
  companyDisplayName,
  companyImage,
  location,
  companyPostalCode,
  onHeaderClick,
  toggleableMenu,
}) => {
  const isAboveLg = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))
  const address = `${companyPostalCode ?? ''} ${location ?? ''}`.trim()

  const jobLocation = address.length > 0
    ? (
      <HBox
        gap={8}
        display='flex'
        flexDirection='row'
      >
        <HIcon
          color='surface_gray_90'
          Icon={Location16}
          size={18}
        />
        <HTypography
          color='surface_gray_90'
          data-test-id='company-location'
          variant='body2'
          weight="regular"
        >
          { address }
        </HTypography>
      </HBox>
    )
    : null

  const jobCompanyName = (
    <HBox mt={2}>
      <HTypography
        color="surface_gray_90"
        data-test-id='company-name'
        fontSize={16}
        lineClamp={1}
        variant='body1'
        weight='regular'
        mt={2}
      >
        {companyDisplayName}
      </HTypography>
    </HBox>
  )

  const placeholder = (
    <HBox display='flex' justifyContent='space-between'>
      <HPlaceholder
        size={(isAboveLg && !isSmall) ? 'M' : 'S'}
        src={companyImage}
        alt={companyDisplayName}
        imgProps={{ style: { objectFit: 'contain' } }}
        data-test-id='company-logo-container'
      />

      {isSmall && toggleableMenu}
    </HBox>
  )

  return (
    <>
      {!isSmall && placeholder}
      <HBox
        flex={1}
        display='flex'
        flexDirection='column'
        gap={16}
      >
        {isSmall && placeholder}
        <div>
          <TitleContainer
            onClick={onHeaderClick}
            // FIXME(TPRE-2031): application-id instead of title
            data-test-id={`${title}-application-card-header`}
          >
            <HTypography
              variant='h5'
              weight='medium'
              color='primary_50'
              data-test-id='job-title'
              lineClamp={2}
            >
              {title}
            </HTypography>
          </TitleContainer>
          {jobCompanyName}
        </div>
        {jobLocation}
      </HBox>
    </>
  )
}
