import React from 'react'
import { HStatusBadge } from './HStatusBadge'
import { HTypography } from '../HTypography'
import { HBox } from '../HBox'
import {
  HApplicationCardContainer,
} from './HApplicationCard.styles'
import { HApplicationCardProps } from './HApplicationCard.types'
import { ApplicationCardHeader } from './HApplicationCardHeader'
import { ToggleableMenu } from './ToggleableMenu'
import { ApplicationCardButton } from './ApplicationCardButton'
import { useMediaQuery, Theme } from '@mui/material'
import { newBreakpoints } from '../theme/breakpoints'

export const HApplicationCard: React.FC<HApplicationCardProps> = ({
  size,
  companyDisplayName,
  companyImage,
  location,
  companyPostalCode,
  title,
  appliedAt,
  badgeStatus,
  // FIXME(TPRE-2031): when menuItems is empty the render is buggy
  menuItems,
  onMenuOpen,
  actionStatus,
  testId,
  LinkComponent,
  onHeaderClick,
}) => {
  const isBelowMd = useMediaQuery(
    (theme: Theme) => theme.breakpoints.down(newBreakpoints.values.md),
  )
  const isSmall = size === 'small' || isBelowMd

  const toggleableMenu = (
    <ToggleableMenu
      onMenuOpen={onMenuOpen}
      LinkComponent={LinkComponent}
      isMobile={isBelowMd}
      menuItems={menuItems}
      testId={testId}
    />
  )

  return (
    <HApplicationCardContainer
      $isSmall={isSmall}
      display='flex'
      p={{ xs: 4, lg: isSmall ? 4 : 6 }}
    >
      <HBox
        flex={1}
        display='flex'
        flexDirection={isSmall ? 'column' : 'row'}
        gap={16}
      >
        <ApplicationCardHeader
          isSmall={isSmall}
          title={title}
          companyDisplayName={companyDisplayName}
          companyImage={companyImage}
          location={location}
          companyPostalCode={companyPostalCode}
          onHeaderClick={onHeaderClick}
          toggleableMenu={toggleableMenu}
        />
        <HBox
          display='flex'
          flexDirection='column'
          gap={16}
          mx={isSmall ? 0 : 12}
        >
          <HBox display='flex' flexDirection='column' gap={8}>
            {appliedAt && (
              <HBox height={isSmall ? '18px' : undefined}>
                <HTypography
                  color="surface_gray_80"
                  data-test-id="application-date"
                >
                  {appliedAt}
                </HTypography>
              </HBox>
            )}
            {badgeStatus && <HStatusBadge {...badgeStatus} />}
          </HBox>
          <ApplicationCardButton
            LinkComponent={LinkComponent}
            actionStatus={actionStatus}
            isSmall={isSmall}
            isMobile={isBelowMd}
          />
        </HBox>
      </HBox>
      {!isSmall && toggleableMenu}
    </HApplicationCardContainer>
  )
}
