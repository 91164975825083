/* istanbul ignore file */
import * as React from 'react'
import type { PropsWithChildren } from 'react'
import { useState, useEffect } from 'react'
import { HDrawer } from 'talent-ui'
import { IconButton, Typography, Toolbar } from '@mui/material'
import { Close24 } from '@carbon/icons-react'
import type { DistributiveOmit } from '@mui/types'
import type { SwipeableDrawerProps as DProps } from '@mui/material/SwipeableDrawer/SwipeableDrawer'
import { DebugReportModalButton } from './debug-report-modal-button'

interface IDebugDrawer {
  hotkey?: string
  debugFetchParsedCvButton?: React.FC
}

type disableProps = 'open' | 'onOpen' | 'onClose'

export type IDebugDrawerProps =
  PropsWithChildren<IDebugDrawer & DistributiveOmit<DProps, disableProps>>

export const DebugDrawer: React.FC<IDebugDrawerProps> = ({ children, hotkey = '?', debugFetchParsedCvButton, ...others }) => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const onClickOpen = (e: KeyboardEvent) => {
      if (e.key === hotkey) {
        setIsOpen((value) => !value)
      }
    }
    window.addEventListener('keydown', onClickOpen)
    return () => { window.removeEventListener('keydown', onClickOpen) }
  }, [hotkey])

  return (
    <HDrawer
      anchor='left'
      open={isOpen}
      style={{ zIndex: 9001 }}
      PaperProps={{ style: { padding: 0, width: 450 } }}
      {...others}
      onOpen={() => { setIsOpen(true) }}
      onClose={() => { setIsOpen(false) }}
    >
      <>
        <Toolbar>
          <Typography
            variant='h6'
            color='inherit'
          >
            <IconButton onClick={() => { setIsOpen(false) }}>
              <Close24 />
            </IconButton>
          </Typography>
        </Toolbar>
        <DebugReportModalButton closeDrawer={() => { setIsOpen(false) }} />
        {debugFetchParsedCvButton && React.createElement(debugFetchParsedCvButton)}
        {children}
      </>
    </HDrawer>
  )
}
