import { useEffect } from 'react'
import { type UserProfileCompletionStatus } from 'user-profile-data'
import { PROFILE_COMPLETENESS } from '../../constants'
import { type fireSnowplowEvent } from '../../services/tracking/snowplow/tracker'

export const useTrackProfileCompletenessChange = (
  profileCompletionStatus: UserProfileCompletionStatus,
  fireTrackingEvent: typeof fireSnowplowEvent,
) => {
  // If percent not changed, but fields have changed, we still want to send the event
  const profileStatusString = JSON.stringify(profileCompletionStatus)

  useEffect(() => {
    if (profileCompletionStatus?.percent === undefined) {
      return
    }

    // FIXME(TPRE-2096): remove JSP dependency on this component (and remove any)
    fireTrackingEvent(PROFILE_COMPLETENESS as any, {
      ...profileCompletionStatus,
    })
  }, [fireTrackingEvent, profileCompletionStatus, profileStatusString])
}
