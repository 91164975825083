// @flow

import React from 'react'
import { Link as _Link } from 'react-router-dom'
import { isValidUrl } from '../../functions_/shared/isValidUrl'

type LinkProps = { children: React$Node, onClick?: () => void, to: string, ...}

const ExternalLink = (props: any) => {
  if (props.target === '_blank') {
    return (
      <a
        rel='noopener'
        {...props}
      />
    )
  }

  return <a {...props} />
}

// $FlowFixMe
const Link = React.forwardRef((props: LinkProps, ref) => {
  const isExternalUrl = isValidUrl(props.to)
  const LinkComponent = isExternalUrl ? ExternalLink : _Link
  const navigationProps = isExternalUrl ? { href: props.to, to: undefined } : { to: props.to }

  return (
    <LinkComponent
      {...props}
      {...navigationProps}
      ref={ref}
    >
      {props.children}
    </LinkComponent>
  )
})

export default Link
