import React, { useEffect, useState } from 'react'
import { HBox } from 'talent-ui'
import { CompletionProgress } from './completion-progress'
import { type ProfileCompletionStatusProps } from './profile-completion-status'

export type AnimatedCompletionProgressProps = {
  targetPercent: number
  animateFrom: number
  onComplete?: () => void
  animationDuration: number
  withCtaButton?: boolean
} & Pick<ProfileCompletionStatusProps, 'fireStructuredTrackingEvent' | 'LinkComponent' | 'routerData'>

const easeOutQuadratic = (currentTime: number, startValue: number, changeInValue: number, duration: number) => {
  currentTime /= duration
  return -changeInValue * currentTime * (currentTime - 2) + startValue
}

const useAnimatedNumber = ({
  startNumber,
  targetNumber,
  duration = 1000,
  interval = 30,
  onComplete,
}: {
  startNumber: number
  targetNumber: number
  duration?: number
  interval?: number
  onComplete?: () => void
}) => {
  const [displayNumber, setDisplayNumber] = useState(startNumber)

  useEffect(() => {
    const startTime = Date.now()
    const changeInValue = targetNumber - startNumber
    if (changeInValue === 0) { return }

    const timer = setInterval(() => {
      // NOTE: we subtract the interval here so that we can ensure the animation completes in the
      // specified duration.
      const durationForCalculation = duration - interval
      const now = Date.now()
      const progress = Math.min((now - startTime) / durationForCalculation, 1)
      const newValue = easeOutQuadratic(progress * durationForCalculation, startNumber, changeInValue, duration)

      setDisplayNumber(newValue)

      if (progress >= 1) {
        onComplete?.()
        clearInterval(timer)
      }
    }, interval)

    return () => { clearInterval(timer) }
  // disabling required missing dependency as adding onComplete messes up the animation
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetNumber, duration, interval, startNumber])

  // NOTE: we avoid rounding here so that animations based on this value can be smooth.
  return displayNumber
}

const AnimatedCompletionProgress = ({
  targetPercent,
  animateFrom,
  onComplete,
  animationDuration,
  withCtaButton,
  fireStructuredTrackingEvent,
  LinkComponent,
  routerData,
}: AnimatedCompletionProgressProps) => {
  const displayPercent = useAnimatedNumber({
    startNumber: animateFrom,
    targetNumber: targetPercent,
    duration: animationDuration,
    onComplete,
  })

  return (
    <HBox
      sx={{
        // disable built in animations so that we can animate the displayed percent
        // and the ring consistently
        '& .MuiCircularProgress-circle': {
          transition: 'none',
        },
      }}
    >
      <CompletionProgress
        percent={displayPercent}
        withCtaButton={withCtaButton}
        fireStructuredTrackingEvent={fireStructuredTrackingEvent}
        LinkComponent={LinkComponent}
        routerData={routerData}
      />
    </HBox>
  )
}

export default AnimatedCompletionProgress
