import React from 'react'

export const Facebook: React.FC = () => {
  return (
    <svg
      width='21'
      height='21'
      viewBox='0 0 21 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20.2444 11.0253C20.2444 5.47015 15.7386 0.964355 10.1835 0.964355C4.62836 0.964355 0.122559 5.47015 0.122559 11.0253C0.122559 16.0459 3.80108 20.2098 8.61146 20.9644V13.9335H6.05693V11.0253H8.61146V8.80873C8.61146 6.2876 10.1147 4.8944 12.4118 4.8944C13.5122 4.8944 14.6637 5.0909 14.6637 5.0909V7.56683H13.3943C12.1446 7.56683 11.7555 8.34302 11.7555 9.13885V11.0253H14.5458L14.0998 13.9335H11.7555V20.9644C16.5659 20.2098 20.2444 16.0479 20.2444 11.0253Z'
        fill='#707485'
      />
    </svg>
  )
}
