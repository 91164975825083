import React from 'react'

export const GlobalLoader: any = ({
  width = 200,
  height = 200,
  ...props
}: any) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox='0 0 100 100'
    preserveAspectRatio='xMidYMid'
  >
    <circle cx='50' cy='50' r='0' fill='none' stroke='#c84182' strokeWidth='3'>
      <animate
        attributeName='r'
        repeatCount='indefinite'
        dur='1.0989010989010988s'
        values='0;40'
        keyTimes='0;1'
        keySplines='0 0.2 0.8 1'
        calcMode='spline'
        begin='-0.5494505494505494s'
      ></animate>
      <animate
        attributeName='opacity'
        repeatCount='indefinite'
        dur='1.0989010989010988s'
        values='1;0'
        keyTimes='0;1'
        keySplines='0.2 0 0.8 1'
        calcMode='spline'
        begin='-0.5494505494505494s'
      ></animate>
    </circle>
    <circle cx='50' cy='50' r='0' fill='none' stroke='#e6af14' strokeWidth='3'>
      <animate
        attributeName='r'
        repeatCount='indefinite'
        dur='1.0989010989010988s'
        values='0;40'
        keyTimes='0;1'
        keySplines='0 0.2 0.8 1'
        calcMode='spline'
      ></animate>
      <animate
        attributeName='opacity'
        repeatCount='indefinite'
        dur='1.0989010989010988s'
        values='1;0'
        keyTimes='0;1'
        keySplines='0.2 0 0.8 1'
        calcMode='spline'
      ></animate>
    </circle>
  </svg>
)
