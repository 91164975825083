// @flow
import * as actions from '../constants/actions'
import { cleanOnLocationChange } from './utils/cleanOn'

export type LinkoutModalState = {
  visible: bool
}

const initialState: LinkoutModalState = {
  visible: false,
}

const reducer = (state: LinkoutModalState = initialState, action) => {
  switch (action.type) {
    case actions.SHOW_LINKOUT_MODAL:
      return {
        ...initialState,
        visible: true,
      }
    case actions.HIDE_LINKOUT_MODAL:
      return {
        ...state,
        visible: false,
      }
    default:
      return state
  }
}

export default cleanOnLocationChange(reducer)
