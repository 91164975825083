import { createSlice } from '@reduxjs/toolkit'

interface JobAlertModalState {
  isVisible: boolean;
}

const initialState: JobAlertModalState = {
  isVisible: false,
}

const JobAlertModalSlice = createSlice({
  name: 'jobAlertModal',
  initialState,
  reducers: {
    showJobAlertModal: (state) => {
      state.isVisible = true
    },
    hideJobAlertModal: (state) => {
      state.isVisible = false
    },
  },
})

export const { showJobAlertModal, hideJobAlertModal } = JobAlertModalSlice.actions

export default JobAlertModalSlice.reducer
