import React from 'react'

import SelectComponentWrapper from './select-component-wrapper'
import { useTranslate } from './hooks/use-translate'
import { LocationData, LocationSelector, LocationSelectorProps, MapsLocationsTypes } from 'location-utils'

type LocationPreferencesProps = {
  selectedLocations: LocationData[]
  setSelectedLocations: React.Dispatch<React.SetStateAction<LocationData[]>>
  variant: 'onboarding' | 'profile',
  showError?: boolean
  locale: string
  country: string
} & Pick<LocationSelectorProps, 'routeName' | 'autoSuggestionLocationClick' | 'removeTrackingData' | 'hookConfig'>

const variants = {
  onboarding: {
    texts: {
      select: 'onboarding_flow_location_step_select',
      selected: 'onboarding_flow_location_step_selected'
    },
    margins: {
      dividerTop: 8,
      selectorTop: 8
    }
  },
  profile: {
    texts: {
      select: 'profile_job_location_select',
      selected: 'profile_job_location_selected'
    },
    margins: {
      dividerTop: 6,
      selectorTop: 6
    }
  }
}

export const LocationPreferences = ({ locale, country, selectedLocations, setSelectedLocations, variant, showError, ...locationSelectorProps }: LocationPreferencesProps) => {
  const formatMessage = useTranslate()

  const { texts, margins } = variants[variant]

  const onDeleteLocation = (deletedLocation: LocationData) => {
    setSelectedLocations((oldLocations) => oldLocations.filter(existingLocation => existingLocation.address !== deletedLocation.address))
  }

  const onAddLocation = (location: LocationData) => {
    setSelectedLocations((oldLocations) => {
      if (oldLocations.some(existingLocation => existingLocation.address === location.address)) {
        return oldLocations
      }

      return [...oldLocations, location]
    })
  }

  return (
    <SelectComponentWrapper
      selectedItems={selectedLocations}
      onDeleteItem={onDeleteLocation}
      texts={texts}
      margins={margins}
      getChipPlaceholder={(location) => (location.address)}
      getChipTestId={(location) => (location.address)}
    >
      <LocationSelector
        HInputProps={{
          placeholder: formatMessage('city_location_placeholder')
        }}
        onChange={(_, value) => {
          onAddLocation(value)
        }}
        country={country}
        locale={locale}
        freeSolo
        shouldResetValue
        data-test-id='location'
        overrides={{
          types: [MapsLocationsTypes.Cities]
        }}
        error={showError}
        caption={showError ? formatMessage('profile_job_location_search_error') : undefined}
        {...locationSelectorProps}
      />
    </SelectComponentWrapper>
  )
}
