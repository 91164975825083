// @flow

// synchronous actions
export const SET_AB_TESTS_INITIALIZED: 'SET_AB_TESTS_INITIALIZED' =
  'SET_AB_TESTS_INITIALIZED'
export const SET_APP_INITIALIZED: 'SET_APP_INITIALIZED' =
  'SET_APP_INITIALIZED'
export const RECEIVE_PROTECTED_DATA: 'RECEIVE_PROTECTED_DATA' =
  'RECEIVE_PROTECTED_DATA'
export const FETCH_PROTECTED_DATA_REQUEST: 'FETCH_PROTECTED_DATA_REQUEST' =
  'FETCH_PROTECTED_DATA_REQUEST'
export const SET_JOBS: 'SET_JOBS' =
  'SET_JOBS'
export const SET_JOB_LIST_ERRORS: 'SET_JOB_LIST_ERRORS' =
  'SET_JOB_LIST_ERRORS'
export const CLEAR_JOBS: 'CLEAR_JOBS' =
  'CLEAR_JOBS'
export const UPDATE_JOB: 'UPDATE_JOB' =
  'UPDATE_JOB'
export const JOB_UPDATE_FAILED: 'JOB_UPDATE_FAILED' =
  'JOB_UPDATE_FAILED'
export const PRELOAD: 'PRELOAD' =
  'PRELOAD'
export const SHOW_MODAL: 'SHOW_MODAL' = 'SHOW_MODAL'
export const HIDE_MODAL: 'HIDE_MODAL' = 'HIDE_MODAL'
export const SHOW_LOGIN_MODAL: 'SHOW_LOGIN_MODAL' =
  'SHOW_LOGIN_MODAL'
export const HIDE_LOGIN_MODAL: 'HIDE_LOGIN_MODAL' =
  'HIDE_LOGIN_MODAL'
export const SHOW_TERMS_AND_CONDITIONS_MODAL: 'SHOW_TERMS_AND_CONDITIONS_MODAL' =
  'SHOW_TERMS_AND_CONDITIONS_MODAL'
export const HIDE_TERMS_AND_CONDITIONS_MODAL: 'HIDE_TERMS_AND_CONDITIONS_MODAL' =
  'HIDE_TERMS_AND_CONDITIONS_MODAL'
export const SHOW_SNACK_BAR: 'SHOW_SNACK_BAR' =
  'SHOW_SNACK_BAR'
export const HIDE_SNACK_BAR: 'HIDE_SNACK_BAR' =
  'HIDE_SNACK_BAR'
export const USER_DID_NOT_CONSENT_TERMS_AND_CONDITIONS: 'USER_DID_NOT_CONSENT_TERMS_AND_CONDITIONS' =
  'USER_DID_NOT_CONSENT_TERMS_AND_CONDITIONS'
export const HIDE_LINKOUT_MODAL: 'HIDE_LINKOUT_MODAL' =
  'HIDE_LINKOUT_MODAL'
export const SHOW_LINKOUT_MODAL: 'SHOW_LINKOUT_MODAL' =
  'SHOW_LINKOUT_MODAL'
export const REMOVE_QUERY_PARAM: 'REMOVE_QUERY_PARAM' = 'REMOVE_QUERY_PARAM'
export const HIDE_JOB_ALERT_MODAL: 'HIDE_JOB_ALERT_MODAL' =
  'HIDE_JOB_ALERT_MODAL'
export const SHOW_JOB_ALERT_MODAL: 'SHOW_JOB_ALERT_MODAL' =
  'SHOW_JOB_ALERT_MODAL'
export const SET_JOB_ALERT_MODAL_STATE: 'SET_JOB_ALERT_MODAL_STATE' =
  'SET_JOB_ALERT_MODAL_STATE'
export const SET_POST_REGISTER_ACTION: 'SET_POST_REGISTER_ACTION' =
  'SET_POST_REGISTER_ACTION'
export const CLEAN_POST_REGISTER_ACTION: 'CLEAN_POST_REGISTER_ACTION' =
  'CLEAN_POST_REGISTER_ACTION'
export const SHOW_SUCCESS_MODAL: 'SHOW_SUCCESS_MODAL' =
  'SHOW_SUCCESS_MODAL'
export const HIDE_SUCCESS_MODAL: 'HIDE_SUCCESS_MODAL' =
  'HIDE_SUCCESS_MODAL'
export const SHOW_NAV_MENU: 'SHOW_NAV_MENU' =
  'SHOW_NAV_MENU'
export const HIDE_NAV_MENU: 'HIDE_NAV_MENU' =
  'HIDE_NAV_MENU'
export const SHOW_EMAIL_JOB_TO_ME_MODAL: 'SHOW_EMAIL_JOB_TO_ME_MODAL' =
  'SHOW_EMAIL_JOB_TO_ME_MODAL'
export const HIDE_EMAIL_JOB_TO_ME_MODAL: 'HIDE_EMAIL_JOB_TO_ME_MODAL' =
  'HIDE_EMAIL_JOB_TO_ME_MODAL'
export const CLEAR_CURRENT_JOB: 'CLEAR_CURRENT_JOB' =
  'CLEAR_CURRENT_JOB'
export const INITIALIZE_AB_TESTS: 'INITIALIZE_AB_TESTS' =
  'INITIALIZE_AB_TESTS'
export const HIDE_COOKIE_BAR: 'HIDE_COOKIE_BAR' =
  'HIDE_COOKIE_BAR'
export const USE_FLOATING_COOKIE_BAR: 'USE_FLOATING_COOKIE_BAR' =
  'USE_FLOATING_COOKIE_BAR'
export const SET_CURRENT_JOB: 'SET_CURRENT_JOB' =
  'SET_CURRENT_JOB'
export const SHOW_LOGIN_APPLICATION_FORM: 'SHOW_LOGIN_APPLICATION_FORM' =
  'SHOW_LOGIN_APPLICATION_FORM'
export const SHOW_JOB_APPLICATION_ERROR: 'SHOW_JOB_APPLICATION_ERROR' =
  'SHOW_JOB_APPLICATION_ERROR'
export const HIDE_JOB_APPLICATION_ERROR: 'HIDE_JOB_APPLICATION_ERROR' =
  'HIDE_JOB_APPLICATION_ERROR'
export const SHOW_JOB_APPLICATION_ERROR_NOTIFIED: 'SHOW_JOB_APPLICATION_ERROR_NOTIFIED' =
  'SHOW_JOB_APPLICATION_ERROR_NOTIFIED'
export const SET_PAGE_LOAD_ROUTE_DATA: 'SET_PAGE_LOAD_ROUTE_DATA' =
  'SET_PAGE_LOAD_ROUTE_DATA'
export const SET_REQUEST_DETAILS: 'SET_REQUEST_DETAILS' =
  'SET_REQUEST_DETAILS'
export const SET_RESPONSE_STATUS: 'SET_RESPONSE_STATUS' =
  'SET_RESPONSE_STATUS'
export const SET_CACHE_CONTROL: 'SET_CACHE_CONTROL' =
  'SET_CACHE_CONTROL'
export const SET_TRANSLATIONS: 'SET_TRANSLATIONS' =
  'SET_TRANSLATIONS'
export const SET_COUNTRY: 'SET_COUNTRY' =
  'SET_COUNTRY'
export const SHOW_CRM_OPTIONS_MODAL: 'SHOW_CRM_OPTIONS_MODAL' =
  'SHOW_CRM_OPTIONS_MODAL'
export const HIDE_CRM_OPTIONS_MODAL: 'HIDE_CRM_OPTIONS_MODAL' =
  'HIDE_CRM_OPTIONS_MODAL'
export const TOGGLE_RECOMMENDATIONS_MODAL: 'TOGGLE_RECOMMENDATIONS_MODAL' =
  'TOGGLE_RECOMMENDATIONS_MODAL'
export const CREATE_CONSENT: 'CREATE_CONSENT' =
  'CREATE_CONSENT'
export const DISPLAY_CRM_THANK_YOU_MODAL: 'DISPLAY_CRM_THANK_YOU_MODAL' =
  'DISPLAY_CRM_THANK_YOU_MODAL'
export const HIDE_CRM_THANK_YOU_MODAL: 'HIDE_CRM_THANK_YOU_MODAL' =
  'HIDE_CRM_THANK_YOU_MODAL'
export const RESEND_VERIFICATION_EMAIL: 'RESEND_VERIFICATION_EMAIL' =
  'RESEND_VERIFICATION_EMAIL'
export const HANDLING_LOGIN: 'HANDLING_LOGIN' =
  'HANDLING_LOGIN'
export const USER_ALREADY_UNSUBSCRIBED: 'USER_ALREADY_UNSUBSCRIBED' =
  'USER_ALREADY_UNSUBSCRIBED'
export const ERROR_UNSUBSCRIBING: 'ERROR_UNSUBSCRIBING' =
  'ERROR_UNSUBSCRIBING'
export const USER_ALREADY_SUBSCRIBED: 'USER_ALREADY_SUBSCRIBED' =
  'USER_ALREADY_SUBSCRIBED'
export const ERROR_SUBSCRIBING: 'ERROR_SUBSCRIBING' =
  'ERROR_SUBSCRIBING'
export const JOB_APPLICATION_SHOW_USER_LOCATION_HINT: 'JOB_APPLICATION_SHOW_USER_LOCATION_HINT' = 'JOB_APPLICATION_SHOW_USER_LOCATION_HINT'
export const JOB_APPLICATION_CLEAR_USER_LOCATION_HINT: 'JOB_APPLICATION_CLEAR_USER_LOCATION_HINT' = 'JOB_APPLICATION_CLEAR_USER_LOCATION_HINT'
export const JOB_APPLICATION_CLEAR_USER_LOCATION: 'JOB_APPLICATION_CLEAR_USER_LOCATION' = 'JOB_APPLICATION_CLEAR_USER_LOCATION'
export const JOB_APPLICATION_SET_USER_LOCATION: 'JOB_APPLICATION_SET_USER_LOCATION' = 'JOB_APPLICATION_SET_USER_LOCATION'
export const TOGGLE_MOBILE_SMART_LINKOUT_FORM: 'TOGGLE_MOBILE_SMART_LINKOUT_FORM' =
  'TOGGLE_MOBILE_SMART_LINKOUT_FORM'
export const ON_MOBILE_SMART_LINKOUT_SUBMIT_CLICK: 'ON_MOBILE_SMART_LINKOUT_SUBMIT_CLICK' =
  'ON_MOBILE_SMART_LINKOUT_SUBMIT_CLICK'
export const SMART_LINKOUT_SIGNUP: 'SMART_LINKOUT_SIGNUP' =
  'SMART_LINKOUT_SIGNUP'
export const REGISTER_MODAL_SIGNUP: 'REGISTER_MODAL_SIGNUP' =
  'REGISTER_MODAL_SIGNUP'
export const SMART_LINKOUT_OPEN_URL: 'SMART_LINKOUT_OPEN_URL' =
  'SMART_LINKOUT_OPEN_URL'
export const ON_SMART_LINKOUT_TAKEN_EMAIL_ERROR: 'ON_SMART_LINKOUT_TAKEN_EMAIL_ERROR' =
  'ON_SMART_LINKOUT_TAKEN_EMAIL_ERROR'
export const SHOW_ALREADY_APPLIED_TO_JOB_ERROR: 'SHOW_ALREADY_APPLIED_TO_JOB_ERROR' =
  'SHOW_ALREADY_APPLIED_TO_JOB_ERROR'
export const ON_ROUTE_TRANSITION: 'ON_ROUTE_TRANSITION' =
  'ON_ROUTE_TRANSITION'
export const RESERVE_ENTERED_DATA: 'RESERVE_ENTERED_DATA' =
  'RESERVE_ENTERED_DATA'
export const SET_REDIRECT_PATH: 'SET_REDIRECT_PATH' = 'SET_REDIRECT_PATH'
export const TOGGLE_GOOGLE_ONE_TAP_FOLLOW_UP: 'TOGGLE_GOOGLE_ONE_TAP_FOLLOW_UP' =
  'TOGGLE_GOOGLE_ONE_TAP_FOLLOW_UP'
export const TOGGLE_APPLICATION_SUBMITTED_FOLLOW_UP: 'TOGGLE_APPLICATION_SUBMITTED_FOLLOW_UP' =
'TOGGLE_APPLICATION_SUBMITTED_FOLLOW_UP'
export const MARK_ONPAGE_NPS_EVENT_AS_TRIGGERED: 'MARK_ONPAGE_NPS_EVENT_AS_TRIGGERED' =
'MARK_ONPAGE_NPS_EVENT_AS_TRIGGERED'
export const INCREMENT_NUMBER_OF_HOME_PAGE_VIEW: 'INCREMENT_NUMBER_OF_HOME_PAGE_VIEW' = 'INCREMENT_NUMBER_OF_HOME_PAGE_VIEW'
export const INCREMENT_NUMBER_OF_JOB_SEARCH_PAGE_VIEW: 'INCREMENT_NUMBER_OF_JOB_SEARCH_PAGE_VIEW' = 'INCREMENT_NUMBER_OF_JOB_SEARCH_PAGE_VIEW'
export const INCREMENT_NUMBER_OF_INBOX_PAGE_VIEW: 'INCREMENT_NUMBER_OF_INBOX_PAGE_VIEW' = 'INCREMENT_NUMBER_OF_INBOX_PAGE_VIEW'
export const INITIALIZE_TRIGGER_OF_CES_EVENT_FOR_CAF: 'INITIALIZE_TRIGGER_OF_CES_EVENT_FOR_CAF' = 'INITIALIZE_TRIGGER_OF_CES_EVENT_FOR_CAF'
export const MARK_CES_EVENT_FOR_CAF_AS_TRIGGERED: 'MARK_CES_EVENT_FOR_CAF_AS_TRIGGERED' = 'MARK_CES_EVENT_FOR_CAF_AS_TRIGGERED'
export const ON_ROUTE_DATA_SYNC: 'ON_ROUTE_DATA_SYNC' =
  'ON_ROUTE_DATA_SYNC'

export const SET_COOKIE_CONSENT_SERVICE: 'SET_COOKIE_CONSENT_SERVICE' =
  'SET_COOKIE_CONSENT_SERVICE'

export const LOCATION_CHANGE: 'LOCATION_CHANGE' = 'LOCATION_CHANGE'

export const BOOKMARK_ANIMATION: 'BOOKMARK_ANIMATION' = 'BOOKMARK_ANIMATION'

// Keyword search actions
export const TOGGLE_MOBILE_SEARCH_VIEW: 'TOGGLE_MOBILE_SEARCH_VIEW' =
  'TOGGLE_MOBILE_SEARCH_VIEW'
export const KEYWORD_SEARCH_SUGGESTIONS: 'KEYWORD_SEARCH_SUGGESTIONS' =
  'KEYWORD_SEARCH_SUGGESTIONS'
export const KEYWORD_SEARCH_SUGGESTIONS_TOGGLE_FILTERS_SIDEBAR: 'KEYWORD_SEARCH_SUGGESTIONS_TOGGLE_FILTERS_SIDEBAR' =
  'KEYWORD_SEARCH_SUGGESTIONS_TOGGLE_FILTERS_SIDEBAR'
export const KEYWORD_SEARCH_MOBILE_ADD_TO_FILTERS_BUCKET: 'KEYWORD_SEARCH_MOBILE_ADD_TO_FILTERS_BUCKET' =
  'KEYWORD_SEARCH_MOBILE_ADD_TO_FILTERS_BUCKET'
export const KEYWORD_SEARCH_MOBILE_EMPTY_FILTERS_BUCKET: 'KEYWORD_SEARCH_MOBILE_EMPTY_FILTERS_BUCKET' =
  'KEYWORD_SEARCH_MOBILE_EMPTY_FILTERS_BUCKET'
export const FIRE_GCTS_TRACKING_EVENT: 'FIRE_GCTS_TRACKING_EVENT' =
  'FIRE_GCTS_TRACKING_EVENT'
export const REMOVE_JUID_COOKIES: 'REMOVE_JUID_COOKIES' =
  'REMOVE_JUID_COOKIES'
export const KEYWORD_SEARCH_UPDATE_FILTERS: 'KEYWORD_SEARCH_UPDATE_FILTERS' =
  'KEYWORD_SEARCH_UPDATE_FILTERS'
export const KEYWORD_SEARCH_TOGGLE_MOBILE_FILTERS_SECTION_EXPANDED: 'KEYWORD_SEARCH_TOGGLE_MOBILE_FILTERS_SECTION_EXPANDED' =
  'KEYWORD_SEARCH_TOGGLE_MOBILE_FILTERS_SECTION_EXPANDED'
export const TOGGLE_RESEND_CONFIRMATION_EMAIL_MODAL: 'TOGGLE_RESEND_CONFIRMATION_EMAIL_MODAL' =
  'TOGGLE_RESEND_CONFIRMATION_EMAIL_MODAL'
export const INITIALIZE_AB_TESTS_DEFINITIONS: 'INITIALIZE_AB_TESTS_DEFINITIONS' =
  'INITIALIZE_AB_TESTS_DEFINITIONS'
export const INITIALIZE_GROWTHBOOK_DEFINITIONS: 'INITIALIZE_GROWTHBOOK_DEFINITIONS' =
  'INITIALIZE_GROWTHBOOK_DEFINITIONS'
export const UPDATE_IS_CRM_MODALS_ALLOWED: 'UPDATE_IS_CRM_MODALS_ALLOWED' =
  'UPDATE_IS_CRM_MODALS_ALLOWED'
export const UPDATE_DEVICE_TOKEN: 'UPDATE_DEVICE_TOKEN' =
  'UPDATE_DEVICE_TOKEN'

export const MARK_APPLICATION_AS_APPLIED: 'MARK_APPLICATION_AS_APPLIED' =
  'MARK_APPLICATION_AS_APPLIED'
export const TOGGLE_APPLICATION_VISIBILITY: 'TOGGLE_APPLICATION_VISIBILITY' =
  'TOGGLE_APPLICATION_VISIBILITY'
export const GOOGLE_ONE_TAP_OPEN_STATUS: 'GOOGLE_ONE_TAP_OPEN_STATUS' =
  'GOOGLE_ONE_TAP_OPEN_STATUS'
export const SET_OPT_IN_MODAL_VISIBILITY: 'SET_OPT_IN_MODAL_VISIBILITY' =
  'SET_OPT_IN_MODAL_VISIBILITY'
export const UPDATE_QUIZ_ANSWERS: 'UPDATE_QUIZ_ANSWERS' =
  'UPDATE_QUIZ_ANSWERS'
export const USER_EXPLICIT_CONSENT: 'USER_EXPLICIT_CONSENT' =
  'USER_EXPLICIT_CONSENT'

// asynchronous actions
export const RESOLVE_DEVICE: 'RESOLVE_DEVICE' =
  'RESOLVE_DEVICE'
export const RESOLVE_USER: 'RESOLVE_USER' =
  'RESOLVE_USER'
export const REFRESH_USER_OPAQUELY: 'REFRESH_USER_OPAQUELY' =
  'REFRESH_USER_OPAQUELY'
export const LOGOUT: 'LOGOUT' =
  'LOGOUT'
export const REQUEST_SIGN_IN_EMAIL: 'REQUEST_SIGN_IN_EMAIL' =
  'REQUEST_SIGN_IN_EMAIL'
export const PASSWORDLESS_SIGN_IN: 'PASSWORDLESS_SIGN_IN' =
  'PASSWORDLESS_SIGN_IN'
export const COGNITO_REQUEST_SIGN_IN_CODE: 'COGNITO_REQUEST_SIGN_IN_CODE' =
  'COGNITO_REQUEST_SIGN_IN_CODE'
export const COGNITO_VERIFY_SIGN_IN_CODE: 'COGNITO_VERIFY_SIGN_IN_CODE' =
  'COGNITO_VERIFY_SIGN_IN_CODE'
export const PASSWORDLESS_SIGN_UP: 'PASSWORDLESS_SIGN_UP' =
  'PASSWORDLESS_SIGN_UP'
export const COGNITO_INIT_SOCIAL_LOGIN: 'COGNITO_INIT_SOCIAL_LOGIN' =
  'COGNITO_INIT_SOCIAL_LOGIN'
export const COGNITO_LOGOUT: 'COGNITO_LOGOUT' =
  'COGNITO_LOGOUT'

export const CHECK_EMAIL_IS_TAKEN: 'CHECK_EMAIL_IS_TAKEN' =
  'CHECK_EMAIL_IS_TAKEN'

export const PASSWORDLESS_SIGN_UP_LEAD_GEN: 'PASSWORDLESS_SIGN_UP_LEAD_GEN' = 'PASSWORDLESS_SIGN_UP_LEAD_GEN'

export const FETCH_CURRENT_JOB: 'FETCH_CURRENT_JOB' =
  'FETCH_CURRENT_JOB'
export const FETCH_CURRENT_JOB_OPAQUELY: 'FETCH_CURRENT_JOB_OPAQUELY' =
  'FETCH_CURRENT_JOB_OPAQUELY'
export const REFETCH_CURRENT_JOB: 'REFETCH_CURRENT_JOB' =
  'REFETCH_CURRENT_JOB'
export const FETCH_CURRENT_JOB_SCREENING_QUESTIONS: 'FETCH_CURRENT_JOB_SCREENING_QUESTIONS' =
  'FETCH_CURRENT_JOB_SCREENING_QUESTIONS'
export const FETCH_SIMILAR_JOBS: 'FETCH_SIMILAR_JOBS' =
  'FETCH_SIMILAR_JOBS'
export const UPDATE_CURRENT_USER: 'UPDATE_CURRENT_USER' =
  'UPDATE_CURRENT_USER'
export const JOB_ALERT_SEARCH_QUERIES_GET: 'JOB_ALERT_SEARCH_QUERIES_GET' =
  'JOB_ALERT_SEARCH_QUERIES_GET'
export const JOB_ALERT_SEARCH_QUERIES_CREATE: 'JOB_ALERT_SEARCH_QUERIES_CREATE' =
  'JOB_ALERT_SEARCH_QUERIES_CREATE'
export const JOB_ALERT_SEARCH_QUERIES_DELETE: 'JOB_ALERT_SEARCH_QUERIES_DELETE' =
  'JOB_ALERT_SEARCH_QUERIES_DELETE'
export const JOB_SEARCH: 'JOB_SEARCH' =
  'JOB_SEARCH'
export const FETCH_QUICK_SEARCH_QUERIES: 'FETCH_QUICK_SEARCH_QUERIES' =
  'FETCH_QUICK_SEARCH_QUERIES'
export const CREATE_QUICK_SEARCH_QUERY: 'CREATE_QUICK_SEARCH_QUERY' =
  'CREATE_QUICK_SEARCH_QUERY'
export const JOB_SEARCH_MODIFY: 'JOB_SEARCH_MODIFY' =
  'JOB_SEARCH_MODIFY'
export const JOB_SEARCH_CALL: 'JOB_SEARCH_CALL' =
  'JOB_SEARCH_CALL'
export const UPDATE_APPLICATION: 'UPDATE_APPLICATION' =
  'UPDATE_APPLICATION'
export const CREATE_APPLICATION: 'CREATE_APPLICATION' =
  'CREATE_APPLICATION'
export const JOB_APPLY: 'JOB_APPLY' =
  'JOB_APPLY'
export const ANSWER_SCREENING_QUESTIONS: 'ANSWER_SCREENING_QUESTIONS' =
  'ANSWER_SCREENING_QUESTIONS'
export const CLEAR_BOOKMARKS: 'CLEAR_BOOKMARKS' =
  'CLEAR_BOOKMARKS'
export const BOOKMARK_JOB: 'BOOKMARK_JOB' =
  'BOOKMARK_JOB'
export const UN_BOOKMARK_JOB: 'UN_BOOKMARK_JOB' =
  'UN_BOOKMARK_JOB'
export const GET_JOB_BOOKMARKS: 'GET_JOB_BOOKMARKS' =
  'GET_JOB_BOOKMARKS'
export const CHECK_BOOKMARK_JOBS: 'CHECK_BOOKMARK_JOBS' =
  'CHECK_BOOKMARK_JOBS'
export const DISABLE_WAIT_FOR_DOCUMENT_CONVERSION: 'DISABLE_WAIT_FOR_DOCUMENT_CONVERSION' =
  'DISABLE_WAIT_FOR_DOCUMENT_CONVERSION'
export const ENABLE_DOCUMENT_CONVERSION: 'ENABLE_DOCUMENT_CONVERSION' =
  'ENABLE_DOCUMENT_CONVERSION'
export const FETCH_APPLICATION_DOCUMENTS: 'FETCH_APPLICATION_DOCUMENTS' =
  'FETCH_APPLICATION_DOCUMENTS'
export const FETCH_APPLICATIONS: 'FETCH_APPLICATIONS' =
  'FETCH_APPLICATIONS'
export const UPLOAD_CV: 'UPLOAD_CV' =
  'UPLOAD_CV'
export const REMOVE_CV: 'REMOVE_CV' =
  'REMOVE_CV'
export const UPLOAD_REFERENCE: 'UPLOAD_REFERENCE' =
  'UPLOAD_REFERENCE'
export const REMOVE_REFERENCE: 'REMOVE_REFERENCE' =
  'REMOVE_REFERENCE'
export const POLL_CV_CONVERSION: 'POLL_CV_CONVERSION' =
  'POLL_CV_CONVERSION'
export const POLL_REFERENCE_CONVERSION: 'POLL_REFERENCE_CONVERSION' =
  'POLL_REFERENCE_CONVERSION'
export const JOB_APPLICATION_REQUEST_FEEDBACK: 'JOB_APPLICATION_REQUEST_FEEDBACK' =
  'JOB_APPLICATION_REQUEST_FEEDBACK'
export const JOB_APPLICATION_UPDATE_FEEDBACK_STATE: 'JOB_APPLICATION_UPDATE_FEEDBACK_STATE' =
  'JOB_APPLICATION_UPDATE_FEEDBACK_STATE'
export const FETCH_SEO_DETAILS: 'FETCH_SEO_DETAILS' =
  'FETCH_SEO_DETAILS'
export const FETCH_COMPANY: 'FETCH_COMPANY' =
  'FETCH_COMPANY'
export const CONFIRM_EMAIL: 'CONFIRM_EMAIL' =
  'CONFIRM_EMAIL'
export const CONFIRM_EMAIL_UNAUTHORIZED: 'CONFIRM_EMAIL_UNAUTHORIZED' = 'CONFIRM_EMAIL_UNAUTHORIZED'
export const GET_USER_STATUS_BY_LOGIN_TOKEN: 'GET_USER_STATUS_BY_LOGIN_TOKEN' =
  'GET_USER_STATUS_BY_LOGIN_TOKEN'
export const GET_USER_CONSENT_STATUS_BY_EMAIL_HASH: 'GET_USER_CONSENT_STATUS_BY_EMAIL_HASH' =
  'GET_USER_CONSENT_STATUS_BY_EMAIL_HASH'
export const UNSUBSCRIBE_EMAILS: 'UNSUBSCRIBE_EMAILS' =
  'UNSUBSCRIBE_EMAILS'
export const SUBSCRIBE_EMAILS: 'SUBSCRIBE_EMAILS' =
  'SUBSCRIBE_EMAILS'
export const FETCH_RECOMMENDED_JOBS: 'FETCH_RECOMMENDED_JOBS' =
  'FETCH_RECOMMENDED_JOBS'
export const GET_VISITED_JOBS: 'GET_VISITED_JOBS' =
  'GET_VISITED_JOBS'
export const SMART_LINK_OUT_JOB_APPLY: 'SMART_LINK_OUT_JOB_APPLY' =
  'SMART_LINK_OUT_JOB_APPLY'
export const CREATE_RECOMMENDATION_CONSENT: 'CREATE_RECOMMENDATION_CONSENT' =
  'CREATE_RECOMMENDATION_CONSENT'
export const CREATE_JOBS_ALERTS_EMAIL_CONSENT: 'CREATE_JOBS_ALERTS_EMAIL_CONSENT' =
  'CREATE_JOBS_ALERTS_EMAIL_CONSENT'
export const CREATE_WHATS_APP_RECOMMENDATION_CONSENT: 'CREATE_WHATS_APP_RECOMMENDATION_CONSENT' =
  'CREATE_WHATS_APP_RECOMMENDATION_CONSENT'
export const CREATE_WHATSAPP_RECOMMENDATION_CONSENT: 'CREATE_WHATSAPP_RECOMMENDATION_CONSENT' =
  'CREATE_WHATSAPP_RECOMMENDATION_CONSENT'
export const CREATE_PROFILE_SHARING_CONSENT: 'CREATE_PROFILE_SHARING_CONSENT' = 'CREATE_PROFILE_SHARING_CONSENT'
export const CREATE_TERMS_AND_CONDITIONS_CONSENT: 'CREATE_TERMS_AND_CONDITIONS_CONSENT' = 'CREATE_TERMS_AND_CONDITIONS_CONSENT'
export const DISABLE_TERMS_AND_CONDITIONS_EXPERIMENT: 'DISABLE_TERMS_AND_CONDITIONS_EXPERIMENT' = 'DISABLE_TERMS_AND_CONDITIONS_EXPERIMENT'
export const VERIFY_GOOGLE_SIGN_IN_TOKEN: 'VERIFY_GOOGLE_SIGN_IN_TOKEN' =
  'VERIFY_GOOGLE_SIGN_IN_TOKEN'
export const GET_TALENT_PLATFORM_COUNTS: 'GET_TALENT_PLATFORM_COUNTS' =
  'GET_TALENT_PLATFORM_COUNTS'
export const GET_APPLICATION_DETAILS: 'GET_APPLICATION_DETAILS' =
  'GET_APPLICATION_DETAILS'
export const GET_JOB_TYPE_QUIZ_RESULT: 'GET_JOB_TYPE_QUIZ_RESULT' =
  'GET_JOB_TYPE_QUIZ_RESULT'
export const GET_JOB_TYPE: 'GET_JOB_TYPE' =
  'GET_JOB_TYPE'
export const SUBMIT_QUIZ_FEEDBACK: 'SUBMIT_QUIZ_FEEDBACK' =
  'SUBMIT_QUIZ_FEEDBACK'
export const GET_QUIZ_FEEDBACK: 'GET_QUIZ_FEEDBACK' =
  'GET_QUIZ_FEEDBACK'
export const GET_JOBS_COUNT: 'GET_JOBS_COUNT' =
  'GET_JOBS_COUNT'
export const GET_JOBS_COUNT_FOR_CURRENT_JOB_TYPE: 'GET_JOBS_COUNT_FOR_CURRENT_JOB_TYPE' =
  'GET_JOBS_COUNT_FOR_CURRENT_JOB_TYPE'

export const CONFIRM_PHONE_NUMBER: 'CONFIRM_PHONE_NUMBER' =
  'CONFIRM_PHONE_NUMBER'
export const RESEND_PHONE_NUMBER_CONFIRMATION_MESSAGE: 'RESEND_PHONE_NUMBER_CONFIRMATION_MESSAGE' =
  'RESEND_PHONE_NUMBER_CONFIRMATION_MESSAGE'
export const PHONE_NUMBER_CONFIRMATION_INVALID_TOKEN: 'PHONE_NUMBER_CONFIRMATION_INVALID_TOKEN' =
  'PHONE_NUMBER_CONFIRMATION_INVALID_TOKEN'
export const PHONE_NUMBER_CONFIRMATION_ERROR: 'PHONE_NUMBER_CONFIRMATION_ERROR' =
  'PHONE_NUMBER_CONFIRMATION_ERROR'
export const PHONE_NUMBER_CONFIRMATION_USER_ALREADY_CONFIRMED: 'PHONE_NUMBER_CONFIRMATION_USER_ALREADY_CONFIRMED' =
  'PHONE_NUMBER_CONFIRMATION_USER_ALREADY_CONFIRMED'
export const SEND_LINK_OUT_JOB_EMAIL: 'SEND_LINK_OUT_JOB_EMAIL' =
  'SEND_LINK_OUT_JOB_EMAIL'
export const UPDATE_JOBSEEKER_STATUS: 'UPDATE_JOBSEEKER_STATUS' =
  'UPDATE_JOBSEEKER_STATUS'

/* COGNITO ACTIONS */

export const RESOLVE_COGNITO_USER: 'RESOLVE_COGNITO_USER' =
  'RESOLVE_COGNITO_USER'

export const COGNITO_SIGN_UP: 'SIGN_UP' =
  'SIGN_UP'

export const USER_IS_PENDING_AN_UPDATE: 'USER_IS_PENDING_AN_UPDATE' = 'USER_IS_PENDING_AN_UPDATE'
export const USER_IS_NO_LONGER_PENDING_AN_UPDATE: 'USER_IS_NO_LONGER_PENDING_AN_UPDATE' = 'USER_IS_NO_LONGER_PENDING_AN_UPDATE'

export const COGNITO_FORGOT_PASSWORD = 'COGNITO_FORGOT_PASSWORD'
export const COGNITO_CONFIRM_FORGOT_PASSWORD = 'COGNITO_CONFIRM_FORGOT_PASSWORD'
export const COGNITO_SIGN_IN = 'SIGN_IN'
export const COGNITO_AUTHENTICATE_BY_TOKENS: 'COGNITO_AUTHENTICATE_BY_TOKENS' =
  'COGNITO_AUTHENTICATE_BY_TOKENS'
export const COGNITO_CHANGE_PASSWORD = 'COGNITO_CHANGE_PASSWORD'
export const COGNITO_CHECK_USER_EXISTENCE = 'COGNITO_CHECK_USER_EXISTENCE'
export const POST_SIGNUP_ACTIONS = 'POST_SIGNUP_ACTIONS'
export const CREATE_UNREGISTERED_JOBSEEKER = 'CREATE_UNREGISTERED_JOBSEEKER'
export const POST_SIGNIN_ACTIONS = 'POST_SIGNIN_ACTIONS'
export const DELETE_USER_PROFILE = 'DELETE_USER_PROFILE'
export const CLEAR_USER_DELETED = 'CLEAR_USER_DELETED'
export const COGNITO_SIGN_OUT_FROM_ALL_DEVICES = 'COGNITO_SIGN_OUT_FROM_ALL_DEVICES'

export const SAVE_VISITED_JOB = 'SAVE_VISITED_JOB'
