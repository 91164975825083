// @flow

import type { Cookies } from 'react-cookie'

import type { TrackingSchema } from './types'
import type { ApplicationState } from '../../types/applicationState'

type Options = {
  application_uid: string,
  job_uid: string,
  requested_feedback: bool,
}

export const REQUEST_FEEDBACK_CONTEXT: 'request_feedback_context' = 'request_feedback_context'

// eslint-disable-next-line flowtype/require-types-at-top
export type RequestFeedbackType = {
  key: typeof REQUEST_FEEDBACK_CONTEXT,
  options?: Options
}

export const SCHEMA_NAME = 'iglu:de.heyjobs/request_feedback_context/jsonschema/1-0-1'

export default (state: ?ApplicationState, cookies: Cookies, options?: Options): Array<TrackingSchema> => {
  return [{
    schema: SCHEMA_NAME,
    data: options,
  }]
}
