import React from 'react'
import { HSvgIcon, HSvgIconProps } from '../HSvgIcon'

export const HIconEnterPasswordBanner: React.FC<HSvgIconProps> = (props) => (
  <HSvgIcon viewBox="0 0 99 120" fill="none" {...props}>
    <g clipPath="url(#clip0_2585_32386)">
      <path d="M20.6652 10.4596C28.6977 4.52921 38.6291 1.01562 49.3831 1.01562C76.1005 1.01562 97.7507 22.6759 97.7507 49.3832C97.7507 76.0905 76.0904 97.7508 49.3831 97.7508C22.6758 97.7508 1.0155 76.1007 1.0155 49.3832C1.0155 40.2743 3.53391 31.7544 7.91065 24.4835" fill="white"/>
      <path d="M49.3831 98.7662C22.1579 98.7662 0 76.6184 0 49.3831C0 40.4062 2.43717 31.6121 7.03732 23.9553C7.32166 23.478 7.95126 23.3156 8.42854 23.6101C8.90581 23.8944 9.06829 24.524 8.7738 25.0013C4.35644 32.3432 2.02082 40.7718 2.02082 49.3831C2.02082 75.4912 23.2648 96.7352 49.3729 96.7352C75.4811 96.7352 96.7251 75.4912 96.7251 49.3831C96.7251 23.2749 75.5014 2.03097 49.3831 2.03097C39.1673 2.03097 29.4491 5.22975 21.2643 11.2821C20.8073 11.6172 20.1777 11.5156 19.8426 11.0688C19.5075 10.622 19.609 9.98223 20.0559 9.64712C28.5961 3.34095 38.7408 0 49.3831 0C76.6184 0 98.7662 22.1579 98.7662 49.3831C98.7662 76.6083 76.6083 98.7662 49.3831 98.7662Z" fill="#4C526B"/>
      <path d="M13.5465 16.9075C14.3386 16.0341 15.1611 15.1913 16.0141 14.3789L13.5465 16.9075Z" fill="white"/>
      <path d="M13.5364 17.9234C13.2927 17.9234 13.049 17.832 12.856 17.6594C12.4397 17.2836 12.4092 16.6439 12.7849 16.2275C13.5872 15.3441 14.43 14.4707 15.3033 13.6482C15.7095 13.2623 16.3493 13.2725 16.7352 13.6787C17.121 14.0849 17.1109 14.7246 16.7047 15.1105C15.872 15.9026 15.0596 16.7353 14.2878 17.5883C14.0847 17.8117 13.8106 17.9234 13.5364 17.9234Z" fill="#4C526B"/>
      <path d="M64.6966 114.883H1.75682C1.1983 114.883 0.741333 114.426 0.741333 113.867C0.741333 113.309 1.1983 112.852 1.75682 112.852H64.6966C65.2552 112.852 65.7121 113.309 65.7121 113.867C65.7121 114.426 65.2552 114.883 64.6966 114.883Z" fill="#4C526B"/>
      <path d="M97.0094 114.883H71.7136C71.1551 114.883 70.6981 114.426 70.6981 113.867C70.6981 113.309 71.1551 112.852 71.7136 112.852H97.0094C97.5679 112.852 98.0248 113.309 98.0248 113.867C98.0248 114.426 97.5679 114.883 97.0094 114.883Z" fill="#4C526B"/>
      <path d="M74.6382 120H63.082C62.5235 120 62.0665 119.543 62.0665 118.984C62.0665 118.426 62.5235 117.969 63.082 117.969H74.6382C75.1968 117.969 75.6537 118.426 75.6537 118.984C75.6537 119.543 75.1968 120 74.6382 120Z" fill="#4C526B"/>
      <path d="M49.3832 44.5093C56.7245 44.5093 62.6759 38.5579 62.6759 31.2165C62.6759 23.8752 56.7245 17.9238 49.3832 17.9238C42.0418 17.9238 36.0905 23.8752 36.0905 31.2165C36.0905 38.5579 42.0418 44.5093 49.3832 44.5093Z" fill="#E6EFFF"/>
      <path d="M49.3832 18.9392C56.1564 18.9392 61.6604 24.4431 61.6604 31.2164C61.6604 37.9897 56.1564 43.4936 49.3832 43.4936C42.6099 43.4936 37.1059 37.9897 37.1059 31.2164C37.1059 24.4431 42.6099 18.9392 49.3832 18.9392ZM49.3832 16.9082C41.4827 16.9082 35.075 23.3159 35.075 31.2164C35.075 39.1169 41.4827 45.5246 49.3832 45.5246C57.2836 45.5246 63.6914 39.1169 63.6914 31.2164C63.6914 23.3159 57.2836 16.9082 49.3832 16.9082Z" fill="#4C526B"/>
      <path d="M53.4857 51.1201H45.2806C33.9985 51.1201 24.849 60.2696 24.849 71.5517V91.0693C32.0386 95.3141 40.4266 97.7512 49.3831 97.7512C58.3397 97.7512 66.7175 95.3039 73.9173 91.0693V71.5517C73.9173 60.2696 64.7678 51.1201 53.4857 51.1201Z" fill="#E6EFFF"/>
      <path d="M49.3831 98.7666C40.5687 98.7666 31.9066 96.4107 24.3311 91.9425C24.0264 91.7597 23.8335 91.4246 23.8335 91.0692V71.5516C23.8335 59.7313 33.4502 50.1045 45.2806 50.1045H53.4857C65.306 50.1045 74.9328 59.7211 74.9328 71.5516V91.0692C74.9328 91.4246 74.7398 91.7597 74.4352 91.9425C66.8596 96.4107 58.1975 98.7666 49.3831 98.7666ZM25.8645 90.4802C33.0033 94.5726 41.1272 96.7356 49.3831 96.7356C57.639 96.7356 65.7629 94.5726 72.9018 90.4802V71.5516C72.9018 60.8483 64.1889 52.1355 53.4857 52.1355H45.2806C34.5773 52.1355 25.8645 60.8483 25.8645 71.5516V90.4802Z" fill="#4C526B"/>
      <path d="M81.0358 95.5677C72.5261 95.5677 65.6106 88.642 65.6106 80.1424C65.6106 71.6428 72.5362 64.707 81.0358 64.707C89.5355 64.707 96.4712 71.6326 96.4712 80.1424C96.4712 88.6522 89.5456 95.5677 81.0358 95.5677Z" fill="#C84182"/>
      <path d="M81.0358 65.7224C88.9972 65.7224 95.4456 72.1707 95.4456 80.1321C95.4456 88.0935 88.9871 94.5419 81.0358 94.5419C73.0846 94.5419 66.6261 88.0834 66.6261 80.1321C66.6261 72.1809 73.0846 65.7224 81.0358 65.7224ZM81.0358 63.6914C71.9675 63.6914 64.5951 71.074 64.5951 80.1321C64.5951 89.1903 71.9777 96.5728 81.0358 96.5728C90.094 96.5728 97.4765 89.1903 97.4765 80.1321C97.4765 71.074 90.094 63.6914 81.0358 63.6914Z" fill="#4C526B"/>
      <path d="M77.7253 86.2557C77.3293 86.2557 76.9332 86.1033 76.6387 85.7987L73.6126 82.7015C73.0236 82.1023 73.0338 81.1376 73.6431 80.5486C74.2422 79.9596 75.2069 79.9698 75.7959 80.5791L77.7659 82.5999L86.3366 74.4557C86.9459 73.8769 87.9106 73.8972 88.4895 74.5065C89.0683 75.1158 89.048 76.0805 88.4387 76.6593L78.7713 85.8393C78.4768 86.1236 78.101 86.2557 77.7253 86.2557Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_2585_32386">
        <rect width="98.7763" height="120" fill="white"/>
      </clipPath>
    </defs>
  </HSvgIcon>

)
