// @flow
import type { Cookies } from 'react-cookie'

import { DEFAULT_SEARCH_PAGE_SIZE } from '../../constants/base'

import type { TrackingSchema } from './types'
import type { ApplicationState } from '../../types/applicationState'
import type { CompensationInfo, CustomAttribute, GCTSJob } from '../../types/gctsJob'

type Options = {
  index: number,
  job: GCTSJob,
  pageNumber: number,
}

export type SingleJobResultType = {
  key: 'single_job_search_result_context',
  options: Options
}

export type JobSearchResultType = {
  key: 'job_search_result_context',
  options?: {}
}

export const SCHEMA_NAME = 'iglu:de.heyjobs/job_search_result_context/jsonschema/1-0-7'

const tryParseJSON = (input: string): string | null => {
  try {
    const json = JSON.parse(input)
    return json.codename
  } catch (e) {
    return null
  }
}

export const getJobTypeInfo = (jobTypes: CustomAttribute = {}): string => {
  if (jobTypes && jobTypes.string_values) {
    const value = jobTypes.string_values[0]
    return tryParseJSON(value) ?? value
  }
  return ''
}

const getRangeText = (compensationInfo: CompensationInfo): string => {
  const { annualized_base_compensation_range: base } = compensationInfo
  let _rangeText = ''
  if (base.min_compensation) {
    _rangeText = `${base?.min_compensation?.units} ${base?.min_compensation?.currency_code}`
  }
  if (base.max_compensation) {
    _rangeText = `${_rangeText} - ${base?.max_compensation?.units} ${base?.max_compensation?.currency_code}`
  }
  return _rangeText
}

const getFixedText = (compensationInfo: CompensationInfo): string => {
  if (compensationInfo.entries[0].amount) {
    return `${compensationInfo.entries[0].amount.units} ${compensationInfo.entries[0].amount.currency_code}`
  }
  return `${compensationInfo.annualized_base_compensation_range.min_compensation.units} ${compensationInfo.annualized_base_compensation_range.min_compensation.currency_code}`
}

const salaryText = (compensationInfo?: CompensationInfo): string | null => {
  if (!compensationInfo) return null
  if (compensationInfo.entries[0].range) {
    return getRangeText(compensationInfo)
  }
  return getFixedText(compensationInfo)
}

export const jobResultBuilder = (state: ApplicationState, cookies: Cookies, options: Options): TrackingSchema[] => {
  const { job, index, pageNumber } = options
  const positionOnPage = index + 1
  const customAttributes = job.job.custom_attributes
  const rankingBoostFactorAttribute = customAttributes.ranking_boost_factor?.long_values
  const boostFactor = rankingBoostFactorAttribute ? rankingBoostFactorAttribute[0] / 100 : null
  const newJobListing = job.job.publicationData.new
  const salary = salaryText(job.job.compensation_info)
  const jobTypeInfo = getJobTypeInfo(job.job.custom_attributes.job_types)

  return [{
    schema: SCHEMA_NAME,
    data: {
      result_position_total: (pageNumber - 1) * DEFAULT_SEARCH_PAGE_SIZE + positionOnPage,
      result_position_on_page: positionOnPage,
      job_uid: job.job.requisition_id,
      job_type: customAttributes.job_types.string_values.join(', '),
      job_type_codename: jobTypeInfo,
      job_working_hours_type: job.job.translated_employment_types.join(', '),
      ranking_boost_factor: boostFactor,
      new: newJobListing,
      salary,
      company_client_status: customAttributes.company_client_status?.string_values[0],
      working_from_home: customAttributes.working_from_home?.string_values[0],
      remote_type: null,
    },
  }]
}

const jobSearchResultContext = (state: ApplicationState, cookies: Cookies, options?: {}): Array<TrackingSchema> => {
  const jobs = state.jobSearch.jobs
  const pageNumber = state.jobSearch.searchOptions.page || 1

  return jobs.map((job, index) => jobResultBuilder(state, cookies, { index, job, pageNumber }).pop())
}

export default jobSearchResultContext
