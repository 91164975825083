import React, { useContext, useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { CAFContext } from '../../context'
import { CVShape } from '../../../../types/application'
import FieldError from '../../../shared/FieldError'
import StyledErrorMessage from '../../../shared/StyledErrorMessage'

type DocumentUploadErrorProps = {
  cv: CVShape
  formData: UseFormReturn
}

const DocumentUploadError = ({ cv, formData }: DocumentUploadErrorProps) => {
  const { intl } = useContext(CAFContext)
  const [uploadAttempted, setUploadAttempted] = useState(false)

  useEffect(() => {
    // we use this similar to an onBlur event to show the error message
    // we also handle the case that the form starts with a file uploaded
    if (cv.uploading || cv.uploaded) {
      setUploadAttempted(true)
    }
  }, [cv])

  const validationMessage = formData?.formState.errors?.documentUpload?.message as string | undefined
  const hasValidataionError = !!validationMessage
  const showFieldError = uploadAttempted && hasValidataionError

  return (
    showFieldError
      ? (
        <FieldError
          show
          id='new-caf-document-error'
        >
          <StyledErrorMessage
            errorMessage={intl.formatMessage({
              id: validationMessage,
            })}
          />
        </FieldError>
        )
      : null
  )
}

export default DocumentUploadError
