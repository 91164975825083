import MenuItem, { MenuItemProps } from '@mui/material/MenuItem'
import React from 'react'
import styled from '@emotion/styled'
import { HTypography } from '../HTypography'
import { COLORS } from '../theme'

const StyledMenuItem = styled(MenuItem)<MenuItemProps>`
  padding: 8px 16px;
  min-height: unset;
  &.Mui-selected {
    background-color: white;
  }
  &.Mui-disabled {
    opacity: 0.4;
  }
  &.Mui-focusVisible, &.Mui-selected:hover, &[data-focus='true'], :hover {
    background-color: ${COLORS.primary_05};
  }
`

type disabledProps = 'button'
interface HMenuItemProps extends Omit<MenuItemProps, disabledProps> {
  component?: string
  href?: string
}

export const HMenuItem = React.forwardRef<HTMLLIElement, HMenuItemProps>(
  (props, ref) => {
    const { value, selected, children, ...others } = props
    return (
      <StyledMenuItem
        {...others}
        selected={selected}
        value={value}
        disableRipple
        ref={ref}
      >
        <HTypography
          component='div'
          variant='body2'
          color='surface_gray_90'
          weight={selected ? 'semibold' : 'regular'}
          truncated
          style={{
            minWidth: 0,
          }}
        >
          {children}
        </HTypography>
      </StyledMenuItem>
    )
  },
)

HMenuItem.displayName = 'HMenuItem'
