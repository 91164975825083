import { DialogActions, DialogContent, DialogProps } from '@mui/material'
import clsx from 'clsx'
import React from 'react'
import { HModalHeader } from './HModalHeader'
import type { HModalHeaderProps } from './HModalHeader'
import type { HModalFooterProps } from './HModalFooter'
import { HButton, IHButtonProps } from '../HButton'
import { HModalContainer, classes } from './styles'

interface OverrideModalProps {
  'data-test-id'?: string
  modalVariant?: 'fullScreen' | 'tabletScreen' | 'fullScreenWithTopBackdrop' | 'default'
  scroll?: 'paper' | 'body'
  actions?: IHButtonProps[]
}

export type HModalProps = (
  OverrideModalProps &
  Omit<DialogProps, keyof OverrideModalProps | 'fullScreen'> &
  HModalHeaderProps &
  HModalFooterProps
)

export const HModal: React.FC<HModalProps> = ({
  open,
  handleClose,
  headerVariant = 'plain',
  footerVariant = 'plain',
  closeIcon = true,
  title,
  modalVariant = 'default',
  scroll = 'paper',
  actions,
  children,
  hasBackIcon = false,
  onBack,
  headerComponent,
  ...otherProps
}) => {
  const footerClasses = clsx(
    footerVariant !== 'plain' && classes.baseFooter,
    footerVariant === 'plain' && classes.plainFooter,
    footerVariant === 'navigation' && classes.navigationFooter,
    footerVariant === 'confirmation' && classes.confirmationFooter,
    footerVariant === 'row' && classes.rowFooter,
    footerVariant === 'fullScreen' && classes.fullScreenFooter,
  )

  const getPaperClasses = (): string => {
    if (modalVariant === 'fullScreen') {
      return classes.fullScreen
    }

    if (modalVariant === 'tabletScreen') {
      return classes.tabletScreen
    }

    if (modalVariant === 'fullScreenWithTopBackdrop') {
      return classes.fullScreenWithTopBackdrop
    }

    return classes.root
  }

  const isFullScreen = modalVariant === 'fullScreen' || modalVariant === 'fullScreenWithTopBackdrop'

  return (
    <HModalContainer
      open={open}
      PaperProps={{ className: getPaperClasses() }}
      onClose={handleClose}
      fullScreen={isFullScreen}
      scroll={scroll}
      {...otherProps}
      TransitionProps={{ ...otherProps.TransitionProps, tabIndex: 'none' } as any}
    >
      <HModalHeader
        closeIcon={closeIcon}
        handleClose={handleClose}
        hasBackIcon={hasBackIcon}
        headerComponent={headerComponent}
        headerVariant={headerVariant}
        onBack={onBack}
        title={title}
      />

      <DialogContent className={classes.contentContainer}>
        {children}
      </DialogContent>
      {footerVariant !== 'none' && (
        <DialogActions className={footerClasses}>
          {(
            footerVariant === 'navigation' ||
            footerVariant === 'row' ||
            footerVariant === 'fullScreen'
          ) && actions?.map((actionProps, index) => (
            <HButton key={index} {...actionProps} />
          ))}
          {footerVariant === 'confirmation' && actions &&
          <HButton {...actions[0]} />}
        </DialogActions>
      )}
    </HModalContainer>
  )
}
