import React from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { HBox, HCheckbox, HIcon, HInput, HRadioButton, HTypography } from 'talent-ui'
import { Locked16 } from '@carbon/icons-react'
import { FormattedMessage, useIntl } from 'react-intl'

export const salaryPeriods = [
  'hour',
  'month',
  'year'
]

export type SalaryFormValues = {
  // eslint-disable-next-line camelcase
  salary_value: string,
  // eslint-disable-next-line camelcase
  salary_period: string
}

export type SalaryStepProps = {
  form: UseFormReturn<SalaryFormValues>
  hasNoPreference?: boolean
  setHasNoPreference?: (hasNoPreference: boolean) => void
  variant: 'onboarding' | 'profile'
  currentValues: { [K in keyof SalaryFormValues]: SalaryFormValues[K] | null }
}

const salaryValueToValidFormat = (salaryValue: string | null): string => {
  if (!salaryValue) return ''

  const withoutLeadingZeros = salaryValue.replace(/^0+/, '')
  const formattedValue = withoutLeadingZeros.replace(/,/g, '.').replace(/[^0-9.]+/g, '')

  if (formattedValue.includes('.')) {
    const parts = formattedValue.split('.')

    if (parts.length > 2) {
      return parts.splice(0, 2).join('.')
    }
  }

  return formattedValue
}

const convertToValidSeperator = (salaryValue: string | null): string => salaryValue?.replace('.', ',') || ''

export const MinimumSalaryPreferences = ({ variant, form, hasNoPreference, setHasNoPreference, currentValues }: SalaryStepProps) => {
  const intl = useIntl()
  const {
    setError,
    setValue,
    clearErrors,
    formState: { errors },
    watch,
    control
  } = form

  // on input value change, validate and set value, or set error,
  // depending on the input value, and clear error if input is empty
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value

    const formattedValue = salaryValueToValidFormat(value)

    setValue('salary_value', formattedValue, { shouldDirty: true })

    if (Number(formattedValue) <= 0 || Number.isNaN(Number(formattedValue))) {
      setError('salary_value', {
        type: 'required',
        message: intl.formatMessage({
          id: 'onboarding_flow_salary_step_input_rate_error'
        })
      })
    } else {
      clearErrors('salary_value')
    }
  }

  const handleNoPreferenceCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHasNoPreference?.(event.target.checked)
    clearErrors('salary_value')

    if (event.target.checked) {
      setValue('salary_value', '')
      setValue('salary_period', '')
    } else {
      setValue('salary_value', currentValues.salary_value || '')
      setValue('salary_period', currentValues.salary_period || 'hour')
    }
  }

  return (
    <form onSubmit={(e) => { e.preventDefault() }}>
      <HBox
        display='flex'
        whiteSpace='nowrap'
        mb={8}
        ml={1}
      >
        {salaryPeriods.map((salaryPeriod) => (
          <HBox
            key={salaryPeriod}
            mr={6}
          >
            <Controller
              name='salary_period'
              control={control}
              render={({ field: { onChange, value } }) => (
                <HRadioButton
                  disabled={hasNoPreference}
                  data-test-id={`salary-period-radio-${salaryPeriod}`}
                  value={salaryPeriod}
                  label={intl.formatMessage({
                    id: `onboarding_flow_salary_step_radio_label_${salaryPeriod}`
                  })}
                  checked={salaryPeriod === value}
                  onChange={() => {
                    onChange(salaryPeriod)
                  }}
                />
              )}
            />
          </HBox>
        ))}
      </HBox>
      <HBox
        display='flex'
        flexDirection='column'
        maxWidth={480}
      >
        <Controller
          name='salary_value'
          control={control}
          render={({ field: { value } }) => (
            <HInput
              disabled={hasNoPreference}
              data-test-id='salary-value-input'
              inputProps={{
                pattern: '[0-9,]*',
                inputMode: 'numeric'
              }}
              role='textbox'
              value={convertToValidSeperator(value)}
              onChange={handleInputChange}
              fullWidth
              error={!!errors.salary_value}
              caption={errors.salary_value?.message}
              endAdornment={
                    watch('salary_period')
                      ? intl.formatMessage({ id: `onboarding_flow_salary_step_input_rate_adornment_${watch('salary_period')}` })
                      : ''
                  }
              label={
                    intl.formatMessage({ id: 'onboarding_flow_salary_step_input_rate_label' })
                  }
            />
          )}
        />
        <HBox
          display='flex'
          gap={11}
          mt={6}
          mb={10}
        >
          <HIcon
            Icon={Locked16}
            color='surface_gray_60'
          />
          <HTypography
            variant='body2'
            color='surface_gray_60'
          >
            <FormattedMessage id='onboarding_flow_salary_step_secure_info_message' />
          </HTypography>
        </HBox>
      </HBox>
      {variant === 'profile' && (
        <div>
          <HCheckbox
            id='salary-checkbox'
            data-test-id='profile-no-preference-checkbox'
            type='checkbox'
            onChange={handleNoPreferenceCheckboxChange}
            checked={!!hasNoPreference}
            label={intl.formatMessage({ id: 'profile_minimum_salary_no_preference' })}
          />
        </div>
      )}
    </form>
  )
}
