import React from 'react'
import LinearProgress, {
  LinearProgressProps as ProgressProps,
} from '@mui/material/LinearProgress'
import styled from '@emotion/styled'
import { COLORS } from '../theme'
import clsx from 'clsx'
import { HBox } from '../HBox'
import { HTypography } from '../HTypography'

type disableProps = 'valueBuffer' | 'color'

interface OverrideProps extends ProgressProps {
  variant: 'determinate' | 'indeterminate'
  rounded?: boolean
  /**
   * Can be a string or react node
   */
  label?: string | React.ReactNode
  /**
   * Show percentage in the label. The label should be set to use this feature
   */
  showPercentage?: boolean
}

type HProgressProps = OverrideProps & Omit<ProgressProps, disableProps>

const StyledProgressbar = styled(LinearProgress)`
  height: 6px;
  background-color: transparent;
  &.rounded {
    border-radius: 20px;
    background-color: ${COLORS.primary_05};
    .MuiLinearProgress-bar {
      border-radius: 20px;
    }
  }
  .MuiLinearProgress-bar {
    background-color: ${COLORS.primary_50};
  }
`

export const HLinearProgress = ({
  rounded = true,
  label,
  showPercentage = false,
  ...props
}: HProgressProps): JSX.Element => {
  return (
    <>
      {label && (
        <HBox mb={2}>
          <HTypography
            variant='caption'
            weight='regular'
            color='surface_gray_80'
          >
            {label}{' '}
            {showPercentage && (
              <HTypography
                variant='caption'
                weight='semibold'
                component='span'
                color='surface_gray_80'
              >
                {`${props.value || 0}%`}
              </HTypography>
            )}
          </HTypography>
        </HBox>
      )}
      <StyledProgressbar className={clsx({ rounded: rounded })} {...props} />
    </>
  )
}
