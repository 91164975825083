// @flow

// A/B Testing
export const EXPERIMENT_EVENT = 'experiment_event'

// User Actions
export const USER_CREATED_EVENT = 'user_created'
export const USER_LOGGED_IN_EVENT = 'user_logged_in'

// Authentication
export const CONTACT_INFORMATION_CONFIRMED_EVENT = 'contact_information_confirmed'
export const OPENED_LOGIN_MODAL_EVENT = 'opened_login_modal'
export const TERMS_AND_CONDITIONS_CONSENT_EVENT = 'terms_and_conditions_consent'

// Job Details
export const JOB_SUGGESTION_EVENT = 'job_suggestion_event'
export const JOB_SUGGESTION_CLICK_EVENT = 'job_suggestion_click_event'
export const APPLY_BUTTON_CLICK_EVENT = 'apply_button_click_event'
export const JDP_JOB_RECOMMENDATION_EVENT = 'jdp_job_recommendation_event'
export const SEND_JOB_TO_ME_ON_EXIT_INTENT_POPOVER_SHOW_EVENT = 'send_job_to_me_on_exit_intent_popover_show_event'
export const SIMILAR_JOBS_CLICKED_EVENT = 'similar_jobs_clicked'

// Application Flow
export const APPLICATION_SUBMISSION_EVENT = 'application_submission_event'
export const JOB_APPLICATION_SCREENING_QUESTION_SUBMIT_EVENT = 'job_application_screening_question_submit'
export const JOB_APPLICATION_FINALIZED_EVENT = 'job_application_finalized'
export const CRM_CONSENT_GIVEN_EVENT = 'crm_consent_given'
export const PROFILE_SHARING_CONSENT_EVENT = 'profile_sharing_consent_click'
export const EMAIL_TYPO_HINT_CLICK_EVENT = 'email_typo_hint_click'
export const HIRING_BONUS_APPLICATION_SUBMITTED = 'hiring_bonus_application_submitted'

// Status Update Flow
export const APPLICATION_STATUS_UPDATE_FLOW_EVENT = 'application_status_update_flow'
export const APPLICATION_OVERVIEW_FLOW_EVENT = 'application_overview_flow'
export const APPLICATION_PENDING_STATUS_TRIGGERED_EVENT = 'application_pending_status_triggered'

// Bookmarks
export const BOOKMARK_JOB_CLICK_EVENT = 'bookmark_job_click'

// Search
export const KEYWORD_SEARCH_GCTS_EVENTS = 'keyword_search_gcts_events'
export const KEYWORD_SEARCH_JOB_CLICK_EVENT = 'keyword_search_job_click_event'
export const KEYWORD_SEARCH_LOGIN_BUTTON_CLICK_EVENT = 'keyword_search_login_button_click_event'
export const KEYWORD_SEARCH_REGISTRATION_BUTTON_CLICK_EVENT = 'keyword_search_registration_button_click_event'
export const JOB_RECOMMENDATION_CONSENT_EVENT = 'job_recommendation_consent'
export const JOB_RECOMMENDATION_WHATSAPP_CONSENT_EVENT = 'job_recommendation_whatsapp_consent'
export const SHOW_MORE_JOB_RECOMMENDATION_EVENT = 'show_more_job_recommendation_event'
export const COOKIE_BANNER_INITIALIZED_EVENT = 'cookie_banner_initialized'
export const SEE_SIMILAR_JOBS_CLICKED = 'similar_jobs_clicked'
export const AUTOSUGGEST_CLICK_EVENT = 'autosuggestion_click'

export const ONBOARDING_FLOW_EXIT_EVENT = 'onboarding_flow_exit'
export const ONBOARDING_FLOW_STEP_COMPLETED_EVENT = 'onboarding_flow_step_completed'
export const ONBOARDING_FLOW_SKIP_EVENT = 'onboarding_flow_skipped'
export const WELCOME_SCREEN_SHOWN_EVENT = 'welcome_screen_shown'
export const ONBOARDING_FLOW_STARTED_EVENT = 'onboarding_flow_started'

// Profile
export { PROFILE_COMPLETENESS } from '../../../../jobseeker-portal-next/src/constants/index.ts'
