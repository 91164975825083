// @flow

import * as React from 'react'
import Loadable from 'react-loadable'
import { PageLoader } from '../../components/shared/loader'
import {
  withClientDataLoader,
} from '../../components/containers/withDataLoader'
import { fetchApplications } from '../../actions/actionCreators'
import compose from 'lodash/flowRight'
import { redirectIfUnauthenticated } from '../../routing/redirectIfUnauthenticated'

import type { Dispatch } from '../../store/configureStore'
import type { RouteData } from '../../types/common'
import type { ApplicationState } from '../../types/applicationState'

const dataLoader = async (
  dispatch: Dispatch,
  state: ApplicationState,
  routeData: RouteData,
  replace: Function
) => {
  if (state.user.user) {
    await dispatch(fetchApplications())
  }
}

const LoadableApplications = Loadable<{}, {}>({
  loader: () => import('./Applications'),
  loading: () => <PageLoader />,
})

const ApplicationsPage = compose(
  withClientDataLoader(dataLoader),
  redirectIfUnauthenticated
)(LoadableApplications)

export default ApplicationsPage
