// @flow
import shuffle from 'lodash/shuffle'

const getSpecialChars = () => {
  const options = ['$', '#', '@', '&', '*', '!']

  return options[Math.floor(Math.random() * options.length)]
}

export const generatePassword = () => {
  const numbers = Math.floor(Math.random() * 1000)
  const alphaLower = Math.random().toString(36).slice(2)
  const alphaUpper = Math.random().toString(36).slice(2).toLocaleUpperCase()
  const special = getSpecialChars()

  return shuffle(`${alphaLower}${alphaUpper}${numbers}${special}`.split('')).join('')
}
