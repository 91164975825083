// @flow

import API from '../clientBuilder'
import type { APIRequestConfig } from '../types'

type ApplicationVisibilityDataObject = {|
  hide: bool
|}

export default {
  v1: {
    apply: (data: $FlowTODO, config: APIRequestConfig) =>
      API.post.promise(
        '/v1/applications',
        data,
        config
      ),
  },
  v2: {
    postScreeningAnswers: (applicationId: string, data: $FlowTODO, config: APIRequestConfig) =>
      API.post.promise(
        `/v2/applications/${applicationId}/screening_answers`,
        data,
        config
      ),
    updateApplication: (id: string, data: $FlowTODO, config: APIRequestConfig) =>
      API.put.promise(
        `/v2/applications/${id}`,
        data,
        config
      ),
    updateJobseekerApplication: (applicationId: string, data: ApplicationVisibilityDataObject, config: APIRequestConfig) =>
      API.put.promise(
        `/v2/talent_platform/applications/${applicationId}`,
        data,
        config
      ),
    getApplicationDetails: (applicationId: string, config: APIRequestConfig) =>
      API.get.promise(
        `/v2/talent_platform/applications/${applicationId}`,
        null,
        config
      ),
    requestJobApplicationFeedback: (applicationId: string, config: APIRequestConfig) => API.put.promise(
      `/v2/talent_platform/applications/${applicationId}/request_feedback`,
      null,
      config
    ),
  },
}
