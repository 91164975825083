// @flow

import sha256 from 'crypto-js/sha256'
import datalayer from '../clients/datalayer'
import { getCookieConsentServices } from '../../cookieConsent/misc/getCookieConsentServices.ts'
import { USER_CENTRIC_SERVICES } from '../../cookieConsent/userCentricServices.tsx'

export type GTMUserDataForTikTok = {
  email: ?string,
  phoneNumber: ?string
}

const setGTMUserDataForTikTok = ({ email, phoneNumber }: GTMUserDataForTikTok) => {
  const services = getCookieConsentServices()
  const service = services.find(s => s.name === USER_CENTRIC_SERVICES.TIKTOK_ADVERTISING)
  const hasConsent = service?.consent.status

  if (hasConsent) {
    datalayer({
      jobseeker_sha256_email: email ? sha256(email.toLocaleLowerCase()).toString() : null,
      jobseeker_sha256_phone_number: phoneNumber ? sha256(phoneNumber).toString() : null,
    })
  }
}

export default setGTMUserDataForTikTok
