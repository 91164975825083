import React from 'react'
import styled from '@emotion/styled'
import Divider, { DividerProps } from '@mui/material/Divider'
import { COLORS } from '../theme'

export interface OverrideDividerProps {
  height?: number
  color?: keyof typeof COLORS
}

type IHDividerProps =
  OverrideDividerProps & Omit<DividerProps, keyof OverrideDividerProps>

const DividerComponent: React.FC<IHDividerProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <>
      {children
        ? <div className={className}>
          <Divider {...props} />
          {children}
          <Divider {...props} />
        </div>
        : <Divider {...props} className={className} />
      }
    </>
  )
}

const hrStyles = ({ height = 1, color }: OverrideDividerProps): string => (
  `
    background-color: ${color ? COLORS[color] : COLORS.surface_gray_10};
    height: ${height}px;
    border-radius: 32px;
  `
)

export const HDivider = styled(DividerComponent)`
  ${({ children, height, color }) => children
    ? `
    text-align: center;
    align-items: center;
    display: flex;

    hr {
      flex-grow: 1;
      ${hrStyles({ height, color })}
    }
  `
    : hrStyles({ height, color })
}
`
