// @flow

import config from '../../../../config'
import flow from 'lodash/flow'

export default class ServerError extends Error {
  // public
  body: string

  // private
  req: Request
  res: Response

  constructor (req: Request, res: Response, body: string, message: ?string = null) {
    super(message ?? ServerError.buildServerErrorMessage(req, res))
    this.name = 'ServerError'
    this.req = req
    this.res = res
    this.body = body
  }

  static buildServerErrorMessage (req: Request, res: Response): string {
    return `${req.method.toUpperCase()} ${this.generalizeUrl(req.url)} ${res.status}`
  }

  static generalizeUrl (url: string): string {
    return flow(
      this.replaceExpectedBackendBaseUrl,
      this.replaceSpecificUUIDs
    )(url)
  }

  static replaceSpecificUUIDs (url: string): string {
    const uuidPattern = /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/
    return url.replace(uuidPattern, 'UUID')
  }

  static replaceExpectedBackendBaseUrl (url: string): string {
    return url.startsWith(config.backend.apiUrl) ? url.replace(config.backend.apiUrl, '') : url
  }

  get props () {
    return {
      http: {
        url: this.req.url,
        method: this.req.method,
        status_code: this.res.status,
      },
      error: {
        kind: this.name,
        message: this.message,
        stack: this.stack,
      },
    }
  }

  get status () {
    return this.res.status
  }

  get signature () {
    return this.message
  }

  toString () {
    return `${this.name}: ${this.message}`
  }
}
