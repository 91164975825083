import { createTheme, ThemeProvider as MuiThemeProvider, StyledEngineProvider, Theme } from '@mui/material'
import React from 'react'
import { breakpoints, newBreakpoints } from './breakpoints'
import { Breakpoints, Spacing } from '@mui/system'
import { ThemeProvider } from '@emotion/react'
import mediaQuery from 'css-mediaquery'

interface ThemeConfigProps {
  children: React.ReactNode
  isMobile?: boolean
  useNewBreakpoints?: boolean
  theme?: Theme
}

declare module '@emotion/react' {
  export interface Theme {
    breakpoints: Breakpoints
    spacing: Spacing
  }
}

export const createThemeInstance = (
  isMobile = false,
  useNewBreakpoints = false,
): Theme => {
  const ssrMatchMedia = (query: string): { matches: boolean } => {
    return {
      matches: mediaQuery.match(query, {
        width: isMobile ? 639 : 1200,
      }),
    }
  }

  const themeOptions = {
    spacing: (factor: number) => factor * 4,
    breakpoints: useNewBreakpoints ? newBreakpoints : breakpoints,
    typography: {
      fontFamily: 'inherit',
    },
    zIndex: {
      mobileStepper: 1000,
      speedDial: 1050,
      appBar: 1100,
      drawer: 1200,
      modal: 10001,
      snackbar: 1400,
      tooltip: 1500,
    },
    props: {
      MuiButton: {},
      Hidden: {
        implementation: 'css',
      },
    },
    components: { MuiUseMediaQuery: { defaultProps: { ssrMatchMedia } } },
  }

  return createTheme(themeOptions)
}

export const ThemeConfig = ({
  children,
  isMobile,
  useNewBreakpoints = false,
  theme,
}: ThemeConfigProps): JSX.Element => {
  const muiTheme = theme || createThemeInstance(isMobile, useNewBreakpoints)

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={muiTheme}>
        <MuiThemeProvider theme={muiTheme}>
          {children}
        </MuiThemeProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}
