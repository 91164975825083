import React from 'react'
import { HTypography } from '../HTypography'
import { HBox } from '../HBox'

export interface InfoDrawerProps {
  title?: string
  description?: string
}

export const InfoContent: React.FC<InfoDrawerProps> = ({
  title,
  description,
}) => {
  return (
    <HBox my={1}>
      {title && (
        <HBox mb={1}>
          <HTypography variant='h6' color='surface_gray_80' weight='regular'>
            {title}
          </HTypography>
        </HBox>
      )}
      {description && (
        <HTypography variant='body1' color='surface_gray_80' weight='regular'>
          {description}
        </HTypography>
      )}
    </HBox>
  )
}
