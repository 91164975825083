import React from 'react'
import { HBox, HBoxProps, HFunnelSelect } from 'talent-ui'

import { useTranslate } from './hooks/use-translate'
import { FullTime, FullTimePartTime, PartTime, WorkingHours } from './types/working-hours'

type WorkingHourSelectProps = {
  optionContainerProps: HBoxProps
  text: string
  onClick: () => void
  isRadioGroup?: boolean
  isSelected?: boolean
  dataTestId: string
}

type WorkingHoursOptionsProps = {
  handleClick: (workingHour: WorkingHours) => void
  isRadioGroup?: boolean
  optionContainerProps: HBoxProps
  selectedWorkingHours?: WorkingHours
  variant: string
}

export const getWorkingHoursPreferenceTranslationKey = (workingHoursPreference: WorkingHours, variant?: string): string => {
  if (variant === 'profile') {
    return workingHoursPreference === FullTimePartTime ? 'profile_working_hours_no_preference' : `onboarding_flow_job_type_${workingHoursPreference}`
  }

  return `onboarding_flow_job_type_${workingHoursPreference === FullTimePartTime ? 'no_preference' : workingHoursPreference}`
}

const WorkingHourSelect = ({ optionContainerProps, text, onClick, isRadioGroup, isSelected, dataTestId } : WorkingHourSelectProps) => {
  const checkedStatus = isSelected ? 'checked' : 'unchecked'

  return (
    <HBox {...optionContainerProps}>
      <HFunnelSelect
        fullWidth={false}
        onClick={onClick}
        data-test-id={isRadioGroup ? `working-hour-option-${dataTestId}-${checkedStatus}` : dataTestId}
        variant={isRadioGroup ? 'icon' : 'normal'}
        icon='radio'
        selected={isSelected}
        text={text}
      />
    </HBox>
  )
}

export const WorkingHoursOptions = ({ handleClick, isRadioGroup, optionContainerProps, selectedWorkingHours, variant } : WorkingHoursOptionsProps) => {
  const formatMessage = useTranslate()

  return (
    <>
      <WorkingHourSelect
        onClick={() => handleClick(FullTime)}
        optionContainerProps={optionContainerProps}
        text={formatMessage(getWorkingHoursPreferenceTranslationKey(FullTime, variant))}
        isSelected={selectedWorkingHours === FullTime}
        isRadioGroup={isRadioGroup}
        dataTestId='full-time'
      />
      <WorkingHourSelect
        onClick={() => handleClick(PartTime)}
        optionContainerProps={optionContainerProps}
        text={formatMessage(getWorkingHoursPreferenceTranslationKey(PartTime, variant))}
        isSelected={selectedWorkingHours === PartTime}
        isRadioGroup={isRadioGroup}
        dataTestId='part-time'
      />
      <WorkingHourSelect
        onClick={() => handleClick(FullTimePartTime)}
        optionContainerProps={optionContainerProps}
        text={formatMessage(getWorkingHoursPreferenceTranslationKey(FullTimePartTime, variant))}
        isSelected={selectedWorkingHours === FullTimePartTime}
        isRadioGroup={isRadioGroup}
        dataTestId='no-preference'
      />
    </>
  )
}
