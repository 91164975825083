import type { TrackingSchema } from './types'
import * as sessionStorage from '../../helpers/sessionStorageUtils'

export const SCHEMA_NAME = 'iglu:com.google.tag-manager.server-side/preview_mode/jsonschema/1-0-0'

export default (): Array<TrackingSchema> => {
  const gtmDebugValue = sessionStorage.getItem(sessionStorage.identifiers.gtmDebug)
  if (!gtmDebugValue || typeof gtmDebugValue !== 'string') {
    return []
  }

  return [{
    schema: SCHEMA_NAME,
    data: {
      'x-gtm-server-preview': gtmDebugValue,
    },
  }]
}
