import API from '../clientBuilder'
import createAppThunk from '../../../store/createAppThunk'
import { buildUrlFragment } from '../../../helpers/url/query'
import type { APIRequestConfig } from '../types'
import { Locale } from '../../../types/common'
import { FetchJobTitlesParams, JobTitle } from 'user-profile-data'

const getJobTitles = (config: APIRequestConfig, options: FetchJobTitlesParams & { locale: Locale }): Promise<JobTitle[]> => {
  const queryParams = buildUrlFragment(options)

  return API.get.promise(`/v2/talent_platform/job_titles/autocomplete${queryParams}`, null, config)
}

export const fetchJobTitles = createAppThunk<JobTitle[], FetchJobTitlesParams>(
  'jobseekerPreferences/fetchJobTitles',
  async (options: FetchJobTitlesParams, { getState, extra: { requestConfig } }) => {
    const { intlData: { locale } } = getState()
    return getJobTitles(requestConfig, { ...options, locale })
  }
)
