// @flow

import { userExplicitConsent } from '../actions/cookieConsent'
import { GTM_CONSENT_EVENT, COOKIE_CONSENT_EXPLICIT_EVENT } from '../constants/base'

import type { Store, Dispatch } from '../store/configureStore'

type DataLayerEvent = {
  event: string,
  type: string
}

const isExplicit = (args: DataLayerEvent) =>
  args.event === GTM_CONSENT_EVENT && args.type === COOKIE_CONSENT_EXPLICIT_EVENT

class UsercentricsDataLayerNotifier extends Array<DataLayerEvent> {
  dispatch: Dispatch

  constructor (store: Store, previousEvents: DataLayerEvent[]) {
    super()

    this.dispatch = store.dispatch
    this.checkExplicitConsent(previousEvents)
  }

  push = (args: DataLayerEvent) => {
    if (isExplicit(args)) {
      this.dispatchUserChoice()
    }
    return Array.prototype.push.call(this, args)
  }

  checkExplicitConsent = (previousEvents: DataLayerEvent[]) => {
    const hasExplicitConsent = previousEvents?.find((d) => isExplicit(d))

    if (hasExplicitConsent) {
      this.dispatchUserChoice()
    }
  }

  dispatchUserChoice = () => {
    this.dispatch(userExplicitConsent())
  }
}

export default UsercentricsDataLayerNotifier
