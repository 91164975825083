export const getStepsProgress = (
  currentStepIndex: number,
  stepsLength: number
): number => {
  if (stepsLength > 0) {
    return ((currentStepIndex + 1) / stepsLength) * 100
  }

  return 0
}
