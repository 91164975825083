// @flow
import * as actions from '../constants/actions'
import { cleanOnLocationChange } from './utils/cleanOn'

export type RecommendationModalState = {
  isCRMModalsAllowed: bool,
  visible: bool
}

type ModalAction = {
  isCRMModalsAllowed: bool,
  type: string,
  visible: bool
}

const initialState: RecommendationModalState = {
  visible: false,
  isCRMModalsAllowed: true,
}

const reducer = (state: RecommendationModalState = initialState, action: ModalAction) => {
  switch (action.type) {
    case actions.TOGGLE_RECOMMENDATIONS_MODAL:
      return {
        ...state,
        visible: action.visible,
      }
    case actions.UPDATE_IS_CRM_MODALS_ALLOWED: {
      return {
        ...state,
        isCRMModalsAllowed: action.isCRMModalsAllowed,
      }
    }
    default:
      return state
  }
}

export default cleanOnLocationChange(reducer)
