
import React from 'react'
import { HSvgIcon, HSvgIconProps } from '../HSvgIcon'

export const GlassesIcon: React.FC<HSvgIconProps> = (props) => {
  return (
    <HSvgIcon xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M3.99998 7.83331C2.80336 7.83331 1.83331 8.80336 1.83331 9.99998C1.83331 11.1966 2.80336 12.1666 3.99998 12.1666C5.1966 12.1666 6.16665 11.1966 6.16665 9.99998C6.16665 8.80336 5.1966 7.83331 3.99998 7.83331ZM0.833313 9.99998C0.833313 8.25108 2.25108 6.83331 3.99998 6.83331C5.74888 6.83331 7.16665 8.25108 7.16665 9.99998C7.16665 11.7489 5.74888 13.1666 3.99998 13.1666C2.25108 13.1666 0.833313 11.7489 0.833313 9.99998Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M12 7.83331C10.8034 7.83331 9.83331 8.80336 9.83331 9.99998C9.83331 11.1966 10.8034 12.1666 12 12.1666C13.1966 12.1666 14.1666 11.1966 14.1666 9.99998C14.1666 8.80336 13.1966 7.83331 12 7.83331ZM8.83331 9.99998C8.83331 8.25108 10.2511 6.83331 12 6.83331C13.7489 6.83331 15.1666 8.25108 15.1666 9.99998C15.1666 11.7489 13.7489 13.1666 12 13.1666C10.2511 13.1666 8.83331 11.7489 8.83331 9.99998Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M3.78513 4.88226C4.00283 4.48139 4.19021 4.23418 4.40304 4.07888C4.60573 3.93096 4.87805 3.83331 5.3333 3.83331C5.60944 3.83331 5.8333 3.60946 5.8333 3.33331C5.8333 3.05717 5.60944 2.83331 5.3333 2.83331C4.72189 2.83331 4.22754 2.969 3.81357 3.27108C3.41349 3.56303 3.13706 3.97646 2.89307 4.4296C2.88523 4.44415 2.87812 4.45908 2.87176 4.47434L1.2051 8.47434C1.09889 8.72924 1.21943 9.02198 1.47433 9.12818C1.72923 9.23439 2.02196 9.11385 2.12817 8.85895L3.78513 4.88226ZM10.1666 3.33331C10.1666 3.05717 10.3905 2.83331 10.6666 2.83331C11.2506 2.83331 11.7402 2.97176 12.1562 3.26739C12.5619 3.55564 12.8564 3.96442 13.1069 4.4296C13.1147 4.44415 13.1218 4.45908 13.1282 4.47434L14.7948 8.47434C14.901 8.72924 14.7805 9.02198 14.5256 9.12818C14.2707 9.23439 13.978 9.11385 13.8718 8.85895L12.2149 4.88238C12.0029 4.49305 11.8006 4.24144 11.577 4.08257C11.3598 3.9282 11.0826 3.83331 10.6666 3.83331C10.3905 3.83331 10.1666 3.60946 10.1666 3.33331ZM6.70361 8.70362C7.04742 8.3598 7.51374 8.16665 7.99997 8.16665C8.4862 8.16665 8.95251 8.3598 9.29633 8.70362C9.64015 9.04743 9.8333 9.51375 9.8333 9.99998C9.8333 10.2761 9.60944 10.5 9.3333 10.5C9.05716 10.5 8.8333 10.2761 8.8333 9.99998C8.8333 9.77897 8.7455 9.567 8.58922 9.41072C8.43294 9.25444 8.22098 9.16665 7.99997 9.16665C7.77895 9.16665 7.56699 9.25444 7.41071 9.41072C7.25443 9.567 7.16663 9.77897 7.16663 9.99998C7.16663 10.2761 6.94278 10.5 6.66663 10.5C6.39049 10.5 6.16663 10.2761 6.16663 9.99998C6.16663 9.51375 6.35979 9.04743 6.70361 8.70362Z" fill="white"/>
    </HSvgIcon>

  )
}
