// @flow

// the environment specific configs, set on the global object in serverConfig.js
// and printed to window.__config in the html on server render
// this just provides a wrapper over that detail to access config on both client and server

import type { Config } from './server/config/build'

// For tests config is defined in jest.config.js
export const underlying =
  ((typeof window === 'undefined' ? process.__config : window.__config): Config)

export default underlying
