// @flow

export const PENDING_BY_JOBSEEKER = Object.freeze([
  'jobseeker_interested',
  'pending_cv',
])

export const PENDING_BY_RECRUITER = Object.freeze([
  'waiting_for_response',
  'check_response',
  'further_information_requested_by_employer',
])

export const PENDING_STATUSES = Object.freeze([
  ...PENDING_BY_JOBSEEKER,
  ...PENDING_BY_RECRUITER,
])

export const REJECTED_STATUSES = Object.freeze([
  'rejected_by_jobseeker',
  'rejected_by_employer',
  'screening_failed',
  'rejected',
])

export const APPLIED_STATUS = Object.freeze([
  'applied',
  'email_sent',
  'ats_sent',
  'interview_confirmed',
  'archived',
  'ats_failed',
  'acknowledged_by_employer',
  'invited_for_interview',
])

export const ACCEPTED_STATUSES = Object.freeze([
  'offer',
  'hired',
  'job_offered',
])

export const UNPUBLISHED_JOB_STATUSES = Object.freeze([
  'unpublished_by_recruiter',
  'unpublished_by_expiration',
  'unpublished_by_admin',
])

export const JOBSEEKER_REJECTED_STATUS = Object.freeze([
  'rejected',
])

export const JOBSEEKER_HIRED_STATUS = Object.freeze([
  'hired',
])

export const JOBSEEKER_APPLIED_PENDING_STATUSES = Object.freeze([
  'pending',
  'feedback',
])

export const JOBSEEKER_NEW_STATUS = Object.freeze([
  'new',
])

export const JOBSEEKER_STATUSES = Object.freeze([
  ...JOBSEEKER_HIRED_STATUS,
  ...JOBSEEKER_REJECTED_STATUS,
  ...JOBSEEKER_APPLIED_PENDING_STATUSES,
  ...JOBSEEKER_NEW_STATUS,
])

export const GENERIC_STATUSES = Object.freeze({
  HIRED: 'hired',
  PENDING: 'pending',
  PENDING_CV: 'pending_cv',
  APPLIED: 'applied',
  REJECTED: 'rejected',
  SCREENING_FAILED: 'screening_failed',
  FEEDBACK: 'feedback',
})

export const JOB_CARD_TYPES = Object.freeze({
  RECENTLY_VIEWED_JOB: 'recentlyViewedJob',
  RECOMMENDED_JOB: 'recommendedJob',
})
