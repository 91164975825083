import React from 'react'
import { HBox } from '../HBox'
import { HTypography } from '../HTypography'

export interface ChoiceDrawerProps {
  primary: React.ReactNode
  secondary: React.ReactNode
  title?: string
  description?: string
}

export const ChoiceContent: React.FC<ChoiceDrawerProps> = ({
  primary,
  secondary,
  title,
  description,
}) => {
  return (
    <>
      {title && (
        <HBox mb={1}>
          <HTypography variant='h6' color='surface_gray_80' weight='regular'>
            {title}
          </HTypography>
        </HBox>
      )}
      {description && (
        <HBox mb={2}>
          <HTypography variant='body1' color='surface_gray_80' weight='regular'>
            {description}
          </HTypography>
        </HBox>
      )}
      <HBox display='flex' justifyContent='flex-end'>
        <HBox mr={3}>{secondary}</HBox>
        <HBox>{primary}</HBox>
      </HBox>
    </>
  )
}
