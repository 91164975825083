// @flow

import { ofType } from 'redux-observable'
import { of, throwError } from 'rxjs'
import {
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
  catchError,
  startWith,
} from 'rxjs/operators'

import find from 'lodash/find'
import * as API from '../../actions/api/clients'
import * as actions from '../../constants/actions'
import { FULFILLED, PENDING, REJECTED } from '../../reducers/promiseUtil'
import { getReqHeaders } from '../../actions/requestHeaders'
import { actionResolver } from '../../actions/utils/epicUtils'

import { getRankingOptions } from './rankingOptions'
import resolveAndBuildSearchState from './resolveAndBuildSearchState'
import transformToSearchAPIOptions from './transformToSearchAPIOptions'

import type { Observable } from 'rxjs'
import type { ApplicationState } from '../../types/applicationState'
import type {
  SearchState,
  SearchStateWithFallback,
} from './types'

type Action = $FlowTODO

const FALLBACK_SEARCH_PAGE_SIZE = 10

const buildFailedSearchState = (options, error) => {
  const data = {
    searchOptions: options.searchOptions,
    currentPage: 0,
    numJobs: 0,
    jobs: [],
    requestId: null,
    totalPages: 0,
    workingHoursFilters: [],
    employmentTypesFilters: [],
    companiesFilters: [],
    spellCorrection: {},
    error: true,
    errors: error.errors,
    salaryFilters: null,
    easyApplyFilters: null,
  }
  return data
}

const processOptionsForFallback = ({
  query,
  location,
  ...options
}) => ({
  ...options,
  page_size: FALLBACK_SEARCH_PAGE_SIZE,
  page: 1,
  offset: 0,
  query: undefined,
  location: !query && location ? undefined : location,
})

const loadFallbackIfNeeded = (config) => (searchState: SearchState): Observable<SearchState | SearchStateWithFallback> => {
  if (searchState.filtersUsed || searchState.numJobs) {
    return of(searchState)
  }

  const options = processOptionsForFallback(
    transformToSearchAPIOptions(searchState.searchOptions)
  )
  return API.search.search(options, config)
    .pipe(
      map(response => {
        return { ...searchState, fallback: { jobs: response.matching_jobs } }
      }),
      catchError(_ => {
        return of({ ...searchState, fallback: { jobs: [], error: true } })
      })
    )
}

const catchHandlableErrors = (jobSearchOptions) => {
  return catchError(error => {
    if (error?.errors) {
      // A location that couldn't be resolved
      if (find(error.errors, { code: 'bad_location' })) {
        return of({
          type: REJECTED(actions.JOB_SEARCH),
          payload: buildFailedSearchState({ searchOptions: jobSearchOptions }, error),
        })
      }
    }
    return throwError(error)
  })
}

const search = ([action, state]: [Action, ApplicationState]): Observable<Action> => {
  const { options } = action.meta
  const { page } = options
  const optionsWithRanking = { ...options, ...getRankingOptions(state, options) }
  const jobSearchAPIOptions = transformToSearchAPIOptions(optionsWithRanking)
  const requestConfig = { headers: getReqHeaders(state) }

  return API.search.search(jobSearchAPIOptions, requestConfig)
    .pipe(
      mergeMap(resolveAndBuildSearchState(requestConfig, state, options, page)),
      mergeMap(loadFallbackIfNeeded(requestConfig)),
      mergeMap(searchState => of({
        type: FULFILLED(actions.JOB_SEARCH),
        payload: searchState,
      })),
      catchHandlableErrors(options), // has to catch errors before the actionResolver
      actionResolver(action),
      startWith({
        type: PENDING(actions.JOB_SEARCH),
        payload: { searchOptions: options },
      }),
      catchError(error => of({
        type: REJECTED(actions.JOB_SEARCH),
        payload: buildFailedSearchState({ searchOptions: options }, error),
      }))
    )
}

export default (action$: Observable<Action>, state$: Observable<ApplicationState>): Observable<Action> =>
  action$.pipe(
    ofType(actions.JOB_SEARCH),
    withLatestFrom(state$),
    switchMap(search),
    catchError(error => of({
      type: REJECTED(actions.JOB_SEARCH),
      payload: buildFailedSearchState({ }, error),
      error,
    }))
  )
