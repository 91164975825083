import styled from '@emotion/styled'
import { COLORS } from '../theme'
import { ChipColorMap } from './ChipColorMap'
import { ChipComponent } from './ChipComponent'

export const HChip = styled(ChipComponent)`
  ${({ onClick }) => !onClick && 'pointer-events: none !important;'}
  position: relative;
  max-width: 100%;
  color: ${({ color, variant }) => ChipColorMap[color][variant].text}!important;
  background-color: ${({ color, variant }) =>
    ChipColorMap[color][variant].background}!important;
  border: ${({ variant }) => (variant === 'outlined' ? '1px solid' : 'none')}!important;
  border-color: ${({ color, variant }) =>
    (variant === 'outlined'
      ? ChipColorMap[color][variant].border
      : 'transparent')}!important;
  height: ${({ size }) => (size === 'large' ? '30px' : '26px')}!important;
  padding: 4px 12px!important;
  border-radius: 20px!important;
  &:active {
    box-shadow: none;
    color: ${({ color, variant }) =>
    ChipColorMap[color][variant].active.text} !important;
    border-color: ${({ color, variant }) =>
    (variant === 'outlined'
      ? ChipColorMap[color][variant].active.border
      : 'transparent')}!important;
    background-color: ${({ color, variant }) =>
    ChipColorMap[color][variant].active.background} !important;
    .chip_delete_icon svg path {
      fill: ${({ color, variant }) =>
    ChipColorMap[color][variant].active.deleteIconColor} !important;
    }
    .MuiChip-icon {
      color: ${({ color, variant }) =>
    ChipColorMap[color][variant].active.iconColor} !important;
    }
  }
  &:hover {
    color: ${({ color, variant }) =>
    ChipColorMap[color][variant].hover.text} !important;
    background-color: ${({ color, variant }) =>
    ChipColorMap[color][variant].hover.background} !important;
    border-color: ${({ color, variant }) =>
    (variant === 'outlined'
      ? ChipColorMap[color][variant].hover.border
      : 'transparent')}!important;
    .chip_delete_icon svg path {
      fill: ${({ color, variant }) =>
    ChipColorMap[color][variant].hover.deleteIconColor} !important;
    }
    .MuiChip-icon {
      color: ${({ color, variant }) =>
    ChipColorMap[color][variant].hover.iconColor} !important; 
    }
  }
  &:focus {
    background-color: ${({ color, variant }) =>
    ChipColorMap[color][variant].background} !important;
  }
  &:focus::after {
    position: absolute;
    content: '';
    left: -2px;
    right: -2px;
    top: -2px;
    bottom: -2px;
    border: 1px solid ${COLORS.primary_25} !important;
  }
  .chip_delete_icon {
    display: flex;
    padding-left: 8px;
    svg path {
      fill: ${({ color, variant }) =>
    ChipColorMap[color][variant].deleteIconColor}!important;
    }
  }
  .MuiChip-label {
    padding-left: 0;
    padding-right: 0;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .MuiChip-icon {
    margin-right: 8px !important;
    margin-left: 0 !important;
    height: ${({ size }) => (size === 'large' ? '20px' : '16px')}!important;
    width: ${({ size }) => (size === 'large' ? '20px' : '16px')}!important;
    font-size: 16px;
    color: ${({ color, variant }) =>
    ChipColorMap[color][variant].iconColor} !important;
  }
  &.Mui-disabled {
    opacity: 0.4;
  }
  .MuiChip-icon {
    color: ${({ color, variant }) =>
    ChipColorMap[color][variant].text} !important;
  }
`
