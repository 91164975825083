// @flow

import assign from 'assign-deep'
import compose from 'lodash/flowRight'
import * as React from 'react'
import Helmet from 'react-helmet'
import { injectIntl, type IntlShape } from 'react-intl'
import Loadable from 'react-loadable'
import NotificationSystem from 'react-notification-system'
import { connect } from 'react-redux'
import retina from 'retinajs'
import config from '../../../config'
import AbTestingExperiment from '../../abTestingOld/abTestingComponents/abTestingExperiment'
import {
  DUMMY_EXPERIMENT,
} from '../../abTestingOld/abTestingDefinitions'
import { runningInBrowser } from '../../actions/actions/support/base'
import UserCentrics from '../../cookieConsent/Usercentrics'
import localStorageUtils from '../../helpers/localStorageUtils'
import DebugDrawer from '../DebugDrawer'
import DisallowedCookieBar from '../layouts/disallowedCookieBar'
import { baseLinks, baseMetas } from '../tags'
import type { ConsentState } from '../../reducers/consent'
import type { SnackBarState } from '../../reducers/snackbar.ts'
import type { ApplicationState } from '../../types/applicationState'
import type { Country, Locale } from '../../types/common'
import type { UserState } from '../../reducers/user'
import { hideSnackBar } from '../../actions/actionCreators'
import { removeJUIDCookies } from '../../actions/job'
import { unPrefixRouteName } from '../../routing/routeNamePrefix'
import type { RouteName } from '../../routes'

type AppState = {
  NotificationElement: React.AbstractComponent<{}>,
  NotificationProps: Object,
  isLocalStorageEnabled: ?bool,
  isMounted: bool
}

type AppProps = {
  children: React.Node,
  country: Country,
  crmOptionsModal: ConsentState,
  hideCookieBar: bool,
  intl: IntlShape,
  isCRMModalsAllowed: bool,
  linkoutModal: {
    visible: bool,
  },
  locale: Locale,
  loginModal: {
    source: string,
    visible: bool,
  },
  onboardingModal: {
    visible: bool
  },
  optInModal: {
    visible: bool
  },
  pageName: RouteName,
  recommendationsModalVisible: bool,
  removeJUIDCookies: () => void,
  resendConfirmationEmailModalVisible: bool,
  routes: any,
  shouldShowApplicationSubmittedFollowUp: bool,
  snackbar: SnackBarState,
  successModal: {
    visible: bool
  },
  termsAndConditionsModal: {
    visible: bool
  },
  user: UserState
}

type NotificationSystemReturnType = {
  addNotification: (options: Object) => void,
  clearNotifications: () => void,
  defaultOptions: Object
}

if (runningInBrowser) {
  // TODO: refactor to be like mobileDetect, not defined in window
  assign(window, {
    heyjobs: {
      notificationSystem: {
        instance: null,
        defaultOptions: {
          autoDismiss: 10,
          position: 'br',
        },
        addNotification (options) {
          return window.heyjobs.notificationSystem.instance.addNotification(
            Object.assign({}, this.defaultOptions, options)
          )
        },
        clearNotifications () {
          return window.heyjobs.notificationSystem.instance.clearNotifications(
            this.defaultOptions
          )
        },
      },
    },
  })
}

const LoadableLoginModal = Loadable({
  loader: () => import('../modals/loginModal'),
  loading: () => <div />,
})
const LoadableLinkoutModal = Loadable({
  loader: () => import('../modals/linkoutModal.tsx'),
  loading: () => <div />,
})
const LoadableOnboardingModal = Loadable({
  loader: () => import('../modals/onboarding/onboarding.tsx'),
  loading: () => <div />,
})
const LoadableSuccessModal = Loadable({
  loader: () => import('../modals/successModal'),
  loading: () => <div />,
})
const LoadableCrmOptionsThankYouModal = Loadable({
  loader: () => import('../modals/crmOptionsThankYouModal'),
  loading: () => <div />,
})
const LoadableRecommendationsModal = Loadable({
  loader: () => import('../modals/recommendationsModal'),
  loading: () => <div />,
})
const LoadableResendEmailConfirmation = Loadable({
  loader: () => import('../modals/resendConfirmationModal'),
  loading: () => <div />,
})
const LoadableOptInModal = Loadable({
  loader: () => import('../modals/optInModal/OptInModal'),
  loading: () => <div />,
})
const LoadableApplicationFollowUpModal = Loadable({
  loader: () => import('../../pages/HomePage/components/ApplicationFollowUpModal'),
  loading: () => <div />,
})
const LoadableTermsAndConditionsModal = Loadable({
  loader: () => import('../modals/TermsAndConditionsModal.tsx'),
  loading: () => <div />,
})
const LoadableTermsAndConditionsSnack = Loadable({
  loader: () => import('../snacks/TermsAndConditionsSnack.tsx'),
  loading: () => <div />,
})
const LoadableSnackbar = Loadable({
  loader: () => import('../snacks/Snackbar.tsx'),
  loading: () => <div />,
})

// FIXME: stop using this global method and use <Notification /> component
export const getNotificationSystem = (): ?NotificationSystemReturnType => {
  if (runningInBrowser) {
    return window.heyjobs.notificationSystem
  }
}

const CRMModals = (props) => props.isCRMModalsAllowed
  ? (
    <>
      {props.recommendationsModalVisible && <LoadableRecommendationsModal />}
      {props.successModal.visible && <LoadableSuccessModal />}
      {props.crmOptionsModal.visible && <LoadableOptInModal />}
      {props.crmOptionsModal.displayThankYou && <LoadableCrmOptionsThankYouModal />}
      {props.resendConfirmationEmailModalVisible && <LoadableResendEmailConfirmation />}
    </>
    )
  : null

class _App extends React.Component<AppProps, AppState> {
  state = {
    NotificationElement: () => <div />,
    NotificationProps: {},
    isMounted: false,
    isLocalStorageEnabled: undefined,
  }

  setNotificationSystem = () => {
    if (!this.refs.notificationSystem) {
      this.setState({
        NotificationElement: NotificationSystem,
        NotificationProps: {
          style: window.heyjobs.notificationSystem.style,
          ref: 'notificationSystem',
        },
      })
    } else {
      window.heyjobs.notificationSystem.instance = this.refs.notificationSystem
    }
  }

  componentUpdate = () => {
    this.setNotificationSystem()
    if (runningInBrowser) retina()
  }

  componentDidMount () {
    this.componentUpdate()

    const jssStyles = document.getElementById('jss-server-side')
    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles)
    }

    const isLocalStorageEnabled = localStorageUtils.isEnabled

    this.setState({
      isLocalStorageEnabled,
    })

    this.props.removeJUIDCookies()
  }

  componentDidUpdate () {
    this.componentUpdate()
  }

  render () {
    const isAgbPage = unPrefixRouteName(this.props.pageName) === 'agb'

    const { NotificationElement, NotificationProps } = this.state

    return (
      <>
        <Helmet
          htmlAttributes={{ lang: this.props.locale }}
          meta={[
            ...baseMetas({ noindex: true, locale: this.props.locale, country: this.props.country }),
            {
              name: 'description',
              content: this.props.intl.formatMessage({
                id: 'site_description_home',
              }),
            },
            {
              name: 'keywords',
              content: this.props.intl.formatMessage({
                id: 'site_keywords_home',
              }),
            },
          ]}
          link={baseLinks}
        />

        {config.userCentrics.settingId && <UserCentrics />}

        {/* We must wait for the app to initialise to test this, otherwise we'll provide
            invalid initial state to the component */}
        {this.state.isLocalStorageEnabled !== undefined &&
          <DisallowedCookieBar
            isLocalStorageEnabled={this.state.isLocalStorageEnabled}
          />}

        {config.debug.renderDebugDrawer && <DebugDrawer />}

        {this.props.snackbar.termsAndConditions && <LoadableTermsAndConditionsSnack />}
        {this.props.snackbar.login &&
          <LoadableSnackbar
            color='success'
            message='snackbar_you_are_logged_in'
            handleClose={() => hideSnackBar('login')}
          />}
        {this.props.snackbar.criticalAuthError &&
          <LoadableSnackbar
            color='error'
            message='invalid_authentication_method'
            handleClose={() => hideSnackBar('criticalAuthError')}
          />}

        <AbTestingExperiment experimentName={DUMMY_EXPERIMENT}>
          {this.props.children}
        </AbTestingExperiment>
        <NotificationElement {...NotificationProps} />

        {/* Modals */}
        {this.props.loginModal.visible && <LoadableLoginModal />}
        {(
          this.props.user.isAuthenticated &&
          !this.props.user.user?.consented_terms_and_conditions &&
          !isAgbPage) &&
            <LoadableTermsAndConditionsModal />}
        {this.props.linkoutModal.visible && <LoadableLinkoutModal />}
        {this.props.shouldShowApplicationSubmittedFollowUp && <LoadableApplicationFollowUpModal />}
        {this.props.user.isAuthenticated &&
          <LoadableOnboardingModal />}
        <CRMModals {...this.props} />
      </>
    )
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user,
  locale: state.intlData.locale,
  country: state.locality.country,
  hideCookieBar: state.navbar.hideCookieBar,
  loginModal: state.loginModal,
  termsAndConditionsModal: state.termsAndConditionsModal,
  snackbar: state.snackbar,
  linkoutModal: state.linkoutModal,
  onboardingModal: state.onboardingModal,
  successModal: state.successModal,
  crmOptionsModal: state.consent,
  recommendationsModalVisible: state.recommendationsModal.visible,
  resendConfirmationEmailModalVisible: state.resendConfirmationEmailModal.visible,
  shouldShowApplicationSubmittedFollowUp: state.user?.shouldShowApplicationSubmittedFollowUp,
  isCRMModalsAllowed: state.recommendationsModal.isCRMModalsAllowed,
  pageName: state.routing.route.name,
})

const mapDispatchToProps = (dispatch) => ({
  removeJUIDCookies: () => dispatch(removeJUIDCookies()),
})

const App = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(_App)

export default App
