// @flow

export const identifiers = {
  DEVICE_TOKEN: 'heyjobs_js_token',
  TRACKING_TOKEN: 'heyjobs_js_tracking_token',
  STORED_SEARCH_OPTIONS: 'STORED_SEARCH_OPTIONS',
  JOB_ALERT_CONSENT: 'JOB_ALERT_CONSENT',
  UNREGISTERED_JOBSEEKER_ID: 'UNREGISTERED_JOBSEEKER_ID',
  USED_AUTH_FLOW_JOB_ID: 'USED_AUTH_FLOW_JOB_ID',
  SEE_ONBOARDING_MODAL: 'SEE_ONBOARDING_MODAL',
  JOB_IDS_IN_HIRING_BONUS_EXPERIMENT: 'JOB_IDS_IN_HIRING_BONUS_EXPERIMENT',
}

const storage = (() => {
  try {
    const currentDate = new Date().toISOString()
    const storage = window.localStorage
    storage.setItem(currentDate, currentDate)
    const fail = storage.getItem(currentDate) !== currentDate
    storage.removeItem(currentDate)
    if (fail) {
      return null
    }
    return storage
  } catch (exception) {
    return null
  }
})()

export const setItem = (key: string, value: string): void => {
  if (storage) {
    storage.setItem(key, value)
  }
}

export const removeItem = (key: string): void => {
  if (storage) {
    storage.removeItem(key)
  }
}

export const getItem = (key: string) => {
  if (storage) {
    return storage.getItem(key)
  }
}

const storeTokenInLocalStorage = (token: string, trackingToken: string) => {
  const oldToken = getItem(identifiers.DEVICE_TOKEN)

  if (oldToken !== token) {
    setItem(identifiers.DEVICE_TOKEN, token)
    setItem(identifiers.TRACKING_TOKEN, trackingToken)
  }
}

const isEnabled = !!storage

export default {
  identifiers,
  setItem,
  getItem,
  removeItem,
  isEnabled,
  storeTokenInLocalStorage,
}
