import React, { ReactNode } from 'react'
import { HBox, HChip, HDivider, HTypography } from 'talent-ui'
import { useScreenSize } from './hooks/use-screen-size'
import { useTranslate } from './hooks/use-translate'

type SelectComponentWrapperProps<T> = {
  selectedItems: T[]
  onDeleteItem: (item: T) => void
  texts: {
    select: string
    selected: string
  }
  margins: {
    dividerTop: number
    selectorTop: number
  }
  children: ReactNode
  getChipTestId: (item: T) => string
  getChipPlaceholder: (item: T) => string
}

// FIXME(TPRE-1566): Long chip names should be on multiple lines
export const truncateChipName = (name: string, maxLength = 40) => name.length > maxLength ? `${name.substring(0, maxLength)}...` : name

const SelectComponentWrapper = <T, >({
  selectedItems,
  onDeleteItem,
  texts,
  margins,
  children,
  getChipTestId,
  getChipPlaceholder,
}: SelectComponentWrapperProps<T>) => {
  const formatMessage = useTranslate()
  const { isMobile } = useScreenSize()

  return (
    <>
      {selectedItems.length > 0 && (
        <HBox>
          <HTypography
            variant='overline1'
            color='surface_gray_90'
            uppercase
          >
            {formatMessage(texts.selected)}
          </HTypography>
          <HBox
            display='flex'
            mt={4}
            flexDirection='row'
            flexWrap='wrap'
          >
            {selectedItems.map((item) => {
              const testId = getChipTestId(item)
              const placeholder = getChipPlaceholder(item)

              return (
                <HBox
                  key={placeholder}
                  mr={2}
                  mb={2}
                  md={{ mb: 4 }}
                >
                  <HChip
                    data-test-id={`selected-chip-${testId}`}
                    variant='filled'
                    color='blue'
                    size='large'
                    placeholder={isMobile ? truncateChipName(placeholder) : placeholder}
                    onDelete={() => onDeleteItem(item)}
                    onClick={() => onDeleteItem(item)}
                  />
                </HBox>
              )
            })}
          </HBox>
          <HBox
            mt={margins.dividerTop}
            md={{ mt: 4 }}
          >
            <HDivider />
          </HBox>
        </HBox>
      )}
      <HBox mt={margins.selectorTop}>
        <HTypography
          variant='overline1'
          color='surface_gray_90'
          uppercase
          space={{ mb: 4 }}
        >
          {formatMessage(texts.select)}
        </HTypography>
        {children}
      </HBox>
    </>
  )
}

export default SelectComponentWrapper
