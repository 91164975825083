// @flow

import {
  CUSTOM_RANKING_ORDER_BY,
  DEFAULT_SEARCH_MODE,
  DEFAULT_RANKING_EXPRESSION,
  DEFAULT_RANKING_IMPORTANCE_LEVEL,
} from '../../constants/base'
import type { ApplicationState } from '../../types/applicationState'
import type { JobSearchOptions } from '../../actions/search'

export const getRankingOptions = (state: ApplicationState, searchOptions?: JobSearchOptions) => {
  const isSortedByDate = searchOptions?.orderBy === 'date'
  const searchOptionsCustomRankingInfo = searchOptions?.custom_ranking_info

  const debugImportanceLevel = searchOptionsCustomRankingInfo ? searchOptionsCustomRankingInfo.importance_level : undefined
  const debugRankingExpression = searchOptionsCustomRankingInfo ? searchOptionsCustomRankingInfo.ranking_expression : undefined
  const searchMode = DEFAULT_SEARCH_MODE
  const orderBy = isSortedByDate ? 'postingPublishTime desc' : CUSTOM_RANKING_ORDER_BY
  const customRankingInfo = !isSortedByDate
    ? {
        importance_level: debugImportanceLevel || DEFAULT_RANKING_IMPORTANCE_LEVEL,
        ranking_expression: debugRankingExpression || DEFAULT_RANKING_EXPRESSION,
      }
    : null

  return {
    searchMode,
    orderBy,
    customRankingInfo,
  }
}
