// @flow

import type { Cookies } from 'react-cookie'
import type { Dispatch, GetState } from '../../store/configureStore'
import type { APIRequestConfig } from '../api/types'
import { getReqHeaders } from '../requestHeaders'

export type PromiseGenerator<T> = (...args: any) => (config: APIRequestConfig, dispatch: Dispatch, getState: GetState, cookies: Cookies) => Promise<T>

export function create (type: string, ...argNames: string[]) {
  return (...argValues: any[]) => {
    const action = { type }
    argNames.forEach((argName, i) => {
      action[argName] = argValues[i]
    })
    return action
  }
}

// use a thunk under the hood to provide auth token from state
export function createPromise<T> (type: string, generatePromise: PromiseGenerator<T>, argsAsMeta?: bool = false) {
  return (
    ...args: any
  ) => async (dispatch: Dispatch, getState: GetState, cookies: Cookies): Promise<T> => {
    const state = getState()

    const requestConfig = {
      headers: getReqHeaders(state),
    }

    return dispatch({
      type,
      payload: generatePromise(...args)(requestConfig, dispatch, getState, cookies),
      ...(argsAsMeta && { meta: args }),
    })
  }
}
