import React from 'react'

import { HApplicationCardTooltip } from './HApplicationCardTooltip'
import { ApplicationCardButtonProps } from './HApplicationCard.types'
import { HBox } from '../HBox'
import { HChip } from '../HChip'
import { HButton } from '../HButton'

const ActionButton: React.FC<{
  LinkComponent: ApplicationCardButtonProps['LinkComponent']
  actionStatus: NonNullable<ApplicationCardButtonProps['actionStatus']>
}> = ({
  LinkComponent,
  actionStatus,
}) => {
  const {
    testId,
    startIcon,
    text,
    tooltipMessage,
    tooltipTestId,
    isChip,
    href,
    ...buttonProps
  } = actionStatus

  const button = (
    <HButton
      data-test-id={`${testId}-button`}
      text={text}
      {...buttonProps}
      color='primary'
    />
  )

  if (href) {
    return (
      <LinkComponent
        to={href}
        onClick={() => { buttonProps.onClick?.() }}
      >
        {button}
      </LinkComponent>
    )
  }

  return button
}

export const ApplicationCardButton: React.FC<ApplicationCardButtonProps> =
 ({ actionStatus, LinkComponent, isSmall, isMobile }) => {
   if (!actionStatus) {
     return <HBox height={isSmall ? 40 : undefined} />
   }

   const {
     testId,
     startIcon,
     text,
     tooltipMessage,
     tooltipTestId,
     isChip,
   } = actionStatus

   return (
     <HBox display='flex' gap={8} data-test-id={testId} height={40}>
       {isChip
         ? (
           // FIXME(TPRE-2031): this disables any onClick handler
           <HChip
             variant='outlined'
             size='large'
             placeholder={text || ''}
             color='gray'
             icon={React.isValidElement(startIcon) ? startIcon : undefined}
           />)
         : (
           <ActionButton
             actionStatus={actionStatus}
             LinkComponent={LinkComponent}
           />
         )
       }
       {tooltipMessage &&
        <HApplicationCardTooltip
          message={tooltipMessage}
          isMobile={isMobile}
          tooltipTestId={tooltipTestId}
        />
       }
     </HBox>
   )
 }
