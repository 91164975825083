import React, { useCallback, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { HTypography } from '../HTypography'
import { HButton } from '../HButton'
import type { HSearchAutocompleteOption } from '../HSearchAutocomplete'
import { HBox } from '../HBox'
import { HSvgIconProps } from '../HSvgIcon'
import { CarbonIconType } from '@carbon/icons-react'
import { COLORS } from '../theme'

export type ExpandableListItem = Pick<HSearchAutocompleteOption, 'suggestion' | 'extra'>

export interface HExpandableListProps {
  maxVisibleItems?: number
  items: ExpandableListItem[]
  showLessText: string
  showMoreText: string
  title: string
  onItemSelect?: (item: ExpandableListItem) => void
  icon?: React.FC<HSvgIconProps> | CarbonIconType
}

const ListItem = (
  {
    label,
    onClick,
    Icon,
  }:
  {
    label: string
    onClick: () => void
    Icon?: React.FC<HSvgIconProps> | CarbonIconType
  },
): JSX.Element => (
  <HBox onClick={onClick} component='div' display='flex' gap={8} alignItems='center'>
    {Icon && (
      <HBox
        bgcolor={COLORS.surface_gray_05}
        borderRadius={2}
        width={32}
        height={32}
        display='flex'
        alignItems='center'
        justifyContent='center'
      >
        <Icon
          style={{ color: COLORS.surface_gray_80 }}
          width={20}
          height={20}
        />
      </HBox>
    )}
    <HTypography color="surface_gray_80">
      {label}
    </HTypography>
  </HBox>
)

const ShowMoreButton = styled(HButton)`
  justify-content: flex-start;
  margin-left: -${({ theme }) => theme.spacing(2)};
`

export const HExpandableList = ({
  maxVisibleItems,
  onItemSelect,
  items,
  showLessText,
  showMoreText,
  title,
  icon,
}: HExpandableListProps): JSX.Element => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false)

  const itemsInView = useMemo(
    () => maxVisibleItems
      ? (
        items.slice(0, maxVisibleItems)
      )
      : items
    , [items, maxVisibleItems],
  )

  const itemsInCollapseView = useMemo(
    () => maxVisibleItems
      ? (
        items.slice(maxVisibleItems)
      )
      : []
    , [items, maxVisibleItems],
  )

  const toggleAccordion = useCallback(() => {
    setIsAccordionOpen(!isAccordionOpen)
  }, [isAccordionOpen])

  const isAccordion = useMemo(() => (
    itemsInView.length < items.length
  ), [])

  return (
    <HBox display='flex' flexDirection='column' alignItems='flex-start' gap={16}>
      <HTypography weight='medium' variant='body1'>{title}</HTypography>
      {itemsInView.map((item, index) => (
        <ListItem
          key={index}
          label={item.suggestion}
          onClick={() => onItemSelect?.(item)}
          Icon={icon}
        />
      ))}

      {isAccordionOpen && itemsInCollapseView.map((item, index) => (
        <ListItem
          key={index}
          label={item.suggestion}
          onClick={() => onItemSelect?.(item)}
          Icon={icon}
        />
      ))}

      {isAccordion && (
        <ShowMoreButton
          onClick={toggleAccordion}
          variant='text'
          color="primary"
          data-test-id="show-more-button"
        >
          {isAccordionOpen ? showLessText : showMoreText}
        </ShowMoreButton>
      )}
    </HBox>
  )
}
