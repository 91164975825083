// @flow

import React from 'react'
import Loadable from 'react-loadable'
import find from 'lodash/find'

import * as actionCreators from '../../actions/actionCreators'
import { PageLoader } from '../shared/loader'
import * as userActions from '../../actions/user'
import * as jobActions from '../../actions/job'
import { withClientDataLoader } from '../../components/containers/withDataLoader'
import { getRoutesForLocale } from '../../routes'
import canFinishApplication from './utils/canFinishApplication'

import {
  shouldRedirectToSearchPage,
  redirectToSearchPage,
} from '../../pages/JobDetail/jobUtils'

import type { Job } from '../../types/job'
import type { ApplicationState } from '../../types/applicationState'
import type { Dispatch } from '../../store/configureStore'
import type { RouteData } from '../../types/common'

const shouldRedirectToJDP = (job: Job) => (
  !!(job?.link_out_url && job?.link_out_type !== 'smart_application_flow')
)

const redirectToJDP = (
  job: Job,
  countryRoutes: $FlowTODO,
  replace: Function
) => {
  const applyNowPath = countryRoutes.jobApply.reduxForm({ id: job.id })
  const redirectPath = countryRoutes.job({
    id: job.id,
    from: applyNowPath,
    linkOutSource: 'apply_now_page',
  })

  replace(redirectPath)
}

export const clientDataLoader = async (
  dispatch: Dispatch,
  state: ApplicationState,
  routeData: RouteData,
  replace: Function
) => {
  const jobId = routeData.params.id
  const { locale } = state.intlData
  const { country } = state.locality
  const routes = getRoutesForLocale(locale)
  const countryRoutes = routes.country(country)
  // $FlowFixMe
  const jdpRoute = countryRoutes.job({ id: jobId })

  // fetch job if it is not already in state
  let job = state.currentJob.job
  if (!job || job.id !== jobId) {
    const { value: fetchedJob } = await dispatch(jobActions.fetchCurrentJob(jobId))
    job = fetchedJob
  }

  if (shouldRedirectToJDP(job)) {
    redirectToJDP(job, countryRoutes, replace)
    return
  }

  // fetch screening questions
  await dispatch(actionCreators.fetchCurrentJobScreeningQuestions(jobId))

  if (shouldRedirectToSearchPage(job, !!state.request.isBot, routeData)) {
    redirectToSearchPage(replace, job, locale, country)
    return
  }

  // disallow showing CRM modals on job screening questions
  dispatch(actionCreators.updateIsCRMModalsAllowed(false))

  // check if the user has started an application, and if not, redirect back to application form
  if (state.user.user) {
    const { value: appliedJobs } = await dispatch(actionCreators.fetchApplications())
    const prevAppliedJob = find(
      appliedJobs,
      (appliedJob) => appliedJob.id === jobId
    )
    const isPendingCvOrigin = routeData.location.query.pending_cv === 'true'

    if (prevAppliedJob && !canFinishApplication(prevAppliedJob.application.status) && !isPendingCvOrigin) {
      replace(jdpRoute)
      return
    }
  }

  // fetch the latest user information
  await dispatch(userActions.resolveUser())

  // load the application documents if they are not already cached in state
  if (!state.documents.applicationDocumentsLoaded) {
    await dispatch(actionCreators.fetchApplicationDocuments())
  }
}

// $FlowFixMe
const LoadableApplicationFlow = Loadable({
  loader: () => import('./jobApplication'),
  loading: () => <PageLoader />,
})

const ApplicationFlow = withClientDataLoader(clientDataLoader)(LoadableApplicationFlow)

export default ApplicationFlow
