// @flow

import { Cookies } from 'react-cookie'

import datalayer from '../../clients/datalayer'
import { snowplowTrackPageView } from '../../clients/snowplow'

import getState from '../../shared/getState'

import getPageType from './getPageType'
import buildContexts from '../../shared/buildContexts'
import buildExperimentContextAndData from '../../contexts/ab_testing'

import trackPurchase from '../purchase'
import { CLIENT_COMPANY_FLAG, NON_CLIENT_COMPANY_FLAG } from '../../../constants/base'
import buildPageViewTrackingEventData from './buildPageViewTrackingEventData'
import processMarketingParams from '../../../middleware/processMarketingParams'

import type { ApplicationState } from '../../../types/applicationState'
import type { ContextData } from '../../types'
import { type RouteName, routeNames } from '../../../routes'
import { hasTriggerredCAFAuthFlow } from '../../../components/jobApplication/CAF/common/authFlowTracking.ts'
import { getTrackingData, removeTrackingData } from '../../../components/shared/SearchAutocomplete/tracking/utils.ts'

const PAGE_VIEW_EVENT_NAME = 'page_view'

// this check is needed to due locally initializing cookies
if (typeof window === 'undefined') {
  console.error(`${__filename} should not be imported on the server`) // eslint-disable-line no-console
  process.exit(1)
}

const cookies = new Cookies()

const pageAdditionalEventMapping: { [string]: ((ApplicationState, Cookies) => void)[] } = {
  web_job_detail: [trackPurchase({ clientStatus: CLIENT_COMPANY_FLAG })],
  out: [trackPurchase({ clientStatus: NON_CLIENT_COMPANY_FLAG })],
}

const trackGAPageView = (pageName: string, data: Object, state: ApplicationState, initialPageView: bool) => {
  const pageviewData = buildPageViewTrackingEventData(
    state.pageLoadRouteData.routeName,
    state
  )

  const gaPageViewData = {}
  gaPageViewData.path = window.location.pathname
  gaPageViewData.title = document.title
  gaPageViewData.location = document.location.origin + document.location.pathname + document.location.search
  gaPageViewData.referrer = document.referrer

  datalayer({
    ...(data || pageviewData),
    event: PAGE_VIEW_EVENT_NAME,
    page_type: pageName,
    page: gaPageViewData,
  })
}

const trackSnowplowPageView = (pageName: string, contextData: ?Object, state: ApplicationState) => {
  const job = state.currentJob.job

  const extraContexts = [
    {
      key: 'job_feed_context',
    },
    {
      key: 'job_search_result_context',
    },
    {
      key: 'page_context',
      options: {
        link_out_source: contextData?.link_out_source,
        pageName,
      },
    },
    {
      key: 'job_suggestion_context',
      options: {
        suggested_job_new_flag: contextData?.suggested_job_new_flag,
        suggested_job_position: contextData?.suggested_job_position,
        suggested_job_uid: contextData?.suggested_job_uid,
      },
    },
    {
      key: 'apply_context',
      options: {
        apply_click_type: contextData?.apply_click_type,
      },
    },
    {
      key: 'application_flow_version_context',
      options: {
        screeing_question_id: contextData?.screeing_question_id,
        screening_question_is_mandatory: contextData?.screening_question_is_mandatory,
        screening_question_type: contextData?.screening_question_type,
        step_number: contextData?.step_number,
        total_number_of_steps: contextData?.total_number_of_steps,
        is_auth_flow: contextData?.is_auth_flow ?? (pageName === routeNames.jobApplicationForm ? hasTriggerredCAFAuthFlow(job?.id) : undefined),
      },
    },
  ]

  if (pageName === 'out' || pageName === 'web_job_detail') {
    extraContexts.push({
      key: 'cpc_context',
      options: {
        isOut: pageName === 'out',
      },
    })
  }

  if (pageName === 'web_job_search') {
    const { keywordSuggestionId, sourcePage, locationSuggestionId } = getTrackingData()
    extraContexts.push({
      key: 'autosuggestion_context',
      options: {
        used_keyword_autosuggest: !!keywordSuggestionId,
        keyword_autosuggestion_id: keywordSuggestionId,
        previous_page_name: getPageType(sourcePage, state.pageLoadRouteData.routeNames),
        used_location_autosuggest: !!locationSuggestionId,
        location_autosuggestion_id: locationSuggestionId,
      },
    })
    removeTrackingData('keywordSuggestionId')
    removeTrackingData('locationSuggestionId')
  }

  const baseEventContexts = buildContexts(state, pageName, extraContexts)
  const experimentContexts = buildExperimentContextAndData(null)

  const snowplowEventContexts = [
    ...baseEventContexts,
    ...experimentContexts,
  ]

  snowplowTrackPageView(null, snowplowEventContexts)
}

const pageview = (pageType: ?(RouteName | string), data: ?ContextData = null, initialPageView: bool = false) => {
  const state = getState()

  // consider the referrer only on the initial page view
  const referrer = initialPageView ? document.referrer : null
  processMarketingParams(cookies)(window.location.search, referrer)

  const pageName = pageType || getPageType(
    state.pageLoadRouteData.routeName,
    state.pageLoadRouteData.routeNames
  )

  trackSnowplowPageView(pageName, data, state)
  trackGAPageView(pageName, data, state, initialPageView)

  // events that are triggered as part of the page view
  const additionalEvents = pageAdditionalEventMapping[pageName]
  if (additionalEvents) {
    additionalEvents.forEach(track => track(state, cookies))
  }
}

export default pageview
