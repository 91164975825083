// @flow

import first from 'lodash/first'
import * as actions from '../../constants/actions'
import * as utils from '../utils/reduxUtils'
import logger from '../../../logging'
import { resolvableEpicAction } from '../utils/epicUtils'
import cookies from '../../cookies'
import { guid } from '../../functions_/shared/guid'
import { fireSnowplowEvent } from '../../tracking/external/trackingClient'
import { KEYWORD_SEARCH_GCTS_EVENTS } from '../../tracking/eventNames'
import { GrowthBook } from '@growthbook/growthbook-react'

import { type GCTSJob } from '../../types/gctsJob'
import { type Cookies } from 'react-cookie'
import { type APIRequestConfig } from '../api/types'
import { type JobSearchCustomRankingOptions } from '../../actions/api/clients/search'
import { type ApplicationState } from '../../types/applicationState'
import { type Country } from '../../types/common'

export type JobSearchOptions = {
  companies: string[],
  country?: Country,
  custom_ranking_info?: JobSearchCustomRankingOptions,
  distance?: string,
  easyApply?: bool,
  employmentTypes: string[],
  experimentVariant?: string,
  keyword?: string,
  location?: string,
  orderBy?: 'date',
  page: number,
  redirect?: {
    city?: string,
    highlight?: string,
    page?: number,
    unpublished?: string,
    unpublishedJob?: string
  },
  salary?: bool,
  subdomain?: string,
  workingHours: string[]
}

export type JobReference = {
  uid: string
}

export type GCTSEventType = 'IMPRESSION' | 'APPLICATION_REDIRECT' | 'APPLICATION_REDIRECT_FROM_SEARCH' | 'VIEW' | 'VIEW_REDIRECT' | 'APPLICATION_START' | 'APPLICATION_FINISH' | 'BOOKMARK'

const log = logger('search-actions')

export const search = (options: JobSearchOptions) => resolvableEpicAction <JobSearchOptions>(
  actions.JOB_SEARCH,
  options
)

export const getSearchEngineExperimentVariant = (isBot: bool, growthbook: GrowthBook) => {
  if (isBot) {
    // bots are not included in the experiment
    return null
  }

  // heyjobs-engine a/b-test
  const searchEngineExperimentFeature = growthbook.evalFeature('matching_search_feature')
  if (searchEngineExperimentFeature?.value !== 'unknown') {
    return searchEngineExperimentFeature.value
  }

  return null
}

const getJobReference = (matchingJob: GCTSJob): JobReference => ({
  uid: matchingJob.job.requisition_id,
})

// GCTS Tracking Events
const logGCTSEventData = (data) => log.info(`${data.event_type} EVENT`, data)

const GCTS_EVENT_COOKIE_PREFIX = 'GCTS_EVENT_REQ_ID_'

export const saveGCTSToCookies = (cookieJar: Cookies) => (data: { event_id: string, request_id: string }) => {
  const allCookies = cookies.loadAll(cookieJar)()

  // removing cookies stored in user's browser.

  Object.keys(allCookies)
    .filter(m => m.startsWith(GCTS_EVENT_COOKIE_PREFIX))
    .forEach(m => cookies.remove(cookieJar)(m))

  const key = `${GCTS_EVENT_COOKIE_PREFIX}${first(data.request_id.split(':'))}`
  cookies.save(cookieJar)(key, data.event_id, {
    expires: new Date(Date.now() + 6 * 60 * 60 * 1000),
  })
}

const getGCTSParentEventId = (cookieJar: Cookies) => (requestId: string) => {
  const key = `${GCTS_EVENT_COOKIE_PREFIX}${first(requestId.split(':'))}`
  return cookies.load(cookieJar)(key)
}

export const sendGCTSEvent = (cookieJar: Cookies, growthbook: GrowthBook) => async (
  options: {
    details: {},
    eventType: GCTSEventType,
    relatedJobs: JobReference[],
    requestId: ?string
  },
  config: APIRequestConfig,
  state: ApplicationState
) => {
  const { eventType, requestId, details, relatedJobs } = options

  // Note: This `relatedJobs` check is protecting against a zero length array and
  // the first element being undefined.
  if (!requestId || !first(relatedJobs)) {
    return
  }

  const relatedJobNames = relatedJobs.map(j => `jobs/${j.uid}`)

  const now = new Date()
  const eventTimestampMillis = now.getTime()
  const eventId: string = guid()
  const parentEventId = getGCTSParentEventId(cookieJar)(requestId)

  const eventData = {
    event_id: eventId,
    event_type: eventType,
    request_id: requestId,
    parent_event_id: parentEventId || 'NULL',
    related_job_names: relatedJobNames || [],
    event_timestamp_millis: eventTimestampMillis,
    details,
  }

  logGCTSEventData(eventData)
  saveGCTSToCookies(cookieJar)({ request_id: eventData.request_id, event_id: eventData.event_id })

  // Route GCTS events to snowplow
  fireSnowplowEvent(
    KEYWORD_SEARCH_GCTS_EVENTS,
    eventData,
    [
      {
        key: 'job_search_result_context',
      },
      {
        key: 'job_feed_context',
      },
    ]
  )
}

export const impressionGCTSEvent = utils.createPromise(
  actions.FIRE_GCTS_TRACKING_EVENT,
  () => async (config, dispatch, getState, [cookieJar, growthbook]) => {
    const state = getState()
    const { jobs, requestId } = state.jobSearch
    const jobReferences = jobs.map(getJobReference)

    return await sendGCTSEvent(cookieJar, growthbook)(
      {
        eventType: 'IMPRESSION',
        requestId,
        relatedJobs: jobReferences,
        details: {},
      },
      config,
      getState()
    )
  }
)

export const applicationRedirectGCTSEvent = utils.createPromise(
  actions.FIRE_GCTS_TRACKING_EVENT,
  (options) => async (config, dispatch, getState, [cookieJar, growthbook]) =>
    await sendGCTSEvent(cookieJar, growthbook)(
      {
        eventType: 'APPLICATION_REDIRECT',
        ...options,
      },
      config,
      getState()
    )
)

export const applicationSearchRedirectGCTSEvent = utils.createPromise(
  actions.FIRE_GCTS_TRACKING_EVENT,
  (options) => async (config, dispatch, getState, [cookieJar, growthbook]) =>
    await sendGCTSEvent(cookieJar, growthbook)(
      {
        eventType: 'APPLICATION_REDIRECT_FROM_SEARCH',
        ...options,
      },
      config,
      getState()
    )
)

export const viewJDPGCTSEvent = utils.createPromise(
  actions.FIRE_GCTS_TRACKING_EVENT,
  (options) => async (config, dispatch, getState, [cookieJar, growthbook]) => {
    return await sendGCTSEvent(cookieJar, growthbook)(
      {
        eventType: 'VIEW',
        ...options,
      },
      config,
      getState()
    )
  }
)

export const viewLinkOutJobGCTSEvent = utils.createPromise(
  actions.FIRE_GCTS_TRACKING_EVENT,
  (options) => async (config, dispatch, getState, [cookieJar, growthbook]) =>
    await sendGCTSEvent(cookieJar, growthbook)(
      {
        eventType: 'VIEW_REDIRECT',
        ...options,
      },
      config,
      getState()
    )
)

export const applicationStartGCTSEvent = utils.createPromise(
  actions.FIRE_GCTS_TRACKING_EVENT,
  (options) => async (config, dispatch, getState, [cookieJar, growthbook]) =>
    await sendGCTSEvent(cookieJar, growthbook)(
      {
        eventType: 'APPLICATION_START',
        ...options,
      },
      config,
      getState()
    )
)

export const applicationFinishEvent = utils.createPromise(
  actions.FIRE_GCTS_TRACKING_EVENT,
  (options) => async (config, dispatch, getState, [cookieJar, growthbook]) =>
    await sendGCTSEvent(cookieJar, growthbook)(
      {
        eventType: 'APPLICATION_FINISH',
        ...options,
      },
      config,
      getState()
    )
)

export const bookmarkGCTSEvent = utils.createPromise(
  actions.FIRE_GCTS_TRACKING_EVENT,
  (options) => async (config, dispatch, getState, [cookieJar, growthbook]) =>
    await sendGCTSEvent(cookieJar, growthbook)(
      {
        eventType: 'BOOKMARK',
        ...options,
      },
      config,
      getState()
    )
)

export const GCTSTracking = {
  viewJDPGCTSEvent,
  viewLinkOutJobGCTSEvent,
  bookmarkGCTSEvent,
  impressionGCTSEvent,
  applicationFinishEvent,
  applicationStartGCTSEvent,
  applicationRedirectGCTSEvent,
  applicationSearchRedirectGCTSEvent,
}
