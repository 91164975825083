// @flow

import * as React from 'react'
import Loadable from 'react-loadable'
import { PageLoader } from '../../components/shared/loader'
import {
  withClientDataLoader,
} from '../../components/containers/withDataLoader'
import compose from 'lodash/flowRight'

import * as actionCreators from '../../actions/actionCreators'
import type { RouteData } from '../../types/common'
import type { Dispatch } from '../../store/configureStore'
import type { ApplicationState } from '../../types/applicationState'
import { getRoutesForLocale } from '../../routes'
import { fetchRecommendedJobs, toggleApplicationSubmittedFollowUp } from '../../actions/actionCreators'
import { fetchCurrentJob } from '../../actions/job'
import { getRecommendationOptionsFromJob } from '../EmailVerification'

// Match behavior of the CAF's `getRedirectPathAfterSubmit`
const failureRedirectPath = (routes, country, isCompanyPage) => {
  const routesForCountry = routes.country(country)

  return isCompanyPage
    ? routesForCountry.index
    : routesForCountry.jobs
}

export const applicationSuccessDataLoader = async (
  dispatch: Dispatch,
  state: ApplicationState,
  routeData: RouteData,
  replace: Function
) => {
  const jobId = routeData.params.id
  const isAuthenticated = state.user.isAuthenticated
  const { locale } = state.intlData
  const { country } = state.locality
  const routes = getRoutesForLocale(locale)
  const isCompanyPage = !!state.company?.current
  const redirectToFailurePage = () => {
    replace(failureRedirectPath(routes, country, isCompanyPage))
  }

  if (!isAuthenticated) {
    return redirectToFailurePage()
  }

  let job = state.currentJob.job
  if (!job || job.id !== jobId) {
    const { value: fetchedJob } = await dispatch(fetchCurrentJob(jobId))
    job = fetchedJob
    await dispatch(actionCreators.setCurrentJob(job))
  }

  try {
    const options = getRecommendationOptionsFromJob(job)
    const { value: matchingJobs } = await dispatch(fetchRecommendedJobs(options))

    if (!matchingJobs || matchingJobs.length === 0) {
      dispatch(toggleApplicationSubmittedFollowUp())
      return redirectToFailurePage()
    }

    const jobIds = matchingJobs.map((job) => job.job.requisition_id)
    dispatch(actionCreators.checkBookmarkJobs(jobIds))
  } catch (e) {
    return redirectToFailurePage()
  }
}
const _LoadableApplicationSuccess = Loadable<{}, {}>({
  loader: () => import('./ApplicationSuccess'),
  loading: () => <PageLoader />,
})

const LoadableApplicationSuccess = compose(
  withClientDataLoader(applicationSuccessDataLoader)
)(_LoadableApplicationSuccess)

export default LoadableApplicationSuccess
