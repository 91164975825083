import clsx from 'clsx'
import * as React from 'react'
import styled from '@emotion/styled'
import { NotificationProps } from './HNotification.types'
import { HBox } from '../HBox'
import { HIcon } from '../HIcon'
import { HTypography } from '../HTypography'
import { HButton } from '../HButton'
import { COLORS } from '../theme'

const COLOR_MAP: any = {
  default: {
    background: COLORS.primary_00,
    text: 'primary_50',
  },
  neutral: {
    background: COLORS.surface_gray_00,
    text: 'surface_gray_60',
  },
  success: {
    background: COLORS.support_success_00,
    text: 'support_success_50',
  },
  error: {
    background: COLORS.support_error_00,
    text: 'support_error_50',
  },
  warning: {
    background: COLORS.support_warning_00,
    text: 'support_warning_50',
  },
  help: {
    background: COLORS.support_help_00,
    text: 'support_help_50',
  },
}

const Notification = React.forwardRef<HTMLDivElement, NotificationProps>(
  (props, ref) => {
    const {
      className,
      variant = 'inline',
      icon,
      color = 'default',
      actionRight,
      actionRightProps,
      actionBottom,
      actionBottomProps,
      text,
      caption,
      ...others
    } = props

    const contained = variant === 'contained'

    return (
      <div className={clsx(className, variant, color)} {...others} ref={ref}>
        <HBox
          display='flex'
          alignItems={caption ? 'flex-start' : 'center'}
          justifyContent={contained ? 'space-between' : 'flex-start'}
        >
          <HBox display='flex' alignItems='flex-start'>
            {icon && (
              <HBox
                mr={2}
                height={contained && caption ? 22 : 18}
                display='flex'
                alignItems='center'
              >
                <HIcon
                  Icon={icon}
                  color={COLOR_MAP[color].text}
                  size={contained ? 20 : 16}
                ></HIcon>
              </HBox>
            )}
            <HBox ml={icon ? 0 : actionBottom ? 2 : 0}>
              {caption && (
                <HBox mb={2}>
                  <HTypography
                    variant='body1'
                    weight='regular'
                    color={COLOR_MAP[color].text}
                  >
                    {caption}
                  </HTypography>
                </HBox>
              )}
              <HTypography
                variant='body2'
                weight='regular'
                color={COLOR_MAP[color].text}
              >
                {text}
              </HTypography>
            </HBox>
          </HBox>

          {actionRight && (
            <HBox ml={4}>
              <HButton variant='text' color='primary' {...actionRightProps}>
                {actionRight}
              </HButton>
            </HBox>
          )}
        </HBox>
        <HBox>
          {actionBottom && (
            <HBox ml={icon && contained ? 5 : icon ? 4 : 0} mt={2}>
              <HButton variant='text' color='primary' {...actionBottomProps}>
                {actionBottom}
              </HButton>
            </HBox>
          )}
        </HBox>
      </div>
    )
  },
)

Notification.displayName = 'Notification'

export const HNotification = styled(Notification)`
  &.contained {
    background-color: ${({ color = 'default' }) => COLOR_MAP[color].background};
    border-radius: 4px;
    padding: 12px;
    padding-bottom: ${({ actionBottom }) => (actionBottom ? '8px' : '12px')};
  }
`
