// @flow
import * as React from 'react'
import styled, { css, type StyledComponent } from 'styled-components'
import Link from '../Link'
import { Spinner, IconComponent, Text } from './buttonComponents'
import * as buttonStyles from './buttonStyles'

type LinkButtonText = {
  children?: React.Node,
  text: string,
}

type LinkButtonChildren = {
  children: React.Node,
  text?: string,
}

type LinkButtonChildrenOrText = LinkButtonText | LinkButtonChildren

type LinkButtonHref = {
  href: string
}

type LinkButtonTo = {
  to: string
}

type LinkButtonHrefOrTo = LinkButtonHref | LinkButtonTo

type LinkButtonProps = LinkButtonChildrenOrText & LinkButtonHrefOrTo & {
  Icon?: React.Node,
  disabled?: bool,
  isLoading?: ?bool,
  onClick?: Function,
  target?: bool,
  type?: string
}

const linkStyles = css`
  && {
    ${buttonStyles.baseButtonStyles}
    ${buttonStyles.primaryAppliedStyles}
    ${buttonStyles.outlineAppliedStyles}

    ${buttonStyles.baseButtonHover}
    ${buttonStyles.baseButtonActive}
  }
`

const CustomLink: StyledComponent<{}, *, *> = styled(({ to, href, onClick, target, children, className, ...props }) => {
  const destination = href || to

  return (
    <Link
      to={destination}
      className={className}
      target={target}
      onClick={onClick}
      data-test-id={props['data-test-id']}
    >
      {children}
    </Link>
  )
})`
  ${linkStyles}
`

const LinkButton = ({
  onClick,
  children,
  Icon,
  text,
  isLoading,
  disabled,
  ...passThroughRestToContainer
}: LinkButtonProps) => (
  <CustomLink
    {...passThroughRestToContainer}
    onClick={onClick}
    isLoading={isLoading}
    disabled={disabled}
  >
    {isLoading && <Spinner />}

    {Icon && <IconComponent Icon={Icon} />}

    {text && <Text text={text} />}

    {children}
  </CustomLink>
)

export default LinkButton
