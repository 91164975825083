import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import React, {
  ReactElement,
  ReactNode,
  createContext,
  useContext,
} from 'react'
import { HBox } from '../HBox'

const BookmarkAnimationContext = createContext(false)

interface BookmarkAnimationProviderProps {
  isBookmarkAnimationActive: boolean
  children: ReactNode
}

export const BookmarkAnimationProvider = ({
  isBookmarkAnimationActive,
  children,
}: BookmarkAnimationProviderProps): ReactElement => {
  return (
    <BookmarkAnimationContext.Provider value={isBookmarkAnimationActive}>
      {children}
    </BookmarkAnimationContext.Provider>
  )
}

export const useBookmarkAnimation = (): boolean => {
  return useContext(BookmarkAnimationContext)
}

const blinkingAnimation = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(60, 105, 190, 0.5);
  }

  30% {
    box-shadow: 0 0 0 6px rgba(60, 105, 190, 0);
  }

  40% {
    box-shadow: 0 0 0 rgba(60, 105, 190, 0);
  }
`

export const AnimatedBox = styled(HBox)`
  &.blinking {
    animation-name: ${blinkingAnimation};
    animation-duration: 3s;
    animation-timing-function: ease-out;
    animation-delay: 0.5s;
    animation-iteration-count: infinite;
  }
`
