// @flow

import ceil from 'lodash/ceil'
import * as actions from '../constants/actions'
import { PENDING, FULFILLED, REJECTED } from './promiseUtil'
import { DEFAULT_SEARCH_PAGE_SIZE } from '../constants/base'
import type { GCTSJob } from '../types/gctsJob'

export type BookmarksState = {
  allBookmarks: {
    error: any,
    failed: bool,
    jobs: GCTSJob[],
    loading: bool,
    numJobs: number,
    totalPages: number
  },
  animationTimer: TimeoutID | null,
  currentBookmarks: {
    failed: bool,
    ids: {
      [id: string]: bool
    },
    loading: bool
  }
}

const initialState: BookmarksState = {
  allBookmarks: {
    loading: false,
    failed: false,
    error: null,
    jobs: [],
    numJobs: 0,
    redirect: {},
    totalPages: 1,
  },
  currentBookmarks: {
    loading: false,
    failed: false,
    ids: {},
  },
  animationTimer: null,
}

export default (state: BookmarksState = { ...initialState }, action: any): BookmarksState => {
  switch (action.type) {
    case PENDING(actions.GET_JOB_BOOKMARKS):
      return {
        ...state,
        allBookmarks: {
          ...initialState.allBookmarks,
          numJobs: state.allBookmarks.numJobs,
          loading: true,
        },
      }

    case REJECTED(actions.GET_JOB_BOOKMARKS):
      return {
        ...state,
        allBookmarks: {
          ...initialState.allBookmarks,
          failed: true,
          error: action.payload,
        },
      }

    case FULFILLED(actions.GET_JOB_BOOKMARKS):
      return {
        ...state,
        allBookmarks: {
          ...initialState.allBookmarks,
          numJobs: action.payload.total_size,
          jobs: action.payload.matching_jobs,
          totalPages: ceil(action.payload.total_size / DEFAULT_SEARCH_PAGE_SIZE),
        },
      }

    case PENDING(actions.CHECK_BOOKMARK_JOBS):
      return {
        ...state,
        currentBookmarks: {
          ...state.currentBookmarks,
          loading: true,
          failed: false,
        },
      }

    case REJECTED(actions.CHECK_BOOKMARK_JOBS):
      return {
        ...state,
        currentBookmarks: {
          ...state.currentBookmarks,
          loading: false,
          failed: true,
        },
      }

    case FULFILLED(actions.CHECK_BOOKMARK_JOBS):
      return {
        ...state,
        currentBookmarks: {
          loading: false,
          failed: false,
          ids: {
            ...action.payload,
            ...state.currentBookmarks.ids,
          },
        },
      }

    case PENDING(actions.BOOKMARK_JOB):

      return {
        ...state,
        currentBookmarks: {
          ...state.currentBookmarks,
          ids: {
            ...state.currentBookmarks.ids,
            [action.meta[0]]: true,
          },
        },
      }

    case REJECTED(actions.BOOKMARK_JOB):

      return {
        ...state,
        currentBookmarks: {
          ...state.currentBookmarks,
          ids: {
            ...state.currentBookmarks.ids,
            [action.meta[0]]: false,
          },
        },
      }

    case FULFILLED(actions.BOOKMARK_JOB):

      return {
        ...state,
        allBookmarks: {
          ...state.allBookmarks,
          numJobs: action.payload.bookmarks_total || 0,
        },
      }

    case PENDING(actions.UN_BOOKMARK_JOB):

      return {
        ...state,
        currentBookmarks: {
          ...state.currentBookmarks,
          ids: {
            ...state.currentBookmarks.ids,
            [action.meta[0]]: false,
          },
        },
      }

    case REJECTED(actions.UN_BOOKMARK_JOB): {
      return {
        ...state,
        currentBookmarks: {
          ...state.currentBookmarks,
          ids: {
            ...state.currentBookmarks.ids,
            [action.meta[0]]: action.payload.res.status !== 404,
          },
        },
      }
    }

    case FULFILLED(actions.UN_BOOKMARK_JOB):
      return {
        ...state,
        allBookmarks: {
          ...state.allBookmarks,
          jobs: state.allBookmarks.jobs.filter((job) => job.job.requisition_id !== action.meta[0]),
          numJobs: action.payload.bookmarks_total || 0,
        },
      }

    case actions.CLEAR_BOOKMARKS:
      return initialState

    case actions.BOOKMARK_ANIMATION:
      if (state.animationTimer) {
        clearTimeout(state.animationTimer)
      }

      return {
        ...state,
        animationTimer: action.payload,
      }

    default:
      return state
  }
}
