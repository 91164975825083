// @flow

import * as React from 'react'
import { withRouter as _withRouter, useRouteMatch, type ContextRouter } from 'react-router'
import { parseQueryString } from '../helpers/url/query'

export const withRouter = (Component: React.ComponentType<any>) => _withRouter<any>(
  (routeProps: ContextRouter) => {
    const { params } = useRouteMatch()
    const routeData = {
      ...routeProps,
      location: {
        ...routeProps.location,
        pathname: encodeURI(routeProps.location.pathname),
        query: parseQueryString(routeProps.location.search),
      },
      params,
    }
    return <Component {...routeData} />
  }
)
