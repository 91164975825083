import { styled } from '@mui/material/styles'
import { COLORS, globalStyles } from '../theme'

const PREFIX = 'HFile'

export const classes = {
  root: `${PREFIX}-root`,
  active: `${PREFIX}-active`,
  plain: `${PREFIX}-plain`,
  error: `${PREFIX}-error`,
  shadowClipLeft: `${PREFIX}-shadowClipLeft`,
  shadowClipRight: `${PREFIX}-shadowClipRight`,
  focusVisible: `${PREFIX}-focusVisible`,
  selected: `${PREFIX}-selected`,
  typography: `${PREFIX}-typography`,
  overflow: `${PREFIX}-overflow`,
  buttonGroup: `${PREFIX}-buttonGroup`,
  endIcon: `${PREFIX}-endIcon`,
}

export const FileContainer = styled('div')(() => ({
  [`& .${classes.root}`]: {
    paddingTop: 8,
    paddingBottom: 8,
    height: '34px',
    width: 'fit-content',
    maxWidth: '100%',
    textTransform: 'none',
    color: COLORS.surface_gray_80,
    border: `1px solid ${COLORS.surface_gray_40}`,
    '&:disabled': {
      opacity: '0.4',
      boxShadow: globalStyles.noShadow.boxShadow,
      color: COLORS.surface_gray_80,
      border: `1px solid ${COLORS.surface_gray_40}`,
    },
  },
  [`& .${classes.active}`]: {
    boxShadow: globalStyles.shadow2.boxShadow,
    '&:hover': {
      boxShadow: globalStyles.shadow3.boxShadow,
      backgroundColor: COLORS.surface_gray_05,
    },
    '&:focus': {
      boxShadow: globalStyles.shadow2.boxShadow,
      backgroundColor: COLORS.surface_white,
    },
    '&:disabled': {
      boxShadow: 'none',
    },
  },
  [`& .${classes.plain}`]: {
    boxShadow: globalStyles.shadow1.boxShadow,
    '&:hover': {
      boxShadow: globalStyles.shadow2.boxShadow,
      backgroundColor: COLORS.surface_gray_05,
    },
    '&:focus': {
      boxShadow: globalStyles.shadow1.boxShadow,
      backgroundColor: COLORS.surface_white,
    },
    '&:disabled': {
      boxShadow: 'none',
    },
  },
  [`& .${classes.error}`]: {
    border: `1px solid ${COLORS.support_error_50}`,
  },
  [`& .${classes.endIcon}`]: {
    paddingLeft: '16px',
  },
  [`& .${classes.shadowClipLeft}`]: {
    clipPath: 'inset(-5px -5px -5px 0)',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    marginLeft: '-1px',
  },
  [`& .${classes.shadowClipRight}`]: {
    clipPath: 'inset(-5px 0 -5px -5px)',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  [`& .${classes.focusVisible}`]: {
    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: -1,
      bottom: -1,
      left: -1,
      right: -1,
      border: `1px solid ${COLORS.primary_25}`,
    },
  },
  [`& .${classes.selected}`]: {
    backgroundColor: COLORS.surface_gray_10,
  },
  [`& .${classes.buttonGroup}`]: {
    width: '100%',
  },
}))
