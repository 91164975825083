import API from '../clientBuilder'

import type {
  APIRequestConfig,
} from '../types'
import { Locale } from '../../../types/common'
import { buildUrlFragment } from '../../../helpers/url/query'
import { OnboardingAPIParams, OnboardingFetchResponse } from 'onboarding-flow'

export default {
  save: (userId: string, data: OnboardingAPIParams, config: APIRequestConfig): Promise<boolean> => {
    return API.post.promise(`/v2/talent_platform/onboarding_flow/${userId}`, data, config)
  },
  get: (userId: string, config: APIRequestConfig, locale?: Locale): Promise<OnboardingFetchResponse> => {
    const localeSuffix = buildUrlFragment({ locale })
    return API.get.promise(`/v2/talent_platform/onboarding_flow/${userId}${localeSuffix}`, null, config)
  },
}
