// @flow

import buildJobContextTrackingEventData from '../../misc/buildJobContextTrackingEventData'
import type { Job } from '../../../types/job'
import type { ApplicationState } from '../../../types/applicationState'
import type { RouteName } from '../../../routes'

const formatJobEventData = (job: ?Job): Object =>
  job
    ? {
        ...buildJobContextTrackingEventData(job),
        ...(job.application
          ? {
              application_uid: job.application.id,
            }
          : {}),
      }
    : {}

// TODO: all data mapping from state should be moved into the respective context builders
// and this function would be no longer necessary.

const additionalPageViewEventData = (routeName: RouteName, state: ApplicationState): Object => {
  switch (routeName) {
    case 'job':
    case 'job-apply-success':
    case 'job-apply-form-new':
      return {
        number_of_screening_questions: state.currentJob.screeningQuestions?.length || null,
        ...formatJobEventData(state.currentJob.job),
      }
    case 'out':
      return {
        link_out_source: state.routing.location?.query.linkOutSource,
        ...formatJobEventData(state.currentJob.job),
      }

    default:
      return formatJobEventData(state.currentJob.job)
  }
}

export default additionalPageViewEventData
