import { useCallback } from 'react'
import { type FormatXMLElementFn } from 'intl-messageformat'
import { type PrimitiveType, useIntl } from 'react-intl'
// FIXME(TPRE-2096): remove JSP dependencies. Related - TPRE-2046
import type Messages from '../translations/de.json'

type TranslateFunction = (id: AllowedMessageIds, values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>) => string
type StrictTranslateFunction = (id: MessageKeys, values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>) => string
type Translations = typeof Messages
export type MessageKeys = keyof Translations

// eslint-disable-next-line @typescript-eslint/ban-types
type LiteralUnion<T> = T | (string & {})

type AllowedMessageIds = LiteralUnion<MessageKeys>

type RestrictedUseTranslate = () => TranslateFunction

export const useTranslate: RestrictedUseTranslate = (): ((id: string, values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>) => string) => {
  const intl = useIntl()

  const translate = useCallback<TranslateFunction>((id, values) => {
    return intl.formatMessage({ id }, values)
  }, [intl])

  return translate
}

export const useStrictTranslate: () => StrictTranslateFunction = () => {
  return useTranslate()
}
