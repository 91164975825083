// @flow

import moment from 'moment'

import type { Job } from '../../types/job'

import {
  MAXIMUM_JOB_SHOWN_AGE,
} from '../../constants/base'

export const addPublicationData = (job: Job) => {
  job.publicationData = getPublicationData(job)
  return job
}

export const getPublicationData = (job: Job) => {
  const [date, format] = job.posting_publish_time
    ? [job.posting_publish_time, moment.ISO_8601]
    : [job.last_published_at || job.created_at, 'x']
  if (!date) return

  const publishedAt = moment(date, format)

  return getPublicationDataFromDate(publishedAt)
}

const getPublicationDataTranslationKey = (days: number) => {
  switch (days) {
    case 0:
      return 'zero'
    case 1:
      return 'one'
    default:
      return 'many'
  }
}

const getPublicationDataFromDate = (date: moment) => {
  const days = moment()
    .startOf('day')
    .diff(date.startOf('day'), 'days')

  return {
    days,
    new: days === 0,
    shouldShowDate: days <= MAXIMUM_JOB_SHOWN_AGE && days > 0,
    translationKey: getPublicationDataTranslationKey(days),
  }
}
