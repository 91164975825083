export const identifiers = {
  postSignupData: 'POST_SIGNUP_DATA',
  triggeredCAFAuthFlowJobId: 'TRIGGERED_CAF_AUTH_FLOW_JOB_ID',
  usedAuthFlowJobId: 'USED_AUTH_FLOW_JOB_ID',
  marketingSearchMode: 'msm',
  autoSuggestionTrackingData: 'AUTO_SUGGESTION_TRACKING_DATA',
  gtmDebug: 'GTM_DEBUG',
} as const

export const isSessionStorageAvailable = () => {
  try {
    window.sessionStorage.setItem('test', 'test')
    window.sessionStorage.removeItem('test')
    return true
  } catch (error) {
    return false
  }
}

type SessionStorageKey = typeof identifiers[keyof typeof identifiers]
const sessionStorage = isSessionStorageAvailable() ? window.sessionStorage : null

export const getItem = (key:SessionStorageKey) => {
  return sessionStorage && sessionStorage.getItem(key)
}

export const setItem = (key:SessionStorageKey, value:string) => {
  return sessionStorage && sessionStorage.setItem(key, value)
}

export const removeItem = (key:SessionStorageKey) => {
  sessionStorage && sessionStorage.removeItem(key)
}
