export interface ResetPasswordProps {
  intl?: any,
  code?: string,
  replace: Function,
  location: {
    query: {
      email: string,
      resetType?: 'initial' | 'mig_initial',
      redirectUrl?: string,
      authOrigin?: string,
      confirmationToken?: string
    }
  }
}

export enum ErrorType {
  ExpiredCode = 'ExpiredCodeException',
  CodeMismatch = 'CodeMismatchException',
  LimitExceeded = 'LimitExceededException'
}

export interface RouteParams {
  code: string
}

export interface ResetPasswordState {
  newPassword: string
}
