// @flow

import ServerError from './ServerError'
import isArray from 'lodash/isArray'

type APIErrorPayload = {
  code: string,
  message: string,
  messages?: string[]
}

type APIErrors = APIErrorPayload[]

export default class ApiError extends ServerError {
  // public
  errors: APIErrors

  constructor (
    errors: APIErrors,
    req: Request,
    res: Response,
    body: string,
    message: ?string = null
  ) {
    super(
      req,
      res,
      body,
      message ?? ApiError.buildApiErrorMessage(req, res, errors)
    )
    this.name = 'ApiError'
    this.errors = errors
  }

  static buildApiErrorMessage (req: Request, res: Response, errors: APIErrors): string {
    return `${super.buildServerErrorMessage(req, res)} ${this.stringifyErrors(errors)}`
  }

  static stringifyErrors (errors: APIErrors): string {
    try {
      if (isArray(errors)) {
        return errors.map(e => e.code || JSON.stringify(e)).join(',')
      }
      return JSON.stringify(errors)
    } catch {
      return 'non-serializable'
    }
  }

  get props () {
    const { errors } = this
    return {
      ...super.props,
      errors,
    }
  }
}
