// @flow
import type { Cookies } from 'react-cookie'

import type { ApplicationState } from '../../types/applicationState'
import type { TrackingSchema } from './types'

type Options = {
  apply_click_type?: string,
}

export type ApplyType = {
  key: 'apply_context',
  options?: Options,
}

export const SCHEMA_NAME = 'iglu:de.heyjobs/apply_context/jsonschema/1-0-0'

export default (state: ApplicationState, cookies: Cookies, options?: Options): Array<TrackingSchema> => [{
  schema: SCHEMA_NAME,
  data: {
    apply_click_type: options?.apply_click_type,
  },
}]
