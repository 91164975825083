// @flow
import get from 'lodash/get'
import isUndefined from 'lodash/isUndefined'
import logger from '../../../../logging'
import getPageType from '../../../tracking/external/getPageType'
import { load as marketingParamLoad } from '../../../helpers/marketingParamStore'
import config from '../../../../config'
import { Cookies } from 'react-cookie'
import { type RouteName, routeNames } from '../../../routes'
import type { Dispatch } from '../../../store/configureStore'
import type { ApplicationState } from '../../../types/applicationState'
import {
  markOnPageNPSEventAsTriggered,
  incrementNumberOfHomePageView,
  incrementNumberOfInboxPageView,
  incrementNumberOfJobSearchPageView,
  markCESEventForCAFAsTriggered,
  createUnregisteredJobseeker,
} from '../../../actions/actionCreators'
import * as localStorage from '../../../helpers/localStorageUtils'
import { type UnregisteredJobseeker } from '../../../actions/api/clients/users'
import { unPrefixRouteName } from '../../../routing/routeNamePrefix'
import {
  InAppMessage,
  initialize,

  changeUser as brazeChangeUser,
  destroy as brazeDestroy,
  getUser,
  isPushBlocked as brazeIsPushBlocked,
  isPushPermissionGranted as brazeIsPushPermissionGranted,
  isPushSupported as brazeIsPushSupported,
  logCustomEvent as brazeLogCustomEvent,
  openSession as openSessionBase,
  requestImmediateDataFlush as brazeRequestImmediateDataFlush,
  requestPushPermission as brazeRequestPushPermission,
  showInAppMessage as brazeShowInAppMessage,
  subscribeToInAppMessage as brazeSubscribeToInAppMessage,
  setLogger as brazeSetLogger,
  isDisabled as brazeIsDisabled,
} from '@braze/web-sdk'

import { executeBrazeCall, BrazeQueueManager } from './braze-queue.tsx'

type additionalBrazeEventConditions = {|
  eventName: string,
  incrementNumberOfThisPageView: Function,
  numberOfThisPageView: string,
  pageType: string,
  requiredNumberOfThisPageView: number
|}

// this check is needed to due locally initiallizing cookies
if (typeof window === 'undefined') {
  console.error(`${__filename} should not be imported on the server`) // eslint-disable-line no-console
  process.exit(1)
}

// Create wrapped versions of each SDK method
const changeUser = (...args) => executeBrazeCall(() => brazeChangeUser(...args))
const destroy = (...args) => executeBrazeCall(() => brazeDestroy(...args))
const isPushBlocked = (...args) => executeBrazeCall(() => brazeIsPushBlocked(...args))
const isPushPermissionGranted = (...args) => executeBrazeCall(() => brazeIsPushPermissionGranted(...args))
const isPushSupported = (...args) => executeBrazeCall(() => brazeIsPushSupported(...args))
const logCustomEvent = (...args) => executeBrazeCall(() => brazeLogCustomEvent(...args))
const openBrazeSession = (...args) => executeBrazeCall(() => openSessionBase(...args))
const requestImmediateDataFlush = (...args) => executeBrazeCall(() => brazeRequestImmediateDataFlush(...args))
const requestPushPermission = (...args) => executeBrazeCall(() => brazeRequestPushPermission(...args))
const showInAppMessage = (...args) => executeBrazeCall(() => brazeShowInAppMessage(...args))
const subscribeToInAppMessage = (...args) => executeBrazeCall(() => brazeSubscribeToInAppMessage(...args))
const setLogger = (...args) => executeBrazeCall(() => brazeSetLogger(...args))
const isDisabled = (...args) => executeBrazeCall(() => brazeIsDisabled(...args))

const cookieJar = new Cookies()
const log = logger('braze-tracking')

const WEB_PUSH_CUSTOM_ATTRIBUTE = 'soft_optin'
const isProduction = config.env === 'production'
//* We need some delay to complete the setup required for web push notifications. No delay could result in a denial of the requestPushPermission.
const WEB_PUSH_DELAY = isProduction ? 30000 : 5000

const TRACKABLE_PAGE_TYPES = [
  { routeName: 'job', eventName: 'job_details_page_viewed' },
  { routeName: 'out', eventName: 'application_started' },
  { routeName: 'job-type-quiz', eventName: 'finished_test' },
]

const ATTRIBUTE_PATHS = [
  { key: 'job_uid', path: 'currentJob.job.id' },
  { key: 'company_id', path: 'currentJob.job.company.id' },
  { key: 'company_client_status', path: 'currentJob.job.company.client_status' },
  { key: 'job_job_type', path: 'currentJob.job.type.codename' },
  { key: 'job_working_hours_type', path: 'currentJob.job.working_hours_type_key' },
  { key: 'job_easy_apply', path: 'currentJob.job.allow_easy_apply' },
  { key: 'job_link_out_type', path: 'currentJob.job.link_out_type' },
]

export const LIST_OF_CES_EVENT_CONDITIONS = [
  {
    eventName: 'homepage_ces',
    pageType: 'tp_homepage',
    numberOfThisPageView: 'numberOfHomePageView',
    requiredNumberOfThisPageView: 3,
    incrementNumberOfThisPageView: () => incrementNumberOfHomePageView(),
  },
  {
    eventName: 'serp_ces',
    pageType: 'web_job_search',
    numberOfThisPageView: 'numberOfJobSearchPageView',
    requiredNumberOfThisPageView: 5,
    incrementNumberOfThisPageView: () => incrementNumberOfJobSearchPageView(),
  },
  {
    eventName: 'inbox_ces',
    pageType: 'messages-list',
    numberOfThisPageView: 'numberOfInboxPageView',
    requiredNumberOfThisPageView: 2,
    incrementNumberOfThisPageView: () => incrementNumberOfInboxPageView(),
  },
]

export const getBrazeEventAttributesFromState = (state: ApplicationState) => {
  const marketingSource = marketingParamLoad(cookieJar)()
  const attributes = {}

  if (marketingSource) {
    attributes.application_mkt_source = marketingSource.source
  }

  ATTRIBUTE_PATHS.forEach((attribute) => {
    const value = get(state, attribute.path)

    if (!isUndefined(value)) {
      attributes[attribute.key] = value
    }
  })

  return attributes
}

// Potentially causes a race condition
let isBrazeInitialized = false

export const configureBraze = async () => {
  if (config.braze.apiKey) {
    try {
      isBrazeInitialized = await initialize(config.braze.apiKey, {
        baseUrl: 'https://sdk.fra-01.braze.eu/api/v3',
        safariWebsitePushId: 'web.com.heyjobs.push',
        enableLogging: !isProduction,
        doNotLoadFontAwesome: true,
        allowUserSuppliedJavascript: true,
        openInAppMessagesInNewTab: true,
      })

      setLogger(log.info)

      BrazeQueueManager.getInstance().setInitialized()
    } catch (err) {
      log.error('Braze failed to initialize', err)
    }
  }

  return isBrazeInitialized
}

export const wrapPrivacyPolicyTextWithAnchorTag = (str: string) => {
  const enUrl = 'https://www.heyjobs.co/recruiting/en/privacy-policy/'
  const deUrl = 'https://www.heyjobs.co/recruiting/datenschutzerklaerung/'
  const styles = 'style="color:#707485; text-decoration: underline"'
  str = str.replace('CRMPrivacyPolicyEN', `<a href="${enUrl}" target="_blank" rel="noopener noreferrer" ${styles}>Privacy Policy</a>`)
  str = str.replace('CRMPrivacyPolicyDE', `<a href="${deUrl}" target="_blank" rel="noopener noreferrer" ${styles}>Datenschutzerklärung</a>`)
  return str
}

export const convertPrivacyPolicyToLink = () => {
  if (!document.body) { return }
  const element = document.querySelector('.ab-message-text')
  if (!element) { return }
  element.innerHTML = wrapPrivacyPolicyTextWithAnchorTag(element.innerHTML)
}

const shouldHandleIncomingMessages = (message: any) => {
  const state: ApplicationState = window.__store.getState()
  if (state.onboardingModal.visible) { return false }
  // $FlowFixMe
  const routeName = unPrefixRouteName(state.routing.route.name)
  const pageType = getPageType(routeName)
  // if in out, or other caf pages, return
  if (['out', 'job-apply-form-new'].includes(pageType)) { return false }

  if (pageType === 'job-apply-success') {
    // if in success screen but in quick apply loop, return
    if (state.currentJob.job?.allow_quick_apply) { return false } // eslint-disable-line brace-style

    // if not in quick apply loop but message is not caf_ces, return
    else if (message.extras['msg-id'] !== 'caf-ces') { return false }
  }

  if (message instanceof InAppMessage) {
    // checks the key-value pair for a 'msg-id' key

    if (!isPushSupported() || isPushPermissionGranted() || isPushBlocked()) {
      // do not show the message because user/browser is not eligible
      return
    }
  }

  return true
}

export const handleIncomingMessages = (message: any) => {
  if (!shouldHandleIncomingMessages(message)) { return }
  if (message instanceof InAppMessage) {
    // checks the key-value pair for a 'msg-id' key
    const keyValuePairs = message.extras || {}

    if (keyValuePairs['msg-id'] === 'push-primer') {
      // the browser is eligible to request push permission
      // register a callback when the right-button is clicked
      if (message.buttons[1] != null) {
        // Prompt the user when the second button is clicked
        message.buttons[1].subscribeToClickedEvent(function () {
          logCustomAttribute(WEB_PUSH_CUSTOM_ATTRIBUTE, 'true')
          requestPushPermission(function () {
            // success!
          }, function () {
            // user declined
          })
        })

        message.buttons[0].subscribeToClickedEvent(function () {
          logCustomAttribute(WEB_PUSH_CUSTOM_ATTRIBUTE, 'false')
        })
      }
    }

    if (keyValuePairs['msg-id'] === 'push-primer-unregistered') {
      if (message.buttons.length > 0) {
        message.buttons[1]?.subscribeToClickedEvent(function () {
          logCustomAttribute(WEB_PUSH_CUSTOM_ATTRIBUTE, 'true')
          requestPushPermission(async () => {
            try {
              const state: ApplicationState = window.__store.getState()

              // $FlowFixMe
              const routeName = unPrefixRouteName(state.routing.route.name)

              const existingId = localStorage.getItem(localStorage.identifiers.UNREGISTERED_JOBSEEKER_ID)

              if (existingId || (routeName !== routeNames.jobDetails && routeName !== routeNames.jobSearchKeyword)) {
                return
              }

              const sources = {
                [routeNames.jobDetails]: 'job',
                [routeNames.jobSearchKeyword]: 'search',
              }

              const params: UnregisteredJobseeker = {
                locale: state.intlData.locale,
                country_code: state.locality.country,
                // $FlowFixMe
                source: sources[routeName],
              }

              if (routeName === routeNames.jobDetails) {
                params.job_uid = state.currentJob.job?.id
              } else if (routeName === routeNames.jobSearchKeyword) {
                params.query = state.jobSearch.searchOptions.keyword
                params.location = state.jobSearch.searchOptions.location
                params.distance_in_miles = state.jobSearch.searchOptions.distance ? Number(state.jobSearch.searchOptions.distance) : undefined
              }

              const { value: response } = await window.__store.dispatch(createUnregisteredJobseeker(params))

              updateBrazeUser(response.uid)

              localStorage.setItem(localStorage.identifiers.UNREGISTERED_JOBSEEKER_ID, response.uid)
            } catch (err) {
              log.error(err)
            }
          }, function () {
          })
        })

        message.buttons[0].subscribeToClickedEvent(function () {
          logCustomAttribute(WEB_PUSH_CUSTOM_ATTRIBUTE, 'false')
        })
      }
    }
  }
  // InAppMessage will be displayed, ControlMessage will be logged to Braze servers
  showInAppMessage(message)
  convertPrivacyPolicyToLink()
}

export const subscribeToInAppMessages = async () => {
  if (!isBrazeInitialized && !isDisabled()) {
    await configureBraze()
  }
  // handleIncomingMessages is a callback function that is called
  // whenever a new in-app message is triggered
  subscribeToInAppMessage(handleIncomingMessages)
}

const openSession = async () => {
  if (!isBrazeInitialized && !isDisabled()) {
    await configureBraze()
  }
  openBrazeSession()
}

const logPrimeForPushEvent = async (registeredUser: boolean) => {
  if (!isBrazeInitialized && !isDisabled()) {
    await configureBraze()
  }

  const eventType = registeredUser ? 'prime-for-push' : 'prime-for-push-unregistered-jobseeker'
  logCustomEvent(eventType)
  window.__heyjobs_braze?.debugEvents.push({
    eventName: eventType,
    eventData: {},
  })
}

let pushAlertQueued = false

export const queuePushAlert = (registeredUser: boolean) => {
  if (!pushAlertQueued) {
    setTimeout(async () => {
      await openSession()
      await logPrimeForPushEvent(registeredUser)
    }, WEB_PUSH_DELAY)
    pushAlertQueued = true
    return true
  }
}

export const logCustomAttribute = (key: string, value: string) => {
  executeBrazeCall(() => {
    getUser()?.addToCustomAttributeArray(key, value)
  })
}

export const logStringCustomAttribute = (key: string, value: string|boolean) => {
  executeBrazeCall(() => {
    getUser()?.setCustomUserAttribute(key, value)
  })
}

export const updateBrazeUser = async (userId: string) => {
  if (!isBrazeInitialized && !isDisabled()) {
    await configureBraze()
  }

  changeUser(userId)

  // We should open session after changing the Braze user ID to ensure the session is properly initialized for the new user
  // More info: https://js.appboycdn.com/web-sdk/latest/doc/modules/braze.html#opensession
  openSession()
}

export const cleanUserInstance = () => {
  if (isBrazeInitialized) {
    destroy()
  }
}

export const trackBrazeCustomEvent = (eventType: string, customAttributes: Object = {}) => {
  const state = window.__store.getState()
  const attributes = getBrazeEventAttributesFromState(state)

  if (isDisabled()) {
    return
  }

  const marketingSource = marketingParamLoad(cookieJar)()
  if (marketingSource) {
    attributes.application_mkt_source = marketingSource.source
  }
  const mergedAttributes = { ...customAttributes, ...attributes }
  log.info('trackBrazeCustomEvent', `eventType: ${eventType}`, mergedAttributes)

  window.__heyjobs_braze?.debugEvents.push({
    eventName: eventType,
    eventData: mergedAttributes,
  })

  logCustomEvent(eventType, mergedAttributes)
  // NOTE: to force immediate flushing
  requestImmediateDataFlush()
}

export const trackBrazePageViewEvent = (routeName: string, state: ApplicationState) => {
  const eventData = TRACKABLE_PAGE_TYPES.find((page) => page.routeName === routeName)
  if (!eventData) return

  trackBrazeCustomEvent(eventData.eventName)
}

const overSevenDaysAfterFirstVisit = (createdAt: string) => {
  const sevenDays = 604800000
  return Date.now() - parseInt(createdAt) >= sevenDays
}

export const trackBrazeCESEvent = (
  conditions: additionalBrazeEventConditions,
  dispatch: Dispatch,
  routeName: RouteName,
  state: ApplicationState
) => {
  const currentUser = state.user
  const {
    pageType,
    numberOfThisPageView,
    requiredNumberOfThisPageView,
    incrementNumberOfThisPageView,
    eventName,
  } = conditions

  if (getPageType(routeName) === pageType && currentUser.isAuthenticated &&
    currentUser[numberOfThisPageView] < requiredNumberOfThisPageView) {
    if (currentUser[numberOfThisPageView] === requiredNumberOfThisPageView - 1) {
      trackBrazeCustomEvent(eventName)
      openSession()
    }
    dispatch(incrementNumberOfThisPageView())
  }
}

export const trackOnPageNPSEvent = (state: ApplicationState, routeName: RouteName, dispatch: Dispatch) => {
  const currentUser = state.user
  // Trigger onpage_nps event at any page view except during CAF
  if (getPageType(routeName) !== 'job-apply-form-new' && currentUser.user &&
    currentUser.isAuthenticated && !currentUser.onPageNPSEventTriggered &&
    overSevenDaysAfterFirstVisit(currentUser.user.created_at)) {
    trackBrazeCustomEvent('onpage_nps')
    openSession()
    dispatch(markOnPageNPSEventAsTriggered())
  }
}

export const trackCESEventForCAF = (dispatch: Dispatch, routeName: RouteName, state: ApplicationState) => {
  const currentUser = state.user
  if (getPageType(routeName) !== 'web_job_detail' && currentUser.cafCESEventTriggered === false &&
    !currentUser.shouldShowApplicationSubmittedFollowUp && currentUser.isAuthenticated) {
    trackBrazeCustomEvent('caf_ces')
    openSession()
    dispatch(markCESEventForCAFAsTriggered())
  }
}

export const trackAdditionalBrazeEvents = (dispatch: Dispatch, routeName: RouteName, state: ApplicationState) => {
  trackOnPageNPSEvent(state, routeName, dispatch)
  // Trigger CES events
  LIST_OF_CES_EVENT_CONDITIONS.forEach((conditions) => {
    trackBrazeCESEvent(conditions, dispatch, routeName, state)
  })

  trackCESEventForCAF(dispatch, routeName, state)
}
