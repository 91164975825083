// @flow
import React from 'react'
import Loadable from 'react-loadable'
import compose from 'lodash/flowRight'
import isEmpty from 'lodash/isEmpty'
import { PageLoader } from '../../components/shared/loader'
import {
  withClientDataLoader,
} from '../../components/containers/withDataLoader'
import { getQuizResult, getQuizFeedback } from '../../actions/jobTypeQuiz'
import { getRoutesForLocale } from '../../routes'

import type { RouteData } from '../../types/common'
import type { Dispatch } from '../../store/configureStore'
import type { ApplicationState } from '../../types/applicationState'

export const clientDataLoader = async (
  dispatch: Dispatch,
  state: ApplicationState,
  routeData: RouteData,
  replace: Function
) => {
  if (isEmpty(routeData.location.query)) {
    const { locale } = state.intlData
    const { country } = state.locality
    const routes = getRoutesForLocale(locale)
    const countryRoutes = routes.country(country)

    return replace(countryRoutes.index)
  }

  dispatch(getQuizResult(routeData.location.query))
  dispatch(getQuizFeedback(routeData.location.query))
}

const LoadableJobTypeQuiz = Loadable<$FlowTODO, $FlowTODO>({
  loader: () => import('./JobTypeQuiz'),
  loading: () => <PageLoader />,
})

const JobTypeQuiz = compose(
  withClientDataLoader(clientDataLoader)
)(LoadableJobTypeQuiz)

export default JobTypeQuiz
