import { CaretDown24 } from '@carbon/icons-react'
import { InputLabel } from '@mui/material'
import Select, { SelectProps } from '@mui/material/Select'
import React from 'react'
import { HBox } from '../HBox'
import { HTypography } from '../HTypography'
import { HSelectFormControl, SelectWrapper } from './HSelect.styles'

export interface HSelectProps extends SelectProps {
  label?: string
  caption?: string
  options?: any
}

export type IHSelectProps = HSelectProps

export const HSelect: React.FC<IHSelectProps> = ({
  label,
  children,
  caption,
  IconComponent = CaretDown24,
  MenuProps = {},
  ...others
}) => {
  return (
    <SelectWrapper>
      <HSelectFormControl
        variant='outlined'
        fullWidth
        disabled={others.disabled}
        error={others.error}
      >
        {label && (
          <InputLabel id='demo'>
            <HTypography variant='body2' color='inherit'>
              {label}
            </HTypography>
          </InputLabel>
        )}
        <Select
          {...others}
          labelId='demo'
          label={label}
          IconComponent={IconComponent}
          MenuProps={{
            disablePortal: true,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            ...MenuProps,
          }}
          color='primary'
          fullWidth
        >
          {children}
        </Select>
      </HSelectFormControl>
      {caption && (
        <HBox pl={3} pt={1}>
          <HTypography
            variant='caption'
            color={others.error ? 'support_error_50' : 'surface_gray_60'}
            className={others.disabled ? 'Mui-disabled' : ''}
          >
            {caption}
          </HTypography>
        </HBox>
      )}
    </SelectWrapper>
  )
}
