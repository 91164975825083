import React from 'react'
import { Close24 } from '@carbon/icons-react'
import { BottomNavigation } from '../common/bottom-navigation'
import {
  HBox,
  HIcon,
  HLogo,
  HRoundIconButton,
  HTypography
} from 'talent-ui'
import { useOnboarding, useOnboardingFlow } from '../common/use-onboarding-flow'
import { FormattedMessage } from 'react-intl'
import { StepAction, WorkingHours } from '../types/api-types'
import { OnboardingStep } from '../types/flow'
import { FullTimePartTime, WorkingHoursOptions } from 'user-profile-data'
import { ONBOARDING_FLOW_STARTED_EVENT } from '../../tracking/events'

const ModalHeader = () => {
  const { onStepClose } = useOnboardingFlow()
  return (
    <HBox
      mt={6}
      mb={4}
      justifyContent='end'
      xs={{ display: 'none' }}
      lg={{ display: 'flex' }}
    >
      <HRoundIconButton
        color='neutral'
        onClick={() => onStepClose('working_hours_type')}
      >
        <HIcon
          Icon={Close24}
          size={24}
        />
      </HRoundIconButton>
    </HBox>
  )
}

export type JobWorkingHoursStepProps = {
  value?: WorkingHours;
  status?: StepAction;
}

export const workingHoursToTrackingFormat = (workingHours: WorkingHours) => {
  if (workingHours === FullTimePartTime) return 'no_preferences'

  return workingHours
}

const OnboardingJobWorkingHours: OnboardingStep<JobWorkingHoursStepProps> = ({ status }) => {
  const { fireSnowplowEvent, intl } = useOnboarding()
  const { saveStepData, goNext, setStepState, baseProps } = useOnboardingFlow()

  const handleJobTypeClick = (workingHours: WorkingHours) => {
    if (!status) {
      fireSnowplowEvent(ONBOARDING_FLOW_STARTED_EVENT, {
        sign_up_source: baseProps.userSignupSource || 'unknown',
        working_hours: workingHoursToTrackingFormat(workingHours)
      })
    }

    setStepState('working_hours_type', {
      value: workingHours
    })

    saveStepData({
      step_name: 'working_hours_type',
      step_action: 'filled',
      step_value: workingHours
    })
    goNext()
  }

  const onSkipped = () => {
    saveStepData({
      step_name: 'working_hours_type',
      step_action: 'skipped'
    })

    goNext()
  }

  return (
    <>
      <HBox
        xs={{ mx: 6 }}
        lg={{ mx: 8 }}
      >
        <ModalHeader />
        <HBox
          mb={4}
          mt={4}
        >
          <HLogo
            variant='pink_yellow_roundel'
            width={40}
          />
        </HBox>
        <HBox>
          <HBox mb={4}>
            <HTypography
              variant='h4'
              color='surface_gray_90'
              weight='regular'
            >
              <FormattedMessage id='onboarding_flow_job_type_find_job_that_fits' />
            </HTypography>
          </HBox>
          <HBox mb={8}>
            <HTypography
              variant='h4'
              color='surface_gray_90'
              weight='regular'
            >
              <FormattedMessage id='onboarding_flow_job_type_how_would_you_like_to_work' />
            </HTypography>
          </HBox>
          <HBox>
            <WorkingHoursOptions
              optionContainerProps={{ mb: 2 }}
              handleClick={handleJobTypeClick}
              variant='onboarding'
            />
          </HBox>
        </HBox>
      </HBox>
      <BottomNavigation
        secondaryAction={{
          text: intl.formatMessage({ id: 'skip_for_now' }),
          onClick: onSkipped
        }}
      />
    </>
  )
}

export default OnboardingJobWorkingHours
