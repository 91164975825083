// @flow

import * as React from 'react'
import { useLocation } from 'react-router-dom'

type ScrollToTopProps = {|
  scrollTop?: bool
|}

const ScrollToTop = (props: ScrollToTopProps) => {
  const { scrollTop } = props
  const { pathname } = useLocation()

  React.useEffect(() => {
    if (scrollTop) {
      window.scrollTo(0, 0)
    }
  }, [pathname])

  return null
}

export default ScrollToTop
