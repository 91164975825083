import React from 'react'
import { MobileMenuProps } from '../HNavBar.types'
import { LoggedInMobileMenu } from './LoggedInMobileMenu'
import { LoggedOutMobileMenu } from './LoggedOutMobileMenu'

export const MobileMenuItems: React.FC<MobileMenuProps> = (props) => {
  const { loggedIn } = props

  return (
    loggedIn
      ? (
        <LoggedInMobileMenu
          menuItems={props.loggedInMenuItems}
          userProfile={props.userProfile}
        />)
      : (<LoggedOutMobileMenu menuItems={props.loggedOutMenuItems}/>)
  )
}
