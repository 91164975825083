import { workingHoursToTrackingFormat } from '../components/onboarding-job-working-hours'
import snakeCase from 'lodash/snakeCase'
import { ContainerState } from '../types/flow'
import { WorkingHours } from '../types/api-types'
import { ONBOARDING_FLOW_SKIP_EVENT, ONBOARDING_FLOW_STEP_COMPLETED_EVENT } from '../../tracking/events'
import { TrackingFunction } from '../../tracking/types'

export const trackCompletedStep = ({
  state,
  totalStepCount,
  isReEntry,
  workingHours,
  fireSnowplowEvent
}: { state: ContainerState; totalStepCount: number; isReEntry: boolean; workingHours?: WorkingHours; fireSnowplowEvent: TrackingFunction }) => {
  workingHours ||= state.stepState?.working_hours_type?.value
  fireSnowplowEvent(ONBOARDING_FLOW_STEP_COMPLETED_EVENT, {
    entry: isReEntry ? 're_entry' : 'first',
    total_steps_count: totalStepCount,
    current_step: state.history.length,
    flow_step_name: snakeCase(state.step),
    working_hours: workingHours ? workingHoursToTrackingFormat(workingHours) : undefined
  })
}

export const trackSkippedStep = (state: Pick<ContainerState, 'history' | 'step'>, totalStepCount: number, fireSnowplowEvent: TrackingFunction) => {
  fireSnowplowEvent(ONBOARDING_FLOW_SKIP_EVENT, {
    total_steps_count: totalStepCount,
    current_step: state.history.length,
    flow_step_name: snakeCase(state.step)
  })
}
