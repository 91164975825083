// @flow

import config from '../../config'

type Encoding = 'jpeg' | 'png'

type ImageWrapOptions = {
  convert?: Encoding,
  fit?: bool,
  height?: number,
  size?: number,
  width?: number
}

const serialize = (options: ImageWrapOptions | string) => {
  if (typeof options === 'string') {
    return options
  }
  const { width, height, size, fit, convert } = options
  const square = [size, fit ? 'fit' : ''].filter((x) => x).join(',')
  const dimensions = width || height ? `${width || ''}x${height || ''}` : ''
  return [square || dimensions, convert].filter((x) => x).join(',')
}

/**
 * Wrap image src urls for on-demand processing with img.hey.jobs
 *
 * @param {string} url - url to wrap for processing
 * @param {ImageWrapOptions|string} options - processing options to apply https://github.com/willnorris/imageproxy/blob/82ba3b64454920e6e46aa9cce2b1609838877b6a/README.md#examples
 */
const imageWrap = (url: string, options: ImageWrapOptions | string) =>
  url && `${config.imageProxy.url}/${serialize(options)}/${url}`

imageWrap.x2 = (url: string, options: ImageWrapOptions) =>
  imageWrap(url, {
    ...options,
    width: options.width && options.width * 2,
    height: options.height && options.height * 2,
    size: options.size && options.size * 2,
  })

imageWrap.x3 = (url: string, options: ImageWrapOptions) =>
  imageWrap(url, {
    ...options,
    width: options.width && options.width * 3,
    height: options.height && options.height * 3,
    size: options.size && options.size * 3,
  })

imageWrap.srcset = (url: string, options: ImageWrapOptions) => `${imageWrap.x2(url, options)} 2x, ${imageWrap.x3(url, options)} 3x`

imageWrap.imageset = (url: string, options: ImageWrapOptions) => `url(${imageWrap(url, options)}) 1x, url(${imageWrap.x2(
    url,
    options
  )}) 2x, url(${imageWrap.x3(url, options)}) 3x`

imageWrap.imageProps = (url: string, options: ImageWrapOptions) => {
  return {
    src: imageWrap(url, options),
    srcset: imageWrap.srcset(url, options),
  }
}

export default imageWrap
