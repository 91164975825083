import clsx from 'clsx'
import React from 'react'
import { HTypography } from '../HTypography'
import { Caption, CheckboxContainer } from './HCheckbox.styles'

export interface HCheckboxProps extends React.HTMLProps<HTMLInputElement> {
  id: string
  error?: boolean
  disabled?: boolean
  label?: string
  caption?: string
  ref?: any
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const HCheckbox = React.forwardRef((props: HCheckboxProps, ref: any) => {
  const {
    id,
    error,
    label,
    caption,
    value,
    ...others
  } = props
  const boxContainerClasses = clsx('box-container', error && 'error')
  return (
    <CheckboxContainer>
      <input
        type="checkbox"
        id={id}
        {...others}
      />
      <div className={boxContainerClasses}>
        <span className='pressed'>
          <div className='box'/>
        </span>
        {(label || caption) && (
          <div className='text-container'>
            {
              label && <HTypography className="label" color='surface_gray_90' variant='body2'>{label}</HTypography>
            }
            {
              caption &&
                  <Caption
                    id={`${id}-input-helper-text`}
                    className="caption"
                    color={error ? 'support_error_50' : 'surface_gray_60'}
                    variant='caption'
                  >
                    {caption}
                  </Caption>
            }
          </div>
        )}
      </div>
    </CheckboxContainer>
  )
})

HCheckbox.displayName = 'HCheckbox'
