import React from 'react'
import styled from '@emotion/styled'
import { Facebook } from '../../HAssets/Facebook'
import { Instagram } from '../../HAssets/Instagram'
import { Kununu } from '../../HAssets/Kununu'
import { Twitter } from '../../HAssets/Twitter'
import { HBox } from '../../HBox'
import { HLogo } from '../../HLogo'
import { HTypography } from '../../HTypography'
import { FooterLeftSection } from './HMainFooter.types'
import { Wikis16 } from '@carbon/icons-react'
import { HIcon } from '../../HIcon'
import { HSelect } from '../../HSelect'
import { HMenuItem } from '../../HMenu'

const PrivacySettingsLink = styled(HTypography)`
  width: fit-content;
`
const SocialMediaLink = styled.a`
  margin-right: 16px;
`

export const LeftSection: React.FC<FooterLeftSection> = ({
  privacySettings: {
    label: privacyLabel,
    onClick: privacyOnClick,
    isClickable: privacySettingsClickable = true,
  },
  countrySelector: {
    label: countryLabel,
    value: countryValue,
    onChange: countryOnChange,
    availableCountries,
    isVisible: countrySelectVisible = true,
  },
  languageSwitcher: {
    component: LangLink = 'a',
    componentProps,
    label: langLabel,
    isVisible: langVisible = true,
  },
}) => {
  return (
    <>
      <HLogo variant='gray' />
      <PrivacySettingsLink
        variant='body2'
        weight='regular'
        color='surface_gray_80'
        cursor='pointer'
        role='button'
        selectors={[{
          selector: ':hover, :focus',
          props: {
            underline: true,
          },
        }]}
        space={{
          mt: 4,
          mb: 6,
        }}
        onClick={privacySettingsClickable
          ? privacyOnClick
          : () => { /* Only call if it's clickable */ }
        }
      >
        {privacyLabel}
      </PrivacySettingsLink>
      <HBox display='flex' mb={6}>
        <SocialMediaLink
          aria-label='Facebook'
          href='https://www.facebook.com/heyjobsofficial'
          rel='noopener noreferrer'
          target='_blank'
        >
          <Facebook />
        </SocialMediaLink>
        <SocialMediaLink
          aria-label='Twitter'
          href='https://twitter.com/heyjobsofficial'
          rel='noopener noreferrer'
          target='_blank'
        >
          <Twitter />
        </SocialMediaLink>
        <SocialMediaLink
          aria-label='Instagram'
          href='https://www.instagram.com/heyjobsofficial/'
          rel='noopener noreferrer'
          target='_blank'
        >
          <Instagram />
        </SocialMediaLink>
        <SocialMediaLink
          aria-label='Kununu'
          href='https://www.kununu.com/de/heyjobs1'
          rel='noopener noreferrer'
          target='_blank'
        >
          <Kununu />
        </SocialMediaLink>
      </HBox>
      {langVisible && (
        <HBox mb={6}>
          <LangLink id='language-switch' {...componentProps}>
            <HBox display='inline-flex' alignItems='center' component='span' gap={8}>
              <HIcon Icon={Wikis16} color='surface_gray_80' />
              <HTypography
                variant='button'
                color='surface_gray_80'
                weight='medium'
              >
                {langLabel}
              </HTypography>
            </HBox>
          </LangLink>
        </HBox>
      )}
      {countrySelectVisible && (
        <HBox width={160} mb={10}>
          <HSelect
            aria-label={countryLabel}
            label={countryLabel}
            value={countryValue}
            onChange={countryOnChange}
          >
            {availableCountries.map(
              ({ countryCode, countryName }) => (
                <HMenuItem
                  key={countryCode}
                  value={countryCode}
                  data-test-id={`country-selector-${countryCode}`}
                >
                  {countryName}
                </HMenuItem>
              ),
            )}
          </HSelect>
        </HBox>
      )}
      <HTypography variant='body2' color='surface_gray_80'>
        © {new Date().getFullYear()} HeyJobs GmbH
      </HTypography>
    </>
  )
}
