// @flow

import { snowplowGetPageViewId } from '../clients/snowplow'

export default () => (
  new Promise<string>((resolve, reject) => {
    const pageViewId = snowplowGetPageViewId()
    if (pageViewId) {
      return resolve(pageViewId)
    }
    reject() // eslint-disable-line prefer-promise-reject-errors
  })
)
