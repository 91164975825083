export const breakpoints = {
  values: {
    xs: 0,
    sm: 376,
    md: 481,
    lg: 1032,
    xl: 1440,
  },
}
export const newBreakpoints = {
  values: {
    xs: 0,
    sm: 376,
    md: 640,
    lg: 864,
    xl: 1440,
  },
}
