import React from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'src/app/types/applicationState'
import { HBox, HTypography } from 'talent-ui'
// @ts-ignore
import { ReactComponent as DeleteProfileIcon } from 'assets/images/profile/delete-profile.svg'
import externalPageMap from 'src/app/helpers/externalPageMap'

const DeleteProfile:React.FC<{}> = () => {
  const { locale } = useSelector((state:ApplicationState) => {
    return {
      locale: state.intlData.locale,
    }
  })

  return (
    <HBox
      display='flex'
      justifyContent='center'
      flexDirection='column'
      alignItems='center'
      data-test-id='delete-profile-page-container'
      md={{ pt: 10 }}
    >
      <DeleteProfileIcon />
      {generateLocalizedContent(locale)}
    </HBox>
  )
}

const generateLocalizedContent = (locale:string) => {
  switch (locale) {
    case 'de':
      return (
        <HBox
          width='327px'
          display='flex'
          justifyContent='center'
          flexDirection='column'
        >
          <HBox
            mt={6}
            mb={4}
          >
            <HTypography
              variant='h6'
              align='center'
              color='surface_gray_90'
              weight='semibold'
            >
              Schade, dass Du gehst...
            </HTypography>
          </HBox>

          <HTypography
            variant='body2'
            color='surface_gray_60'
            align='center'
          >
            Um Dein Profil zu löschen, schreibe bitte eine Anfrage an
            <HTypography
              variant='body2'
              color='surface_gray_60'
              weight='semibold'
              component='span'
            > datenschutz@heyjobs.de
            </HTypography>. Mehr Informationen, wie wir mit Deinen Daten umgehen, findest Du in unserer {dataPolicyLink(locale)}.
          </HTypography>
        </HBox>
      )
    case 'en':
    default:
      return (
        <HBox
          width='327px'
          display='flex'
          justifyContent='center'
          flexDirection='column'
        >
          <HBox
            mt={6}
            mb={4}
          >
            <HTypography
              variant='h6'
              align='center'
              color='surface_gray_90'
              weight='semibold'
            >
              Sad to see you go...
            </HTypography>
          </HBox>

          <HTypography
            variant='body2'
            color='surface_gray_60'
            align='center'
          >
            To delete your profile, please write a request to
            <HTypography
              variant='body2'
              color='surface_gray_60'
              weight='semibold'
              component='span'
            > datenschutz@heyjobs.de
            </HTypography>. For more information on
            how your data is handled, please read our {dataPolicyLink(locale)}.
          </HTypography>
        </HBox>
      )
  }
}

const dataPolicyLink = (locale:string) => {
  const linkTo = externalPageMap(locale)
  return (
    <HTypography
      variant='body2'
      align='center'
      color='primary_50'
      component='a'
      target='_blank'
      href={linkTo.privacy}
      cursor='pointer'
    >
      {locale === 'en' ? 'Data Policy' : 'Datenschutzerklärung'}
    </HTypography>
  )
}

export default DeleteProfile
