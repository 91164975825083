import React from 'react'
import { useNormalizedMapsAPI } from '../utils/use-normalized-maps-api'
import { LocationSelectorInput, LocationSelectorProps } from './location-selector-input'

export const LocationSelector: React.FC<LocationSelectorProps> = ({
  locale,
  overrides,
  hookConfig,
  ...others
}) => {
  const { fetchThrottled, getPlaceDetail } = useNormalizedMapsAPI(locale, hookConfig, overrides)

  return (
    <LocationSelectorInput
      {...others}
      getPlaceDetail={getPlaceDetail}
      fetchThrottled={fetchThrottled}
    />
  )
}
