// @flow

import * as actions from '../constants/actions'

type ActionType = typeof actions.SHOW_SUCCESS_MODAL | typeof actions.HIDE_SUCCESS_MODAL

export type SuccessModal = {
  visible: bool
}

type ModalAction = {
  type: ActionType,
  visible: bool
}

const initialState: SuccessModal = {
  visible: false,
}

const reducer = (state: SuccessModal = initialState, action: ModalAction) => {
  switch (action.type) {
    case actions.SHOW_SUCCESS_MODAL:
      return {
        ...initialState,
        visible: true,
      }
    case actions.HIDE_SUCCESS_MODAL:
      return {
        ...state,
        visible: false,
      }
    default:
      return state
  }
}

export default reducer
