// @flow

import pickBy from 'lodash/pickBy'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import flow from 'lodash/flow'
import isUndefined from 'lodash/isUndefined'
import memoize from '../memoize'
import { getRoutesForLocale } from '../../routes'
import * as query from './query'
import searchSplatParser from './searchSplatParser'
import searchParamEncoder from './searchParamEncoder'
import quoteWrap from './encoding/quoteWrap'
import escapeQuotes from './encoding/escapeQuotes'
import translateSpaceEncoding from './decoding/translateSpaceEncoding'
import translateDoubleQuotesEncoding from './decoding/translateDoubleQuotesEncoding'

import {
  AVAILABLE_RADIUS_OPTIONS,
  DEFAULT_RADIUS_OPTION,
} from '../../constants/base'

import type { Locale, QueryParams } from '../../types/common'
import type {
  JobSearchCustomRankingOptions,
} from '../../actions/api/clients/search'

type OrderByOptions = 'date'

export type SearchUrlOptions = {
  companies?: string[],
  custom_ranking_info?: JobSearchCustomRankingOptions,
  distance?: string,
  easyApply?: bool,
  employmentTypes?: string[],
  job?: string,
  keyword?: string,
  location?: string,
  marketing?: Object,
  orderBy?: ?OrderByOptions,
  page?: number,
  redirect?: {
    city?: string,
    highlight?: string,
    page?: number,
    unpublished?: string,
    unpublishedJob?: string
  },
  salary?: bool,
  spellcheck?: bool,
  workingHours?: string[]
}

type SearchUrlFragmentSeparators = {
  companies: string,
  employmentTypes: string,
  keyword: string,
  location: string,
  workingHours: string
}

type EmploymentType = {
  localized: Object
}

// Hardcoded Values
const localizedSearchUrlSeparators = {
  de: {
    location: 'in',
    keyword: 'als',
    companies: 'bei',
    employmentTypes: 'fuer',
    workingHours: 'mit',
  },
  en: {
    location: 'in',
    keyword: 'as',
    companies: 'at',
    employmentTypes: 'for',
    workingHours: 'with',
  },
}

const defaultPage = 1

const getLocalizedSearchUrlSeparators = (
  locale: Locale
): SearchUrlFragmentSeparators => {
  let separators = localizedSearchUrlSeparators[locale]
  if (!separators) {
    const fallbackLocale = locale.split('_')[0]
    if (fallbackLocale !== locale) {
      separators = localizedSearchUrlSeparators[fallbackLocale]
    }
  }
  if (!separators) {
    throw Error(`No separators defined for locale '${locale}'`)
  }
  return separators
}

const extraEncoding = flow(escapeQuotes, quoteWrap, searchParamEncoder)
// NOTE: multiple decodeURIComponent to handle history@5 bug which encodes url components by default
// but doesn't encode non-latin characters
const safeDecoding = (text: string) => {
  try {
    return decodeURIComponent(decodeURIComponent(text))
  } catch (_) {
    return decodeURIComponent(text)
  }
}

const validateDistance = (distance, options = AVAILABLE_RADIUS_OPTIONS) => {
  return options.indexOf(distance) > -1
    ? distance
    : DEFAULT_RADIUS_OPTION
}

const spaceAndDoubleQuotesEncoding = flow(translateSpaceEncoding, translateDoubleQuotesEncoding)

const _parse = (
  splat: ?string,
  queryParams: QueryParams = {},
  locale: Locale,
  country: string,
  overrideRadiusNoneOption?: string
): SearchUrlOptions => {
  // Adding this try catch block as searchSplatParser.parse would throw errors if path contains any special characters
  let parts
  try {
    parts = splat
      ? searchSplatParser.parse(spaceAndDoubleQuotesEncoding(splat))
      : {}
  } catch (error) {
    parts = {}
  }

  const page = (queryParams.page && parseInt(queryParams.page)) || defaultPage
  // NOTE: Don't parse distance if there is no location
  const radiusOptions = AVAILABLE_RADIUS_OPTIONS.filter(item => item !== 'none')
  const distance =
    parts.location &&
    validateDistance(queryParams.distance, [overrideRadiusNoneOption || 'none', ...radiusOptions])

  const easyApply = queryParams.easyApply
  const salary = queryParams.salary
  const orderBy = queryParams.orderBy
  /* eslint-disable camelcase */
  const { debug_importance_level, debug_ranking_expression } = queryParams

  const customRankingInfo =
    debug_importance_level || debug_ranking_expression
      ? {
          importance_level: query.first(debug_importance_level),
          ranking_expression: query.first(debug_ranking_expression),
        }
      : undefined

  if (customRankingInfo?.ranking_expression) {
    if (customRankingInfo.ranking_expression.includes('"')) {
      // some browsers add " character while decoding the url.
      customRankingInfo.ranking_expression = customRankingInfo.ranking_expression?.replace(/"/g, '')
    }
    // a temporary solution for this : https://hey-jobs.slack.com/archives/CCHBDSQ90/p1655721417337769
    const invalidRanking = ')   ranking_boost_factor'
    if (customRankingInfo.ranking_expression?.includes(invalidRanking)) {
      customRankingInfo.ranking_expression = customRankingInfo.ranking_expression?.replace(invalidRanking, ') + ranking_boost_factor')
    }
  }
  /* eslint-enable camelcase */

  const redirect = {}
  if (queryParams.fromCity) redirect.city = queryParams.fromCity
  if (queryParams.fromPage) redirect.page = queryParams.fromPage
  if (queryParams.fromUnpublished) {
    redirect.unpublished = queryParams.fromUnpublished
  }

  const spellcheck = queryParams.spellcheck

  return pickBy(
    {
      page,
      distance,
      location: parts.location && safeDecoding(parts.location),
      keyword: parts.keyword && safeDecoding(parts.keyword),
      companies: map(parts.companies, safeDecoding),
      employmentTypes: map(parts.employmentTypes, safeDecoding),
      workingHours: map(parts.workingHours, safeDecoding),
      country,
      redirect,
      orderBy,
      job: queryParams.job,
      easyApply,
      salary,
      spellcheck,
      custom_ranking_info: customRankingInfo,
    },
    (x) => !isUndefined(x)
  )
}

// Public

/**
 * Parses the given search splat + query params (locale aware) with memoisation
 * @param {string} splat - the splat portion of the url. ('in-berlin', etc.)
 * @param {Object} queryParams - additional parameters to pass through the query ({ page, distance, sort, fromCity, fromJobTypes, fromPage })
 * @param {string} queryParams.page - page number
 * @param {string} queryParams.distance - distance
 * @param {string} queryParams.fromCity - city the user was redirected from
 * @param {string} queryParams.fromJobTypes - job types the user was redirected from
 * @param {string} queryParams.fromPage - page the user was redirected from
 * @param {string} locale - to parse the splat with. (en/de)
 * @param {string} country - target country for the url (us/de/uk...)
 * @returns {Object} parsed search keys
 */
export const parse = memoize(_parse)

/**
 * Builds path + query param portion of the job search page url
 * @param {Object} options - params to build the target url with
 * @param {string} options.keyword - search keyword
 * @param {string} options.location - search location
 * @param {string[]} options.companies - filter company names
 * @param {string[]} options.employmentTypes - filter employment types
 * @param {string[]} options.workingHours - filter working hours
 * @param {number} options.distance - filter distance
 * @param {number} options.page - target page number
 * @param {Object} options.redirect - included redirect parameters (to show where the user was redirected to the current page from)
 * @param {string} options.redirect.city - city that the user was redirected from
 * @param {string} options.redirect.jobTypes - job types that the user was redirected from
 * @param {string} options.redirect.page - page that the user was redirected from
 * @param {Object} options.marketing - marketing parameters to map through to this url
 * @param {string} options.job - referenced jobId
 * @param {boolean} options.spellcheck - allow GCTS to autocorrect keyword mistakes
 * @param {string} locale - target locale for the url (en/de)
 * @param {string} country - target country for the url (us/de/uk...)
 * @returns {string} job search page url
 */
export const build = (
  {
    keyword,
    location,
    companies,
    employmentTypes,
    workingHours,
    page,
    distance,
    redirect = {},
    marketing = {},
    job,
    orderBy = null,
    spellcheck = true,
    easyApply = false,
    salary = false,
  }: SearchUrlOptions,
  locale: Locale,
  country: string,
  hasCompany: bool = true,
  dashboardEnabled?: boolean = false
): string => {
  const separators = getLocalizedSearchUrlSeparators(locale)
  const locationPath = buildLocationPath(location, separators)
  const keywordPath = buildKeywordPath(keyword, separators)
  const companiesPath = buildCompaniesPath(companies, separators)
  const employmentTypesPath = buildEmploymentTypesPath(
    employmentTypes,
    separators
  )
  const workingHoursPath = buildWorkingHoursPath(workingHours, separators)
  const options = { ...marketing }
  if (page && page > 1) options.page = page
  if (distance && validateDistance(distance) && distance !== DEFAULT_RADIUS_OPTION) options.distance = distance
  if (redirect) {
    if (redirect.city) options.fromCity = redirect.city
    if (redirect.page) options.fromPage = redirect.page
    if (redirect.highlight) options.h = redirect.highlight
    if (redirect.unpublishedJob) {
      options.fromUnpublished = redirect.unpublishedJob
    }
  }

  if (spellcheck === false || spellcheck === 'false') {
    options.spellcheck = spellcheck
  }

  if (job) {
    options.job = job
  }

  if (easyApply) {
    options.easyApply = easyApply
  }

  if (salary) {
    options.salary = salary
  }

  if (orderBy) {
    options.orderBy = orderBy
  }

  const queryFragment = query.buildUrlFragment(options)
  const path = [
    locationPath,
    keywordPath,
    companiesPath,
    employmentTypesPath,
    workingHoursPath,
  ]
    .filter((s) => s)
    .join('-')

  const routes = getRoutesForLocale(locale).country(country)
  return `${buildJobsPath(path, routes, hasCompany, dashboardEnabled)}${queryFragment}`
}

const buildJobsPath = (path: string, routes: Object, hasCompany: bool, dashboardEnabled: boolean) => {
  if (path !== '') {
    return routes.jobSearch({
      splat: path,
    })
  }

  return dashboardEnabled ? routes.dashboard : routes.index
}

const dashJoin = (items: string[]) => items.map(extraEncoding).join('-')

// Building
const buildLocationPath = (
  location: ?string,
  separators: SearchUrlFragmentSeparators
) => (location ? `${separators.location}-${extraEncoding(location)}` : '')

const buildKeywordPath = (
  keyword: ?string,
  separators: SearchUrlFragmentSeparators
) => (keyword ? `${separators.keyword}-${extraEncoding(keyword)}` : '')

const buildEmploymentTypesPath = (
  employmentTypes: ?(string[]),
  separators: SearchUrlFragmentSeparators
) =>
  !isEmpty(employmentTypes)
    ? `${separators.employmentTypes}-${dashJoin(employmentTypes)}`
    : ''

const buildCompaniesPath = (
  companies: ?(string[]),
  separators: SearchUrlFragmentSeparators
) =>
  !isEmpty(companies) ? `${separators.companies}-${dashJoin(companies)}` : ''

const buildWorkingHoursPath = (
  workingHours: ?(string[]),
  separators: SearchUrlFragmentSeparators
) =>
  !isEmpty(workingHours)
    ? `${separators.workingHours}-${dashJoin(workingHours)}`
    : ''

// Employment Types

export const localizeEmploymentTypes = (
  employmentTypes: EmploymentType[],
  locale: Locale
): EmploymentType[] => {
  return employmentTypes.map((et) => {
    return {
      ...et,
      localized: et.localized[locale],
    }
  })
}
