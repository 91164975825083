// @flow
import toPairs from 'lodash/toPairs'

type Params = {
  [key: string]: Array<any>,
}

export const arrayToQuery = (params: Params) => {
  return toPairs(params).map(([key, array]) => {
    return array
      .reduce((values, value) => { return values + `${key}[]=${value}&` }, '')
      .slice(0, -1)
  }).join('&')
}
