// @flow

import API from '../clientBuilder'
import type { APIRequestConfig } from '../types'

export default {
  get: (config: APIRequestConfig) => API.get.promise('/v2/documents', null, config),

  upload: (data: $FlowTODO, config: APIRequestConfig) => API.post.promise('/v2/documents', data, config),

  update: (id: string, data: $FlowTODO, config: APIRequestConfig) => API.put.promise(`/v2/documents/${id}`, data, config),

  delete: (id: string, config: APIRequestConfig) =>
    API.delete.promise(`/v2/documents/${id}`, null, config),

  generate: (config: $FlowTODO) => API.post.promise('/v2/documents/generate', {}, config),

  pollDocumentConversion: (id: string, config: APIRequestConfig) =>
    API.get.promise(`/v2/documents/${id}`, null, config),
}
