// @flow

import type { Cookies } from 'react-cookie'

import type { TrackingSchema } from './types'
import type { ApplicationState } from '../../types/applicationState'

type Options = {
  keyword_autosuggestion_id: ?string,
  location_autosuggestion_id: ?string,
  previous_page_name: string,
  used_keyword_autosuggest: boolean,
  used_location_autosuggest: boolean
}
export type AutoSuggestionType = {
  key: 'autosuggestion_context',
  options?: Options
}

export const SCHEMA_NAME = 'iglu:de.heyjobs/autosuggestion_context/jsonschema/2-0-0'

export default (state: ?ApplicationState, cookies: Cookies, options?: Options): Array<TrackingSchema> => {
  return [{
    schema: SCHEMA_NAME,
    data: options,
  }]
}
