import { Information16 } from '@carbon/icons-react'
import styled from '@emotion/styled'
import { Theme, useMediaQuery } from '@mui/material'
import React, { useCallback, useState } from 'react'
import { useTranslate } from '../../../../hooks/useTranslate'
import { COLORS, HBox, HChip, HDrawer, HTooltip, HTypography } from 'talent-ui'
import { $tooltipZIndex } from '../../../../components/shared/dependencies/variables'

export const InfoIcon = styled(Information16)`
  flex-shrink: 0;
  color: ${COLORS.surface_gray_60};
  cursor: pointer;
`

export const HiringBonusTag = () => {
  const formatMessage = useTranslate()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const [showTooltip, setShowTooltip] = useState(false)
  const toggleTooltip = useCallback(() => setShowTooltip(prevState => !prevState), [])

  return (
    <HBox
      display='flex'
      gap={8}
      maxWidth='700px'
      mb={6}
      width='100%'
    >
      <HTooltip
        placement='bottom'
        title={formatMessage('hiring_bonus_tag_tooltip')}
      >
        <HChip
          color='accent_pink'
          data-test-id='hiring-bonus-tag'
          onClick={toggleTooltip}
          placeholder={formatMessage('hiring_bonus_tag_title')}
          size='large'
          variant='filled'
        />
      </HTooltip>

      {isMobile
        ? (
          <>
            <HDrawer
              onClose={toggleTooltip}
              onOpen={toggleTooltip}
              open={showTooltip}
              style={{ zIndex: $tooltipZIndex }}
            >
              <HTypography>
                {formatMessage('hiring_bonus_tag_tooltip')}
              </HTypography>
            </HDrawer>
            <InfoIcon onClick={toggleTooltip} />
          </>
          )
        : (
          <HTooltip
            placement='bottom'
            title={formatMessage('hiring_bonus_tag_tooltip')}
          >
            <InfoIcon />
          </HTooltip>
          )}
    </HBox>
  )
}
