import React from 'react'
import clsx from 'clsx'
import { ArrowLeft16 } from '@carbon/icons-react'
import { classes } from './styles'
import { HBox } from '../HBox'
import { HRoundIconButton } from '../HButton'
import { HIcon } from '../HIcon'
import { HTypography } from '../HTypography'
import { CloseIcon } from '../HAssets/CloseIcon'

export interface HModalHeaderProps {
  closeIcon?: boolean
  handleClose?: React.MouseEventHandler<HTMLButtonElement> | undefined
  hasBackIcon?: boolean
  headerComponent?: JSX.Element
  headerVariant?: 'plain' | 'withTitle' | 'withExit' | 'none' | 'fullScreen'
  onBack?: React.MouseEventHandler<HTMLButtonElement> | undefined
  title?: string
}

export const HModalHeader: React.FC<HModalHeaderProps> = ({
  closeIcon = true,
  handleClose,
  hasBackIcon = false,
  headerComponent,
  headerVariant = 'plain',
  onBack,
  title,
}) => {
  const headerClasses = clsx(
    headerVariant !== 'plain' && classes.baseHeader,
    headerVariant === 'plain' && classes.plainHeader,
    headerVariant === 'withTitle' && classes.headerWithTitle,
    headerVariant === 'withExit' && classes.headerWithExit,
    headerVariant === 'fullScreen' && classes.fullScreenHeader,
    hasBackIcon && classes.headerWithBackIcon,
    hasBackIcon && headerVariant === 'plain' && classes.plainHeaderWithBack,
  )

  if (headerVariant === 'none') {
    return null
  }

  if (headerComponent) {
    return headerComponent
  }

  return (
    <HBox className={headerClasses}>
      {hasBackIcon &&
        <HRoundIconButton
          onClick={onBack}
          data-test-id="modal-back-icon-container"
          color='neutral'
        >
          <HIcon
            className={classes.icon}
            Icon={ArrowLeft16}
            size={24}
          ></HIcon>
        </HRoundIconButton>
      }
      {title && headerVariant === 'withTitle' &&
        <HTypography
          as='h2'
          weight='regular'
          variant='h5'
          color='surface_gray_80'
          md={{ variant: 'h6' }}
        >
          {title}
        </HTypography>
      }
      {closeIcon && headerVariant !== 'plain' &&
        <HRoundIconButton
          onClick={handleClose}
          data-test-id="modal-close-icon-container"
          color='neutral'
          className='modal-close-button'
        >
          <HIcon
            className={classes.icon}
            Icon={CloseIcon}
          ></HIcon>
        </HRoundIconButton>
      }
    </HBox>
  )
}
