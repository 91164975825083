/* istanbul ignore file */
import styled from '@emotion/styled'
import { HCarouselProps } from './HCarousel'
import { css } from '@emotion/react'

export const SliderWrapper = styled.div<Partial<HCarouselProps>>`
  .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    position: relative;
  }
  .slick-disabled,
  .slick-disabled:hover {
    color: rgba(60, 105, 190, 0.4);
  }
  .slick-prev {
    position: absolute;
    z-index: 1;
    left: ${({ theme }) => theme.spacing(-4)};
    top: calc(50% - 16px);
    // TODO: get rid of important
    display: flex !important;
  }
  .slick-next {
    position: absolute;
    z-index: 1;
    right: ${({ theme }) => theme.spacing(-4)};
    top: calc(50% - 16px);
    // TODO: get rid of important
    display: flex !important;
  }

  .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  .slick-list:focus {
    outline: none;
  }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }
  .slick-slider .slick-track,
  .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: flex;

    ${({ rows }) =>
    rows === 2 &&
      css`
        // to display the second row of slides
        align-items: center;
      `}
  }
  .slick-track .slick-slide {
    display: flex;
    height: auto;
    > div {
      height: 100%;
    }
  }
  .slick-track:before,
  .slick-track:after {
    display: table;
    content: "";
  }
  .slick-track:after {
    clear: both;
  }
  .slick-loading .slick-track {
    visibility: hidden;
  }
  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;

    ${({ rows, theme }) =>
    rows === 2 &&
      css`
        > :last-child {
          padding-top: ${theme.spacing(5)};
        }
      `}
  }
  .slick-slide img {
    display: block;
  }
  .slick-slide.slick-loading img {
    display: none;
  }
  .slick-slide.dragging img {
    pointer-events: none;
  }
  .slick-initialized .slick-slide {
    display: block;
  }
  .slick-loading .slick-slide {
    visibility: hidden;
  }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
`
