// @flow

import * as React from 'react'
import styled, { type StyledComponent } from 'styled-components'
import { $mainHeaderHeightXS } from '../components/shared/dependencies/variables'
import Header from './partials/MainHeaderNew'
import MainFooter from './partials/MainFooter/MainFooter'

import type { RouteLocation } from '../types/common'

type MainLayoutProps = {
  children?: React$Element<any>,
  location: RouteLocation
}

export const Container: StyledComponent<{}, *, *> = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const Content: StyledComponent<{}, *, *> = styled.main`
  flex: 1 0 auto;
  margin-top: ${$mainHeaderHeightXS};
  padding-top: 0;
`

// React.Fragment cannot take props by itself. Passing props to it will trigger a
// React alert. To avoid it, we've created a FragmentWrapper element
export const FragmentWrapper = (props: any) => <>{props.children}</>

const MainLayout = (props: MainLayoutProps) => (
  <Container>
    <Header location={props.location} />
    <Content>
      {props.children}
    </Content>
    <MainFooter location={props.location} />
  </Container>
)

export default MainLayout
