// @flow

import * as utils from '../utils/reduxUtils'
import * as actions from '../../constants/actions'
import * as API from '../api/clients'
import { addPublicationData } from '../utils/jobPublicationData'
import cookies from '../../cookies'

import type { APIRequestConfig } from '../api/types'
import type { Dispatch, GetState } from '../../store/configureStore'

const _fetchCurrentJob = (jobId: string, countryCode?: string) => async (
  requestConfig: APIRequestConfig,
  dispatch: Dispatch,
  getState: GetState
) => {
  const job = await API.jobs.get(jobId, countryCode, requestConfig)
  const user = getState().user.user
  const jobWithPublicationData = addPublicationData(job)
  const payload = {
    ...jobWithPublicationData,
    forUser: !!user,
  }
  return payload
}

/**
 * fetches job with the given jobId
 * @function
 * @param {string} jobId id of the job to be fetched
 * @return {Promise}
 */
export const fetchCurrentJob = utils.createPromise(
  actions.FETCH_CURRENT_JOB,
  _fetchCurrentJob
)

/**
 * fetches job with the given jobId without setting any loading state
 * @function
 * @param {string} jobId id of the job to be fetched
 * @return {Promise}
 */
export const fetchCurrentJobOpaquely = utils.createPromise(
  actions.FETCH_CURRENT_JOB_OPAQUELY,
  _fetchCurrentJob
)

/**
 * based on the current job and user state this either:
 * * different job - fetches job state with setting loading state
 * * same job - refetches job state without setting loading state
 * * no user - does nothing at all
 * @function
 * @param {string} jobId id of the job to be fetched
 * @return {Promise}
 */
export const refetchCurrentJobForUser = utils.createPromise(
  actions.REFETCH_CURRENT_JOB,
  (jobId) => async (
    requestConfig: APIRequestConfig,
    dispatch: Dispatch,
    getState: GetState
  ) => {
    const job = getState().currentJob.job
    const user = getState().user.user
    const jobPreviuoslyFetchedForUser = !!job?.forUser
    const currentlySignedIn = !!user
    if (currentlySignedIn && !jobPreviuoslyFetchedForUser) {
      if (job?.id === jobId) {
        return dispatch(fetchCurrentJobOpaquely(jobId))
      } else {
        return dispatch(fetchCurrentJob(jobId))
      }
    }
  }
)

// TODO: Remove this function code after June 2024
export const removeJUIDCookies = utils.createPromise(
  actions.REMOVE_JUID_COOKIES,
  (options) => async (config, dispatch, getState, [cookieJar, growthbook]) => {
    const allCookies = cookies.loadAll(cookieJar)()

    Object.keys(allCookies)
      .filter(m => m.startsWith('j-'))
      .forEach(m => cookies.remove(cookieJar)(m))
  }
)
