import React from 'react'
import styled from '@emotion/styled'
import { MobileUserProfileProps } from '../HNavBar.types'
import { getUsernameInitials, formatDisplayName } from '../utils'
import { HTypography } from '../../HTypography'
import { HAvatar } from '../../HAvatar'

const ProfileContainer = styled.div`
  display: flex;
  gap: 8px;
  height : 32px;
  justify-content: flex-start;
  align-items: center;
`

const LogOutButton = styled(HTypography)`
  margin-left: 24px;
  height: 32px;
  display: inline-flex;
  padding: 4px 8px 0;
  justify-content: center;
  align-items: center;
  &:hover, &:active {
    cursor: pointer;
  }
`

export const MobileUserProfile: React.FC<MobileUserProfileProps> = (props) => {
  const { user, logout } = props
  return (
    <>
      <ProfileContainer>
        <HAvatar size={24} color='primary_50' letters={getUsernameInitials(user)}/>
        <HTypography
          variant='body2'
          weight='semibold'
          component='div'
          color='surface_gray_80'
          data-test-id='main-header-username-mobile'
        >
          {formatDisplayName(user)}
        </HTypography>
      </ProfileContainer>
      <LogOutButton
        variant='button'
        weight='semibold'
        component='div'
        color='surface_gray_60'
        onClick={logout.clickAction}
        data-test-id='main-header-logout-link-mobile'
      >
        {logout.label}
      </LogOutButton>
    </>
  )
}
