// @flow
import type { Cookies } from 'react-cookie'

import { DEFAULT_SEARCH_PAGE_SIZE, DEFAULT_ORDER_BY_OPTION } from '../../constants/base'

import type { TrackingSchema } from './types'
import type { ApplicationState } from '../../types/applicationState'
import { getRankingOptions } from '../../epics/searchEpic/rankingOptions'
import get from 'lodash/get'

export type JobFeedType = {
  key: 'job_feed_context',
  options?: {}
}

export const SCHEMA_NAME = 'iglu:de.heyjobs/job_feed_context/jsonschema/1-0-10'

const jobFeed = (state: ApplicationState, cookies: Cookies, options?: {}): TrackingSchema[] => {
  const { customRankingInfo } = getRankingOptions(state)
  const searchType = state.jobSearch.searchModified ? 'active' : 'linked'
  const jobs = state.jobSearch.jobs || []
  const quickApplyCount = jobs.filter((job) => {
    return get(job, 'job.custom_attributes.allow_quick_apply.string_values[0]', 'false') === 'true'
  }).length

  const requestId = state.jobSearch.requestId || state.router.location.query.ri || null

  return [{
    schema: SCHEMA_NAME,
    data: {
      feed_page: state.jobSearch.currentPage,
      feed_total_results_count: state.jobSearch.numJobs,
      feed_results_on_page_count: state.jobSearch.numJobs < DEFAULT_SEARCH_PAGE_SIZE
        ? state.jobSearch.numJobs
        : DEFAULT_SEARCH_PAGE_SIZE,
      feed_search_query: state.jobSearch.searchOptions.keyword || undefined,
      feed_search_location: state.jobSearch.searchOptions.location || undefined,
      feed_search_radius: Number(state.jobSearch.searchOptions.distance) || undefined,
      feed_employment_type_filter: state.jobSearch.searchOptions.employmentTypes,
      feed_working_hours_filter: state.jobSearch.searchOptions.workingHours,
      feed_company_filter: state.jobSearch.searchOptions.companies,
      feed_easy_apply_filter: state.jobSearch.searchOptions.easyApply === 'true',
      feed_salary_filter: !!state.jobSearch.searchOptions.salary,
      feed_order_by: state.jobSearch.searchOptions.orderBy || DEFAULT_ORDER_BY_OPTION,
      feed_quick_apply_count: quickApplyCount,
      request_id: requestId,
      ranking_expression: customRankingInfo?.ranking_expression,
      importance_level: customRankingInfo?.importance_level,
      search_type: searchType,
    },
  }]
}

export default jobFeed
