// @flow

import additionalPageViewEventData from './additionalPageViewEventData'

import {
  orderIdApplicationSubmission,
  orderIdApplicationStarted,
} from '../../misc/getOrderId'

import type { ApplicationState } from '../../../types/applicationState'
import type { ContextData } from '../../types'
import type { RouteName } from '../../../routes'

const getTrackingToken = (state) => state.session.device.tracking_token
const isUserAuthenticated = (state) => state.user.isAuthenticated

const getOrderIdEventData = (routeName: RouteName, state: ApplicationState) => {
  switch (routeName) {
    case 'verify-email':
    case 'job-apply-success':
      return {
        conversionOrderId: orderIdApplicationSubmission(state),
      }
    case 'job-apply-form-new':
      return {
        conversionOrderId: orderIdApplicationStarted(state),
      }
    default:
      return {}
  }
}
const buildPageViewTrackingContextData = (routeName: RouteName, state: ApplicationState): ContextData => ({
  user_device_token: getTrackingToken(state),
  is_logged_in: isUserAuthenticated(state),
  ...additionalPageViewEventData(routeName, state),
  ...getOrderIdEventData(routeName, state),
})

export default buildPageViewTrackingContextData
