import React from 'react'
import { CheckmarkFilled32 } from '@carbon/icons-react'
import styled from '@emotion/styled'
import { COLORS } from '../theme'

interface HCheckCircleIconProps {
  size?: number
  animate?: boolean
}

const DEFAULT_SIZE = 64

export const HCheckCircleIcon =
styled(CheckmarkFilled32)<HCheckCircleIconProps>`
  fill: ${COLORS.support_success_30};
  width: ${({ size }) => size || DEFAULT_SIZE}px;
  height: ${({ size }) => size || DEFAULT_SIZE}px;
  position: relative;

  ${({ animate }) => animate && `
    & > path[data-icon-path='inner-path'] {
      animation: colorChange 1.5s;
      animation-delay: 0.5s;
    }
    @keyframes colorChange {
      0% {
        fill: ${COLORS.support_success_90};
        clip-path: inset(0 100% 0 0);
      }
      100% {
        fill: white;
        clip-path: inset(0 0 0 0);
      }
    }
  `}
`
