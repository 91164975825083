// @flow
import * as React from 'react'

type AbTestingVariantProps = {
  children: React.Node,
  variantName: string
}

type AbTestingOriginalProps = {
  children: React.Node,
}

const AbTestingVariant = (props: AbTestingVariantProps) => (
  <>{props.children}</>
)

export const AbTestingOriginal = (props: AbTestingOriginalProps) => (
  <>{props.children}</>
)

export default AbTestingVariant
