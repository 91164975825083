// @flow

import API from '../clientBuilder'
import { buildUrlFragment } from '../../../helpers/url/query'
import type { APIRequestConfig } from '../types'

export default {
  search: (options: { subdomain: string }, config: ?APIRequestConfig) =>
    API.get.promise(`/v2/company_search${buildUrlFragment(options)}`, null, config),
}
