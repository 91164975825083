export const styles = {
  h1: {
    fontSize: 36,
    lineHeight: '42px',
    letterSpacing: 0.2,
  },
  h2: {
    fontSize: 32,
    lineHeight: '40px',
    letterSpacing: 0.2,
  },
  h3: {
    fontSize: 28,
    lineHeight: '36px',
    letterSpacing: 0.2,
  },
  h4: {
    fontSize: 24,
    lineHeight: '32px',
    letterSpacing: 0.2,
  },
  h5: {
    fontSize: 20,
    lineHeight: '26px',
    letterSpacing: 0.2,
  },
  h6: {
    fontSize: 18,
    lineHeight: '24px',
    letterSpacing: 0.2,
  },
  body1: {
    fontSize: 16,
    lineHeight: '22px',
    letterSpacing: 0.1,
  },
  body2: {
    fontSize: 14,
    lineHeight: '18px',
    letterSpacing: 0.1,
  },
  body3: {
    fontSize: 10,
    lineHeight: '14px',
    letterSpacing: 0.1,
  },
  caption: {
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: 0.1,
  },
  caption2: {
    fontSize: 8,
    lineHeight: '12px',
    letterSpacing: 0.1,
  },
  overline1: {
    fontSize: 14,
    lineHeight: '18px',
    letterSpacing: 1.5,
  },
  overline2: {
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: 1.5,
  },
  button: {
    fontSize: 14,
    lineHeight: '24px',
    letterSpacing: 0.2,
  },
  underline: {
    textDecoration: 'underline !important',
  },
  italic: {
    fontStyle: 'italic',
  },

  light: {
    fontWeight: 300,
  },
  semibold: {
    fontWeight: 600,
  },
  regular: {
    fontWeight: 400,
  },
  medium: {
    fontWeight: 500,
  },
  inherit: {
    fontWeight: 'inherit',
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  left: {
    textAlign: 'left',
  },
  center: {
    textAlign: 'center',
  },
  right: {
    textAlign: 'right',
  },
  truncated: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  pointer: {
    cursor: 'pointer',
  },
  breakWord: {
    wordBreak: 'break-word',
  },
  lineClamp: (lineClamp: number) => ({
    display: '-webkit-box',
    WebkitLineClamp: `${lineClamp}`,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    wordBreak: 'break-word',
  }),
}
