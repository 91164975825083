import { GrowthBook } from '@growthbook/growthbook-react'
import logger from '../../logging'
import { Job } from '../types/job'
import { isJobUnavailable } from '../pages/JobDetail/jobUtils'

const log = logger('highlighted-job-card')

const VALID_MARKETING_SOURCES = [
  'joblift',
  'jobrapido',
  'neuvoo',
  'jobworld',
  'jobsora',
  'meinestadt',
  'jobboersede',
  'allthetopbananas',
  'bebee',
  'jobkicks',
  'adzuna',
  'jobtome',
  'opportuno',
  'stellenonline',
  'jobijoba',
  'jobrobot',
  'facebook',
  'tiktok',
]

const isMarketingSourceValid = (query: Record<string, string>) => {
  const source = query.utm_source || query.mkt_source
  return VALID_MARKETING_SOURCES.includes(source?.toLowerCase())
}

export const resolveHighlightedJobExperiment = (
  growthbook: GrowthBook,
  job: Job,
  query: Record<string, string>
) => {
  const validMarketingSource = isMarketingSourceValid(query)
  if (!validMarketingSource) {
    return false
  }

  if (isJobUnavailable(job)) {
    log.dataCapture('job is unavailable', {
      datadogContext: {
        jobUnavailable: isJobUnavailable(job),
      },
    })

    return false
  }

  if (!job?.location?.city) {
    log.dataCapture('job city is missing', {
      datadogContext: {
        city: job?.location?.city,
      },
    })

    return false
  }

  const experiment = growthbook.evalFeature('growth_highlighted_job_card')
  const variant = experiment?.value
  if (variant && variant !== 'unknown') {
    log.dataCapture('highlighted job variant', { datadogContext: { variant, source: experiment?.source } })
  }

  return variant === 'growth_highlighted_job_card_on'
}
