// @flow

const charactersNeedingQoutes = ['-', '"']

export default (text: string) => {
  if (charactersNeedingQoutes.some((c) => text.indexOf(c) > -1)) {
    return `"${text}"`
  }

  return text
}
