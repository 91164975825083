// @flow

import typeof BuildPageViewTrackingEventDataType from '../events/pageview/buildPageViewTrackingEventData'
import type { ApplicationState } from '../../types/applicationState'
import type { RouteName } from '../../routes'

const pageViewTrackingEventBuilder = (routeName: RouteName, state: ApplicationState) => {
  if (typeof window === 'undefined') return

  return (window.__heyjobs_tc.buildPageViewTrackingEventData: BuildPageViewTrackingEventDataType)(
    routeName,
    state
  )
}

export default pageViewTrackingEventBuilder
