// @flow

const getClientRelativeURI = () => {
  if (typeof window !== 'undefined') {
    return window.location.pathname + window.location.search
  }
  return ''
}

export default getClientRelativeURI
