// @flow

import * as actions from '../constants/actions'

export type NavbarState = {
  hideCookieBar: bool,
  showMenu: bool
}

type NavbarActionType = typeof actions.LOCATION_CHANGE |
  typeof actions.SHOW_NAV_MENU |
  typeof actions.HIDE_NAV_MENU |
  typeof actions.HIDE_COOKIE_BAR |
  typeof actions.USE_FLOATING_COOKIE_BAR

type NavbarAction = {
  type: NavbarActionType,
}

const initialState: NavbarState = {
  showMenu: false,
  hideCookieBar: false,
}

export default (state: NavbarState = initialState, action: NavbarAction) => {
  switch (action.type) {
    case actions.LOCATION_CHANGE:
      return {
        ...state,
        showMenu: false,
      }
    case actions.SHOW_NAV_MENU:
      return {
        ...state,
        showMenu: true,
      }
    case actions.HIDE_NAV_MENU:
      return {
        ...state,
        showMenu: false,
      }
    case actions.HIDE_COOKIE_BAR:
      return {
        ...state,
        hideCookieBar: true,
      }
    default:
      return state
  }
}
