// @flow

import lowerCase from 'lodash/lowerCase'
import get from 'lodash/get'
import first from 'lodash/first'
import isFunction from 'lodash/isFunction'
import config from '../../../config'
import { getRoutesForLocale } from '../../routes'
import type { Store } from 'redux'
import type { Locale, RouteData } from '../../types/common'
import type { Action } from '../../store/configureStore'
import type { Job } from '../../types/job'
import type { ApplicationState } from '../../types/applicationState'

const getJobLocale = (job: Job): Locale => job.locale
const getJobCountry = (job: Job) => job.company.country_code

const getAlternativeRoute = (locale: Locale,
  country: string,
  routePath: string,
  routeData: RouteData
) => {
  const routes = getRoutesForLocale(locale)

  const countryRef = routes.country(country)
  const routeRef = get(countryRef, routePath)

  return isFunction(routeRef)
    ? routeRef({ ...routeData.params, ...routeData.location.query })
    : routeRef
}

const localeRedirect = (
  replace: Function,
  store: Store<ApplicationState, Action>,
  routePath: string,
  routeData: RouteData
) => {
  const state = store.getState()
  const job = state.currentJob.job
  const { locale } = state.intlData
  const { country } = state.locality

  if (job) {
    const jobLocale = getJobLocale(job)
    const jobCountry = lowerCase(getJobCountry(job))

    const isNotMatchingCountry = country !== jobCountry
    const allowedLocalesForJobCountry = config.countries.locales[jobCountry]
    const isNotAllowedLocaleForJobCountry = !allowedLocalesForJobCountry.includes(
      locale
    )

    if (isNotMatchingCountry || isNotAllowedLocaleForJobCountry) {
      const alternativeLocale = isNotAllowedLocaleForJobCountry
        ? jobLocale
        : locale

      const alternativeRoute = getAlternativeRoute(alternativeLocale, jobCountry, routePath, routeData)

      return replace(alternativeRoute)
    }
  } else {
    const allowedLocalesForCountry = config.countries.locales[lowerCase(country)]
    const isNotAllowedLocaleForCountry = allowedLocalesForCountry && !allowedLocalesForCountry.includes(locale)

    if (isNotAllowedLocaleForCountry) {
      const alternativeLocale = first(allowedLocalesForCountry)
      const alternativeRoute = getAlternativeRoute(alternativeLocale, country, routePath, routeData)

      return replace(alternativeRoute)
    }
  }
}

export default localeRedirect
