// @flow
import * as actions from '../constants/actions'
import { FULFILLED, REJECTED, PENDING } from './promiseUtil'

export type ResendConfirmationEmailModalState = {
  loading: bool,
  redirect: ?string,
  visible: bool
}

const initialState: ResendConfirmationEmailModalState = {
  visible: false,
  loading: false,
  redirect: null,
}

const reducer = (state: ResendConfirmationEmailModalState = initialState, action: Object) => {
  switch (action.type) {
    case actions.TOGGLE_RESEND_CONFIRMATION_EMAIL_MODAL:
      return {
        ...state,
        redirect: (action.options && action.options.redirect) || null,
        visible: !state.visible,
      }
    case PENDING(actions.RESEND_VERIFICATION_EMAIL):
      return {
        ...state,
        loading: true,
      }
    case FULFILLED(actions.RESEND_VERIFICATION_EMAIL):
      return {
        ...state,
        loading: false,
      }
    case REJECTED(actions.RESEND_VERIFICATION_EMAIL):
      return {
        ...state,
        error: action.payload.errors,
        loading: false,
      }
    default:
      return state
  }
}

export default reducer
