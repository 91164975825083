import React from 'react'
import { FormattedMessage } from 'react-intl'
import { HBox, HTypography } from 'talent-ui'

interface SuccessMessageProps {
  isAlmostThere: boolean
  hasEveryValue: boolean
  location?: string
  keyword?: string
}

export const SuccessMessage: React.FC<SuccessMessageProps> = ({ isAlmostThere, hasEveryValue, location, keyword }) => {
  if (hasEveryValue) {
    return (
      <>
        <HBox mb={2}>
          <HTypography
            variant='h4'
            color='surface_gray_90'
            lg={{ variant: 'h3' }}
          >
            <FormattedMessage id='onboarding_flow_you_are_in_demand' />
          </HTypography>
        </HBox>
        <HBox mb={8}>
          <HTypography
            variant='h4'
            color='surface_gray_90'
            lg={{ variant: 'h3' }}
            data-test-id='onboarding_flow_success_found_for_in'
          >
            <FormattedMessage
              id='onboarding_flow_success_found_for_in'
              values={{
                filter: keyword,
                location
              }}
            />
          </HTypography>
        </HBox>
      </>
    )
  }

  return (
    <HBox mb={8}>
      {isAlmostThere
        ? (
          <HTypography
            variant='h4'
            color='surface_gray_90'
            lg={{ variant: 'h3' }}
          >
            <FormattedMessage id='onboarding_flow_almost_there' />
          </HTypography>
          )
        : (
          <HTypography
            variant='h4'
            color='surface_gray_90'
            lg={{ variant: 'h3' }}
          >
            <FormattedMessage
              id='onboarding_flow_what_we_found'
            />
          </HTypography>

          )}
    </HBox>
  )
}
