// @flow

import * as actions from '../constants/actions'

export type AppState = {
  abTests: bool,
  init: bool
}

const initialState: AppState = {
  abTests: false,
  init: true,
}

const reducer = (state: AppState = initialState, action: $FlowTODO) => {
  switch (action.type) {
    case actions.SET_APP_INITIALIZED:
      return {
        ...state,
        init: action.initState,
      }
    case actions.SET_AB_TESTS_INITIALIZED:
      return {
        ...state,
        abTests: action.abTestsState,
      }
    default:
      return state
  }
}

export default reducer
