import { JobTitlesPreferences, JobTitlesValidationState } from 'user-profile-data'
import React, { useState } from 'react'
import StepContainer from '../../common/step-container'
import { useOnboarding, useOnboardingFlow } from '../../common/use-onboarding-flow'
import { JobTitle } from '../../types/api-types'
import { OnboardingStep } from '../../types/flow'

export type JobTitleProps = {
  value?: JobTitle[]
}

const OnboardingJobTitles: OnboardingStep<JobTitleProps> = ({ value }) => {
  const { fetchJobTitles } = useOnboarding()
  const { saveStepData, setStepState, goNext, onStepClose, stepsProgress, goBack } = useOnboardingFlow()

  const [selectedJobTitles, setSelectedJobTitles] = useState<JobTitle[]>(value || [])
  const [validationState, setValidationState] = useState<JobTitlesValidationState>('valid')

  const onContinue = () => {
    if (validationState === 'dirty') {
      setValidationState('invalid')
      return
    }

    setStepState('job_title', { value: selectedJobTitles })

    saveStepData({
      step_name: 'job_title',
      step_action: 'filled',
      step_value: selectedJobTitles
    })

    goNext()
  }

  const onSkip = () => {
    setStepState('job_title', { value: selectedJobTitles })

    saveStepData({
      step_name: 'job_title',
      step_action: 'skipped'
    })

    goNext()
  }

  const onGoBack = () => {
    setStepState('job_title', { value: selectedJobTitles })

    goBack()
  }

  return (
    <StepContainer
      title='onboarding_flow_job_titles_step_title'
      validToContinue={!!selectedJobTitles.length}
      progress={stepsProgress}
      onClose={() => onStepClose('job_title')}
      onSkip={onSkip}
      onContinue={onContinue}
      onBack={onGoBack}
    >
      <JobTitlesPreferences
        fetchJobTitles={fetchJobTitles}
        selectedJobTitles={selectedJobTitles}
        setSelectedJobTitles={setSelectedJobTitles}
        variant='onboarding'
        showError={validationState === 'invalid'}
        setValidationState={setValidationState}
        validationState={validationState}
      />
    </StepContainer>

  )
}

export default OnboardingJobTitles
