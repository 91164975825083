// @flow
import * as actions from '../constants/actions'
import { FULFILLED, REJECTED } from './promiseUtil'

type Action = {
  payload: {
    published_jobs: number
  },
  type: string
}

export type CountsState = {
  publishedJobs: number
}

const fallbackState: CountsState = {
  publishedJobs: 355289,
}

const initialState: CountsState = {
  publishedJobs: 0,
}

const reducer = (state: CountsState = initialState, action: Action) => {
  switch (action.type) {
    case FULFILLED(actions.GET_TALENT_PLATFORM_COUNTS):
      return {
        publishedJobs: action.payload.published_jobs,
      }
    case REJECTED(actions.GET_TALENT_PLATFORM_COUNTS):
      return fallbackState
    default:
      return state
  }
}

export default reducer
