// @flow

import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import styled, { type StyledComponent } from 'styled-components'
import SlimLayout from '../layouts/SlimLayout'
import errorImage from 'assets/images/general/error-icon.svg'
import notAvailableJobImage from 'assets/images/general/not-available-job.svg'
import LinkButton from '../components/shared/buttons/linkButton'
import { $smallerThan } from '../components/shared/dependencies/mixins'
import { $sizes, $fontWeights, $small } from '../components/shared/dependencies/variables'
import * as searchUrl from '../helpers/url/searchUrl'

import type { Locale, Country } from '../types/common'
import type { Job } from '../types/job'

type NotAvailableContainerProps = {
  children: React.Node,
  description: string,
  image: string,
  title: string
}

type NotPublishedButtonProps = {
  country: Country,
  job: Job,
  locale: Locale
}

const CenteredContainer: StyledComponent<{}, *, *> = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Content = styled.div`
  text-align: center;
  width: 100%;
  max-width: 702px;
`

const Image = styled.img`
  width: 100%;
  max-width: 190px;
`

const Title = styled.div`
  width: 100%;
  margin-top: 24px;
  font-size: 36px;
  font-weight: ${$fontWeights.bold};
  line-height: 1.2;
  text-align: center;
  color: rgba(0, 0, 0, 0.8);
  white-space: pre-line;
  display: block;
  unicode-bidi: embed;

  ${$smallerThan($small)} {
    padding: 0 ${$sizes.base};
  }
`

const Description = styled.div`
  width: 100%;
  margin-top: ${$sizes.xl};
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: rgba(0, 0, 0, 0.8);
  white-space: pre-line;
  display: block;
  unicode-bidi: embed;

  ${$smallerThan($small)} {
    padding: 0 ${$sizes.base};
  }
`

const LinkContainer = styled.div`
  width: 184px;
  margin: auto;
  margin-top: ${$sizes.xl};
`

const NotAvailableComponent = (props: any) => (
  <CenteredContainer>
    <Content>
      <Image
        src={props.image}
        alt='404'
      />
      <Title>
        <FormattedMessage id={props.title} />
      </Title>
      <Description>
        <FormattedMessage id={props.description} />
      </Description>
      {props.children}
    </Content>
  </CenteredContainer>
)

const NotAvailableLayout = (props: any) => (
  <SlimLayout {...props}>
    <NotAvailableComponent {...props} />
  </SlimLayout>
)

export const NotFound = (props: NotAvailableContainerProps) => (
  <NotAvailableLayout
    {...props}
    image={errorImage}
    title='404_title'
    description='404_description'
  />
)

export const NotFoundComponent = (props: NotAvailableContainerProps) => (
  <NotAvailableComponent
    {...props}
    image={errorImage}
    title='404_title'
    description='404_description'
  />
)

export const ShowNotFoundIf = (condition: (arg: any) => bool, withLayout: bool = true) =>
  (Component: React.ComponentType<any>) =>
    (props: any): React.Node =>
      condition(props)
        ? (
          <Component {...props} />
          )
        : (
            withLayout ? (<NotFound {...props} />) : (<NotFoundComponent {...props} />)
          )

const NotPublishedButton = (props: NotPublishedButtonProps) => {
  const { locale, country, job } = props
  const redirectUrl = searchUrl.build(
    {
      location: job.location.city,
      keyword: job.type.localized_name,
    },
    locale,
    country
  )
  return (
    <LinkContainer>
      <LinkButton
        href={redirectUrl}
        data-test-id='more-jobs-button'
      >
        <FormattedMessage id='job_not_available_button' />
      </LinkButton>
    </LinkContainer>
  )
}

const ShowNotPublished = (props: (NotAvailableContainerProps & NotPublishedButtonProps)) => (
  <NotAvailableLayout
    {...props}
    image={notAvailableJobImage}
    title='job_not_available_title'
    description='job_not_available_description'
  >
    <NotPublishedButton
      job={props.job}
      country={props.country}
      locale={props.locale}
    />
  </NotAvailableLayout>
)

export const ShowNotPublishedIf = (condition: (arg: any) => bool) => (Component: React.ComponentType<any>) => (props: any) =>
  condition(props)
    ? <ShowNotPublished {...props} />
    : <Component {...props} />
