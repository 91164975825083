import Tab, { TabProps } from '@mui/material/Tab'
import styled from '@emotion/styled'
import React from 'react'
import { COLORS } from '../theme'
import { HTypography } from '../HTypography'

type disableProps = 'color' | 'disableRipple'
type HTabProps = Omit<TabProps, disableProps>

const HTabComp = ({ label, ...others }: HTabProps): JSX.Element => {
  return (
    <Tab
      color='default'
      disableRipple
      {...others}
      label={
        <HTypography variant='body1' color='inherit' weight='inherit'>
          {label}
        </HTypography>
      }
    ></Tab>
  )
}

export const HTab = styled(HTabComp)`
  text-transform: none;
  min-width: unset;
  padding: 12px;
  opacity: 1;
  font-weight: 400;
  color: ${COLORS.surface_gray_60};
  max-width: unset;
  &:hover {
    color: ${COLORS.primary_50};
  }
  &.Mui-selected {
    font-weight: 500;
    color: ${COLORS.primary_50};
    border: none !important;
    background-color: unset;
  }
`
