import { Breakpoint, Dialog, DialogProps, Theme, useMediaQuery } from '@mui/material'
import React from 'react'
import styled from '@emotion/styled'
import { HDrawer, IHDrawerProps } from '../HDrawer'
import { HModalProps } from '../HModal'

export type HModalWrapperProps = HModalProps & IHDrawerProps & {
  desktopBreakpoint?: Breakpoint
  forceModal?: boolean
}

const ModalDialog: React.FC<DialogProps> = ({
  open,
  onClose,
  fullScreen,
  children,
  ...otherProps
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={fullScreen}
      {...otherProps}
    >
      {children}
    </Dialog>)
}

const WrappedDrawer = styled(HDrawer)(() => ({
  '.MuiPaper-root': {
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
  },
}))

export const HModalWrapper: React.FC<HModalWrapperProps> = ({
  children,
  open,
  onClose,
  onOpen,
  forceModal,
  desktopBreakpoint = 'lg',
  modalVariant,
  ...other
}) => {
  const isDesktop = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.up(desktopBreakpoint)
  })
  const Component = (isDesktop || forceModal)
    ? (
      <ModalDialog
        open={open}
        fullScreen={modalVariant === 'fullScreen'}
        {...other}
      >
        {children}
      </ModalDialog>
    )
    : (
      <WrappedDrawer
        open={open}
        onClose={onClose}
        onOpen={onOpen}
        {...other}
      >
        {children}
      </WrappedDrawer>
    )
  return (
    <div className='universal-modal-wrapper'>
      {Component}
    </div>
  )
}
