import React from 'react'
import Card, { CardProps } from '@mui/material/Card'
import styled from '@emotion/styled'
import { COLORS, globalStyles } from '../theme'

interface OverrideCardProps {
  elevated?: boolean
  padding?: 'small' | 'large'
}

type HCardProps = OverrideCardProps & Omit<CardProps, keyof OverrideCardProps>

const HCardComp = ({
  elevated,
  padding = 'large',
  ...others
}: HCardProps): JSX.Element => {
  return <Card elevation={0} raised={false} {...others} />
}

export const HCard = styled(HCardComp)`
  border: 1px solid ${COLORS.surface_gray_10};
  ${({ elevated }) => (elevated ? globalStyles.shadow1 : '')};
  padding: ${({ padding }) =>
    padding === 'small' ? '16px 16px 24px 16px' : '24px 24px 32px 24px'};
`
