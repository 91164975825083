import React, { useEffect } from 'react'
import OnboardingFlow from './flow/onboarding-flow'
import { OnboardingProps } from './flow/types/types'

const displayDelay = 3000

type OnboardingModalPresentationProps = Omit<OnboardingProps, 'onClickClose'> & {
  isModalVisible: boolean;
  fetchedModalData: boolean;
  isUserPendingAnUpdate: boolean;
  isTheTermsAndConditionsModalVisible: boolean;
  hasUserFilledJobSearchPreferences: boolean;
  shouldSeeOnboardingModal: boolean;
  getUserOnboardingData: () => void;
  clearSeeOnboardingModal: () => void;
  hideOnboardingModal: () => void;
  refreshUser: () => void;
  redirectToProfile: () => void;
};

export const OnboardingModalPresentation: React.FC<OnboardingModalPresentationProps> = ({
  isModalVisible,
  fetchedModalData,
  isTheTermsAndConditionsModalVisible,
  isUserPendingAnUpdate,
  hasUserFilledJobSearchPreferences,
  shouldSeeOnboardingModal,
  getUserOnboardingData,
  clearSeeOnboardingModal,
  hideOnboardingModal,
  refreshUser,
  redirectToProfile,
  ...onboardingProps
}) => {
  useEffect(() => {
    if (
      !shouldSeeOnboardingModal ||
      isTheTermsAndConditionsModalVisible) {
      return
    }

    const timeoutID = setTimeout(getUserOnboardingData, displayDelay)

    return () => clearTimeout(timeoutID)
  }, [
    shouldSeeOnboardingModal,
    isTheTermsAndConditionsModalVisible,
    getUserOnboardingData,
  ])

  const showOnboardingFlow =
  shouldSeeOnboardingModal &&
  isModalVisible &&
  (fetchedModalData || hasUserFilledJobSearchPreferences) &&
  !isUserPendingAnUpdate

  if (!showOnboardingFlow) {
    return null
  }

  const handleClose = () => {
    clearSeeOnboardingModal()

    refreshUser()
    hideOnboardingModal()
  }

  if (hasUserFilledJobSearchPreferences) {
    handleClose()

    redirectToProfile()
  }

  return (
    <OnboardingFlow
      onClickClose={handleClose}
      {...onboardingProps}
    />
  )
}
