import createAppThunk from '../createAppThunk'
import parsedCvApi from '../../actions/api/clients/parsedCv'
import { ParsedCv, RawParsedCv } from './types'
import camelCase from 'lodash/camelCase'
import snakeCase from 'lodash/snakeCase'

export const convertParsedCv = (from: 'state' | 'response') => {
  const convertMethod = from === 'state' ? snakeCase : camelCase
  const map: Record<string, string> = from === 'state'
    ? { employmentHistory: 'experiences', educationHistory: 'educations' }
    : { experiences: 'employmentHistory', educations: 'educationHistory' }

  const convert = (obj: any): ParsedCv | RawParsedCv => {
    const returnObject: Record<string, unknown> = {}

    for (const key in obj) {
      if (key === 'location_data') {
        returnObject[key] = obj[key]
        continue
      }

      const value = obj[key]
      const convertedKey = convertMethod(key)

      if (value === null || typeof value !== 'object') {
        returnObject[convertedKey] = value
      } else if (Array.isArray(value)) {
        const newArray = value.map((element) => convert(element))

        if (map[key]) {
          returnObject[map[key]] = newArray
        } else {
          returnObject[convertedKey] = newArray
        }
      } else {
        returnObject[convertedKey] = convert(value)
      }
    }

    return returnObject
  }

  return convert
}

const convertToParsedCv = convertParsedCv('response')
const convertToRawParsedCv = convertParsedCv('state')

export const getParsedCvThunk = createAppThunk(
  'parsedCv/get',
  async (documentId: string, { extra: { requestConfig }, rejectWithValue }) => {
    const parsedCvResponse = await parsedCvApi.get(documentId, requestConfig).catch(rejectWithValue)

    return convertToParsedCv(parsedCvResponse)
  }
)

export const updateParsedCvThunk = createAppThunk<ParsedCv, { documentId: string, parsedCv: RawParsedCv }>(
  'parsedCv/update',
  async ({ documentId, parsedCv }, { extra: { requestConfig }, rejectWithValue }) => {
    const rawParsedCv = convertToRawParsedCv(parsedCv) as RawParsedCv

    // FIXME(TPRE-1906): 4** does not reject with an error
    const response = await parsedCvApi.update(documentId, rawParsedCv, requestConfig).catch(rejectWithValue)

    return convertToParsedCv(response)
  }
)
