// @flow

import * as actions from '../constants/actions'
import { FULFILLED, REJECTED } from './promiseUtil'

export type SessionState = {
  device: {
    token: string,
    tracking_token: string
  },
  errors: Object
}

const initialState: SessionState = {
  cognito: {},
  device: {},
  errors: {},
}

const stateReducer = (state: SessionState = initialState, action: $FlowTODO): SessionState => {
  switch (action.type) {
    case FULFILLED(actions.RESOLVE_DEVICE): {
      return {
        ...state,
        device: action.payload,
      }
    }
    case REJECTED(actions.RESOLVE_DEVICE): {
      return {
        ...state,
        device: {},
        errors: action.errors,
      }
    }
    case actions.UPDATE_DEVICE_TOKEN: {
      return {
        ...state,
        device: action.payload,
      }
    }
    default:
      return state
  }
}

export default stateReducer
