// @flow

import {
  tap,
} from 'rxjs/operators'

type Resolvers = {
  reject: (Error) => void,
  resolve: (any) => void
}

type ResolvableAction<P> = {
  meta: {
    options: P,
    resolvers: Resolvers
  },
  rx: Promise<any>,
  type: string
};

export function resolvableEpicAction<P> (type: string, options: P): ResolvableAction<P> {
  const resolvers = {}
  const dummyPromise = new Promise<void>((resolve, reject) => {
    resolvers.resolve = resolve
    resolvers.reject = reject
  })
  return {
    type,
    meta: { options, resolvers },
    rx: dummyPromise,
  }
}

export function actionResolver (action: ResolvableAction<any>) {
  return tap<any>(
    value => {
      const resolve = action.meta?.resolvers?.resolve
      if (resolve) resolve(value)
    },
    error => {
      const reject = action.meta?.resolvers?.reject
      if (reject) reject(error)
    }
  )
}
