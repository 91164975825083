import React from 'react'
import { Wikis16 as LangChangeIcon } from '@carbon/icons-react'
import { LoggedOutDesktopMenuProps } from '../HNavBar.types'
import { LoggedOutMenuItems } from './LoggedOutMenuItems'
import { HBox } from '../../HBox'
import { HTypography } from '../../HTypography'
import styled from '@emotion/styled'

const AlignedText = styled(HTypography)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`

export const LoggedOutDesktopMenu: React.FC<LoggedOutDesktopMenuProps> =
  (props) => {
    const {
      Component,
      componentProps,
      DesktopMenuLabel,
    } = props.menuItems.languageChange

    return (
      <HBox display='flex' gap={32} alignItems='center'>
        <LoggedOutMenuItems {...props} platform="desktop" />
        <Component {...componentProps} data-test-id='lang-switch-desktop'>
          <AlignedText
            color='surface_gray_60'
            weight='medium'
            variant='button'
            cursor='pointer'
            selectors={[{ selector: ':hover', props: { color: 'surface_gray_80' } }]}
          >
            <LangChangeIcon />
            {DesktopMenuLabel}
          </AlignedText>
        </Component>
      </HBox>
    )
  }
