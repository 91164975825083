
/**
 * Flattens the object structure (up to `maxDepth`, if given) & returns a new
 * result object containing the flattened keys and values at the original path.
 */
const flatten = (
  obj: any,
  maxDepth?: number | null,
): any => {
  const resultObj: any = {}

  const dfs = (obj: any, path: string[] = [], depth = 0): void => {
    if ((maxDepth != null && depth >= maxDepth) || !(obj instanceof Object)) {
      resultObj[path.join('.')] = obj
      return // break out of recursion
    }
    for (const [key, value] of Object.entries(obj)) {
      dfs(value, [...path, key], depth + 1)
    }
  }

  dfs(obj)

  return resultObj
}

export default flatten
