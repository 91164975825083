import React, { useState } from 'react'
import { SwitchTransition } from 'react-transition-group'
import { Fade, type FadeProps } from '@mui/material'
import { type UserProfileCompletionStatus } from 'user-profile-data'
import ProfileCompleted from './profile-completed'
import AnimatedCompletionProgress from './animated-completion-progress'
import { usePrevious } from './use-previous'
import { useTrackProfileCompletenessChange } from './use-track-profile-completeness-change'
import { type fireSnowplowEvent, type fireSnowplowStructEvent } from '../../services/tracking/snowplow/tracker'

export interface ProfileCompletionStatusProps {
  profileCompletionStatus: UserProfileCompletionStatus
  fireTrackingEvent: typeof fireSnowplowEvent
  fireStructuredTrackingEvent: typeof fireSnowplowStructEvent
  withCtaButton?: boolean
  LinkComponent: React.ComponentType<any>
  routerData: {
    locale: LOCALE
    country: COUNTRY
  }
}

export const animationDuration = 1000
export const fadeAnimationDuration = 300

/* We need to wrap our render components in a component that forwards refs (which HBox does not) */
const FadeWrapper: React.FC<FadeProps> = ({ children, ...fadeProps }) => {
  return (
    <Fade {...fadeProps}>
      <div>
        {children}
      </div>
    </Fade>
  )
}

export const ProfileCompletionStatus = ({
  fireTrackingEvent,
  fireStructuredTrackingEvent,
  profileCompletionStatus,
  withCtaButton,
  LinkComponent,
  routerData,
}: ProfileCompletionStatusProps) => {
  useTrackProfileCompletenessChange(profileCompletionStatus, fireTrackingEvent)

  const targetPercent = profileCompletionStatus?.percent || 0
  const [committedPercent, setCommittedPercent] = useState(targetPercent)
  const isProfileCompleted = targetPercent >= 100
  const isAnimationCompleted = committedPercent === targetPercent

  // Ensure we don't show the completion status as complete until the animation has finished
  const showCompleted = isProfileCompleted && isAnimationCompleted

  // We always want this to appear animating from zero except when it's initially 100
  // FIXME(TPRE-1519): animating from zero every time the user navigates between profile tabs is
  // distracting
  const animateFromZero = !!withCtaButton
  const isInitialRender = React.useRef(true)
  const fadeDuration = isInitialRender.current ? 0 : fadeAnimationDuration
  const initialPreviousPercent = showCompleted ? 100 : animateFromZero ? 0 : targetPercent
  const previousPercent = usePrevious(targetPercent) ?? initialPreviousPercent

  isInitialRender.current = false

  return (
    <div data-test-id='profile-completion-status'>
      <SwitchTransition mode='out-in'>
        {showCompleted
          ? (
            <FadeWrapper
              key='completed'
              timeout={fadeDuration}
            >
              <ProfileCompleted />
            </FadeWrapper>
            )
          : (
            <FadeWrapper
              key='progress'
              timeout={fadeDuration}
            >
              <AnimatedCompletionProgress
                targetPercent={targetPercent}
                animateFrom={previousPercent}
                animationDuration={animationDuration}
                onComplete={() => { setCommittedPercent(targetPercent) }}
                withCtaButton={withCtaButton}
                fireStructuredTrackingEvent={fireStructuredTrackingEvent}
                LinkComponent={LinkComponent}
                routerData={routerData}
              />
            </FadeWrapper>
            )}
      </SwitchTransition>
    </div>
  )
}
