import createAppThunk from '../createAppThunk'

import onboarding from '../../actions/api/clients/onboarding'
import { JobTitle } from 'user-profile-data'
import { SaveJobTitlesParams, SaveJobTitlesAPIParams, SaveStepParams } from 'onboarding-flow'

const extractJobTitlesIds = (jobTitle: JobTitle) => jobTitle.title_id

export const saveOnBoardingJobTitles = createAppThunk<boolean, SaveJobTitlesParams>(
  'onboarding/jobTitles',
  (params, { getState, extra: { requestConfig }, rejectWithValue }) => {
    const userId = getState().user.user?.id

    const callParams: SaveJobTitlesAPIParams = {
      step_name: 'job_title',
      step_action: 'filled',
      step_value: params.step_value.map(extractJobTitlesIds),
    }

    return onboarding.save(userId!, callParams, requestConfig).catch(rejectWithValue)
  }
)

export const saveOnboardingThunk = createAppThunk<boolean, SaveStepParams>(
  'onboarding/saveStep',
  (params, { getState, extra: { requestConfig }, rejectWithValue }) => {
    const userId = getState().user.user?.id
    return onboarding.save(userId!, params, requestConfig).catch(rejectWithValue)
  }
)

export const getOnboardingThunk = createAppThunk(
  'onboarding/getStep',
  (_, { getState, extra: { requestConfig }, rejectWithValue }) => {
    const state = getState()
    const userId = state.user.user?.id
    const locale = state.intlData.locale

    return onboarding.get(userId!, requestConfig, locale).catch(rejectWithValue)
  }
)
