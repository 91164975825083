// @flow

import moment from 'moment'
import get from 'lodash/get'
import { REDIRECT_EXPIRED_JOBS_AFTER_IN_DAYS } from '../../constants/base'
import { type SearchUrlOptions, build } from '../../helpers/url/searchUrl'
import isPreviewMode from '../../helpers/url/isPreviewMode'

import type { Job } from '../../types/job'
import type { GCTSJob } from '../../types/gctsJob'
import type { RouteData, Locale, Country, QueryParams } from '../../types/common'
import type { Company } from '../../types/company'
import { fireSnowplowEvent } from '../../tracking/external/trackingClient'
import { SIMILAR_JOBS_CLICKED_EVENT } from '../../tracking/eventNames'
import logger from '../../../logging'

type SimilarJobsOptionsParams = {
  company?: ?Company,
  experimentVariant: string,
  job: Job
}

const highlightedJobLogger = logger('highlighted-job')

// Expiration redirects
const isJobUnpublished = (status): bool => status === 'unpublished'
const isAtsDeliveryMode = (deliveryMode: string) => deliveryMode === 'ats'
const isNonClientCompany = (company: Company): bool => company && company.client_status === 'no_client'
const isUnpublishedByAdmin = (status: string): bool =>
  status === 'unpublished_by_admin'
const isUnpublishedByRecruiter = (status: string): bool =>
  status === 'unpublished_by_recruiter'
const isUnpublishedByExpiration = (status): bool =>
  status === 'unpublished_by_expiration'
const shouldRedirectAfterExpiration = (unpublishedAt): bool =>
  moment().diff(moment(unpublishedAt, 'x'), 'days') >=
  REDIRECT_EXPIRED_JOBS_AFTER_IN_DAYS

const isJobExpired = (jobStatus: string, unpublishedAt: string): bool =>
  isUnpublishedByExpiration(jobStatus) && shouldRedirectAfterExpiration(unpublishedAt)

export const isJobUnavailable = (job: Job | GCTSJob): bool => {
  const careerPageStatus = get(job, 'career_page_status') || get(job, 'job.career_page_status')
  const unpublishedAt = get(job, 'unpublished_at') || get(job, 'job.unpublished_at')
  const candidateDeliveryMode = get(job, 'company.candidate_delivery_mode')
  const jobStatus = get(job, 'status') || get(job, 'job.status')

  return (
    isJobUnpublished(careerPageStatus) &&
    (
      isAtsDeliveryMode(candidateDeliveryMode) ||
      isUnpublishedByRecruiter(jobStatus) ||
      isUnpublishedByAdmin(jobStatus) ||
      isJobExpired(jobStatus, unpublishedAt)
    )
  )
}

export const shouldJobBeAccessible = (
  job: Job,
  viewedByBot: bool,
  routeData: RouteData
): bool =>
  !isPreviewMode(routeData) &&
  isJobUnpublished(job.career_page_status) &&
  viewedByBot

export const shouldRedirectToSearchPage = (job: Job, viewedByBot: bool, routeData: RouteData): bool => {
  if (isPreviewMode(routeData)) {
    return false
  }
  return viewedByBot
    ? false
    : isNonClientCompany(job.company)
      ? isJobUnpublished(job.career_page_status)
      : isJobUnavailable(job)
}

const similarJobsQuery = (job: Job) => {
  return job.title
}

export const similarJobsOptions = ({ job, company, experimentVariant }: SimilarJobsOptionsParams) => {
  const options: $FlowTODO = {
    query: similarJobsQuery(job),
    location: job.location.city,
    exclude_job_uids: [job.id],
    country_code: job.location.country_code,
    ...(company ? { company_subdomain: company.subdomain } : { }),
  }

  if (experimentVariant && experimentVariant !== 'unknown') {
    options.experiment_variant = experimentVariant
  }
  return options
}

export const redirectToSearchPage = (replace: Function, job: Job, locale: Locale, country: Country, highlight: bool = false, queryParams: QueryParams | null = {}) => {
  const builderParams: SearchUrlOptions = {
    location: job?.location?.city,
  }

  if (highlight) {
    builderParams.redirect = { highlight: job.id }
    builderParams.marketing = { ...queryParams }
  } else {
    builderParams.redirect = { unpublishedJob: job.id }
    builderParams.keyword = job.localized_b2c_job_title ?? job.type.localized_name
  }

  const redirectUrl = build(
    builderParams,
    locale,
    country
  )

  const [pathname, search] = redirectUrl.split('?')

  replace({
    pathname,
    search: `?${search}`,
    state: {
      redirectCode: highlight ? 302 : 301,
    },
  })

  if (highlight) {
    highlightedJobLogger.dataCapture('url replaced', { datadogContext: { url: redirectUrl } })
  }
}

export const fireSeeSimilarJobsClickedEvent = (
  contextData: { page_type: ?string }
) => {
  fireSnowplowEvent(SIMILAR_JOBS_CLICKED_EVENT,
    {
      similar_jobs_param_type: 'title',
    },
    [
      {
        key: 'page_context',
        options: {
          pageName: contextData.page_type,
        },
      },
    ]
  )
}
