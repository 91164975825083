import React from 'react'
import Avatar, { AvatarProps } from '@mui/material/Avatar'
import { COLORS } from '../theme'
import styled from '@emotion/styled'
import { BuildingPlaceholder } from '../HAssets/BuildingPlaceholder'
import { HSkeleton } from '../HSkeleton/HSkeleton'

export const PlaceHolderSize = {
  XL: { size: 132, padding: 12 },
  L: { size: 80, padding: 8 },
  M: { size: 56, padding: 6 },
  S: { size: 48, padding: 6 },
  XS: { size: 40, padding: 4 },
}

interface OverrideProps {
  size: keyof typeof PlaceHolderSize
}

export type HPlaceholderProps = OverrideProps &
Omit<AvatarProps, keyof OverrideProps>

const StyledPlaceholder = styled(
  ({ size, ...otherProps }: HPlaceholderProps) => <Avatar {...otherProps} />,
)`
  width: ${({ size }) => PlaceHolderSize[size].size}px;
  height: ${({ size }) => PlaceHolderSize[size].size}px;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid ${COLORS.surface_gray_20};
  padding: ${({ size }) => PlaceHolderSize[size].padding}px;
`

export const HPlaceholder = ({
  size,
  children,
  ...others
}: HPlaceholderProps): JSX.Element => {
  if (!children) {
    children = (
      <BuildingPlaceholder
        width={PlaceHolderSize[size].size}
        height={PlaceHolderSize[size].size}
      />
    )
  }
  const props = {
    size,
    ...others,
    children,
  }
  return <StyledPlaceholder {...props} variant='square' />
}
