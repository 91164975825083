// @flow

import React from 'react'
import Loadable from 'react-loadable'
import { getIndexRoute } from '../../routes'
import * as actionCreators from '../../actions/actionCreators'
import { withClientDataLoader } from '../../components/containers/withDataLoader'
import PageLoader from '../../components/shared/loader'
import { DEFAULT_UNSUBSCRIBE_CONSENT_TYPE } from '../../constants/base'

import type { RouteData } from '../../types/common'
import type { Dispatch } from '../../store/configureStore'
import type { ApplicationState } from '../../types/applicationState'

export const emailUnsubscribeDataLoader = async (
  dispatch: Dispatch,
  state: ApplicationState,
  routeData: RouteData,
  replace: Function
) => {
  // redirect user if no hashed email
  if (!routeData.location.query.email) {
    const redirectUrl = getIndexRoute(
      state.intlData.locale,
      state.locality.country
    )
    replace(redirectUrl)
  } else {
    try {
      const { value: consents } = await dispatch(
        actionCreators.getUserConsentStatusByEmailHash(
          routeData.location.query.email
        )
      )
      const givenConsentType = routeData.location.query.consent_type || DEFAULT_UNSUBSCRIBE_CONSENT_TYPE
      if (
        consents.some(
          ({ consent_type: type, consent_given: value }) =>
            type === givenConsentType && value === false
        )
      ) {
        dispatch(actionCreators.userAlreadyUnsubscribed())
      }

      await dispatch(actionCreators.unsubscribeEmail(routeData.location.query.email, { consent_type: givenConsentType, consent_value: false }))
    } catch (e) {
      // if user is not found, a 404 is returned. We will, however, display error
      // message to user for all errors within the data loader
      dispatch(actionCreators.errorUnsubscribing())
    }
  }
}

const LoadableEmailUnsubscribe = Loadable<$FlowTODO, $FlowTODO>({
  loader: () => import('./EmailUnsubscribe'),
  loading: () => <PageLoader />,
})

const EmailUnsubscribe = withClientDataLoader(emailUnsubscribeDataLoader)(LoadableEmailUnsubscribe)

export default EmailUnsubscribe
