import { LOCATION_CHANGE as REACT_ROUTER_REDUX_LOCATION_CHANGE_ACTION } from 'connected-react-router'

export const cleanOnLocationChange = (reducer) => (state, action) => {
  return reducer(
    action.type === REACT_ROUTER_REDUX_LOCATION_CHANGE_ACTION && !action.payload?.isFirstRendering
      ? undefined // when state is undefined, all reducers return initial state
      : state,
    action
  )
}
