// @flow

import React from 'react'
import Loadable from 'react-loadable'
import { getRoutesForLocale } from '../../routes'
import { PageLoader } from '../../components/shared/loader'
import compose from 'lodash/flowRight'

import { withClientDataLoader } from '../../components/containers/withDataLoader'
import { redirectIfUnauthenticated } from '../../routing/redirectIfUnauthenticated'
import { getApplicationDetails } from '../../actions/actionCreators'
import type { RouteData } from '../../types/common'
import type { Dispatch } from '../../store/configureStore'
import type { ApplicationState } from '../../types/applicationState'
import { fetchCurrentJob } from '../../actions/job'

export const applicationDetailDataLoader = async (
  dispatch: Dispatch,
  state: ApplicationState,
  routeData: RouteData,
  replace: Function
) => {
  const isAuthenticated = state.user.isAuthenticated
  const { locale } = state.intlData
  const { country } = state.locality
  const routes = getRoutesForLocale(locale)

  if (!isAuthenticated) {
    replace(routes.country(country).index)
  }

  const currentJobId = state.currentJob.job?.id

  if (currentJobId) {
    await dispatch(fetchCurrentJob(currentJobId))
  }

  try {
    const { value: application } = await dispatch(
      getApplicationDetails(routeData.params.id)
    )

    if (application.status === 'smart_link_out') {
      replace(routes.country(state.locality.country).applications)
    }
  } catch (e) {
    return replace(routes.country(state.locality.country).applications)
  }
}

const LoadableApplicationDetails = Loadable<{}, {}>({
  loader: () => import('./ApplicationDetails'),
  loading: () => <PageLoader />,
})

const ApplicationDetailsPage = compose(
  withClientDataLoader(applicationDetailDataLoader),
  redirectIfUnauthenticated
)(LoadableApplicationDetails)

export default ApplicationDetailsPage
