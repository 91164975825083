/* istanbul ignore file */

import React from 'react'
import { useDispatch } from 'react-redux'
import { HBox, HButton, HDivider } from 'talent-ui'
import { useAppSelector } from '../../hooks/useAppSelector'
import { getParsedCvThunk } from '../../store/parsedCv/parsedCvThunk'

export const DebugFetchParsedCvButton = () => {
  const { cv } = useAppSelector((state) => ({
    cv: state.documents.applicationCv.file,
  }))

  const dispatch = useDispatch()

  const fetchParsedCv = () => {
    // @ts-expect-error cv typed wrongly
    dispatch(getParsedCvThunk(cv?.id))
  }

  return (
    <>
      <HBox
        py={2}
        display='flex'
        justifyContent='center'
      >
        <HButton
          onClick={() => { fetchParsedCv() }}
          fullWidth
          variant='text'
          disabled={!cv}
        >
          Fetch parsed cv
        </HButton>
      </HBox>
      <HDivider />
    </>
  )
}
