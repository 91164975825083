import { createSlice } from '@reduxjs/toolkit'

import { getOnboardingThunk, saveOnBoardingJobTitles, saveOnboardingThunk } from './onboardingThunk'
import { OnboardingFetchResponse, StepsOrder } from 'onboarding-flow'

export type ApiErrors = {
  code: string;
  messages: string[]
}

export type OnboardingThunkError = {
  errors: ApiErrors[];
  status: number;
}

export type OnBoardingModalState = {
  visible: boolean,
  initialStep: number,
  totalStepCount: number,
  isReEntry: boolean,
  stepsData: OnboardingFetchResponse,
  isLoading: boolean,
  fetchedData: boolean,
  error: OnboardingThunkError | null | undefined
}

const initialState: OnBoardingModalState = {
  visible: false,
  initialStep: 0,
  isReEntry: false,
  fetchedData: false,
  totalStepCount: StepsOrder.length,
  stepsData: {
    has_onboarding_steps_status: undefined as never,
    steps: {
      location: {
        status: null as never,
        value: [],
      },
      working_hours_type: {
        status: null as never,
        value: undefined as never,
      },
      minimum_salary: {
        status: null as never,
        value: {
          salary_value: '',
          salary_period: 'hour',
        },
      },
      employment_type: {
        status: null as never,
        value: [],
      },
      job_title: {
        status: null as never,
        value: [],
      },
    },
    userId: '',
  },
  isLoading: false,
  error: null,
}

const OnBoardingModalSlice = createSlice({
  name: 'onBoardingModal',
  initialState,
  reducers: {
    showOnBoardingModal: (state) => {
      state.visible = true
    },
    hideOnBoardingModal: (state) => {
      state.visible = false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOnboardingThunk.fulfilled, (state, action) => {
      state.isLoading = false
      state.stepsData = action.payload
      state.visible = true

      const firstExitedStep = StepsOrder.findIndex((step) => step !== 'success' && (state.stepsData.steps[step].status === 'exited' || !state.stepsData.steps[step].status))
      const initialIndex = firstExitedStep > -1 ? firstExitedStep : 0

      state.initialStep = initialIndex
      state.totalStepCount = StepsOrder.length - initialIndex

      state.isReEntry = action.payload.has_onboarding_steps_status
      state.fetchedData = true
    })

    builder.addCase(getOnboardingThunk.pending, (state) => {
      state.isLoading = true
    })

    builder.addCase(getOnboardingThunk.rejected, (state, action) => {
      const error = action.payload as OnboardingThunkError
      const errorStatus = error.status

      state.isLoading = false
      state.stepsData = initialState.stepsData
      state.isReEntry = false
      state.initialStep = 0

      state.error = { ...state.error, errors: error.errors, status: errorStatus }

      // TODO(TPRE-1491): This is kept for backwards compatibility with the previous endpoint, can be removed once TPRE-1491 is done
      if (errorStatus === 404) {
        state.fetchedData = true
        state.visible = true
      }
    })

    builder.addCase(saveOnboardingThunk.fulfilled, (state, action) => {
      state.isLoading = false

      const newState = {
        ...state.stepsData.steps,
        [action.meta.arg.step_name]: {
          status: action.meta.arg.step_action,
          value: action.meta.arg.step_action === 'filled'
            ? action.meta.arg.step_value
            : state.stepsData.steps[action.meta.arg.step_name].value,
        },
      }

      state.stepsData.steps = newState
    })

    builder.addCase(saveOnboardingThunk.pending, (state) => {
      state.isLoading = true
    })

    builder.addCase(saveOnboardingThunk.rejected, (state, action) => {
      const error = action.payload as OnboardingThunkError

      state.isLoading = false
      state.stepsData = initialState.stepsData
      state.error = { ...state.error, errors: error.errors, status: error.status }
    })

    builder.addCase(saveOnBoardingJobTitles.fulfilled, (state, action) => {
      return {
        ...state,
        isLoading: false,
        stepsData: {
          ...state.stepsData,
          steps: {
            ...state.stepsData.steps,
            job_title: {
              value: action.meta.arg.step_value,
              status: 'filled',
            },
          },
        },
      }
    })

    builder.addCase(saveOnBoardingJobTitles.pending, (state) => {
      state.isLoading = true
    })

    builder.addCase(saveOnBoardingJobTitles.rejected, (state, action) => {
      const error = action.payload as OnboardingThunkError

      state.isLoading = false
      state.stepsData = initialState.stepsData
      state.error = { ...state.error, errors: error.errors, status: error.status }
    })
  },
})

export const { showOnBoardingModal, hideOnBoardingModal } = OnBoardingModalSlice.actions

export default OnBoardingModalSlice.reducer
