import React from 'react'
import { HSvgIcon, HSvgIconProps } from '../HSvgIcon'

export const HIconPasswordResetEmailSent: React.FC<HSvgIconProps> = (props) => (
  <HSvgIcon viewBox="0 0 116 112" fill="none" {...props}>
    <g clipPath="url(#clip0_2585_32220)">
      <path d="M57.547 1.03125V94.6018C65.6737 91.25 94.0965 77.9977 94.0965 56.9075V9.16827C94.0965 7.85851 93.1477 6.75501 91.8586 6.53843L57.547 1.03125Z" fill="#E6EFFF"/>
      <path d="M57.5469 95.6334C57.4128 95.6334 57.2788 95.6025 57.155 95.5509C48.4302 91.9517 19.9661 78.5137 19.9661 56.9078V31.8574C19.9661 31.2901 20.4302 30.8261 20.9974 30.8261C21.5646 30.8261 22.0287 31.2901 22.0287 31.8574V56.9078C22.0287 65.5708 27.0202 73.8728 36.8589 81.5973C44.7278 87.7645 53.6795 91.8691 57.5469 93.4883C61.4143 91.8691 70.3661 87.7645 78.2349 81.5973C88.0736 73.8831 93.0651 65.5708 93.0651 56.9078V9.1686C93.0651 8.36418 92.4876 7.68352 91.6935 7.55976L57.5469 2.0732L23.4003 7.55976C22.6062 7.68352 22.0287 8.36418 22.0287 9.1686V15.903C22.0287 16.4703 21.5646 16.9343 20.9974 16.9343C20.4302 16.9343 19.9661 16.4703 19.9661 15.903V9.1686C19.9661 7.34318 21.2758 5.81685 23.0703 5.52808L57.3819 0.0105868C57.485 -0.0100394 57.5985 -0.0100394 57.7119 0.0105868L92.0235 5.51777C93.8283 5.80654 95.1278 7.34318 95.1278 9.1686V56.9078C95.1278 78.5137 66.6637 91.9517 57.9388 95.5509C57.8151 95.6025 57.681 95.6334 57.5469 95.6334Z" fill="#4C526B"/>
      <path d="M79.4519 90.3629C70.8096 90.3629 63.7864 83.3294 63.7864 74.6974C63.7864 66.0653 70.8199 59.0215 79.4519 59.0215C88.084 59.0215 95.1278 66.055 95.1278 74.6974C95.1278 83.3397 88.0943 90.3629 79.4519 90.3629Z" fill="#C84182"/>
      <path d="M79.452 60.0528C87.5375 60.0528 94.0863 66.6017 94.0863 74.6871C94.0863 82.7726 87.5271 89.3214 79.452 89.3214C71.3769 89.3214 64.8177 82.7726 64.8177 74.6871C64.8177 66.6017 71.3769 60.0528 79.452 60.0528ZM79.452 57.9902C70.2424 57.9902 62.7551 65.4878 62.7551 74.6871C62.7551 83.8864 70.2424 91.384 79.452 91.384C88.6616 91.384 96.1489 83.8967 96.1489 74.6871C96.1489 65.4775 88.6513 57.9902 79.452 57.9902Z" fill="#4C526B"/>
      <path d="M76.0796 80.8953C75.6774 80.8953 75.2752 80.7406 74.9761 80.4312L71.9028 77.2857C71.3046 76.6772 71.315 75.6975 71.9337 75.0993C72.5422 74.5012 73.522 74.5115 74.1201 75.1303L76.1209 77.1826L84.8251 68.9115C85.4439 68.3236 86.4236 68.3443 87.0115 68.9631C87.5993 69.5818 87.5787 70.5616 86.9599 71.1494L77.1522 80.4724C76.8531 80.7612 76.4715 80.8953 76.0899 80.8953H76.0796Z" fill="white"/>
      <path d="M64.9518 112H1.03131C0.464088 112 0 111.536 0 110.969C0 110.402 0.464088 109.938 1.03131 109.938H64.9518C65.519 109.938 65.9831 110.402 65.9831 110.969C65.9831 111.536 65.519 112 64.9518 112Z" fill="#4C526B"/>
      <path d="M114.052 112H88.3625C87.7953 112 87.3312 111.536 87.3312 110.969C87.3312 110.402 87.7953 109.938 88.3625 109.938H114.052C114.62 109.938 115.084 110.402 115.084 110.969C115.084 111.536 114.62 112 114.052 112Z" fill="#4C526B"/>
      <path d="M83.2781 112H71.5316C70.9643 112 70.5002 111.536 70.5002 110.969C70.5002 110.402 70.9643 109.938 71.5316 109.938H83.2781C83.8454 109.938 84.3095 110.402 84.3095 110.969C84.3095 111.536 83.8454 112 83.2781 112Z" fill="#4C526B"/>
      <path d="M20.9974 26.5767C20.4302 26.5767 19.9661 26.1126 19.9661 25.5454V22.2452C19.9661 21.678 20.4302 21.2139 20.9974 21.2139C21.5646 21.2139 22.0287 21.678 22.0287 22.2452V25.5454C22.0287 26.1126 21.5646 26.5767 20.9974 26.5767Z" fill="#4C526B"/>
    </g>
    <defs>
      <clipPath id="clip0_2585_32220">
        <rect width="115.084" height="112" fill="white"/>
      </clipPath>
    </defs>
  </HSvgIcon>
)
