// @flow

import get from 'lodash/get'
import * as actions from '../constants/actions'

import type { RouteLocation, QueryParams } from '../types/common'
import type { PrefixedRouteName } from '../routing/routeNamePrefix'

export type RoutingState = {
  clientRoute: ?string,
  location: RouteLocation,
  params: QueryParams,
  route: {
    name: ?PrefixedRouteName,
    routePathFromRoutesMap: ?string
  },
  serverRoute: ?string
}

const initialState: RoutingState = {
  serverRoute: null,
  clientRoute: null,
  params: {},
  location: {
    pathname: '',
    query: {},
    search: '',
  },
  route: {
    name: null,
    options: {},
    routePathFromRoutesMap: null,
  },
}

const reducer = (
  state: RoutingState = initialState,
  action: Object
): RoutingState => {
  switch (action.type) {
    case actions.ON_ROUTE_TRANSITION:
      return {
        ...state,
        serverRoute: get(action, 'data.serverRoute', state.serverRoute),
        clientRoute: get(action, 'data.clientRoute', state.clientRoute),
      }

    case actions.ON_ROUTE_DATA_SYNC:
      return {
        ...state,
        ...action.data,
      }

    default:
      return state
  }
}

export default reducer
