import { withStyles } from '@mui/styles'
import Tooltip, { TooltipProps } from '@mui/material/Tooltip'
import React, { PropsWithChildren } from 'react'
import { HTypography } from '../HTypography'
import { COLORS, globalStyles } from '../theme'

const TooltipComp: React.FC<PropsWithChildren<TooltipProps>> = ({
  title,
  arrow = true,
  ...others
}: TooltipProps) => {
  return (
    <Tooltip
      {...others}
      arrow
      title={
        <HTypography variant='caption' color='surface_gray_90' weight='regular'>
          {title}
        </HTypography>
      }
    />
  )
}

export const HTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: COLORS.surface_white,
    borderRadius: 4,
    padding: 8,
  },
  tooltipPlacementLeft: {
    boxShadow: globalStyles.shadow4.boxShadow,
  },
  tooltipPlacementRight: {
    boxShadow: globalStyles.shadow4.boxShadow,
  },
  tooltipPlacementTop: {
    boxShadow: globalStyles.shadow4.boxShadow,
  },
  tooltipPlacementBottom: {
    boxShadow: globalStyles.shadow4Inverted.boxShadow,
  },
  arrow: {
    color: COLORS.surface_white,
  },
  popperArrow: {
    '&[x-placement*="top-start"] $arrow': {
      left: '8px !important',
    },
    '&[x-placement*="bottom-start"] $arrow': {
      left: '8px !important',
    },
    '&[x-placement*="right-start"] $arrow': {
      top: '4px !important',
    },
    '&[x-placement*="left-start"] $arrow': {
      top: '4px !important',
    },

    '&[x-placement*="top-end"] $arrow': {
      left: 'unset !important',
      right: '8px !important',
    },
    '&[x-placement*="bottom-end"] $arrow': {
      left: 'unset !important',
      right: '8px !important',
    },
    '&[x-placement*="right-end"] $arrow': {
      top: 'unset !important',
      bottom: '4px !important',
    },
    '&[x-placement*="left-end"] $arrow': {
      top: 'unset !important',
      bottom: '4px !important',
    },
  },
}))(TooltipComp)
