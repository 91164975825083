// @flow
import cookies from '../cookies'
import abTestingDefinitions from './abTestingDefinitions'
import type { AbTestingDefinition, AssignmentData } from './abTestingTypes'
import { Planout, type PlanoutAssignmentArgs } from './abTestingComponents/abTestingGetUserGroup'

import type { Cookies } from 'react-cookie'

type AssignmentFromCookie = AssignmentData

const EXPERIMENT_ID_SEPARATOR = ','

const getStoredAssignments = (cookieJar) => cookies.load(cookieJar)(cookies.identifiers.AB_TESTING_COOKIE) || ''

export const storeExperimentIDInCookie = (cookieJar: Cookies) => (
  experimentID: string,
  host: string
): void => {
  const previousValue = getStoredAssignments(cookieJar)

  if (previousValue.split(',').includes(`${experimentID}`)) {
    return
  }

  const value = previousValue === ''
    ? `${experimentID}`
    : `${previousValue}${EXPERIMENT_ID_SEPARATOR}${experimentID}`

  cookies.wide.save(cookieJar)(cookies.identifiers.AB_TESTING_COOKIE, value, {
    path: '/',
    expires: new Date(Date.now() + 360 * 24 * 60 * 60 * 1000),
    domain: cookies.globalize(host),
  })
}

export const getExperimentsAssignmentsFromCookies = (cookieJar: Cookies) => (
  trackingToken: string,
  definitions: Array<AbTestingDefinition> = abTestingDefinitions
): Array<AssignmentFromCookie> => {
  const value = getStoredAssignments(cookieJar)
  const experimentIds = value.split(EXPERIMENT_ID_SEPARATOR)
  const assignments = []

  experimentIds.forEach((experimentId) => {
    const experimentDefinition = definitions.find((definition) =>
      definition.experimentID === experimentId)

    if (experimentDefinition) {
      const { experimentVariants, experimentName } = experimentDefinition
      const planoutArgs: PlanoutAssignmentArgs = {
        experimentName,
        trackingToken,
        variants: experimentVariants,
      }
      const userGroup = new Planout(planoutArgs)

      assignments.push({
        name: experimentName,
        group_name: userGroup.get(experimentName),
      })
    }
  })

  return assignments
}
