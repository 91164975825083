import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import {
  HBox,
  HButton,
  HCard,
  HInputPassword,
  HNotification,
  HTypography,
} from 'talent-ui'
import {
  showSnackBar,
  showTermsAndConditionsModal,
  confirmEmailUnauthorized,
  updateCurrentUser,
  removeQueryParam,
} from '../../actions/actionCreators'
import {
  cognitoConfirmForgotPassword,
  cognitoForgotPassword,
  cognitoSignIn,
} from '../../actions/user/cognito'
import { getRoutesForLocale } from '../../routes'
import { ApplicationState } from '../../types/applicationState'
import {
  ErrorType,
  ResetPasswordProps,
  ResetPasswordState,
  RouteParams,
} from './ResetPassword.types'
import logger from '../../../logging'
import validateSameOriginRedirect from '../../helpers/url/validateSameOriginRedirect'

const Wrapper = styled(HCard)`
  border: none;
  margin-top: 0px;
  width: 100%;

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-top: 96px;
    width: 444px;
  }
`
const log = logger('reset-password')

export const ResetPassword: React.FC<ResetPasswordProps> = ({
  intl,
  location,
}) => {
  const { code } = useParams<RouteParams>()
  const { email, resetType, redirectUrl, authOrigin, confirmationToken } = location.query

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<ResetPasswordState>()
  const dispatch = useDispatch()
  const history = useHistory()
  const [loading, setLoading] = React.useState(false)
  const [failure, setFailure] = React.useState<false | 'limit' | 'expired'>(
    false
  )

  useEffect(() => {
    const confirmUser = async () => {
      try {
        await dispatch(confirmEmailUnauthorized(confirmationToken))

        dispatch(removeQueryParam(history, 'confirmationToken'))
      } catch (err: any) {
        log.error(err?.message, { error: JSON.stringify(err) })
      }
    }

    if (confirmationToken) {
      confirmUser()
    }

    if (!email) {
      handleRedirect()

      log.info(
        'email is not provided',
        {
          email,
          redirectUrl,
        }
      )
    }
  }, [confirmationToken, email])

  const newPassword = register('newPassword', {
    required: intl.formatMessage({ id: 'cognito_new_password_required' }),
    minLength: {
      value: 8,
      message: intl.formatMessage({ id: 'cognito_password_invalid' }),
    },
  })

  const { country, locale } = useSelector(
    (state: ApplicationState) => {
      return {
        country: state.locality.country,
        locale: state.intlData.locale,
      }
    }
  )

  const handleError = (err: any) => {
    if (
      err.code === ErrorType.ExpiredCode ||
      err.code === ErrorType.CodeMismatch
    ) {
      setFailure('expired')
      // automatically restart forgot password flow
      dispatch(cognitoForgotPassword({
        email,
        clientMetaData: {
          resetType,
          authOrigin,
          redirectUrl: validateSameOriginRedirect(redirectUrl),
        },
      }))
    } else if (err.code === ErrorType.LimitExceeded) {
      setFailure('limit')
    } else {
      throw err
    }
  }

  const handleRedirect = () => {
    const url = validateSameOriginRedirect(redirectUrl)

    if (url) {
      history.replace(url)
    } else {
      history.replace(getRoutesForLocale(locale).country(country).index)
    }
  }

  const onSubmit = async ({ newPassword }: ResetPasswordState) => {
    try {
      setLoading(true)
      await dispatch(cognitoConfirmForgotPassword({ email: email, verificationCode: code, newPassword }))
      const response:any = await dispatch(cognitoSignIn({ email, password: newPassword, authOrigin }))

      if (!response.value.has_password || !response.value.confirmed) {
        await dispatch(updateCurrentUser(response.value.id, { has_password: true, cognito_flow: 'set_password' }))
      }

      handleRedirect()

      !response.value.consented_terms_and_conditions
        ? dispatch(showTermsAndConditionsModal())
        : dispatch(showSnackBar('login'))
    } catch (err) {
      setLoading(false)
      handleError(err)
    }
  }

  return (
    <HBox
      display='flex'
      justifyContent='center'
      pt={17}
    >
      <Wrapper padding='large'>
        <HBox py={10}>
          <HBox mb={4}>
            <HTypography
              variant='h6'
              weight='semibold'
              color='surface_gray_90'
            >
              <FormattedMessage id='cognito_reset_password_create_new_password' />
            </HTypography>
          </HBox>
          <HBox mb={6}>
            <HTypography
              variant='body2'
              weight='regular'
              color='surface_gray_60'
              data-test-id='reset-password-descp'
              selectors={[
                {
                  selector: 'span span',
                  props: {
                    weight: 'semibold',
                  },
                },
              ]}
            >
              <FormattedMessage
                id='cognito_reset_password_set_password_for_email'
                values={{ email }}
              />
            </HTypography>
          </HBox>
          <HBox>
            <HInputPassword
              label={intl.formatMessage({ id: 'cognito_new_password' })}
              fullWidth
              {...newPassword}
              error={!!errors.newPassword}
              caption={
                errors.newPassword
                  ? errors.newPassword.message?.toString()
                  : intl.formatMessage({ id: 'cognito_password_invalid' })
              }
              data-test-id='new-password'
              autoComplete='new-password'
              disabled={!!failure}
            />
          </HBox>
          {failure && (
            <HBox mt={4}>
              <HNotification
                variant='contained'
                color='error'
                text={intl.formatMessage({
                  id:
                    failure === 'expired'
                      ? 'cognito_reset_password_code_expired'
                      : 'cognito_password_retry_limit',
                })}
              />
            </HBox>
          )}
          <HBox mt={6}>
            <HButton
              variant='contained'
              color='primary'
              fullWidth
              onClick={handleSubmit(onSubmit)}
              data-test-id='confirm-button'
              disabled={!!failure || loading}
              loading={loading}
            >
              <FormattedMessage id='cognito_confirm_password_change' />
            </HButton>
          </HBox>
        </HBox>
      </Wrapper>
    </HBox>
  )
}
