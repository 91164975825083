// @flow

import API from '../clientBuilder'
import type { APIRequestConfig } from '../types'

export type DeviceOptions = {|
  locale?: string,
  remote_addr?: string,
  user_agent?: string
|}

type Device = {|
  token: string,
  tracking_token: string
|}

export default {
  current: (config: APIRequestConfig): Promise<Device> => API.get.promise('/v1/devices/current', null, config),
  create: (options: DeviceOptions, config: APIRequestConfig): Promise<Device> => API.post.promise('/v1/devices', options, config),
}
