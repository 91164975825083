import React from 'react'
import { HBox, HTypography, HCircularProgress, COLORS, HButton } from 'talent-ui'
import { useTranslate } from '../../hooks/use-translate'
import { type ProfileCompletionStatusProps } from './profile-completion-status'

export type CompletionProgressProps = {
  percent: number
  withCtaButton?: boolean
} & Pick<ProfileCompletionStatusProps, 'fireStructuredTrackingEvent' | 'LinkComponent' | 'routerData'>

const clampBetweenZeroAndHundred = (raw: number) => {
  return Math.min(Math.max(raw, 0), 100)
}

const PercentageIndicator = ({ displayPercent }: { displayPercent: number }) => {
  const indicatorSize = 64
  const lineWidth = 5
  const maskCircleSize = indicatorSize - (2 * lineWidth)

  return (
    <HBox
      position='relative'
      textAlign='center'
      minWidth={indicatorSize}
      minHeight={indicatorSize}
      display='flex'
      data-test-id='profile-completion-indicator'
    >
      <HBox
        position='absolute'
        left={0}
        top={0}
        width={indicatorSize}
        height={indicatorSize}
        sx={{
          backgroundColor: COLORS.surface_gray_10,
          borderRadius: indicatorSize,
        }}
      >
        <HCircularProgress
          value={displayPercent}
          variant='determinate'
          size={indicatorSize}
        />
        {/* HACK: Inner circle to mask out the background colour as CircularProgress doesn't
          allow a second colour for the 'remaining' portion of the circle */}
        <HBox
          sx={{
            position: 'absolute',
            left: lineWidth,
            top: lineWidth,
            width: maskCircleSize,
            height: maskCircleSize,
            background: COLORS.primary_05,
            borderRadius: indicatorSize,
          }}
        />
      </HBox>
      <HBox
        width='100%'
        alignSelf='center'
        // Needs to position above the progress circle which has absolute position
        zIndex={0}
      >
        <HTypography
          variant='body1'
          weight='medium'
          color='surface_gray_80'
          align='center'
          translate='no'
        >
          {Math.round(displayPercent)}%
        </HTypography>
      </HBox>
    </HBox>
  )
}

const CallToAction = ({ withCtaButton }: { withCtaButton?: boolean }) => {
  const translate = useTranslate()

  return (
    <HBox
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 2,
        alignSelf: 'stretch',
      }}
    >
      <HTypography
        xs={{ variant: 'h5' }}
        lg={{ variant: withCtaButton ? 'h4' : 'h5' }}
        weight='medium'
        color='primary_50'
      >
        {translate('complete_your_profile_in_progress_cta_title')}
      </HTypography>

      <HTypography
        variant='body1'
        weight='regular'
        color='surface_gray_90'
      >
        {translate('complete_your_profile_in_progress_cta_body')}
      </HTypography>
    </HBox>
  )
}

const CallToActionButton = (props: Pick<ProfileCompletionStatusProps, 'fireStructuredTrackingEvent' | 'LinkComponent' | 'routerData'>) => {
  const translate = useTranslate()
  const { locale, country } = props.routerData

  // FIXME: remove JSP dependency on this component once we fully migrated to Next.js
  const profileRoute = `/${locale}-${country}/profile`

  return (
    <HBox
      alignSelf={{ xs: 'flex-end', lg: 'center' }}
      width={{ xs: '100%', lg: 'auto' }}
    >
      <props.LinkComponent
        to={profileRoute}
        href={profileRoute}
        onClick={() => {
          props.fireStructuredTrackingEvent({
            category: 'tp_page_action',
            action: 'finish_profile_click',
            label: 'complete_your_profile_cta_button',
          })
        }}
      >
        <HButton
          variant='outlined'
          fullWidth
        >
          {translate('complete_your_profile_cta_button')}
        </HButton>
      </props.LinkComponent>
    </HBox>
  )
}

export const CompletionProgress = ({
  percent,
  withCtaButton,
  fireStructuredTrackingEvent,
  LinkComponent,
  routerData,
}: CompletionProgressProps) => {
  const displayPercent = clampBetweenZeroAndHundred(percent)

  return (
    <HBox
      sx={{
        borderRadius: 1,
        background: COLORS.primary_05,
        display: 'flex',
        padding: withCtaButton ? 6 : 4,
        gap: 4,
        alignItems: 'flex-start',
        flexDirection: {
          xs: withCtaButton ? 'column' : 'row',
          md: 'column',
          lg: 'row',
        },
      }}
    >
      <HBox
        display='flex'
        alignItems='flex-start'
        flexDirection={{
          xs: 'row',
          lg: withCtaButton ? 'row' : 'column',
        }}
        mr='auto'
      >
        <HBox
          mr={4}
          mb={withCtaButton ? 0 : 4}
        >
          <PercentageIndicator displayPercent={displayPercent} />
        </HBox>
        <CallToAction withCtaButton={withCtaButton} />
      </HBox>
      {withCtaButton &&
        <CallToActionButton
          fireStructuredTrackingEvent={fireStructuredTrackingEvent}
          LinkComponent={LinkComponent}
          routerData={routerData}
        />}
    </HBox>
  )
}
