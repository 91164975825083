import styled from '@emotion/styled'
import { HBox } from '../../HBox'
import { HTypography } from '../../HTypography'
import { COLORS } from '../../theme'
import React from 'react'

export const SlimFooterBox = styled(HBox)`
  background-color: ${COLORS.surface_off_white_001};
  padding-top: 8px;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding-top: 16px;
  }
`

export const StyledSlimFooter = styled.ul`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 8px;
  column-gap: 16px;
  color: ${COLORS.surface_gray_60};
  li:hover{
    color: ${COLORS.surface_gray_60};
  }
  ${({ theme }) => theme.breakpoints.up('sm')} {
    row-gap: 16px;
    column-gap: 24px;
  }
`

export const TypographyLink = styled(HTypography)`
  cursor: pointer;
  display: flex;
  align-items: center;
  :hover {
    text-decoration: underline;
  }
`
