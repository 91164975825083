import clsx from 'clsx'
import React, { PropsWithChildren } from 'react'
import { HButton, IHButtonProps } from '../HButton/Button'
import styled from '@emotion/styled'
import { COLORS } from '../theme'

interface HIconButtonProps extends IHButtonProps {
  selected?: boolean
}

export type IHIconButtonProps = HIconButtonProps

const StyledIconButton = styled(HButton)`
  width: 32px;
  height: 32px;
  padding: 0;
  &.selected {
    background-color: ${COLORS.surface_gray_10}
  }
  svg {
    display: block;
  }
`

export const HIconButton: React.FC<PropsWithChildren<IHIconButtonProps>> = ({
  variant,
  color,
  disabled,
  children,
  selected,
  ...others
}) => {
  const className = clsx(
    others.className,
    selected && 'selected',
  )
  return (
    <span>
      <StyledIconButton
        color={color}
        disableElevation
        disabled={disabled}
        variant={variant}
        disableTouchRipple
        {...others}
        className={className}
      >
        {children}
      </StyledIconButton>
    </span>
  )
}
