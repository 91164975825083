// @flow

import * as actions from '../constants/actions'
import type { AbTestingDefinition } from '../abTestingOld/abTestingTypes'

export type ABTestingState = {
  assignments: { [string]: {
    group_name: string,
    source?: string
  } },
  definitions: AbTestingDefinition[],
  // FIXME: Create Type for growthbook
  growthbook: any,
}

const initialState: ABTestingState = {
  assignments: {},
  definitions: [],
  growthbook: {},
}

export default (state: ABTestingState = initialState, action: $FlowTODO) => {
  switch (action.type) {
    case actions.INITIALIZE_AB_TESTS: {
      return {
        ...state,
        assignments: {
          ...state.assignments,
          [action.activeTest.name]: {
            group_name: action.activeTest.group_name,
            source: action.activeTest.source,
          },
        },
      }
    }
    case actions.INITIALIZE_AB_TESTS_DEFINITIONS:
      return {
        ...state,
        definitions: action.definitions,
      }
    case actions.INITIALIZE_GROWTHBOOK_DEFINITIONS:
      return {
        ...state,
        growthbook: action.definitions,
      }
    default:
      return state
  }
}
