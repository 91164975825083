// @flow
import * as React from 'react'
import styled, { keyframes, type StyledComponent } from 'styled-components'
import { ReactComponent as SearchLoaderImage } from 'assets/images/search/search-loader.svg'
import { $colors } from './dependencies/variables'
import { AnimatedThreeDotsLoader } from './icons'

type StyledPulsingCircleProps = {
  pulseStyle?: Object
}

type LoaderProps = {
  isSearchLoader?: bool,
  pulseStyle?: Object,
  style?: Object
}

type PageLoaderProps = {
  isSearchLoader?: bool,
}

const Container: StyledComponent<{}, *, *> = styled.div`
  height: 2000px;
`

const LoaderContainer: StyledComponent<{}, *, *> = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.4);
  z-index: 10;
`

const pulseKeyframes = keyframes`
  0% {
    transform: scale(.1);
    opacity: 0;
  }
  50% {
    opacity: .7;
  }
  100% {
    transform: scale(1.0);
    opacity: 0;
  }
`

export const PulsingCircle: StyledComponent<{ }, *, *> = styled.div`
  background-color: ${$colors.secondary[100]};
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${pulseKeyframes} 1.5s infinite;
  margin: auto auto;
`

const AbsolutePulsingCircle: StyledComponent<{}, *, *> = styled(PulsingCircle)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

const StyledPulsingCircle = (props: StyledPulsingCircleProps) => (
  <AbsolutePulsingCircle
    style={props.pulseStyle || {}}
  />
)

export const Loader = (props: LoaderProps) => (
  <LoaderContainer
    style={props.style || {}}
    data-test-id='loader-con'
  >
    <StyledPulsingCircle {...props} />
  </LoaderContainer>
)

const PulsingDotsLoaderContainer: StyledComponent<{}, *, *> = styled.div`
  width: 100px;
  margin: 0 auto;
`

const ComponentLoaderContainer: StyledComponent<{}, *, *> = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  min-height: 200px;
`

const PageContentLoaderContainer: StyledComponent<{}, *, *> = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  min-height: 500px;
`

export const ComponentLoader = (props: LoaderProps) => (
  <ComponentLoaderContainer
    data-test-id='component_loader'
    style={props.style || {}}
  >
    {props.isSearchLoader ? <SearchLoaderImage /> : <StyledPulsingCircle {...props} />}
  </ComponentLoaderContainer>
)

export const PageLoader = (props: PageLoaderProps) => (
  <Container data-test-id='page_loader'>
    {props.isSearchLoader ? <SearchLoaderImage /> : <Loader />}
  </Container>
)

export const withLoader = (Component: any) => (props: Object) =>
  props.loading ? <PageLoader /> : <Component {...props} />

export const PageContentLoader = (props: PageLoaderProps) => (
  <PageContentLoaderContainer>
    {props.isSearchLoader ? <SearchLoaderImage /> : <StyledPulsingCircle {...props} />}
  </PageContentLoaderContainer>
)

export const PulsingDotsLoader = () => (
  <PulsingDotsLoaderContainer>
    <AnimatedThreeDotsLoader />
  </PulsingDotsLoaderContainer>
)

export default Loader
