// @flow
import { css } from 'styled-components'
import { $colors, $palette, $fontWeights, $primaryBorderRadius } from '../dependencies/variables'

const isEnabledAndLoadedPrimaryButton = ({ disabled, isLoading, primary }) =>
  !disabled && !isLoading && primary
const isEnabledAndLoadedSecondaryButton = ({ disabled, isLoading, primary }) =>
  !disabled && !isLoading && !primary

// Outline Styles
const defaultOutlineButtonStyles = css`
  color: ${$colors.secondary[100]};
  border: solid 1px ${$colors.secondary[100]};
  background: transparent;

  svg {
    fill: ${$colors.secondary[100]};
  }
`
const disabledOutlineButtonStyles = css`
  border: solid 1px ${$palette.grey[200]};
  cursor: default;
  background: transparent;

  svg {
    fill: ${$palette.grey[200]};
  }
`
export const activeOutlineButtonStyles = css`
  color: ${$colors.secondary[300]};
  border: solid 1px ${$colors.secondary[300]};
  background: transparent;
  box-shadow: 1px 1px 5px 0 #D2D2D2;

  svg {
    /* To override material transition */
    transition: none;
    fill: ${$colors.secondary[300]};
  }
`
const loadingOutlineButtonStyles = css`
  ${activeOutlineButtonStyles}
`

const hoverOutlineButtonStyles = css`
  color: ${$colors.secondary[200]};
  border: solid 1px ${$colors.secondary[200]};
  box-shadow: 1px 1px 10px 0 #D2D2D2;
  background: transparent;

  svg {
    /* To override material transition */
    transition: none;
    fill: ${$colors.secondary[200]};
  }
`
export const outlineAppliedStyles = css`
  ${({ disabled, primary }) => disabled && !primary ? disabledOutlineButtonStyles : ''}
  ${({ isLoading, primary }) => isLoading && !primary ? loadingOutlineButtonStyles : ''}
  ${(props) => isEnabledAndLoadedSecondaryButton(props) ? defaultOutlineButtonStyles : ''}
`

// Primary Styles
const defaultButtonStyles = css`
  color: #ffffff;
  background-color: ${$colors.secondary[100]};

  svg {
    fill: #ffffff;
  }
`
const disabledButtonStyles = css`
  cursor: default;
  background-color: ${$palette.grey[200]};

  svg {
    fill: #ffffff;
  }
`

export const activeButtonStyles = css`
  color: #ffffff;
  box-shadow: 1px 1px 5px 0 #D2D2D2;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: ${$primaryBorderRadius};
    background-color: rgba(0, 0, 0, 0.3);
  }

  > span {
    z-index: 100;
  }

  svg {
    /* To override material transition */
    transition: none;
    fill: #ffffff;
  }
`

const loadingButtonStyles = css`
  ${activeButtonStyles}
  cursor: default;

  svg {
    fill: #ffffff;
  }
`

const hoverButtonStyles = css`
  box-shadow: 1px 1px 10px 0 #D2D2D2;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: ${$primaryBorderRadius};
    background-color: rgba(0, 0, 0, 0.2);
  }

  > span {
    z-index: 100;
  }

  svg {
    /* To override material transition */
    transition: none;
    fill: #ffffff;
  }
`
export const primaryAppliedStyles = css`
  ${({ disabled, primary }) => disabled && primary ? disabledButtonStyles : ''}
  ${({ isLoading, primary }) => isLoading && primary ? loadingButtonStyles : ''}
  ${(props) => isEnabledAndLoadedPrimaryButton(props) ? defaultButtonStyles : ''}
`

// Button Base Styles
export const baseButtonStyles = css`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-weight: ${$fontWeights.bold};
  text-align: center;
  height: 48px;
  line-height: 44px;
  font-size: 16px;
  border-radius: ${$primaryBorderRadius};
  border: none;
`

export const baseButtonHover = css`
  &:hover {
    ${({ primary, disabled, isLoading }) => disabled || isLoading ? '' : primary ? hoverButtonStyles : hoverOutlineButtonStyles}
  }
`
export const baseButtonActive = css`
  &:active  {
    ${({ primary, disabled, isLoading }) => disabled || isLoading ? '' : primary ? activeButtonStyles : activeOutlineButtonStyles}
  }
`
