// @flow

import * as React from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory, matchPath } from 'react-router-dom'
import { withCookies } from 'react-cookie'
import compose from 'lodash/flowRight'
import { HMainFooter } from 'talent-ui/src/HFooter/Main/HMainFooter.tsx'
import { SeoPage } from 'talent-ui/src/HFooter/Main/seo-links.ts'
import { COLORS, HBox } from 'talent-ui'
import { injectIntl } from 'react-intl'
import Badges from '../Badges'
import injectPathr from '../../../components/containers/pathr'
import { AVAILABLE_COUNTRY_OPTIONS, DEFAULT_COUNTRY_OPTION } from '../../../constants/base'
import cookiesConst from '../../../cookies'
import { getOppositeLocaleConfig } from './../LangChange'
import externalPageMap from '../../../helpers/externalPageMap'
import { unPrefixRouteName } from '../../../routing/routeNamePrefix'
import { routeForTargetLocale, routeNames } from '../../../routes'
import config from '../../../../config'
import { setCountry } from '../../../actions/actionCreators'
import { build } from '../../../helpers/url/searchUrl'

const _MainFooter = (props) => {
  const {
    cpathr,
    locale: activeLocale,
    params,
    location,
    country,
    intl,
    cookies,
    isJdp = false,
    job,
  } = props

  const isSearchPage = matchPath(location.pathname, { path: props.cpathr.jobSearch() })
  const routeName = isSearchPage ? routeNames.jobSearchKeyword : routeNames.home
  const oppositeLocale = getOppositeLocaleConfig({ activeLocale, country })
  const countryCookieName = cookiesConst.identifiers.PREFERRED_COUNTRY
  const history = useHistory()
  const dispatch = useDispatch()
  const externalLinks = externalPageMap(activeLocale)

  const availableCountries = []

  Object.entries(AVAILABLE_COUNTRY_OPTIONS).forEach(
    ([countryName, countryCode]) => {
      availableCountries.push({ countryName: intl.formatMessage({ id: countryName.toLowerCase() }), countryCode })
    }
  )

  const isValidCountryCode = (code: string) => {
    return Object.values(AVAILABLE_COUNTRY_OPTIONS).includes(code)
  }

  const handleChange = (event) => {
    const targetCountryCode = isValidCountryCode(event.target.value) ? event.target.value : DEFAULT_COUNTRY_OPTION
    cookies.set(countryCookieName, targetCountryCode)
    history.push(getPathForTargetCountry(targetCountryCode, activeLocale))
    dispatch(setCountry(targetCountryCode))
  }

  const getPathForTargetCountry = (targetCountryCode, locale) => {
    if (isSearchPage) return `/${locale}-${targetCountryCode}`
    const pattern = new RegExp(`/(en|de)-(${Object.values(AVAILABLE_COUNTRY_OPTIONS).join('|')})`)
    return location.pathname.replace(pattern, `/${locale}-${targetCountryCode}`)
  }

  const targetRoute =
    routeForTargetLocale(
      oppositeLocale?.locale || 'en',
      params,
      unPrefixRouteName(routeName),
      location,
      activeLocale,
      country
    ) || cpathr.index

  /**
     * FOR JDP, Breadcrumbs link
     * 1. Main page link (HeyJobs)
     * 2. KldB SERP or SEO SERP URL
     * 3. KldB & Location SERP or SEO & Location SERP URL
     * 4. JDP Title (No link)
     *
     * Example: HeyJobs  > Industriemeister Jobs > Industriemeister Jobs in Chemnitz > Industriemeister Elektrotechnik (m/w/d)
     */
  const breadcrumbProps = React.useMemo(() => {
    if (
      !isJdp ||
      !job?.location ||
      (!job.seo_page_title && !job.localized_b2c_job_title)) {
      return undefined
    }

    const mainTitle = 'HeyJobs' // Ask about translations
    const mainPageUrl = build({ locale: activeLocale },
      activeLocale,
      country,
      false
    )

    const baseTitle = job.seo_page_title ?? job.localized_b2c_job_title

    const jobTitle = `${baseTitle} Jobs`
    const jobUrl = job.seo_page_search_query ?? build({ keyword: job.localized_b2c_job_title }, activeLocale, country, false)

    const jobCityTitle = `${jobTitle} in ${job.location.city}`
    const jobCityUrl = build({ keyword: baseTitle, location: job.location.city }, activeLocale, country, false)

    return {
      breadcrumbs: [
        { label: mainTitle, href: mainPageUrl },
        { label: jobTitle, href: jobUrl, hasNoFollowTag: !job.seo_page_title },
        { label: jobCityTitle, href: jobCityUrl, hasNoFollowTag: !job.seo_page_title },
        { label: job.title },
      ],
    }
  }, [isJdp, job, activeLocale, country])

  const breadcrumbSchema = React.useMemo(
    () =>
      breadcrumbProps?.breadcrumbs
        ? {
            '@context': 'https://schema.org ',
            '@type': 'BreadcrumbList',
            itemListElement: breadcrumbProps.breadcrumbs.map(
              (breadcrumb, index) => ({
                '@type': 'ListItem',
                position: index + 1,
                name: breadcrumb.label,
                item: breadcrumb.href || undefined,
              })
            ),
          }
        : undefined,
    [breadcrumbProps?.breadcrumbs]
  )

  return (
    <>
      {!props.isCareerPage && (
        <Badges byeJobBoardsVariant={!!props.byeJobBoardsVariant} />
      )}

      {breadcrumbSchema && (
        <script
          id='structured-data-markup-jdp'
          type='application/ld+json'
          dangerouslySetInnerHTML={{ __html: JSON.stringify(breadcrumbSchema) }}
        />
      )}
      <HMainFooter
        breadcrumbs={breadcrumbProps}
        popularJobs={{
          title: intl.formatMessage({ id: 'prefilled_search_links_title' }),
          pageSettings: {
            pageType: isJdp ? SeoPage.JDP_SERP : SeoPage.MAIN,
            country,
            locale: activeLocale,
          },
          isVisible: !(props.hidePopularLinks || props.isCareerPage),
        }}
        rightSection={{
          aboutUs: {
            title: intl.formatMessage({ id: 'about_us_links_about_us' }),
            links: {
              imprint: {
                label: intl.formatMessage({ id: 'Button_Imprint' }),
                componentProps: {
                  href: externalLinks.imprint,
                },
              },
              termsAndCondition: {
                label: intl.formatMessage({ id: 'Button_TermsConditions' }),
                componentProps: {
                  href: externalLinks.tc,
                },
              },
              privacyPolicy: {
                label: intl.formatMessage({ id: 'Button_PrivacyPolicy' }),
                componentProps: {
                  href: externalLinks.privacy,
                },
              },
              careerAtHeyjobs: {
                label: intl.formatMessage({ id: 'Button_Jobs' }),
                componentProps: {
                  href: externalLinks.careers,
                },
              },
              careerGuide: {
                label: intl.formatMessage({ id: 'Button_Career_Guide' }),
                componentProps: {
                  href: externalLinks.careerGuide,
                },
              },
            },
          },
          forEmployers: {
            title: intl.formatMessage({ id: 'about_us_links_for_employers' }),
            links: {
              homePage: {
                label: intl.formatMessage({ id: 'Button_Employers' }),
                componentProps: {
                  href: externalLinks.employers,
                },
              },
              complianceAndSecurity: {
                label: intl.formatMessage({ id: 'compliance_and_security' }),
                componentProps: {
                  href: externalLinks.complianceAndSecurity,
                },
              },
              recruiterLogin: {
                label: intl.formatMessage({ id: 'Button_Recruiters' }),
                componentProps: {
                  href: externalLinks.recruiters,
                },
              },
            },
            isVisible: !props.isCareerPage,
          },
        }}
        leftSection={{
          privacySettings: {
            label: intl.formatMessage({ id: 'show_privacy_settings' }),
            onClick: () => {
              window.UC_UI?.showSecondLayer()
            },
            isVisible: !!config.userCentrics.settingId,
          },
          countrySelector: {
            label: intl.formatMessage({ id: 'select_country_menu_label' }),
            value: country,
            onChange: handleChange,
            availableCountries: availableCountries,
            isVisible: !isJdp,
          },
          languageSwitcher: {
            label: oppositeLocale
              ? intl.formatMessage({ id: oppositeLocale.config.changeLangId })
              : '',
            componentProps: {
              href: targetRoute,
            },
            isVisible: !!oppositeLocale,
          },
        }}
      />
      {isJdp && (
        <HBox
          p={10}
          lg={{
            p: 0,
          }}
          bgcolor={COLORS.surface_gray_00}
        />
      )}
    </>
  )
}

const mapState = (state, ownProps) => ({
  locale: state.intlData.locale,
  isCareerPage: !!state.company.current,
  country: state.locality.country,
  job: state.currentJob.job,
})

const MainFooter = compose(
  injectPathr,
  injectIntl,
  connect(mapState)
)(withCookies(_MainFooter))

export default MainFooter
