import { googleTagManager, mouseFlowContext } from '../../../server/scripts'

type ScriptTag = {|
  async?: boolean,
  innerHTML?: string,
  src?: string,
  strategy?: string,
  type?: string
|}

export const baseScripts = (props): ScriptTag[] => {
  const scripts = []

  if (props.hasGtmConsent) {
    scripts.push({
      type: 'text/javascript',
      innerHTML: googleTagManager,
    })

    if (props.growthbook?.evalFeature('ux_mouseflow').on) {
      const features = props.growthbook?.getFeatures()
      let experimentValues = ''
      if (features) {
        const featureValues = {}
        Object.keys(features).forEach(key => {
          featureValues[key] = props.growthbook?.getFeatureValue(key, features[key].defaultValue)
        })
        experimentValues = Object.entries(featureValues)
          .map(([key, value]) => `window._mfq.push(["setVariable", "${key}", "${String(value)}"]);`)
          .join('\n')
      }
      scripts.push({
        type: 'text/javascript',
        innerHTML: mouseFlowContext + experimentValues,
        strategy: 'afterInteractive',
      })
    }
  }
  return scripts
}
