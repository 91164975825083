import React from 'react'
import {
  ChevronDown16 as DropDownIcon,
  UserProfile20 as ProfileIcon,
} from '@carbon/icons-react'
import { Popover } from '@mui/material'
import { COLORS, globalStyles } from '../../theme'
import { UserProfile as UserProfileProps } from '../HNavBar.types'
import {
  DesktopProfileContainer,
  LogOutMenuItem,
  ProfileMenuItem,
} from './desktop.styles'
import { formatDisplayName, getUsernameInitials } from '../utils'
import { HAvatar } from '../../HAvatar'
import { HTypography } from '../../HTypography'
import { HBox } from '../../HBox'
import { HDivider } from '../../HDivider'
import styled from '@emotion/styled'

const HPopover = styled(Popover)`
  .MuiPaper-root {
    margin-top: 10px;
    box-shadow: ${globalStyles.shadow4.boxShadow};
    border-radius: 4px;
  }
`

export const DesktopUserProfile: React.FC<UserProfileProps> = (props) => {
  const { user, menu, logout, IndicatorComp } = props
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  return (
    <>
      <DesktopProfileContainer
        onClick={handleClick}
        data-test-id="main-menu-trigger-desktop"
      >
        <HBox position='relative' pl={2}>
          <HAvatar size={32} color='primary_50' letters={getUsernameInitials(user)} />
          {IndicatorComp && (
            <HBox position='absolute' right={-4} top={-4}>
              <IndicatorComp keyType='menuIndicator' keyName='top_menu_bar' strokeWidth={3} />
            </HBox>
          )}
        </HBox>
        <HTypography
          variant='body2'
          weight='semibold'
          component='div'
          color='surface_gray_80'
        >
          {formatDisplayName(user)}
        </HTypography>
        <DropDownIcon color={COLORS.surface_gray_80} />
      </DesktopProfileContainer>
      <HPopover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <HBox
          display='flex'
          padding={4}
          justifyContent='center'
          alignItems='flex-start'
          flexDirection='column'
          width='100%'
        >
          <HTypography
            variant='caption'
            weight='regular'
            color='surface_gray_60'
          >
            {menu.loggedInText}
          </HTypography>
          <HBox pt={1} pb={4}>
            <HTypography
              variant='body2'
              weight='semibold'
              color='surface_gray_80'
              data-test-id='main-header-profile-email-desktop'
            >
              {user.email}
            </HTypography>
          </HBox>
          <ProfileMenuItem
            color='primary_50'
            variant='button'
            weight='medium'
            onClick={menu.profile.clickAction}
            component={menu.profile.component}
            data-test-id='main-header-profile-link-desktop'
            {...menu.profile.componentProps}
          >
            <ProfileIcon/>
            <HBox display='flex' position='relative'>
              {menu.profile.text}
              {IndicatorComp && (
                <HBox position='relative' top={-4}>
                  <IndicatorComp keyType='menuIndicator' keyName='top_menu_open_profile_link' strokeWidth={0} />
                </HBox>
              )}
            </HBox>

          </ProfileMenuItem>
          <HBox width='100%' pt={3} pb={2}>
            <HDivider variant='fullWidth' />
          </HBox>
          <LogOutMenuItem
            variant='button'
            color='surface_gray_80'
            weight='medium'
            onClick={logout.clickAction}
            data-test-id='main-header-logout-link-desktop'
          >
            {logout.label}
          </LogOutMenuItem>
        </HBox>
      </HPopover>
    </>
  )
}
