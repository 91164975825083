import { DistributiveOmit } from '@mui/types'
import clsx from 'clsx'
import React from 'react'
import styled from '@emotion/styled'
import {
  RadioButton16,
  RadioButtonChecked16,
  CheckboxCheckedFilled16,
  Checkbox16,
} from '@carbon/icons-react'
import { COLORS, globalStyles } from '../theme'
import { HButton, IHButtonProps } from '../HButton'

type IVariant = 'normal' | 'icon'
type IIcon = 'radio' | 'checkbox'
interface FunnelProps {
  selected?: boolean
  text: string
  variant?: IVariant
  iconId?: string
  icon?: IIcon
}

type disableProps =
  | keyof FunnelProps
  | 'startIcon'
  | 'endIcon'
  | 'variant'

export type IHFunnelSelectProps =
  FunnelProps & DistributiveOmit<IHButtonProps, disableProps>

const IconVariant: React.FC<Omit<FunnelProps, 'text' | 'variant'>> = ({
  selected,
  icon,
  iconId = '',
}) => {
  switch (icon) {
    case 'checkbox':
      return selected
        ? (
          <CheckboxCheckedFilled16
            data-test-id={`${iconId}-checkbox-checked`}
          />
        )
        : (
          <Checkbox16 data-test-id={`${iconId}-checkbox-unchecked`} />
        )

    default:
      return selected
        ? (
          <RadioButtonChecked16 data-test-id={`${iconId}-radio-checked`} />
        )
        : (
          <RadioButton16 data-test-id={`${iconId}-radio-unchecked`} />
        )
  }
}

const FunnelSelect: React.FC<IHFunnelSelectProps> = ({
  disabled,
  text,
  selected = false,
  variant = 'normal',
  icon = 'radio',
  iconId,
  ...others
}) => {
  const className = clsx(others.className, selected && 'selected')

  return (
    <HButton
      disableElevation
      disabled={disabled}
      variant='outlined'
      {...others}
      className={className}
      startIcon={
        variant !== 'normal' && (
          <IconVariant selected={selected} icon={icon} iconId={iconId} />
        )
      }
    >
      {text}
    </HButton>
  )
}

export const HFunnelSelect = styled(FunnelSelect)`
  &.selected {
    background-color: ${COLORS.primary_15};
    border-color: ${COLORS.primary_50} !important;
    color: ${COLORS.primary_70};
    ${globalStyles.shadow2};
    &:hover {
      background-color: ${COLORS.primary_25};
    }
  }
`
