// @flow
import * as React from 'react'
import styled, { type StyledComponent } from 'styled-components'
import CircularProgress from '@mui/material/CircularProgress'
import { $sizes } from '../dependencies/variables'
import { UI_BUTTON_LOADER_SIZE } from '../../../constants/base'

type SpinnerProps = {}

type TextProps = {
  text: string
}

type IconComponentProps = {
  Icon: React.Node
}

const SpinnerContainer: StyledComponent<{}, *, *> = styled.span`
  margin-right: ${$sizes.base};
  line-height: 1;
  color: #ffffff;
`

export const Spinner = (props: SpinnerProps) => (
  <SpinnerContainer>
    <CircularProgress
      color='inherit'
      size={UI_BUTTON_LOADER_SIZE}
    />
  </SpinnerContainer>
)

export const Text = (props: TextProps) => <span>{props.text}</span>

const IconContainer: StyledComponent<{}, *, *> = styled.div`
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;
  margin: 0 8px;

  & svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
  }
`

export const IconComponent = (props: IconComponentProps) => <IconContainer>{props.Icon}</IconContainer>
