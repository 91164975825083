import React from 'react'
import styled from '@emotion/styled'
import {
  // @ts-expect-error - The icon exists but is not in the type definitions.
  SearchLocate20 as JobSearchIcon,
  Box20 as ApplicationsIcon,
  Bookmark20 as SavedJobsIcon,
  UserProfile20 as ProfileIcon,
} from '@carbon/icons-react'
import { LoggedInMobileMenuProps } from '../HNavBar.types'
import { LoggedInMobileMenuItem } from './LoggedInMobileMenuItem'
import { HBox } from '../../HBox'

const LoggedInMenuItemsContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
  align-items: flex-start;
`
const icons: { [key: string]: any } = {
  profile: <ProfileIcon />,
  jobSearch: <JobSearchIcon />,
  applications: <ApplicationsIcon />,
  savedJobs: <SavedJobsIcon />,
}

export const LoggedInMobileMenuItems: React.FC<LoggedInMobileMenuProps> =
  (props) => {
    const { profile, jobSearch, applications, savedJobs } = props.menuItems
    const IndicatorComponent = props.userProfile?.IndicatorComp
    return (
      <LoggedInMenuItemsContainer>
        <LoggedInMobileMenuItem
          {...jobSearch}
          data-test-id='main-header-navigation-jobs-mobile'
        >
          {icons.jobSearch}
          <div>{jobSearch.text}</div>
        </LoggedInMobileMenuItem>
        <LoggedInMobileMenuItem
          data-test-id='main-header-navigation-applications-mobile'
          {...applications}
        >
          {icons.applications}
          <div>{applications.text}</div>
        </LoggedInMobileMenuItem>
        <LoggedInMobileMenuItem
          data-test-id='main-header-navigation-bookmarks-mobile'
          {...savedJobs}
        >
          {icons.savedJobs}
          <div>{savedJobs.text}</div>
        </LoggedInMobileMenuItem>
        <LoggedInMobileMenuItem
          data-test-id='main-header-profile-link-mobile'
          {...profile}
        >
          {icons.profile}
          <div>{profile.text}</div>
          {IndicatorComponent && (
            <HBox position='absolute' top={0} right={0}>
              <IndicatorComponent keyType='menuIndicator' keyName='top_menu_open_profile_link' strokeWidth={0} />
            </HBox>
          )}
        </LoggedInMobileMenuItem>
      </LoggedInMenuItemsContainer>
    )
  }
