// @flow

import { createStore, combineReducers, compose, applyMiddleware } from 'redux'
import { runningInBrowser } from '../actions/actions/support/base'

import thunkMiddleware from 'redux-thunk'
import promiseMiddleware from 'redux-promise-middleware'
import { routerMiddleware, connectRouter } from 'connected-react-router'
import epicPromiseMiddleware from './middleware/epicPromise'
import { GrowthBook } from '@growthbook/growthbook-react'

import { createEpicMiddleware } from 'redux-observable'

import reducers from '../reducers'
import rootEpic from '../epics'

import type { Store as ReduxStore } from 'redux'
import type { ApplicationState } from '../types/applicationState'
import type { Cookies } from 'react-cookie'

export type Action = $FlowTODO
export type Store = ReduxStore<ApplicationState, Action>

type RegularDispatch = (action: Action) => $FlowTODO
type PromiseDispatch = (action: $FlowTODO) => $FlowTODO
export type Dispatch = RegularDispatch | PromiseDispatch

export type GetState = () => ApplicationState

export const configureStore = (history: History, cookies: Cookies, growthbook?: GrowthBook, initialState?: ApplicationState): Store => {
  const epicMiddleware = createEpicMiddleware({ dependencies: growthbook })

  const middlewares = [
    routerMiddleware(history),
    promiseMiddleware(),
    thunkMiddleware.withExtraArgument([cookies, growthbook]),
    epicPromiseMiddleware,
    epicMiddleware,
  ]

  /*
   * devToolsExtension is for old versions, new recommended way is to check is to see if `__REDUX_DEVTOOLS_EXTENSION__` function
   * exists directly. Still keeping the old way too for backward compatibility.
   */
  const devToolsAvailable = runningInBrowser && (window.__REDUX_DEVTOOLS_EXTENSION__ || window.devToolsExtension)

  const createStoreWithMiddleware = compose(
    applyMiddleware(...middlewares),
    devToolsAvailable ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f // use redux dev tools chrome extension if installed
  )

  const rootReducer = combineReducers({
    router: connectRouter(history),
    ...reducers,
  })

  const storeCreator = createStoreWithMiddleware(createStore)

  // $FlowTODO - storeCreator without initialState doesn't match `ApplicationState`
  const store: any = initialState
    ? storeCreator(rootReducer, initialState)
    : storeCreator(rootReducer)

  epicMiddleware.run(rootEpic)

  // get the initial state from the window
  if (typeof window !== 'undefined' && typeof jest === 'undefined') {
    store.dispatch({
      type: 'SET_TRANSLATIONS',
      locale: window.__heyjobs__.locale,
      messages: window.__heyjobs__.translations,
    })
  }

  return store
}
