import React from 'react'
import { HBox, HBoxProps } from '../HBox'
import { COLORS, globalStyles } from '../theme'
import { DesktopMenu } from './DesktopMenu/DesktopMenu'
import {
  DesktopMenuProps,
  HNavBarProps,
} from './HNavBar.types'
import { InternalMobileMenuProps, MobileMenu } from './MobileMenu/MobileMenu'
import { Theme, useMediaQuery } from '@mui/material'
import { TabletMenu } from './TabletMenu/TabletMenu'
import { NavbarLogo } from './NavbarLogo'
import { BookmarkAnimationProvider } from './bookmarkAnimationContext'

export const HNavbar: React.FC<HNavBarProps> = (
  {
    loggedInMenu,
    loggedOutMenu,
    logoClickAction,
    logoLink,
    Link,
    jdp,
    careerPage,
    buttonLabel,
    isBookmarkAnimationActive,
    sticky = true,
  }) => {
  const desktopMenuProps: DesktopMenuProps = {
    loggedInMenuItems: loggedInMenu.menuItems,
    loggedIn: !!(loggedInMenu.userProfile),
    loggedOutMenuItems: loggedOutMenu,
    userProfile: loggedInMenu.userProfile,
  }

  const mobileMenuProps: InternalMobileMenuProps = {
    loggedInMenuItems: loggedInMenu.menuItems,
    loggedIn: !!(loggedInMenu.userProfile),
    loggedOutMenuItems: loggedOutMenu,
    userProfile: loggedInMenu.userProfile,
    logoLink: logoLink,
    Link: Link,
    logoClickAction: logoClickAction,
    buttonLabel: buttonLabel,
  }

  const parentOnLanguageChangeClick = mobileMenuProps
    .loggedOutMenuItems.languageChange.componentProps.onClick

  const onLanguageChangeClick =
    (event: React.MouseEvent<HTMLAnchorElement>): void => {
      parentOnLanguageChangeClick?.(event)
    }

  mobileMenuProps.loggedOutMenuItems.languageChange
    .componentProps.onClick = onLanguageChangeClick

  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.between('md', 'lg'))
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const stickyProps: Partial<HBoxProps> = sticky ? { position: 'fixed', top: 0 } : {}

  return (
    <HBox
      id='navbar-container'
      component='header'
      data-test-id='navbar'
      width='100%'
      display='flex'
      justifyContent='center'
      alignItems='center'
      boxShadow={globalStyles.shadow2.boxShadow}
      {...stickyProps}
      zIndex={8989}
      bgcolor={COLORS.surface_white}
    >
      <HBox
        maxWidth={1240}
        width='100%'
        px={6}
        md={{ px: 10 }}
        lg={{ px: 10 }}
      >
        <BookmarkAnimationProvider
          isBookmarkAnimationActive={!!isBookmarkAnimationActive}
        >
          <HBox
            component='nav'
            width='100%'
            pt={4}
            pb={3}
            lg={{
              py: 4,
            }}
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            gap={12}
          >
            <NavbarLogo
              Link={Link}
              logoLink={logoLink}
              logoClickAction={logoClickAction}
              userProfile={loggedInMenu.userProfile}
              jdp={jdp}
              careerPage={careerPage}
            />
            <DesktopMenu {...desktopMenuProps} />
            {isTablet && (
              <TabletMenu
                {...mobileMenuProps}
                searchButton={loggedOutMenu.searchButton}
              />
            )}
            {isMobile && (
              <MobileMenu
                {...mobileMenuProps}
                searchButton={loggedOutMenu.searchButton}
                logoLink={logoLink}
                Link={Link}
                logoClickAction={logoClickAction}
              />
            )}
          </HBox>
        </BookmarkAnimationProvider>
      </HBox>
    </HBox>
  )
}
