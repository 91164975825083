export const USER_CENTRIC_SERVICES = {
  CRITEO: 'Criteo',
  FACEBOOK_PIXEL: 'Facebook Pixel',
  LINKEDIN_INSIGHT_TAG: 'LinkedIn Insight Tag',
  TROVIT: 'Trovit',
  JOBRAPIDO: 'Jobrapido',
  ALLTHETOPBANANAS: 'AllTheTopBananas',
  ADZUNA: 'Adzuna',
  NEUVOO: 'Neuvoo',
  DOUBLECLICK_AD: 'DoubleClick Ad',
  GOOGLE_ADWORDS_REMARKETING: 'Google AdWords Remarketing',
  GOOGLE_ADWORDS_CONVERSION: 'Google AdWords Conversion',
  GOOGLE_ADS_CONVERSION: 'Google Ads Conversion',
  GOOGLE_ADS_CONVERSION_TRACKING: 'Google Ads Conversion Tracking',
  GOOGLE_ADS_REMARKETING: 'Google Ads Remarketing',
  TIKTOK_ADVERTISING: 'TikTok Advertising',
  SNAP_PIXEL: 'Snap Pixel',
  JOOBLE: 'Jooble',
  JOBG8: 'Jobg8',
  HEYFLOW: 'HeyFlow',
  JOBLIFT: 'Joblift',
  HOTJAR: 'Hotjar',
  VIMEO: 'Vimeo',
  JOBSAROUND_TV: 'JOBSaround.tv',
  WATCHADO: 'Watchado',
  JAHNHR_DE: 'jahnhr.de',
  YOUTUBE_VIDEO: 'YouTube Video',
  GOOGLE_ANALYTICS: 'Google Analytics',
  GOOGLE_MAPS: 'Google Maps',
  SPRIG: 'Sprig',
  USERCENTRICS_CONSENT_MANAGEMENT_PLATFORM: 'Usercentrics Consent Management Platform',
  GOOGLE_TAG_MANAGER: 'Google Tag Manager',
  SNOWPLOW: 'SnowPlow',
  CLOUDFRONT_NET: 'cloudfront.net',
  FONTAWESOME: 'Fontawesome',
  SENTRY: 'Sentry',
  DATADOG_REAL_USER_MONITORING: 'Datadog Real User Monitoring',
  BRAZE: 'Braze',
}
