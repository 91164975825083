// @flow

import React from 'react'
import { injectIntl } from 'react-intl'

import compose from 'lodash/flowRight'
import injectPathr from '../../components/containers/pathr'
import { connect } from 'react-redux'
import { HSlimFooter } from 'talent-ui/src/HFooter/Slim/HSlimFooter.tsx'
import { getOppositeLocaleConfig } from './LangChange'
import { matchPath } from 'react-router-dom'
import externalPageMap from '../../helpers/externalPageMap'
import { unPrefixRouteName } from '../../routing/routeNamePrefix'
import { routeForTargetLocale, routeNames } from '../../routes'
import config from '../../../config'

const _SlimFooter = (props) => {
  const {
    cpathr,
    locale: activeLocale,
    params,
    location,
    country,
    intl,
  } = props

  // get route name (Check if search page)
  const isSearchPage = matchPath(location.pathname, { path: props.cpathr.jobSearch() })
  const routeName = isSearchPage ? routeNames.jobSearchKeyword : routeNames.home
  const oppositeLocale = getOppositeLocaleConfig({ activeLocale, country })
  const linkTo = externalPageMap(activeLocale)

  const targetRoute =
    routeForTargetLocale(
      oppositeLocale?.locale || 'en',
      params,
      unPrefixRouteName(routeName),
      location,
      activeLocale,
      country
    ) || cpathr.index

  return (
    <HSlimFooter
      links={{
        changeLanguage: {
          isVisible: !!oppositeLocale,
          label: oppositeLocale ? intl.formatMessage({ id: oppositeLocale.config.changeLangId }) : '',
          componentProps: {
            href: targetRoute,
          },
        },
        forEmployers: {
          isVisible: !props.isCareerPage,
          label: intl.formatMessage({ id: 'for_employer' }),
          componentProps: {
            href: linkTo.employers,
          },
        },
        imprint: {
          isVisible: true,
          label: intl.formatMessage({ id: 'Button_Imprint' }),
          componentProps: {
            href: linkTo.imprint,
          },
        },
        termsAndConditions: {
          isVisible: !props.isCareerPage,
          label: intl.formatMessage({ id: 'Button_TermsConditions' }),
          componentProps: {
            href: linkTo.tc,
          },
        },
        privacyPolicy: {
          isVisible: true,
          label: intl.formatMessage({ id: 'Button_PrivacyPolicy' }),
          componentProps: {
            href: linkTo.privacy,
          },
        },
        cookieSettings: {
          isVisible: true,
          label: intl.formatMessage({ id: 'show_privacy_settings' }),
          onClick: () => window.UC_UI?.showSecondLayer(),
          isClickable: !!config.userCentrics?.settingId,
        },
        careersAtHeyjobs: {
          isVisible: !props.isCareerPage,
          label: intl.formatMessage({ id: 'Button_Jobs' }),
          componentProps: {
            href: linkTo.careers,
          },
        },
        careerGuide: {
          isVisible: true,
          label: intl.formatMessage({ id: 'Button_Career_Guide' }),
          componentProps: {
            href: linkTo.careerGuide,
          },
        },
      }}
    />
  )
}

const mapState = (state, ownProps) => ({
  locale: state.intlData.locale,
  isCareerPage: !!state.company.current,
  country: state.locality.country,
})

const SlimFooter = compose(
  injectPathr,
  injectIntl,
  connect(mapState)
)(_SlimFooter)

export default SlimFooter
