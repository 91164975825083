/* eslint-disable max-len */
import React from 'react'
import { HTypography } from '../HTypography'
import { BADGE_COLOR_MAP } from './HBadge.styles'
import styled from '@emotion/styled'

type IColor =
  | 'grey'
  | 'blue'
  | 'yellow'
  | 'pink'
  | 'green'
  | 'red'
  | 'orange'
  | 'purple'

type IVariant = 'full' | 'outline'

type disableProps = 'size' | 'as'
interface HBadgeProps extends
  Omit<React.HTMLProps<HTMLDivElement>, disableProps> {
  color: IColor
  variant: IVariant
  truncate?: boolean
  size?: 'small' | 'large'
  as?: React.ElementType
}

const BadgeContainer = styled.div<HBadgeProps>`
  padding: 2px 8px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  border: solid 1px;
  width: 100%;
  max-width: fit-content;
  height: ${props => (props.size === 'small' ? '20px' : '26px')};
  
  color: ${props => BADGE_COLOR_MAP[props.color][props.variant].color};
  border-color: ${props => BADGE_COLOR_MAP[props.color][props.variant].borderColor};
  background-color: ${props => BADGE_COLOR_MAP[props.color][props.variant].backgroundColor};
`

export const HBadge = React.forwardRef((props: HBadgeProps, ref: any) => {
  const { color, variant, truncate = true, size = 'small', children, ...others } = props
  const textVariant = size === 'small' ? 'body2' : 'body1'

  return (
    <BadgeContainer
      variant={variant}
      color={color}
      size={size}
      ref={ref}
      {...others}
    >
      <HTypography
        weight='regular'
        align='center'
        color='inherit'
        variant={textVariant}
        truncated={truncate}
      >
        {children}
      </HTypography>
    </BadgeContainer>
  )
})

HBadge.displayName = 'HBadge'
