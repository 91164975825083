// @flow
import React from 'react'
import Loadable from 'react-loadable'
import compose from 'lodash/flowRight'
import { PageLoader } from '../../components/shared/loader'
import * as actionCreators from '../../actions/actionCreators'
import {
  withUniversalDataLoader,
} from '../../components/containers/withDataLoader'
import * as actions from '../../constants/actions'
import { connect } from 'react-redux'
import { getRoutesForLocale } from '../../routes'

import type { RouteData } from '../../types/common'
import type { Dispatch } from '../../store/configureStore'
import type { ApplicationState } from '../../types/applicationState'

const dataLoader = async (
  dispatch: Dispatch,
  state: ApplicationState,
  routeData: RouteData,
  replace: Function
) => {
  const company = state.company.current
  const subdomain = ((routeData.params.subdomain: any): string)

  if (!company) {
    if (subdomain) {
      try {
        const companyFromSubdomain = await dispatch(actionCreators.fetchCompanyBySubdomain()(subdomain))
        const policyUrl = companyFromSubdomain.value.privacy_policy_url
        if (policyUrl) {
          await dispatch(
            actionCreators.fetchCompanyBySubdomain(actions.FETCH_COMPANY)(
              subdomain
            )
          )
        } else {
          replace(getRoutesForLocale(state.intlData.locale).country('de').privacy())
        }
      } catch (e) {
        if (e.res && e.res.status === 404) {
          replace(getRoutesForLocale(state.intlData.locale).country('de').privacy())
        }
      }
    }
  }
}

const LoadableOut = Loadable<$FlowTODO, $FlowTODO>({
  loader: () => import('./Privacy'),
  loading: () => <PageLoader />,
})

const Privacy = compose(
  withUniversalDataLoader(dataLoader),
  connect((state) => ({
    loading: state.company.loading,
    company: state.company.current,
  }))
)(LoadableOut)

export default Privacy
