// @flow

import * as actions from '../constants/actions'

export type ModalState = {
 [key: string]: bool,
}

type ActionType = typeof actions.SHOW_MODAL | typeof actions.HIDE_MODAL

type ModalActionType = {
  key: string,
  type: ActionType
}

const initialState: ModalState = {}

const reducer = (state: ModalState = initialState, action: ModalActionType) => {
  switch (action.type) {
    case actions.SHOW_MODAL:
      return {
        ...initialState,
        [action.key]: true,
      }

    case actions.HIDE_MODAL:
      return {
        ...initialState,
        [action.key]: false,
      }

    default:
      return state
  }
}

export default reducer
