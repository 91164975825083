// @flow

import React from 'react'
import config from '../../../config'
import { DebugKeyValueInfo, DebugDrawer } from '../../../../../jobseeker-portal-next/src/components/shared/debug-drawer/index.tsx'
import { DebugFetchParsedCvButton } from '../../store/parsedCv/debug-fetch-parsed-cv-button.tsx'

const Debug = () => {
  return (
    <DebugDrawer debugFetchParsedCvButton={DebugFetchParsedCvButton}>
      <DebugKeyValueInfo debugObject={config} />
    </DebugDrawer>
  )
}

export default Debug
