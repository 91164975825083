import React from 'react'
import { LogoBuilder } from './LogoBuilder'
import { HBox } from '../HBox'
import { HTypography } from '../HTypography'
import styled from '@emotion/styled'

export type ILogoVariant = 'pink_white' | 'pink_yellow' | 'pink_yellow_roundel' | 'gray'
interface IHLogoProps {
  variant: ILogoVariant
  text?: string
  textPosition?: 'besides' | 'above'
  size?: 'small' | 'medium' | 'large'
  width?: number
  mt?: number
}

const LogoText = styled(HTypography)`
  white-space: nowrap;
`

export const HLogo: React.FC<IHLogoProps> = ({
  variant,
  text,
  textPosition = 'besides',
  size = 'large',
  width = 116,
}) => {
  const textVariant = size === 'small' ? 'caption2' : 'caption'
  return (
    <HBox
      pl={textPosition === 'above' ? 1 : 0}
      display='flex'
      alignItems='center'
      height='fit-content'
    >
      {text && (
        <HBox
          mr={1}
          md={{
            mr: 2,
          }}
        >
          <LogoText
            variant={textVariant}
            color='surface_gray_40'
            weight='regular'
          >
            {text}
          </LogoText>
        </HBox>
      )}
      <LogoBuilder width={width} variant={variant as string} />
    </HBox>
  )
}
