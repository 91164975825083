/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import MBox, { BoxProps } from '@mui/material/Box'
import { DefaultTheme } from '@mui/styles'
import {
  spacing,
  sizing,
  display,
  flexbox,
  compose,
  borders,
  FlexboxProps,
  SizingProps,
  DisplayProps,
  SpacingProps,
  PositionsProps,
  positions,
  shadows,
  ShadowsProps,
  BordersProps,
} from '@mui/system'
import React from 'react'
import styled from '@emotion/styled'
import { Property } from 'csstype'

const STOP_FORWARD_PROPS: Record<string, boolean> = {
  gap: true,
  cursor: true,
  content: true,
  textDecoration: true,
  selectors: true,
  xs: true,
  sm: true,
  md: true,
  lg: true,
}

interface ExtraProps {
  gap?: number
  cursor?: Property.Cursor
  content?: Property.Content
  textDecoration?: Property.TextDecoration
}

// Add extra props here that are not supported by @mui/system
const extraStyles = <C extends React.ElementType = 'div'>(
  { gap, cursor, content, textDecoration }: HBoxProps<C>) => {
  return {
    gap,
    cursor,
    content,
    textDecoration,
  }
}

type StyleProps = SizingProps
& SpacingProps
& DisplayProps
& FlexboxProps
& PositionsProps
& ShadowsProps
& BordersProps
& ExtraProps

type ResponsiveStyleProps = {
  [Breakpoint in 'xs' | 'sm' | 'md' | 'lg' | 'xl']?: StyleProps;
}

type InternalBoxProps = BoxProps & ResponsiveStyleProps & ExtraProps & {
  selectors?: Array<{ selector: string, props: Omit<InternalBoxProps, 'selectors'> }>
}

export type HBoxProps<C extends React.ElementType = 'div'> = BoxProps<C> & InternalBoxProps & {
  component?: C
}

const style: any = (props?: StyleProps, theme?: DefaultTheme) => {
  if (!props) return {}
  const composed = compose(
    sizing,
    spacing,
    display,
    flexbox,
    positions,
    shadows,
    borders,
  )
  const themedProps = { ...props, theme: theme }
  return { ...composed(themedProps), ...extraStyles(props) }
}

const createSelector = <C extends React.ElementType = 'div'>(selector: {
  selector: string
  props: HBoxProps<C>
}, theme: DefaultTheme): Record<string, ReturnType<typeof style>> => {
  return {
    [selector.selector]: ({ ...style(selector.props, theme) }),
  }
}

const ResponsiveBox = styled(MBox, {
  shouldForwardProp: (prop) => !STOP_FORWARD_PROPS[prop],
}) <HBoxProps<any>>`
  ${({ theme, ...props }) => ({ ...style(props, theme) })}
  ${({ theme }) => theme.breakpoints.up('xs')} { ${({ xs, theme }) => ({ ...style(xs, theme) })} }
  ${({ theme }) => theme.breakpoints.up('sm')} { ${({ sm, theme }) => ({ ...style(sm, theme) })} }
  ${({ theme }) => theme.breakpoints.up('md')} { ${({ md, theme }) => ({ ...style(md, theme) })} }
  ${({ theme }) => theme.breakpoints.up('lg')} { ${({ lg, theme }) => ({ ...style(lg, theme) })} }
  ${({ theme }) => theme.breakpoints.up('xl')} { ${({ xl, theme }) => ({ ...style(xl, theme) })} }
  ${({ theme, selectors }) => selectors?.map((selector) => createSelector(selector, theme))}
`

export const HBox = <C extends React.ElementType = 'div'>({ component, ...props }: HBoxProps<C>): JSX.Element => {
  return <ResponsiveBox as={component} {...props} />
}
