import React from 'react'
import styled from '@emotion/styled'
import {
  Login16 as LoginIcon,
  // @ts-expect-error - The icon exists but is not in the type definitions.
  SearchLocate20 as SearchIcon,
} from '@carbon/icons-react'
import { HBox } from '../../HBox'
import { COLORS } from '../../theme'
import { HTypography } from '../../HTypography'
import { LoggedOutMenuProps } from '../HNavBar.types'

const MenuButtonBlue = styled(HTypography)`
  display: inline-flex;
  gap: 8px;
  height: 32px;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  &:hover, &:active {
    cursor: pointer;
    color: ${COLORS.primary_70} !important;
  }
`

const MenuButtonPink = styled(HTypography)`
  display: inline-flex;
  gap: 8px;
  height: 32px;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  &:hover, &:active {
    cursor: pointer;
    color: ${COLORS.accent_pink_70} !important;
  }
`

export const LoggedOutMenuItems: React.FC<LoggedOutMenuProps> =
(props) => {
  const {
    menuItems: {
      jobseekerLogin, recruiterLogin, searchButton,
    }, platform,
  } = props

  return (
    <>
      {searchButton && (
        <HBox
          component='span'
          display='none'
          lg={{ display: 'flex' }}
        >
          <MenuButtonBlue
            variant='button'
            component={searchButton.component}
            weight='medium'
            color='primary_50'
            onClick={searchButton.clickAction}
            data-test-id={`main-menu-see-similar-jobs-${platform}`}
            lg={{ display: 'none' }}
            {...searchButton.componentProps}
          >
            {searchButton?.text}
            <SearchIcon />
          </MenuButtonBlue>
        </HBox>
      )}

      <MenuButtonBlue
        variant='button'
        component='div'
        weight='medium'
        color='primary_50'
        onClick={jobseekerLogin.clickAction}
        data-test-id={`main-menu-login-signup-link-${platform}`}
        lg={{ display: 'none' }}
      >
        <HBox
          component='span'
          display='flex'
          lg={{ display: 'none' }}
        >
          <LoginIcon/>
        </HBox>
        {jobseekerLogin.text}
      </MenuButtonBlue>
      <MenuButtonPink
        variant='button'
        weight='medium'
        component='a'
        color='accent_pink_50'
        href={recruiterLogin.href}
        data-test-id={`main-menu-recruiter-link-${platform}`}
      >
        <HBox
          component='span'
          display='flex'
          lg={{ display: 'none' }}
        >
          <LoginIcon/>
        </HBox>
        {recruiterLogin.label}
      </MenuButtonPink>
    </>
  )
}
