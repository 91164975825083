// @flow

import { snowplowSetUserId } from '../clients/snowplow'
import datalayer from '../clients/datalayer'

const setUserId = (userId: ?string) => {
  snowplowSetUserId(userId)
  datalayer({ userId })
}

export default setUserId
