// @flow

import * as actions from '../constants/actions'
import { FULFILLED, REJECTED } from './promiseUtil'

import type { User } from '../actions/api/clients/users'
import { getCurrentUserAuthenticationMethod, type AuthenticationMethods } from '../helpers/cognitoHelper.ts'
import cookies from '../cookies'
import { runningInBrowser } from '../actions/actions/support/base'
import config from '../../config'

export type UserState = {
  authenticationMethod: AuthenticationMethods | null,
  cafCESEventTriggered: ?bool,
  errors: $FlowTODO,
  initialized: bool,
  // did we try to resolve the user at least once?
  isAuthenticated: bool,
  isPendingAnUpdate: bool,
  isUserDeleted: boolean,
  numberOfHomePageView: number,
  numberOfInboxPageView: number,
  numberOfJobSearchPageView: number,
  onPageNPSEventTriggered: bool,
  shouldShowApplicationSubmittedFollowUp: bool,
  shouldShowOneTapSignInFollowUp: bool,
  user: ?User,
  user_pending_consent_terms_and_conditions: ?User
}

const initialState: UserState = {
  user: null,
  user_pending_consent_terms_and_conditions: null,
  initialized: false,
  isAuthenticated: false,
  numberOfHomePageView: 0,
  numberOfJobSearchPageView: 0,
  numberOfInboxPageView: 0,
  shouldShowApplicationSubmittedFollowUp: false,
  shouldShowOneTapSignInFollowUp: false,
  errors: {},
  onPageNPSEventTriggered: false,
  cafCESEventTriggered: null,
  isUserDeleted: false,
  isPendingAnUpdate: false,
  authenticationMethod: null,
}

const aYearInSeconds = 86400 * 365

const manageIsAuthCookie = (isAuth: boolean) => {
  if (isAuth) {
    document.cookie = `${cookies.identifiers.IS_AUTHENTICATED}=true;path=/;max-age=${aYearInSeconds};domain=${config.cookies.domain}`
  } else {
    document.cookie = `${cookies.identifiers.IS_AUTHENTICATED}=;path=/;expires=-1;domain=${config.cookies.domain}`
  }
}

export default (state: UserState = initialState, action: $FlowTODO): UserState => {
  switch (action.type) {
    case FULFILLED(actions.REFRESH_USER_OPAQUELY):
    case FULFILLED(actions.RESOLVE_USER):
    case FULFILLED(actions.PASSWORDLESS_SIGN_IN):
    case FULFILLED(actions.PASSWORDLESS_SIGN_UP):
    case FULFILLED(actions.VERIFY_GOOGLE_SIGN_IN_TOKEN):
      if (runningInBrowser) {
        manageIsAuthCookie(Boolean(action.payload))
      }

      return {
        ...state,
        authenticationMethod: getCurrentUserAuthenticationMethod(),
        initialized: true,
        isAuthenticated: action.payload != null,
        user: action.payload,
      }
    case REJECTED(actions.REFRESH_USER_OPAQUELY):
      // consider a failed refresh of the user as OK and not trigger a logout
      // incase it's just a temporary network issue
      return state
    case REJECTED(actions.RESOLVE_USER):
    case REJECTED(actions.RESOLVE_COGNITO_USER):
      if (runningInBrowser) {
        manageIsAuthCookie(false)
      }

      return {
        initialized: true,
        isAuthenticated: false,
        numberOfHomePageView: 0,
        numberOfJobSearchPageView: 0,
        numberOfInboxPageView: 0,
        shouldShowApplicationSubmittedFollowUp: false,
        shouldShowOneTapSignInFollowUp: false,
        user: null,
        user_pending_consent_terms_and_conditions: null,
        errors: action.payload.errors,
        onPageNPSEventTriggered: false,
        cafCESEventTriggered: null,
        isUserDeleted: false,
        isPendingAnUpdate: false,
        authenticationMethod: null,
      }

    case FULFILLED(actions.LOGOUT):
      manageIsAuthCookie(false)

      return {
        ...state,
        isAuthenticated: false,
        user: undefined,
        user_pending_consent_terms_and_conditions: null,
      }
    case FULFILLED(actions.DELETE_USER_PROFILE):
      manageIsAuthCookie(false)

      return {
        ...state,
        isAuthenticated: false,
        isUserDeleted: true,
        user: null,
      }

    case actions.CLEAR_USER_DELETED:
      return {
        ...state,
        isUserDeleted: false,
      }
    case FULFILLED(actions.CONFIRM_EMAIL):
      return {
        ...state,
        user: state.user
          ? {
              ...state.user,
              confirmed: true,
            }
          : null,
      }
    case FULFILLED(actions.CREATE_RECOMMENDATION_CONSENT):
      return {
        ...state,
        user: state.user
          ? {
              ...state.user,
              [`consented_${action?.meta?.[0]?.consent_type || 'job_recommendations'}`]: action?.meta?.[0]?.consent_value,
            }
          : null,
      }
    case FULFILLED(actions.CREATE_PROFILE_SHARING_CONSENT):
      return {
        ...state,
        user: state.user
          ? {
              ...state.user,
              consented_profile_sharing: action?.meta?.[0]?.consent_value,
            }
          : null,
      }
    case REJECTED(actions.CREATE_RECOMMENDATION_CONSENT):
      return {
        ...state,
        user: state.user
          ? {
              ...state.user,
              consented_job_recommendations: false,
            }
          : null,
      }
    case FULFILLED(actions.CREATE_WHATS_APP_RECOMMENDATION_CONSENT):
      return {
        ...state,
        user: state.user
          ? {
              ...state.user,
              consented_job_recommendations_whatsapp: action?.meta?.[0]?.consent_value,
            }
          : null,
      }
    case REJECTED(actions.CREATE_WHATS_APP_RECOMMENDATION_CONSENT):
      return {
        ...state,
        user: state.user
          ? {
              ...state.user,
              consented_job_recommendations_whatsapp: false,
            }
          : null,
      }
    case FULFILLED(actions.CREATE_JOBS_ALERTS_EMAIL_CONSENT):
      return {
        ...state,
        user: state.user
          ? {
              ...state.user,
              consented_job_alerts_email: true,
            }
          : null,
      }
    case REJECTED(actions.CREATE_JOBS_ALERTS_EMAIL_CONSENT):
      return {
        ...state,
        user: state.user
          ? {
              ...state.user,
              consented_job_alerts_email: false,
            }
          : null,
      }
    case FULFILLED(actions.CREATE_TERMS_AND_CONDITIONS_CONSENT):
    {
      let delta
      if (state.user) {
        delta = state.user
      } else if (state.user_pending_consent_terms_and_conditions) {
        delta = state.user_pending_consent_terms_and_conditions
      }

      return {
        ...state,
        isAuthenticated: true,
        user: delta ? { ...delta, consented_terms_and_conditions: true } : null,
      }
    }
    case REJECTED(actions.CREATE_TERMS_AND_CONDITIONS_CONSENT):
    {
      let delta
      if (state.user) {
        delta = state.user
      } else if (state.user_pending_consent_terms_and_conditions) {
        delta = state.user_pending_consent_terms_and_conditions
      }

      return {
        ...state,
        isAuthenticated: false,
        user: null,
        user_pending_consent_terms_and_conditions: delta
          ? {
              ...delta,
              consented_terms_and_conditions: false,
            }
          : null,
      }
    }
    case actions.USER_DID_NOT_CONSENT_TERMS_AND_CONDITIONS:
      return {
        ...state,
        user: null,
        isAuthenticated: false,
        user_pending_consent_terms_and_conditions: state.user ? { ...state.user } : null,
      }
    case FULFILLED(actions.UPDATE_CURRENT_USER):
      return {
        ...state,
        user: !state.user_pending_consent_terms_and_conditions
          ? {
              ...state.user,
              ...action.payload,
            }
          : null,
        user_pending_consent_terms_and_conditions: state.user_pending_consent_terms_and_conditions
          ? {
              ...state.user_pending_consent_terms_and_conditions,
              ...action.payload,
            }
          : null,
      }
    case FULFILLED(actions.MARK_APPLICATION_AS_APPLIED):
      return {
        ...state,
        user: state.user
          ? {
              ...state.user,
              has_submitted_applications: true,
            }
          : null,
      }
    case actions.USER_IS_PENDING_AN_UPDATE:
      return {
        ...state,
        isPendingAnUpdate: true,
      }
    case actions.USER_IS_NO_LONGER_PENDING_AN_UPDATE:
      return {
        ...state,
        isPendingAnUpdate: false,
      }
    case actions.LOCATION_CHANGE:
      return {
        ...state,
        error: {},
      }
    case actions.TOGGLE_GOOGLE_ONE_TAP_FOLLOW_UP:
      return {
        ...state,
        shouldShowOneTapSignInFollowUp: !state.shouldShowOneTapSignInFollowUp,
      }

    case actions.TOGGLE_APPLICATION_SUBMITTED_FOLLOW_UP:
      return {
        ...state,
        shouldShowApplicationSubmittedFollowUp: !state.shouldShowApplicationSubmittedFollowUp,
      }

    case REJECTED(actions.GET_APPLICATION_DETAILS):
      return {
        ...state,
      }

    case actions.MARK_ONPAGE_NPS_EVENT_AS_TRIGGERED:
      return {
        ...state,
        onPageNPSEventTriggered: true,
      }

    case actions.INCREMENT_NUMBER_OF_HOME_PAGE_VIEW:
      return {
        ...state,
        numberOfHomePageView: state.numberOfHomePageView + 1,
      }

    case actions.INCREMENT_NUMBER_OF_JOB_SEARCH_PAGE_VIEW:
      return {
        ...state,
        numberOfJobSearchPageView: state.numberOfJobSearchPageView + 1,
      }

    case actions.INCREMENT_NUMBER_OF_INBOX_PAGE_VIEW:
      return {
        ...state,
        numberOfInboxPageView: state.numberOfInboxPageView + 1,
      }

    case actions.INITIALIZE_TRIGGER_OF_CES_EVENT_FOR_CAF:
      return {
        ...state,
        cafCESEventTriggered: false,
      }

    case actions.MARK_CES_EVENT_FOR_CAF_AS_TRIGGERED:
      return {
        ...state,
        cafCESEventTriggered: true,
      }
    default:
      return state
  }
}
