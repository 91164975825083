// @flow

import API from '../clientBuilder'
import type { APIRequestConfig } from '../types'

export default {
  get: (countryCode: string, config: APIRequestConfig) => {
    // if it is not allowed country dont make a request and use intial state
    if (!['de', 'at'].includes(countryCode.toLowerCase())) return {}
    return API.get.promise(`/v2/talent_platform/counts?country_code=${countryCode}`, null, config)
  },
}
