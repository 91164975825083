import { HBox } from '../HBox'
import { COLORS, globalStyles, hStyled } from '../theme'

export const HApplicationCardContainer = hStyled(HBox) <{ $isSmall: boolean }>`
  border: 1px solid ${COLORS.surface_gray_10};
  border-radius: 4px;
  box-shadow: ${globalStyles.shadow1.boxShadow};
  height: 100%;
  width: 100%;
  position: relative;

  ${({ $isSmall }) => $isSmall
    ? `
      flex-direction: column;
    `
    : 'flex-wrap: wrap;'
}
`
