import React from 'react'
import { FormattedMessage } from 'react-intl'
import { HBox, HButton, HTypography } from 'talent-ui'
import { useOnboarding, useOnboardingFlow } from '../../common/use-onboarding-flow'
import { trackSkippedStep } from '../../common/tracking'
import { useMediaQuery } from '@mui/material'

export const NoDataVariant: React.FC<{messageId: string, restartButtonLabel: string}> = ({ messageId, restartButtonLabel }) => {
  const { intl, fireSnowplowEvent } = useOnboarding()
  const { onRestartFlow, closeOnBoardingWithNoTracking, step, history, baseProps } = useOnboardingFlow()

  const useFullWidthButton = useMediaQuery((theme: any) => theme.breakpoints.down('lg'))

  const onGoToStart = () => {
    onRestartFlow()
  }

  const onSkip = () => {
    trackSkippedStep({ step, history }, baseProps.totalStepCount, fireSnowplowEvent)

    closeOnBoardingWithNoTracking()
  }

  return (
    <>
      <HTypography
        variant='h4'
        color='surface_gray_90'
      >
        <FormattedMessage id={messageId} />
      </HTypography>
      <HBox
        width='100%'
        marginTop='auto'
        marginBottom={10}
        lg={{
          mt: 14
        }}
      >
        <HBox mb={6}>
          <HButton
            fullWidth={useFullWidthButton}
            onClick={onGoToStart}
            variant='contained'
            color='primary'
            text={intl.formatMessage({ id: restartButtonLabel })}
            data-test-id='onboarding-flow-restart-button'
          />
        </HBox>
        <HButton
          fullWidth={useFullWidthButton}
          onClick={onSkip}
          variant='text'
          color='neutral'
          text={intl.formatMessage({ id: 'skip_for_now' })}
          data-test-id='onboarding-flow-skip-button'
        />
      </HBox>
    </>
  )
}
