import { AutocompletePrediction, AutocompleteService, AutocompletionRequest, PlacesService, PlacesServiceStatus } from 'google.maps'
import throttle from 'lodash.throttle'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import useScript from './use-script'
import { GetPlaceDetail } from './types'
import { getPlaceDetail } from '../location-selector/get-place-detail'

interface MapsApiConfig {
  locale: string
  apiKey: string
}

type PredictionCallback = (a: AutocompletePrediction[] | null, b: PlacesServiceStatus) => void

interface MapServices {
  autoCompleteService?: AutocompleteService
  placesService?: PlacesService
}

export const createMapUrl = ({ apiKey, locale }: MapsApiConfig) => {
  return `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&language=${locale}`
}

const createSessionToken = () => {
  return typeof window !== 'undefined' && window.google?.maps?.places?.AutocompleteSessionToken ? new window.google.maps.places.AutocompleteSessionToken() : undefined
}

const isGoogleAvailable = () => window.google?.maps?.places?.AutocompleteService && window.google?.maps?.places?.PlacesService

export const useMapsApi = (config: MapsApiConfig) => {
  const { isLoaded } = useScript('google-maps', createMapUrl(config))
  const [mapsService, setMapsServices] = useState<MapServices>()

  const sessionToken = useRef<google.maps.places.AutocompleteSessionToken | undefined>(createSessionToken())

  const fetchThrottled = useMemo(
    () =>
      throttle((request: AutocompletionRequest,
        callback: PredictionCallback) => {
        mapsService?.autoCompleteService?.getPlacePredictions({
          ...request,
          sessionToken: sessionToken.current,
        }, callback)
      }, 200),
    [mapsService]
  )

  const getPlaceDetailWrapper = useCallback<GetPlaceDetail>((prediction, callback) => {
    if (mapsService?.placesService === undefined) {
      return
    }

    const getPlaceDetailSessionToken = sessionToken.current

    sessionToken.current = createSessionToken()

    getPlaceDetail(mapsService?.placesService!, prediction, getPlaceDetailSessionToken, callback)
  }, [mapsService])

  React.useEffect(() => {
    if (isLoaded && isGoogleAvailable() && !mapsService) {
      const services = {
        autoCompleteService: new window.google.maps.places.AutocompleteService(),
        placesService: new window.google.maps.places.PlacesService(document.createElement('div')),
      }
      setMapsServices(services)

      if (!sessionToken.current) {
        sessionToken.current = createSessionToken()
      }
    }
  }, [isLoaded, mapsService])

  return { fetchThrottled, getPlaceDetail: getPlaceDetailWrapper }
}
