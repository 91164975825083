import React from 'react'
import { HSvgIcon, HSvgIconProps } from '../HSvgIcon'

export const ProfileCompletedAwardIcon: React.FC<HSvgIconProps> = (props) => {
  return (
    <HSvgIcon width="44" height="58" viewBox="0 -1 44 59" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g className="sparkle-top-left">
        <path d="M16.6088 4.22057C16.2887 4.22057 16.0269 3.95439 16.0269 3.62907V0.257516C16.0269 -0.0678091 16.2887 -0.333984 16.6088 -0.333984C16.9289 -0.333984 17.1907 -0.0678091 17.1907 0.257516V3.62907C17.1907 3.95439 16.9289 4.22057 16.6088 4.22057Z" fill="#CE6D9C"/>
        <path d="M18.2613 2.54042H14.9501C14.63 2.54042 14.3682 2.27425 14.3682 1.94892C14.3682 1.6236 14.63 1.35742 14.9501 1.35742H18.2613C18.5814 1.35742 18.8432 1.6236 18.8432 1.94892C18.8432 2.27425 18.5814 2.54042 18.2613 2.54042Z" fill="#CE6D9C"/>
      </g>
      <g className="sparkle-top-right">
        <path d="M30.6796 7.15429C30.3595 7.15429 30.0977 6.88812 30.0977 6.56279V3.80048C30.0977 3.47516 30.3595 3.20898 30.6796 3.20898C30.9997 3.20898 31.2615 3.47516 31.2615 3.80048V6.56279C31.2615 6.88812 30.9997 7.15429 30.6796 7.15429Z" fill="#DA9BB9"/>
        <path d="M32.0413 5.77577H29.3236C29.0036 5.77577 28.7417 5.5096 28.7417 5.18427C28.7417 4.85895 29.0036 4.59277 29.3236 4.59277H32.0413C32.3613 4.59277 32.6232 4.85895 32.6232 5.18427C32.6232 5.5096 32.3613 5.77577 32.0413 5.77577Z" fill="#DA9BB9"/>
      </g>
      <g className="sparkle-bottom-right">
        <path d="M40.8515 39.3617C40.5314 39.3617 40.2695 39.0956 40.2695 38.7702V34.5114C40.2695 34.1861 40.5314 33.9199 40.8515 33.9199C41.1715 33.9199 41.4334 34.1861 41.4334 34.5114V38.7702C41.4334 39.0956 41.1715 39.3617 40.8515 39.3617Z" fill="#DA9BB9"/>
        <path d="M42.9467 37.2328H38.7567C38.4367 37.2328 38.1748 36.9666 38.1748 36.6413C38.1748 36.316 38.4367 36.0498 38.7567 36.0498H42.9467C43.2667 36.0498 43.5286 36.316 43.5286 36.6413C43.5286 36.9666 43.2667 37.2328 42.9467 37.2328Z" fill="#DA9BB9"/>
      </g>
      <g className="sparkle-bottom-left">
        <path d="M3.60776 28.2184C3.12476 28.2184 2.73486 27.8221 2.73486 27.3311V21.771C2.73486 21.2801 3.12476 20.8838 3.60776 20.8838C4.09077 20.8838 4.48066 21.2801 4.48066 21.771V27.3311C4.48066 27.8221 4.09077 28.2184 3.60776 28.2184Z" fill="#CE6D9C"/>
        <path d="M6.34308 25.4383H0.8729C0.389896 25.4383 0 25.042 0 24.5511C0 24.0601 0.389896 23.6638 0.8729 23.6638H6.34308C6.82608 23.6638 7.21598 24.0601 7.21598 24.5511C7.21598 25.042 6.82608 25.4383 6.34308 25.4383Z" fill="#CE6D9C"/>
      </g>
      <g className="goblet">
        <path d="M24.4471 49.0687C24.3307 49.0687 24.2143 49.0451 24.1038 48.9978L16.5095 45.7268C16.1313 45.5671 15.916 45.1589 15.9916 44.7508C16.0673 44.3427 16.4048 44.041 16.818 44.0232C17.0158 44.0114 21.9041 43.621 21.9041 34.8077C21.9041 34.3167 22.2939 33.9204 22.777 33.9204H26.1173C26.6003 33.9204 26.9902 34.3167 26.9902 34.8077C26.9902 43.621 31.8784 44.0114 32.0821 44.0232C32.4894 44.0469 32.8269 44.3486 32.8968 44.7567C32.9666 45.1648 32.7571 45.5611 32.3847 45.7268L24.7904 48.9978C24.6799 49.0451 24.5635 49.0687 24.4471 49.0687Z" fill="#F7CD55"/>
        <path d="M26.1171 34.8072C26.1171 44.6675 32.0412 44.91 32.0412 44.91L24.447 48.181L16.8527 44.91C16.8527 44.91 22.7768 44.6675 22.7768 34.8072H26.1171ZM26.1171 33.0327H22.7768C21.8108 33.0327 21.031 33.8253 21.031 34.8072C21.031 42.5263 17.1088 43.1001 16.7655 43.1355C15.9449 43.1474 15.2699 43.7389 15.1069 44.5551C14.944 45.3832 15.4037 46.2113 16.1719 46.5367L23.7661 49.8077C23.9814 49.9023 24.2142 49.9496 24.447 49.9496C24.6797 49.9496 24.9125 49.9023 25.1278 49.8077L32.7221 46.5367C33.4844 46.2054 33.95 45.3773 33.787 44.5551C33.6241 43.733 32.949 43.1415 32.1285 43.1355C31.791 43.1001 27.8629 42.5263 27.8629 34.8072C27.8629 33.8253 27.0831 33.0327 26.1171 33.0327Z" fill="#4C526B"/>
        <path d="M32.0413 44.0232H16.8529C16.3708 44.0232 15.98 44.4204 15.98 44.9104V51.4584C15.98 51.9484 16.3708 52.3456 16.8529 52.3456H32.0413C32.5234 52.3456 32.9142 51.9484 32.9142 51.4584V44.9104C32.9142 44.4204 32.5234 44.0232 32.0413 44.0232Z" fill="#83A6E9"/>
        <path d="M32.0412 44.91V51.4579H16.8527V44.91H32.0412ZM32.0412 43.1355H16.8527C15.8867 43.1355 15.1069 43.9281 15.1069 44.91V51.4579C15.1069 52.4398 15.8867 53.2324 16.8527 53.2324H32.0412C33.0072 53.2324 33.787 52.4398 33.787 51.4579V44.91C33.787 43.9281 33.0072 43.1355 32.0412 43.1355Z" fill="#4C526B"/>
        <path d="M11.9295 57.1135C11.4465 57.1135 11.0566 56.7172 11.0566 56.2262V55.0196C11.0566 52.2514 13.268 50.0037 15.9914 50.0037H32.8908C35.6142 50.0037 37.8256 52.2573 37.8256 55.0196V56.2262C37.8256 56.7172 37.4357 57.1135 36.9527 57.1135H11.9295Z" fill="#FEDD84"/>
        <path d="M32.8965 50.8905C35.1428 50.8905 36.9584 52.7419 36.9584 55.0191V56.2258H11.9294V55.0191C11.9294 52.7359 13.7509 50.8905 15.9913 50.8905H32.8907M32.8907 49.116H15.9971C12.7965 49.116 10.1895 51.7659 10.1895 55.0191V56.2258C10.1895 57.2077 10.9692 58.0003 11.9353 58.0003H36.9584C37.9244 58.0003 38.7042 57.2077 38.7042 56.2258V55.0191C38.7042 51.7659 36.0971 49.116 32.8965 49.116H32.8907Z" fill="#4C526B"/>
        <path d="M13.9664 28.5729C6.04046 23.5866 4.23065 18.2394 5.08609 15.1754C5.60401 13.324 7.09958 12.1765 8.98504 12.1765H12.6512C13.1342 12.1765 13.5241 12.5728 13.5241 13.0638C13.5241 13.5547 13.1342 13.951 12.6512 13.951H8.98504C7.87937 13.951 7.07048 14.578 6.76206 15.6605C6.01718 18.3281 8.24017 22.8827 14.88 27.0646L13.9606 28.5729H13.9664Z" fill="#4C526B"/>
        <path d="M34.9278 28.5729L34.0083 27.0646C40.654 22.8827 42.8712 18.3281 42.1263 15.6605C41.8237 14.5721 41.0148 13.951 39.9033 13.951H36.2371C35.7541 13.951 35.3642 13.5547 35.3642 13.0638C35.3642 12.5728 35.7541 12.1765 36.2371 12.1765H39.9033C41.7888 12.1765 43.2843 13.324 43.8022 15.1754C44.6577 18.2394 42.8479 23.5866 34.9219 28.5729H34.9278Z" fill="#4C526B"/>
        <path d="M24.447 39.0073C21.7352 39.0073 19.3958 37.9662 17.4871 35.9137C10.6726 28.5791 11.6037 10.6212 11.6444 9.86405C11.6677 9.39676 12.0518 9.03003 12.5173 9.03003H36.3708C36.8305 9.03003 37.2146 9.39676 37.2437 9.86405C37.2844 10.6271 38.2155 28.585 31.4011 35.9137C29.4923 37.9662 27.153 39.0073 24.4412 39.0073H24.447Z" fill="#FEDD84"/>
        <path d="M36.371 9.91098C36.371 9.91098 37.9597 38.1196 24.4414 38.1196C10.9231 38.1196 12.5176 9.91098 12.5176 9.91098H36.371ZM36.371 8.13647H12.5176C11.5923 8.13647 10.83 8.86994 10.7718 9.81042C10.7601 10.0056 10.5157 14.5838 11.0918 20.1203C11.8949 27.7565 13.8269 33.2753 16.8472 36.5226C18.9247 38.7585 21.4794 39.8941 24.4414 39.8941C27.4034 39.8941 29.9581 38.7585 32.0356 36.5226C35.0559 33.2753 36.9937 27.7506 37.791 20.1203C38.3729 14.5838 38.1227 10.0056 38.111 9.81042C38.0586 8.86994 37.2963 8.13647 36.3652 8.13647H36.371Z" fill="#4C526B"/>
      </g>
    </HSvgIcon>
  )
}
