import Popper from '@mui/material/Popper'
import Autocomplete from '@mui/material/Autocomplete'
import React from 'react'
import styled from '@emotion/styled'
import { HIcon } from '../HIcon'
import { COLORS, globalStyles } from '../theme'
import { IHAutocompleteProps } from './HAutocomplete.types'
import { AutocompleteListBox } from './Listbox'
import { HInput } from '../HInput'

const StyledAutocomplete = styled(Autocomplete)`
  .MuiAutocomplete-endAdornment {
    .MuiSvgIcon-root {
      color: ${COLORS.surface_gray_90};
    }
  }
  .MuiInputAdornment-positionEnd {
    cursor: pointer;
  }
  > .MuiFormControl-root > .MuiInputBase-root {
    padding: 11px 12px !important;
    cursor: default;
  }
  .MuiInputBase-input {
    padding: 0px !important;
  }
  .MuiInputBase-input::placeholder {
    color: ${COLORS.surface_gray_60};
    opacity: 1;
  }
`

const PopperComponent = styled(Popper)`
  .MuiPaper-root {
    box-shadow: ${globalStyles.shadow3.boxShadow};
    border-radius: 4px;
    margin: 8px 0px;
    background-color: ${COLORS.surface_white};
  }
  .MuiAutocomplete-noOptions {
    display: none;
  }
`

const defaultRenderOption =
  (props: React.HTMLAttributes<HTMLLIElement>,
    option: string | { label: string }): React.ReactNode => {
    return <li {...props}>{typeof option === 'string' ? option : option.label}</li>
  }

export function HAutocomplete<
  T,
  Multiple extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> (props: IHAutocompleteProps<T, Multiple, true, FreeSolo>): JSX.Element {
  const {
    label,
    caption,
    error,
    InputProps: overrideInputProps,
    disabled,
    icon,
    enableSearch,
    onInputChange,
    renderOption = defaultRenderOption,
    HInputProps,
    forceShrink,
    ...others
  } = props

  const [shrink, setShrink] = React.useState(typeof others.focused === 'string' ? others.focused === 'true' : others.focused)

  return (
    <StyledAutocomplete
      size='small'
      disabled={disabled}
      onInputChange={(event, value, reason) => {
        setShrink(!!value)
        onInputChange?.(event, value, reason)
      }}
      PopperComponent={PopperComponent}
      renderInput={({ InputProps, ...props }) => {
        return (
          <HInput
            label={label}
            {...props}
            {...HInputProps}
            caption={caption}
            startAdornment={icon && <HIcon Icon={icon} />}
            forceShrink={forceShrink || shrink}
            error={error}
            disabled={disabled}
            InputProps={{ ...InputProps, ...overrideInputProps }}
            readOnly={!enableSearch}
          />
        )
      }}
      selectOnFocus={false}
      ListboxComponent={AutocompleteListBox}
      disableClearable
      /* @ts-expect-error props don't match any expected overload */
      renderOption={renderOption}
      {...others}
    />
  )
}
