import React, { type ReactElement, useEffect } from 'react'
import { HBox, HMenuIndicator } from 'talent-ui'
import {
  useShowProfileCompletionIndicator, type UseShowProfileCompletionIndicatorProps,
} from './use-show-profile-completion-indicator'
import { UserProfileCompletionStatus } from '../types/user'

export type ProfileCompletionIndicatorProps =
  UseShowProfileCompletionIndicatorProps &
  {
    strokeWidth?: number,
  }

type ProfileCompletionIndicatorPropsExtended = ProfileCompletionIndicatorProps & {
  profileCompletionStatus: UserProfileCompletionStatus | undefined
}

export const CompleteYourProfileIndicator = ({ strokeWidth, profileCompletionStatus, ...hookProps }: ProfileCompletionIndicatorPropsExtended): ReactElement => {
  const { showIndicator, setIndicatorSeen } = useShowProfileCompletionIndicator(hookProps, profileCompletionStatus)
  const indicatorIdentifier = hookProps.keyName

  useEffect(() => {
    const setRelevantLocalStorageItems = () => {
      setIndicatorSeen()
    }

    window.addEventListener('beforeunload', setRelevantLocalStorageItems)

    return () => {
      window.removeEventListener('beforeunload', setRelevantLocalStorageItems)
      setRelevantLocalStorageItems()
    }
  }, [indicatorIdentifier, setIndicatorSeen])

  return showIndicator
    ? (
      <HBox
        flex={1}
        paddingLeft={1}
        alignSelf='auto'
        data-test-id={`${indicatorIdentifier}-indicator`}
      >
        <HMenuIndicator
          size={12}
          animate={hookProps.keyType === 'dataPoint'}
          strokeWidth={strokeWidth}
        />
      </HBox>
      )
    : <></>
}

export const createCompleteYourProfileIndicatorWrapper = (
  profileCompletionStatus: UserProfileCompletionStatus | undefined
): React.FC<ProfileCompletionIndicatorProps> => {
  return (props) => (
    <CompleteYourProfileIndicator
      profileCompletionStatus={profileCompletionStatus}
      {...props}
    />
  )
}
