import * as searchUrl from './searchUrl'

export const moreJobsLink = ({ currentJob, locale, country }) => {
  const keyword = currentJob?.title
  const location = currentJob?.location?.city

  return searchUrl.build(
    {
      location,
      keyword,
    },
    locale,
    country
  )
}
