import { SeoLink } from './HMainFooter.types'

export const enum SeoPage {
  JDP_SERP = 'JDP_SERP',
  MAIN = 'MAIN',
}

export const enum Country {
  de = 'de',
  at = 'at',
}

export const enum Locale {
  de = 'de',
  en = 'en',
}

const SEO_LINKS = {
  [SeoPage.JDP_SERP]: {
    [Country.de]: {
      [Locale.de]: [
        { href: '/de-de/jobs-als-Minijob', title: 'Minijob Jobs' },
        { href: '/de-de/jobs-in-Berlin', title: 'Jobs in Berlin' },
        { href: '/de-de/jobs-als-Home+Office', title: 'Home Office Jobs' },
        { href: '/de-de/jobs-in-Hamburg', title: 'Jobs in Hamburg' },
        { href: '/de-de/jobs-als-Quereinsteiger', title: 'Quereinsteiger Jobs' },
        { href: '/de-de/jobs-in-M%C3%BCnchen', title: 'Jobs in München' },
        { href: '/de-de/jobs-als-Azubi', title: 'Ausbildungsstellen' },
        { href: '/de-de/jobs-in-K%C3%B6ln', title: 'Jobs in Köln' },
        { href: '/de-de/jobs-mit-Teilzeit', title: 'Teilzeit Jobs' },
        { href: '/de-de/jobs-in-Frankfurt', title: 'Jobs in Frankfurt' },
        { href: '/de-de/jobs-mit-Vollzeit', title: 'Vollzeit Jobs' },
        { href: '/de-de/jobs-in-Stuttgart', title: 'Jobs in Stuttgart' },
        { href: '/de-de/jobs-als-Werkstudent', title: 'Werkstudent Jobs' },
        { href: '/de-de/jobs-in-D%C3%BCsseldorf', title: 'Jobs in Düsseldorf' },
        { href: '/de-de/jobs-als-Lidl', title: 'Lidl Jobs' },
        { href: '/de-de/jobs-als-Aushilfe', title: 'Aushilfe Jobs' },
        { href: '/de-de/jobs-als-Picnic', title: 'Picnic Jobs' },
        { href: '/de-de/jobs-als-Au%C3%9Fendienst', title: 'Außendienst Jobs' },
        { href: '/de-de/jobs-als-Amazon', title: 'Amazon Jobs' },
        { href: '/de-de/jobs-als-Fahrer', title: 'Fahrer Jobs' },
        { href: '/de-de/jobs-als-Deutsche+Bahn', title: 'Deutsche Bahn Jobs' },
        { href: '/de-de/jobs-als-Call+Center', title: 'Call Center Jobs' },
        { href: '/de-de/jobs-als-Siemens', title: 'Siemens Jobs' },
        { href: '/de-de/jobs-als-Hausmeister', title: 'Hausmeister Jobs' },
        { href: '/de-de/jobs-als-REWE', title: 'REWE Jobs' },
        { href: '/de-de/jobs-als-Marketing', title: 'Marketing Jobs' },
        { href: '/de-de/jobs-als-Aldi', title: 'Aldi Jobs' },
        { href: '/de-de/jobs-als-%C3%96ffentlicher+Dienst', title: 'Öffentlicher Dienst Jobs' },
      ] as SeoLink[],
      [Locale.en]: [
        { href: '/en-de/jobs-as-Minijob', title: 'Minijob Jobs' },
        { href: '/en-de/jobs-in-Berlin', title: 'Jobs in Berlin' },
        { href: '/en-de/jobs-as-Home+Office', title: 'Home Office Jobs' },
        { href: '/en-de/jobs-in-Hamburg', title: 'Jobs in Hamburg' },
        { href: '/en-de/jobs-as-Quereinsteiger', title: 'Career Changer Jobs' },
        { href: '/en-de/jobs-in-M%C3%BCnchen', title: 'Jobs in Munich' },
        { href: '/en-de/jobs-as-Azubi', title: 'Training and Apprenticeships' },
        { href: '/en-de/jobs-in-K%C3%B6ln', title: 'Jobs in Cologne' },
        { href: '/en-de/jobs-with-%22Part-time%22', title: 'Part-Time Jobs' },
        { href: '/en-de/jobs-in-Frankfurt', title: 'Jobs in Frankfurt' },
        { href: '/en-de/jobs-with-%22Full-time%22', title: 'Full-Time Jobs' },
        { href: '/en-de/jobs-in-Stuttgart', title: 'Jobs in Stuttgart' },
        { href: '/en-de/jobs-as-Werkstudent', title: 'Jobs for Working Students' },
        { href: '/en-de/jobs-in-D%C3%BCsseldorf', title: 'Jobs in Dusseldorf' },
        { href: '/en-de/jobs-as-Lidl', title: 'Lidl Jobs' },
        { href: '/en-de/jobs-as-Aushilfe', title: 'Temporary Help' },
        { href: '/en-de/jobs-as-Picnic', title: 'Picnic Jobs' },
        { href: '/en-de/jobs-as-Au%C3%9Fendienst', title: 'Sales Force Jobs' },
        { href: '/en-de/jobs-as-Amazon', title: 'Amazon Jobs' },
        { href: '/en-de/jobs-as-Fahrer', title: 'Driver Jobs' },
        { href: '/en-de/jobs-as-Deutsche+Bahn', title: 'Deutsche Bahn Jobs' },
        { href: '/en-de/jobs-as-Call+Center', title: 'Call Center Jobs' },
        { href: '/en-de/jobs-as-Siemens', title: 'Siemens Jobs' },
        { href: '/en-de/jobs-as-Hausmeister', title: 'Custodian Jobs' },
        { href: '/en-de/jobs-as-REWE', title: 'REWE Jobs' },
        { href: '/en-de/jobs-as-Marketing', title: 'Marketing Jobs' },
        { href: '/en-de/jobs-as-Aldi', title: 'Aldi Jobs' },
        { href: '/en-de/jobs-as-%C3%96ffentlicher+Dienst', title: 'Civil Service Jobs' },
      ] as SeoLink[],
    },
    [Country.at]: {
      [Locale.de]: [
        { href: '/de-at/jobs-als-Studentenjob', title: 'Studentenjobs' },
        { href: '/de-at/jobs-in-Wien', title: 'Jobs in Wien' },
        { href: '/de-at/jobs-fuer-Azubi', title: 'Ausbildungsstellen' },
        { href: '/de-at/jobs-in-Graz', title: 'Jobs in Graz' },
        { href: '/de-at/jobs-als-Remote', title: 'Remote Jobs' },
        { href: '/de-at/jobs-in-Salzburg', title: 'Jobs in Salzburg' },
        { href: '/de-at/jobs-als-Home+Office', title: 'Home Office Jobs' },
        { href: '/de-at/jobs-in-Linz', title: 'Jobs in Linz' },
        { href: '/de-at/jobs-als-Online', title: 'Online Jobs' },
        { href: '/de-at/jobs-in-Klagenfurt', title: 'Jobs in Klagenfurt' },
        { href: '/de-at/jobs-als-Quereinsteiger', title: 'Quereinsteiger Jobs' },
        { href: '/de-at/jobs-in-Innsbruck', title: 'Jobs in Innsbruck' },
        { href: '/de-at/jobs-mit-Teilzeit', title: 'Teilzeit Jobs' },
        { href: '/de-at/jobs-in-Steyr', title: 'Jobs in Steyr' },
        { href: '/de-at/jobs-als-%C3%96BB', title: 'ÖBB Jobs' },
        { href: '/de-at/jobs-als-Kurier', title: 'Kurier Jobs' },
        { href: '/de-at/jobs-als-Caritas', title: 'Caritas Jobs' },
        { href: '/de-at/jobs-als-Apotheke', title: 'Apotheke Jobs' },
        { href: '/de-at/jobs-als-HOFER', title: 'HOFER Jobs' },
        { href: '/de-at/jobs-als-Lagerhaus', title: 'Lagerhaus Jobs' },
        { href: '/de-at/jobs-als-Voestalpine', title: 'Voestalpine Jobs' },
        { href: '/de-at/jobs-als-IT', title: 'IT Jobs' },
        { href: '/de-at/jobs-als-IKEA', title: 'IKEA Jobs' },
        { href: '/de-at/jobs-als-Security', title: 'Security Jobs' },
        { href: '/de-at/jobs-als-BILLA', title: 'BILLA Jobs' },
        { href: '/de-at/jobs-als-B%C3%BCro', title: 'Büro Jobs' },
        { href: '/de-at/jobs-als-Siemens', title: 'Siemens Jobs' },
        { href: '/de-at/jobs-als-Personalverrechner', title: 'Personalverrechner Jobs' },
      ] as SeoLink[],
      [Locale.en]: [
        { href: '/en-at/jobs-as-Studentenjob', title: 'Student Jobs' },
        { href: '/en-at/jobs-in-Wien', title: 'Jobs in Vienna' },
        { href: '/en-at/jobs-for-Apprenticeship', title: 'Training and Apprenticeships' },
        { href: '/en-at/jobs-in-Graz', title: 'Jobs in Graz' },
        { href: '/en-at/jobs-as-Remote', title: 'Remote Jobs' },
        { href: '/en-at/jobs-in-Salzburg', title: 'Jobs in Salzburg' },
        { href: '/en-at/jobs-as-Home+Office', title: 'Home Office Jobs' },
        { href: '/en-at/jobs-in-Linz', title: 'Jobs in Linz' },
        { href: '/en-at/jobs-as-Online', title: 'Online Jobs' },
        { href: '/en-at/jobs-in-Klagenfurt', title: 'Jobs in Klagenfurt' },
        { href: '/en-at/jobs-as-Quereinsteiger', title: 'Career Changer Jobs' },
        { href: '/en-at/jobs-in-Innsbruck', title: 'Jobs in Innsbruck' },
        { href: '/en-at/jobs-with-%22Part-time%22', title: 'Part-Time Jobs' },
        { href: '/en-at/jobs-in-Steyr', title: 'Jobs in Steyr' },
        { href: '/en-at/jobs-as-%C3%96BB', title: 'ÖBB Jobs' },
        { href: '/en-at/jobs-as-Kurier', title: 'Courier Jobs' },
        { href: '/en-at/jobs-as-Caritas', title: 'Caritas Jobs' },
        { href: '/en-at/jobs-as-Apotheke', title: 'Pharmacy Jobs' },
        { href: '/en-at/jobs-as-HOFER', title: 'HOFER Jobs' },
        { href: '/en-at/jobs-as-Lagerhaus', title: 'Warehouse Jobs' },
        { href: '/en-at/jobs-as-Voestalpine', title: 'Voestalpine Jobs' },
        { href: '/en-at/jobs-as-IT', title: 'IT Jobs' },
        { href: '/en-at/jobs-as-IKEA', title: 'IKEA Jobs' },
        { href: '/en-at/jobs-as-Security', title: 'Security Jobs' },
        { href: '/en-at/jobs-as-BILLA', title: 'BILLA Jobs' },
        { href: '/en-at/jobs-as-B%C3%BCro', title: 'Office Jobs' },
        { href: '/en-at/jobs-as-Siemens', title: 'Siemens Jobs' },
        { href: '/en-at/jobs-as-Personalverrechner', title: 'Accountant Jobs' },
      ] as SeoLink[],
    },
  },
  [SeoPage.MAIN]: {
    [Country.de]: {
      [Locale.de]: [
        { href: '/de-de/jobs-als-DHL', title: 'DHL Jobs' },
        { href: '/de-de/jobs-fuer-Praktikum', title: 'Praktikumsplätze' },
        { href: '/de-de/jobs-als-Rossmann', title: 'Rossmann Jobs' },
        { href: '/de-de/jobs-als-Azubi', title: 'Ausbildungsstellen' },
        { href: '/de-de/jobs-als-Boehringer+Ingelheim', title: 'Boehringer Ingelheim Jobs' },
        { href: '/de-de/jobs-als-Ohne+Ausbildung', title: 'Jobs ohne Ausbildung' },
        { href: '/de-de/jobs-als-Bosch', title: 'Bosch Jobs' },
        { href: '/de-de/jobs-als-Remote', title: 'Remote Jobs' },
        { href: '/de-de/jobs-als-BMW', title: 'BMW Jobs' },
        { href: '/de-de/jobs-als-Freelancer', title: 'Freelancer Jobs' },
        { href: '/de-de/jobs-als-Edeka', title: 'EDEKA Jobs' },
        { href: '/de-de/jobs-als-Ferienjob', title: 'Ferienjobs' },
        { href: '/de-de/jobs-als-Picnic', title: 'Picnic Jobs' },
        { href: '/de-de/jobs-als-FSJ', title: 'FSJ Stellen' },
        { href: '/de-de/jobs-als-Bundesagentur+f%C3%BCr+Arbeit', title: 'Agentur für Arbeit Jobs' },
        { href: '/de-de/jobs-als-IT', title: 'IT Jobs' },
        { href: '/de-de/jobs-als-Kaufland', title: 'Kaufland Jobs' },
        { href: '/de-de/jobs-als-Hausmeister', title: 'Hausmeister Jobs' },
        { href: '/de-de/jobs-als-Apple', title: 'Apple Jobs' },
        { href: '/de-de/jobs-als-Gesundheitsmanagement', title: 'Gesundheitsmanagement Jobs' },
        { href: '/de-de/jobs-als-Tesla', title: 'Tesla Jobs' },
        { href: '/de-de/jobs-als-Marketing', title: 'Marketing Jobs' },
        { href: '/de-de/jobs-als-NETTO', title: 'NETTO Jobs' },
        { href: '/de-de/jobs-als-Logistik', title: 'Logistik Jobs' },
        { href: '/de-de/jobs-als-AIDA', title: 'AIDA Jobs' },
        { href: '/de-de/jobs-als-Industriemechaniker', title: 'Industriemechaniker Jobs' },
        { href: '/de-de/jobs-als-BVG', title: 'BVG Jobs' },
        { href: '/de-de/jobs-als-Sozialarbeiter', title: 'Sozialarbeiter Jobs' },
      ] as SeoLink[],
      [Locale.en]: [
        { href: '/en-de/jobs-as-DHL', title: 'DHL Jobs' },
        { href: '/en-de/jobs-for-Internship', title: 'Internships' },
        { href: '/en-de/jobs-as-Rossmann', title: 'Rossmann Jobs' },
        { href: '/en-de/jobs-as-Azubi', title: 'Training and Apprenticeships' },
        { href: '/en-de/jobs-as-Boehringer+Ingelheim', title: 'Boehringer Ingelheim Jobs' },
        { href: '/en-de/jobs-as-Ohne+Ausbildung', title: 'Jobs without Apprenticeship' },
        { href: '/en-de/jobs-as-Bosch', title: 'Bosch Jobs' },
        { href: '/en-de/jobs-as-Remote', title: 'Remote Jobs' },
        { href: '/en-de/jobs-as-BMW', title: 'BMW Jobs' },
        { href: '/en-de/jobs-as-Freelancer', title: 'Freelancer Jobs' },
        { href: '/en-de/jobs-as-Edeka', title: 'EDEKA Jobs' },
        { href: '/en-de/jobs-as-Ferienjob', title: 'Vacation Jobs' },
        { href: '/en-de/jobs-as-Picnic', title: 'Picnic Jobs' },
        { href: '/en-de/jobs-as-FSJ', title: 'Voluntary Social Year Jobs' },
        { href: '/en-de/jobs-as-Bundesagentur+f%C3%BCr+Arbeit', title: 'Federal Employment Agency Jobs' },
        { href: '/en-de/jobs-as-IT', title: 'IT Jobs' },
        { href: '/en-de/jobs-as-Kaufland', title: 'Kaufland Jobs' },
        { href: '/en-de/jobs-as-Hausmeister', title: 'Custodian Jobs' },
        { href: '/en-de/jobs-as-Apple', title: 'Apple Jobs' },
        { href: '/en-de/jobs-as-Gesundheitsmanagement', title: 'Health Management Jobs' },
        { href: '/en-de/jobs-as-Tesla', title: 'Tesla Jobs' },
        { href: '/en-de/jobs-as-Marketing', title: 'Marketing Jobs' },
        { href: '/en-de/jobs-as-NETTO', title: 'NETTO Jobs' },
        { href: '/en-de/jobs-as-Logistik', title: 'Logistics Jobs' },
        { href: '/en-de/jobs-as-AIDA', title: 'AIDA Jobs' },
        { href: '/en-de/jobs-as-Industriemechaniker', title: 'Industrial Mechanic Jobs' },
        { href: '/en-de/jobs-as-BVG', title: 'BVG Jobs' },
        { href: '/en-de/jobs-as-Sozialarbeiter', title: 'Social Worker Jobs' },
      ] as SeoLink[],
    },
    [Country.at]: {
      [Locale.de]: [
        { href: '/de-at/jobs-als-Red+Bull', title: 'Red Bull Jobs' },
        { href: '/de-at/jobs-fuer-Azubi', title: 'Ausbildungsstellen' },
        { href: '/de-at/jobs-als-Voestalpine', title: 'Voestalpine Jobs' },
        { href: '/de-at/jobs-als-Online', title: 'Online Jobs' },
        { href: '/de-at/jobs-als-Siemens', title: 'Siemens Jobs' },
        { href: '/de-at/jobs-als-Freelancer', title: 'Freelancer Jobs' },
        { href: '/de-at/jobs-als-Boehringer+Ingelheim', title: 'Boehringer Ingelheim Jobs' },
        { href: '/de-at/jobs-als-Nachtschicht', title: 'Nachtschicht Jobs' },
        { href: '/de-at/jobs-als-%C3%96sterreichische+Gesundheitskasse', title: 'ÖGK Jobs' },
        { href: '/de-at/jobs-als-Pensionisten', title: 'Jobs für Pensionisten' },
        { href: '/de-at/jobs-als-KELAG', title: 'KELAG Jobs' },
        { href: '/de-at/jobs-fuer-Minijob', title: 'Minijob Jobs' },
        { href: '/de-at/jobs-als-ASFINAG', title: 'ASFINAG Jobs' },
        { href: '/de-at/jobs-als-Wochenend', title: 'Wochenendjobs' },
        { href: '/de-at/jobs-in-Steyr', title: 'Jobs in Steyr' },
        { href: '/de-at/jobs-als-NGO', title: 'NGO Jobs' },
        { href: '/de-at/jobs-in-Bad+Ischl', title: 'Jobs in Bad Ischl' },
        { href: '/de-at/jobs-als-Uni', title: 'Uni Jobs' },
        { href: '/de-at/jobs-in-Parndorf', title: 'Jobs in Parndorf' },
        { href: '/de-at/jobs-als-PKA', title: 'PKA Jobs' },
        { href: '/de-at/jobs-in-Wels', title: 'Jobs in Wels' },
        { href: '/de-at/jobs-als-Justiz', title: 'Justiz Jobs' },
        { href: '/de-at/jobs-in-Steiermark', title: 'Jobs in Steiermark' },
        { href: '/de-at/jobs-als-SAP', title: 'SAP Jobs' },
        { href: '/de-at/jobs-in-Villach', title: 'Jobs in Villach' },
        { href: '/de-at/jobs-als-Busfahrer', title: 'Busfahrer Jobs' },
        { href: '/de-at/jobs-in-Eisenstadt', title: 'Jobs in Eisenstadt' },
        { href: '/de-at/jobs-als-Marketing', title: 'Marketing Jobs' },
      ] as SeoLink[],
      [Locale.en]: [
        { href: '/en-at/jobs-as-Red+Bull', title: 'Red Bull Jobs' },
        { href: '/en-at/jobs-for-Apprenticeship', title: 'Training and Apprenticeships' },
        { href: '/en-at/jobs-as-Voestalpine', title: 'Voestalpine Jobs' },
        { href: '/en-at/jobs-as-Online', title: 'Online Jobs' },
        { href: '/en-at/jobs-as-Siemens', title: 'Siemens Jobs' },
        { href: '/en-at/jobs-as-Freelancer', title: 'Freelancer Jobs' },
        { href: '/en-at/jobs-as-Boehringer+Ingelheim', title: 'Boehringer Ingelheim Jobs' },
        { href: '/en-at/jobs-as-Nachtschicht', title: 'Night Shift Jobs' },
        { href: '/en-at/jobs-as-%C3%96sterreichische+Gesundheitskasse', title: 'ÖGK Jobs' },
        { href: '/en-at/jobs-as-Pensionisten', title: 'Jobs for Pensioners' },
        { href: '/en-at/jobs-as-KELAG', title: 'KELAG Jobs' },
        { href: '/en-at/jobs-as-Minijob', title: 'Minijob Jobs' },
        { href: '/en-at/jobs-as-ASFINAG', title: 'ASFINAG Jobs' },
        { href: '/en-at/jobs-as-Wochenend', title: 'Weekend Jobs' },
        { href: '/en-at/jobs-in-Steyr', title: 'Jobs in Steyr' },
        { href: '/en-at/jobs-as-NGO', title: 'NGO Jobs' },
        { href: '/en-at/jobs-in-Bad+Ischl', title: 'Jobs in Bad Ischl' },
        { href: '/en-at/jobs-as-Uni', title: 'University Jobs' },
        { href: '/en-at/jobs-in-Parndorf', title: 'Jobs in Parndorf' },
        { href: '/en-at/jobs-as-PKA', title: 'Pharmaceutical Clerk Jobs' },
        { href: '/en-at/jobs-in-Wels', title: 'Jobs in Wels' },
        { href: '/en-at/jobs-as-Justiz', title: 'Jobs in the Justice System' },
        { href: '/en-at/jobs-in-Steiermark', title: 'Jobs in Steiermark' },
        { href: '/en-at/jobs-as-SAP', title: 'SAP Jobs' },
        { href: '/en-at/jobs-in-Villach', title: 'Jobs in Villach' },
        { href: '/en-at/jobs-as-Busfahrer', title: 'Bus Driver Jobs' },
        { href: '/en-at/jobs-in-Eisenstadt', title: 'Jobs in Eisenstadt' },
        { href: '/en-at/jobs-as-Marketing', title: 'Marketing Jobs' },
      ] as SeoLink[],
    },
  },
}

export const getSeoLinks = ({
  pageType,
  country,
  locale,
}: {
  pageType: SeoPage
  country: keyof typeof Country
  locale: keyof typeof Locale
},
): SeoLink[] => {
  return SEO_LINKS[pageType][country][locale]
}
