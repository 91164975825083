import { User } from './HNavBar.types'

export const getUsernameInitials = ({
  firstName, lastName, email,
}: User): string => {
  if (!firstName && !lastName) {
    return email[0].toUpperCase()
  }
  const lasNameInitial = lastName ? `${lastName[0]}` : ''

  return `${firstName[0]}${lasNameInitial}`.toUpperCase()
}

export const formatDisplayName = (user: User): string => {
  const { firstName, lastName, email } = user
  if (firstName && lastName) {
    const formattedFirstName =
      firstName.charAt(0).toUpperCase() + firstName.slice(1)
    const formattedLastName = lastName.charAt(0).toUpperCase() + '.'
    return `${formattedFirstName} ${formattedLastName}`
  }
  return email.split('@')[0]
}
