import styled from '@emotion/styled'
import { HTypography } from '../../HTypography'
import { COLORS } from '../../theme'
import React from 'react'

export const DesktopProfileContainer = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  &:hover, &:active {
    cursor: pointer;
  }
`
export const ProfileMenuItem = styled(HTypography)`
  display: inline-flex;
  gap: 8px;
  height: 32px;
  justify-content: flex-start;
  align-items: center;
  &:hover, &:active {
    cursor: pointer;
    color: ${COLORS.primary_70} !important;
  }
`

export const LogOutMenuItem = styled(HTypography)`
  display: inline-flex;
  gap: 8px;
  height: 32px;
  justify-content: flex-start;
  align-items: center;
  &:hover, &:active {
    cursor: pointer;
    color: ${COLORS.surface_gray_90} !important;
  }
`
