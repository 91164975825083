// @flow

import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as actionCreators from '../actions/actionCreators'
import * as userActions from '../actions/user'
import isEmpty from 'lodash/isEmpty'

const shouldAutoLogout = (user: { [key: string]: any }, emailParam): boolean => {
  return user.initialized && !isEmpty(emailParam) && user.isAuthenticated && user.user && emailParam !== user.user.email
}

const shouldOpenLoginModal = (user: { [key: string]: any }, emailParam): boolean => {
  return user.initialized && ((!isEmpty(emailParam) && !user.isAuthenticated) || (!isEmpty(emailParam) && user.isAuthenticated && user.user && emailParam !== user.user.email)) && !user.user_pending_consent_terms_and_conditions
}

const removeQueryParams = (): void => {
  window.history.pushState({}, document.title, window.location.pathname)
}

const removeEmailFromStore = (dispatch, state: { [key: string]: any }): void => {
  const routeData = {
    ...state,
    location: {
      ...state.location,
      query: {
        ...state.query,
        email: '',
      },
    },
  }
  dispatch(actionCreators.routeDataSync(routeData))
}

export const autoLogoutAndOpenLoginModal = (Component: React.ComponentType<any>) => {
  return (props: any) => {
    const dispatch = useDispatch()

    const { currentUser, redirectLocation, routingState } = useSelector((state) => {
      return {
        currentUser: state.user,
        redirectLocation: state.router.location?.pathname,
        routingState: state.routing,
      }
    })

    const email = decodeURIComponent(routingState.location?.query?.email || '')

    React.useEffect(() => {
      if (shouldAutoLogout(currentUser, email)) {
        dispatch(userActions.logout())
        removeEmailFromStore(dispatch, routingState)
        removeQueryParams()
      }

      if (shouldOpenLoginModal(currentUser, email)) {
        dispatch(actionCreators.reserveEnteredData(email))

        if (redirectLocation && redirectLocation !== '/') {
          dispatch(actionCreators.setRedirectPath(redirectLocation))
        }

        dispatch(
          actionCreators.showLoginModal({
            redirect: window.location.pathname,
            activeTab: 0,
          })
        )
        removeEmailFromStore(dispatch, routingState)
      }
    }, [email, currentUser])

    return <Component {...props} />
  }
}
