// @flow

import { newTracker, setUserId, trackPageView, trackSelfDescribingEvent, trackStructEvent } from '@snowplow/browser-tracker'
import { enableLinkClickTracking, LinkClickTrackingPlugin } from '@snowplow/browser-plugin-link-click-tracking'
import { WebVitalsPlugin } from '@snowplow/browser-plugin-web-vitals'
import config from '../../../config'
import debug from '../shared/debug'
import type { TrackingSchema } from '../contexts/types'
import logger from '../../../logging'

type GenericFunction = (...args: Array<any>) => any;

let sp
const log = logger('snowplow-tracking')

/** We don't want to allow tracking failures to crash the application */
function trapError<T: GenericFunction> (namespace: string, fn: T): T {
  return ((...args) => {
    try {
      fn(...args)

      // NOTE: The E2E suite depends on these events being pushed onto the window
      // with this shape. https://heyjobs.atlassian.net/browse/TAL-581
      debug.snowplow.push([namespace, ...args])
      if (config && config.snowplow.enableLogging) {
        log.info('event', [namespace, args])
      }
    } catch (e) {
      log.error('error', e)
    }
  }: any)
}

export const initSnowplow = (cookieDomain: string) => {
  sp = newTracker('co_web', config.snowplow.hostUrl, {
    appId: 'jobseeker-portal',
    platform: 'web',
    cookieDomain,
    post: true,
    withCredentials: true,
    contexts: {
      webPage: true,
      performanceTiming: true,
    },
    plugins: [
      LinkClickTrackingPlugin(),
      WebVitalsPlugin(),
    ],
  })

  enableLinkClickTracking()
}

/* eslint no-unused-vars: error */
export const snowplowGetPageViewId = () => sp?.getPageViewId()
export const snowplowSetUserId = trapError(
  'setUserId',
  (userId: ?string) => setUserId(userId)
)

export const snowplowTrackPageView = trapError(
  'trackPageView:co_web',
  (title: string | null, context: TrackingSchema[]) => {
    trackPageView({ title, context })
  }
)

export const snowplowTrackSelfDescribingEvent = trapError(
  'trackSelfDescribingEvent:co_web',
  (event: TrackingSchema, context: TrackingSchema[]) => {
    trackSelfDescribingEvent({ event, context })
  }
)

export const snowplowTrackStructEvent = trapError(
  'trackStructEvent:co_web',
  (
    category: string,
    action: string,
    label: string,
    property: string,
    value: number,
    context: TrackingSchema[]
  ) => {
    trackStructEvent({ category, action, label, property, value, context })
  }
)
