// @flow

import type { ApplicationState } from '../../types/applicationState'
import type { TrackingSchema } from './types'

// V3 exists in Snowplow but it's not the schema version we use. Do not change it unless you were specifically requested to do so.
export const SCHEMA_NAME = 'iglu:de.heyjobs/job_context/jsonschema/2-0-4'

export default (state: ApplicationState): Array<TrackingSchema> => {
  const job = state.currentJob.job

  if (job) {
    return [{
      schema: SCHEMA_NAME,
      data: {
        job_uid: job.id,
        job_type: job.type?.codename,
        job_working_hours_type: job.working_hours_type,
        job_price_category: '',
        job_client_status: job.company.client_status,
        job_is_easy_apply: job.allow_easy_apply,
        job_is_quick_apply: job.allow_quick_apply,
        job_link_out_type: job.link_out_type || null,
        job_number_of_screening_questions: state.currentJob.screeningQuestions?.length || null,
        job_source: job.source,
      },
    }]
  }

  return []
}
