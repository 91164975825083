// @flow

import type { ClientStatus } from '../../types/company'
import type { Job } from '../../types/job'

type ShouldCPCBeTrackedParams = {
  job: Job,
  requiredClientStatus: ClientStatus,
}

/**
 * CPC tracking is mostly handled by the Talent Marketing team.
 * You can find docs about the logic in the {@link https://heyjobs.atlassian.net/wiki/spaces/Product/pages/2709061776/TP+-+FAQ+-+CPC+Revenue+Tracking docs}.
 */
const shouldCPCBeTracked = ({
  job,
  requiredClientStatus,
}: ShouldCPCBeTrackedParams): bool => {
  const cpc = job.cpc
  const hasCpc = cpc !== null
  const hasCorrectClientStatus = requiredClientStatus === job.company.client_status

  return hasCorrectClientStatus && hasCpc
}

export default shouldCPCBeTracked
