// @flow

import config from '../config'
import {
  createLogger,
  transports,
  format,
} from 'winston'

import type {
  LogLevel,
  LoggerFunction,
  LoggerInterface,
} from './types'

const getLoggingFormat = () => {
  if (process.env.NODE_ENV !== 'production') {
    return format.combine(
      format.timestamp(),
      format.colorize({ all: true }),
      format.simple()
    )
  }
  return format.combine(
    format.timestamp(),
    format.json()
  )
}

const base = createLogger({
  level: config.log.server.level,
  format: getLoggingFormat(),
  transports: [new transports.Console()],
})

const buildLoggingFunction = (log) => (ns: string) => (level: LogLevel): LoggerFunction => {
  level = level === 'dataCapture' ? 'info' : level

  return (message: string, ...args: any[]) => {
    const namespacedMessage = `[${ns}]: ${message}`
    log[level](namespacedMessage, ...args)
  }
}

export default (ns: string): LoggerInterface => {
  const log = base.child({
    namespace: ns,
  })
  const logger = buildLoggingFunction(log)(ns)
  return {
    debug: logger('debug'),
    info: logger('info'),
    warn: logger('warn'),
    // Alias for 'info' that takes precendence over it to send logs to datadog
    dataCapture: logger('dataCapture'),
    error: logger('error'),
  }
}
