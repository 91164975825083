import React from 'react'
import { HTypography } from '../HTypography'
import { HBox } from '../HBox'
import { HCheckbox } from '../HCheckbox'
import { HRadioButton } from '../HRadioButton'
import { HFilterSectionContentProps, HFilterSectionContentFilterType } from './HAdvancedFilter.types'
import styled from '@emotion/styled'

const FilterContainer = styled(HBox)`
  cursor: pointer;
`

export const HFilterSectionContent =
  ({ options, disabled }: HFilterSectionContentProps): JSX.Element => (
    <HBox
      display='flex'
      flexDirection='column'
      mt={4}
      gap={16}
      lg={{ gap: 12 }}
    >
      {options.map((option, index) => (
        <FilterContainer
          component='label'
          key={index}
          alignItems='center'
          display='flex'
          flexDirection='row'
          justifyContent='space-between'
          gap={12}
        >
          {option.type === HFilterSectionContentFilterType.Checkbox && (
            <HCheckbox {...option.HCheckboxProps} disabled={disabled} />
          )}
          {option.type === HFilterSectionContentFilterType.RadioButton && (
            <HRadioButton {...option.HRadioButtonProps} disabled={disabled} />
          )}
          <HTypography data-test-id={option.matchingItemsCountDataTestId} color='surface_gray_60' variant='body2'>
            {option.matchingItemsCount}
          </HTypography>
        </FilterContainer>
      ))}
    </HBox>
  )
