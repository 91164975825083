// @flow
import config from '../../../../config'
import { buildUrlFragment } from '../../../helpers/url/query'

type CognitoTokenAPIParams = {
  client_id: string,
  code: string,
  code_verifier : string,
  grant_type : 'authorization_code', // other options can be added when needed
  redirect_uri: string
}

const token = async (params: CognitoTokenAPIParams) => {
  const apiURL = `${config.cognito.customDomain}/oauth2/token`

  const response = await fetch(`${apiURL}${buildUrlFragment(params)}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  }
  )
  const tokens = await response.json()
  return tokens
}

export default { token }
