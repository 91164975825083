// @flow

import typeof TrackPageviewType from '../events/pageview'
import typeof TrackEventType from '../events/event'
import type { ExtraContextParam } from '../shared/buildContexts'
import typeof TrackStructuredEventType from '../events/structuredEvent'
import type { StructuredEventProps } from '../events/structuredEvent'
import typeof GetSnoplowPageViewIdType from '../misc/getSnowplowPageViewId'
import typeof SetUserIdType from '../misc/setUserId'
import typeof SetGTMUserDataForTikTok from '../misc/setGTMUserDataForTikTok'
import type { GTMUserDataForTikTok } from '../misc/setGTMUserDataForTikTok'
import type { RouteName } from '../../routes'
import type { ContextData, EventName } from '../types'
import type { TrackingSchema } from '../contexts/types'

// -------------------------------------------------------------------------------------------
// This is just an interface to bundledTrackingClient, which binds the client api onto window
// It exists so that the app code does not have to know/care about this
// -------------------------------------------------------------------------------------------

export const fireSnowplowPageView = (pageType: ?(RouteName | string), contextData: ?ContextData) => {
  if (typeof window === 'undefined') return

  (window.__heyjobs_tc.fireSnowplowPageView: TrackPageviewType)(pageType, contextData)
}

export const fireSnowplowEvent = (
  name: EventName,
  eventData: $FlowTODO = {},
  extraContexts?: ExtraContextParam[],
  builtContext?: TrackingSchema
) => {
  if (typeof window === 'undefined') return

  (window.__heyjobs_tc.fireSnowplowEvent: TrackEventType)(name, eventData, extraContexts, builtContext)
}

export const fireSnowplowStructuredEvent = (
  eventData: StructuredEventProps,
  extraContexts?: ExtraContextParam[]
) => {
  if (typeof window === 'undefined') return

  (window.__heyjobs_tc.fireSnowplowStructuredEvent: TrackStructuredEventType)(eventData, extraContexts)
}

export const getSnowplowPageViewId = (): ?Promise<string> => {
  if (typeof window === 'undefined') return

  return (window.__heyjobs_tc.getSnowplowPageViewId: GetSnoplowPageViewIdType)()
}

export const setUserId = (userId: ?string) => {
  if (typeof window === 'undefined') return

  return (window.__heyjobs_tc.setUserId: SetUserIdType)(userId)
}

export const setGTMUserDataForTikTok = (userData: GTMUserDataForTikTok) => {
  if (typeof window === 'undefined') return

  return (window.__heyjobs_tc.setGTMUserDataForTikTok: SetGTMUserDataForTikTok)(userData)
}

export function getSnowplowDomainUid () {
  const cookieName = '_sp_'
  const matcher = new RegExp(cookieName + 'id\\.[a-f0-9]+=([^;]+);?')
  const match = document.cookie.match(matcher)
  if (match && match[1]) {
    const split = match[1].split('.')
    return split[0]
  } else {
    return ''
  }
}
