import React from 'react'
import clsx from 'clsx'
import { HTypography } from '../HTypography'
import { HRadioButtonContainer, classes } from './HRadioButton.styles'

export interface HRadioButtonProps extends React.HTMLProps<HTMLElement> {
  checked: boolean
  error?: boolean
  disabled?: boolean
  label?: string
  caption?: string
  ref?: any
}

export const HRadioButton: React.FC<HRadioButtonProps> = ({
  disabled,
  checked,
  error,
  label,
  caption,
  onChange,
  ...props
}) => {
  const inputClassName = clsx(
    classes.radio_button,
    label && classes.radio_button_with_label,
  )
  const textContainerClassName = clsx(
    classes.radio_button_text_container,
    label && classes.radio_button_text_container_with_label,
    error && classes.radio_button_text_container_error,
    disabled && classes.radio_button_text_container_disabled,
  )
  const labelClassName = clsx(
    classes.radio_button_label,
    caption && classes.label_with_caption,
  )
  const captionClassName = clsx(
    !error && classes.radio_button_caption,
    error && classes.radio_button_error_caption,
  )

  const handleCheck = (event?: any): void => {
    if (disabled) {
      return
    }
    onChange?.(event)
  }

  return (
    <HRadioButtonContainer
      disabled={disabled}
      onClick={handleCheck}
      onMouseDown={e => e.preventDefault()}
      tabIndex={!disabled ? 0 : undefined}
    >
      <input
        className={inputClassName}
        type='radio'
        checked={checked}
        onChange={(event) => handleCheck(event)}
        disabled={disabled}
        {...props}
      />
      <div className={textContainerClassName}>
        {
          label && (
            <div className={labelClassName}>
              <HTypography variant='body2'>
                {label}
              </HTypography>
            </div>
          )
        }
        {
          caption && <HTypography variant='caption' className={captionClassName}>
            {caption}
          </HTypography>
        }
      </div>
    </HRadioButtonContainer>
  )
}
