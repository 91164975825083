import * as sessionStorageUtils from '../../../../helpers/sessionStorageUtils'
import logger from '../../../../../logging'

const log = logger('search-suggestion-tracking')

type TrackingData = {
  keywordSuggestionId: string,
  sourcePage: string
  locationSuggestionId: string
}

export const updateTrackingData = (data:Partial<TrackingData>) => {
  try {
    const currentData = getTrackingData()
    sessionStorageUtils.setItem('AUTO_SUGGESTION_TRACKING_DATA', JSON.stringify({ ...currentData, ...data }))
  } catch (error) {
    log.error('Failed to save autosuggestion data in session storage:', error)
  }
}

export function removeTrackingData (key?: keyof TrackingData) {
  try {
    if (!key) {
      sessionStorageUtils.removeItem('AUTO_SUGGESTION_TRACKING_DATA')
      return
    }
    const currentData = getTrackingData()
    if (!currentData) return
    delete currentData[key]
    sessionStorageUtils.setItem('AUTO_SUGGESTION_TRACKING_DATA', JSON.stringify(currentData))
  } catch (error) {
    log.error('Failed to remove autosuggestion data from session storage:', error)
  }
}

export function getTrackingData (key?: keyof TrackingData):any {
  try {
    const data = sessionStorageUtils.getItem('AUTO_SUGGESTION_TRACKING_DATA')
    if (!data) return {}
    const currentData = JSON.parse(data) as TrackingData
    if (!key) return currentData
    return currentData[key]
  } catch (error) {
    log.error('Failed to get autosuggestion data in session storage:', error)
  }
}
