// @flow

export const growthbookVisualEditorConfig = (props: Object) => `
window.GROWTHBOOK_CONFIG = {
  // Optional logged-in user id
  userId: "${props.userId || ''}",
  anonId: "${props.sessionToken || ''}",
  track: function(experimentId, variationId) {
    const experimentName = experimentId
    const assignedGroupName = 'growthbook-variant-' + variationId
    window.__heyjobs_tc.fireSnowplowEvent(
      'experiment_event',
      {
        experiment_id: experimentName,
        group_name: assignedGroupName
      }
    )
  }
}
`
