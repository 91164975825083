// @flow

import isPreviewMode from '../url/isPreviewMode'

import type { LinkOutType } from '../../types/job'
import type { RouteData } from '../../types/common'
import type { ClientStatus } from '../../types/company'

type ShouldBeOutPageParams = {
  clientStatus: ClientStatus,
  cpc: ?string | number,
  isAuthenticated: bool,
  linkOutType: ?LinkOutType,
  preventSmartLinkoutAutoRedirect?: bool,
  routeData?: RouteData,
  type: 'link' | 'redirect'
}

const shouldDisableOutAutoRedirect = (routeData: RouteData): bool =>
  routeData.location.query.disable_out_auto_redirect === 'true'

const shouldBeOutPage = ({
  isAuthenticated,
  cpc,
  linkOutType,
  clientStatus,
  routeData,
  preventSmartLinkoutAutoRedirect,
  type,
}: ShouldBeOutPageParams): bool => {
  const isPreview = routeData && isPreviewMode(routeData)
  const disabledOutRedirect = routeData && shouldDisableOutAutoRedirect(routeData)

  if (isPreview || disabledOutRedirect) {
    return false
  }

  if (linkOutType === 'instant') {
    if (type === 'redirect') {
      return isAuthenticated
    }
    return type === 'link'
  }

  if (linkOutType === 'smart_link_out') {
    const positiveCPC = parseFloat(cpc) > 0
    const nonClientCompany = clientStatus === 'no_client'

    return type === 'redirect' && nonClientCompany && positiveCPC && !preventSmartLinkoutAutoRedirect && isAuthenticated
  }

  return false
}

export default shouldBeOutPage
