const validateSameOriginRedirect = (url?:string) => {
  if (!url) return

  try {
    const parsed = new URL(url, window.location.origin)
    if (parsed.origin !== window.location.origin) {
      return null
    }
    return url
  } catch (error) {
    return null
  }
}

export default validateSameOriginRedirect
