import React from 'react'
import { HBox } from '../HBox'
import { HLogo } from '../HLogo'
import {
  CareerPageProps,
  JDPNavbarProps,
  LogoProps,
  UserProfile,
} from './HNavBar.types'
import { HPlaceholder } from '../HPlaceholder'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Theme } from '@mui/material/styles'
import { HTypography } from '../HTypography'

interface NavbarLogoProps extends LogoProps {
  jdp?: JDPNavbarProps
  userProfile?: UserProfile
  careerPage?: CareerPageProps
}

export const NavbarLogo: React.FC<NavbarLogoProps> = ({
  logoLink,
  logoClickAction,
  Link,
  jdp,
  userProfile,
  careerPage,
}) => {
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))

  if (jdp && !careerPage && !userProfile) {
    const { company } = jdp

    return (
      <HBox
        display='flex'
        alignItems='center'
        justifyContent='flex-start'
        height='fit-content'
        gap={16}
        minWidth={0}
        data-test-id='jdp-navbar-logged-out-branding'
      >
        <HBox display='flex' flexDirection='column'>
          <Link {...company.LinkProps}>
            <HPlaceholder
              size={isTablet ? 'XS' : 'M'}
              src={company.logo?.src}
              srcSet={company.logo?.srcset}
              alt={company.name}
              imgProps={{ style: { objectFit: 'contain' } }}
              data-test-id="company-logo"
            />
          </Link>
        </HBox>
        <HBox
          display='flex'
          flexDirection='column'
          alignItems='flex-start'
          gap={5}
          minWidth={0}
        >
          <HBox display='flex' maxWidth='100%'>
            <HTypography
              variant='body2'
              weight='medium'
              lg={{ variant: 'h6' }}
              data-test-id='company-name'
              truncated
            >
              {company.name}
            </HTypography>
          </HBox>
          <Link
            href={logoLink}
            onClick={logoClickAction}
            data-test-id='heyjobs-logo'
          >
            <HLogo
              size='small'
              variant='pink_yellow'
              text={jdp.applyWithLabel}
              width={isTablet ? 52 : 65}
              textPosition='besides'
            />
          </Link>
        </HBox>
      </HBox>
    )
  }
  return (
    <HBox
      display='flex'
      alignItems='center'
      justifyContent='flex-start'
      height='fit-content'
      data-test-id='heyjobs-navbar-branding'
    >
      <Link
        href={logoLink}
        onClick={logoClickAction}
        data-test-id='logo-image'
      >
        {careerPage
          ? (
            <HBox display='flex' alignItems='center' gap={16}>
              <HPlaceholder
                size={isTablet ? 'XS' : 'M'}
                src={careerPage.logoUrl}
                alt={careerPage.companyName}
                imgProps={{ style: { objectFit: 'contain' } }}
                data-test-id="company-logo-container"
              />
              <HTypography
                variant='body2'
                weight='medium'
                lg={{ variant: 'h6' }}
                data-test-id='company-name'
                truncated
              >
                {careerPage.companyName}
              </HTypography>
            </HBox>
          )
          : (
            <HLogo variant='pink_yellow' size='small' />
          )}
      </Link>
    </HBox>
  )
}
