import React from 'react'
import { COLORS } from '../theme'
import {
  SpacingProps,
} from '@mui/system'

export type IWeight = 'light' | 'semibold' | 'regular' | 'medium' | 'inherit'
export type IAlign = 'center' | 'right' | 'left'

export type IType =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'body1'
  | 'body2'
  | 'body3'
  | 'caption'
  | 'caption2'
  | 'overline1'
  | 'overline2'
  | 'button'

export const TypeMap = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  subtitle1: 'p',
  subtitle2: 'p',
  body1: 'p',
  body2: 'p',
  body3: 'p',
  caption: 'p',
  caption2: 'p',
  overline1: 'p',
  overline2: 'p',
  button: 'p',
} as const

export interface TypographyProps {
  align?: IAlign
  breakWord?: boolean
  className?: string
  color?: keyof typeof COLORS | 'inherit'
  component?: React.ElementType
  cursor?: 'pointer'
  italic?: boolean
  lineClamp?: number
  selectors?: Array<{ selector: string, props: Omit<TypographyProps, 'selectors'> }>
  truncated?: boolean
  underline?: boolean
  uppercase?: boolean
  variant?: IType
  weight?: IWeight
  space?: SpacingProps
  [componentProp: string]: unknown
}

export type HTypographyProps =
  TypographyProps & {
    xs?: TypographyProps
    sm?: TypographyProps
    md?: TypographyProps
    lg?: TypographyProps
    xl?: TypographyProps
  }
