// @flow
import React, { useState } from 'react'
import styled, { type StyledComponent } from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { $colors, $sizes, $disallowedCookieBarZIndex } from '../shared/dependencies/variables'
import { Close16 as CloseIcon } from '@carbon/icons-react'

const DisallowedCookieBarContainer: StyledComponent<{}, *, *> = styled.div`
  position: fixed;
  z-index: ${$disallowedCookieBarZIndex};
  padding: ${$sizes.lg};
  background: ${$colors.primary[100]};
  width: 100%;
  color: #ffffff;
  bottom: 0;
  text-align: center;
  font-size: 16px;

  display: flex;
  flex-direction: row;
  align-items: center;

  & > span {
    width: 100%;
  }
`

const CloseBannerButton = styled(CloseIcon)`
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: inline-block;
  align-self: center;
`

const DisallowedCookieBar = ({ isLocalStorageEnabled }: { isLocalStorageEnabled: ?bool }) => {
  const [showBanner, setShowBanner] = useState(!isLocalStorageEnabled)

  return showBanner && (
    <DisallowedCookieBarContainer>
      <FormattedMessage id='disallowed_cookie_bar_message' />

      <CloseBannerButton
        onClick={() => setShowBanner(false)}
      />
    </DisallowedCookieBarContainer>
  )
}

export default DisallowedCookieBar
