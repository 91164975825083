import { COLORS } from '../theme'
import { IChipColor, IChipVariant } from './ChipComponent'

interface IChipVariantStyle {
  text: string
  background: string
  deleteIconColor: string
  iconColor: string
  border?: string
}

export const ChipColorMap:
{ [key in IChipColor]:
  { [key in IChipVariant]: IChipVariantStyle & {
    hover: IChipVariantStyle
    active: IChipVariantStyle
  }}
} = {
  gray: {
    filled: {
      text: COLORS.surface_gray_90,
      background: COLORS.surface_gray_05,
      deleteIconColor: COLORS.surface_gray_90,
      iconColor: COLORS.surface_gray_90,
      hover: {
        text: COLORS.surface_gray_90,
        background: COLORS.surface_gray_10,
        deleteIconColor: COLORS.surface_gray_90,
        iconColor: COLORS.surface_gray_90,
      },
      active: {
        text: COLORS.surface_gray_80,
        background: COLORS.surface_gray_20,
        deleteIconColor: COLORS.surface_gray_60,
        iconColor: COLORS.surface_gray_60,
      },
    },
    outlined: {
      text: COLORS.surface_gray_90,
      border: COLORS.surface_gray_90,
      background: 'transparent',
      deleteIconColor: COLORS.surface_gray_90,
      iconColor: COLORS.surface_gray_90,
      hover: {
        background: COLORS.surface_gray_05,
        text: COLORS.surface_gray_90,
        border: COLORS.surface_gray_90,
        deleteIconColor: COLORS.surface_gray_90,
        iconColor: COLORS.surface_gray_90,
      },
      active: {
        background: COLORS.surface_gray_10,
        text: COLORS.surface_gray_80,
        border: COLORS.surface_gray_60,
        deleteIconColor: COLORS.surface_gray_60,
        iconColor: COLORS.surface_gray_60,
      },
    },
  },
  blue: {
    filled: {
      text: COLORS.primary_70,
      background: COLORS.primary_05,
      deleteIconColor: COLORS.primary_70,
      iconColor: COLORS.primary_70,
      hover: {
        text: COLORS.primary_90,
        background: COLORS.primary_15,
        deleteIconColor: COLORS.primary_90,
        iconColor: COLORS.primary_90,
      },
      active: {
        text: COLORS.primary_70,
        background: COLORS.primary_15,
        deleteIconColor: COLORS.primary_70,
        iconColor: COLORS.primary_70,
      },
    },
    outlined: {
      text: COLORS.primary_50,
      border: COLORS.primary_50,
      background: 'transparent',
      deleteIconColor: COLORS.primary_50,
      iconColor: COLORS.primary_50,
      hover: {
        background: COLORS.primary_05,
        text: COLORS.primary_70,
        border: COLORS.primary_70,
        deleteIconColor: COLORS.primary_70,
        iconColor: COLORS.primary_70,
      },
      active: {
        background: COLORS.primary_05,
        text: COLORS.primary_70,
        border: COLORS.primary_70,
        deleteIconColor: COLORS.primary_70,
        iconColor: COLORS.primary_70,
      },
    },
  },
  success: {
    filled: {
      text: COLORS.support_success_70,
      background: COLORS.support_success_00,
      deleteIconColor: COLORS.support_success_70,
      iconColor: COLORS.support_success_70,
      hover: {
        text: COLORS.support_success_90,
        background: COLORS.support_success_05,
        deleteIconColor: COLORS.support_success_90,
        iconColor: COLORS.support_success_90,
      },
      active: {
        text: COLORS.support_success_70,
        background: COLORS.support_success_15,
        deleteIconColor: COLORS.support_success_70,
        iconColor: COLORS.support_success_70,
      },
    },
    outlined: {
      text: COLORS.support_success_50,
      border: COLORS.support_success_50,
      background: 'transparent',
      deleteIconColor: COLORS.support_success_50,
      iconColor: COLORS.support_success_50,
      hover: {
        background: COLORS.support_success_00,
        text: COLORS.support_success_70,
        border: COLORS.support_success_70,
        deleteIconColor: COLORS.support_success_70,
        iconColor: COLORS.support_success_70,
      },
      active: {
        background: COLORS.support_success_05,
        text: COLORS.support_success_70,
        border: COLORS.support_success_70,
        deleteIconColor: COLORS.support_success_70,
        iconColor: COLORS.support_success_70,
      },
    },
  },
  warning: {
    filled: {
      text: COLORS.support_warning_90,
      background: COLORS.support_warning_00,
      deleteIconColor: COLORS.support_warning_90,
      iconColor: COLORS.support_warning_90,
      hover: {
        text: COLORS.support_warning_90,
        background: COLORS.support_warning_05,
        deleteIconColor: COLORS.support_warning_90,
        iconColor: COLORS.support_warning_90,
      },
      active: {
        text: COLORS.support_warning_90,
        background: COLORS.support_warning_15,
        deleteIconColor: COLORS.support_warning_90,
        iconColor: COLORS.support_warning_90,
      },
    },
    outlined: {
      text: COLORS.support_warning_50,
      border: COLORS.support_warning_50,
      background: 'transparent',
      deleteIconColor: COLORS.support_warning_50,
      iconColor: COLORS.support_warning_50,
      hover: {
        background: COLORS.support_warning_00,
        text: COLORS.support_warning_70,
        border: COLORS.support_warning_70,
        deleteIconColor: COLORS.support_warning_70,
        iconColor: COLORS.support_warning_70,
      },
      active: {
        background: COLORS.support_warning_05,
        text: COLORS.support_warning_70,
        border: COLORS.support_warning_70,
        deleteIconColor: COLORS.support_warning_70,
        iconColor: COLORS.support_warning_70,
      },
    },
  },
  error: {
    filled: {
      text: COLORS.support_error_70,
      background: COLORS.support_error_00,
      deleteIconColor: COLORS.support_error_70,
      iconColor: COLORS.support_error_70,
      hover: {
        text: COLORS.support_error_90,
        background: COLORS.support_error_05,
        deleteIconColor: COLORS.support_error_90,
        iconColor: COLORS.support_error_90,
      },
      active: {
        text: COLORS.support_error_70,
        background: COLORS.support_error_15,
        deleteIconColor: COLORS.support_error_70,
        iconColor: COLORS.support_error_70,
      },
    },
    outlined: {
      text: COLORS.support_error_50,
      border: COLORS.support_error_50,
      background: 'transparent',
      deleteIconColor: COLORS.support_error_50,
      iconColor: COLORS.support_error_50,
      hover: {
        background: COLORS.support_error_00,
        text: COLORS.support_error_70,
        border: COLORS.support_error_70,
        deleteIconColor: COLORS.support_error_70,
        iconColor: COLORS.support_error_70,
      },
      active: {
        background: COLORS.support_error_05,
        text: COLORS.support_error_70,
        border: COLORS.support_error_70,
        deleteIconColor: COLORS.support_error_70,
        iconColor: COLORS.support_error_70,
      },
    },
  },
  help: {
    filled: {
      text: COLORS.support_help_70,
      background: COLORS.support_help_00,
      deleteIconColor: COLORS.support_help_70,
      iconColor: COLORS.support_help_70,
      hover: {
        text: COLORS.support_help_90,
        background: COLORS.support_help_05,
        deleteIconColor: COLORS.support_help_90,
        iconColor: COLORS.support_help_90,
      },
      active: {
        text: COLORS.support_help_70,
        background: COLORS.support_help_15,
        deleteIconColor: COLORS.support_help_70,
        iconColor: COLORS.support_help_70,
      },
    },
    outlined: {
      text: COLORS.support_help_50,
      border: COLORS.support_help_50,
      background: 'transparent',
      deleteIconColor: COLORS.support_help_50,
      iconColor: COLORS.support_help_50,
      hover: {
        background: COLORS.support_help_00,
        text: COLORS.support_help_70,
        border: COLORS.support_help_70,
        deleteIconColor: COLORS.support_help_70,
        iconColor: COLORS.support_help_70,
      },
      active: {
        background: COLORS.support_help_05,
        text: COLORS.support_help_70,
        border: COLORS.support_help_70,
        deleteIconColor: COLORS.support_help_70,
        iconColor: COLORS.support_help_70,
      },
    },
  },
  selected: {
    filled: {
      text: COLORS.primary_70,
      border: COLORS.primary_70,
      background: COLORS.primary_15,
      deleteIconColor: COLORS.primary_70,
      iconColor: COLORS.primary_70,
      hover: {
        background: COLORS.primary_25,
        text: COLORS.primary_70,
        border: COLORS.primary_70,
        deleteIconColor: COLORS.primary_70,
        iconColor: COLORS.primary_70,
      },
      active: {
        background: COLORS.primary_05,
        text: COLORS.primary_70,
        border: COLORS.primary_70,
        deleteIconColor: COLORS.primary_70,
        iconColor: COLORS.primary_70,
      },
    },
    outlined: {
      text: COLORS.primary_70,
      border: COLORS.primary_70,
      background: COLORS.primary_15,
      deleteIconColor: COLORS.primary_70,
      iconColor: COLORS.primary_70,
      hover: {
        background: COLORS.primary_25,
        text: COLORS.primary_70,
        border: COLORS.primary_70,
        deleteIconColor: COLORS.primary_70,
        iconColor: COLORS.primary_70,
      },
      active: {
        background: COLORS.primary_05,
        text: COLORS.primary_70,
        border: COLORS.primary_70,
        deleteIconColor: COLORS.primary_70,
        iconColor: COLORS.primary_70,
      },
    },
  },
  accent_pink: {
    filled: {
      background: COLORS.accent_pink_50,
      border: COLORS.accent_pink_70,
      deleteIconColor: COLORS.surface_white,
      iconColor: COLORS.surface_white,
      text: COLORS.surface_white,
      hover: {
        background: COLORS.accent_pink_70,
        border: COLORS.accent_pink_70,
        deleteIconColor: COLORS.surface_white,
        iconColor: COLORS.surface_white,
        text: COLORS.surface_white,
      },
      active: {
        background: COLORS.accent_pink_70,
        border: COLORS.accent_pink_70,
        deleteIconColor: COLORS.surface_white,
        iconColor: COLORS.surface_white,
        text: COLORS.surface_white,
      },
    },
    outlined: {
      background: COLORS.surface_white,
      border: COLORS.accent_pink_50,
      deleteIconColor: COLORS.accent_pink_50,
      iconColor: COLORS.accent_pink_50,
      text: COLORS.accent_pink_50,
      hover: {
        background: COLORS.surface_white,
        border: COLORS.accent_pink_70,
        deleteIconColor: COLORS.accent_pink_70,
        iconColor: COLORS.accent_pink_70,
        text: COLORS.accent_pink_70,
      },
      active: {
        background: COLORS.surface_white,
        border: COLORS.accent_pink_70,
        deleteIconColor: COLORS.accent_pink_70,
        iconColor: COLORS.accent_pink_70,
        text: COLORS.accent_pink_70,
      },
    },
  },
}
