// @flow

import * as actions from '../constants/actions'
import { FULFILLED, PENDING } from './promiseUtil'

import type { JobType, JobTypeQuizResult } from '../types/job'

export type JobTypeState = {
  counts: {[key: string]: ?number},
  currentJobType: {
    isLoading: bool,
    jobType: ?JobType,
    jobsCount: number
  },
  quiz: {
    answers: ?{ [key: string]: string },
    hasFeedback: bool,
    isLoading: bool,
    result: ?JobTypeQuizResult[]
  }
}

const initialState: JobTypeState = {
  currentJobType: {
    jobType: null,
    isLoading: false,
    jobsCount: 0,
  },
  quiz: {
    answers: null,
    isLoading: false,
    hasFeedback: false,
    result: null,
  },
  counts: {},
}

const jobTypeReducer = (state: JobTypeState = initialState, action: $FlowTODO): JobTypeState => {
  switch (action.type) {
    case PENDING(actions.GET_JOB_TYPE_QUIZ_RESULT):
      return {
        ...state,
        quiz: {
          ...state.quiz,
          isLoading: true,
        },
      }
    case FULFILLED(actions.GET_JOB_TYPE_QUIZ_RESULT):
      return {
        ...state,
        quiz: {
          ...state.quiz,
          answers: action.meta[0],
          isLoading: false,
          result: action.payload,
        },
      }
    case PENDING(actions.GET_JOB_TYPE):
      return {
        ...state,
        currentJobType: {
          ...state.currentJobType,
          isLoading: true,
        },
      }
    case FULFILLED(actions.GET_JOB_TYPE):
      return {
        ...state,
        currentJobType: {
          ...state.currentJobType,
          jobType: action.payload,
          isLoading: false,
        },
      }
    case FULFILLED(actions.GET_QUIZ_FEEDBACK):
      return {
        ...state,
        quiz: {
          ...state.quiz,
          hasFeedback: true,
        },
      }

    case FULFILLED(actions.GET_JOBS_COUNT): {
      const codename = action.meta[1]
      const jobsCount = action.payload?.total_size

      return {
        ...state,
        counts: {
          ...state.counts,
          [codename]: jobsCount,
        },
      }
    }
    case actions.UPDATE_QUIZ_ANSWERS:
      return {
        ...state,
        quiz: {
          ...state.quiz,
          answers: action.answers,
        },
      }

    default:
      return state
  }
}

export default jobTypeReducer
