// @flow
import moment from 'moment'
import { parsePhoneNumberFromString } from 'libphonenumber-js/max'
import { DEFAULT_COUNTRY_CODE } from '../../constants/base'

// NOTE: (asciiCharsRegex) Will match any non-ascii characters.
// Don't merge it with email validation, make it easier if we want to support
// non-ascii characters in email validation later
export const asciiCharsRegex = /[^\u0000-\u007F]/ // eslint-disable-line

const VALID_NAME_CHARACTERS = /^[A-Za-z,.+-_ ]+$/

export const validateEmail = (email: string) => {
  return emailRegex.test(email) && !asciiCharsRegex.test(email)
}

const EXACT_MATCH = true
export const validateDate = (format: string) => (date: string) =>
  moment(date, format, EXACT_MATCH).isValid()

export const validateDateOfBirth = (dateOfBirth: string) =>
  validateDate('DD.MM.YYYY')(dateOfBirth)

export const validatePhoneNumber = (phoneNumber: string): bool => {
  const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber, DEFAULT_COUNTRY_CODE)
  return parsedPhoneNumber && parsedPhoneNumber.isValid()
}

export const validateFullName = (fullName: string) =>
  fullName
    .trim()
    .split(' ', 2)
    .filter((word: string) => !!word).length >= 2 &&
  VALID_NAME_CHARACTERS.test(fullName)

export const validateLocation = (address: string | null) => {
  const validLocation = typeof address === 'string' && address.trim() !== ''

  return Boolean(validLocation)
}

// NOTE: This breaks editor parsers, please keep it at the end.
export const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // eslint-disable-line
