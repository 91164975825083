import 'regenerator-runtime/runtime'
import 'intersection-observer'
import * as Store from './store/configureStore'
import renderApp from './renderApp'
import '../../assets/styles/flexboxgrid.css'
import '../../assets/styles/slick.css'
import 'leaflet/dist/leaflet.css'
import { Cookies } from 'react-cookie'
import { createBrowserHistory } from 'history'
import UsercentricsDataLayerNotifier from './cookieConsent/UsercentricsDataLayerNotifier'
import { createGrowthbook } from './abTesting/GrowthBookProvider'
import { StoreProxy } from './abTesting/StoreProxy'

const initApp = async () => {
  // use html.non-touch-device in addition to @media hover:hover to apply css hover rules
  // only on devices where hover styles are properly supported, i.e. disable hover styles on
  // all touch devices since they sometimes report hover support but then get sticky hover state bugs
  const isTouchDevice =
    'ontouchstart' in document.documentElement ||
    navigator.MaxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0

  document.documentElement.className += isTouchDevice
    ? ' touch-device'
    : ' non-touch-device'

  const data = window.__data
  const reactContextsData = window.__reactContextsData
  const history = createBrowserHistory()
  const cookies = new Cookies()
  const storeProxy = new StoreProxy()
  const growthbook = createGrowthbook(data, storeProxy)
  const store = Store.configureStore(history, cookies, growthbook, data)
  storeProxy.setStore(store)
  window.heyjobsDataLayerNotifier = new UsercentricsDataLayerNotifier(store, window.heyjobsDataLayerNotifier)

  // used for the tracking bundle - /app/tracking
  window.__store = store

  // delete window.__data only after window.__store has been initialized.
  delete window.__data

  renderApp(store, history, cookies, growthbook, reactContextsData)

  /* istanbul ignore if */
  // set up react hot loader if configured
  if (module.hot) {
    module.hot.accept('./renderApp', () => {
      renderApp(store, history, cookies, growthbook, reactContextsData)
    })
  }
}

export default initApp
