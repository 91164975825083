import React from 'react'
import { SettingsAdjust16 } from '@carbon/icons-react'
import styled from '@emotion/styled'
import { Indicator } from '../HAssets/Indicator'
import { HIconButton } from '../HButton'

const IconWrapper = styled.span`
  margin-right: 16px;
  position: relative;
`

const IndicatorWrapper = styled.span`
  position: absolute;
  top: -7px;
  right: -5px;
  padding: 0;
  margin: 0;
`

export interface AdvancedFilterIconProps {
  'data-test-id'?: string
  active: boolean
  disabledView: boolean
  onClick: () => void
  ariaLabel?: string
}

export const AdvancedFilterIcon: React.FC<AdvancedFilterIconProps> = (
  { 'data-test-id': dataTestId, active, disabledView, onClick, ariaLabel },
) => {
  return (
    <IconWrapper data-test-id={dataTestId}>
      <HIconButton disabled={disabledView} color='primary' variant='outlined' onClick={onClick} aria-label={ariaLabel}>
        <SettingsAdjust16/>
      </HIconButton>
      { active &&
        (<IndicatorWrapper>
          <Indicator/>
        </IndicatorWrapper>
        )
      }
    </IconWrapper>
  )
}
