// @flow

/* eslint-disable flowtype/require-types-at-top */

import * as searchUrl from './helpers/url/searchUrl'
import * as query from './helpers/url/query'

import map from 'lodash/fp/map'
import filter from 'lodash/fp/filter'
import flow from 'lodash/fp/flow'
import head from 'lodash/fp/head'
import type { QueryParams, Locale, RouteLocation } from './types/common'

export const routeNames = Object.freeze({
  applicationDetail: 'application-detail',
  applications: 'application-overview',
  auth: 'passwordless-sign-in',
  applicationStatusUpdate: 'application-status-update',
  bookmarkedJobs: 'bookmarked-jobs',
  careerPage: 'career-page',
  combinedPrivacy: 'combined-privacy',
  confirmPhoneNumber: 'phone_number_confirmation',
  country: 'country',
  home: 'homepage',
  jobApplicationForm: 'job-apply-form-new',
  jobApplicationSuccess: 'job-apply-success',
  jobApplicationSuccessExperiment: 'job-apply-success-experiment',
  jobDetails: 'job',
  jobSearchKeyword: 'job-keyword-search',
  out: 'out',
  privacy: 'privacy',
  profile: 'profile_tab_menu',
  profileTabPersonalInformation: 'personal-information',
  profileTabNotificationSettings: 'notification-settings',
  profileTabJobSearchPreferences: 'job-search-preferences',
  profileTabAccountSettings: 'account-settings',
  deleteProfile: 'delete-profile',
  screeningQuestions: 'job-application-screening-questions',
  subscribe: 'subscribe',
  unsubscribe: 'unsubscribe',
  verifyEmail: 'verify-email',
  jobTypeQuiz: 'job-type-quiz',
  jobType: 'job-type',
  messageList: 'messages-list',
  messageDetails: 'messages-details',
  resetPassword: 'reset-password',
  termsAndConditions: 'agb',
  heyAssessmentAGB: 'agb-h',
})

export type RouteName = $Values<typeof routeNames>
export type RouteNameMap = typeof routeNames

type JobApplicationParams = {
  id?: string,
  ri?: string
}

type JobUrlParams = {
  disable_out_auto_redirect?: string,
  id?: string,
  preview?: string,
  ri?: string
}

type OutUrlParams = {
  from?: string,
  id?: string,
  linkOutSource?: string,
  ri?: string
}

type AmpJobUrlParams = {
  id?: string
}

const buildJobApplicationUrl = (prefix: string, country?: string, subRoute: string) => {
  return (params: JobApplicationParams = {}) => {
    const fullPrefix = country ? `${prefix}-${country}` : prefix

    return `${fullPrefix}/jobs/${params.id ||
      ':id'}/apply/${subRoute}${query.buildUrlFragment({
      ri: params.ri,
    })}`
  }
}

const buildJobUrl = (prefix: string, country?: string) => ({
  disable_out_auto_redirect, // eslint-disable-line camelcase
  id,
  ri,
  preview,
}: JobUrlParams = {}) => {
  const params = {
    disable_out_auto_redirect, // eslint-disable-line camelcase
    ri,
    preview,
  }
  const fullPrefix = country ? `${prefix}-${country}` : prefix
  return `${fullPrefix}/jobs/${id || ':id'}${query.buildUrlFragment(params)}`
}

const buildJobSplat = (prefix: string, country?: string) => {
  const fullPrefix = country ? `${prefix}-${country}` : prefix
  return `${fullPrefix}/jobs/*`
}

const buildAmpJobUrl = (
  prefix: string,
  country?: string
) => ({ id, ...params }: AmpJobUrlParams = {}) => {
  const fullPrefix = country ? `${prefix}-${country}` : prefix
  return `${fullPrefix}/jobs/${id || ':id'}/amp${query.buildUrlFragment(
    params
  )}`
}

const buildOutUrl = (prefix: string, country?: string, path: ?string) => ({
  id,
  from,
  linkOutSource,
  ri,
}: OutUrlParams = {}) => {
  const params = {
    from: from || undefined,
    linkOutSource: linkOutSource || undefined,
    ri,
  }
  const fullPrefix = country ? `${prefix}-${country}` : prefix
  return `${fullPrefix}/jobs/${id || ':id'}/${path || 'out'}${query.buildUrlFragment(
    params
  )}`
}

const buildStaticUrl = (prefix: string, country?: string) => (staticPath) => {
  const fullPrefix = country ? `${prefix}-${country}` : prefix
  return `${fullPrefix}/${staticPath}`
}

const buildEmailSubscriptionUrl = (
  prefix: string,
  country: string
) => (params?: QueryParams) =>
  `${prefix}-${country}/subscribe${query.buildUrlFragment(params)}`

const buildEmailUnSubscriptionUrl = (
  prefix: string,
  country: string
) => (params?: QueryParams) =>
  `${prefix}-${country}/unsubscribe${query.buildUrlFragment(params)}`

const buildConfirmPhoneNumberUrl = (
  prefix: string,
  country: string
) => (params?: QueryParams) =>
  `${prefix}-${country}/confirm-phone-number${query.buildUrlFragment(params)}`

const bookmarkedJobsUrl = (
  prefix: string,
  country?: string
) => {
  const fullPrefix = country ? `${prefix}-${country}` : prefix
  return `${fullPrefix}/bookmarked-jobs`
}

const buildApplicationDetailUrl = (
  prefix: string,
  country?: string
) => (applicationId?: string, params?: QueryParams) => {
  const fullPrefix = country ? `${prefix}-${country}` : prefix
  return `${fullPrefix}/applications/${applicationId || ':id'}${query.buildUrlFragment(params)}`
}

const buildProfileTabUrl = (
  prefix: string,
  country?: string
) => (tabName?: string, params?: QueryParams) => {
  const fullPrefix = country ? `${prefix}-${country}` : prefix
  return `${fullPrefix}/profile/${tabName || ':tabName'}${query.buildUrlFragment(params)}`
}

const buildApplicationDetailStatusUpdateUrl = (
  prefix: string,
  country?: string
) => (applicationId?: string, params?: QueryParams) => {
  const fullPrefix = country ? `${prefix}-${country}` : prefix
  return `${fullPrefix}/applications/${applicationId || ':id'}/update/status${query.buildUrlFragment(params)}`
}

const buildJobTypeQuizUrl = (
  prefix: string,
  country?: string
) => (params: ?QueryParams) => {
  const fullPrefix = country ? `${prefix}-${country}` : prefix

  return `${fullPrefix}/job-types/quiz/${query.buildUrlFragment(params)}`
}
const buildJobTypeUrl = (
  prefix: string,
  country?: string
) => (id?: string, params: ?QueryParams) => {
  const fullPrefix = country ? `${prefix}-${country}` : prefix

  return `${fullPrefix}/job-types/${id || ':codename'}${query.buildUrlFragment(params)}`
}

export const getIndexRoute = (locale: Locale, country: string) => `/${locale}-${country}`

const buildJobRoutes = (prefix: string, country?: string) => ({
  job: buildJobUrl(prefix, country), // this is JDP
  ampJob: buildAmpJobUrl(prefix, country),
  jobSplat: buildJobSplat(prefix, country),
  bookmarkedJobs: bookmarkedJobsUrl(prefix, country),
  jobApply: {
    form: buildJobApplicationUrl(prefix, country, 'form'),
    reduxForm: buildJobApplicationUrl(prefix, country, 'now'),
    screeningQuestions: buildJobApplicationUrl(prefix, country, 'screening-questions'),
    success: buildJobApplicationUrl(prefix, country, 'success'), // For 1-click-apply Fake Door Test
  },
  out: buildOutUrl(prefix, country),
  cvCreation: {
    overview: buildJobApplicationUrl(prefix, country, 'create-cv'),
    basicsPersonal: buildJobApplicationUrl(
      prefix,
      country,
      'create-cv/basics/personal'
    ),
    basicsContact: buildJobApplicationUrl(prefix, country, 'create-cv/basics/contact'),
    photo: buildJobApplicationUrl(prefix, country, 'create-cv/photo'),
    photoUpload: buildJobApplicationUrl(prefix, country, 'create-cv/photo/upload'),
    languages: buildJobApplicationUrl(prefix, country, 'create-cv/languages'),
    education: buildJobApplicationUrl(prefix, country, 'create-cv/education'),
    skillsJob: buildJobApplicationUrl(prefix, country, 'create-cv/skills/job'),
    skillsJobTasks: buildJobApplicationUrl(
      prefix,
      country,
      'create-cv/skills/job-tasks'
    ),
    skillsJobEdit: buildJobApplicationUrl(prefix, country, 'create-cv/skills/job/edit'),
    skillsJobTasksEdit: buildJobApplicationUrl(
      prefix,
      country,
      'create-cv/skills/job-tasks/edit'
    ),
    skillsExperience: buildJobApplicationUrl(
      prefix,
      country,
      'create-cv/skills/experience'
    ),
    motivationMessage: buildJobApplicationUrl(prefix, country, 'create-cv/motivation'),
  },
})

// TODO: @routes-refactoring
// (stateCountry) should equal (country)
// routes should be adjusted to always accept country
// /{locale}/* paths should be the exception
const generateRouteMap = (prefix: string) => ({
  home: `${prefix}`,
  auth: `${prefix}/auth`,
  jobSearch: ({ splat }: {splat: ?string} = {}) => `${prefix}/jobs-${splat || '*'}`,
  country: (country: string) => ({
    index: `${prefix}-${country}`,
    dashboard: `${prefix}-${country}/dashboard`,
    auth: `${prefix}-${country}/auth`,
    cafFlow: (jobId: string) => `/${prefix}-${country}/jobs/${jobId}/apply/now`,
    resetPassword: (code?: string) => `${prefix}-${country}/reset-password/${code || ':code'}`,
    applications: `${prefix}-${country}/applications`,
    jobSearch: ({ splat }: {splat: ?string} = {}) => `${prefix}-${country}/jobs-${splat || '*'}`,
    jobs: `${prefix}-${country}/jobs`,
    privacy: (subdomain?: string | null) => `${prefix}-${country}/privacy${subdomain ? `/${subdomain}` : ''}`,
    privacyForCompanies: `${prefix}-${country}/privacy/:subdomain`,
    verifyEmail: `${prefix}-${country}/verify-email`,
    unsubscribe: buildEmailUnSubscriptionUrl(prefix, country),
    subscribe: buildEmailSubscriptionUrl(prefix, country),
    profile: `${prefix}-${country}/profile`,
    profileTab: buildProfileTabUrl(prefix, country),
    deleteProfile: `${prefix}-${country}/delete-profile`,
    confirmPhoneNumber: buildConfirmPhoneNumberUrl(prefix, country),
    applicationDetail: buildApplicationDetailUrl(prefix, country),
    applicationUpdateStatus: buildApplicationDetailStatusUpdateUrl(prefix, country),
    jobTypeQuiz: buildJobTypeQuizUrl(prefix, country),
    jobType: buildJobTypeUrl(prefix, country),
    termsAndConditions: `${prefix}-${country}/agb`,
    heyAssessmentAGB: `${prefix}-${country}/agb-h`,
    ...buildJobRoutes(prefix, country),
  }),
  unsubscribe: buildStaticUrl(prefix)('unsubscribe'),
  subscribe: buildStaticUrl(prefix)('subscribe'),
  confirmPhoneNumber: buildStaticUrl(prefix)('confirm-phone-number'),
  everywhere: `${prefix}/*`,
  verifyEmail: buildStaticUrl(prefix)('verify-email'),
  ...buildJobRoutes(prefix), /* for old paths */
})

export const getRoutesForLocale = (locale: Locale) => generateRouteMap(`/${locale}`)

export const generateRoutes = (locale: Locale) => {
  return getRoutesForLocale(locale)
}

export const extractSplatFromPath = (locale: Locale, country: string) => (
  path: string
): string => {
  const countryRoutes = getRoutesForLocale(locale).country(country)
  const jobsRoutes = [
    countryRoutes.index,
    countryRoutes.jobs,
    countryRoutes.jobSearch(),
  ]

  // FUTURE: this could be implemented in a lazy way and it would save us the extra call.
  // But lodash doesn't support lazy-eval without `chain`, so this stays eager until they do.
  const match = flow(
    // route patterns with wildcards replaced with groups * -> (.*)
    map((route) => new RegExp(`^${route.replace(/\*/, '(.*)')}(?:/)?$`)),
    // match patterns with path
    map((p) => p.exec(path)),
    // filter out non-matching ones
    filter((r) => r !== null),
    // get the first one
    head
  )(jobsRoutes)

  if (!match) {
    throw new Error(`job-search pattern could not be matched with ${path}`)
  }
  // take the group value or return empty string
  return match.length > 1 ? match[1] : ''
}

export function routeForTargetLocale (
  targetLocale: Locale,
  params: QueryParams = {},
  routeName: string,
  location: RouteLocation,
  locale: Locale,
  country: string
) {
  // NOTE: rebuilding the full url to replace reserved keywords
  // like (for => fuer) (with => mit)
  const isSearchPage = routeName === 'job-keyword-search'
  const isCareerPage = routeName === 'career-page'
  if (isSearchPage || isCareerPage) {
    const extractSplat = extractSplatFromPath(locale, country)
    const searchSplat = extractSplat(location.pathname)
    const searchOptions = searchUrl.parse(
      searchSplat,
      location.query,
      locale,
      country
    )
    return searchUrl.build(searchOptions, targetLocale, country, isCareerPage)
  }
  const currentLocation = location.pathname + location.search
  return currentLocation.replace(`${locale}`, `${targetLocale}`)
}
