// @flow

import React from 'react'
import Loadable from 'react-loadable'
import * as actionCreators from '../../actions/actionCreators'
import { getIndexRoute } from '../../routes'
import { getRankingOptions } from '../../epics/searchEpic/rankingOptions'
import { withUniversalDataLoader } from '../../components/containers/withDataLoader'

import type { Job } from '../../types/job'
import type { RouteData } from '../../types/common'
import type { Dispatch } from '../../store/configureStore'
import type { ApplicationState } from '../../types/applicationState'

const recommendedJobsQuery = (job: Job) => {
  if (job?.type.codename === 'other') {
    return job?.title
  } else {
    return job?.type.codename
  }
}

export const getRecommendationOptionsFromJob = (job: Job) => ({
  query: recommendedJobsQuery(job) || '',
  location: job?.location?.city || '',
  country_code: job?.location?.country_code || '',
})

export const dataLoader = async (
  dispatch: Dispatch,
  state: ApplicationState,
  routeData: RouteData,
  replace: Function
) => {
  // if user did not access page after job application, redirect to job search page
  if (!(routeData.location.state && routeData.location.state.redirect)) {
    const redirectUrl =
      state.company && state.company.current
        ? '/'
        : getIndexRoute(state.intlData.locale, state.locality.country)
    replace(redirectUrl)
    return
  }

  if (state.currentJob && state.currentJob.job) {
    const options = {
      ...getRecommendationOptionsFromJob(state.currentJob.job),
      ...getRankingOptions(state),
      ...(state.company.current ? { company_subdomain: state.company.current.subdomain } : {}),
    }

    await dispatch(actionCreators.fetchRecommendedJobs(options))
  }
}

const LoadableEmailVerification = Loadable<$FlowTODO, $FlowTODO>({
  loader: () => import('./EmailVerification'),
  loading: () => <div />,
})

const EmailVerification = withUniversalDataLoader(dataLoader)(LoadableEmailVerification)

export default EmailVerification
