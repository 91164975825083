import React from 'react'
import { COLORS } from '../theme'

export interface HMenuIndicatorProps {
  size?: number
  color?: string
  animate?: boolean
  strokeWidth?: number
}
export const HMenuIndicator: React.FC<HMenuIndicatorProps> = ({
  size = 16,
  color = COLORS.accent_pink_50,
  animate = true,
  strokeWidth = 1,
}: HMenuIndicatorProps) => (
  <svg width={size} height={size} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10" cy="10" fill={color} r="6" stroke={COLORS.surface_white} strokeWidth={strokeWidth}/>
    <circle cx="10" cy="10" fill={color} r="6">
      {animate && <>
        <animate attributeName="r" from="6" to="10" dur="1.5s" begin="0s" repeatCount="indefinite"/>
        <animate attributeName="opacity" from="1" to="0" dur="1.5s" begin="0s" repeatCount="indefinite"/>
      </> }
    </circle>
  </svg>)
