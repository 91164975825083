// @flow
import * as PlanOut from 'planout'
import { ORIGINAL_VARIANT } from '../abTestingDefinitions'
import { storeExperimentIDInCookie } from '../abTestingCookies'
import type { AbTestingDefinition, AbTestingVariant } from '../abTestingTypes'

import type { Cookies } from 'react-cookie'

export type PlanoutAssignmentArgs = {
  experimentName: string,
  trackingToken: string,
  variants: AbTestingVariant[]
}

type GetUserGroupProps = {
  experimentName: string,
  forcedExperimentName: string,
  forcedExperimentUserGroup: string,
  host: string,
  initializeAbTests: (experimentData: { [key: string]: string }) => void,
  previousAssignments: { [key: string]: { group_name: string, source: string} },
  trackingToken: string
}

export class Planout extends PlanOut.Experiment {
  configureLogger () {}

  getParamNames () {
    return this.getDefaultParamNames()
  }

  setup () {
    this.setName(this.inputs.experimentName)
  }

  assign (params: any, args: PlanoutAssignmentArgs) {
    const choices = args.variants.map((variant) => variant.name)
    const weights = args.variants.map((variant) => variant.weight)

    params.set(
      args.experimentName,
      new PlanOut.Ops.Random.WeightedChoice({
        choices,
        weights,
        unit: args.trackingToken,
      })
    )
  }
}

export const getExperimentDefinition = (
  experimentName: string,
  definitions: AbTestingDefinition[]
) =>
  definitions.find((definition) =>
    definition.experimentName === experimentName)

export const isGroupIdValid = (
  groupId: string,
  experimentName: string,
  definitions: Object
): bool => {
  if (!groupId || !experimentName) {
    return false
  }

  const definition = definitions.find((definition) => definition.experimentName === experimentName)
  return !!(definition && (definition.experimentVariants.findIndex(({ name }) => name === groupId) > -1))
}

// NOTE: try to return early as much as you can to avoid
// .map and .find statements
export const getUserGroup = (cookieJar: Cookies) => (
  props: GetUserGroupProps,
  definitions: AbTestingDefinition[]
) => {
  const {
    host,
    experimentName,
    trackingToken,
    previousAssignments,
    forcedExperimentName,
    forcedExperimentUserGroup,
  } = props

  const isForcedExperiment = forcedExperimentName === experimentName
  const isForcedVariantDiffersFromAssignedVariant =
    forcedExperimentUserGroup !== previousAssignments[props.experimentName]?.group_name

  if (
    isForcedExperiment && isForcedVariantDiffersFromAssignedVariant &&
    isGroupIdValid(forcedExperimentUserGroup, forcedExperimentName, definitions)
  ) {
    props.initializeAbTests({
      name: forcedExperimentName,
      group_name: forcedExperimentUserGroup,
    })

    return forcedExperimentUserGroup
  }

  const previousGroup = previousAssignments[props.experimentName]
  if (previousGroup?.group_name) {
    return previousGroup.group_name
  }

  const experimentDefinition = getExperimentDefinition(experimentName, definitions)
  if (experimentDefinition) {
    const planoutArgs: PlanoutAssignmentArgs = {
      experimentName,
      trackingToken,
      variants: experimentDefinition.experimentVariants,
    }
    const createdUserGroup = new Planout(planoutArgs)
    const createdUserGroupName = createdUserGroup.get(experimentName)

    props.initializeAbTests({
      name: experimentName,
      group_name: createdUserGroupName,
    })

    storeExperimentIDInCookie(cookieJar)(experimentDefinition.experimentID, host)

    return createdUserGroupName
  } else {
    // Fallback if experimentDefinition doesn't exist
    return ORIGINAL_VARIANT
  }
}
