// @flow

import * as React from 'react'
import { Route as _Route, Switch, Redirect } from 'react-router-dom'
import flattenDeep from 'lodash/flattenDeep'

import Route from './Route'

import SlimLayout from '../../layouts/SlimLayout'

import App from '../containers/app'
import JobDetailPage from '../../pages/JobDetail'
import Out from '../../pages/Out'
import UnsubscribePage from '../../pages/EmailUnsubscribe'
import SubscribePage from '../../pages/EmailSubscribe'
import BookmarkedPage from '../../pages/Bookmarked'
import ProfilePage from '../../pages/Profile/index.tsx'
import ProfileTabPage from '../../pages/Profile/tabs/index.tsx'
import ApplicationsPage from '../../pages/Applications'
import ApplicationSuccess from '../../pages/ApplicationSuccess'
import SearchPage from '../../pages/Search'
import Auth from '../../pages/Auth'
import ResetPassword from '../../pages/ResetPassword/ResetPasswordWrapper.tsx'
import DeleteProfile from '../../pages/DeleteProfile/index.tsx'
import JobApplication from '../jobApplication'
import EmailVerificationPage from '../../pages/EmailVerification'
import ApplicationDetailsPage from '../../pages/ApplicationDetails'
import ApplicationStatusUpdatePage from '../jobApplication/ApplicationStatusUpdate'
import JobTypeQuizPage from '../../pages/JobTypeQuiz'
import JobTypePage from '../../pages/JobType'
import Privacy from '../../pages/Privacy'
import { NotFound } from '../../pages/NotFound'

import TermsAndConditionsPage from '../../pages/TermsAndConditions/TermsAndConditions.tsx'
import { HeyAssessmentAGB } from '../../pages/HeyAssessmentAGB/HeyAssessmentAGB.tsx'
import { prefixRouteName, type PrefixedRouteName } from '../../routing/routeNamePrefix'
import { generateRoutes, routeNames } from '../../routes'
import config from '../../../config'
import { GrowthBook } from '@growthbook/growthbook-react'

import type { LayoutProps } from './Route'
import type { History } from 'history'
import type { RouteName } from '../../routes'
import type { Locale } from '../../types/common'
import type { ApplicationState } from '../../types/applicationState'
import type { Action } from '../../store/configureStore'
import type { Store } from 'redux'
import type { Cookies } from 'react-cookie'
import { FragmentWrapper } from '../../layouts/MainLayout'

type RoutesProps = {|
  cookies: Cookies,
  growthbook: GrowthBook,
  history: History,
  store: Store < ApplicationState, Action >
|}

type RedirectOptions = {|
  from: string,
  to: string
|}

export type RouteOptions = {|
  component: $FlowTODO,
  country: string,
  exact ?: bool,
  layout: ?React$ComponentType < LayoutProps >,
  locale: string,
  name: PrefixedRouteName,
  path: string,
  routePathFromRoutesMap: string,
  scrollTop ?: bool,
|}

type RoutingOptions = RedirectOptions | RouteOptions

const getRouteOption = ({
  locale,
  country,
  name,
  component,
  path,
  routePathFromRoutesMap,
  layout = null,
  exact = true,
  scrollTop = true,
}): RouteOptions => ({
  exact,
  locale,
  country,
  name,
  component,
  routePathFromRoutesMap,
  layout,
  path,
  scrollTop,
})

// FIXME(TPRE-2104): Remove this route if we will move reload logic from Route.js
const getHomeRoutes = (route, locale, country, prefix): RoutingOptions[] => [
  getRouteOption({
    name: prefix(routeNames.home),
    path: route.country(country).index,
    routePathFromRoutesMap: 'index',
    component: null,
    locale,
    country,
  }),
]

const getCareerPageRoutes = (route, locale, country, prefix): RoutingOptions[] => [
  getRouteOption({
    name: prefix(routeNames.careerPage),
    routePathFromRoutesMap: 'index',
    path: route.country(country).index,
    component: SearchPage,
    locale,
    country,
  }),
  getRouteOption({
    name: prefix(routeNames.careerPage),
    routePathFromRoutesMap: 'index',
    path: route.country(country).jobSearch(),
    component: SearchPage,
    locale,
    country,
  }),
  getRouteOption({
    layout: FragmentWrapper,
    locale,
    country,
    name: prefix(routeNames.resetPassword),
    routePathFromRoutesMap: 'resetPassword',
    path: route.country(country).resetPassword(),
    component: ResetPassword,
  }),
]

const getSearchRoutes = (route, locale, country, prefix): RoutingOptions[] => [
  {
    from: route.jobSearch(), // Country-less Job Search
    to: route.country(country).jobSearch(),
  },
  getRouteOption({
    name: prefix('job-keyword-search'),
    routePathFromRoutesMap: 'index',
    path: route.country(country).jobSearch(),
    component: SearchPage,
    locale,
    country,
  }),
]

const getJobRoutes = (route, locale, country, prefix): RoutingOptions[] => [
  {
    from: route.job(), // Country-less JDP paths
    to: route.country(country).job(),
  },
  {
    from: route.country(country).ampJob(), // RETIRED AMP pages
    to: route.country(country).job(),
  },
  getRouteOption({
    layout: FragmentWrapper,
    name: prefix(routeNames.jobDetails),
    path: route.country(country).job(),
    routePathFromRoutesMap: 'job',
    component: JobDetailPage,
    locale,
    country,
  }),
  getRouteOption({
    layout: FragmentWrapper,
    name: prefix(routeNames.out),
    routePathFromRoutesMap: 'out',
    path: route.country(country).out(),
    component: Out,
    exact: true,
    locale,
    country,
  }),
]

const getPrivacyRoutes = (route, locale, country, prefix): RoutingOptions[] => [
  getRouteOption({
    layout: SlimLayout,
    locale,
    country,
    name: prefix(routeNames.privacy),
    routePathFromRoutesMap: 'privacy',
    path: route.country(country).privacy(),
    component: Privacy,
  }),
  getRouteOption({
    layout: SlimLayout,
    locale,
    country,
    name: prefix(routeNames.privacy),
    routePathFromRoutesMap: 'privacy',
    path: route.country(country).privacyForCompanies,
    component: Privacy,
  }),
]

const getApplicationRoutes = (route, locale, country, prefix): RoutingOptions[] => [
  {
    from: route.country(country).jobApply.screeningQuestions(), // RETIRED separate screening-question path
    to: route.country(country).jobApply.reduxForm(),
  },
  {
    from: route.country(country).jobApply.form(), // RETIRED application flow path (pre-CAF)
    to: route.country(country).jobApply.reduxForm(),
  },
  {
    from: route.jobApply.reduxForm(), // country-less application flow path
    to: route.country(country).jobApply.reduxForm(),
  },
  {
    from: route.jobApply.screeningQuestions(), // RETIRED country-less screening-question path
    to: route.country(country).jobApply.reduxForm(),
  },
  getRouteOption({
    layout: FragmentWrapper,
    locale,
    country,
    name: prefix(routeNames.jobApplicationForm),
    routePathFromRoutesMap: 'jobApply.reduxForm',
    path: route.country(country).jobApply.reduxForm(),
    component: JobApplication,
  }),
  getRouteOption({
    layout: FragmentWrapper,
    locale,
    country,
    name: prefix(routeNames.verifyEmail),
    routePathFromRoutesMap: 'verifyEmail',
    path: route.country(country).verifyEmail,
    component: EmailVerificationPage,
  }),
  getRouteOption({
    layout: SlimLayout,
    locale,
    country,
    name: prefix(routeNames.jobApplicationSuccess),
    routePathFromRoutesMap: 'jobApply.success',
    path: route.country(country).jobApply.success(),
    component: ApplicationSuccess,
  }),
]

const getUserRoutes = (route, locale, country, prefix): RoutingOptions[] => [
  getRouteOption({
    layout: FragmentWrapper,
    locale,
    country,
    name: prefix(routeNames.resetPassword),
    routePathFromRoutesMap: 'resetPassword',
    path: route.country(country).resetPassword(),
    component: ResetPassword,
  }),
  getRouteOption({
    layout: FragmentWrapper,
    locale,
    country,
    name: prefix(routeNames.auth),
    routePathFromRoutesMap: 'auth',
    path: route.country(country).auth,
    component: Auth,
  }),
  getRouteOption({
    layout: SlimLayout,
    locale,
    country,
    name: prefix(routeNames.bookmarkedJobs),
    path: route.country(country).bookmarkedJobs,
    routePathFromRoutesMap: 'bookmarkedJobs',
    component: BookmarkedPage,
  }),
  getRouteOption({
    layout: SlimLayout,
    locale,
    country,
    name: prefix(routeNames.profile),
    path: route.country(country).profile,
    routePathFromRoutesMap: 'profile',
    component: ProfilePage,
  }),
  getRouteOption({
    layout: SlimLayout,
    locale,
    country,
    name: prefix(routeNames.profileTabPersonalInformation),
    path: route.country(country).profileTab('personal-information'),
    routePathFromRoutesMap: 'profileTabPersonalInformation',
    component: ProfileTabPage,
  }),
  getRouteOption({
    layout: SlimLayout,
    locale,
    country,
    name: prefix(routeNames.profileTabNotificationSettings),
    path: route.country(country).profileTab('notification-settings'),
    routePathFromRoutesMap: 'profileTabNotificationSettings',
    component: ProfileTabPage,
  }),
  getRouteOption({
    layout: SlimLayout,
    locale,
    country,
    name: prefix(routeNames.profileTabJobSearchPreferences),
    path: route.country(country).profileTab('job-search-preferences'),
    routePathFromRoutesMap: 'profileTabJobSearchPreferences',
    component: ProfileTabPage,
  }),
  getRouteOption({
    layout: SlimLayout,
    locale,
    country,
    name: prefix(routeNames.profileTabAccountSettings),
    path: route.country(country).profileTab('account-settings'),
    routePathFromRoutesMap: 'profileTabAccountSettings',
    component: ProfileTabPage,
  }),
  getRouteOption({
    layout: SlimLayout,
    locale,
    country,
    name: prefix(routeNames.deleteProfile),
    path: route.country(country).deleteProfile,
    routePathFromRoutesMap: 'deleteProfile',
    component: DeleteProfile,
  }),
  getRouteOption({
    layout: SlimLayout,
    locale,
    country,
    name: prefix(routeNames.applications),
    path: route.country(country).applications,
    routePathFromRoutesMap: 'applications',
    component: ApplicationsPage,
  }),
  getRouteOption({
    layout: SlimLayout,
    locale,
    country,
    name: prefix(routeNames.applicationDetail),
    path: route.country(country).applicationDetail(),
    routePathFromRoutesMap: 'applicationDetail',
    component: ApplicationDetailsPage,
  }),
  getRouteOption({
    layout: SlimLayout,
    locale,
    country,
    name: prefix(routeNames.unsubscribe),
    routePathFromRoutesMap: 'unsubscribe',
    path: route.country(country).unsubscribe(),
    component: UnsubscribePage,
  }),
  getRouteOption({
    layout: SlimLayout,
    locale,
    country,
    name: prefix(routeNames.subscribe),
    routePathFromRoutesMap: 'subscribe',
    path: route.country(country).subscribe(),
    component: SubscribePage,
  }),
  getRouteOption({
    layout: SlimLayout,
    locale,
    country,
    name: prefix(routeNames.applicationStatusUpdate),
    routePathFromRoutesMap: 'applicationUpdateStatus',
    path: route.country(country).applicationUpdateStatus(),
    component: ApplicationStatusUpdatePage,
  }),
  getRouteOption({
    layout: SlimLayout,
    locale,
    country,
    name: prefix(routeNames.jobTypeQuiz),
    routePathFromRoutesMap: 'jobTypeQuiz',
    path: route.country(country).jobTypeQuiz(),
    component: JobTypeQuizPage,
  }),
  getRouteOption({
    layout: SlimLayout,
    locale,
    country,
    name: prefix(routeNames.jobType),
    routePathFromRoutesMap: 'jobType',
    path: route.country(country).jobType(),
    component: JobTypePage,
  }),
]

const getTermsAndConditionsRoutes = (route, locale, country, prefix): RoutingOptions[] => [
  getRouteOption({
    layout: SlimLayout,
    locale,
    country,
    name: prefix(routeNames.termsAndConditions),
    routePathFromRoutesMap: 'agb',
    path: route.country(country).termsAndConditions,
    component: TermsAndConditionsPage,
  }),
  getRouteOption({
    layout: SlimLayout,
    locale,
    country,
    name: prefix(routeNames.termsAndConditions),
    routePathFromRoutesMap: 'agb-hey-assessment',
    path: route.country(country).heyAssessmentAGB,
    component: HeyAssessmentAGB,
  }),
]

export const getRoutes = (state: ApplicationState): RoutingOptions[] => {
  const locale: Locale = state.intlData && state.intlData.locale
  const country = state.locality.country
  const isCareerPage = !!state.company.current
  const baseRoutes: RoutingOptions[] = [
    {
      from: '/',
      to: `/${locale}-${country}`,
    },
    {
      from: `/${locale}-${country}/jobs`,
      to: `/${locale}-${country}${state.router.location?.search || ''}`,
    },
    // Ensure we don't break in (accidental) client side navigation to dashboard
    // - implemented here rather than getHomeRoutes so we're safe on career pages
    {
      from: `/${locale}-${country}/dashboard`,
      to: `/${locale}-${country}`,
    },
  ]

  const localizedRoutes = flattenDeep < RoutingOptions, any>(config.locale.available.map((locale) => (
    config.countries.available.map((country) => {
      const route = generateRoutes(locale)
      const prefix = (name: RouteName) => prefixRouteName(name, locale)
      const sharedRoutes = [
        getJobRoutes(route, locale, country, prefix),
        getSearchRoutes(route, locale, country, prefix),
        getApplicationRoutes(route, locale, country, prefix),
        getPrivacyRoutes(route, locale, country, prefix),
        getTermsAndConditionsRoutes(route, locale, country, prefix),
      ]
      return isCareerPage
        ? [
            sharedRoutes,
            getCareerPageRoutes(route, locale, country, prefix),
          ]
        : [
            sharedRoutes,
            getHomeRoutes(route, locale, country, prefix),
            getUserRoutes(route, locale, country, prefix),
          ]
    })
  )))
  const routes = [...baseRoutes, ...localizedRoutes]

  return routes
}

const Routes = (props: RoutesProps) => {
  const state: ApplicationState = props.store.getState()
  const routes = getRoutes(state)
  return (
    <App>
      <Switch>
        {routes.map((routeProps: RoutingOptions) => {
          return routeProps.from
            ? (
              <Redirect
                exact
                key={`${routeProps.to}-${routeProps.from}`}
                from={routeProps.from}
                to={routeProps.to}
              />
              )
            : routeProps.path
              ? (
                <Route
                  history={props.history}
                  store={props.store}
                  cookies={props.cookies}
                  growthbook={props.growthbook}
                  key={routeProps.path}
                  component={routeProps.component}
                  locale={routeProps.locale}
                  country={routeProps.country}
                  name={routeProps.name}
                  path={routeProps.path}
                  exact={routeProps.exact}
                  layout={routeProps.layout}
                  routePathFromRoutesMap={routeProps.routePathFromRoutesMap}
                  scrollTop={routeProps.scrollTop}
                />
                )
              : null
        })}

        <_Route
          exact
          path='*'
          component={NotFound}
        />
      </Switch>
    </App>
  )
}

export default Routes
