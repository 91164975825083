import { Theme, useMediaQuery } from '@mui/material'

export const useScreenSize = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const isMobileOrTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))
  const isLargeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))

  return {
    isMobile,
    isMobileOrTablet,
    isLargeScreen
  }
}
