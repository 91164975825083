import { useDeferredValue, useEffect, useState } from 'react'
import { FetchJobTitles, FetchJobTitlesParams, JobTitle } from '../types/job-titles'
import debounce from 'lodash.debounce'

const debounceTimeInMs = 500

export const useJobTitlesRequest = ({ query, excludeTitleIds, limit, fetchJobTitles }: FetchJobTitlesParams & {fetchJobTitles: FetchJobTitles}) => {
  const [fetchedJobTitles, setFetchedJobTitles] = useState<JobTitle[]>([])
  const deferredFetchedJobTitles = useDeferredValue(fetchedJobTitles)

  useEffect(() => {
    const trimmedQuery = query.trim()
    const minimumLength = 3

    if (trimmedQuery.length >= minimumLength) {
      debounce(() => {
        fetchJobTitles({ query, excludeTitleIds, limit }).then(setFetchedJobTitles)
      }, debounceTimeInMs)()
    } else {
      setFetchedJobTitles([])
    }
  }, [query, excludeTitleIds, limit, fetchJobTitles])

  return deferredFetchedJobTitles
}
