// @flow

import pick from 'lodash/pick'
import cookies from '../cookies'
import type { Cookies } from 'react-cookie'

export type MarketingParams = {
  campaign?: string,
  content?: string,
  medium?: string,
  source?: string,
  term?: string
}

const eligibleMarketingParams = [
  'source',
  'medium',
  'campaign',
  'term',
  'content',
]

const filter = (params: ? Object): MarketingParams =>
  params ? pick(params, eligibleMarketingParams) : {}

const parse = (serializedParams: string | Object): Object => {
  if (typeof serializedParams === 'string') {
    try {
      return JSON.parse(serializedParams)
    } catch (e) {
      return {}
    }
  } else {
    return serializedParams // already deserialised into a object
  }
}

const currentDateWithAddedDays = (days: number) => new Date(new Date().valueOf() + 864E5 * days)

export const load = (cookieJar: Cookies) => (): ?MarketingParams => {
  const params = cookies.load(cookieJar)(cookies.identifiers.MARKETING_PARAMETERS)
  if (params) {
    const filtered = filter(parse(params))
    return Object.keys(filtered).length ? filtered : null
  }
  return null
}

export const save = (cookieJar: Cookies) => (params: MarketingParams) => {
  cookies.save(cookieJar)(
    cookies.identifiers.MARKETING_PARAMETERS,
    JSON.stringify(params), {
      expires: currentDateWithAddedDays(90),
      domain: window.location.hostname,
    }
  )
}
