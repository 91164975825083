import { useCallback, useEffect, useState } from 'react'
import {
  type UserProfileCompletionStatus,
  type ProfileTabName,
  type ValidMenuIndicatorPositions,
  type DataPoints,
  type ProfileCategories,
} from '../types/user'

const allMenuPositions = ['top_menu_bar', 'top_menu_open_profile_link'] as const

export type UseShowProfileCompletionIndicatorProps =
  { keyType: 'dataPoint', keyName: DataPoints }
  | { keyType: 'category', keyName: ProfileTabName }
  | { keyType: 'menuIndicator', keyName: ValidMenuIndicatorPositions }

export const PROFILE_CATEGORIES: ProfileCategories = {
  'personal-information': ['full_name', 'location', 'phone_number', 'cv'],
  'job-search-preferences': ['job_title', 'working_hours_type', 'employment_type', 'minimum_salary', 'preferred_location'],
  'notification-settings': [],
  'account-settings': [],
}

const isDataPointCompleted = (dataPoint: DataPoints, profileCompletionStatus: UserProfileCompletionStatus) => {
  return profileCompletionStatus?.[dataPoint] === 'completed'
}

const isCategoryCompleted = (category: ProfileTabName, profileCompletionStatus: UserProfileCompletionStatus) => {
  return PROFILE_CATEGORIES[category].every(dataPoint => {
    return isDataPointCompleted(dataPoint, profileCompletionStatus)
  })
}

const categoriesWithIndicator: ProfileTabName[] = ['personal-information', 'job-search-preferences']

const isProfileCompleted = (profileCompletionStatus: UserProfileCompletionStatus) => {
  const areRelevantCategoriesComplete = categoriesWithIndicator.every(category => isCategoryCompleted(category, profileCompletionStatus))
  return (profileCompletionStatus?.percent === 100 || areRelevantCategoriesComplete)
}

const shouldShowIndicator = (props: UseShowProfileCompletionIndicatorProps, profileCompletionStatus: UserProfileCompletionStatus | undefined) => {
  if (!profileCompletionStatus) {
    // wait until the profile object is initialized before making a decision
    return false
  }

  switch (props.keyType) {
    case 'category':
      return !isCategoryCompleted(props.keyName, profileCompletionStatus)
    case 'dataPoint':
      return !isDataPointCompleted(props.keyName, profileCompletionStatus)
    case 'menuIndicator':
      return !isProfileCompleted(profileCompletionStatus) && !allMenuIndicatorsSeen()
  }
}

const localStorageKey = (keyName: DataPoints | ProfileTabName | ValidMenuIndicatorPositions) => `${keyName}-indicator-seen`
const localStorageSeenValue = 'true'

// We need to trigger a custom event for listening to localstorage reliably
const localStorageWindowEventName = 'local-storage'

const setIndicatorSeen = (indicator: DataPoints | ProfileTabName | ValidMenuIndicatorPositions) => {
  if (typeof window === 'undefined') {
    return
  }

  window.localStorage.setItem(localStorageKey(indicator), localStorageSeenValue)
  window.dispatchEvent(new Event(localStorageWindowEventName))
}

const isMenuIndicatorSeen = (position: ValidMenuIndicatorPositions) => {
  if (typeof window === 'undefined') {
    return
  }

  return window.localStorage.getItem(localStorageKey(position)) === localStorageSeenValue
}

const allMenuIndicatorsSeen = () => {
  return allMenuPositions.every(isMenuIndicatorSeen)
}

const useReRenderOnLocalStorageChange = () => {
  const [, setNeedsRecalculation] = useState(false)

  useEffect(() => {
    const triggerRecalculation = () => {
      setNeedsRecalculation((state) => !state)
    }

    window.addEventListener(localStorageWindowEventName, triggerRecalculation)

    return () => {
      window.removeEventListener(localStorageWindowEventName, triggerRecalculation)
    }
  }, [])
}

export const useShowProfileCompletionIndicator = (props: UseShowProfileCompletionIndicatorProps, profileCompletionStatus: UserProfileCompletionStatus | undefined) => {
  const [showIndicator, setShowIndicator] = useState(false)
  useReRenderOnLocalStorageChange()

  useEffect(() => {
    setShowIndicator(shouldShowIndicator(props, profileCompletionStatus))
  }, [profileCompletionStatus, props])

  const setIndicatorSeenForKey = useCallback(() => {
    setIndicatorSeen(props.keyName)
  }, [props.keyName])

  return { showIndicator, setIndicatorSeen: setIndicatorSeenForKey }
}
