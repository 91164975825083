import styled from '@emotion/styled'
import TextField from '@mui/material/TextField'
import { COLORS, globalStyles } from '../theme'

export const HInputStyled = styled(TextField)`
  & label.MuiFormLabel-root {
    font-size: 14px;
  }
  & label.Mui-focused {
    color: ${COLORS.primary_50};
  }
  & label.Mui-error {
    color: ${COLORS.support_error_50};
  }
  & label.MuiInputLabel-startAdorned {
    margin-left: 18px;
  }
  & .MuiInput-underline:after {
    border-bottom-color: ${COLORS.primary_50};
  }
  & .MuiOutlinedInput-adornedStart {
    padding-left: 12px;
  }
  & .MuiFormHelperText-root {
    margin-left: 8px;
  }
  & .MuiOutlinedInput-input {
    color: ${COLORS.surface_gray_90};
    padding: 11px 12px;
    font-size: 14px;
    height: 18px;
    line-height: 16px;
  }
  & .MuiInputBase-inputAdornedStart {
    padding-left: 0;
  }
  & .MuiInputBase-inputAdornedEnd {
    padding-right: 0;
  }
  & .MuiOutlinedInput-adornedEnd {
    padding-right: 12px;
  }
  & .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(12px, -8px);
    font-size: 12px;
  }
  & .MuiInputLabel-startAdorned.MuiInputLabel-shrink {
    transform: translate(-5px, -8px);
  }
  & .MuiOutlinedInput-root {
    background-color: ${COLORS.surface_white};
    & fieldset {
      border-color: ${COLORS.surface_gray_20};
      box-shadow: ${globalStyles.shadow1.boxShadow};
      border-width: 1px;
      font-size: 14px;
    }
    & fieldset legend span {
      font-size: 12px;
      padding-right: 0;
    }
    &:hover fieldset {
      border-color: ${COLORS.surface_gray_20};
      border-width: 1px;
    }
    &.Mui-focused fieldset {
      border-color: ${COLORS.primary_50};
      box-shadow: ${globalStyles.shadow2.boxShadow};
      border-width: 1px;
    }
  }
  & .MuiOutlinedInput-root.Mui-error {
    color: ${COLORS.support_error_50};
    & fieldset {
      border-color: ${COLORS.support_error_50};
      font-size: 14px;
    }
    &:hover fieldset {
      border-color: ${COLORS.support_error_50};
    }
    &.Mui-focused fieldset {
      border-color: ${COLORS.support_error_50};
    }
  }
  & .MuiInputAdornment-root {
    color: ${COLORS.surface_gray_60};
    font-size: 14px;
    font-weight: 400;
    padding-right: 1px;
    & p {
      color: ${COLORS.surface_gray_60};
      font-size: 14px;
      font-weight: 400;
    }
  }
  & .Mui-error {
    color: ${COLORS.support_error_50};
  }
  & .Mui-focused {}
  & .Mui-disabled {
    opacity: 0.4;
  }
  & .InputAction {
    color: ${COLORS.primary_50};
    cursor: pointer;
  }
  & .MuiOutlinedInput-multiline {
    padding: 0;
  }
  & .MuiInputBase-root {
    height: 100%;
  }
  & textarea {
    min-height: 170px;
    padding: 16px 0 !important;
    &::placeholder {
      font-style: italic;
    }
  }
`
