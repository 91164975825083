// @flow

import * as constants from '../constants/index'
import { cleanOnLocationChange } from './utils/cleanOn'

export type ServerResponseState = {
  headers: {
    'Cache-Control': ?string
  },
  status: ?number
}

const initialState: ServerResponseState = {
  headers: {
    'Cache-Control': null,
  },
  status: null,
}

const reducer = (state: ServerResponseState = initialState, action: $FlowTODO): ServerResponseState => {
  switch (action.type) {
    case constants.SET_CACHE_CONTROL:
      return {
        ...state,
        headers: {
          ...state.headers,
          'Cache-Control': action.value,
        },
      }
    case constants.SET_RESPONSE_STATUS:
      return {
        ...state,
        status: action.responseStatus,
      }
    default:
      return state
  }
}

export default cleanOnLocationChange(reducer)
