// @flow

import { setItem, getItem, identifiers, removeItem } from '../../../../helpers/sessionStorageUtils'

const sessionStorageIdentifier = identifiers.triggeredCAFAuthFlowJobId

export const setUsedAuthFlowJobId = (jobId: string) => {
  setItem(identifiers.usedAuthFlowJobId, jobId)
}

export const usedAuthFlow = (jobId: string): boolean => {
  const storedJobId = getItem(identifiers.usedAuthFlowJobId)

  if (!storedJobId) return false

  return storedJobId === jobId
}

export const clearUsedAuthFlow = () => {
  removeItem(identifiers.usedAuthFlowJobId)
}

export const setTriggeredCAFAuthFlowJobId = (jobId: string) => {
  setItem(sessionStorageIdentifier, jobId)
}

export const hasTriggerredCAFAuthFlow = (jobId: string) => {
  const storedJobId = getItem(sessionStorageIdentifier)

  if (storedJobId === undefined || storedJobId === null) {
    return undefined
  }

  return storedJobId === jobId
}
