import { Rocket16, Star16, ViewNext16 } from '@carbon/icons-react'
import React, { PropsWithChildren } from 'react'

import { HBadge } from '../HBadge'
import { HBox } from '../HBox'
import { HChip } from '../HChip'
import { HClampedText } from '../HClampedText'
import { HTypography } from '../HTypography'
import { JobCardContainer, JobCardOpacityWrapper } from './HJobCard.styles'
import { HJobCardProps } from './HJobCard.types'
import { JobCardHeader } from './HJobCardHeader'
import { JobCardButton } from './JobCardButton'
import { UnavailableJobActions } from './UnavailableJobActions'
import { JobCardTagList } from './JobCardTagList'
import { HighlightedContainer } from './HighlightedContainer'
import styled from '@emotion/styled'

const Description = styled(HTypography)`
  max-width: 100%;
`

export const HJobCard: React.FC<HJobCardProps> = ({
  variant = 'default',
  size = 'large',
  title,
  companyName,
  companyImage,
  location,
  multiLocationButton,
  description,
  tags,
  quickApply,
  postedTime,
  isSaved,
  savedText,
  onSaveClick,
  requisitionId,
  matchesProfileText,
  isUnavailable,
  jobNoLongerActiveText,
  removeFromSavedText,
  searchForSimilarText,
  onRemoveFromSavedClick,
  searchForSimilarProps,
  isCareerPage,
  verifiedBadgeText,
  showSaveButton,
  recentlyViewed,
  buttonProps,
  savedButtonProps,
  readMoreText,
  highlight,
}) => {
  const isSmall = size === 'small'

  const containerCardProps = isSmall
    ? { px: 4, pt: 4, pb: 4, opacity: 0.4 }
    : { px: 4, pt: 4, pb: 4, lg: { px: 6, pt: 6, pb: 6 } }

  const HighlightContainer = highlight
    ? HighlightedContainer
    : React.Fragment

  const StyledJobCardOpacityWrapper: React.FC<PropsWithChildren> = ({
    children,
  }) => (
    <JobCardOpacityWrapper
      display='flex'
      flexDirection='column'
      gap={16}
      sx={{ opacity: isUnavailable ? '0.4' : '1' }}
    >
      {children}
    </JobCardOpacityWrapper>
  )

  const jobTags = tags &&
    <JobCardTagList tags={tags} allowWrapping={variant === 'carousel'} />

  const descriptionContent = description && (
    <Description
      variant='caption'
      color='surface_gray_60'
      weight='regular'
    >
      <HClampedText
        lineClamp={2}
        dangerouslySetInnerHTML={{ __html: description }}
        suffixElement={
          readMoreText && (
            <HTypography
              color='primary_50'
              component={'span'}
              variant='caption'
              weight='regular'
            >
              {readMoreText}
            </HTypography>
          )
        }
      />
    </Description>
  )

  const matchesProfileChip = matchesProfileText && (
    <HChip
      size='small'
      variant='filled'
      color='help'
      icon={<Star16 />}
      placeholder={matchesProfileText}
    />
  )

  const recentlyViewedChip = recentlyViewed && (
    <HChip
      size='small'
      variant='filled'
      color='blue'
      icon={<ViewNext16 />}
      placeholder={recentlyViewed}
    />
  )

  const postedTimeBadge = postedTime && (
    <HBadge
      color='yellow'
      variant='full'
      data-test-id='date'
    >
      <time suppressHydrationWarning>{postedTime}</time>
    </HBadge>
  )

  const quickApplyChip = quickApply?.allowQuickApply && (
    <HChip
      color='success'
      variant='filled'
      size='small'
      placeholder={quickApply.quickApplyText}
      icon={<Rocket16 />}
      data-test-id='quick-apply-badge'
    />
  )

  const shouldRenderDescriptionContainer =
    descriptionContent || tags || matchesProfileChip || recentlyViewedChip

  const descriptionContainer = shouldRenderDescriptionContainer && (
    <HBox
      display='flex'
      flexDirection='column'
      alignItems='flex-start'
      gap={16}
    >
      {matchesProfileChip}
      {recentlyViewedChip}
      {descriptionContent}
    </HBox>
  )

  const shouldRenderBadges =
    matchesProfileChip ||
    recentlyViewedChip ||
    postedTimeBadge ||
    quickApplyChip

  const badges = shouldRenderBadges && (
    <HBox
      display='flex'
      alignItems={isSmall ? 'flex-start' : 'center'}
      justifyContent={isSmall ? 'flex-start' : 'space-between'}
      flexDirection={isSmall ? 'column' : 'row'}
      gap={16}
      alignSelf='stretch'
    >
      {postedTimeBadge}
      {quickApplyChip}
    </HBox>
  )

  const badgesAndDescription = (descriptionContainer || badges) && (
    <StyledJobCardOpacityWrapper>
      {descriptionContainer}
      {badges}
    </StyledJobCardOpacityWrapper>
  )

  const jobCardButton = buttonProps && !isUnavailable && (
    <JobCardButton {...buttonProps} />
  )

  const footer = (badgesAndDescription || jobCardButton) && (
    <HBox
      display='flex'
      flexDirection='column'
      gap={16}
      mt='auto'
      pt={4}
    >
      {badgesAndDescription}
      {jobCardButton}
    </HBox>
  )

  const unavailableJobActions = isUnavailable && (
    <UnavailableJobActions
      jobNoLongerActiveText={jobNoLongerActiveText}
      onRemoveFromSavedClick={onRemoveFromSavedClick}
      removeFromSavedText={removeFromSavedText}
      requisitionId={requisitionId}
      searchForSimilarProps={searchForSimilarProps}
      searchForSimilarText={searchForSimilarText}
      size={size}
    />
  )

  return (
    <HBox
      maxWidth={isSmall ? '640px' : 'unset'}
      width='100%'
      height='100%'
      position='relative'
    >
      <HighlightContainer {...highlight}>
        <JobCardContainer
          data-test-id={`gcts-card-${requisitionId}`}
          display='flex'
          flexDirection='column'
          justifyContent={buttonProps ? 'space-between' : 'flex-start'}
          highlight={!!highlight}
          className='job-card-container'
          {...containerCardProps}
        >
          <JobCardHeader
            companyName={companyName}
            companyImage={companyImage}
            isCareerPage={isCareerPage}
            isSaved={isSaved}
            isUnavailable={isUnavailable}
            jobNoLongerActiveText={jobNoLongerActiveText}
            location={location}
            multiLocationButton={multiLocationButton}
            onSaveClick={onSaveClick}
            removeFromSavedText={removeFromSavedText}
            requisitionId={requisitionId}
            savedButtonProps={savedButtonProps}
            savedText={savedText}
            searchForSimilarText={searchForSimilarText}
            verifiedBadgeText={verifiedBadgeText}
            showSaveButton={showSaveButton}
            size={size}
            title={title}
            tags={tags}
          />

          <StyledJobCardOpacityWrapper>
            {jobTags}
          </StyledJobCardOpacityWrapper>

          {footer}
          {unavailableJobActions}
        </JobCardContainer>
      </HighlightContainer>
    </HBox>
  )
}
