import React from 'react'
import { StyledPopover, classes } from './styles'
import { HPopoverProps } from './types'
import HHidden from '../HHidden'
import { HBox } from '../HBox'
import { HTypography } from '../HTypography'
import { HButton } from '../HButton'

export const HPopover: React.FC<HPopoverProps> = ({
  title,
  content,
  actions,
  anchorEl = null,
  anchorOrigin = {
    vertical: 'center',
    horizontal: 'center',
  },
  transformOrigin = {
    vertical: 'center',
    horizontal: 'center',
  },
  ...otherProps
}) => {
  return (
    <StyledPopover
      transformOrigin={transformOrigin}
      anchorOrigin={anchorOrigin}
      anchorEl={anchorEl}
      {...otherProps}
    >
      <HBox
        className={classes.root}
      >
        {title && <HBox className={classes.titleContainer}>
          <HHidden mdUp>
            <HTypography weight='regular' variant='h6' color='surface_gray_90'>{title}</HTypography>
          </HHidden>
          <HHidden mdDown>
            <HTypography weight='regular' variant='h5' color='surface_gray_90'>{title}</HTypography>
          </HHidden>
        </HBox>}
        <HBox className={classes.contentContainer}>
          <HHidden mdUp>
            <HTypography weight='regular' component='span' variant='body2'>{content}</HTypography>
          </HHidden>
          <HHidden mdDown>
            <HTypography weight='regular' component='span' variant='body1'>{content}</HTypography>
          </HHidden>
        </HBox>
        <HBox className={classes.actionsContainer}>
          { actions?.map((actionProps, index) =>
            (<HButton key={index} {...actionProps}/>),
          )}
        </HBox>
      </HBox>
    </StyledPopover>
  )
}
