// @flow

import type { Observable } from 'rxjs'
import API from '../clientBuilder'
import { buildUrlFragment } from '../../../helpers/url/query'
import type { APIRequestConfig } from '../types'

type JobSearchMode = 'JOB_SEARCH' | 'FEATURED_JOB_SEARCH'
type JobSearchOrderBy = 'relevance desc' | 'custom_ranking desc' | 'postingPublishTime desc'

export type JobSearchCustomRankingOptions = {
  importance_level: 'NONE' | 'LOW' | 'MILD' | 'MEDIUM' | 'HIGH' | 'EXTREME',
  ranking_expression: string
}

export type JobSearchRequestOptions = {
  allow_easy_apply?: string[],
  base_compensation?: string[],
  company_display_name?: string[],
  company_subdomain: string,
  country_code: string,
  custom_ranking_info?: JobSearchCustomRankingOptions,
  disable_spell_check: bool,
  distance_in_miles?: number,
  employment_types?: string[],
  experiment_variant?: string,
  location?: string,
  offset: number,
  order_by?: JobSearchOrderBy,
  page: number,
  page_size: number,
  query?: string,
  region_code: string,
  search_mode: JobSearchMode,
  working_hours_types?: string[]
}

type HistogramResultValues = {
  [string]: number,
}

export type HistogramResult = {
  histogram: HistogramResultValues,
  histogram_query: string,
  translated_values?: HistogramResultValues
}

type JobSearchMatchingJob = {
  job: $FlowTODO,
  job_summary: string
}

export type JobSearchResponse = {
  histogram_query_results: ?HistogramResult[],
  location_filters: ?$FlowTODO,
  matching_jobs: ?JobSearchMatchingJob[],
  metadata: { request_id: string },
  next_page_token: ?string,
  spell_correction: ?{ corrected: bool, corrected_text: string },
  total_size: ?number
}

export type JobSearchCompleteType = 'JOB_TITLE' | 'COMPANY_NAME'
export type JobSearchCompleteRequestType = JobSearchCompleteType | 'COMBINED'

export type JobSearchCompleteRequestOptions = {
  company_subdomain?: string,
  country_code: string,
  experiment_variant?: string,
  query: string,
  type?: JobSearchCompleteRequestType
}

type JobSearchCompleteSuggestion = {
  image_uri: ?string,
  suggestion: string,
  type: JobSearchCompleteType
}

type JobSearchCompleteResponse = JobSearchCompleteSuggestion[]

export type PlacePredictionsRequestOptions = {
  country_code?: string,
  page_size?: number,
  query: string,
}

export type LocationPrediction = {
  description: string,
  matched_substrings: {
    length: number,
    offset: number
    }[],
}

type PlacePredictionsResponse = {
  predictions: LocationPrediction[]
}

export default {
  search: (options: JobSearchRequestOptions, config: APIRequestConfig): Observable<JobSearchResponse> =>
    API.post.rx('/v2/search', options, config),

  complete: (options: JobSearchCompleteRequestOptions, config: APIRequestConfig): Observable<JobSearchCompleteResponse> =>
    API.get.rx(`/v2/complete${buildUrlFragment(options)}`, null, config),

  completePromise: (options: JobSearchCompleteRequestOptions, config: APIRequestConfig) =>
    API.get.promise(`/v2/complete${buildUrlFragment(options)}`, null, config),

  jobsCount: (options: JobSearchRequestOptions, config: APIRequestConfig) =>
    API.post.promise('/v2/search', options, config),

  getLocationAutocompletePredictions: (options: PlacePredictionsRequestOptions, config: APIRequestConfig): Promise<PlacePredictionsResponse> =>
    API.get.promise(`/v2/location_autosuggest${buildUrlFragment(options)}`, null, config),
}
