// @flow

import 'url-polyfill'
import pickBy from 'lodash/pickBy'
import type { Cookies } from 'react-cookie'
import * as marketingParamStore from '../../app/helpers/marketingParamStore'
import logger from '../../logging'

const log = logger('marketing-param-parser')

const googleDomainRegexes = [
  // NOTE: We assume Google owns any domain that matches the common TLD patterns
  /^(www\.)?google\.(com\.)?([a-z]{2,3}\.)?[a-z]{2,3}$/,
  /^webcache\.googleusercontent\.com$/,
  /^encrypted\.google\.com$/,
  /^googlesyndicatedsearch\.com$/,
  /^www\.cnn\.com$/,
  /^www\.gooofullsearch\.com$/,
  /^i?search\.avg\.com$/,
  /^(search\.)?darkoogle\.com$/,
  /^search\.foxtab\.com$/,
  /^search\.hiyo\.com$/,
  /^search\.sweetim\.com$/,
  /^search\.juno\.com$/,
  /^searchresults\.verizon\.com$/,
  /^search\.alot\.com$/,
  /^search\d?\.incredi(mail|bar)\.com$/,
  /^www\.googleearth\.de$/,
  /^www\.googleearth\.fr$/,
  /^www\.fastweb\.it$/,
  /^search\.walla\.co\.il$/,
  /^find\.tdc\.dk$/,
]

const isGoogleUrl = (urlString: string): bool => {
  try {
    const url = new URL(urlString)
    log.info(`checking google domain ${url.host}`)
    return googleDomainRegexes.some((regex) => regex.test(url.host))
  } catch (e) {
    log.warn(`an invalid url was sent as the referer: ${urlString}`, { url: urlString })
  }
  return false
}

const processMarketingParams = (cookies: Cookies) => (search: string, referrer: ?string) => {
  const query = new URLSearchParams(search)
  const params = pickBy({
    source: query.get('utm_source') || query.get('mkt_source'),
    medium: query.get('utm_medium') || query.get('mkt_medium'),
    campaign: query.get('utm_campaign') || query.get('mkt_campaign'),
    term: query.get('utm_term') || query.get('mkt_term'),
    content: query.get('utm_content') || query.get('mkt_content'),
  })
  if (Object.keys(params).length) {
    marketingParamStore.save(cookies)(params)
  } else {
    if (referrer && isGoogleUrl(referrer)) {
      marketingParamStore.save(cookies)({
        source: 'search',
        medium: 'google',
      })
    }
  }
}

export default processMarketingParams
