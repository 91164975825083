// @flow

import config from '../config'
import typeof * as BrowserSentry from '@sentry/browser'
import typeof * as NodeSentry from '@sentry/node'
import ServerError from '../app/actions/api/errors/ServerError'

type SentryTags = { [string]: ?string }
type SentryUser = { id: string }

export const configureSentry = (sentry: BrowserSentry | NodeSentry, env: 'client' | 'server', dsn: string) => {
  const isProduction = config.env === 'production'
  sentry.init({
    dsn,
    environment: config.env,
    release: config.sentry.release,
    integrations: isProduction && env === 'client'
      ? [
          new sentry.Integrations.Breadcrumbs({
            console: false,
          }),
        ]
      : [],
    beforeSend: function (event, hint) {
      const error = hint?.originalException

      if (error instanceof ServerError) {
        event.fingerprint = [
          String(error.name),
          String(error.signature),
        ]
      }

      return event
    },
    ignoreErrors: [
      // Email link Microsoft Outlook crawler compatibility error
      // cf. https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062
      'Non-Error promise rejection captured with value: Object Not Found Matching Id:',
      // https://github.com/getsentry/sentry-javascript/issues/10011#issuecomment-2180849453
      "Cannot read properties of undefined (reading 'domInteractive')",
    ],
  })

  const staticTags: SentryTags = {
    env: config.env,
    service: 'jobseeker_portal',
    branch: config.scm.branchName,
    commit: config.scm.commitHash,
    source: env,
  }

  sentry.configureScope((scope) => {
    setSentryTags(scope, staticTags)
  })
}

export const setSentryUser = (sentry: BrowserSentry, user: SentryUser) => {
  sentry.setUser(user)
}

const setSentryTags = (scope, tags: SentryTags) => {
  Object.entries(tags).forEach(([key, value]) => {
    if (typeof value === 'string') {
      scope.setTag(key, value)
    } else {
      scope.setTag(key, '')
    }
  })
}
