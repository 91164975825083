import React from 'react'
import styled from '@emotion/styled'
import {
  // @ts-expect-error - The icon exists but is not in the type definitions.
  SearchLocate20 as JobSearchIcon,
  Box20 as ApplicationsIcon,
  Bookmark20 as SavedJobsIcon,
} from '@carbon/icons-react'
import { DesktopUserProfile } from './DesktopUserProfile'
import { LoggedInDesktopMenuItem } from './LoggedInDesktopMenuItem'
import { LoggedInDesktopMenuProps } from '../HNavBar.types'
import { HBox } from '../../HBox'

const icons: { [key: string]: any } = {
  jobSearch: <JobSearchIcon />,
  applications: <ApplicationsIcon />,
  savedJobs: <SavedJobsIcon />,
}

export const LoggedInDesktopMenuContainer = styled.div`
  display: flex;
  height: 100%;
  gap: 32px;
  align-items: center;
`

export const LoggedInDesktopMenu: React.FC<LoggedInDesktopMenuProps> =
  (props) => {
    const { userProfile } = props
    const { jobSearch, applications, savedJobs } = props.menuItems
    return (
      <HBox
        display='flex'
        height='100%'
        gap={8}
        alignItems='center'
      >
        <LoggedInDesktopMenuItem
          data-test-id='main-header-navigation-jobs-desktop'
          {...jobSearch}
        >
          {icons.jobSearch}
          <div>{jobSearch.text}</div>
        </LoggedInDesktopMenuItem>
        <LoggedInDesktopMenuItem
          data-test-id='main-header-navigation-applications-desktop'
          {...applications}
        >
          {icons.applications}
          <div>{applications.text}</div>
        </LoggedInDesktopMenuItem>
        <LoggedInDesktopMenuItem
          data-test-id='main-header-navigation-bookmarks-desktop'
          itemType='saved_jobs'
          {...savedJobs}
        >
          {icons.savedJobs}
          <div>{savedJobs.text}</div>
        </LoggedInDesktopMenuItem>
        {userProfile && <DesktopUserProfile {...userProfile} />}
      </HBox>
    )
  }
