// @flow

import API from '../clientBuilder'
import { buildUrlFragment } from '../../../helpers/url/query'
import type { APIRequestConfig } from '../types'

export default {
  postFeedback: (data: $FlowTODO, config: APIRequestConfig) =>
    API.post.promise('/v2/talent_platform/job_type_quiz_feedback', data, config),
  getFeedback: (options: $FlowTODO, config: APIRequestConfig) =>
    API.get.promise(`/v2/talent_platform/job_type_quiz_feedback${buildUrlFragment(options)}`, null, config),
}
