import React, { useState } from 'react'

import { MenuItem } from '@mui/material'
import { OverflowMenuHorizontal20 as MenuToggleIcon } from '@carbon/icons-react'
import { HButton, HIconButton } from '../HButton'
import { ApplicationMenuProps } from './HApplicationCard.types'
import { HResponsiveMenu } from './HResponsiveMenu'
import { HBox } from '../HBox'
import { hStyled } from '../theme'

const StyledMenuItem = hStyled(MenuItem)<{$isMobile: boolean}>`
  padding: ${({ $isMobile }) => $isMobile ? '0' : '2px 0'};
  cursor: default;

  :hover {
    background-color: initial;
  }
`

export const ToggleableMenu: React.FC<ApplicationMenuProps> = ({
  onMenuOpen,
  menuItems,
  isMobile,
  testId,
  LinkComponent,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const isOpen = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
    onMenuOpen?.(event)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const dataTestId = `application-card-menu-button-${testId}`

  return (
    <>
      <HIconButton color='primary' variant='outlined' onClick={handleClick} data-test-id={dataTestId}>
        <MenuToggleIcon />
      </HIconButton>
      <HResponsiveMenu
        anchorEl={anchorEl}
        isOpen={isOpen}
        handleClick={handleClick}
        handleClose={handleClose}
        isMobile={isMobile}
        testId={testId}
      >
        {menuItems.map(
          (item) =>
            !item.isHidden && (
              <StyledMenuItem
                $isMobile={isMobile}
                key={item.itemId}
                disableTouchRipple
              >
                <HBox
                  data-test-id={item.itemId}
                  onClick={() => {
                    item.onClick?.()
                    handleClose()
                  }}
                  width={'100%'}
                >
                  {(item.url)
                    ? <LinkComponent style={{ width: '100%' }} to={item.url}>
                      <HButton
                        fullWidth
                        text={item.text}
                        color={item.colorType === 'alert' ? 'destructive' : 'primary'}
                        variant='text'
                        startIcon={item.icon}
                        style={{
                          justifyContent: 'flex-start',
                        }}
                      />
                    </LinkComponent>
                    : (
                      <HButton
                        fullWidth
                        text={item.text}
                        color={item.colorType === 'alert' ? 'destructive' : 'primary'}
                        variant='text'
                        href={item.url}
                        startIcon={item.icon}
                        style={{
                          justifyContent: 'flex-start',
                        }}
                      />
                    )
                  }
                </HBox>
              </StyledMenuItem>
            ),
        )}
      </HResponsiveMenu>
    </>
  )
}
