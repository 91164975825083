// @flow

import * as React from 'react'
import Loadable from 'react-loadable'
import { PageLoader } from '../../components/shared/loader'
import { withClientDataLoader } from '../../components/containers/withDataLoader'
import { first } from '../../helpers/url/query'
import validateSameOriginRedirect from '../../helpers/url/validateSameOriginRedirect.ts'
import * as actionCreators from '../../actions/actionCreators'
import * as userActions from '../../actions/user'
import { fireSnowplowEvent } from '../../tracking/external/trackingClient'
import { CONTACT_INFORMATION_CONFIRMED_EVENT, USER_LOGGED_IN_EVENT } from '../../tracking/eventNames'
import { JOB_ALERT_POP_UP_SOURCE } from '../../tracking/eventSources'
import logger from '../../../logging'
import config from '../../../config'
import { generateRoutes } from '../../routes'
import ApiError from '../../actions/api/errors/ApiError'
import localStorageUtils, { identifiers } from '../../helpers/localStorageUtils'
import { PRIVACY_POLICY_VERSION } from '../Privacy/constants'
import { authenticateCognitoUserByTokens } from '../../actions/user/cognito'
import cognitoUserPoolAPI from '../../actions/user/cognito/userPoolAPI.js'
import type { PrefixedRouteName } from '../../routing/routeNamePrefix'
import { AuthOrigins, getPostSignupDataFromSessionStorage } from '../../helpers/postSignupUtils.ts'
import { limitCognitoCookiesToOneSession } from '../../helpers/cognitoHelper.ts'
import * as Sentry from '@sentry/browser'

const log = logger('sign-in')

const redirecter = (dispatch, state, cookies, replace) => async (redirectLocation: string, emailVerified, showCrmOptionsModal, validAuthToken = true) => {
  /* if the redirect path is an absolute url, then we need to
    * navigate to that url, and pass a query param in order to show the success modal
    * on navigation completion */
  if (redirectLocation.match(/^https?:\/\/.*/)) {
    const redirectURL = new URL(redirectLocation)
    redirectURL.searchParams.set('show_crm_modal', 'true')
    if (emailVerified) {
      redirectURL.searchParams.set('email_verified', 'true')
    }

    window.location.assign(redirectURL.toString())
  } else {
    replace(redirectLocation)
    showCrmOptionsModal && dispatch(actionCreators.showCrmOptionsModal())
  }
}

const handleCognitoOAuthSignIn = async (dispatch, state, routeData, replace, cookies) => {
  const locale = state.intlData.locale
  const country = state.locality.country
  const options = routeData.location.query
  const cognitoCode = first(options.code)
  const cognitoState = first(options.state)
  const routes = generateRoutes(locale)
  const routeName = routeData.route.name

  const authRedirectUrl = `${window.location.origin}${routes.country(country).auth}`
  const redirect = redirecter(dispatch, state, cookies, replace)
  const parsedCognitoState = cognitoState && JSON.parse(atob(cognitoState))
  const redirectUrl: string = parsedCognitoState ? parsedCognitoState.redirect_url : routes.country(country).index
  const authStrategy: string = parsedCognitoState ? parsedCognitoState.auth_strategy : ''
  const codeVerifier = parsedCognitoState ? parsedCognitoState.code_verifier : ''
  /* istanbul ignore next */
  const googleOneTapEmail = parsedCognitoState && parsedCognitoState.google_one_tap_email

  try {
    const tokens = await cognitoUserPoolAPI.token({
      grant_type: 'authorization_code',
      client_id: config.cognito.userPoolWebClientId,
      redirect_uri: authRedirectUrl,
      code_verifier: codeVerifier,
      code: cognitoCode || '',
    })

    const { value: user } = await dispatch(authenticateCognitoUserByTokens(tokens))

    /* istanbul ignore next */
    if (googleOneTapEmail && user?.email !== googleOneTapEmail) {
      Sentry.captureMessage(`[userId: ${user.id} ]: Google One Tap email does not match the user email for userId`)
    }

    limitCognitoCookiesToOneSession(cookies)

    if (user.has_to_handle_signup) {
      await dispatch(userActions.handlePostSignupActions(user, authStrategy)).catch(error => {
        log.error(`Error ${error.message}`, { datadogContext: { error } }, error)
        Sentry.captureException(error)
      })
    } else {
      // TODO: move triggerUserLoggedInEvent to handlePostSigninActionns and remove the event from other places of where user signs in.
      triggerUserLoggedInEvent(routeName, user.id, authStrategy)

      await dispatch(userActions.handlePostSigninActions(user)).catch(error => {
        log.error(`Error ${error.message}`, { datadogContext: { error } }, error)
        Sentry.captureException(error)
      })
    }

    if (authStrategy === 'google_one_tap') {
      dispatch(actionCreators.toggleGoogleOneTapFollowUp())
    }

    redirect(redirectUrl)

    await dispatch(user.consented_terms_and_conditions
      ? actionCreators.showSnackBar('login')
      : actionCreators.showTermsAndConditionsModal())
  } catch (error) {
    log.error(`Error ${error.message}`, { datadogContext: { error } }, error)

    Sentry.captureException(error)
  }
}

const triggerUserLoggedInEvent = (routeName: PrefixedRouteName, userId: string, authStrategy: string) => {
  const authService = authStrategy.split('_')[0]

  const { tracking } = (getPostSignupDataFromSessionStorage() || {})

  fireSnowplowEvent(
    USER_LOGGED_IN_EVENT,
    {
      location: routeName,
      authentication_method: `${authService}_sso`,
    },
    [
      {
        key: 'auth_strategy_context',
        options: {
          user_id: userId,
          google_sso: authService === 'google',
          facebook_sso: authService === 'facebook',
          email_pw: false,
          auth_origin: tracking?.authLocation === AuthOrigins.CAF ? AuthOrigins.CAF : routeName,
        },
      },
    ]
  )
}

const handleUserSignIn = (dispatch, redirect) => async (authToken, redirectLocation, routeData, currentUser) => {
  try {
    const { value: user } = await dispatch(userActions.passwordlessSignIn(authToken))

    if (currentUser && currentUser.email !== user.email) {
      log.dataCapture('Tried to log-in while having an existing session', {
        datadogContext: {
          previousUser: currentUser.id,
          newUser: user.id,
        },
      })
      await dispatch(userActions.logout())

      return handleUserSignIn(dispatch, redirect)(authToken, redirectLocation, routeData, null)
    }

    if (!currentUser) {
      fireSnowplowEvent(
        USER_LOGGED_IN_EVENT,
        {
          location: routeData.route.name,
          authentication_method: 'email',
        }
      )
    }
    return user
  } catch (error) {
    if (error instanceof ApiError) {
      if (error.errors.find((e) => e.code === 'base_device_belongs_to_other_user')) {
        await dispatch(userActions.logout())
        return handleUserSignIn(dispatch, redirect)(authToken, redirectLocation, routeData, currentUser)
      } else {
        await redirect(redirectLocation, false, false, false)
      }
    }
    return null
  }
}

const handleEmailConfirmation = (dispatch) => async (confirmationToken, redirectLocation, currentUser, redirect) => {
  // collect user consent as part of email confirmation process
  try {
    await dispatch(
      actionCreators.createConsent({
        consent_type: 'privacy_policy',
        consent_value: true,
        version: PRIVACY_POLICY_VERSION,
      })
    )
  } catch (error) {
    log.error(`Error ${error.message}`, { error: JSON.stringify(error) })
    Sentry.captureException(error)
  }

  const userHasConsentedJobRecommendations = (currentUser.consented_job_recommendations || currentUser.consented_job_recommendations_whatsapp)

  // if the user has not already been verified, then verify email
  if (!currentUser?.confirmed) {
    try {
      await dispatch(actionCreators.confirmEmail(confirmationToken))
      fireSnowplowEvent(CONTACT_INFORMATION_CONFIRMED_EVENT, { contact_type: 'email' })
      await redirect(redirectLocation, true, !userHasConsentedJobRecommendations)
    } catch (error) {
      dispatch(actionCreators.toggleResendConfirmationModal({
        redirect: redirectLocation,
      }))
    }
  } else {
    // user already verified - redirect and show modal
    await redirect(redirectLocation, false, !userHasConsentedJobRecommendations)
  }
}

const clientDataLoader = async (dispatch, state, routeData, replace, cookies) => {
  let { user: currentUser } = state.user
  const options = routeData.location.query
  const authToken = first(options.sign_in_token)
  const cognitoOAuthCode = first(options.code)
  const userEmail = first(options.email)
  const redirectLocation = validateSameOriginRedirect(first(options.redirect_path)) || '/'
  const confirmationToken = first(options.confirmation_token)
  const jobAlertConsent = localStorageUtils.getItem(identifiers.JOB_ALERT_CONSENT) ?? null
  const searchOptions = localStorageUtils.getItem(identifiers.STORED_SEARCH_OPTIONS) ?? null
  const redirect = redirecter(dispatch, state, cookies, replace)

  if (cognitoOAuthCode) {
    await handleCognitoOAuthSignIn(dispatch, state, routeData, replace, cookies)
    return
  }

  const redirectUrl = new URL(redirectLocation, window.location.origin)

  // We need to manually add the CRM params that do not start with 'utm_' to this array
  const crmParams = ['canvas_id', 'dispatch_id', 'job_recommendation_id']
  const trackingParams = Object.keys(options).filter(key => key.match(/utm_\w+/g) || crmParams.includes(key))

  // forward utm params if any
  for (const param of trackingParams) {
    redirectUrl.searchParams.set(param, options[param].toString())
  }
  const relativeRedirectLink = redirectUrl.pathname + redirectUrl.search

  // if a sign-in token is present, try signing-in
  if (authToken) {
    const user = await handleUserSignIn(dispatch, redirect)(authToken, redirectLocation, routeData, currentUser)
    if (user) {
      currentUser = user
    }
  }

  // confirmation-token only present in email verification sign in links
  if (currentUser && confirmationToken) {
    await handleEmailConfirmation(dispatch)(confirmationToken, redirectLocation, currentUser, redirect)
  }

  if (currentUser && jobAlertConsent) {
    await dispatch(
      actionCreators.createRecommendationConsent(
        {
          consent_type: 'job_alerts_email',
          consent_value: true,
        },
        {
          source: JOB_ALERT_POP_UP_SOURCE,
        }
      )
    )
    localStorageUtils.removeItem(identifiers.JOB_ALERT_CONSENT)
  }

  if (currentUser && searchOptions) {
    await dispatch(actionCreators.createJobAlertSearchQueries(JSON.parse(searchOptions)))
    localStorageUtils.removeItem(identifiers.STORED_SEARCH_OPTIONS)
  }
  if (currentUser) {
    // Logout the user if current user's email is not equal to the email in query param
    // This is the part of magic link removal. See https://heyjobs.atlassian.net/browse/TPRE-551
    if (userEmail && currentUser.email !== userEmail) {
      await dispatch(userActions.logout())
      dispatch(actionCreators.reserveEnteredData(userEmail))
      if (redirectLocation !== '/') {
        dispatch(actionCreators.setRedirectPath(redirectLocation))
      }
    } else {
      redirect(relativeRedirectLink, false, false)

      dispatch(currentUser.consented_terms_and_conditions
        ? actionCreators.showSnackBar('login')
        : actionCreators.showTermsAndConditionsModal())
    }
  }
  if (!currentUser) {
    if (userEmail) {
      dispatch(actionCreators.reserveEnteredData(userEmail))
    }

    if (redirectLocation !== '/') {
      dispatch(actionCreators.setRedirectPath(redirectLocation))
    }
  }
}

const LoadableAuth = Loadable<$FlowTODO, $FlowTODO>({
  loader: () => import('./Auth'),
  loading: () => <PageLoader />,
})

const AuthPage = withClientDataLoader(clientDataLoader)(LoadableAuth)

export default AuthPage
