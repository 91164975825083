// @flow

/**
 * CSS Variables
 * To identify css variables, add ($) prefix to Variables
 * makes it easier to know that the variable is CSS-related
 */

// Grid breakpoints
export const $xxxSmall = 320
export const $xxSmall = 375
export const $xSmall = 576
export const $small = 767
export const $medium = 992
export const $mediumLarge = 1032
export const $large = 1201
export const $xLarge = 1800

export const $defaultPageMaxWidth = '1100px'

export const $careerPageSidebarWidth = 300

export const $maxJobSearchQueryWidth = 1024

// Z-index
export const $searchFooterZIndex = 10
export const $applyButtonZIndex = 101
export const $applyToJobCardZIndex = 102
export const $disallowedCookieBarZIndex = 103
export const $fixedScrollableBarZIndex = 102
export const $searchNavBarZIndex = 999
export const $loginZIndex = 1001
export const $applicationsMenuToggleZIndex = 8900
export const $applicationsMenuOverlayZIndex = 9101
export const $applicationsMenuZIndex = 9102
export const $mainHeaderZIndex = 8989
export const $mainMenuOverlayZIndex = 8999
export const $mainMenuZIndex = 9000
export const $mobileFiltersZIndex = 9001
export const $tooltipZIndex = 9002

export const $overlayZIndex = 10001
export const $searchFieldZIndex = 2
export const $searchFieldIconZIndex = 3
export const $searchFieldCoverupZIndex = 4
export const $CAFStepsContainerZIndexDesktop = 5
export const $CAFStepsContainerZIndexMobile = 1
export const $saveJobSearchBarZIndex = 1

/* Conversational Application Flow */
export const $loadingOverlayZIndex = 1000
export const $navigationSectionZIndex = 2
export const $stepsContainerZIndex = 2

// Colors
export const $secondaryColor = '#00bed8'
export const $accentColor = 'rgb(250, 93, 99)'

export const $fontWeights = {
  bold: 600,
  semibold: 500,
  normal: 400,
  thin: 300,
}
/* eslint-disable quote-props */
export const $palette = {
  alpha: {
    '250': 'rgba(205, 205, 205, 0.25)',
  },
  primary: {
    light: '',
    main: '',
    dark: '',
    text: '',
  },
  secondary: {
    light: '',
    main: '',
    dark: '',
    text: '',
  },
  grey: {
    '0': '#ffffff',
    '100': '#f8f8f8',
    '150': '#E3E3E3',
    '200': '#dadada',
    '250': '#cdcdcd',
    '300': '#9B9B9B',
    '400': '#6f6f6f',
    '425': '#7B7A7A',
    '450': '#5C5C5C',
    '500': '#474747',
    '600': '#1c1c1c',
    '1000': '#000000',
  },
  status: {
    active: '#c2d3ed',
  },
  error: {},
}
/* eslint-enable quote-props */

/* eslint-disable quote-props */
export const $colors = {
  primary: {
    '0': '#1A1A1A',
    '100': '#c84182',
    '150': '#A32C65',
    '200': '#6d1c4d',
    '300': '#52153e',
  },
  accent: {
    '100': '#e6af14',
  },
  secondary: {
    '50': '#3964B7',
    '100': '#3c69be',
    '150': '#2551A1',
    '200': '#26326d',
    '300': '#212956',
  },
  default: {
    '100': '#9397A9',
    '200': '#707485',
    '300': '#4C526B',
    '400': '#313545',
  },
  disabled: '#9b9b9b',
  border: $palette.grey[200],
  primaryText: $palette.grey[600],
  homePageLightGrey: '#bec1c4',
}
/* eslint-enable quote-props */

export const $cardLabelColors = {
  pending: {
    text: '#e6af14',
    background: '#fffaee',
  },
  hired: {
    text: '#28a745',
    background: '#cbffd7',
  },
  rejected: {
    text: '#c84182',
    background: '#ffcbe4',
  },
  recommendedJob: {
    text: '#7041c8',
    background: '#e4cfff',
  },
  lastSeenJob: {
    text: '#17A2B8',
    background: '#c4f7ff',
  },
  disabled: {
    text: '#707485',
    background: '#EDEEF3',
  },
  feedback: {
    text: '#615BBC',
    background: '#F5F4FF',
  },
}

// Fonts
export const $primaryFont = "'IBM Plex Sans', sans-serif"

export const $primaryBoxShadow = '0 1px 3px 0 rgba(63, 63, 68, 0.1)'

export const $primaryBorderRadius = '3px'

// Banner Images
export const $smallBannerImage = 76
export const $bigBannerImage = 188
export const $bigScrollingBannerImage = 132

export const $CardBgColor = '#f0f4fa'

// Sizes
const sizes = [2, 4, 8, 16, 24, 32, 40, 48]

const xs = sizes[0] // 2
const sm = sizes[1] // 4
const base = sizes[2] // 8
const lg = sizes[3] // 16
const xl = sizes[4] // 24
const xxl = sizes[5] // 32
const xxxl = sizes[6] // 40
const xxxxl = sizes[7] // 48

export const $sizes = {
  numbers: {
    xs,
    sm,
    base,
    lg,
    xl,
    xxl,
    xxxl,
    xxxxl,
  },
  xs: `${xs}px`,
  sm: `${sm}px`,
  base: `${base}px`,
  lg: `${lg}px`,
  xl: `${xl}px`,
  xxl: `${xxl}px`,
  xxxl: `${xxxl}px`,
  xxxxl: `${xxxxl}px`,
}

export const $mainHeaderHeight = '68px'
export const $mainHeaderHeightXS = '64px'

export const $screeningQuestionActiveColor = 'rgba(244, 249, 254, 255)'
