/* istanbul ignore file */
// @flow

import { arrayToQuery } from '../../../helpers/url/arrayToQuery'
import { buildUrlFragment } from '../../../helpers/url/query'
import API from '../clientBuilder'
import type {
  APIRequestConfig, Language, LocationData,
} from '../types'
import type { Locale, Country } from '../../../types/common'
import { getSnowplowDomainUid } from '../../../tracking/external/trackingClient'
import localStorageUtils from '../../../helpers/localStorageUtils'
import { type ProfileCompletionStatus } from 'user-profile-data'

export type UserType = 'jobseeker' | 'recruiter'
export type User = {
  address: any,
  allow_get_headhunted: ?bool,
  birthday?: string,
  confirmed: bool,
  consented_job_alerts_email: bool,
  consented_job_recommendations: bool,
  consented_job_recommendations_whatsapp: bool,
  consented_profile_sharing: boolean,
  consented_terms_and_conditions: boolean,
  created_at: string,
  email: string,
  experience: any[],
  experiences: any[],
  first_name: string,
  gender?: string,
  has_password: boolean,
  has_seen_onboarding: boolean,
  has_submitted_applications: bool,
  has_to_handle_signup?: boolean,
  id: string,
  languages: any[],
  last_name: string,
  locale: Locale,
  location_data?: { address: string },
  phone_number?: string,
  picture_url?: string,
  profile_completion_status: ProfileCompletionStatus,
  show_profile_sharing_in_caf: boolean,
  signup_source?: string,
  user_type: UserType
}

export type CreateUserParams = {
  email: string,
  first_name: string,
  last_name: string,
  phone_number: string,
  unregistered_jobseeker_uid?: string,
  version: string
}

export type UpdateUserParams = {|
  address?: string,
  allow_get_headhunted?: bool,
  birthday?: string,
  first_name?: string,
  gender?: string,
  has_password?: bool,
  languages?: Array<Language>,
  last_name?: string,
  location_data?: LocationData,
  phone_number?: string,
  picture?: $FlowTODO, // FILE
  remove_picture?: bool
|}

export type UserExistence = {
  has_facebook_account: boolean,
  has_google_account: boolean,
  has_password: boolean,
  user_exists: boolean
}

export type UnregisteredJobseeker = {
  country_code: Country,
  distance_in_miles?: number,
  job_uid?: string,
  locale: Locale,
  location?: string,
  query?: string,
  source: 'job' | 'search',
  version?: string
}

export default {
  current: (config: APIRequestConfig): Promise<User> => API.get.promise('/v2/users/current', null, config),

  update: (id: string, data: UpdateUserParams, config: APIRequestConfig): Promise<User> => {
    return API.put.promise(`/v2/users/${id}`, data, config)
  },

  passwordlessSignUp: (data: CreateUserParams, config: APIRequestConfig): Promise<User> => {
    const query = buildUrlFragment({ domain_user_id: getSnowplowDomainUid() })
    const unregisteredJobseekerId = localStorageUtils.getItem(localStorageUtils.identifiers.UNREGISTERED_JOBSEEKER_ID)

    if (unregisteredJobseekerId) { data.unregistered_jobseeker_uid = unregisteredJobseekerId }

    return API.post.promise(`/v2/users/passwordless_sign_up${query}`, data, config)
  },

  checkUserExistenceAndSocialAccounts: (email: string, config: APIRequestConfig): Promise<UserExistence> => {
    const query = buildUrlFragment({ email: email })
    return API.get.promise(`/v2/users/check_user_existence_and_social_accounts${query}`, null, config)
  },

  passwordlessSignIn: (signInToken: string, config: APIRequestConfig): Promise<User> => {
    localStorageUtils.removeItem(localStorageUtils.identifiers.UNREGISTERED_JOBSEEKER_ID)

    return API.post.promise('/v2/users/token_sign_in', { sign_in_token: signInToken }, config)
  },

  logout: (config: APIRequestConfig) => API.delete.promise('/v1/logout', null, config),

  requestSignInEmail: (data: $FlowTODO, config: APIRequestConfig) => {
    const query = buildUrlFragment({ domain_user_id: getSnowplowDomainUid() })

    return API.post.promise(`/v2/users/request_sign_in_email${query}`, data, config)
  },

  updatePhoto: (userId: string, formData: FormData, config: APIRequestConfig) =>
    API.put.promise(`/v2/users/${userId}`, formData, config),

  getExperience: (userId: string, config: APIRequestConfig) =>
    API.get.promise(`/v2/users/${userId}/experience`, null, config),

  updateExperience: (userId: string, data: $FlowTODO, config: APIRequestConfig) =>
    API.put.promise(`/v2/users/${userId}/experience`, data, config),

  createConsent: (
    options: { company_id?: string, consent_type: string, consent_value: bool, version: string },
    config: APIRequestConfig
  ) =>
    API.post.promise('/v2/users/consents', options, config),

  confirmEmail: (options: { confirmation_token: string }, config: APIRequestConfig) =>
    API.put.promise('/v2/users/confirm_registration_email', options, config),

  confirmEmailUnauthorized: (options: { token: string }, config: APIRequestConfig) =>
    API.put.promise('/v2/users/confirm_email', options, config),

  resendVerificationEmail: (data: { email: string }, config: APIRequestConfig) =>
    API.post.promise('/v2/users/send_new_confirmation_token', data, config),

  saveVisitedJob: (jobUID: string, config: APIRequestConfig) => {
    return API.post.promise('/v2/talent_platform/viewed_jobs', { job_uid: jobUID }, config)
  },

  bookmarkJob: (parameters: $FlowTODO, config: APIRequestConfig) =>
    API.post.promise('/v2/users/current/job_bookmarks', parameters, config),

  unBookmarkJob: (id: string, config: APIRequestConfig) =>
    API.delete.promise(`/v2/users/current/job_bookmarks/${id}`, null, config),

  getMyBookmarks: (options: $FlowTODO, config: APIRequestConfig) => {
    const query = buildUrlFragment(options)
    return API.get.promise(`/v2/users/current/job_bookmarks${query}`, null, config)
  },

  checkBookmarkJobs: (ids: string[], config: APIRequestConfig) => {
    const query = arrayToQuery({ job_uids: ids })
    return API.get.promise(`/v2/users/current/job_bookmarks/statuses?${query}`, null, config)
  },

  getUserStatusByLoginToken: (loginToken: string) => {
    const query = buildUrlFragment({ token: loginToken })
    return API.get.promise(`/v2/users/check_user_confirmed${query}`)
  },

  getUserConsentStatusByEmailHash: (hashedEmail: string) => {
    const query = buildUrlFragment({ email: hashedEmail })
    return API.get.promise(
      `/v2/users/check_consent_status${query}`
    )
  },

  unsubscribeEmail: (hashedEmail: string, options: $FlowTODO) =>
    API.post.promise('/v2/users/unsubscribe', {
      ...options,
      email: hashedEmail,
    }),

  subscribeEmail: (hashedEmail: string, options: $FlowTODO) =>
    API.post.promise('/v2/users/subscribe', {
      ...options,
      email: hashedEmail,
    }),

  confirmPhoneNumber: (token: string) =>
    API.put.promise('/v2/users/confirm_phone_number', { token }),

  sendLinkOutJobEmail: (jobUid: string, config: APIRequestConfig) =>
    API.post.promise('/v2/users/send_link_out_job_email', { job_uid: jobUid }, config),

  verifyGoogleSignInToken: (options: { client_id: string, credential: string, version: string }, config: APIRequestConfig) =>
    API.post.promise('/v2/users/verify_google_sign_in', options, config),

  migrateCognito: (email: string, config: APIRequestConfig) =>
    API.post.promise('/v2/users/migrate', { email }, config),

  createUnregisteredJobseeker: (params: UnregisteredJobseeker, config: APIRequestConfig) =>
    API.post.promise('/v2/talent_platform/unregistered_jobseekers', params, config),

  deleteProfile: (reason: string, config: APIRequestConfig) =>
    API.post.promise(`/v2/users/anonymize?reason=${reason}`, null, config),
}
