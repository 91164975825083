// @flow

type RelType = 'canonical' | 'og:url'

type CanonicalTagType = {
  href: string,
  rel: RelType
}

export const buildCanonicalUrl = (
  baseUrl: string,
  path: string
): string => {
  return `${baseUrl}${path}`
}

const canonicalTags = (
  baseUrl: string,
  path: string
): Array<CanonicalTagType> => {
  const target = buildCanonicalUrl(baseUrl, path)
  return [
    {
      rel: 'canonical',
      href: target,
    },
  ]
}

export default canonicalTags
