// @flow

import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'
import config from '../../../config'
import isPlainObject from 'lodash/isPlainObject'
import ServerError from './errors/ServerError'
import ApiError from './errors/ApiError'
import { from, type Observable } from 'rxjs'
import { fromFetch } from 'rxjs/fetch'
import { concatMap, mergeMap, tap } from 'rxjs/operators'
import logger from '../../../logging'

import type { APIRequestConfig } from './types'
import type { APIHTTPHeaders } from '../requestHeaders'
import { getFreshCognitoIdToken } from '../user/cognito'

type HTTPMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'

type APIRequestBody = ({ [key: string]: any } | BodyInit)

type RequestPayload = {
  body: ?BodyInit,
  type?: string
}

type APIClientConfiguration = {
  body: ?APIRequestBody,
  method: HTTPMethod,
  path: string,
  requestConfig: ?APIRequestConfig
}

const log = logger('api-client')

const handleResponse = async (request: Request, response: Response) => {
  if (response.status >= 200 && response.status < 300) {
    const contentType = response.headers.get('content-type')
    if (contentType && contentType.indexOf('application/json') > -1) {
      return response.json()
    } else {
      return response.text()
    }
  }
  throw await buildError(request, response)
}

const buildError = async (request: Request, response: Response) => {
  const text = await response.text()
  try {
    const json = JSON.parse(text)
    const { errors } = json
    return new ApiError(errors, request, response, text)
  } catch (e) {
    return new ServerError(request, response, text)
  }
}

export const request = (url: URL, options: RequestOptions) => {
  return fromFetch(url, options).pipe(
    // $FlowFixMe
    mergeMap(res => from(handleResponse({ url: url.href, ...options }, res))),
    tap(undefined, (error) => {
      log.error(error.toString(), error?.props)
    })
  )
}

const buildRequestPayload = (body: ?APIRequestBody): RequestPayload => {
  const isBodyJson = isPlainObject(body)
  if (isBodyJson) {
    return {
      body: JSON.stringify(body),
      type: 'application/json',
    }
  } else {
    return {
      body: (body: any),
    }
  }
}

export const buildRequestHeaders = (headers: ?APIHTTPHeaders, payload: RequestPayload) => {
  const result: { [string]: string } = { ...headers }
  if (payload.type) {
    result['Content-Type'] = payload.type
  }
  return result
}

// TODO: Should refactor so state is mapped in more easily
function apiRequest (options: APIClientConfiguration) {
  const {
    method,
    path,
    body,
    requestConfig,
  } = options

  const url = new URL(config.backend.apiUrl + path)

  const payload = buildRequestPayload(body)

  const params: RequestOptions = {
    method,
    body: payload.body === null ? undefined : payload.body,
    headers: buildRequestHeaders(requestConfig?.headers, payload),
    credentials: 'omit',
    follow: 'manual', // we don't expect any redirects from our APIs
  }

  return request(url, params)
}

export const setAndRefreshAuthorizationHeader = async (requestConfig: ?APIRequestConfig): Promise<?APIRequestConfig> => {
  if (!requestConfig?.headers) return

  if ('X-Auth-Token' in requestConfig.headers) {
    return requestConfig
  }

  const idToken = await getFreshCognitoIdToken()

  if (!idToken) {
    requestConfig.headers['X-Auth-Token'] = requestConfig.headers['Device-Token']
    delete requestConfig.headers['Device-Token']

    return requestConfig
  }

  requestConfig.headers.Authorization = `Bearer ${idToken}`

  return requestConfig
}

const optionsWithFreshAuthorizationHeader = async (options: APIClientConfiguration) => {
  options.requestConfig = await setAndRefreshAuthorizationHeader(options.requestConfig)

  return options
}

function createAPIClients (method: HTTPMethod) {
  const promise = async (path: string, body: ?APIRequestBody, requestConfig: ?APIRequestConfig) => {
    await setAndRefreshAuthorizationHeader(requestConfig)

    return apiRequest({
      method,
      path,
      body,
      requestConfig,
    }).toPromise()
  }

  const rx = (path: string, body: ?APIRequestBody, requestConfig: ?APIRequestConfig): Observable<any> => {
    const promiseObservble = from(optionsWithFreshAuthorizationHeader({
      method,
      path,
      body,
      requestConfig,
    }))

    return promiseObservble.pipe(concatMap(apiRequest))
  }

  return { promise, rx }
}

const clientBuilder = {
  get: createAPIClients('GET'),
  post: createAPIClients('POST'),
  put: createAPIClients('PUT'),
  delete: createAPIClients('DELETE'),
}

export default clientBuilder
