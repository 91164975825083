import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
import React from 'react'

export type HSvgIconProps = SvgIconProps

export const HSvgIcon: React.FC<HSvgIconProps> = ({ children, ...rest }) => {
  return (
    <SvgIcon
      style={{
        maxWidth: rest.width,
        maxHeight: rest.height,
        width: '100%',
        height: '100%',
      }}
      {...rest}
    >
      {children}
    </SvgIcon>
  )
}
