
export type ProfileCompletionStatusValue = 'completed' | 'missing'

/* eslint-disable camelcase */
export interface UserProfileCompletionStatus {
  cv: ProfileCompletionStatusValue
  employment_type: ProfileCompletionStatusValue
  full_name: ProfileCompletionStatusValue
  job_title: ProfileCompletionStatusValue
  location: ProfileCompletionStatusValue
  minimum_salary: ProfileCompletionStatusValue
  percent: number
  phone_number: ProfileCompletionStatusValue
  preferred_location: ProfileCompletionStatusValue
  working_hours_type: ProfileCompletionStatusValue
}

export type DataPoints = keyof Omit<UserProfileCompletionStatus, 'percent'>

export const jobSearchPreferences: Array<DataPoints> = ['job_title', 'working_hours_type', 'employment_type', 'minimum_salary', 'preferred_location']

export type ProfileTabName = 'personal-information' | 'notification-settings' | 'job-search-preferences' | 'account-settings'
const allMenuPositions = ['top_menu_bar', 'top_menu_open_profile_link'] as const
export type ValidMenuIndicatorPositions = typeof allMenuPositions[number]
export type ProfileCategories = { [key in ProfileTabName]: DataPoints[] }

export type IndicatorKeys = DataPoints | ProfileTabName | ValidMenuIndicatorPositions
