// @flow

import * as React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { ReactComponent as UploadIcon } from 'assets/images/application/document-upload-icon.svg'
import { HTypography, HButton } from 'talent-ui'

type UploadButtonProps = {|
  buttonText: string,
  disabled?: bool,
  id: string,
  primary?: bool,
  uploadCv: (files: FileList) => Promise<void>
|}

const UploadButtonInput = styled.input`
  display: none;
`

const UploadDocumentButton = (props: UploadButtonProps) => {
  const inputEl = React.useRef<HTMLInputElement | null>(null)

  const onClick = () => {
    if (inputEl.current !== null) {
      inputEl.current.click()
    }
  }

  const onChange = async (event: SyntheticInputEvent<HTMLInputElement>) => {
    await props.uploadCv(event.target.files)
  }

  const clearInputValueOnClick = (e) => (e.target.value = '')

  return (
    <HButton
      data-test-id={`upload-button-${props.id}`}
      variant='outlined'
      color='primary'
      startIcon={<UploadIcon />}
      onClick={onClick}
    >
      <HTypography
        weight='medium'
        color='primary'
      >
        <FormattedMessage id={props.buttonText} />
      </HTypography>
      <UploadButtonInput
        data-test-id={`upload-document-input-${props.id}`}
        ref={inputEl}
        type='file'
        onChange={onChange}
        onClick={clearInputValueOnClick}
      />
    </HButton>
  )
}

export default UploadDocumentButton
