// @flow
import type { Cookies } from 'react-cookie'

import type { TrackingSchema } from './types'
import type { ApplicationState } from '../../types/applicationState'

type Options = {
  suggested_job_new_flag?: bool,
  suggested_job_position?: string | number,
  suggested_job_uid?: string,
}

export type JobSuggestionType = {
  key: 'job_suggestion_context',
  options?: Options
}

export const SCHEMA_NAME = 'iglu:de.heyjobs/job_suggestion_context/jsonschema/1-0-0'

export default (state: ApplicationState, cookies: Cookies, options?: Options): Array<TrackingSchema> => [{
  schema: SCHEMA_NAME,
  data: {
    suggested_job_uid: options?.suggested_job_uid,
    position: options?.suggested_job_position,
    new_flag: options?.suggested_job_new_flag,
  },
}]
