// @flow

import * as utils from '../utils/reduxUtils'
import * as actions from '../../constants/actions'

export const setABTestsInitialized = utils.create(
  actions.SET_AB_TESTS_INITIALIZED, 'abTestsState'
)

export const setAppInitialized = utils.create(
  actions.SET_APP_INITIALIZED, 'initState'
)
