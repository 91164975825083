import { styled } from '@mui/material/styles'
import { COLORS, globalStyles } from '../theme'

const PREFIX = 'HRadioButton'

export const classes = {
  radio_button: `${PREFIX}-radio_button`,
  radio_button_with_label: `${PREFIX}-radio_button_with_label`,
  radio_button_text_container: `${PREFIX}-radio_button_text_container`,
  radio_button_text_container_with_label:
    `${PREFIX}-radio_button_text_container_with_label`,
  radio_button_text_container_error:
    `${PREFIX}-radio_button_text_container_error`,
  radio_button_text_container_disabled:
    `${PREFIX}-radio_button_text_container_disabled`,
  radio_button_label: `${PREFIX}-radio_button_label`,
  radio_button_caption: `${PREFIX}-radio_button_caption`,
  radio_button_error_caption: `${PREFIX}-radio_button_error_caption`,
  label_with_caption: `${PREFIX}-label_with_caption`,
}

export const HRadioButtonContainer = styled('div')<{ disabled?: boolean }>(({ disabled }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  width: 'fit-content',
  cursor: disabled ? 'not-allowed !important' : 'pointer',
  opacity: disabled ? 0.4 : 1,
  '&:focus': {
    outline: `1px solid ${COLORS.primary_25}`,
  },
  [`& .${classes.radio_button}`]: {
    height: 16,
    position: 'relative',
    display: 'inline-block',
    opacity: 0,
    '&:checked + div:first-of-type:before': {
      content: "''",
      backgroundImage: 'url("data:image/svg+xml,%3Csvg width=\'8\' height=\'8\' viewBox=\'0 0 8 8\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath fill-rule=\'evenodd\' clip-rule=\'evenodd\' d=\'M-0.000366211 4C-0.000366211 1.79086 1.79049 0 3.99963 0C6.20877 0 7.99963 1.79086 7.99963 4C7.99963 6.20914 6.20877 8 3.99963 8C1.79049 8 -0.000366211 6.20914 -0.000366211 4Z\' fill=\'%233C69BE\'/%3E%3C/svg%3E%0A")',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      border: `1px solid ${COLORS.primary_50} !important`,
      boxShadow: 'none',
    },
  },
  [`& .${classes.radio_button_with_label}`]: {
    marginRight: 12,
  },
  [`& .${classes.radio_button_text_container}`]: {
    position: 'relative',
    '&:before, &:hover::before': {
      content: "''",
      height: 14,
      width: 14,
      position: 'absolute',
      top: 0,
      left: -16,
      border: `1px solid ${COLORS.surface_gray_60}`,
      borderRadius: '50%',
      boxShadow: globalStyles.shadow1.boxShadow,
    },
    '&:after': {
      content: "''",
      height: 16,
      width: 16,
      position: 'absolute',
      top: 0,
      left: -16,
      borderRadius: '50%',
    },
    '&:hover::before': {
      border: `1px solid ${COLORS.primary_50} !important`,
      boxShadow: globalStyles.shadow2.boxShadow,
    },
    '&:hover::after': {
      boxShadow: `0 0 0 6px ${COLORS.primary_05}`,
    },
  },
  [`& .${classes.radio_button_text_container_with_label}`]: {
    '&:before, &:hover::before, &:hover::after': {
      left: -28,
    },
  },
  [`& .${classes.radio_button_text_container_error}`]: {
    '&:before, &:hover::before': {
      border: `1px solid ${COLORS.support_error_50} !important`,
    },
    '&:hover::after': {
      boxShadow: `0 0 0 6px ${COLORS.support_error_05}`,
    },
  },
  [`& .${classes.radio_button_text_container_disabled}`]: {
    '&:before': {
      backgroundColor: COLORS.surface_gray_05,
    },
    '&:hover::before, &:hover::after': {
      boxShadow: 'none !important',
    },
  },
  [`& .${classes.radio_button_label}`]: {
    textShadow: '0 1px 2px rgba(255, 255, 255, 0.16)',
  },
  [`& .${classes.radio_button_caption}`]: {
    color: `${COLORS.surface_gray_60} !important`,
  },
  [`& .${classes.radio_button_error_caption}`]: {
    color: `${COLORS.support_error_50} !important`,
  },
  [`& .${classes.label_with_caption}`]: {
    marginBottom: 6,
  },
}))
