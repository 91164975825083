import React from 'react'
import { JobCardTagProps } from './HJobCard.types'
import { JobCardTag } from './JobCardTag'
import { Money20, Lightning20, Portfolio20, Moon20, Sun20, Education20, Hourglass20, Time20 } from '@carbon/icons-react'
import { HBox } from '../HBox'
import { CareerChangeIcon } from '../HAssets/CareerChange'
import { DayAndNightIcon } from '../HAssets/DayAndNight'
import styled from '@emotion/styled'

const MAX_TAGS = 5

const StyledContainer = styled(HBox)`
  gap: 8px 16px;
`

export const JobCardTagList: React.FC<JobCardTagProps> = (props) => {
  const { tags, allowWrapping } = props

  const tagElements = [
    tags.salary?.text && (
      <JobCardTag
        Icon={Money20}
        text={tags.salary.text}
        data-test-id='salary-tag'
        key='salary-tag'
        highlight={tags.salary.highlight}
      />
    ),
    tags.employmentTypes?.length && (
      <JobCardTag
        Icon={Time20}
        text={tags.employmentTypes?.map(m => m.text).join(' / ')}
        data-test-id='working-hour-tag'
        key='working-hour-tag'
        highlight={tags.employmentTypes.some(m => m.highlight)}
      />
    ),
    tags.easyApply?.text && (
      <JobCardTag
        Icon={Lightning20}
        text={tags.easyApply.text}
        data-test-id='easy-apply-tag'
        key='easy-apply-tag'
      />
    ),
    tags.requiredExperience?.text && (
      <JobCardTag
        Icon={Portfolio20}
        text={tags.requiredExperience.text}
        data-test-id='required-experience-tag'
        key='required-experience-tag'
      />
    ),
    tags.openForCareerChangers?.text && (
      <JobCardTag
        Icon={CareerChangeIcon}
        text={tags.openForCareerChangers.text}
        data-test-id='open-for-career-changers'
        key='open-for-career-changers'
      />
    ),
    tags.shift && typeof tags.shift !== 'string' && (
      <JobCardTag
        Icon={tags.shift.type === 'both' ? DayAndNightIcon : tags.shift.type === 'day' ? Sun20 : Moon20}
        text={tags.shift.text}
        data-test-id='shift-tag'
        key='shift-tag'
      />
    ),
    tags.requiredEducation?.text && (
      <JobCardTag
        Icon={Education20}
        text={tags.requiredEducation.text}
        data-test-id='required-education-tag'
        key='required-education-tag'
      />
    ),
    tags.scheduleType?.text && (
      <JobCardTag
        Icon={Hourglass20}
        text={tags.scheduleType.text}
        data-test-id='schedule-type-tag'
        key='schedule-type-tag'
      />
    ),
  ].filter(Boolean) // Filter out any falsey values (e.g., null, undefined)

  return (
    <StyledContainer
      display='flex'
      flexWrap="wrap"
      flexDirection='column'
      md={{ flexDirection: !allowWrapping ? 'row' : 'inherit' }}
      data-test-id='job-card-experimental-tags'
      mt={4}
    >
      {tagElements.slice(0, MAX_TAGS)}
    </StyledContainer>
  )
}
