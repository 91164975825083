import React, { useEffect, useRef } from 'react'
import { HSnackbar } from 'talent-ui'

interface NegativeFeedbackToasterProps {
  open: boolean
  onCloseClick: () => void
  onOpen: () => void,
  title: string | JSX.Element,
  text: string | JSX.Element,
  isProfileComplete: boolean,
  actionBtnProps?: {
    text: string | undefined
    onClick: () => void
  }
}

export const NegativeFeedbackToaster = ({ open, onCloseClick, onOpen, text, isProfileComplete, title, actionBtnProps }: NegativeFeedbackToasterProps) => {
  const prevOpenRef = useRef(false)

  useEffect(() => {
    if (open && prevOpenRef.current !== open) {
      onOpen()
    }

    prevOpenRef.current = open
  }, [open, onOpen])

  return open
    ? (
      <HSnackbar
        caption={title}
        text={text}
        color={isProfileComplete ? 'success' : 'default'}
        onCloseClick={onCloseClick}
        data-test-id='negative-feedback-toaster'
        actionBottom={actionBtnProps?.text}
        actionBottomProps={{
          variant: 'text',
          onClick: actionBtnProps?.onClick,
        }}
      />
      )
    : null
}
