import React from 'react'
import Loadable from 'react-loadable'
import compose from 'lodash/flowRight'
import { PageLoader, redirectIfUnauthenticated } from '../dependencies'

const loadableProfile = Loadable<{}, {}>({
  loader: () => import('./TabContainer'),
  loading: () => <PageLoader />,
})

const ProfileTab = compose(
  redirectIfUnauthenticated
)(loadableProfile)

export default ProfileTab
