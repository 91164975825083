import { HBox } from 'talent-ui'
import React, { PropsWithChildren } from 'react'

export const Container: React.FC<PropsWithChildren> = ({ children }) => (
  <HBox
    display='flex'
    flexDirection='column'
    xs={{
      flex: '1 1 auto',
      overflow: 'auto',
      maxWidth: '100%'
    }}
    lg={{
      flex: 'none',
      maxWidth: 740,
      width: '100%',
      margin: '0px auto'
    }}
  >
    {children}
  </HBox>
)
