import React from 'react'
import {
  ListItem,
  UrlWrapper,
  AlphaList,
  UpperAlphaList,
} from './styles'

type TermsAndConditionsContentProps = {
  text?: string,
  component: JSX.Element
}

export const mainContentDE: TermsAndConditionsContentProps[] = [
  {
    text: '§ 1 Geltungsbereich, Begriffsbestimmungen',
    component:
  <>
    <ListItem>
      Diese Allgemeinen Geschäftsbedingungen für Jobsuchende (“AGB”) regeln das Vertragsverhältnis zwischen der HeyJobs GmbH, Paul-Lincke-Ufer 39/40, 10999 Berlin, einer Gesellschaft mit beschränkter Haftung nach deutschem Recht, eingetragen im Handelsregister des Amtsgerichts Berlin-Charlottenburg unter Registernummer HRB 175212 („HeyJobs“) und sämtlichen Nutzern (“Nutzer”) bezüglich der Nutzung aller auf der Internetplattform
      {' '}
      <UrlWrapper
        text='www.heyjobs.co'
        href='https://www.heyjobs.co'
      />
      , deren Unterseiten, sowie den in zusammengehörigen mobilen Anwendungen (im Folgenden zusammen „Plattform“) angebotenen Diensten. Die AGB gelten ausschließlich für Nutzungen durch Nutzer, die Verbraucher sind. Für die Aufgabe von Stellenangeboten und andere Nutzungen durch Unternehmer kommen diese AGB nicht zur Anwendung.
    </ListItem>
    <ListItem>
      Nutzer müssen 16 Jahre oder älter sein, um die Plattform zu nutzen.
    </ListItem>
  </>,
  },
  {
    text: '§ 2 Art der Dienstleistungen',
    component:
  <>
    <ListItem>
      Nutzer können auf der Plattform kostenfrei und ohne Registrierung Stellenanzeigen, Beschäftigungsmöglichkeiten und andere ähnliche Informationen für die individuelle Arbeitssuche einsehen, die ihrerseits auf kostenpflichtige Angebote Dritter hinweisen können. Hierzu gehören auch Links zu Webseiten Dritter sowie die Bereitstellung der Plattform im Rahmen des Bewerbungsprozesses.
    </ListItem>
    <ListItem>
      Nutzer können sich auf der Plattform ein Nutzerkonto anlegen und haben dann nach ihrer Registrierung Zugriff auf weitere folgende zusätzliche Funktionen:
      <AlphaList>
        <ListItem>
          Führung eines Nutzerkontos: Im Nutzerkonto können Nutzer ihr Profil mit für Bewerbungen relevanten Informationen anlegen;
        </ListItem>
        <ListItem>
          Einreichen von Bewerbungen: Nutzer können sich auf bei oder durch HeyJobs veröffentlichte Stellenanzeigen bewerben und dazu bereits im Nutzerkonto gespeicherte Informationen zu ihrer Person nutzen. Abhängig vom Sitz des anzeigenaufgebenden Unternehmens wird die Bewerbung des Nutzers in dessen Auftrag an das anzeigenaufgebende Unternehmen durch HeyJobs oder durch ein mit HeyJobs verbundenes Unternehmen übermittelt. Gegebenenfalls werden Bewerbungen direkt in die Bewerbermanagementsysteme weitergeleitet, die die anzeigenaufgebenden Unternehmen zur Verwaltung von Bewerbungen nutzen;
        </ListItem>
        <ListItem>
          Pflege des Nutzerkontos und Verbesserung des Nutzererlebnis: Informationen zur Person, die ein Nutzer für eine Bewerbung an das anzeigenaufgebende Unternehmen übermittelt oder unabhängig davon in das eigene Nutzerkonto hochlädt, können durch HeyJobs in das Nutzerkonto übernommen werden, um dieses aktuell und aussagekräftig zu halten. HeyJobs kann die Interaktionen der Nutzer mit der Plattform und die durch den Nutzer im Nutzerkonto hinterlegten Informationen analysieren, um die Nutzung der Plattform effektiver zu gestalten;
        </ListItem>
        <ListItem>
          Übersicht der eingereichten Bewerbungen: Im Nutzerkonto kann der Nutzer abgegebene Bewerbungen und ihren Status sehen. HeyJobs kann den Zeitraum, aus dem in der Vergangenheit Bewerbungen abgegeben worden sind, zeitlich beschränken;
        </ListItem>
        <ListItem>
          Erhalt von Jobvorschlägen: HeyJobs kann, ist aber nicht verpflichtet dazu, dem Nutzer für ihn interessante Stellenanzeigen vorschlagen;
        </ListItem>
        <ListItem>
          Job-Matching: HeyJobs kann, ist aber nicht verpflichtet dazu, Unternehmen, die ihrerseits als Arbeitgeber auf der Plattform von HeyJobs registriert sind, passende Profile von registrierten Nutzern vorzuschlagen und diesen Unternehmen erlauben, sich bei den Nutzern als Arbeitgeber zu bewerben; und
        </ListItem>
        <ListItem>
          Direkte Kommunikation mit Unternehmen: Nutzer können mit dem jeweils anzeigenaufgebenden Unternehmen elektronisch kommunizieren. Dies bedeutet, Nutzer können teilweise vor und nach Abgabe einer Bewerbung Kontakt mit dem anzeigenaufgebenden Unternehmen aufnehmen, um Rückfragen zu stellen und Unternehmen können mit Nutzern nach Einreichen einer Bewerbung kommunizieren. Jede Interaktion zwischen den Nutzern und Unternehmen liegt in der alleinigen Verantwortung der Parteien und keine der Parteien kann HeyJobs für Verbindlichkeiten, die sich aus diesen Interaktionen ergeben, in Anspruch nehmen oder in Betracht ziehen. HeyJobs übernimmt keine Haftung für die Interaktion der Parteien.
        </ListItem>
      </AlphaList>
    </ListItem>
    <ListItem>
      Ein weiteres Kernelement der von HeyJobs angebotenen Dienstleistung ist das Vorschlagen von Stellenanzeigen, die sich auf Grundlage der HeyJobs bekannten Informationen an der jeweiligen Arbeits- und Lebenssituation der Nutzer ausrichten und den Nutzern somit einen möglichst großen Mehrwert bieten. Den Nutzern ist bewusst und sie sind damit einverstanden, diese Stellenanzeigen im Rahmen ihrer üblichen Verwendung von Internetanwendungen wie beispielsweise Social-Media-Netzwerken, die beispielsweise von Meta, (Facebook und Instagram), Google, oder TikTok betrieben werden, in der jeweiligen Internetanwendung angezeigt zu bekommen, ohne dass sie aktiv die HeyJobs Plattform besuchen. Um dies zu ermöglichen, ist es erforderlich, dass HeyJobs bestimmte personenbezogene Daten mit Anbietern von Internetanwendungen teilt. Mehr Informationen hierzu finden sich in der HeyJobs
      {' '}
      <UrlWrapper
        text='Datenschutzerklärung'
        href='https://www.heyjobs.co/recruiting/datenschutzerklaerung/'
      />.
    </ListItem>
  </>,
  },
  {
    text: '§ 3 Registrierung und Nutzerprofil',
    component:
  <>
    <ListItem>
      Eine Registrierung kann nach Maßgabe von HeyJobs auf einem oder mehreren der folgenden Wege erfolgen:
      <AlphaList>
        <ListItem>
          Der Nutzer gibt eine gültige E-Mail-Adresse und ein Passwort an. Das Setzen eines Passwort kann nach Maßgabe von HeyJobs entweder bei der Registrierung oder nach Abruf des Aktivierungs-Links, den HeyJobs dem Nutzer per E-Mail nach Durchführung der Registrierung zusendet, erforderlich sein. In diesem Fall erfolgen weitere Logins nach erfolgreicher Registrierung über die Eingabe der E-Mail-Adresse und des Passworts;
        </ListItem>
        <ListItem>
          Der Nutzer gibt eine gültige E-Mail-Adresse an. In diesem Fall wird die Registrierung nach Abruf des Aktivierungs-Links, den HeyJobs dem Nutzer per E-Mail nach Durchführung der Registrierung zusendet, durchgeführt. Weitere Logins erfolgen in diesem Fall durch Angabe der E-Mail-Adresse und dem Abruf eines “Magic Links”, den HeyJobs dem Nutzer per E-Mail nach Angabe der E-Mail-Adresse während des Logins per E-Mail zusendet. HeyJobs kann im eigenen Ermessen nach der Registrierung das Setzen eines Passwortes erforderlich machen;
        </ListItem>
        <ListItem>
          Der Nutzer verknüpft ein bereits eröffnetes und von ihm bestätigtes Konto bei einem Drittanbieter (zum Beispiel Google oder Facebook) mit HeyJobs, indem der Nutzer - während er in seinem jeweiligen Konto bei dem von HeyJobs unterstützten Drittanbieter eingeloggt ist - auf der Website von HeyJobs einen Verknüpfungs-Link aufruft. HeyJobs kann keinen Zugang des Nutzers zu einem auf diesem Wege erstellten Nutzerkontos gewährleisten, wenn der Zugang des Nutzers zu seinem Konto beim Drittanbieter nicht möglich ist. In diesem Fall erfolgen weitere Logins dadurch, dass der Nutzer erneut den Verknüpfungs-Link zu dem von ihm gewählten Drittanbieter auf der Website von HeyJobs abruft. HeyJobs kann im eigenen Ermessen nach der Registrierung das Setzen eines Passwortes erforderlich machen.
        </ListItem>
      </AlphaList>
    </ListItem>
    <ListItem> Es besteht kein Rechtsanspruch auf eine Registrierung. </ListItem>
    <ListItem>
      Die registrierten Nutzer sind selbst dafür verantwortlich, ihre Informationen und Kontaktdaten auf dem aktuellen Stand zu halten und sicherzustellen, dass sie unter den angegebenen Daten erreichbar sind.
    </ListItem>
    <ListItem>
      Jeder Nutzer darf nur ein Nutzerprofil erstellen. Das Nutzerkonto ist nicht übertragbar. Es ist ausschließlich für persönliche Zwecke zu verwenden. Es ist nicht gestattet, ohne die Einwilligung der betroffenen Personen Profile für Dritte zu erstellen oder Profile für nicht existierende Personen zu erstellen
    </ListItem>
    <ListItem>
      Ein Nutzerkonto wird auf unbestimmte Zeit angelegt und kann jederzeit durch den Nutzer mittels Löschung des Nutzerkontos oder durch HeyJobs im Wege der Kündigung mit einer Kündigungsfrist von vier Wochen beendet werden. Die Daten werden in diesem Fall unverzüglich gelöscht, wenn und soweit keine etwaigen gesetzlichen Aufbewahrungsfristen entgegenstehen.
    </ListItem>
    <ListItem>
      HeyJobs behandelt die personenbezogenen Daten der registrierten Nutzer vertraulich und verarbeitet diese nur im Rahmen dieser AGB und gesetzlicher Bestimmungen. Weitere Informationen über die Verwendung personenbezogener  Daten auf der Plattform enthält die jeweils aktuelle
      {' '}
      <UrlWrapper
        text='Datenschutzerklärung'
        href='https://www.heyjobs.co/recruiting/datenschutzerklaerung/'
      />
      {' '}
      von HeyJobs, die auf der Plattform abrufbar ist.
    </ListItem>
  </>,
  },
  {
    text: '§ 4 Pflichten der Nutzer',
    component:
  <>
    <ListItem>
      Die Plattform ist nur für die individuelle Arbeitssuche der Nutzer vorgesehen. Es ist nicht zulässig, die Dienstleistungen auf andere Weise kommerziell zu nutzen.
    </ListItem>
    <ListItem>
      Die registrierten Nutzer sind für jedwede Nutzung der Zugangsdaten und Passwörter sowie sämtliche Tätigkeiten verantwortlich, die im Zusammenhang mit der Nutzung ihres Nutzerprofils durchgeführt werden. Sämtliche Zugangsdaten und Passwörter sind geheim zu halten. Falls ein unbefugter Zugriff auf das Nutzerprofil durch Dritte erfolgt, ist HeyJobs sofort darüber zu informieren und jedes betroffene Passwort unverzüglich zu ändern.
    </ListItem>
    <ListItem>
      Im Zusammenhang mit der Nutzung der Dienstleistungen ist ein widerrechtliches und/oder gegen geltendes Recht verstoßendes Handeln untersagt. Insbesondere ist es nicht gestattet:
      <AlphaList>
        <ListItem>
          Inhalte bereitzustellen, die
          <UpperAlphaList>
            <ListItem>
              belästigend, beleidigend, jugendgefährdend oder auf andere Weise rechtswidrig sind;
            </ListItem>
            <ListItem>
              gegen die Rechte Dritter verstoßen, insbesondere das Urheberrecht, Persönlichkeitsrechte oder andere Eigentumsrechte;
            </ListItem>
            <ListItem>
              vertraulich sind, z. B. Geschäftsgeheimnisse enthalten;
            </ListItem>
          </UpperAlphaList>
        </ListItem>
        <ListItem>
          sich als eine andere Person auszugeben oder vorzugeben, in einer nicht tatsächlich bestehenden Beziehung zu einer solchen Person zu stehen, Nutzernamen zu fälschen oder Erkennungszeichen auf andere Weise zu manipulieren und/oder den Ursprung von Inhalten, die im Zusammenhang mit den Dienstleistungen übertragen werden, zu verschleiern;
        </ListItem>
        <ListItem>
          Werbe-, Junk- oder Massen-E-Mails, Kettenbriefe, Schneeballsysteme oder sonstige kommerzielle Kommunikation bereitzustellen und/oder zu versenden;
        </ListItem>
        <ListItem>
          Scraping oder vergleichbare Techniken zu nutzen, um Inhalte zu erfassen, für einen anderen Zweck zu verwenden, wieder zu veröffentlichen oder auf andere Art als den vorgesehenen Zweck der Plattform zu nutzen;
        </ListItem>
        <ListItem>
          Techniken oder automatisierte andere Dienstleistungen zu nutzen, die darauf abzielen oder dazu führen, dass Aktivitäten von Nutzern falsch angezeigt werden, (z. B. Bots, Bot-Nets, Scripts, Apps, Plugins, Erweiterungen oder andere automatisierte Instrumente, um Inhalte abzuspielen, zu veröffentlichen, Nachrichten zu verschicken oder andere Handlungen auszuführen);
        </ListItem>
        <ListItem>
          Eine natürliche Person oder ein Unternehmen zu belästigen, zu bedrohen, zu beleidigen oder zu diffamieren, aufgrund von Geschlecht, Rasse, Religion oder Überzeugungen zu verunglimpfen oder zu diskriminieren, oder unwahre Behauptungen über eine natürliche oder juristische Person aufzustellen oder zu verbreiten;
        </ListItem>
        <ListItem>
          Personenbezogene Daten anderer Nutzer zu erfassen, zu speichern oder zu übertragen, sofern die betroffenen Personen dem nicht eingewilligt haben;
        </ListItem>
        <ListItem>
          Links zu Inhalten Dritter zu posten, die gegen die Bestimmungen dieser AGB oder andere Vorschriften verstoßen; oder
        </ListItem>
        <ListItem>
          Inhalte bereitzustellen oder hochzuladen, die Viren, Würmer oder anderen schädlichen Code enthalten.
        </ListItem>
      </AlphaList>
    </ListItem>
    <ListItem>
      HeyJobs rät den Nutzern dringend davon ab, besondere Kategorien personenbezogener Daten über die Plattform zu verschicken oder bereitzustellen. Hierzu gehören Angaben über die ethnische Herkunft, politische Meinungen, religiöse oder weltanschauliche Überzeugungen oder die Gewerkschaftszugehörigkeit, sowie die genetische Daten, biometrische Daten zur eindeutigen Identifizierung einer natürlichen Person, Gesundheitsdaten oder Daten zum Sexualleben oder der sexuellen Orientierung einer natürlichen Person. Außerdem wird davon abgeraten, an Unternehmen im Rahmen des Bewerbungsprozesses Zahlungen zu leisten.
    </ListItem>
    <ListItem>
      HeyJobs ist nicht dazu verpflichtet, Inhalte von Nutzern auf der Plattform zu überwachen. Dennoch behält HeyJobs sich das Recht vor, Inhalte von Nutzern ganz oder zum Teil ohne Angabe von Gründen zu löschen, insbesondere sofern diese Inhalte gegen die unter §4 Absatz 3 Buchstabe a) bis i) genannten Regeln verstoßen.
    </ListItem>
    <ListItem>
      HeyJobs hat das Recht, das Nutzerkonto und den Zugang zu der Plattform jederzeit fristlos vorübergehend oder dauerhaft zu sperren und/oder zu beenden sowie einzelne Nutzungsmöglichkeiten zu beschränken. Dies gilt insbesondere, sofern der Nutzer ihre Pflichten gem. §3 Absatz 4 oder §4 Absatz 3 Buchstabe a) bis i) wiederholt oder erheblich verletzen (bei Verdacht ist eine vorübergehende Sperrung oder Beschränkung möglich) oder für den Fall des Widerrufs der Einwilligung zur Datennutzung.
    </ListItem>
  </>,
  },
  {
    text: '§ 5 Ausschluss von Garantien',
    component:
  <>
    <ListItem>
      Die Stellenanzeigen auf der Plattform werden von Dritten erstellt und bereitgestellt. HeyJobs hat keinen Einfluss auf Inhalt und Bedingungen der Stellenanzeigen oder weiterführende Links. HeyJobs ist nicht verpflichtet, die Stellenanzeigen vor oder nach ihrer Veröffentlichung zu überprüfen. HeyJobs übernimmt keine Verantwortung oder Garantie für die Gültigkeit von Stellenanzeigen, die Aktualität, Korrektheit, Vollständigkeit, Verwendbarkeit, oder Eignung von Inhalten der Plattform für einen bestimmten Verwendungszweck.
    </ListItem>
    <ListItem>
      HeyJobs übernimmt keine Garantie und hat auch keinen Einfluss auf die Berücksichtigung von Bewerbungen bzw. Nachrichten von Nutzern durch anzeigeaufgebende Unternehmen oder den Abschluss von Arbeitsverträgen mit Dritten. Allein die anzeigenaufgebenden Unternehmen treffen die Entscheidung darüber, ob und wie eine Bewerbung berücksichtigt wird und wie die Arbeitsbedingungen im Falle des Abschlusses eines Arbeitsvertrages gestaltet würden.
    </ListItem>
    <ListItem>
      HeyJobs übernimmt keine Garantie dafür, dass die Plattform zu bestimmten Zeiten oder dauerhaft verfügbar ist. Störungen, Unterbrechungen oder mögliche Ausfallzeiten der Plattform können nicht ausgeschlossen werden. Aus technischen oder betrieblichen Gründen kann es zu einer vorübergehend eingeschränkten Verfügbarkeit kommen. Das ist insbesondere im Hinblick auf die Kapazitätsbeschränkungen, die Sicherheit oder Integrität der Datenverarbeitungssysteme oder die Umsetzung technischer Maßnahmen, die für eine ordnungsgemäße oder verbesserte Leistung erforderlich sind, möglich. HeyJobs rät den Nutzern, Backup-Kopien ihrer auf der Plattform bereitgestellten Daten vorzuhalten, um Datenverluste zu vermeiden. Dies gilt auch für den Fall der Löschung des Nutzerprofils, da in diesem Fall keine Kopie von Nutzerdaten aufbewahrt wird, sofern keine gesetzlichen Verpflichtungen zur Speicherung bestehen.
    </ListItem>
    <ListItem>
      HeyJobs ist bestrebt, die höchste Sicherheit aller personenbezogenen Daten zu gewährleisten. HeyJobs kann jedoch keine Garantie für die angemessene und rechtzeitige technische Übermittlung von Daten übernehmen im Hinblick auf
      <AlphaList>
        <ListItem>
          die Übermittlung von Bewerbungsinformationen durch Nutzer ausgehend von Webseiten Dritter (etwa Facebook) zu HeyJobs; oder
        </ListItem>
        <ListItem>
          die Übermittlung von Daten von HeyJobs in Bewerbermanagementsysteme von Dritten.
        </ListItem>
      </AlphaList>
    </ListItem>
    <ListItem>
      HeyJobs übernimmt keine Garantie für die Identität von Arbeitgebern oder Einzelpersonen. Die Übertragung von personenbezogenen Daten und Informationen an andere Nutzer oder im Rahmen einer Stellenanzeige geschieht auf eigenes Risiko der Nutzer. HeyJobs hat keine Kontrolle über die tatsächliche Verwendung der personenbezogenen Daten durch die Empfänger.
    </ListItem>
  </>,
  },
  {
    text: '§ 6 Haftungsbeschränkung',
    component:
  <>
    <ListItem>
      Ansprüche der Nutzer auf Schadensersatz sind ausgeschlossen. Hiervon ausgenommen sind Schadensersatzansprüche der Nutzer aus der Verletzung des Lebens, des Körpers, der Gesundheit oder aus der Verletzung wesentlicher Vertragspflichten sowie die Haftung für sonstige Schäden, die auf einer vorsätzlichen oder grob fahrlässigen Pflichtverletzung von HeyJobs, einem gesetzlichen Vertreter oder Erfüllungsgehilfen beruhen. Wesentliche Vertragspflichten sind solche, deren Erfüllung zur Erreichung des Ziels des Vertrages notwendig sind.
    </ListItem>
    <ListItem>
      Bei leichter Fahrlässigkeit bei der Verletzung wesentlicher Vertragspflichten haftet HeyJobs nur auf den vertragstypischen, objektiv vorhersehbaren Schaden, es sei denn, es handelt sich um Schadensersatzansprüche der Nutzer aus einer Verletzung des Lebens, des Körpers und der Gesundheit.
    </ListItem>
  </>,
  },
  {
    text: '§ 7 Nutzungsrechte',
    component:
  <>
    <ListItem>
      Die Plattform und jegliche damit verbundene Software, Datenbanken, Grafiken, Nutzerschnittstellen, Designs und zusätzliche Inhalte, Namen und Warenzeichen unterstehen dem Schutz des Urheberrechts, des Markenrechts und zusätzlicher Eigentumsrechte. HeyJobs ist und bleibt alleiniger Inhaber bzw. Lizenzinhaber aller von HeyJobs auf der Plattform bereitgestellten geistigen Eigentumsrechte. Das gilt nicht für Inhalte von Nutzern und die Stellenanzeigen selbst.
    </ListItem>
    <ListItem>
      Das einfache Nutzungsrecht für die Plattform wird unter der Bedingung eingeräumt, dass die Nutzer diese AGB und alle sonstigen anwendbaren Bestimmungen einhalten. Dieses Nutzungsrecht ist nicht ausschließlich und nicht übertragbar. Es ist den Nutzern untersagt, (i) die Plattform für die Entwicklung anderer Dienstleistungen zu nutzen; (ii) Funktionen der Plattform oder Dienstleistungen zu aktivieren oder zu nutzen, für die kein Nutzungsrecht der Nutzer besteht; (iii) den Quellcode zu ändern, zu übersetzen, zu reproduzieren oder zu dekompilieren oder seine Funktionen zu untersuchen, außer wenn dies von Rechts wegen zugelassen werden muss; (iv) die Dienstleistungen in anderer Weise kommerziell zu nutzen.
    </ListItem>
    <ListItem>
      HeyJobs kann Lizenzen für die Nutzung von urheberrechtlich geschütztem Material auf individueller Basis und unter Ausschluss vertragswidriger Nutzung und gegen Zahlung einer angemessenen Lizenzgebühr erteilen. Soweit verfügbar, richtet sich die Lizenzgebühr nach branchenüblichen Sätzen.
    </ListItem>
    <ListItem>
      Überschreiten die Nutzer die nach diesem Vertrag eingeräumten Nutzungsrechte ohne vorherige Einwilligung von HeyJobs, behält sich HeyJobs vor, für die überschreitende Nutzung einen entsprechenden finanziellen Ausgleich zu verlangen. Grundlage der Berechnung im Einzelfall ist hierbei der Betrag, den HeyJobs verlangt hätte, wenn den Nutzern die entsprechenden Nutzungsrechte gegen Zahlung einer marktüblichen Lizenz eingeräumt worden wären. Außervertragliche Schadensersatzansprüche bleiben unberührt.
    </ListItem>
    <ListItem>
      Begrenzt auf die Dauer des Bestehens des Nutzerkontos räumt der Nutzer HeyJobs ein nicht exklusives, räumlich unbegrenztes, übertragbares Nutzungsrecht ein im Hinblick auf alle durch den Nutzer zur Verfügung gestellten Inhalte. Dieses Nutzungsrecht umfasst auch die Verwendung der Inhalte durch HeyJobs in durch HeyJobs bearbeiteter Form sowie die Zugänglichmachung an und die Analyse durch Dritte. Indem der Nutzer Inhalte auf der HeyJobs Plattform und in seinem Nutzerkonto bereitstellt, erklärt der Nutzer, zur Erteilung des vorgenannten Nutzungsrechts berechtigt zu sein und HeyJobs alle Schäden zu erstatten, die HeyJobs aufgrund dieser Inhalte entstehen.
    </ListItem>
    <ListItem>
      HeyJobs ist berechtigt, die durch den Nutzer bereitgestellten Informationen (beispielsweise im Rahmen der Registrierung) sowie die Interaktionen der Nutzer mit der Plattform und mit den durch HeyJobs veröffentlichten Stellenanzeigen zu analysieren und auf deren Basis Nutzer- und Zielgruppenprofile zu erstellen. HeyJobs ist weiter dazu berechtigt, diese Daten dazu zu nutzen, die Leistung der Plattform insgesamt zu verbessern und ein besseres und zielgenaueres Ausspielen von Stellenanzeigen an aktuelle und zukünftige Nutzer der Plattform zu erreichen. Zu diesem Zweck räumt der Nutzer HeyJobs das Recht ein, seine Daten – beispielsweise den Namen, die E-Mail-Adresse und/oder die Telefonnummer – in pseudonymisierter Form an Anbieter von Internetanwendungen übermitteln, wo diese mit bestehenden Kundendaten der jeweiligen Internetanwendung abgeglichen werden, um mit diesen vergleichbare Profile von Nutzern der jeweiligen Internetanwendungen zu ermitteln. Den Nutzern dieser Internetanwendungen, deren Profilangaben den deinen ähneln, können daraufhin besser passende Stellenangebote angezeigt werden. Die Details zu den von HeyJobs tatsächlich durchgeführten Tätigkeiten in Ausübung dieses Rechts finden sich in unserer Datenschutzerklärung.
    </ListItem>
  </>,
  },
  {
    text: '§ 8 Änderung dieser AGB und der Plattform',
    component:
  <>
    <ListItem>
      HeyJobs behält sich das Recht vor, diese AGB zu ändern, soweit dies aus triftigen Gründen, insbesondere aufgrund einer geänderten Rechtslage oder höchstrichterlichen Rechtsprechung, technischer Änderungen oder Weiterentwicklungen, Veränderung der Marktgegebenheiten oder anderen gleichwertigen Gründen erforderlich ist und die Nutzer nicht unangemessen benachteiligt.
    </ListItem>
    <ListItem>
      Registrierte Nutzer werden über solche Änderungen der AGB über ihre im Nutzerprofil hinterlegte E-Mail-Adresse und/оder über die Plattform informiert unter Mitteilung des Inhalts der geänderten Regelungen informiert und auf die Möglichkeit hingewiesen, dass sie der Änderung binnen 30 Tagen widersprechen können. Die Änderung wird Vertragsbestandteil, wenn die Nutzer nicht binnen 30 Tagen nach Zugang der Änderungsmitteilung der Einbeziehung in das Vertragsverhältnis gegenüber HeyJobs in Textform widersprechen. Widerspricht der Nutzer der Änderung, behält HeyJobs sich das Recht vor, den Vertrag fristlos zu kündigen.
    </ListItem>
    <ListItem>
      Für Dienstleistungen, die ohne Registrierung verfügbar sind, gelten die AGB in ihrer jeweils aktuellen Form, die zu dem Zeitpunkt, zu dem die Dienstleistungen genutzt werden, auf der Plattform veröffentlicht ist.
    </ListItem>
    <ListItem>
      Für einen Ausgleich der Interessen der Nutzer und HeyJobs-Kunden und um die Nutzbarkeit der Plattform zu gewährleisten und an die Gegebenheiten des Marktes anzupassen, ist es unerlässlich, die Plattform stetig weiterzuentwickeln. Es kann daher vorkommen, dass einzelne Dienste geändert oder ganz eingestellt werden. Der Kern der Dienstleistung von HeyJobs bleibt in jedem Fall erhalten, jedoch haben die Nutzer keinen Anspruch auf die Beibehaltung eines bestimmten Dienstes, einer Ansicht oder einer bestimmten Funktionsweise der Plattform.
    </ListItem>
  </>,
  },
  {
    text: '§ 9 Anwendbares Recht, Sonstiges',
    component:
  <>
    <ListItem>
      Diese AGB unterliegen dem deutschen Recht unter Ausschluss des UN-Kaufrechts. Die gesetzlichen Vorschriften zur Beschränkung der Rechtswahl und zur Anwendbarkeit zwingender Vorschriften insbesondere des Staates, in dem die Nutzer als Verbraucher ihren gewöhnlichen Aufenthalt haben, bleiben unberührt.
    </ListItem>
    <ListItem>
      Die  EU-Kommission stellt eine Plattform für die außergerichtliche Online-Streitbeilegung bereit, die unter
      {' '}
      <UrlWrapper
        text='https://ec.europa.eu/consumers/odr'
        href='https://ec.europa.eu/consumers/odr'
      />
      {' '}
      aufrufbar ist. HeyJobs nimmt nicht an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teil.
    </ListItem>
    <ListItem>
      Sollten einzelne Bestimmungen der AGB unwirksam sein oder werden oder sollte der Vertrag ungewollt Lücken aufweisen, so wird dadurch die Wirksamkeit der AGB im Übrigen nicht berührt. Die Parteien ersetzen eine unwirksame Bestimmung einvernehmlich durch eine solche Bestimmung, welche dem wirtschaftlichen Zweck der unwirksamen Bestimmung in rechtswirksamer Weise am nächsten kommt. Bei ungewollten Vertragslücken ergänzen die Parteien die Vereinbarung durch eine rechtswirksame Bestimmung, die dem Zweck und Inhalt des Vertrags entspricht.
    </ListItem>
  </>,
  },
]
