
import capitalize from 'lodash/capitalize'
import map from 'lodash/map'
import * as React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { fireSnowplowStructuredEvent } from '../../../tracking/external/trackingClient'
import { findCompanyName } from '../../../functions_/shared/utils'
import { build } from '../../../helpers/url/searchUrl'

interface LinkComponentProps {
  href?: string | UrlObject,
  to?: string,
  [key: string]: any
}

export const LinkComponent = ({ href, to, ...others }: LinkComponentProps) => {
  if (href) {
    return (
      <a
        href={href}
        {...others}
      />
    )
  }
  return (
    <RouterLink
      to={to}
      {...others}
    />
  )
}

export const handleNavLinkClick = (key: string) => {
  fireSnowplowStructuredEvent({
    action: 'top_bar_clicked',
    category: 'tp_page_action',
    label: key,
  })
}

export const renderCareerPageTitleText = (props) =>
  props.company || props.job
    ? `${(props.company || props.job.company).name} ${props.city
      ? props.intl.formatMessage({ id: 'navbar_career_page_jobs_in' }) +
      ' ' +
      map(props.city.split(' '), capitalize).join(' ')
      : props.intl.formatMessage({ id: 'navbar_career_page_jobs_default' })
    }`
    : ''

export const renderJobDetailPageTitleText = (props) =>
  `${props.job
    ? findCompanyName(props.job.company)
    : props.intl.formatMessage({ id: 'navbar_careers_loading' })
  } ${props.intl.formatMessage({ id: 'navbar_careers' })}`

export const renderTitleText = (props) =>
  props.careerPage
    ? renderCareerPageTitleText(props)
    : renderJobDetailPageTitleText(props)

// generate a link to show the jobs related to the current company
export const getLogoLink = (props) =>
  props.company
    ? props.cpathr.dashboard
    : build(
      { keyword: (props.companyShortName ?? props.companyName ?? '') },
      props.locale,
      props.country
    )
