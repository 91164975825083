import React from 'react'
import { HSvgIcon, HSvgIconProps } from '../HSvgIcon'

export const DayAndNightIcon: React.FC<HSvgIconProps> = (props) => {
  return (
    <HSvgIcon xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none" {...props}>
      <path d="M13.1103 2.06329H11.6103V5.80729H13.1103V2.06329Z" fill="#313545"/>
      <path d="M5.46439 4.61408L4.40373 5.67474L7.03417 8.30518L8.09483 7.24452L5.46439 4.61408Z" fill="#313545"/>
      <path d="M5.61029 11.8073H1.86629V13.3073H5.61029V11.8073Z" fill="#313545"/>
      <path d="M7.04067 16.828L4.41023 19.4584L5.47089 20.5191L8.10133 17.8887L7.04067 16.828Z" fill="#313545"/>
      <path d="M13.1103 19.3193H11.6103V23.0633H13.1103V19.3193Z" fill="#313545"/>
      <path d="M17.6969 16.8215L16.6362 17.8821L19.2667 20.5126L20.3273 19.4519L17.6969 16.8215Z" fill="#313545"/>
      <path d="M22.8663 11.8073H19.1223V13.3073H22.8663V11.8073Z" fill="#313545"/>
      <path d="M19.2602 4.60754L16.6297 7.23798L17.6904 8.29864L20.3208 5.6682L19.2602 4.60754Z" fill="#313545"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M12.3663 8.06329C11.1783 8.06329 10.0263 8.54329 9.18629 9.38329C8.56229 10.0073 8.1303 10.8113 7.9503 11.6873C7.7703 12.5633 7.8663 13.4633 8.2023 14.2913C8.5383 15.1193 9.11429 15.8153 9.85829 16.3073C10.6023 16.7993 11.4663 17.0633 12.3543 17.0633C13.2423 17.0633 14.1183 16.7993 14.8503 16.3073C15.5943 15.8153 16.1703 15.1073 16.5063 14.2913C16.8423 13.4633 16.9383 12.5633 16.7583 11.6873C16.5783 10.8113 16.1583 10.0073 15.5223 9.38329C14.6823 8.54329 13.5303 8.06329 12.3423 8.06329H12.3663ZM15.0903 13.7393C15.0183 13.9073 14.9583 14.0753 14.8623 14.2313C14.5383 14.7233 14.0583 15.1073 13.5183 15.3353C12.9783 15.5633 12.3663 15.6233 11.7903 15.5033C11.2143 15.3833 10.6743 15.1073 10.2543 14.6873C9.69029 14.1233 9.3783 13.3553 9.3783 12.5633C9.3783 11.7713 9.69029 11.0033 10.2543 10.4393C10.5183 10.1753 10.8543 9.98329 11.2023 9.82729C10.9623 10.5833 10.7343 11.9633 11.8503 13.0913C12.9663 14.2193 14.3463 13.9793 15.1023 13.7393H15.0903Z" fill="#313545"/>
    </HSvgIcon>
  )
}
