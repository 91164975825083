// @flow

import datalayer from '../clients/datalayer'
import { snowplowTrackStructEvent } from '../clients/snowplow'
import getState from '../shared/getState'
import { unPrefixRouteName } from '../../routing/routeNamePrefix'
import getPageType from './pageview/getPageType'
import buildContexts, { type ExtraContextParam } from '../shared/buildContexts'
import buildExperimentContextAndData from '../contexts/ab_testing'
import { addDefaultPageContext } from '../contexts/page'

export type StructuredEventProps = {
  action?: string,
  category: string,
  label?: string,
  property?: string,
  value?: number,
}

const structuredEvent = (
  {
    action,
    category,
    label,
    value,
    property,
  }: StructuredEventProps,
  extraContexts?: ExtraContextParam[] = []
): void => {
  const preparedAction = action || 'click'
  const preparedLabel = label || window.location.href
  const preparedValue = value || 0

  const withDefaultPageContext = addDefaultPageContext(extraContexts)

  const state = getState()

  const prefixedCurrentRouteName = state.routing.route.name

  // Fall back to the initally loaded route if we don't have a route set for some reason.
  const currentRouteName = prefixedCurrentRouteName
    ? unPrefixRouteName(prefixedCurrentRouteName)
    : state.pageLoadRouteData.routeName

  const pageName = getPageType(
    currentRouteName,
    state.pageLoadRouteData.routeNames
  )

  const baseEventContexts = buildContexts(state, pageName, withDefaultPageContext)
  const experimentContexts = [
    ...buildExperimentContextAndData(null),
  ]
  const snowplowEventContexts = [...baseEventContexts, ...experimentContexts]

  // Send Snowplow event.
  snowplowTrackStructEvent(category, preparedAction, preparedLabel, property || '', preparedValue, snowplowEventContexts)

  // Google Tag Manager generic event
  datalayer({
    event: 'genericJobSeekerEvent',
    eventCategory: category,
    eventAction: preparedAction,
    eventLabel: preparedLabel,
    eventValue: preparedValue,
  })
}

export default structuredEvent
