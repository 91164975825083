// @flow

import * as React from 'react'
import {
  GrowthBook,
  GrowthBookProvider as Provider,
  FeatureResult,
} from '@growthbook/growthbook-react'
import { datadogRum } from '@datadog/browser-rum'
import { useSelector, shallowEqual } from 'react-redux'
import { initializeAbTests } from '../actions/actionCreators'
import { fireSnowplowEvent } from '../tracking/external/trackingClient'
import { StoreProxy } from './StoreProxy.ts'
import config from '../../config'
import getPageType from '../tracking/events/pageview/getPageType'

import { type ApplicationState } from '../types/applicationState'
import { type AssignmentData } from '../abTestingOld/abTestingTypes'

const transformValueToGroup = (value: any): string => {
  if (value === undefined || value === null || value === '') {
    return 'unknown'
  }

  if (typeof value === 'string') {
    return value
  }

  return JSON.stringify(value)
}

const transformToStoreAssignment = (feature: string, value: FeatureResult<any>): AssignmentData => ({
  name: feature,
  group_name: transformValueToGroup(value.value),
  source: value.source,
})

const trackViaSnowplow = (assignment: AssignmentData) => {
  const {
    name: experimentName,
    group_name: assignedGroupName,
    source,
  } = assignment

  fireSnowplowEvent(
    'experiment_event',
    {
      experiment_id: experimentName,
      group_name: assignedGroupName,
      source,
    }
  )
}

const extractGrowthBookAttributes = (state: ApplicationState) => {
  const sessionId = state?.session?.device?.tracking_token
  const browser = typeof window !== 'undefined' ? window.navigator.userAgent : 'server'
  const { pathname, search } = state.router.location

  return {
    id: sessionId,
    userType: 'jobseeker',
    userId: state.user?.user?.id,
    sessionId,
    jobType: state.currentJob?.job?.type?.codename,
    locale: state.intlData?.locale,
    browser,
    newCaf: true,
    isBot: state.request.isBot,
    url: pathname + search,
    pageName: getPageType(
      state.pageLoadRouteData.routeName,
      state.pageLoadRouteData.routeNames
    ),
    app: 'jobseeker-portal',
  }
}

export const onFeatureUsage = (storeProxy: StoreProxy) => (featureKey: string, featureResult: FeatureResult<any>) => {
  const assignment = transformToStoreAssignment(featureKey, featureResult)

  // Necessary to modify the store for the automatically added contexts to work properly
  storeProxy?.dispatch(initializeAbTests(assignment))

  if (assignment.group_name !== 'unknown') {
    trackViaSnowplow(assignment)

    datadogRum.addFeatureFlagEvaluation(assignment.name, assignment.group_name)
  }
}

export const createGrowthbook = (state: ApplicationState, storeProxy?: StoreProxy): GrowthBook => {
  const growthBookDefinitions = state.abTesting.growthbook
  const url = state.router.location.pathname + state.router.location.search

  const growthbookInstance = new GrowthBook({
    ...growthBookDefinitions,
    url,
    qaMode: config.env === 'local' || config.env === 'ci',
    enableDevMode: true,
    attributes: extractGrowthBookAttributes(state),
    // NOTE: We could also provide `trackingCallback`, but that doesn't fire unless a user is
    // included in an experiment (due to the experiment being 'run'). Examples of why this would
    // not occur can be found here: https://github.com/growthbook/growthbook/blob/4bae5514726478820fc643f6854378477bd86969/docs/docs/faq.mdx#why-is-the-trackingcallback-not-firing-in-the-sdk
    // In particular if we're forcing the variant then it will override the experiment rule and
    // mean the experiment will not be run. For our usage it looks like onFeatureUsage will work
    // as we expect it to.
    onFeatureUsage: storeProxy
      ? onFeatureUsage(storeProxy)
      : undefined,
  })

  return growthbookInstance
}

export const GrowthBookProvider = ({ children, growthbook }: { children: React.Node, growthbook: GrowthBook }) => {
  const growthBookAttributes = useSelector(extractGrowthBookAttributes, shallowEqual)

  React.useEffect(() => {
    growthbook.setAttributes(growthBookAttributes)
  }, [growthBookAttributes])

  return (
    <Provider growthbook={growthbook}>
      {children}
    </Provider>
  )
}
