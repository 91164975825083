import React from 'react'
import CircularProgress, {
  CircularProgressProps as CPProps,
} from '@mui/material/CircularProgress'
import styled from '@emotion/styled'
import { COLORS } from '../theme'

type disableProps =
  | 'color'
  | 'disableShrink'
  | 'thickness'

type HProgressProps = Omit<CPProps, disableProps>

const StyledCircularProgress = styled(CircularProgress)`
  color: ${COLORS.primary_50};
`

export const HCircularProgress = (props: HProgressProps): JSX.Element => {
  return <StyledCircularProgress {...props} />
}
