import { UserCentricsService } from '../Usercentrics'
import { USER_CENTRIC_SERVICES } from '../userCentricServices'
import { logStringCustomAttribute } from '../../tracking/clients/braze'

const googleConsentModeSupported = (serviceName: string) => {
  return [
    USER_CENTRIC_SERVICES.GOOGLE_ADS_CONVERSION_TRACKING,
    USER_CENTRIC_SERVICES.GOOGLE_ADS_REMARKETING,
    USER_CENTRIC_SERVICES.DOUBLECLICK_AD,
  ].includes(serviceName)
}

const updateGoogleCustomAttributes = (consentStatus: boolean) => {
  logStringCustomAttribute('$google_ad_user_data', consentStatus)
  logStringCustomAttribute('$google_ad_personalization', consentStatus)
}

export const syncGoogleConsentModeSignalsWithBraze = (
  consentStateValues: { [key: string]: boolean },
  updatedUCServices: UserCentricsService[],
  forceUpdate: boolean = false
) => {
  const targetServices = updatedUCServices.filter(service => googleConsentModeSupported(service.name))
  const consentUpdated = targetServices.some(service => service.consent.status !== consentStateValues[service.name])

  if (!consentUpdated && !forceUpdate) return

  // If all services are allowed, we can assume that the user has given consent
  const consentModeStatus = targetServices.every(service => service.consent.status)

  updateGoogleCustomAttributes(consentModeStatus)
}
