// @flow

import * as React from 'react'
import { Container, Content } from './MainLayout'
import type { RouteLocation } from '../types/common'

import Header from './partials/MainHeaderNew'
import SlimFooter from './partials/SlimFooter'

type SlimLayoutProps = {
  children?: React$Element<any>,
  location: RouteLocation
}

const SlimLayout = ({ location, children }: SlimLayoutProps) => (
  <Container>
    <Header location={location} />
    <Content>
      {children}
    </Content>
    <SlimFooter location={location} />
  </Container>
)

export default SlimLayout
