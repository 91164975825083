import React from 'react'
import { ArrowLeft16 } from '@carbon/icons-react'
import { HButton } from 'talent-ui'
import { useOnboarding } from './use-onboarding-flow'

type BackStepButtonProps = {
  onClick?: () => void
}

const BackToPreviousStepButton: React.FC<BackStepButtonProps> = ({ onClick }) => {
  const { intl } = useOnboarding()

  return (
    <HButton
      text={intl.formatMessage({
        id: 'onboarding_flow_back_step_button',
      })}
      variant='text'
      color='primary'
      startIcon={<ArrowLeft16 />}
      data-test-id='onboarding-flow-back-button'
      onClick={onClick}
      space={{
        ml: -2,
        mt: 8,
        mb: 6,
        md: { mt: 14, mb: 8 },
      }}
    />
  )
}

export default BackToPreviousStepButton
