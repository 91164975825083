import React from 'react'
import Avatar, { AvatarProps } from '@mui/material/Avatar'
import styled from '@emotion/styled'
import { PersonIcon } from '../HAssets/PersonIcon'
import { HTypography } from '../HTypography'
import { COLORS } from '../theme'
import { IType } from '../HTypography/index.types'

interface OverrideProps {
  color?: keyof typeof COLORS
  size?: 40 | 32 | 24
  letters?: string
}

type HAvatarProps = OverrideProps & Omit<AvatarProps, keyof OverrideProps>

const SizeVariantMap: Record<number, IType> = {
  40: 'h5',
  32: 'body2',
  24: 'caption',
}

const StyledAvatar = styled(({ color, size, ...otherProps }: HAvatarProps) => (
  <Avatar {...otherProps} />
))`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  background-color: ${({ color }) => (color ? COLORS[color] : 'transparent')};
`

export const HAvatar = ({
  color,
  size = 32,
  letters,
  children,
  ...others
}: HAvatarProps): JSX.Element => {
  if (letters) {
    const variant = SizeVariantMap[size]
    children = (
      <HTypography component='span' color='surface_white' weight='medium' variant={variant}>
        {letters}
      </HTypography>
    )
  }
  if (!letters && !children) {
    children = <PersonIcon width={size} height={size} />
  }
  const props = {
    size,
    color,
    children,
    ...others,
  }
  return <StyledAvatar {...props} />
}
