// @flow

// NOTE: this function is an illustration of the shortcomings of this approach.
// ideally routes would be equal to pages and therefore their route names would
// be the same as page names.
// TODO: reduce this function as much as possible and remove it.

import type { RouteName, RouteNameMap } from '../../../routes'

const routeNamePageNameMap = (routeName: RouteName, routeNames: RouteNameMap) => {
  switch (routeName) {
    case routeNames.jobSearchKeyword:
    case routeNames.careerPage:
      return 'web_job_search'
    case routeNames.jobDetails:
      return 'web_job_detail'
    case routeNames.verifyEmail:
      return 'web_application_submitted_confirmation'
    case routeNames.privacy:
      return 'web_privacy_policy'
    case routeNames.unsubscribe:
      return 'web_unsubscribe'
    case routeNames.bookmarkedJobs:
      return 'bookmarked_jobs'
    case routeNames.messageList:
      return 'messages-list'
    default:
      // use the original routeName, if no special mapping is needed
      return routeName
  }
}

export default routeNamePageNameMap
