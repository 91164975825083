import React from 'react'
import Loadable from 'react-loadable'
import compose from 'lodash/flowRight'

import {
  PageLoader,
  redirectIfUnauthenticated,
} from './dependencies'

const loadableProfile = Loadable<{}, {}>({
  loader: () => import('./Profile'),
  loading: () => <PageLoader />,
})

const ProfilePage = compose(
  redirectIfUnauthenticated
)(loadableProfile)

export default ProfilePage
