import { useEffect } from 'react'

type UseWindowDragParams = {
  dragEnterCallback: () => void,
  dragLeaveWindowCallback: () => void,
  dragDropWindowCallback: () => void
}

export const useWindowDrag = ({ dragEnterCallback, dragLeaveWindowCallback, dragDropWindowCallback }: UseWindowDragParams) => {
  const handleWindowDragEnter = (event: DragEvent) => {
    event.preventDefault()

    dragEnterCallback()
  }

  const handleDragLeave = (event: DragEvent) => {
    event.preventDefault()

    // Check if we're leaving the browser window
    if (
      window.innerHeight < event.clientY ||
      window.innerWidth < event.clientX ||
      event.clientX <= 0 ||
      event.clientY <= 0
    ) {
      dragLeaveWindowCallback()
    }
  }

  const handleWindowDrop = (event: DragEvent) => {
    event.preventDefault()

    dragDropWindowCallback()
  }

  const handleWindowDragOver = (event: DragEvent) => {
    event.preventDefault()
  }

  useEffect(() => {
    window.addEventListener('dragenter', handleWindowDragEnter)
    window.addEventListener('dragleave', handleDragLeave)

    // Prevent opening file in browser. Allow only drag & drop in focused areas
    window.addEventListener('dragover', handleWindowDragOver)
    window.addEventListener('drop', handleWindowDrop)

    return () => {
      window.removeEventListener('dragenter', handleWindowDragEnter)
      window.removeEventListener('dragleave', handleDragLeave)
      window.removeEventListener('dragover', handleWindowDragOver)
      window.removeEventListener('drop', handleWindowDrop)
    }
  }, [])
}
