import React from 'react'

export const SvgPinkAndYellow: React.FC<{width?: number}> = ({ width }) => (
  <svg
    width={width}
    height='100%'
    viewBox='0 0 116 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    aria-label='Heyjobs'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M67.4711 23.6886C69.8888 23.6886 71.3743 21.8578 71.3743 18.4381C71.3743 15.0187 69.8888 13.188 67.4711 13.188C65.0188 13.188 63.5335 15.0187 63.5335 18.4381C63.5335 21.8578 65.0188 23.6886 67.4711 23.6886ZM67.4711 27.8326C61.5647 27.8326 58.007 23.9294 58.007 18.4375C58.007 12.9456 61.5647 9.04272 67.4711 9.04272C73.3428 9.04272 76.9006 12.9456 76.9006 18.4375C76.9006 23.9294 73.3428 27.8326 67.4711 27.8326Z'
      fill='#C84182'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M91.941 18.4381C91.941 14.984 90.5595 13.1877 88.3142 13.1877C86.0693 13.1877 84.6874 14.984 84.6874 18.4381C84.6874 21.8921 86.0693 23.6885 88.3142 23.6885C90.5595 23.6885 91.941 21.8921 91.941 18.4381ZM97.4676 18.4371C97.4676 24.2397 94.4627 27.8322 89.7649 27.8322C87.2091 27.8322 85.3437 26.4503 84.6528 25.5179H84.5841V27.2796H79.0575V2.58301H84.5841V11.3562H84.6528C85.3437 10.4238 87.2091 9.04196 89.7649 9.04196C94.4627 9.04196 97.4676 12.6344 97.4676 18.4371Z'
      fill='#C84182'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M115.682 22.0643C115.682 25.7948 112.85 27.8326 107.945 27.8326C101.624 27.8326 99.2062 25.0002 99.0334 21.4081H104.215C104.387 23.1695 105.562 24.0331 107.738 24.0331C109.292 24.0331 110.155 23.5152 110.155 22.5135C110.155 20.9936 108.497 20.7865 105.7 20.1646C102.695 19.5083 99.3789 18.4029 99.3789 14.7073C99.3789 11.4951 102.142 9.04272 107.116 9.04272C112.781 9.04272 114.991 11.9095 115.164 15.0181H109.983C109.81 13.6019 109.05 12.8419 107.254 12.8419C105.7 12.8419 104.905 13.3948 104.905 14.2585C104.905 15.5017 106.011 15.536 108.739 16.1233C112.228 16.8832 115.682 18.0577 115.682 22.0643Z'
      fill='#E6AF14'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M27.2589 16.5427H34.4687C34.4687 14.0818 32.978 12.8338 30.8635 12.8338C28.7488 12.8338 27.2589 14.0818 27.2589 16.5427ZM34.2255 21.9507H39.7371C38.767 25.5204 35.9933 27.8772 31.1756 27.8772C25.2829 27.8772 21.7127 23.9956 21.7127 18.4492C21.7127 13.0422 25.2132 9.021 30.8636 9.021C36.9296 9.021 40.0145 13.4579 40.0145 19.905H27.259C27.259 22.4014 29.0958 23.7185 31.0719 23.7185C32.978 23.7185 33.8794 22.8171 34.2255 21.9507Z'
      fill='#E6AF14'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M52.8681 5.00161C52.8681 3.40717 54.0815 2.54077 55.641 2.54077C57.2014 2.54077 58.4138 3.40717 58.4138 5.00161C58.4138 6.63072 57.2014 7.46306 55.641 7.46306C54.0815 7.46306 52.8681 6.63072 52.8681 5.00161Z'
      fill='#C84182'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M47.7529 18.1909L45.095 9.57642H39.5493L45.283 24.6148C45.283 24.6148 46.6089 21.5181 47.7529 18.1909Z'
      fill='#E6AF14'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M52.5479 9.57642L50.3291 17.2624C49.7933 19.4636 47.9325 26.3881 45.3025 28.4366C44.6624 29.1454 43.5071 29.2698 42.1146 28.9529H42.0449V33.1128C43.1887 33.3549 44.1944 33.4595 45.1298 33.4595C48.8737 33.4595 50.5378 32.2115 52.0625 27.6705L58.0942 9.57642H52.5479Z'
      fill='#C84182'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.57385 2.58569V12.26H13.9551V2.58569H19.4871V27.2896H13.9551V17.3392H5.57385V27.2896H0.0418243V2.58569H5.57385Z'
      fill='#E6AF14'
    />
  </svg>
)
