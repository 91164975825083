import React, { PropsWithChildren } from 'react'
import styled from '@emotion/styled'
import { styles } from './index.styles'
import { HTypographyProps, TypeMap, TypographyProps } from './index.types'
import { COLORS } from '../theme'
import { compose, DefaultTheme, spacing, SpacingProps } from '@mui/system'
import { isValidProp } from '../utils/isValidProp'

const createStyles = ({
  variant,
  weight,
  align,
  italic,
  truncated,
  underline,
  uppercase,
  color,
  cursor,
  breakWord,
  lineClamp,
  space,
}: HTypographyProps, theme: DefaultTheme): Record<string, string | number> => {
  return {
    ...(variant && styles[variant]),
    ...(weight && styles[weight]),
    ...(align && styles[align]),
    ...(color && { color: COLORS[color] }),
    ...(truncated && styles.truncated),
    ...(italic && styles.italic),
    ...(underline && styles.underline),
    ...(uppercase && styles.uppercase),
    ...(cursor && styles.pointer),
    ...(breakWord && styles.breakWord),
    ...(lineClamp && styles.lineClamp(lineClamp)),
    ...(space && createSpacingStyles(space, theme)),
  }
}

const createSpacingStyles = (props: SpacingProps,
  theme: DefaultTheme): Record<string, string> => {
  const composed = compose(
    spacing,
  )
  const themedProps = { ...props, theme: theme }
  return composed(themedProps)
}

const createSelector = (selector: {
  selector: string
  props: TypographyProps
}, theme: DefaultTheme): Record<string, ReturnType<typeof createStyles>> => {
  return {
    [selector.selector]: createStyles(selector.props, theme),
  }
}

const StyledComp = styled((
  {
    xs, sm, md, lg, xl,
    weight,
    align,
    color,
    italic,
    variant,
    underline,
    uppercase,
    truncated,
    cursor,
    ...props
  }: HTypographyProps) => (<span {...props} />), {
  shouldForwardProp: (prop: string) => isValidProp(prop),
},
)`
  ${({ theme, ...props }) => createStyles({
    ...props,
    weight: props.weight ?? 'regular',
    align: props.align ?? 'left',
    color: props.color ?? 'surface_gray_90',
  }, theme,
  )}
  ${({ theme, xs }) => xs && theme.breakpoints.up('xs')} {
    ${({ theme, xs }) => xs && createStyles(xs, theme)}
  }
  ${({ theme, sm }) => sm && theme.breakpoints.up('sm')} {
    ${({ theme, sm }) => sm && createStyles(sm, theme)}
  }
  ${({ theme, md }) => md && theme.breakpoints.up('md')} {
    ${({ theme, md }) => md && createStyles(md, theme)}
  }
  ${({ theme, lg }) => lg && theme.breakpoints.up('lg')} {
    ${({ theme, lg }) => lg && createStyles(lg, theme)}
  }
  ${({ theme, xl }) => xl && theme.breakpoints.up('xl')} {
    ${({ theme, xl }) => xl && createStyles(xl, theme)}
  }
  ${({ theme, selectors }) => selectors?.map((selector: NonNullable<HTypographyProps['selectors']>[number]) => createSelector(selector, theme))}
`

export const HTypography: React.FC<PropsWithChildren<HTypographyProps>> =
  (props) => {
    const {
      component,
      variant = 'body2',
      children,
      color,
      ...tags
    } = props

    return (
      <StyledComp
        as={component ?? TypeMap[variant]}
        variant={variant}
        color={color}
        {...tags}
      >
        {children}
      </StyledComp>
    )
  }
