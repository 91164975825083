// @flow
import React from 'react'
import { connect } from 'react-redux'
import { IntlProvider } from 'react-intl'
import type { Locale } from '../../types/common'
import type { ApplicationState } from '../../types/applicationState'

/**
 * Exchanges the locale name format from localize (underscores '_') to react-intl (dashes '-')
 * @param {string} locale - locale name in underscore format
 * @returns {string} locale name in dash format
 */
const transformLocaleName = (locale: Locale) => locale.replace(/_/g, '-')

const mapState = (state: ApplicationState) => ({
  locale: transformLocaleName(state.intlData.locale),
  messages: state.intlData.messages,
  key: state.intlData.locale,
})

export const defaultRichTextElements = {
  br: () => <br />,
  b: (chunks: string[]) => <b>{chunks}</b>,
  strong: (chunks: string[]) => <strong>{chunks}</strong>,
  p: (chunks: string[]) => <p>{chunks}</p>,
  div: (chunks: string[]) => <div>{chunks}</div>,
  span: (chunks: string[]) => <span>{chunks}</span>,
}

const Provider = (props) => {
  return (
    <IntlProvider
      {...props}
      textComponent='span'
      defaultRichTextElements={defaultRichTextElements}
    />
  )
}

export default connect(mapState)(Provider)
