import { MenuList, MenuListProps } from '@mui/material'
import React from 'react'
import styled from '@emotion/styled'
import { HMenuItem } from '../HMenu'
import { COLORS, globalStyles } from '../theme'

const AutoCompleteMenuList = styled(MenuList)`
  box-shadow: ${globalStyles.shadow3.boxShadow};
  border-radius: 4px;
  background-color: ${COLORS.surface_white};
`

export const AutocompleteListBox = React.forwardRef<
HTMLUListElement,
MenuListProps
>((props, ref) => {
  const { children, ...others } = props
  return (
    <AutoCompleteMenuList {...others} ref={ref}>
      {(children as JSX.Element[]).map((child: JSX.Element, i: number) => {
        return (
          <HMenuItem
            key={i}
            data-test-id={`autocomplete-option-${i}`}
            selected={child.props['aria-selected']}
            disabled={child.props['aria-disabled']}
            {...child.props}
          ></HMenuItem>
        )
      })}
    </AutoCompleteMenuList>
  )
})

AutocompleteListBox.displayName = 'AutocompleteListBox'
