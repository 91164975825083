/* istanbul ignore file */
import React from 'react'
import { Bookmark16, BookmarkFilled16, Location16 } from '@carbon/icons-react'
import { Theme, keyframes, useMediaQuery } from '@mui/material'
import styled from '@emotion/styled'
import { HButton } from '../HButton'
import { HPlaceholder } from '../HPlaceholder'
import { HTypography } from '../HTypography'
import { HJobCardProps } from './HJobCard.types'
import { HBox } from '../HBox'
import { HChip } from '../HChip'
import { HIcon } from '../HIcon'
import { VerifiedBadge } from './VerifiedBadge'
import { JobCardOpacityWrapper } from './HJobCard.styles'

const scaleUp = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
`

const StyledBookmarkFilled16 = styled(BookmarkFilled16)`
  &.scaledUp {
    animation: ${scaleUp} 0.5s ease-in-out forwards;
  }
`

export const JobCardHeader: React.FC<HJobCardProps> = ({
  companyName,
  companyImage,
  jobNoLongerActiveText,
  location,
  multiLocationButton,
  isCareerPage,
  isSaved,
  isUnavailable,
  onSaveClick,
  savedText,
  savedButtonProps,
  verifiedBadgeText,
  showSaveButton,
  size,
  title,
  tags,
}) => {
  const isSmall = size === 'small'
  const isAboveLg = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))

  const [liked, setLiked] = React.useState(false)

  const handleSaveButtonClick = (
    event: React.MouseEvent<Element, MouseEvent>,
  ): void => {
    onSaveClick?.(event)

    if (!isSaved) {
      setLiked(true)
    }
  }

  const jobTitle = (
    <HTypography
      as='h3'
      variant='h5'
      weight='medium'
      color='primary_50'
      lineClamp={3}
      breakWord
      data-test-id='job-title'
      className='job-card-title'
    >
      {title}
    </HTypography>
  )

  const jobCompanyName = (
    <HTypography
      color='surface_gray_90'
      data-test-id='company-name'
      fontSize={16}
      lineClamp={2}
      variant='body1'
      weight='regular'
    >
      {companyName}
    </HTypography>
  )

  const jobLocation = location && (
    <HBox
      mt={4}
      gap={8}
      display='flex'
      flexDirection='row'
      flexWrap='wrap'
      alignItems='center'
    >
      <HBox
        display='flex'
        gap={8}
      >
        <HIcon
          color='surface_gray_90'
          Icon={Location16}
          size={18}
        />

        <HTypography
          color='surface_gray_90'
          data-test-id='company-location'
          variant='body2'
          weight='regular'
          breakWord
        >
          {location}
        </HTypography>
      </HBox>

      {tags?.remoteType?.text && (
        <HChip color='gray' size='small' variant='filled' placeholder={tags.remoteType.text} />
      )}
    </HBox>
  )
  const multiLocationButtonNode = multiLocationButton && (
    <HButton
      {...multiLocationButton}
      variant='text'
      color='primary'
      space={{ mt: 2 }}
    >
    </HButton>
  )

  const companyImageAndBookmarkButton = (
    <HBox
      display='flex'
      justifyContent='space-between'
      gap={32}
      mb={4}
    >
      <JobCardOpacityWrapper
        display='flex'
        gap={16}
        minWidth={0}
        isUnavailable={isUnavailable}
      >
        <HPlaceholder
          size={isSmall || isAboveLg ? 'M' : 'S'}
          src={companyImage?.src}
          srcSet={companyImage?.srcset}
          alt={companyName}
          imgProps={{ style: { objectFit: 'contain' } }}
          data-test-id='company-logo-container'
        />
      </JobCardOpacityWrapper>

      {!isUnavailable && showSaveButton && (
        <HButton
          {...savedButtonProps}
          variant='text'
          color='primary' onClick={handleSaveButtonClick}
          data-test-id={isSaved ? 'unbookmark-button' : 'bookmark-button'}
          endIcon={isSaved ? <StyledBookmarkFilled16 className={liked ? 'scaledUp' : ''} /> : <Bookmark16 />}
        >
          {savedText}
        </HButton>
      )}
      {isUnavailable && jobNoLongerActiveText && !isSmall && (
        <HChip
          color='warning'
          placeholder={jobNoLongerActiveText}
          data-test-id='unavailable-job-title'
          variant='filled'
          size='small'
        />
      )}
    </HBox>
  )

  return (
    <>
      {!isCareerPage && companyImageAndBookmarkButton}
      <JobCardOpacityWrapper isUnavailable={isUnavailable}>
        <HBox display='flex' flexDirection='column' gap={8}>
          {jobTitle}
          {jobCompanyName}
          {verifiedBadgeText && <VerifiedBadge text={verifiedBadgeText} />}
        </HBox>
        {jobLocation}
        {multiLocationButtonNode}
      </JobCardOpacityWrapper>
    </>
  )
}
