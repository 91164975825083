import React from 'react'

export const Twitter: React.FC = () => {
  return (
    <svg
      width='25'
      height='21'
      viewBox='0 0 25 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24.8543 3.33183C23.9489 3.73347 22.9757 4.00491 21.9545 4.12685C22.9968 3.50201 23.7974 2.51257 24.1745 1.3335C23.1988 1.91221 22.1183 2.3323 20.9682 2.55871C20.0473 1.57739 18.7351 0.964355 17.2828 0.964355C14.4945 0.964355 12.2337 3.2249 12.2337 6.01321C12.2337 6.40893 12.2784 6.79433 12.3645 7.16384C8.16829 6.95331 4.44788 4.94317 1.95773 1.88846C1.52312 2.63414 1.27406 3.50152 1.27406 4.42685C1.27406 6.17859 2.16544 7.72397 3.52021 8.62936C2.69258 8.60316 1.91405 8.37601 1.23333 7.99787C1.23284 8.01892 1.23284 8.03996 1.23284 8.06137C1.23284 10.5076 2.97326 12.5483 5.28302 13.0121C4.85936 13.1276 4.41331 13.1893 3.95286 13.1893C3.62751 13.1893 3.31115 13.1576 3.00291 13.0987C3.64535 15.1045 5.50993 16.5643 7.71941 16.605C5.99142 17.9592 3.81442 18.7664 1.44879 18.7664C1.04125 18.7664 0.639374 18.7424 0.244385 18.6958C2.47884 20.1284 5.13278 20.9644 7.98409 20.9644C17.271 20.9644 22.3496 13.2708 22.3496 6.59868C22.3496 6.37977 22.3446 6.1621 22.3349 5.94553C23.3215 5.23356 24.1774 4.34441 24.8543 3.33183Z'
        fill='#707485'
      />
    </svg>
  )
}
