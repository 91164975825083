import { useCallback } from 'react'
import throttle from 'lodash.throttle'
import { FetchThrotlled, LocationAutoCompleteHook } from './use-normalized-maps-api'
import { LocationPrediction, NormalizedMapsPrediction, PlacePredictionsRequestOptions, PlacePredictionsResponse } from './types'

export const normalizeApiPrediction = (prediction: LocationPrediction): NormalizedMapsPrediction => {
  const splittedText = (prediction.description ?? '').split(', ') ?? []

  return {
    description: prediction.description,
    matched_substrings: prediction.matched_substrings,
    structured_formatting: {
      main_text: splittedText[0],
      secondary_text: splittedText.slice(1).join(', '),
      main_text_matched_substrings: prediction.matched_substrings
    }
  }
}

export type FetchLocationAutocompleteAPICall = (config: PlacePredictionsRequestOptions) => Promise<PlacePredictionsResponse>

export const useInternalEngineLocationAutocomplete = (fetchLocationAutocompleteAPICall: FetchLocationAutocompleteAPICall): ReturnType<LocationAutoCompleteHook> => {
  const fetchThrottled = useCallback<FetchThrotlled>((config, callback) => {
    const fetchLocationAutoComplete = (config: PlacePredictionsRequestOptions) => fetchLocationAutocompleteAPICall(config).then(res => callback(res.predictions.map(normalizeApiPrediction)))

    return throttle<FetchThrotlled>(fetchLocationAutoComplete, 200)(config, callback)
  }, [fetchLocationAutocompleteAPICall])

  return {
    fetchThrottled
  }
}
