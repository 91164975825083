// @flow

import * as utils from '../utils/reduxUtils'
import * as API from '../api/clients'
import * as actions from '../../constants/actions'
import transformToSearchAPIOptions from '../../epics/searchEpic/transformToSearchAPIOptions'

export const getQuizResult = utils.createPromise(
  actions.GET_JOB_TYPE_QUIZ_RESULT,
  (answers) => (config) => API.jobTypes.suggestions({ answers }, config),
  true
)

export const getJobType = utils.createPromise(
  actions.GET_JOB_TYPE,
  (id) => (config) => API.jobTypes.get(id, config)
)

export const submitQuizFeedback = utils.createPromise(
  actions.SUBMIT_QUIZ_FEEDBACK,
  (data) => (config) => API.jobTypeQuizFeedback.postFeedback(data, config)
)

export const getQuizFeedback = utils.createPromise(
  actions.GET_QUIZ_FEEDBACK,
  (options) => (config) => API.jobTypeQuizFeedback.getFeedback(options, config)
)

export const updateQuizAnswers = utils.create(
  actions.UPDATE_QUIZ_ANSWERS,
  'answers'
)

export const getJobsCount = utils.createPromise(
  actions.GET_JOBS_COUNT,
  (data) => (config) => API.search.jobsCount(transformToSearchAPIOptions(data), config),
  true
)
