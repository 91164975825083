import React from 'react'
import { HBox } from '../HBox'
import { HChip } from '../HChip'
import { ApplicationStatusBadgeProps } from './HApplicationCard.types'

export const HStatusBadge: React.FC<ApplicationStatusBadgeProps> = ({
  testId,
  message,
  color,
  icon,
}) => (
  <HBox data-test-id={testId}>
    <HChip
      variant='filled'
      placeholder={message}
      color={color}
      icon={React.isValidElement(icon) ? icon : undefined}
    />
  </HBox>
)
