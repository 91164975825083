import { InputAdornment } from '@mui/material'
import { TextFieldProps } from '@mui/material/TextField'
import { DistributiveOmit } from '@mui/types'
import clsx from 'clsx'
import React, { ReactNode } from 'react'
import { HTypography } from '../HTypography'
import { HInputStyled } from './HInputStyled'

type disableProps = 'variant' | 'size' | 'helperText'

interface HInputProps {
  startAdornment?: ReactNode
  endAdornment?: ReactNode
  caption?: string
  action?: any
  readOnly?: boolean
  forceShrink?: boolean
  dataTestId?: string
  focused?: boolean
}

export type IHTextFieldProps =
  React.PropsWithChildren<
  HInputProps &
  DistributiveOmit<TextFieldProps, disableProps>
  >

export const HInput = React.forwardRef(
  (props: IHTextFieldProps, ref: React.Ref<HTMLDivElement>) => {
    const {
      color,
      InputProps,
      startAdornment,
      endAdornment,
      caption,
      action,
      readOnly = false,
      onChange,
      InputLabelProps,
      forceShrink = false,
      dataTestId,
      ...others
    } = props

    const [focused, setFocused] = React.useState(others.focused)
    const [value, setValue] = React.useState(others.value)
    const hInputProps: HInputProps = {
      ...InputProps,
    }

    // `focused` is not part of InputProps in TextField element
    delete hInputProps?.focused

    if (startAdornment) {
      hInputProps.startAdornment = (
        <InputAdornment position='start'>{startAdornment}</InputAdornment>
      )
    }
    if (action || endAdornment) {
      hInputProps.endAdornment = (
        <InputAdornment position='end' className={action ? 'InputAction' : ''}>
          {endAdornment || action}
        </InputAdornment>
      )
    }
    if (readOnly) {
      hInputProps.readOnly = readOnly
    }

    return (
      <HInputStyled
        inputRef={ref}
        variant='outlined'
        size='small'
        helperText={ caption && (
          <HTypography color= {others.error ? 'support_warning_50' : 'surface_gray_80'} weight='regular' variant='caption'>
            {caption}
          </HTypography>
        )
        }
        {...others}
        onChange={(event) => {
          setValue(event.target.value)
          onChange?.(event)
        }}
        InputLabelProps={{
          ...InputLabelProps,
          shrink: focused || !!value || forceShrink || !!others.defaultValue,
          className: clsx(startAdornment && 'MuiInputLabel-startAdorned'),
        }}
        onFocus={(event) => {
          setFocused(true)
          if (props.onFocus) {
            props.onFocus(event)
          }
        }}
        onBlur={(event) => {
          setFocused(false)
          if (props.onBlur) {
            props.onBlur(event)
          }
        }}
        InputProps={hInputProps}
        inputProps={{
          ...others.inputProps,
          'data-test-id': dataTestId,
        }}
      />
    )
  })

HInput.displayName = 'HInput'
