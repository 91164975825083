import Grid from '@mui/material/Grid'
import React from 'react'
import { HBox } from '../../HBox'
import { HTypography } from '../../HTypography'
import { MainFooterLink } from './FooterLink'
import { RightSectionProps } from './HMainFooter.types'

export const RightSection: React.FC<RightSectionProps> = ({
  forEmployers,
  aboutUs,
}) => {
  return (
    <Grid container>
      <Grid item xs={12} sm={6} lg={12}>
        <HBox mb={8} display='flex' flexDirection='column' gap={8}>
          <HTypography
            variant='overline2'
            weight='semibold'
            color='surface_gray_80'
          >
            {aboutUs.title.toUpperCase()}
          </HTypography>
          <MainFooterLink
            key={aboutUs.links.imprint.label}
            label={aboutUs.links.imprint.label}
            component={aboutUs.links.imprint.component}
            componentProps={aboutUs.links.imprint.componentProps}
            id='imprint-link'
          />
          <MainFooterLink
            key={aboutUs.links.termsAndCondition.label}
            label={aboutUs.links.termsAndCondition.label}
            component={aboutUs.links.termsAndCondition.component}
            componentProps={aboutUs.links.termsAndCondition.componentProps}
            id='tc-link'
          />
          <MainFooterLink
            key={aboutUs.links.privacyPolicy.label}
            label={aboutUs.links.privacyPolicy.label}
            component={aboutUs.links.privacyPolicy.component}
            componentProps={aboutUs.links.privacyPolicy.componentProps}
            id='privacy-link'
          />
          <MainFooterLink
            key={aboutUs.links.careerAtHeyjobs.label}
            label={aboutUs.links.careerAtHeyjobs.label}
            component={aboutUs.links.careerAtHeyjobs.component}
            componentProps={aboutUs.links.careerAtHeyjobs.componentProps}
            id='jobs-link'
          />
          <MainFooterLink
            key={aboutUs.links.careerGuide.label}
            label={aboutUs.links.careerGuide.label}
            component={aboutUs.links.careerGuide.component}
            componentProps={aboutUs.links.careerGuide.componentProps}
            id='career-guide-link'
          />
        </HBox>
      </Grid>
      {forEmployers.isVisible && (
        <Grid item xs={12} sm={6} lg={12} >
          <HBox display='flex' flexDirection='column' gap={8}>
            <HBox >
              <HTypography
                variant='overline2'
                weight='semibold'
                color='surface_gray_80'
              >
                {forEmployers.title.toUpperCase()}
              </HTypography>
            </HBox>
            <MainFooterLink
              key={forEmployers.links.homePage.label}
              label={forEmployers.links.homePage.label}
              component={forEmployers.links.homePage.component}
              componentProps={forEmployers.links.homePage.componentProps}
              id='employers-link'
            />
            <MainFooterLink
              key={forEmployers.links.complianceAndSecurity.label}
              label={forEmployers.links.complianceAndSecurity.label}
              component={forEmployers.links.complianceAndSecurity.component}
              componentProps={
                forEmployers.links.complianceAndSecurity.componentProps
              }
              id='compliance-link'
            />
            <MainFooterLink
              key={forEmployers.links.recruiterLogin.label}
              label={forEmployers.links.recruiterLogin.label}
              component={forEmployers.links.recruiterLogin.component}
              componentProps={forEmployers.links.recruiterLogin.componentProps}
              id='recruiters-link'
            />
          </HBox>
        </Grid>
      )}
    </Grid>
  )
}
