import { hasExplicitConsent } from './initialization-handler'

export const dataLayer = (...args: any[]) => {
  try {
    const hasConsent = hasExplicitConsent()

    if (hasConsent) {
      return window.dataLayer.push(...args)
    }

    window.dataLayerBeforeConsent.push(...args)
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('tracking - datalayer - error', err)
  }
}
