import { Wikis16 } from '@carbon/icons-react'
import React from 'react'
import { HBox } from '../../HBox'
import { HIcon } from '../../HIcon'
import { SlimFooterBox, StyledSlimFooter, TypographyLink } from './HSlimFooter.styles'
import { SlimFooterProps } from './HSlimFooter.types'

export const HSlimFooter: React.FC<SlimFooterProps> = ({
  links: {
    changeLanguage,
    forEmployers,
    imprint,
    termsAndConditions,
    privacyPolicy,
    cookieSettings,
    careersAtHeyjobs,
    careerGuide,
  },
}) => {
  return (
    <SlimFooterBox
      component='footer'
      data-test-id='footer-slim'
      pb={6}
      px={4}
    >
      <nav>
        <StyledSlimFooter>
          {changeLanguage.isVisible && (
            <li>
              <TypographyLink
                color='inherit'
                variant='caption'
                component={changeLanguage.component ?? 'a'}
                {...changeLanguage.componentProps}
              >
                <HBox pr={1}>{changeLanguage.label}</HBox>
                <HIcon Icon={Wikis16} size={12} color='inherit' />
              </TypographyLink>
            </li>
          )}
          {forEmployers.isVisible && (
            <li>
              <TypographyLink
                id='employer-link'
                color='inherit'
                variant='caption'
                component={forEmployers.component ?? 'a'}
                {...forEmployers.componentProps}
              >
                {forEmployers.label}
              </TypographyLink>
            </li>)}
          {imprint.isVisible && (
            <li>
              <TypographyLink
                color='inherit'
                variant='caption'
                component={imprint.component ?? 'a'}
                {...imprint.componentProps}
              >
                {imprint.label}
              </TypographyLink>
            </li>)}
          {termsAndConditions.isVisible && (
            <li>
              <TypographyLink
                color='inherit'
                variant='caption'
                component={termsAndConditions.component ?? 'a'}
                {...termsAndConditions.componentProps}
              >
                {termsAndConditions.label}
              </TypographyLink>
            </li>)}
          {privacyPolicy.isVisible && (
            <li>
              <TypographyLink
                color='inherit'
                variant='caption'
                component={privacyPolicy.component ?? 'a'}
                {...privacyPolicy.componentProps}
              >
                {privacyPolicy.label}
              </TypographyLink>
            </li>)}
          {cookieSettings.label && (
            <li>
              <TypographyLink
                color='inherit'
                variant='caption'
                onClick={
                  cookieSettings.isClickable
                    ? cookieSettings.onClick
                    : () => { /* placeholder */ }
                }
              >
                {cookieSettings.label}
              </TypographyLink>
            </li>
          )}
          {careersAtHeyjobs.isVisible && (
            <li>
              <TypographyLink
                color='inherit'
                variant='caption'
                component={careersAtHeyjobs.component ?? 'a'}
                {...careersAtHeyjobs.componentProps}
              >
                {careersAtHeyjobs.label}
              </TypographyLink>
            </li>
          )}
          {careerGuide.isVisible && (
            <li>
              <TypographyLink
                color='inherit'
                variant='caption'
                component={careerGuide.component ?? 'a'}
                {...careerGuide.componentProps}
              >
                {careerGuide.label}
              </TypographyLink>
            </li>
          )}
        </StyledSlimFooter>
      </nav>
    </SlimFooterBox>
  )
}
