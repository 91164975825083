import styled from '@emotion/styled'
import { Popover } from '@mui/material'
import React from 'react'
import { HBox } from '../../HBox'
import { globalStyles } from '../../theme'
import { MenuItem, MobileMenuProps } from '../HNavBar.types'
import { MenuToggleButton } from '../MenuToggleButton'
import { MobileMenuItems } from '../MobileMenu/MobileMenuItems'
import { HRoundIconButton } from '../../HButton'
import { HIcon } from '../../HIcon'
import { Search24 as SearchIcon } from '@carbon/icons-react'

interface TabletMenuProps extends MobileMenuProps {
  searchButton?: MenuItem
}

const HPopover = styled(Popover)`
  .MuiPaper-root {
    margin-top: 20px;
    box-shadow: ${globalStyles.shadow4.boxShadow};
    border-radius: 4px;
  }
`

export const TabletMenu: React.FC<TabletMenuProps> =
 (props) => {
   const [open, setOpen] = React.useState(false)
   const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
   const { userProfile, searchButton, buttonLabel } = props

   return (
     <>
       <HBox display='flex' gap={24} >
         {searchButton && (
           <HRoundIconButton
             {...searchButton.componentProps}
             color='neutral'
           >
             <HIcon Icon={SearchIcon} size={24} />
           </HRoundIconButton>
         )}
         <MenuToggleButton
           buttonLabel={buttonLabel}
           open={open}
           IndicatorComponent={userProfile?.IndicatorComp}
           onClick={(event) => {
             setAnchorEl(event.currentTarget)
             setOpen(!open)
           }}
         />
       </HBox>

       <HPopover
         open={open}
         anchorEl={anchorEl}
         anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
         transformOrigin={{ vertical: 'top', horizontal: 'left' }}
         onClose={() => setOpen(false)}
       >
         <HBox
           p={6}
           width={240}
           boxShadow={globalStyles.shadow4.boxShadow}
         >
           <MobileMenuItems {...props} />
         </HBox>
       </HPopover>
     </>
   )
 }
