// @flow
import toPairs from 'lodash/toPairs'
import forEach from 'lodash/forEach'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import join from 'lodash/join'
import filter from 'lodash/filter'
import searchParamEncoder from './searchParamEncoder'
import 'url-polyfill'
import { type RouteData } from '../../types/common'

export const encodeUrlParamsManually = (
  paramsAsPairs: Array<[string, any]>
): string => {
  const encoder = searchParamEncoder
  return join(
    map(paramsAsPairs, ([key, value]) => `${encoder(key)}=${encoder(value)}`),
    '&'
  )
}

/**
 * Builds a url query string from a given set of parameters (eg. 'foo=bar&fizz=buzz')
 * @param {Object} params - keys and values to include in the query string
 * @returns {string} the query string
 */
export const build = (params: Object): string => {
  const searchParams = new URLSearchParams()
  const filteredParams = filter(
    toPairs(params),
    ([key, value]) => value !== undefined
  )

  forEach(filteredParams, ([key, value]) => searchParams.append(key, value))

  const searchParamsAsString = searchParams.toString()

  // NOTE: This is for Microsoft Edge :)
  try {
    decodeURIComponent(searchParamsAsString)
    return searchParamsAsString
  } catch (_) {
    return encodeUrlParamsManually(filteredParams)
  }
}

/**
 * Builds a url query fragment string from a given set of parameters (eg. '?foo=bar')
 * @param {Object} params - keys and values to include in the query string
 * @returns {string} the query string url fragment
 */
export const buildUrlFragment = (params: Object): string => {
  const query = !isEmpty(params) && build(params)
  return query ? `?${query}` : ''
}

/**
 * Returns the first value if multiple query params with the same name are provided (eg. '?var=foo&var=bar')
 * @param {Array | string} element - element from the parsed query string. A string or array.
 * @returns {string?} the first element in the array or the same string as the input element.
 */
export const first = (element: ?(Array<string> | string)): ?string => {
  if (!element) return element
  if (typeof element === 'string') {
    return element
  } else if (Array.isArray(element) && element.length > 0) {
    return element[0]
  }
  throw new Error('element is neither a non-empty array nor string')
}

export const parseQueryString = (search: ?string) => {
  if (!search) return {}

  const query = {}
  const searchParams = new URLSearchParams(search)
  Array.from(searchParams.keys()).forEach((key) => {
    query[key] = searchParams.get(key)
  })
  return query
}

/**
 * This should be used only if the history object is not available.
 * If it is, the `removeQueryParam` redux action should be preferred.
 */
export const removeQueryParam = (param: string, routeData: RouteData) => {
  const newSearchParams = { ...routeData.location.query }

  delete newSearchParams[param]

  const params = buildUrlFragment(newSearchParams)

  window.history.replaceState(null, '', window.location.pathname + params)
}
