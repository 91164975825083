// This function creates a custom Redux Toolkit async thunk for use in the application.
// It handles the common behavior of refreshing the Cognito session and setting the
// request headers for every thunk.
import { AsyncThunk, AsyncThunkPayloadCreator, createAsyncThunk } from '@reduxjs/toolkit'
import { getReqHeaders } from '../actions/requestHeaders'
import { ThunkAPIConfig } from './types'

// The createAppThunk function takes a type string and a thunk payload creator.
// It returns a new async thunk with the common behavior applied.
export const createAppThunk = <Returned, ThunkArg = any>(
  type: string,
  thunk: AsyncThunkPayloadCreator<Returned, ThunkArg, ThunkAPIConfig>
): AsyncThunk<Returned, ThunkArg, ThunkAPIConfig> => {
  return createAsyncThunk<Returned, ThunkArg, ThunkAPIConfig>(
    type,
    async (params, config) => {
      try {
        const state = config.getState()

        // Get the request headers using the current state
        const requestConfig = {
          headers: getReqHeaders(state),
        }

        // Ensure the extra object exists and set the requestConfig property
        config.extra = config.extra || {}
        config.extra.requestConfig = requestConfig
        // Call the original thunk payload creator with the updated config
        return await thunk(params, config) as Returned
      } catch (err) {
        // If an error occurs, reject the action with a null value
        return config.rejectWithValue(null)
      }
    }
  )
}

export default createAppThunk
