import isString from 'lodash/isString'

export const textCompare = (locale) => (a, b) => {
  if (Intl.Collator) {
    const intl = new Intl.Collator(locale)
    return intl.compare(a, b)
  } else if (String.prototype.localeCompare && isString(a) && isString(b)) {
    return a.localeCompare(b, locale)
  } else {
    if (a > b) {
      return 1
    }
    if (a < b) {
      return -1
    }
    return 0
  }
}
