import React from 'react'
import { css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { FormattedMessage } from 'react-intl'
import { COLORS, HBox, HIcon, HTypography } from 'talent-ui'
import { CheckmarkFilled16 } from '@carbon/icons-react'
import { ProfileCompletedAwardIcon } from 'talent-ui/src/HAssets/ProfileCompletedAwardIcon'

const sparkleAnimation = keyframes`
  0%, 100% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(0.8);
    opacity: 0.5;
  }`

const animatedStyle = css`
  min-width: 44px;

  .profile-completed-award_svg {
    &__sparkle-top-left {
      transform: scale(1);
      transform-box: fill-box;
      transform-origin: center;
      animation: ${sparkleAnimation} 1.2s ease-in-out 0ms 4;
    }

    &__sparkle-top-right {
      transform: scale(1);
      transform-box: fill-box;
      transform-origin: center;
      animation: ${sparkleAnimation} 1.2s ease-in-out 200ms 4;
    }

    &__sparkle-bottom-right {
      transform: scale(1);
      transform-box: fill-box;
      transform-origin: center;
      animation: ${sparkleAnimation} 1.2s ease-in-out 400ms 4;
    }

    &__sparkle-bottom-left {
      transform: scale(1);
      transform-box: fill-box;
      transform-origin: center;
      animation: ${sparkleAnimation} 1.2s ease-in-out 600ms 4;
    }
  }
`

const AnimatedProfileCompletedAward = styled(ProfileCompletedAwardIcon)`
  ${animatedStyle}
`

const ProfileCompleted = () => (
  <HBox
    data-test-id='profile-completed-award'
    display='flex'
    alignItems='flex-start'
    padding={4}
    flexDirection={{ xs: 'row', lg: 'column' }}
    borderRadius={1}
    sx={{
      backgroundColor: COLORS.support_success_00,
      gap: 4,
    }}
  >
    <AnimatedProfileCompletedAward />
    <HBox
      display='flex'
      flexDirection='column'
      alignItems='flex-start'
      align-self='stretch'
      sx={{ gap: 2 }}
    >
      <HBox
        alignItems='center'
        display='flex'
      >
        <HTypography
          variant='h5'
          weight='medium'
          color='support_success_50'
        >
          <FormattedMessage id='complete_your_profile_completed_title' />
        </HTypography>
        <HBox
          paddingLeft={2}
        >
          <HIcon
            name='check'
            size={18}
            color='support_success_30'
            Icon={CheckmarkFilled16}
          />
        </HBox>
      </HBox>
      <HTypography
        variant='body1'
        weight='regular'
        color='surface_gray_90'
      >
        <FormattedMessage id='complete_your_profile_completed_body' />
      </HTypography>
    </HBox>
  </HBox>
)

export default ProfileCompleted
