import { datadogRum } from '@datadog/browser-rum'
import { type Config } from '@/config'

const getTracingUrls = (config: Config) => {
  const ownUrl = window.location.protocol + '//' + window.location.host
  return [ownUrl, config.environment.apiUrl]
}

export const configureDatadogRum = (config: Config) => {
  if (typeof window !== 'undefined') {
    const { enableRum, clientToken } = config.datadog

    if (enableRum && clientToken) {
      datadogRum.init({
        applicationId: 'fb973cc3-550b-4b61-b5ba-c3979bd33627',
        clientToken,
        site: 'datadoghq.com',
        service: 'jobseeker-portal-browser',
        env: config.environment.environment,
        version: config.datadog.version,
        sessionSampleRate: 3,
        sessionReplaySampleRate: 1,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
        allowedTracingUrls: getTracingUrls(config),
        enableExperimentalFeatures: ['feature_flags'],
      })
    }
  }
}
