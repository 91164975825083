import { ChevronLeft16, ChevronRight16 } from '@carbon/icons-react'
import React from 'react'
import styled from '@emotion/styled'
import clsx from 'clsx'
import { UsePaginationItem } from '@mui/material/usePagination'
import { HButton, HIconButton } from '../HButton'
import { COLORS } from '../theme/colors'
import { HBox } from '../HBox/HBox'

export interface PaginationItemProps extends UsePaginationItem {
  makeUrl?: (page: number) => string
  component?: React.ElementType
  componentProps?: {
    shallow?: boolean
    locale?: boolean
    navigationButtonLabels?: {
      previous: string
      next: string
    }
  }
}

const SmallPageButton = styled(HButton)`
  max-height: 32px;
  padding: 4px 11px 4px 12px;
  border-radius: 4px;
  &:hover {
    background-color: ${COLORS.surface_gray_10};
  }
  &.page-selected {
    background-color: ${COLORS.surface_gray_05};
    &:hover {
      background-color: ${COLORS.surface_gray_10};
    }
  }
`

export const PaginationItem: React.FC<PaginationItemProps> = ({
  page,
  type,
  makeUrl,
  selected,
  component,
  componentProps,
  ...others
}: PaginationItemProps) => {
  const buttonProps: any = {
    ...others,
    color: 'neutral',
    variant: 'text',
  }

  if (!selected && makeUrl && page) {
    buttonProps.href = makeUrl(page)
    buttonProps.component = component
  }

  let children: React.ReactElement | null = null

  if (type === 'start-ellipsis' || type === 'end-ellipsis') {
    children = <HBox mr={2}>...</HBox>
  } else if (type === 'previous') {
    children = (
      <HBox mr={3}>
        <HIconButton
          data-test-id='previous-page'
          aria-label={componentProps?.navigationButtonLabels?.previous}
          {...buttonProps}
          {...componentProps}
        >
          <ChevronLeft16 />
        </HIconButton>
      </HBox>
    )
  } else if (type === 'next') {
    children = (
      <HBox ml={2}>
        <HIconButton
          data-test-id='next-page'
          aria-label={componentProps?.navigationButtonLabels?.next}
          {...buttonProps}
          {...componentProps}
        >
          <ChevronRight16 />
        </HIconButton>
      </HBox>
    )
  } else {
    children = (
      <HBox mr={2}>
        <SmallPageButton
          {...buttonProps}
          {...componentProps}
          className={clsx(selected && 'page-selected')}
          text={page}
        ></SmallPageButton>
      </HBox>
    )
  }

  return <>{children}</>
}
