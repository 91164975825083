// @flow

import API from '../clientBuilder'
import type { APIRequestConfig } from '../types'
import type { JobSearchRequestOptions } from '../../../actions/api/clients/search'

export type JobAlertSearchQueriesPayload = Array<JobSearchRequestOptions>

const API_PATH = '/v2/talent_platform/job_alert_search_queries'

export default {
  get: (config: APIRequestConfig) => API.get.promise(API_PATH, null, config),
  create: (options: JobSearchRequestOptions, config: APIRequestConfig) => API.post.promise(API_PATH, options, config),
  delete: (uid: string, config: APIRequestConfig) => API.delete.promise(`${API_PATH}/${uid}`, null, config),
}
