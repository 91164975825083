// @flow

import * as actions from '../constants/actions'
import { PENDING, FULFILLED, REJECTED } from './promiseUtil'
import type { Company } from '../types/company'

export type CompanyState = {
  current: ?Company,
  loading: bool
}

const initialState = {
  current: null,
  loading: false,
}

const reducer = (state: CompanyState = initialState, action: $FlowTODO): CompanyState => {
  switch (action.type) {
    case FULFILLED(actions.FETCH_COMPANY):
      return {
        ...state,
        current: action.payload,
        loading: false,
      }

    case PENDING(actions.FETCH_COMPANY):
      return {
        ...state,
        current: initialState.current,
        loading: true,
      }

    case REJECTED(actions.FETCH_COMPANY):
      return {
        ...state,
        current: initialState.current,
        loading: false,
      }
    default:
      return state
  }
}

export default reducer
