import { setItem, removeItem, getItem, identifiers } from '../../../../helpers/localStorageUtils'

export const cleanHiringBonusExperimentStorage = () => {
  removeItem(identifiers.JOB_IDS_IN_HIRING_BONUS_EXPERIMENT)
}

const getHiringBonusExperimentStorage = () => {
  try {
    const storageExperimentMap = getItem(identifiers.JOB_IDS_IN_HIRING_BONUS_EXPERIMENT)
    return storageExperimentMap ? JSON.parse(storageExperimentMap) : []
  } catch (error) {
    return []
  }
}

export const collectJobIdForHiringBonusExperimentRecord = (jobId: string) => {
  const jobIdsInHiringBonusExperiment = getHiringBonusExperimentStorage()
  const uniqueJobIds = new Set([...jobIdsInHiringBonusExperiment, jobId])
  setItem(identifiers.JOB_IDS_IN_HIRING_BONUS_EXPERIMENT, JSON.stringify(Array.from(uniqueJobIds)))
}

export const removeJobIdFromHiringBonusExperimentStorage = (jobId: string) => {
  const jobIdsInHiringBonusExperiment = getHiringBonusExperimentStorage()
  setItem(identifiers.JOB_IDS_IN_HIRING_BONUS_EXPERIMENT, JSON.stringify(
    jobIdsInHiringBonusExperiment.filter((id: string) => id !== jobId)
  ))
}

export const isJobInHiringBonusExperiment = (jobId: string) => {
  const jobIdsInHiringBonusExperiment = getHiringBonusExperimentStorage()
  return jobIdsInHiringBonusExperiment.includes(jobId)
}
