import { v4 as uuid } from 'uuid'
import { fireSnowplowEvent } from '../../../../tracking/external/trackingClient'
import { AUTOSUGGEST_CLICK_EVENT } from '../../../../tracking/eventNames'
import logger from '../../../../../logging'
import { SearchSuggestionItem } from '../types'
import { updateTrackingData } from './utils'
import { NormalizedMapsPrediction } from 'location-utils'

enum AutoSuggestionType {
  KEYWORD='keyword',
  LOCATION='location'
}
const log = logger('search-suggestion-tracking')

export const autoSuggestionKeywordClick = (
  suggestion:string,
  suggestionList:SearchSuggestionItem[],
  sourcePage:string) => {
  const keywordSuggestionId = uuid()
  const position = suggestionList.findIndex((option) => option?.suggestion === suggestion) + 1
  const totalCount = suggestionList.length

  if (position === 0 || position > totalCount) {
    log.error('Invalid clicked Suggestion Data')
    return
  }

  updateTrackingData({ keywordSuggestionId, sourcePage })

  fireSnowplowEvent(AUTOSUGGEST_CLICK_EVENT, {
    autosuggest_clicked_value: suggestion,
    clicked_suggest_position: position,
    total_suggest_count: totalCount,
    autosuggestion_id: keywordSuggestionId,
    autosuggestion_type: AutoSuggestionType.KEYWORD,
  })
}

export const autoSuggestionLocationClick = (
  suggestion: NormalizedMapsPrediction,
  suggestionList: NormalizedMapsPrediction[],
  sourcePage:string) => {
  const locationSuggestionId = uuid()
  const positionIndex = suggestionList.findIndex((option) => option?.description === suggestion?.description)
  const totalCount = suggestionList.length

  if (positionIndex === -1 || positionIndex === totalCount) {
    log.error('Invalid clicked Suggestion Data')
    return
  }

  updateTrackingData({ locationSuggestionId, sourcePage })

  fireSnowplowEvent(AUTOSUGGEST_CLICK_EVENT, {
    autosuggest_clicked_value: suggestion.description,
    clicked_suggest_position: positionIndex + 1, // 1-based index
    total_suggest_count: totalCount,
    autosuggestion_id: locationSuggestionId,
    autosuggestion_type: AutoSuggestionType.LOCATION,
  })
}
