import React, { ReactElement } from 'react'
import { BreadcrumbsProps, SingleBreadcrumbProps } from './HMainFooter.types'
import { HTypography } from '../../HTypography'
import { HBox } from '../../HBox'

interface BreadcrumbProps {
  breadcrumb: SingleBreadcrumbProps
  isLast: boolean
  index: number
}

const Breadcrumb = (
  { breadcrumb, isLast, index }: BreadcrumbProps): ReactElement => {
  const { label, href } = breadcrumb

  const testId = `breadcrumb-link-${index + 1}`

  return (
    <React.Fragment key={label}>
      {href
        ? (
          <HTypography
            target='_blank'
            rel={`noopener${breadcrumb.hasNoFollowTag ? ' nofollow' : ''}`}
            href={href}
            variant='body2'
            component='a'
            data-test-id={testId}
            color='surface_gray_80'
            space={{ py: 1 }}
            selectors={
              [{
                selector: ':hover, :focus',
                props: {
                  underline: true,
                },
              }]
            }
          >
            {label}
          </HTypography>
        )
        : <span data-test-id={testId}>{label}</span>
      }
      {!isLast && <span>{' • '}</span>}
    </React.Fragment>
  )
}

/**
 * Breadcumbs section for SERP and JDP pages in the footer.
 * It's for SEO purposes.
 */
export const Breadcrumbs = ({
  breadcrumbs,
}: BreadcrumbsProps): ReactElement | null => {
  if (!breadcrumbs) return null

  return (
    <HBox mb={8}>
      <HTypography variant='body2' color='surface_gray_80'>
        {breadcrumbs.map((breadcrumb, index) => (
          <Breadcrumb
            key={breadcrumb.label}
            breadcrumb={breadcrumb}
            index={index}
            isLast={index === breadcrumbs.length - 1}
          />
        ),
        )}
      </HTypography>
    </HBox>
  )
}
