import React, { PropsWithChildren, useState, useRef, useEffect } from 'react'
import styled from '@emotion/styled'

interface ClampedTextProps {
  lineClamp: number
  dangerouslySetInnerHTML?: {
    __html: string
  }
  suffixElement?: React.ReactNode
}

const ClampedTextWrapper = styled.span`
  position: relative;
  display: block;
`

const ClampedText = styled.span<{ lineClamp: number, isClamped: boolean }>`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${({ lineClamp }) => lineClamp};
  display: ${({ isClamped }) => isClamped ? '-webkit-box' : 'inline'};
  overflow: hidden;
`
const SuffixElementWrapper = styled.span<{ isClamped: boolean }>`
  white-space: nowrap;
  position: ${({ isClamped }) => isClamped ? 'absolute' : 'relative'};
  bottom: ${({ isClamped }) => isClamped ? '0' : 'auto'};
  right: ${({ isClamped }) => isClamped ? '0' : 'auto'};
  background-color: ${({ isClamped }) => isClamped ? 'white' : 'transparent'};
  padding: 0;
`

export const HClampedText: React.FC<PropsWithChildren<ClampedTextProps>> = ({
  children, dangerouslySetInnerHTML, lineClamp, suffixElement,
}) => {
  const [isClamped, setIsClamped] = useState<boolean>(true)
  const [isViewInitialized, setIsViewInitialized] = useState<boolean>(false)
  const ref = useRef<HTMLParagraphElement>(null)

  useEffect(() => {
    if (ref.current) {
      const isElementClamped = (
        ref.current.scrollHeight > ref.current.clientHeight
      )
      setIsClamped(isElementClamped)

      setIsViewInitialized(true)
    }
  }, [lineClamp, ref.current])

  return (
    <ClampedTextWrapper>
      <ClampedText
        lineClamp={lineClamp}
        ref={ref}
        isClamped={isClamped}
        dangerouslySetInnerHTML={dangerouslySetInnerHTML}
      >
        {children}
      </ClampedText>
      {isViewInitialized && suffixElement && (
        <SuffixElementWrapper isClamped={isClamped}>
          {'... '}
          {suffixElement}
        </SuffixElementWrapper>
      )}
    </ClampedTextWrapper>
  )
}
