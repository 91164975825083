import { type Store } from '../store/configureStore'

// StoreProxy is neccessary so that we can create an instance of Growthbook
// while also setting its onFeatureUsage handler with a reference to a store
// setting onFeatureUsage after Growthbook instantiation doesn't work
export class StoreProxy {
  store: Store | undefined

  setStore (store: Store) {
    this.store = store
  }

  dispatch (action: any) {
    this.store?.dispatch(action)
  }

  getState () {
    return this.store?.getState()
  }
}
