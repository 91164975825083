/* istanbul ignore file */
import * as React from 'react'
import { useState } from 'react'
import { HBox, HButton, HDivider, HTypography } from 'talent-ui'
import flatten from './flatten'

interface DebugKeyValueItemProps {
  name: string
  value: any
}

const DebugKeyValueItem: React.FC<DebugKeyValueItemProps> = ({
  name,
  value,
}) => {
  const serializedValue =
    typeof value !== 'string' && value !== null ? JSON.stringify(value) : value

  const [isCopyButtonVisible, setIsCopyButtonVisible] = useState(false)
  const [isCopied, setIsCopied] = useState(false)

  const onClickCopy = (): void => {
    void (async () => {
      await navigator.clipboard.writeText(serializedValue)
      setIsCopied(true)
      setTimeout(() => {
        setIsCopied(false)
      }, 1500)
    })()
  }

  return (
    <>
      <HBox
        onMouseEnter={() => { setIsCopyButtonVisible(true) }}
        onMouseLeave={() => { setIsCopyButtonVisible(false) }}
        style={{ position: 'relative' }}
      >
        <HBox
          style={{ padding: 8, paddingLeft: 16 }}
        >
          <HTypography
            variant='caption'
            color='surface_gray_80'
          >{name}
          </HTypography>
          <HTypography
            weight='semibold'
            variant='body2'
            style={{ wordBreak: 'break-all' }}
          >
            {serializedValue || <span>none</span>}
          </HTypography>
        </HBox>

        {isCopyButtonVisible && (
          <HBox
            display='flex'
            alignItems='center'
            style={{ position: 'absolute', height: '100%', top: 0, right: 8 }}
          >
            <HButton
              color='neutral'
              onClick={onClickCopy}
              variant='contained'
            >
              {isCopied ? 'Copied!' : 'Copy'}
            </HButton>
          </HBox>
        )}
      </HBox>
      <HDivider />
    </>
  )
}

interface DebugKeyValueInfoProps {
  debugObject: any
}

export const DebugKeyValueInfo: React.FC<DebugKeyValueInfoProps> = ({
  debugObject,
}) => {
  const pairs = Object.entries(flatten(debugObject, 2))
  return (
    <div>
      {pairs.map(([key, value]) => (
        <DebugKeyValueItem
          key={key}
          name={key}
          value={value}
        />
      ))}
    </div>
  )
}
