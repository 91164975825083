import React from 'react'
import { HTypography } from '../HTypography/HTypography'
import { HSearchAutocompleteOption } from './types'
import styled from '@emotion/styled'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { HIcon } from '../HIcon'
import { HBox } from '../HBox'

const AutocompleteOption = styled.li`
  padding: ${({ theme }) => theme.spacing(2, 4)} !important;
`

const StyledHBox = styled(HBox)`
  img {
      width: ${({ theme }) => theme.spacing(6)};
      height: ${({ theme }) => theme.spacing(6)};
    }
`

export function SearchAutocompleteOption (
  {
    // We don't want to pass className to the AutocompleteOption
    props: { className, ...props },
    option,
    state: { inputValue },
    showSecondaryText = false,
  }: {
    props: React.HTMLAttributes<HTMLLIElement>
    option: HSearchAutocompleteOption
    state: { inputValue: string }
    showSecondaryText?: boolean
  }): JSX.Element {
  const matched = match(option.suggestion, inputValue)
  const parts = parse(option.suggestion, matched)

  return (
    <AutocompleteOption {...props}>
      <StyledHBox
        display='flex'
        alignItems='center'
        gap={8}
      >
        {option.Icon && (
          option.isPopularSearch
            ? option.Icon
            : <HIcon Icon={option.Icon} color='surface_gray_80'/>)
        }
        <HTypography
          data-test-id='search-autocomplete-option'
          component='span'
          variant='body2'
          weight='regular'
          color='surface_gray_80'
          truncated
          translate="no"
          selectors={[
            {
              selector: 'span.bold',
              props: {
                weight: 'semibold',
              },
            },
          ]}
        >
          {parts.map((part, index) => (
            <React.Fragment key={index}>
              {inputValue && !part.highlight
                ? <span className='bold'>{part.text}</span>
                : <span>{part.text}</span>
              }
            </React.Fragment>
          ))}
          {showSecondaryText && Boolean(option.secondaryText) &&
            <span>, ${option.secondaryText}</span>}
        </HTypography>
      </StyledHBox>

    </AutocompleteOption>
  )
}
