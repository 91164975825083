import { useEffect } from 'react'
import { configureBraze, subscribeToInAppMessages } from '.'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { USER_CENTRIC_SERVICES } from '../../../cookieConsent/userCentricServices'
import { disableSDK, enableSDK } from '@braze/web-sdk'
import logger from '../../../../logging'
import { BrazeQueueManager } from './braze-queue'

const log = logger('braze-sdk')

const debugEvents: any[] = []

export const initBraze = async (hasBrazeConsent: boolean) => {
  if (typeof window !== 'undefined') {
    // @ts-expect-error Always add debugEvents to window.__heyjobs_braze since it's used by Cypress
    window.__heyjobs_braze = {
      debugEvents,
    }
  }

  // Do not disable SDK in Cypress for e2e tests to work correctly
  // FIXME(TPRE-2195): avoid disabling SDK in Cypress, rather mock consents properly
  if (!('Cypress' in window) && !hasBrazeConsent) {
    disableSDK()
    return
  }

  enableSDK()

  const initialized = await configureBraze()

  if (initialized) {
    BrazeQueueManager.getInstance().processQueue()
  }

  if (!('Cypress' in window) && initialized) {
    await subscribeToInAppMessages()
  }
}

// FIXME(TPRE-2190): move all initialization logic to one place and consider moving this to ServiceLoaderWithConsent
export const BrazeLoader = () => {
  const hasBrazeConsent = useAppSelector(state => state.cookieConsent.services[USER_CENTRIC_SERVICES.BRAZE])

  useEffect(() => {
    initBraze(hasBrazeConsent)
      .catch(error => {
        log.error('Error initializing Braze',
          { datadogContext: { message: error.message } }, error)
      })
  }, [hasBrazeConsent])

  return null
}
