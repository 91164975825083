/* istanbul ignore file */

import React from 'react'
import * as Sentry from '@sentry/browser'
import { HBox, HButton, HDivider } from 'talent-ui'

export const DebugReportModalButton = ({ closeDrawer }: { closeDrawer: () => void }) => {
  const openReportModal = () => {
    closeDrawer()

    const eventId = Sentry.lastEventId() ?? Sentry.captureEvent({
      message: 'triggered_user_feedback_event',
      level: 'log',
    })

    Sentry.showReportDialog({ eventId })
  }

  return (
    <>
      <HBox
        py={2}
        display='flex'
        justifyContent='center'
      >
        <HButton
          onClick={openReportModal}
          fullWidth
          variant='text'
        >
          Log error report to Sentry
        </HButton>
      </HBox>
      <HDivider />
    </>
  )
}
