// @flow
import moment from 'moment'
import { parsePhoneNumberFromString } from 'libphonenumber-js/max'
import isString from 'lodash/isString'
import isEmpty from 'lodash/isEmpty'
import {
  ESC_KEY_CODE,
  DEFAULT_COUNTRY_CODE,
  PHONE_NUMBER_FORMAT_API,
  AVAILABLE_COUNTRY_OPTIONS,
} from '../../constants/base'
import { type Recruiter } from '../../types/user'
import { type Company } from '../../types/company'
/**
 * Used for creating an object where
 * multiple keys with the same value,
 * where the last value passed will
 * be the set value for the keys
 * @param keys
 * @returns {*}
 * @example
 * values('a', 'b', 'c', 2)
 * // -> { a: 2, b: 2, c: 2 }
 */
export const values = (...keys: Array<string>) => {
  const value = keys.pop()
  return keys.reduce((result, key) => ({ ...result, [key]: value }), {})
}

// 02.2010 -> 1264978800000
// 03.10.2020 -> 1601676000000
export const dateStringToMs = (dateString: string) => {
  if (!isString(dateString)) {
    return undefined
  }

  const format = (dateString.length > 7 ? 'DD.' : '') + 'MM.YYYY'
  return moment.utc(dateString, format).unix() * 1000
}

const length_ = (operand) => (length: number) => (text: string) => {
  return eval(`${text.length} ${operand} ${length}`) // eslint-disable-line
}

export const length = {
  eq: length_('==='),
  gte: length_('>='),
  gt: length_('>'),
  lt: length_('<'),
  lte: length_('<='),
}

export const onlyNumbers = (value: string): string => {
  return value.replace(/[^0-9]/g, '')
}

export const toNumbersArray = (stringWithNumbers: string): Array<number> => {
  return onlyNumbers(stringWithNumbers)
    .split('')
    .map(Number)
}

export const isEven = (value: number) => {
  return value % 2 === 0
}

// (prop: string, data: Array<T>) -> Map<prop, T>
export const normalize = (prop: string, data: Array<any>) => {
  return data.reduce(
    (normalized, element) => ({ ...normalized, [element[prop]]: element }),
    {}
  )
}

export const input = (initialValue: string): Object => ({
  value: initialValue || '',
  errors: [],
  focused: false,
  disabled: false,
})

export const file = ({ isCv, uploaded = false }: Object): Object => {
  return {
    id: null,
    name: '',
    errors: [],
    is_cv: isCv,
    uploading: false,
    deleting: false,
    uploaded,
    url: '',
    updated_at: '',
  }
}

export const pressedEsc = (event: any): bool => {
  return event.keyCode === ESC_KEY_CODE
}

export const removeWhitespace = (str: string): string =>
  str.replace(/^\s+|\s+$/g, '')

export const getFirstLastNamesFromFullName = (
  fullName: string = ''
): {firstName: string, lastName: string} => {
  const trimmed = fullName.trim()
  const words = trimmed.split(' ').filter((w) => w.length > 0)

  return {
    firstName: words[0] || '',
    lastName: words.slice(1).join(' '),
  }
}

/**
 * Used for converting color HEX codes to RGBA
 * @param {String} hex
 * @param {Number} opacity
 * @returns {String}
 * @example
 * fullHexToRgba('#000000', 0.9)
 * // -> rgba(0, 0, 0, 0.9)
 */

export const fullHexToRgba = (hex: string, opacity: number = 1): string => {
  const code = hex.replace('#', '')

  if (code.length < 6) {
    return 'rgba(255, 0, 0, 1)' // (red) so you see it!
  }
  const r = parseInt(code.substring(0, 2), 16)
  const g = parseInt(code.substring(2, 4), 16)
  const b = parseInt(code.substring(4, 6), 16)

  return `rgba(${r}, ${g}, ${b}, ${opacity})`
}

/**
 * Used for lightening and darkening colors
 * @param {String} color
 * @param {Number} amount
 * @returns {String}
 * @example
 * adjustColor('#edad2f', -40)
 * // -> #c58507
 */
export const adjustColor = (color: string, amount: number) => {
  return '#' + color.replace(/^#/, '').replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2))
}

/**
 * Used to determine if color is light or dark
 * @param {String} hex color
 * @example
 * isDarkColor('#000')
 * // -> true
 */
export const isDarkColor = (color: string): bool => {
  const hexColor = +('0x' + color.slice(1).replace(color.length < 5 ? /./g : '', '$&$&'))

  const r = hexColor >> 16
  const g = hexColor >> 8 & 255
  const b = hexColor & 255

  // HSP equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(
    0.299 * (r * r) +
    0.587 * (g * g) +
    0.114 * (b * b)
  )

  // Using the HSP value, determine whether the color is light or dark
  return hsp < 127.5
}

/** Utility for passing props to styled component
 * The method will return just the props in list, otherwise will return
 * an empty object.
 */

const pickByKeys = (keys: Array<string>, props: Object): Object => {
  const result = {}

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i]
    if (props[key]) {
      result[key] = props[key]
    }
  }

  return result
}
export const onlyProps = (list: Array<string>, props: Object): Object =>
  isEmpty(list) || isEmpty(props) ? {} : pickByKeys(list, props)

export const toKebabCase = (text: string): string =>
  text
    .replace(/(\n|\r)/g, '')
    .replace(/\s/g, (match, index) => index === 0 ? '' : '-')
    .replace(/[A-Z]/g, (match, index) => match.toLowerCase())

export const preparePhoneNumberForSubmission = (phoneNumber: string, countryCode: string = DEFAULT_COUNTRY_CODE): string => (
  parsePhoneNumberFromString(phoneNumber, countryCode)
    .format(PHONE_NUMBER_FORMAT_API)
)

export const findCompanyName = (company: Company): string => (
  company.use_parent_company_name && company.parent
    ? company.parent.name
    : company.name
)

export const getFullName = (user: Recruiter) => [user?.first_name, user?.last_name].filter(n => !!n).join(' ')

export const isInternalLink = (link: string): bool => !(link.match(/(?:https?)?:?\/\//) !== null)

export const isAvailableCountry = (country: string) => Object.values(AVAILABLE_COUNTRY_OPTIONS).includes(country.toLowerCase())
