// @flow

export const EMPTY_TRACKING_SOURCE = ''

// Application flow
export const CONVERSATIONAL_APPLICATION_FLOW_SOURCE = 'conversational_application_flow'
export const JOB_APPLICATION_ALREADY_REGISTERED_SOURCE = 'job_application_already_registered'

// Recommendation cards
export const SIMILAR_JOBS_RECOMMENDATION_CARD_SOURCE = 'similar_jobs_recommendation_card'
export const JOB_SEARCH_NO_RESULTS_RECOMMENDATION_CARD_SOURCE = 'job_search_no_results_recommendation_card'
export const JDP_EXIT_INTENT_MODAL_RECOMMENDATION_CARD_SOURCE = 'jdp_exit_intent_modal_recommendation_card'
export const EMAIL_VERIFICATION_RECOMMENDATION_CARD_SOURCE = 'email_verification_recommendation_card'
export const JOB_RECOMMENDATIONS_EMAIL_VERIFICATION_SOURCE = 'job_recommendations_email_verification'

// Pop ups
export const CRM_OPT_IN_MODAL_SOURCE = 'opt_in_modal'
export const JOB_ALERT_POP_UP_SOURCE = 'job_alert_pop_up'

// Search
export const JOB_SEARCH_PAGE_SOURCE = 'job_search_page'

// Job details
export const SMART_LINKOUT_JOB_SOURCE = 'smart_linkout_job'
export const APPLICATION_FORM_SOURCE = 'job_application_form'
export const SMART_LINK_OUT_ALREADY_REGISTERED_SOURCE = 'smart_link_out_already_registered'

// Profile
export const PROFILE_SOURCE = 'profile'

// Bookmarks
export const BOOKMARK_TOGGLE_SOURCE = 'bookmark_toggle'
export const BOOKMARK_NO_BOOKMARKS_PAGE_SOURCE = 'job_bookmark_no_bookmark_page'

// Authentication
export const AUTH_PAGE_SOURCE = 'auth-page'
export const REGISTRATION_FORM_SOURCE = 'registration_form'
export const GOOGLE_ONE_TAP_SIGN_IN_SOURCE = 'google_one_tap_sign_in'
export const LOGIN_MODAL_SOURCE = 'login-modal'
export const REGISTER_MODAL_SOURCE = 'register-modal'
