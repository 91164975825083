// @flow

// TODO: this mapping can be done directly in the context building
// function for the user.

const getTrackingToken = (state) => state.session.device.tracking_token
const isUserAuthenticated = (state) => state.user.isAuthenticated

export default (state: $FlowTODO) => ({
  user_device_token: getTrackingToken(state),
  is_logged_in: isUserAuthenticated(state),
})
