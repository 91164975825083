// @flow
/* istanbul ignore file */

import * as Sentry from '@sentry/browser'
import find from 'lodash/find'
import logger from '../../../logging'
import { setSentryUser } from '../../../monitoring/sentry'
import * as actions from '../../constants/actions'
import cookies from '../../cookies'
import { postMessageToBroadcastChannel } from '../../helpers/channel/broadcast-channel.ts'
import { PRIVACY_POLICY_VERSION } from '../../pages/Privacy/constants'
import * as braze from '../../tracking/clients/braze'
import { USER_CREATED_EVENT } from '../../tracking/eventNames'
import {
  fireSnowplowEvent, fireSnowplowStructuredEvent, setGTMUserDataForTikTok,
  setUserId as setUserIdForTracking,
} from '../../tracking/external/trackingClient'
import { runningInBrowser } from '../actions/support/base' // TODO: don't use this
import users from '../api/clients/users'
import ApiError from '../api/errors/ApiError'
import { resolveDevice } from '../session'
import * as utils from '../utils/reduxUtils'
import { cognitoLogout, cognitoSignIn } from './cognito'
import { clearCAFlocalData } from '../../components/conversationalApplicationFlow/shared/util.ts'
import { clearUsedAuthFlow } from '../../components/jobApplication/CAF/common/authFlowTracking.ts'
import { clearSeeOnboardingModal, setSeeOnboardingModal } from '../../components/modals/onboarding/seeOnBoardingModal.ts'
import localStorageUtils, { identifiers } from '../../helpers/localStorageUtils'
import {
  AuthOrigins, getPostSignupDataFromSessionStorage,
  removePostSignupDataFromSessionStorage,
} from '../../helpers/postSignupUtils.ts'
import { JOB_SEARCH_PAGE_SOURCE } from '../../tracking/eventSources'
import {
  bookmarkJob,
  createJobAlertSearchQueries,
  createRecommendationConsent,
  updateCurrentUser,
  fetchApplicationDocuments,
  createConsent,
  showSnackBar,
} from '../actionCreators'
import { refetchCurrentJobForUser, fetchCurrentJobOpaquely } from '../job'
import type { User } from '../api/clients/users'
import type { APIRequestConfig } from '../api/types'
import { setAuthStrategy } from '../../store/auth/strategySlice.ts'
import type { ApplicationState } from '../../types/applicationState'
import { getCookieConsentServices } from '../../cookieConsent/misc/getCookieConsentServices.ts'
import { syncGoogleConsentModeSignalsWithBraze } from '../../cookieConsent/misc/google-consent-mode-utils.ts'
import { cleanHiringBonusExperimentStorage } from '../../components/jobDetails/job/HiringBonusTag'
import { VISITED_JOBS } from '../../constants/base'
import { datadogRum } from '@datadog/browser-rum'

export const log = logger('user')

export const tryFetchingUser = async (
  requestConfig: APIRequestConfig
): Promise<?User> => {
  try {
    return await users.current(requestConfig)
  } catch (error) {
    if (error instanceof ApiError) {
      // user couldn't be resolved
      if (find(error.errors, { code: 'user_not_found' })) {
        return null
      }
    }
    throw error
  }
}

const updateUserDependencies = (user) => {
  if (user) {
    if (runningInBrowser) {
      braze.updateBrazeUser(user.id)
      setSentryUser(Sentry, { id: user.id })
      setUserIdForTracking(user.id)
      datadogRum.setUser({ id: user.id })
      setGTMUserDataForTikTok({ email: user.email, phoneNumber: user.phone_number })
    }
  } else {
    if (runningInBrowser) {
      setUserIdForTracking(null)
      datadogRum.clearUser()
      setGTMUserDataForTikTok({ email: null, phoneNumber: null })
    }
  }
}

export const resolveUser = utils.createPromise(
  actions.RESOLVE_USER,
  () => async (requestConfig, dispatch, _, [cookieJar]) => {
    const user = await tryFetchingUser(requestConfig).catch((err) => {
      if (err instanceof ApiError) {
        const criticalError = err.errors.find(err => err.code === 'UserNotFoundException' || err.code === 'critical_auth_error')

        if (criticalError) {
          dispatch(cognitoLogout())

          if (criticalError.code === 'critical_auth_error') {
            dispatch(showSnackBar('criticalAuthError'))
          }
        }
      }

      log.error('resolve user failed', {}, err)

      return null
    })

    updateUserDependencies(user)

    return user
  }
)

export const refreshUser = utils.createPromise(
  actions.REFRESH_USER_OPAQUELY,
  () => async (requestConfig, dispatch, getState) => {
    const currentUserId = getState().user.user?.id
    const user: ?User = await tryFetchingUser(requestConfig)

    if (!user) {
      throw new Error('User refresh failed')
    } else if (user.id !== currentUserId) {
      throw new Error('User refresh failed, user id mismatch')
    }

    return user
  })

// don't export this, use `signUp` below to delegate appropriately
const passwordlessSignUp = utils.createPromise(
  actions.PASSWORDLESS_SIGN_IN,
  (userData) => async (requestConfig) => {
    userData.version = PRIVACY_POLICY_VERSION
    const user = await users.passwordlessSignUp(userData, requestConfig)

    updateUserDependencies(user)
    postMessageToBroadcastChannel({ type: actions.PASSWORDLESS_SIGN_UP, payload: user })

    return user
  }
)

export const signUp = () => passwordlessSignUp
export const signIn = () => cognitoSignIn

export const passwordlessSignIn = utils.createPromise(
  actions.PASSWORDLESS_SIGN_IN,
  (signInToken: string) => async (requestConfig) => {
    const user = await users.passwordlessSignIn(signInToken, requestConfig)

    if (!user?.has_seen_onboarding) {
      setSeeOnboardingModal()
    }

    updateUserDependencies(user)
    postMessageToBroadcastChannel({ type: actions.PASSWORDLESS_SIGN_IN, payload: user })
    return user
  }
)

export const verifyGoogleSignInToken = utils.createPromise(
  actions.VERIFY_GOOGLE_SIGN_IN_TOKEN,
  (options) => async (config) => {
    const user = await users.verifyGoogleSignInToken({ ...options, version: PRIVACY_POLICY_VERSION }, config)

    updateUserDependencies(user)
    postMessageToBroadcastChannel({ type: actions.VERIFY_GOOGLE_SIGN_IN_TOKEN, payload: user })

    return user
  }
)

export const logout = utils.createPromise(
  actions.LOGOUT,
  () => async (requestConfig, dispatch, getState, [cookieJar]) => {
    const state = getState()

    try {
      await users.logout(requestConfig)

      await dispatch(cognitoLogout())

      if (state.currentJob.job?.id) {
        await dispatch(fetchCurrentJobOpaquely(state.currentJob.job.id))
      }
    } catch (error) {
      // we don't care, if logout failed - we still want to let the user log out
      if (state.user.user?.id) {
        log.error(`failed logging out user ${state.user.user.id}`, {
          user: { id: state.user.user.id },
          error,
        })
      }
    } finally {
      cleanUserData(state, cookieJar)

      await dispatch(resolveDevice()) // refetch a new device
    }

    postMessageToBroadcastChannel({ type: actions.LOGOUT })
  }
)

const cleanUserData = (state: ApplicationState, cookieJar: any) => {
  clearUsedAuthFlow()
  clearSeeOnboardingModal()
  clearCAFlocalData()

  cleanUserCookies(state, cookieJar)
  cleanTrackingStorage()

  updateUserDependencies()

  cleanHiringBonusExperimentStorage()
  cleanVisitedJobs()
}

export const deleteProfile = utils.createPromise(
  actions.DELETE_USER_PROFILE,
  (reason) => async (config, dispatch, getState, [cookieJar]) => {
    await users.deleteProfile(reason, config)
    await dispatch(cognitoLogout())

    cleanUserData(getState(), cookieJar)
    braze?.cleanUserInstance?.()

    configureOneTapAutoLogin(cookieJar)

    return true
  }
)

const cleanVisitedJobs = () => {
  localStorageUtils.removeItem(VISITED_JOBS)
}

const cleanTrackingStorage = () => {
  localStorageUtils.removeItem(identifiers.DEVICE_TOKEN)
  localStorageUtils.removeItem(identifiers.TRACKING_TOKEN)
}

const cleanUserCookies = (state: ApplicationState, cookieJar: any) => {
  const domain = cookies.globalize(state.request.host)
  cookies.wide.remove(cookieJar)(cookies.identifiers.DEVICE_TOKEN, {
    domain,
  })
  cookies.wide.remove(cookieJar)(cookies.identifiers.TRACKING_TOKEN, {
    domain,
  })
  cookies.wide.remove(cookieJar)(cookies.identifiers.AB_TESTING_COOKIE, {
    domain,
  })
  cookies.wide.remove(cookieJar)(cookies.identifiers.IS_AUTHENTICATED, {
    domain,
  })
}

export const checkUserExistenceAndSocialAccounts = utils.createPromise(
  actions.COGNITO_CHECK_USER_EXISTENCE,
  (email: string) => async (requestConfig, dispatch) => {
    return await users.checkUserExistenceAndSocialAccounts(email, requestConfig)
  }
)

const handleJobAlertCreation = async (dispatch) => {
  const jobSearchOptions = localStorageUtils.getItem(identifiers.STORED_SEARCH_OPTIONS) ?? null
  if (jobSearchOptions) {
    await dispatch(
      createRecommendationConsent(
        {
          consent_type: 'job_alerts_email',
          consent_value: true,
        },
        {
          source: JOB_SEARCH_PAGE_SOURCE,
        }
      )
    )
    await dispatch(createJobAlertSearchQueries(JSON.parse(jobSearchOptions)))

    fireSnowplowStructuredEvent({
      category: 'job_alerts_search_query_create',
    })
    localStorageUtils.removeItem(identifiers.STORED_SEARCH_OPTIONS)
  }
}

export const handlePostSigninActions = utils.createPromise(actions.POST_SIGNIN_ACTIONS,
  (user: User, refreshApplicationData: boolean = false) => async (requestConfig, dispatch, getState) => {
    const { bookmarkJobId, tracking, shouldOpenOnboardingModal } = getPostSignupDataFromSessionStorage()
    if (bookmarkJobId) {
      await dispatch(bookmarkJob(bookmarkJobId))
    }

    if (shouldOpenOnboardingModal || !user.has_seen_onboarding) {
      setSeeOnboardingModal()
    }

    await handleJobAlertCreation(dispatch)
    await dispatch(setAuthStrategy(tracking?.authLocation))

    if (refreshApplicationData) {
      const state = getState()
      if (state.currentJob.job?.id) {
        await dispatch(refetchCurrentJobForUser(state.currentJob.job.id))
      }
      await dispatch(fetchApplicationDocuments())
    }

    if (
      tracking?.authLocation === 'JDP_RECOMMENDATION_CARD' &&
      !user.consented_job_recommendations
    ) {
      await dispatch(createConsent({
        consent_type: 'job_recommendations',
        consent_value: true,
        event_source: '',
      }))
    }

    removePostSignupDataFromSessionStorage()

    localStorageUtils.removeItem(localStorageUtils.identifiers.UNREGISTERED_JOBSEEKER_ID)
  })

export const handlePostSignupActions = utils.createPromise(actions.POST_SIGNUP_ACTIONS,
  (user, authStrategy, hasPassword = false) => async (requestConfig, dispatch, getState) => {
    const { registrationSource, bookmarkJobId, tracking } = getPostSignupDataFromSessionStorage()
    const state = getState()

    let userFieldsToUpdate: Object = {
      has_password: Boolean(hasPassword),
      signup_source: tracking?.authLocation,
      country_code: state.locality.country.toUpperCase(),
      locale: state.intlData.locale,
    }

    if (bookmarkJobId) {
      await dispatch(bookmarkJob(bookmarkJobId))
    } else if (registrationSource && registrationSource.source) {
      userFieldsToUpdate = {
        ...userFieldsToUpdate,
        source: registrationSource.source,
        job_id: registrationSource.data.jobId,
        keyword: registrationSource.data.keyword,
        location: registrationSource.data.location,
      }
    }

    await handleJobAlertCreation(dispatch)

    if (user.has_to_handle_signup) {
      userFieldsToUpdate.has_to_handle_signup = false
    }

    await dispatch(updateCurrentUser(user.id, userFieldsToUpdate))

    // fire tracking events
    const authService = authStrategy.split('_')[0]
    const isSigningupWithGoogleOneTap = authStrategy === 'google_one_tap'

    const trackingContexts = [{
      key: 'job_search_result_context',
    }]

    if (!isSigningupWithGoogleOneTap) {
      const location = state.routing.route.name
      const source = state.loginModal.source

      trackingContexts.push({
        key: 'auth_strategy_context',
        options: {
          user_id: user.id,
          google_sso: authService === 'google',
          facebook_sso: authService === 'facebook',
          email_pw: authService !== 'google' && authService !== 'facebook',
          auth_origin: tracking?.authLocation === AuthOrigins.CAF ? AuthOrigins.CAF : (source || location),
        },
      })
    }

    fireSnowplowEvent(
      USER_CREATED_EVENT,
      {
        source: tracking?.authLocation,
      },
      trackingContexts
    )

    const UCServices = getCookieConsentServices()
    syncGoogleConsentModeSignalsWithBraze(state.cookieConsent.services, UCServices, true)

    await dispatch(setAuthStrategy(tracking?.authLocation))

    removePostSignupDataFromSessionStorage()

    localStorageUtils.removeItem(localStorageUtils.identifiers.UNREGISTERED_JOBSEEKER_ID)
    setSeeOnboardingModal()
  })

if (typeof window !== 'undefined' && window.Cypress) {
  window.__userActions = {
    logout,
    passwordlessSignIn,
    verifyGoogleSignInToken,
  }
}

const configureOneTapAutoLogin = (cookieJar) => {
  try {
    window.google?.accounts?.id?.disableAutoSelect()
  } catch (error) {
    log.error('Failed to disable auto select Google One Tap', {}, error)
  }

  /*
   * Disable auto login for 2 days
  */
  if ('FederatedCredential' in window) {
    cookies.save(cookieJar)(
      cookies.identifiers.GOOGLE_ONE_TAP_LOGIN,
      '*',
      { expires: new Date(Date.now() + 2 * 24 * 60 * 60 * 1000) }
    )
  }
}
