// @flow

import 'abortcontroller-polyfill'
import { ConnectedRouter } from 'connected-react-router'
import 'intersection-observer'
import moment from 'moment'
import 'moment/locale/de'
import React from 'react'
import Helmet from 'react-helmet'
import type { Cookies } from 'react-cookie'
import { hydrateRoot } from 'react-dom/client'
import { AppContainer } from 'react-hot-loader'
import Loadable from 'react-loadable'
import { connect, Provider } from 'react-redux'
import { ThemeConfig, createThemeInstance } from 'talent-ui'
import { GrowthBook } from '@growthbook/growthbook-react'
import * as actionCreators from '../app/actions/actionCreators'
import * as appActions from '../app/actions/app'
import * as sessionActions from '../app/actions/session'
import * as userActions from '../app/actions/user'
import config from '../config'
import { getExperimentsAssignmentsFromCookies } from './abTestingOld/abTestingCookies'
import DataLoaderContext from './contexts/DataLoaderContext'
import abTestingDefinitions from './abTestingOld/abTestingDefinitions'
import Root from './components/containers/root'
import { externalDataLoaderExecuter } from './components/routerPrerenderMiddleware'
import cookies from './cookies'
import type { Store } from './store/configureStore'
import type { ReactContextsData } from './types/reactContexts'
import type { ApplicationState } from './types/applicationState'
import pageView from '../app/tracking/events/pageview'
import { HeyJobsTC } from '../app/tracking/bundledTrackingClient'
import '../../assets/styles/fontface.css'
import { GrowthBookProvider } from './abTesting/GrowthBookProvider'
import { growthbookVisualEditorConfig } from './scripts'
import * as sessionStorage from './helpers/sessionStorageUtils.ts'
import { isValidGtmSSDebugValue } from './helpers/gtmDebugHelpers.ts'
import type { DataLoaderContextValue } from './contexts/DataLoaderContext'
import { migrateLocalStorageSessionToCookies, limitCognitoCookiesToOneSession, verifyTokenIsValid } from './helpers/cognitoHelper.ts'
import { initSnowplow } from './tracking/clients/snowplow'
import { ThemeProvider } from 'styled-components'
import { ServiceLoaderWithConsent } from './helpers/service-loader-with-consent.ts'
import { BrazeLoader } from './tracking/clients/braze/braze-loader.tsx'

const CustomHelmet = (props) => {
  window.__heyjobs_tc = HeyJobsTC

  const scripts = []
  scripts.push({
    src: `${config.growthbook.visualEditorUrl}`,
    async: true,
  })
  scripts.push({
    type: 'text/javascript',
    innerHTML: growthbookVisualEditorConfig({ userId: props.userId, sessionToken: props.trackingToken }),
  })

  return (
    <Helmet
      script={scripts}
    />
  )
}

const isMobile = false
const useNewBreakpoints = true
const theme = createThemeInstance(isMobile, useNewBreakpoints)

const renderApp = async (
  store: Store,
  history: $FlowTODO,
  cookieJar: Cookies,
  growthbook: GrowthBook,
  reactContextsData: ReactContextsData
) => {
  const hasMigrated = migrateLocalStorageSessionToCookies()
  if (hasMigrated) {
    window.location.reload()
  }

  limitCognitoCookiesToOneSession(cookieJar)
  verifyTokenIsValid(cookieJar)

  // NOTE: rest of the state might be incomplete at this point
  const host = store.getState()?.request?.host
  initSnowplow(cookies.globalize(host))

  // AB Testing
  store.dispatch(actionCreators.initializeAbTestsDefinitions(abTestingDefinitions))

  await Loadable.preloadReady()

  const externalDataLoader: DataLoaderContextValue = {
    current: null,
  }

  const redux = [
    (state: ApplicationState) => ({
      userId: state.user.user?.id,
      trackingToken: state.session.device.tracking_token,
    }),
  ]

  const ConnectedHelmet = connect(...redux)(CustomHelmet)

  // AppContainer is the hot loader provider for development.
  // It does nothing in production where hot loader is not present.
  hydrateRoot(document.getElementById('root'),
    <DataLoaderContext.Provider value={externalDataLoader}>
      <ThemeProvider theme={theme}>
        <ThemeConfig theme={theme}>
          <AppContainer>
            <Provider store={store}>
              <ServiceLoaderWithConsent />
              <BrazeLoader />
              <ConnectedRouter history={history}>
                <ConnectedHelmet />
                <GrowthBookProvider growthbook={growthbook}>
                  <Root
                    store={store}
                    history={history}
                    cookies={cookieJar}
                    growthbook={growthbook}
                    reactContextsData={reactContextsData}
                  />
                </GrowthBookProvider>
              </ConnectedRouter>
            </Provider>
          </AppContainer>
        </ThemeConfig>
      </ThemeProvider>
    </DataLoaderContext.Provider>
  )

  store.dispatch(appActions.setAppInitialized(false))

  // hide the cookie notification bar, if already consented
  if (cookies.load(cookieJar)(cookies.identifiers.COOKIE_CONSENT)) {
    store.dispatch(actionCreators.hideCookieBar())
  }

  await store.dispatch(sessionActions.resolveDevice())
  await store.dispatch(userActions.resolveUser())

  // NOTE: must be after session initialization
  // because A/B tests depend on the device tracking token
  const state = store.getState()

  const trackingToken = state.session.device.tracking_token

  const assignedExperiments = getExperimentsAssignmentsFromCookies(cookieJar)(trackingToken)
  const routeData = state.routing
  const gtmDebug = routeData.location.query.gtm_debug
  if (gtmDebug && typeof gtmDebug === 'string' && isValidGtmSSDebugValue(gtmDebug)) {
    sessionStorage.setItem(sessionStorage.identifiers.gtmDebug, gtmDebug)
  }
  moment.locale(state.intlData.locale)

  for (const experimentAssignment of assignedExperiments) {
    store.dispatch(actionCreators.initializeAbTests(experimentAssignment))
  }

  store.dispatch(appActions.setABTestsInitialized(true))

  if (externalDataLoader.current) {
    const ext = externalDataLoader.current
    await externalDataLoaderExecuter(
      store,
      cookieJar,
      ext.loader,
      routeData,
      history,
      growthbook
    )
  }

  store.dispatch(appActions.setAppInitialized(true))
  pageView(null, null, true)
}

export default renderApp
