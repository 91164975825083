import * as actions from '../constants/actions'
import { PENDING, FULFILLED, REJECTED } from './promiseUtil'
import type { CVShape, ReferencesShape } from '../types/application'
import { generateTemporaryIdForReferenceFile } from '../components/shared/DocumentUpload'

export type DocumentsState = {
  applicationCv: {
    converting: boolean,
    deleting: bool,
    errors: [],
    file: ?CVShape,
    is_converted: boolean,
    uploaded: bool,
    uploading: bool
  },
  applicationDocumentsLoaded: bool,
  applicationDocumentsLoading: bool,
  applicationReferences: {
    converting: boolean,
    errors: [],
    files: ReferencesShape[],
    uploaded: bool,
    uploading: bool
  },
  disableWaitForDocumentConversion: boolean
}

const initialState: DocumentsState = {
  applicationDocumentsLoading: false,
  applicationDocumentsLoaded: false,
  disableWaitForDocumentConversion: false,
  applicationCv: {
    deleting: false,
    errors: [],
    uploading: false,
    uploaded: false,
    file: null,
    is_converted: false,
  },
  applicationReferences: {
    files: [],
    errors: [],
    uploading: false,
    uploaded: false,
  },
}

const initialFileState = (file) => ({
  file: {
    ...file,
    temporaryId: generateTemporaryIdForReferenceFile(file),
  },
  uploading: false,
  deleting: false,
  uploaded: true,
  is_converted: file.is_converted,
  errors: [],
})
const addReferenceFile = (state: DocumentsState, action) => {
  // Using an array of args in meta is probably not great, but at least it's abstracted away to this one place.
  // If we can find a way to have these piped as named params from 'action creator', ahem, creator.. that'd be awesome
  const fileData = action.meta[1]

  return [
    ...state.applicationReferences.files,
    {
      file: fileData,
      uploading: true,
      uploaded: false,
      errors: [],
      is_converted: false,
    },
  ]
}

// FIXME(TPE-2887): this does not work as expected
const mergeReferenceFile = (state, action, merger) => {
  // Using an array of args in meta is probably not great, but at least it's abstracted away to this one place.
  // If we can find a way to have these piped as named params from 'action creator', ahem, creator.. that'd be awesome
  if (!state?.applicationReferences?.files) return []

  const id = action.error ? action.meta[0] : null
  const fileData = action.error ? null : action.meta[1]

  return state.applicationReferences.files.map((candidate) => {
    const shouldMerge = action.error
      ? id === candidate.file?.id
      : fileData?.temporaryId === candidate.file?.temporaryId

    return shouldMerge ? merger(candidate) : candidate
  })
}

const mergeReferenceFileStatuses = (state, action, merger) => {
  // Using an array of args in meta is probably not great, but at least it's abstracted away to this one place.
  // If we can find a way to have these piped as named params from 'action creator', ahem, creator.. that'd be awesome
  const fileId = action.meta[0]
  const newFiles = []
  state.applicationReferences.files.forEach((candidate) => {
    if (fileId === candidate.file?.id) {
      newFiles.push(merger(candidate))
    } else {
      newFiles.push(candidate)
    }
  })

  return newFiles
}

const removeReferenceFile = (state, action) => {
  // Using an array of args in meta is probably not great, but at least it's abstracted away to this one place.
  // If we can find a way to have these piped as named params from 'action creator', ahem, creator.. that'd be awesome
  const fileData = action.meta[0]

  const newFiles = state.applicationReferences.files.filter((candidate, i) => {
    return fileData.file.temporaryId !== candidate.file.temporaryId
  })

  return newFiles
}

const reducer = (state: DocumentsState = initialState, action: any): DocumentsState => {
  switch (action.type) {
    case actions.DISABLE_WAIT_FOR_DOCUMENT_CONVERSION:
      return {
        ...state,
        disableWaitForDocumentConversion: true,
      }
    case actions.ENABLE_DOCUMENT_CONVERSION:
      return {
        ...state,
        disableWaitForDocumentConversion: false,
      }
    case PENDING(actions.FETCH_APPLICATION_DOCUMENTS):
      return {
        ...state,
        applicationDocumentsLoading: true,
        applicationDocumentsLoaded: false,
        applicationCv: initialState.applicationCv,
        applicationReferences: initialState.applicationReferences,
      }
    case FULFILLED(actions.FETCH_APPLICATION_DOCUMENTS): {
      const cv = action.payload.find((file) => !!file.is_cv)
      const references = action.payload
        .filter((file) => !file.is_cv)
        .map(initialFileState)

      return {
        ...state,
        applicationDocumentsLoading: false,
        applicationDocumentsLoaded: true,
        applicationCv: {
          ...state.applicationCv,
          ...(cv && {
            file: cv,
            uploaded: !!cv,
            uploading: false,
            is_converted: cv.is_converted,
          }),
        },
        applicationReferences: {
          ...state.applicationReferences,
          files: references,
          errors: [],
          uploading: false,
          uploaded: !!references?.length,
        },
      }
    }
    case REJECTED(actions.FETCH_APPLICATION_DOCUMENTS):
      return {
        ...state,
        applicationDocumentsLoading: false,
        applicationDocumentsLoaded: false,
        applicationCv: {
          ...state.applicationCv,
          file: null,
          uploading: false,
          uploaded: false,
        },
        applicationReferences: {
          ...state.applicationReferences,
          uploading: false,
          uploaded: false,
        },
      }
    case PENDING(actions.UPLOAD_CV):
      return {
        ...state,
        applicationCv: {
          ...state.applicationCv,
          file: {
            name: action.meta[1].name,
            temporaryId: generateTemporaryIdForReferenceFile(action.meta[1]),
          },
          uploading: true,
          uploaded: false,
          errors: [],
        },
      }
    case FULFILLED(actions.UPLOAD_CV):
      return {
        ...state,
        applicationCv: {
          ...state.applicationCv,
          file: action.payload,
          uploading: false,
          uploaded: true,
          is_converted: false,
          errors: [],
        },
      }
    case REJECTED(actions.UPLOAD_CV):
      return {
        ...state,
        applicationCv: {
          ...state.applicationCv,
          uploading: false,
          uploaded: false,
          errors: [...action.payload.errors],
        },
      }
    case PENDING(actions.REMOVE_CV):
      return {
        ...state,
        applicationCv: {
          ...state.applicationCv,
          deleting: true,
          errors: [],
        },
      }
    case FULFILLED(actions.REMOVE_CV):
      return {
        ...state,
        applicationCv: {
          uploaded: false,
          uploading: false,
          deleting: false,
          file: null,
          errors: [],
        },
      }
    case REJECTED(actions.REMOVE_CV):
      return {
        ...state,
        applicationCv: {
          ...state.applicationCv,
          uploaded: false,
          errors: [],
          deleting: false,
        },
      }
    case PENDING(actions.UPLOAD_REFERENCE):
      return {
        ...state,
        applicationReferences: {
          ...state.applicationReferences,
          uploading: true,
          files: addReferenceFile(state, action),
        },
      }
    case FULFILLED(actions.UPLOAD_REFERENCE):
      return {
        ...state,
        applicationReferences: {
          ...state.applicationReferences,
          files: mergeReferenceFile(state, action, (currentFile) => ({
            errors: [],
            ...currentFile,
            file: {
              ...action.payload,
              temporaryId: generateTemporaryIdForReferenceFile(action.payload),
            },
            uploading: false,
            uploaded: true,
            is_converted: false,
          })),
          uploading: false,
          uploaded: true,
          failed: false,
        },
      }
    case REJECTED(actions.UPLOAD_REFERENCE): {
      return {
        ...state,
        applicationReferences: {
          ...state.applicationReferences,
          files: mergeReferenceFile(state, action, (currentFile) => ({
            ...currentFile,
            errors: [...action.payload.errors],
            uploading: false,
            uploaded: false,
            failed: true,
          })),
          uploading: false,
          uploaded: false,
          failed: true,
        },
      }
    }
    case PENDING(actions.REMOVE_REFERENCE):
      return {
        ...state,
        applicationReferences: {
          ...state.applicationReferences,
          files: mergeReferenceFile(state, action, (currentFile) => ({
            ...currentFile,
            deleting: true,
          })),
        },
      }
    case FULFILLED(actions.REMOVE_REFERENCE):
      return {
        ...state,
        applicationReferences: {
          ...state.applicationReferences,
          files: removeReferenceFile(state, action),
        },
      }
    case REJECTED(actions.REMOVE_REFERENCE):
      return {
        ...state,
        applicationReferences: {
          ...state.applicationReferences,
          files: mergeReferenceFile(state, action, (currentFile) => ({
            ...currentFile,
            deleting: false,
          })),
        },
      }
    case PENDING(actions.POLL_CV_CONVERSION):
      return {
        ...state,
        applicationCv: {
          ...state.applicationCv,
          converting: true,
          errors: [],
        },
      }
    case FULFILLED(actions.POLL_CV_CONVERSION):
      return {
        ...state,
        applicationCv: {
          ...state.applicationCv,
          converting: !action.payload.is_converted,
          is_converted: action.payload.is_converted,
          errors: [],
        },
      }
    case REJECTED(actions.POLL_CV_CONVERSION):
      return {
        ...state,
        applicationCv: {
          ...state.applicationCv,
          converting: false,
          errors: [...action.payload.errors],
        },
      }
    case PENDING(actions.POLL_REFERENCE_CONVERSION):
      return {
        ...state,
        applicationReferences: {
          ...state.applicationReferences,
          files: mergeReferenceFileStatuses(state, action, (currentFile) => ({
            ...currentFile,
            errors: [],
            converting: true,
          })),
        },
      }
    case FULFILLED(actions.POLL_REFERENCE_CONVERSION):
      return {
        ...state,
        applicationReferences: {
          ...state.applicationReferences,
          files: mergeReferenceFileStatuses(state, action, (currentFile) => ({
            ...currentFile,
            errors: [],
            converting: !action.payload.is_converted,
            is_converted: action.payload.is_converted,
          })),
        },
      }
    case REJECTED(actions.POLL_REFERENCE_CONVERSION):
      return {
        ...state,
        applicationReferences: {
          ...state.applicationReferences,
          files: mergeReferenceFile(state, action, (currentFile) => ({
            ...currentFile,
            errors: [...action.payload.errors],
            converting: false,
          })),
        },
      }
    case PENDING(actions.LOGOUT):
      return {
        ...state,
        applicationCv: initialState.applicationCv,
        applicationReferences: initialState.applicationReferences,
      }

    default:
      return state
  }
}

export default reducer
