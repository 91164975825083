import { styled } from '@mui/material/styles'
import { COLORS } from '../theme/colors'

const PREFIX = 'MuiSwitch'

export const classes = {
  container: `${PREFIX}-container`,
  root: `${PREFIX}-root`,
  root_hovered: `${PREFIX}-root_hovered`,
  switchBase: `${PREFIX}-switchBase`,
  switchBaseHovered: `${PREFIX}-switchBaseHovered`,
  thumb: `${PREFIX}-thumb`,
  track: `${PREFIX}-track`,
  checked: `${PREFIX}-checked`,
}

export const SwitchContainer = styled('div')({
  [`& .${classes.container}`]: {
    padding: 0,
    width: 'fit-content',
    height: 18,
    '&:focus': {
      outline: `1px solid ${COLORS.primary_25}`,
    },
  },
  [`& .${classes.root}`]: {
    top: -2,
    width: 32,
    height: 18,
    padding: 0,
    position: 'relative' as const,
  },
  [`& .${classes.root_hovered}`]: {
    '& .MuiSwitch-switchBase': {
      padding: '0 !important',
      border: '2px solid rgba(244, 249, 254, 0.4) !important',
    },
  },
  [`& .${classes.switchBase}`]: {
    padding: 2,
    '&:hover': {
      padding: 0,
      border: '2px solid rgba(244, 249, 254, 0.4)',
    },
    '&.Mui-checked': {
      transform: 'translateX(14px)',
      color: COLORS.surface_white,
      '& + .MuiSwitch-track': {
        backgroundColor: `${COLORS.primary_50} !important`,
        opacity: 1,
        border: 'none',
      },
      '&.Mui-disabled': {
        opacity: 0.4,
        color: COLORS.surface_white,
      },
    },
    '&.Mui-checked.Mui-disabled': {
      transform: 'translateX(14px)',
      color: COLORS.surface_white,
      '& + .MuiSwitch-track': {
        backgroundColor: `${COLORS.primary_50} !important`,
        opacity: 0.4,
        border: 'none',
      },
      '&.Mui-disabled': {
        opacity: 0.4,
        color: COLORS.surface_white,
      },
    },
    '&.Mui-disabled': {
      opacity: 0.4,
      color: COLORS.surface_white,
      '& + .MuiSwitch-track': {
        opacity: 0.4,
      },
    },
    '&.Mui-focusVisible $thumb': {
      border: `2px solid ${COLORS.primary_05}`,
      opacity: 0.4,
    },
    '&.MuiSwitch-colorSecondary.Mui-disabled': {
      color: COLORS.surface_white,
      opacity: 0.4,
      '& + .MuiSwitch-track': {
        opacity: 0.4,
        backgroundColor: COLORS.surface_gray_20,
      },
    },
  },
  [`& .${classes.switchBaseHovered}`]: {
    padding: '0 !important',
    border: '2px solid rgba(244, 249, 254, 0.4) !important',
    '&.Mui-checked': {
      transform: 'translateX(14px)',
      color: COLORS.surface_white,
      '& + .MuiSwitch-track': {
        backgroundColor: `${COLORS.primary_50} !important`,
        opacity: 1,
        border: 'none',
      },
    },
    '&.Mui-focusVisible $thumb': {
      border: `2px solid ${COLORS.primary_05}`,
      opacity: 0.4,
    },
    '&.MuiSwitch-colorSecondary.Mui-disabled': {
      color: COLORS.surface_white,
      '& + .MuiSwitch-track': {
        opacity: 0.4,
        backgroundColor: COLORS.surface_gray_20,
      },
    },
  },
  [`& .${classes.thumb}`]: {
    height: 14,
    width: 14,
    boxShadow: '0px 0px 2px rgba(76, 82, 107, 0.14), 0px 2px 2px rgba(60, 105, 190, 0.12)',
  },
  [`& .${classes.track}`]: {
    borderRadius: '100px',
    backgroundColor: COLORS.surface_gray_20,
    opacity: 1,
  },
  [`& .${classes.checked}`]: {},
})
