import React from 'react'

export const BuildingPlaceholder: any = ({ width, height, ...props }: any) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 108 108'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M94.5 6.75H54C52.2103 6.75179 50.4945 7.46352 49.229 8.729C47.9635 9.99449 47.2518 11.7103 47.25 13.5V47.25H13.5C11.7103 47.2518 9.99449 47.9635 8.729 49.229C7.46352 50.4945 6.75179 52.2103 6.75 54V101.25H101.25V13.5C101.248 11.7104 100.536 9.99472 99.2707 8.7293C98.0053 7.46387 96.2896 6.75205 94.5 6.75ZM30.375 94.5V70.875H43.875V94.5H30.375ZM94.5 94.5H50.625V67.5C50.625 66.6049 50.2694 65.7464 49.6365 65.1135C49.0035 64.4806 48.1451 64.125 47.25 64.125H27C26.1049 64.125 25.2465 64.4806 24.6135 65.1135C23.9806 65.7464 23.625 66.6049 23.625 67.5V94.5H13.5V54H54V13.5H94.5V94.5ZM67.5 27H60.75V33.75H67.5V27ZM81 27H87.75V33.75H81V27ZM67.5 47.25H60.75V54H67.5V47.25ZM81 47.25H87.75V54H81V47.25ZM67.5 67.4988H60.75V74.2488H67.5V67.4988ZM81 67.4988H87.75V74.2488H81V67.4988Z'
      fill='#9397A9'
    />
  </svg>
)
