import React from 'react'
import { LoggedInMobileMenuProps } from '../HNavBar.types'
import { LoggedInMobileMenuItems } from './LoggedInMobileMenuItems'
import { MobileUserProfile } from './MobileUserProfile'
import { HBox } from '../../HBox'
import { HDivider } from '../../HDivider'

export const LoggedInMobileMenu: React.FC<LoggedInMobileMenuProps> =
 (props) => {
   return (
     <HBox
       width ='100%'
       display='flex'
       flexDirection='column'
       alignItems='flex-start'
       justifyContent='space-between'
       flex={1}
     >
       <HBox width='100%'>
         <LoggedInMobileMenuItems {...props} />
       </HBox>
       <HBox px={2} width='100%'>
         <HBox py={4}><HDivider/></HBox>
         {props.userProfile &&
        <MobileUserProfile
          {...{
            user: props.userProfile.user,
            logout: props.userProfile.logout,
          }}
        />}
       </HBox>
     </HBox>
   )
 }
