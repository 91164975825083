import { useState } from 'react'

// eslint-disable-next-line @typescript-eslint/comma-dangle
export const usePrevious = <T, >(value: T) => {
  const [current, setCurrent] = useState(value)
  const [previous, setPrevious] = useState<T | null>(null)

  if (value !== current) {
    setPrevious(current)
    setCurrent(value)
  }

  return previous
}
