// @flow

export const isValidUrl = (string: ?string) => {
  if (!string) return false

  try {
    new URL(string) // eslint-disable-line no-new
  } catch (_) {
    return false
  }

  return true
}
