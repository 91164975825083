// @flow

import { replace as reduxReplace } from 'connected-react-router'
import localeRedirect from './shared/localeRedirect'
import { GrowthBook } from '@growthbook/growthbook-react'

import type { Store } from '../store/configureStore'
import type { Cookies } from 'react-cookie'
import type { History } from 'history'
import type { DataLoader } from './containers/withDataLoader'

export const externalDataLoaderExecuter = async (
  store: Store,
  cookies: Cookies,
  loader: DataLoader,
  routeData: $FlowTODO,
  history: History,
  growthbook: GrowthBook
) => {
  const routePath = routeData.route.routePathFromRoutesMap

  const replace = (destination) => store.dispatch(reduxReplace(destination))

  const result = await loader(
    store.dispatch,
    store.getState(),
    routeData,
    replace,
    cookies,
    growthbook
  )

  if (routePath) {
    localeRedirect(replace, store, routePath, routeData)
  }
  return result
}
