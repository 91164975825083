import { CircularProgress } from '@mui/material'
import { ButtonProps } from '@mui/material/Button'
import { DistributiveOmit } from '@mui/types'
import React, { HTMLAttributeAnchorTarget } from 'react'
import { HTypography } from '../HTypography'
import { StyledButton, SpacingProps } from './Button.styles'
import clsx from 'clsx'

type IColor = 'primary' | 'destructive' | 'neutral' | 'special'
type IVariant = 'text' | 'contained' | 'outlined'

interface HButtonProps {
  color?: IColor
  variant: IVariant
  disableElevation?: boolean
  round?: boolean
  elevated?: boolean
  loading?: boolean
  text?: string
  space?: SpacingProps
  target?: HTMLAttributeAnchorTarget
}

type disableProps =
  | keyof HButtonProps
  | 'disableElevation'
  | 'size'
  | 'disableFocusRipple'
  | 'centerRipple'
  | 'disableRipple'
  | 'focusRipple'
  | 'focusVisibleClassName'
  | 'TouchRippleProps'
  | 'color'

export type IHButtonProps =
  HButtonProps & DistributiveOmit<ButtonProps, disableProps>

export const HButton = React.forwardRef(
  (
    {
      variant,
      disabled,
      disableElevation = true,
      color = 'primary',
      elevated,
      startIcon,
      endIcon,
      loading,
      space,
      ...others
    }: IHButtonProps,
    ref: any,
  ) => {
    if (loading) {
      endIcon = <CircularProgress size={16} color='inherit' />
    }
    const classes = clsx(
      `${variant}-${color}`,
      others.className,
    )
    return (
      <StyledButton
        ref={ref}
        disableElevation
        disabled={disabled}
        variant={variant}
        startIcon={startIcon}
        endIcon={endIcon}
        {...others}
        disableFocusRipple={true}
        className={classes}
        space={space}
      >
        <HTypography
          component='span'
          weight='medium'
          align='center'
          variant='button'
          color='inherit'
        >
          {others.text || others.children}
        </HTypography>
      </StyledButton>
    )
  },
)

HButton.displayName = 'HButton'
