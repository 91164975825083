// @flow

import React, { useContext } from 'react'
import DataLoaderContext from '../../contexts/DataLoaderContext'
import { GrowthBook } from '@growthbook/growthbook-react'

import type { Location, RouterHistory, Match } from 'react-router'
import type { ApplicationState } from '../../types/applicationState'
import type { Dispatch } from '../../store/configureStore'
import type { RouteData } from '../../types/common'
import type { Cookies } from 'react-cookie'

export type DataLoader = (
  dispatch: Dispatch,
  state: ApplicationState,
  routeData: RouteData,
  replace: Function,
  cookies?: Cookies,
  growthbook?: GrowthBook
) => Promise<void>

export type UniversalLoadable = { loadData: DataLoader }
export type ClientLoadable = { clientLoadData: DataLoader }

type LoadableComponentProps = {
  history: RouterHistory,
  location: Location,
  match: Match,
}

export const withClientDataLoader = (loadData: DataLoader) => {
  return <U: LoadableComponentProps, T: React$ComponentType<U>>(Component: T): T & ClientLoadable => {
    const comp: any = (props: U) => {
      const container = useContext(DataLoaderContext)
      container.current = {
        loader: loadData,
      }
      return <Component {...props} />
    }
    comp.loadData = (Component: any).loadData
    comp.clientLoadData = loadData
    return comp
  }
}

export const withUniversalDataLoader = (loadData: DataLoader) => {
  return <U: LoadableComponentProps, T: React$ComponentType<U>>(Component: T): T & UniversalLoadable => {
    const c = ((Component: any): T & UniversalLoadable)
    c.loadData = loadData
    return c
  }
}
