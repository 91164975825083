// @flow

import API from '../clientBuilder'
import { buildUrlFragment } from '../../../helpers/url/query'
import { arrayToQuery } from '../../../helpers/url/arrayToQuery'
import type { APIRequestConfig } from '../types'

export default {
  get: (id: string, countryCode?: string, config: APIRequestConfig) => API.get.promise(`/v2/jobs/${id}${countryCode ? buildUrlFragment({ country_code: countryCode }) : ''}`, null, config),

  applied: (config: APIRequestConfig) => API.get.promise('/v2/jobs/applied', null, config),

  similarJobs: (options: $FlowTODO, config: APIRequestConfig) =>
    API.post.promise('/v2/similar_jobs', options, config),

  published: (options: $FlowTODO) =>
    API.get.promise(`/v2/jobs/published${buildUrlFragment(options)}`, null),

  screeningQuestions: (jobId: string, config: APIRequestConfig) =>
    API.get.promise(`/v2/talent_platform/jobs/${jobId}/screening_questions`, null, config),

  recommendedJobs: (options: $FlowTODO, config: APIRequestConfig) =>
    API.post.promise('/v2/recommended', options, config),

  visitedJobs: (ids: string[], config: APIRequestConfig) => {
    const query = arrayToQuery({ ids })
    return API.get.promise(`/v2/jobs/visited?${query}`, null, config)
  },

}
