import { createSlice } from '@reduxjs/toolkit'
import { GOOGLE_ONE_TAP_SOURCE } from '../../components/shared/GoogleOneTapSignIn'
import { AuthOrigins } from '../../helpers/postSignupUtils'

export type AuthStrategyState = {
  authStrategy: typeof GOOGLE_ONE_TAP_SOURCE | 'login-modal' | typeof AuthOrigins.CAF | null
}

const initialState: AuthStrategyState = {
  authStrategy: null,
}

const AuthStrategySlice = createSlice({
  name: 'onBoardingModal',
  initialState,
  reducers: {
    setAuthStrategy: (state, action) => {
      state.authStrategy = action.payload
    },
  },
})

export const { setAuthStrategy } = AuthStrategySlice.actions

export default AuthStrategySlice.reducer
