import React, { ComponentPropsWithoutRef } from 'react'
import OnboardingJobWorkingHours from '../components/onboarding-job-working-hours'
import OnboardingLocation from '../components/onboarding-location'
import OnboardingSalary from '../components/onboarding-salary'
import OnboardingSuccess from '../components/onboarding-success/onboarding-success'
import OnboardingEmploymentType from '../components/onboarding-employment-type/onboarding-employment-type'
import OnBoardingJobTitles from '../components/onboarding-job-titles/onboarding-job-titles'
import { JobTitle, LocationData, SaveStepParams, StepValues } from './api-types'

export const OnboardingFlowComponents = {
  working_hours_type: { Component: OnboardingJobWorkingHours, backButton: false },
  location: { Component: OnboardingLocation, backButton: false },
  employment_type: { Component: OnboardingEmploymentType, backButton: true },
  job_title: { Component: OnBoardingJobTitles, backButton: true },
  minimum_salary: { Component: OnboardingSalary, backButton: true },
  success: { Component: OnboardingSuccess, backButton: false },
}

export type Steps = keyof typeof OnboardingFlowComponents

export const StepsOrder = [
  'working_hours_type',
  'employment_type',
  'job_title',
  'location',
  'minimum_salary',
  'success',
] as const

export type StepState = Partial<{[K in Steps]: Omit<ComponentPropsWithoutRef<(typeof OnboardingFlowComponents)[K]['Component']>, 'children'>}>

export type OnBoardingSearchParams = Omit<Partial<{[K in keyof StepValues]: StepValues[K] extends Array<LocationData> | Array<JobTitle> ? StepValues[K][number] : StepValues[K]}>, 'minimum_salary'>

export interface BaseComponentProps {
  userSignupSource?: string;
  totalStepCount: number;
}

export interface ContainerState {
  step: Steps
  stepIndex: number,
  stepsSeen: number,
  stepsProgress: number,
  stepState: StepState,
  history: Steps[],
  totalStepCount: number
}

export interface OnboardingFlowContextProps extends ContainerState {
  goNext: () => void
  goBack: () => void
  onRestartFlow: () => void
  setStepState: <K extends keyof StepState>(step: K, value: StepState[K]) => void
  onStepClose: (stepName: Steps) => void
  closeOnBoardingWithNoTracking: () => void
  trackingContext?: Object
  saveStepData: (params: SaveStepParams) => void
  baseProps: BaseComponentProps
}

export type OnboardingStep <Props extends Record<any, unknown>> = React.FC<Props>
