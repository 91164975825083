// @flow

import React from 'react'
import { matchPath } from 'react-router-dom'
import compose from 'lodash/flowRight'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { $colors, $fontWeights } from '../../components/shared/dependencies/variables'
import injectPathr from '../../components/containers/pathr'
import { unPrefixRouteName } from '../../routing/routeNamePrefix'
import { routeForTargetLocale, routeNames } from '../../routes'
import { LOCALES_CONFIG, COUNTRIES_CONFIG } from '../../constants/country'
import type { LangChangeProps, Locale, Country } from '../../types/common'
import type { ApplicationState } from '../../types/applicationState'
import { FragmentWrapper } from '../MainLayout'

const LangChangeLink = styled.a`
  display: inline-flex;
  align-items: center;
  color: ${$colors.default[200]};
  font-size: 14px;
  font-weight: ${$fontWeights.semibold};

  &:hover, &:active {
    color: ${$colors.default[400]};
  }

  & > * {
    display: block;
  }
`

const FlagImg = styled.div`
  background-size: contain;
  border-radius: 50%;
  display: inline-block;
  margin-right: 12px;
`

const FlagImgDiv = ({ src, size }: {size: any, src: string}) =>
  // FIXME: find a way to avoid style without breaking amp-validations
  <FlagImg
    style={{
      backgroundImage: `url('${src}')`,
      width: size,
      height: size,
    }}
  />

export const getOppositeLocaleConfig = ({
  activeLocale,
  country,
}: { activeLocale?: Locale | string, country: Country | string }) => {
  const getTargetLocale = (locale, country) => {
    const { available } = COUNTRIES_CONFIG[country]

    return available.find((a) => a !== locale)
  }
  const targetLocale = getTargetLocale(activeLocale, country)
  if (targetLocale && LOCALES_CONFIG[targetLocale]) {
    return {
      locale: targetLocale,
      config: LOCALES_CONFIG[targetLocale],
    }
  }
  return null
}

const _LangChange = (props: LangChangeProps) => {
  const {
    cpathr,
    locale: activeLocale,
    params,
    location,
    country,
    company,
    abbrev,
    imageSize,
    wrapper: LangChangeWrapper,
    testId,
  } = props
  const Wrapper = LangChangeWrapper || FragmentWrapper

  // get route name (Check if search page)
  const isSearchPage = matchPath(location.pathname, { path: props.cpathr.jobSearch() })
  const routeName = isSearchPage ? routeNames.jobSearchKeyword : routeNames.home
  const oppositeLocale = getOppositeLocaleConfig({ activeLocale, country })
  if (oppositeLocale) {
    const targetRoute =
      routeForTargetLocale(
        oppositeLocale.locale,
        params,
        unPrefixRouteName(routeName),
        location,
        activeLocale,
        country
      ) || cpathr.index

    return (
      <Wrapper company={company}>
        <LangChangeLink
          data-test-id={testId || `language-switch${abbrev ? '-abbrev' : ''}`}
          id={`language-switch${abbrev ? '-abbrev' : ''}`}
          href={targetRoute}
        >
          <FlagImgDiv
            src={oppositeLocale.config.flag}
            size={imageSize}
          />

          {abbrev && oppositeLocale.locale?.toUpperCase()}
          {!abbrev && (
            <FormattedMessage id={oppositeLocale.config.changeLangId} />
          )}
        </LangChangeLink>
      </Wrapper>
    )
  }
  return null
}

const redux = [
  (state: ApplicationState) => ({
    locale: state.intlData.locale,
    country: state.locality.country,
  }),
]

const LangChange = compose(injectPathr, injectIntl, connect(...redux))(_LangChange)

export default LangChange
