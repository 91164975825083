// @flow
import {
  COGNITO_SIGN_IN, COGNITO_SIGN_UP, LOGOUT, PASSWORDLESS_SIGN_IN, PASSWORDLESS_SIGN_UP,
} from '../../constants'
import { getItem, identifiers } from '../../helpers/localStorageUtils'
import { FULFILLED } from '../../reducers/promiseUtil'
import * as actionCreators from '../actionCreators'

import type { Dispatch } from '../../store/configureStore'

export const onLogout = (dispatch: Dispatch) => {
  dispatch({ type: FULFILLED(LOGOUT) })
  dispatch(actionCreators.updateDeviceTokens({
    token: getItem(identifiers.DEVICE_TOKEN),
    tracking_token: getItem(identifiers.TRACKING_TOKEN),
  }))
}

export const onSignUp = (payload: any, dispatch: Dispatch) => {
  dispatch({ type: FULFILLED(PASSWORDLESS_SIGN_UP), payload })
  dispatch(actionCreators.hideLoginModal())
}

export const onCognitoSignUp = (payload: any, dispatch: Dispatch) => {
  dispatch({ type: FULFILLED(COGNITO_SIGN_UP), payload })
  dispatch(actionCreators.hideLoginModal())
}

export const onSignIn = (payload: any, dispatch: Dispatch) => {
  dispatch({ type: FULFILLED(PASSWORDLESS_SIGN_IN), payload })
  dispatch(actionCreators.hideLoginModal())
}

export const onCognitoSignIn = (payload: any, dispatch: Dispatch) => {
  dispatch({ type: FULFILLED(COGNITO_SIGN_IN), payload })
  dispatch(actionCreators.hideLoginModal())
}
