import { PropsOf, Theme } from '@emotion/react'
import styled, { CreateStyledComponent, StyledOptions } from '@emotion/styled'
import { ComponentProps, ComponentType, ElementType } from 'react'

export interface HStyledOptions<T> extends StyledOptions<T>{
  blackListedProps?: string[]
}

/**
 * `hStyled` is a helper function that creates an emotion styled component,
 * with the ability to prevent passing unknown props to DOM.
 *
 * The props that start with `$` will not be passed to the DOM element.
 */
export function hStyled<C extends ComponentType<ComponentProps<C>>> (
  component: C,
  options?: HStyledOptions<ComponentProps<C>>
): CreateStyledComponent<PropsOf<C> & { theme?: Theme }>

/**
 * `hStyled` is a helper function that creates an emotion styled component,
 * with the ability to prevent passing unknown props to DOM.
 *
 * The props that start with `$` will not be passed to the DOM element.
 */
export function hStyled<Tag extends keyof JSX.IntrinsicElements> (
  tag: Tag,
  options?: HStyledOptions<JSX.IntrinsicElements[Tag]>
): CreateStyledComponent<
{ theme?: Theme, as?: ElementType },
JSX.IntrinsicElements[Tag]
>

// FIXME: Manually defining the overloads instead of using `Parameters` and
// `ReturnType` due to it being unsound and unsupported in TypeScript when
// multiple overloads exist.
// https://github.com/microsoft/TypeScript/issues/32164#issuecomment-506810756
//
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function hStyled (Component: any, options?: HStyledOptions<any>): any {
  return styled(Component, {
    shouldForwardProp: (propName: string) => {
      // Allow more forbidding of props with a passed shouldForwardProp function
      const shouldForward = options?.shouldForwardProp?.(propName) || true

      const isPrefixed = propName.startsWith('$')
      const isBlacklisted = options?.blackListedProps?.includes(propName)

      return shouldForward && !isPrefixed && !isBlacklisted
    },
  })
}
