// @flow

import config from '../../config'

type externalPageMapResult = {
  careerGuide: string,
  careers: string,
  complianceAndSecurity: string,
  employers: string,
  imprint: string,
  innovativeFeatures: string,
  privacy: string,
  recruiters: string,
  tc: string
}

const links = {
  en: () => ({
    employers: `${config.linking.marketingUrl}/en/`,
    imprint: `${config.linking.marketingUrl}/en/imprint/`,
    tc: `${config.linking.jobseekerUrl}/en-de/agb`,
    careers: `${config.linking.marketingUrl}/en/career/`,
    recruiters: `${config.linking.recruiterUrl}/en`,
    privacy: `${config.linking.marketingUrl}/en/privacy-policy/`,
    innovativeFeatures: `${config.linking.jobseekerUrl}/services/en/heyjobs-features/`,
    careerGuide: `${config.linking.jobseekerUrl}/services/en`,
    complianceAndSecurity: 'https://trust.heyjobs.co/',
  }),
  de: () => ({
    employers: `${config.linking.marketingUrl}/`,
    imprint: `${config.linking.marketingUrl}/impressum/`,
    tc: `${config.linking.jobseekerUrl}/de-de/agb`,
    careers: `${config.linking.marketingUrl}/karriere/`,
    recruiters: `${config.linking.recruiterUrl}/de`,
    privacy: `${config.linking.marketingUrl}/datenschutzerklaerung/`,
    innovativeFeatures: `${config.linking.jobseekerUrl}/services/heyjobs-features/`,
    careerGuide: `${config.linking.jobseekerUrl}/services/`,
    complianceAndSecurity: 'https://trust.heyjobs.co/',
  }),
}

const externalPageMap = (locale: string): externalPageMapResult => {
  const language = locale.startsWith('en') ? 'en' : 'de'

  return links[language]()
}

export default externalPageMap
