import Tabs from '@mui/material/Tabs'
import clsx from 'clsx'
import React from 'react'
import styled from '@emotion/styled'
import { COLORS } from '../theme'
import { HTabProps } from './HTab.types'

const HTabComp = ({
  alignment = 'container',
  onChange,
  TabIndicatorProps,
  fullWidth,
  ...others
}: HTabProps): JSX.Element => {
  return (
    <Tabs
      onChange={onChange}
      {...others}
      TabIndicatorProps={{
        children: <span />,
        className: clsx(
          `align-${alignment}`,
          'tab-indicator',
          TabIndicatorProps?.className,
        ),
        ...TabIndicatorProps,
      }}
      color='default'
    ></Tabs>
  )
}

export const HTabs = styled(HTabComp)`
  .tab-indicator {
    border-radius: 4px;
    height: 1px;
  }
  .align-container {
    background-color: ${COLORS.primary_50};
  }
  .align-content,
  .align-content-stacked {
    display: flex;
    background-color: transparent;
    justify-content: center;
    padding: 0px 4px;
    span {
      background-color: ${COLORS.primary_50};
      width: 100%;
      border-radius: 4px;
    }
  }
  .MuiTab-root {
    ${({ fullWidth }) => (fullWidth ? 'flex: 1' : '')}
  }
  .MuiTab-root::after {
    content: '';
    height: 1px;
    background-color: ${COLORS.surface_gray_10};
    width: ${({ alignment }) =>
    alignment === 'container' ? '100%' : 'calc(100% - 8px)'};
    position: absolute;
    bottom: 0;
    border-radius: 4px;
  }
  .MuiTabs-flexContainer::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background: ${COLORS.surface_gray_10};
    bottom: 0px;
    border-radius: 4px;
    display: ${({ alignment }) =>
    alignment === 'content-stacked' ? 'block' : 'none'};
  }
`
