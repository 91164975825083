export type PopularJobTitlesType = {
  // eslint-disable-next-line camelcase
  title_id: number,
  key: string
}

export const popularJobTitles:Readonly<PopularJobTitlesType[]> = [
  { title_id: 1609, key: 'popular_job_titles_mechanic' },
  { title_id: 254, key: 'popular_job_titles_professional_driver' },
  { title_id: 1389, key: 'popular_job_titles_nurse' },
  { title_id: 1999, key: 'popular_job_titles_cleaning_person' },
  { title_id: 2410, key: 'popular_job_titles_salesperson' },
  { title_id: 564, key: 'popular_job_titles_elektriker' }
]
