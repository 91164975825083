
// @flow

import find from 'lodash/find'
import * as utils from '../utils/reduxUtils'
import * as actions from '../../constants/actions'
import * as API from '../api/clients'
import cookies from '../../cookies'
import localStorageUtils from '../../helpers/localStorageUtils'
import { runningInBrowser } from '../actions/support/base'
import omit from 'lodash/omit'
import logger from '../../../logging'
import ApiError from '../api/errors/ApiError'

import type { DeviceOptions } from '../api/clients/devices'

const log = logger('session')

const fetchDevice = (token, requestConfig) => {
  return API.devices.current({
    headers: {
      // 2 auth tokens per request are forbidden by the API
      // for backwards compatibility, exclude the potential `Authorization` header
      // which may be added by the cognito auth flow
      ...omit(requestConfig.headers, 'Authorization'),
      'X-Auth-Token': token, // override current token
    },
  })
}

const createDevice = (state, requestConfig) => {
  const options: DeviceOptions = {
    locale: state.intlData.locale,
  }
  return API.devices.create(
    options, // TODO: all of these options are already provided as Headers and should be removed, might need to update the api
    requestConfig
  )
}

const getDeviceToken = async (token: ?string, requestConfig, state) => {
  if (token) {
    let response = null
    try {
      log.info('device exists. Fetching...')
      response = await fetchDevice(token, requestConfig)
      log.info(`fetched ${response.tracking_token}`, { token: response.tracking_token })
    } catch (error) {
      if (error instanceof ApiError) {
        // provided token is invalid, create a new one
        if (find(error.errors, { code: 'token_invalid' })) {
          log.info('failed to fetch device. Creating...')
          response = await createDevice(state, requestConfig)
          log.info(`created device ${response.tracking_token}`, { token: response.tracking_token })
        } else {
          throw error
        }
      } else {
        throw error
      }
    }
    return response
  } else {
    log.info('device does not exist. creating...')
    const response = await createDevice(state, requestConfig)
    log.info('created', { token: response.tracking_token })
    return response
  }
}

const updateSessionDependencies = (device) => {
  if (runningInBrowser) {
    localStorageUtils.storeTokenInLocalStorage(device.token, device.tracking_token)
  }
}

export const resolveDevice = utils.createPromise(actions.RESOLVE_DEVICE, () => async (requestConfig, dispatch, getState, [cookieJar]) => {
  const state = getState()
  const domain = cookies.globalize(state.request.host)

  const token = cookies.load(cookieJar)(cookies.identifiers.DEVICE_TOKEN)
  const device = await getDeviceToken(token, requestConfig, state)

  // update these cookies only if the session has changed
  if (token !== device.token) {
    cookies.wide.save(cookieJar)(
      cookies.identifiers.DEVICE_TOKEN,
      device.token,
      { domain }
    )
    cookies.wide.save(cookieJar)(
      cookies.identifiers.TRACKING_TOKEN,
      device.tracking_token,
      { domain }
    )
    cookies.wide.remove(cookieJar)(cookies.identifiers.AB_TESTING_COOKIE)
  }

  updateSessionDependencies(device)

  return device
})
