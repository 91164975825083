import React from 'react'
import { COLORS } from '../theme'

export const PersonIcon = (
  { width, height, ...props }: React.SVGProps<SVGSVGElement>,
): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <circle cx='20' cy='20' r='20' fill='white' />
    <path d="M35.3826 32.7568C37.4352 30.2885 38.8627 27.3624 39.5444 24.2262C40.226 21.09 40.1418 17.8358 39.2987 14.739C38.4557 11.6422 36.8786 8.79388 34.701 6.43486C32.5234 4.07584 29.8092 2.27556 26.7881 1.1863C23.767 0.0970326 20.5278 -0.249176 17.3444 0.176955C14.1611 0.603085 11.1273 1.78902 8.4996 3.63445C5.87192 5.47988 3.72767 7.93051 2.24821 10.7791C0.768752 13.6276 -0.00239096 16.7903 5.56881e-06 19.9997C0.00112527 24.6657 1.6467 29.1823 4.64799 32.7568L4.61939 32.7806C4.71947 32.9013 4.83384 33.0044 4.93706 33.1223C5.06573 33.2703 5.20355 33.408 5.33737 33.552C5.73768 33.9853 6.14856 34.4023 6.58118 34.7949C6.71199 34.9136 6.84767 35.0251 6.98148 35.1401C7.43898 35.5343 7.90848 35.9076 8.39685 36.2563C8.4599 36.3009 8.5158 36.3553 8.57928 36.4V36.3819C11.9277 38.7364 15.9222 40 20.0166 40C24.111 40 28.1055 38.7364 31.4539 36.3819V36.3997C31.5174 36.355 31.5733 36.3006 31.6363 36.256C32.1241 35.9073 32.5942 35.534 33.0517 35.1399C33.1855 35.0256 33.3212 34.9133 33.452 34.7946C33.8845 34.4018 34.2955 33.9848 34.6958 33.5517C34.8292 33.408 34.9666 33.27 35.0961 33.122C35.1989 33.0041 35.3137 32.901 35.4138 32.7803L35.3826 32.7568ZM20.0153 8.57108C21.2877 8.57108 22.5316 8.94811 23.5896 9.65449C24.6475 10.3609 25.4721 11.3649 25.9591 12.5395C26.446 13.7142 26.5734 15.0068 26.3252 16.2538C26.0769 17.5008 25.4642 18.6463 24.5645 19.5454C23.6647 20.4444 22.5184 21.0567 21.2704 21.3047C20.0224 21.5528 18.7289 21.4255 17.5533 20.9389C16.3777 20.4523 15.373 19.6284 14.6661 18.5712C13.9591 17.514 13.5818 16.2711 13.5818 14.9997C13.5818 13.2947 14.2596 11.6596 15.4661 10.454C16.6727 9.24837 18.309 8.57108 20.0153 8.57108ZM8.58814 32.7568C8.61283 30.8811 9.37564 29.0904 10.7115 27.7724C12.0474 26.4543 13.8489 25.7149 15.7263 25.714H24.3043C26.1817 25.7149 27.9832 26.4544 29.319 27.7724C30.6549 29.0905 31.4177 30.8811 31.4425 32.7568C28.307 35.5801 24.236 37.1426 20.0153 37.1426C15.7946 37.1426 11.7236 35.5801 8.58814 32.7568Z" fill={COLORS.surface_gray_40}/>
  </svg>
)
