// @flow

import omit from 'lodash/omit'
import * as actions from '../constants/actions'
import { FULFILLED, PENDING, REJECTED } from './promiseUtil'

import type { AppliedJob, ApplicationDetail } from '../types/application'

export type ApplicationsState = {
  applicationDetail: ApplicationDetail,
  hiddenJobs: AppliedJob[],
  isLoading: bool,
  jobs: AppliedJob[],
  visibleJobs: AppliedJob[]
}

const initialState: ApplicationsState = {
  isLoading: false,
  jobs: [],
  hiddenJobs: [],
  visibleJobs: [],
  applicationDetail: {
    isLoading: true,
    documents: [],
    job: null,
    screening_answers: [],
    screening_questions: [],
    id: null,
    jobseeker_status: null,
    recruiter_status: null,
    screenout_email_sent_at: null,
  },
}

const reducer = (state: ApplicationsState = initialState, action: $FlowTODO) => {
  switch (action.type) {
    case PENDING(actions.FETCH_APPLICATIONS):
      return {
        ...state,
        isLoading: true,
      }
    case FULFILLED(actions.FETCH_APPLICATIONS): {
      const hiddenApplications = []
      const visibleApplications = []

      action.payload.forEach((job) => {
        if (job.application?.hidden_by_jobseeker) { // eslint-disable-line camelcase
          hiddenApplications.push(job)
        } else {
          visibleApplications.push(job)
        }
      })

      return {
        ...state,
        isLoading: false,
        jobs: action.payload,
        hiddenJobs: hiddenApplications,
        visibleJobs: visibleApplications,
      }
    }
    case PENDING(actions.GET_APPLICATION_DETAILS):
      return {
        ...state,
        isLoading: true,
      }

    case REJECTED(actions.GET_APPLICATION_DETAILS):
      return {
        ...state,
        isLoading: false,
      }

    case FULFILLED(actions.GET_APPLICATION_DETAILS):
      return {
        ...state,
        applicationDetail: {
          ...action.payload,
          documents: action.payload.documents.map((doc) => ({
            file: doc,
            deleting: false,
            uploading: false,
            uploaded: true,
            errors: [],
          })),
          isLoading: false,
        },
      }

    case FULFILLED(actions.TOGGLE_APPLICATION_VISIBILITY): {
      const visibleJobsAfterToggle = []
      const hiddenJobsAfterToggle = []

      state.visibleJobs.forEach((job) => {
        if (job.application?.id === action.payload.id) {
          job.application = action.payload
          hiddenJobsAfterToggle.push(job)
        } else {
          visibleJobsAfterToggle.push(job)
        }
      })
      state.hiddenJobs.forEach((job) => {
        if (job.application?.id === action.payload.id) {
          job.application = action.payload
          visibleJobsAfterToggle.push(job)
        } else {
          hiddenJobsAfterToggle.push(job)
        }
      })

      return {
        ...state,
        visibleJobs: visibleJobsAfterToggle,
        hiddenJobs: hiddenJobsAfterToggle,
        isLoading: false,
      }
    }

    case FULFILLED(actions.LOGOUT):
      return {
        ...initialState,
      }

    case actions.LOCATION_CHANGE: {
      return {
        ...state,
        ...omit(initialState, 'jobs'),
      }
    }

    case actions.JOB_APPLICATION_UPDATE_FEEDBACK_STATE: {
      const updateJobs = (jobs) => jobs.map(job => {
        if (job && job.application && job.application.id === action.payload.id) {
          job.application.can_request_feedback = action?.payload?.can_request_feedback
          job.application.have_requested_feedback = action?.payload?.have_requested_feedback
        }
        return job
      })

      return {
        ...state,
        jobs: [...updateJobs(state.jobs)],
        hiddenJobs: [...updateJobs(state.hiddenJobs)],
        visibleJobs: [...updateJobs(state.visibleJobs)],
        ...(state.applicationDetail?.id === action.payload.id &&
          {
            applicationDetail: {
              ...state.applicationDetail,
              can_request_feedback: action?.payload?.can_request_feedback,
              have_requested_feedback: action?.payload?.have_requested_feedback,
            },
          }
        ),
      }
    }

    default:
      return state
  }
}

export default reducer
