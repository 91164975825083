// @flow

import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { CookiesProvider } from 'react-cookie'
import IntlProvider from './intlProvider'
import { baseMetas } from '../tags'
import GlobalStyles from './globalStyles'
import ViewerContext from '../../contexts/ViewerContext'
import NoScriptNotification from '../shared/NoScriptNotification'
import Routes from '../Routes'

import type { Store } from 'redux'
import type { Cookies } from 'react-cookie'
import type { MemoryHistory } from 'history'
import type { ReactContextsData } from '../../types/reactContexts'
import type { ApplicationState } from '../../types/applicationState'
import type { Action } from '../../store/configureStore'
import { GrowthBook } from '@growthbook/growthbook-react'
import { baseScripts } from './baseScripts'
import { BroadcastChannelListener } from '../../helpers/channel/broadcast-channel.ts'

type StyleTag = {|
  cssText: string,
  type: string
|}

type RootProps = {|
  cookies: Cookies,
  growthbook: GrowthBook,
  history: MemoryHistory,
  reactContextsData: ReactContextsData,
  store: Store<ApplicationState, Action>
|}

const baseStyles = (props): StyleTag[] => {
  return []
}

const CustomHelmet = (props) => (
  <Helmet
    defaultTitle='HeyJobs'
    titleTemplate={props.companyName ? '%s' : '%s | HeyJobs'}
    meta={baseMetas({ noindex: true, locale: props.locale, country: props.country })}
    script={baseScripts(props)}
    style={baseStyles(props)}
  />
)

const redux = [
  (state: ApplicationState) => ({
    userId: state.user.user?.id,
    sessionToken: state.session.device.token,
    locale: state.intlData.locale,
    country: state.locality.country,
    company: state.company.current,
    companyName: state.company.current && state.company.current.name,
    hasGtmConsent: state.cookieConsent.services['Google Tag Manager'] && state.cookieConsent.isExplicit,
  }),
]

const ConnectedHelmet = connect(...redux)(CustomHelmet)

const Root = (props: RootProps) => (
  <>
    <BroadcastChannelListener />
    <GlobalStyles />
    <ConnectedHelmet growthbook={props.growthbook} />
    <CookiesProvider cookies={props.cookies}>
      <IntlProvider>
        <ViewerContext.Provider value={{ ...props.reactContextsData.ViewerContext }}>
          <NoScriptNotification />
          <Routes
            history={props.history}
            store={props.store}
            cookies={props.cookies}
            growthbook={props.growthbook}
          />
        </ViewerContext.Provider>
      </IntlProvider>
    </CookiesProvider>
  </>
)

export default Root
