import parse from 'autosuggest-highlight/parse'
import React from 'react'
import { HTypography } from 'talent-ui/src/HTypography/HTypography'
import { NormalizedMapsPrediction } from '../utils/types'

const RenderOption = (
  props: React.HTMLAttributes<HTMLLIElement>,
  option: NormalizedMapsPrediction,
  { inputValue }: { inputValue: string },
  showSecondaryText: boolean = false
) => {
  const matches = option.structured_formatting.main_text_matched_substrings

  const parts = parse(
    option.structured_formatting.main_text,
    matches.map((match: any) => [
      match.offset,
      parseInt(match.offset) + parseInt(match.length),
    ])
  )

  return (
    <li
      // Render option now does not include it: https://mui.com/material-ui/migration/v5-component-changes/#update-renderoption
      {...props}
    >
      <HTypography
        data-test-id='generic-homepage-location-input-autocomplete'
        // Options get nested in a `p` tag, so we need to use `span` to not break nesting rules
        component='span'
        variant='body2'
        weight='regular'
        color='surface_gray_60'
        translate='no'
        selectors={[
          {
            selector: 'span',
            props: {
              color: 'surface_gray_90',
            },
          },
        ]}
      >
        {parts.map((part, index) => (
          <React.Fragment key={index}>
            {part.highlight && inputValue ? <span>{part.text}</span> : part.text}
          </React.Fragment>
        ))}
        {Boolean(showSecondaryText && option.structured_formatting.secondary_text) && `, ${option.structured_formatting.secondary_text}`}
      </HTypography>
    </li>
  )
}

export default RenderOption
