// @flow

import * as React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import compose from 'lodash/flowRight'
import injectPathr from '../components/containers/pathr'

import type { ApplicationState } from '../types/applicationState'

type StateProps = {|
  isAuthenticated: bool,
  isUserInitialized: bool,
  useEmail: string
|}

type DispatchProps = {|
  navigateToHomePage: () => void
|}
type OwnProps = {|
  cpathr: any
|}

type MergeProps = {|
  ...StateProps,
  ...DispatchProps,
  ...OwnProps
|}
export const redirectIfUnauthenticated = (Component: React.ComponentType<any>) => {
  const RedirectHoc = (props) => {
    React.useEffect(() => {
      const search = window.location.search
      const params = new URLSearchParams(search)
      const email = params.get('email')

      const fullPath = props.location.pathname + props.location.search

      if (!props.isAuthenticated && props.isUserInitialized) {
        props.navigateToAuthPage(fullPath)
      }
      if (props.isAuthenticated && props.isUserInitialized && email && props.userEmail && email !== props.userEmail) {
        props.navigateToAuthPage(fullPath)
      }
    })

    return <Component />
  }

  const redux = [
    (state: ApplicationState) => ({
      isAuthenticated: state.user.isAuthenticated,
      userEmail: state.user.user?.email,
      isUserInitialized: state.user.initialized,
    }),
    (dispatch, ownProps) => ({
      navigateToAuthPage: (redirectPath) => {
        const queryString = `?redirect_path=${encodeURIComponent(redirectPath)}`
        dispatch(push(ownProps.cpathr.auth + queryString))
      },
    }),
    (stateProps: StateProps, dispatchProps: DispatchProps, ownProps: OwnProps): MergeProps => ({
      ...ownProps,
      ...dispatchProps,
      ...stateProps,
    }),

  ]

  return compose(
    injectPathr,
    connect(...redux)
  )(RedirectHoc)
}
