// @flow
import get from 'lodash/get'
import snakeCase from 'lodash/snakeCase'
import truncate from 'lodash/truncate'
import join from 'lodash/join'
import filter from 'lodash/filter'
import type { IntlShape } from 'react-intl'
import config from '../../config'
import { unPrefixRouteName } from '../routing/routeNamePrefix'
import logger from '../../logging'
import { findCompanyName } from '../functions_/shared/utils'

import type { PrefixedRouteName } from '../routing/routeNamePrefix'

type BaseMetas = {
  country: string,
  locale: string,
  noindex: ?bool,
  sitename?: ?string,
  targetUrl?: string
}

type RouteTitle = {
  intl: IntlShape,
  messages: Object,
  route: Object
}

type Include = {
  description: bool,
  keywords: bool
}

type RouteMetas = {
  data?: Object,
  include?: Include,
  intl: IntlShape,
  messages: Object,
  route: Object
}

const log = logger('tags')

const faviconLinks = [
  {
    rel: 'apple-touch-icon',
    sizes: '57x57',
    href: '/favicons/apple-icon-57x57.png',
  },
  {
    rel: 'apple-touch-icon',
    sizes: '114x114',
    href: '/favicons/apple-icon-114x114.png',
  },
  {
    rel: 'apple-touch-icon',
    sizes: '72x72',
    href: '/favicons/apple-icon-72x72.png',
  },
  {
    rel: 'apple-touch-icon',
    sizes: '144x144',
    href: '/favicons/apple-icon-144x144.png',
  },
  {
    rel: 'apple-touch-icon',
    sizes: '60x60',
    href: '/favicons/apple-icon-60x60.png',
  },
  {
    rel: 'apple-touch-icon',
    sizes: '120x120',
    href: '/favicons/apple-icon-120x120.png',
  },
  {
    rel: 'apple-touch-icon',
    sizes: '76x76',
    href: '/favicons/apple-icon-76x76.png',
  },
  {
    rel: 'apple-touch-icon',
    sizes: '152x152',
    href: '/favicons/apple-icon-152x152.png',
  },
  {
    rel: 'android-touch-icon',
    sizes: '57x57',
    href: '/favicons/apple-icon-57x57.png',
  },
  {
    rel: 'android-touch-icon',
    sizes: '114x114',
    href: '/favicons/apple-icon-114x114.png',
  },
  {
    rel: 'android-touch-icon',
    sizes: '72x72',
    href: '/favicons/apple-icon-72x72.png',
  },
  {
    rel: 'android-touch-icon',
    sizes: '144x144',
    href: '/favicons/apple-icon-144x144.png',
  },
  {
    rel: 'android-touch-icon',
    sizes: '60x60',
    href: '/favicons/apple-icon-60x60.png',
  },
  {
    rel: 'android-touch-icon',
    sizes: '120x120',
    href: '/favicons/apple-icon-120x120.png',
  },
  {
    rel: 'android-touch-icon',
    sizes: '76x76',
    href: '/favicons/apple-icon-76x76.png',
  },
  {
    rel: 'android-touch-icon',
    sizes: '152x152',
    href: '/favicons/apple-icon-152x152.png',
  },
  {
    rel: 'icon',
    type: 'image/png',
    href: '/favicons/favicon-96x96.png',
    sizes: '96x96',
  },
  {
    rel: 'icon',
    type: 'image/png',
    href: '/favicons/favicon-32x32.png',
    sizes: '32x32',
  },
  {
    rel: 'icon',
    type: 'image/png',
    href: '/favicons/favicon-16x16.png',
    sizes: '16x16',
  },
]

const faviconMetas = [
  { name: 'msapplication-TileColor', content: '#FFFFFF' },
  { name: 'msapplication-TileImage', content: '/favicons/ms-icon-144x144.png' },
  {
    name: 'msapplication-square70x70logo',
    content: '/favicons/ms-icon-70x70.png',
  },
  {
    name: 'msapplication-square150x150logo',
    content: '/favicons/ms-icon-150x150.png',
  },
  {
    name: 'msapplication-wide310x150logo',
    content: '/favicons/ms-icon-310x150.png',
  },
  {
    name: 'msapplication-square310x310logo',
    content: '/favicons/ms-icon-310x310.png',
  },
]

export const baseLinks = [
  // Favicons
  ...faviconLinks,
]

export const baseMetas = ({
  noindex,
  locale,
  country,
  sitename = 'HeyJobs',
  targetUrl = config.linking.jobseekerUrl,
}: BaseMetas): Object => {
  const baseMetas = [
    // Control Indexing
    { name: 'robots', content: noindex ? 'noindex' : 'index' },
    // HTML
    { httpEquiv: 'Content-Type', content: 'text/html charset=UTF-8' },
    // Mobile Scale
    {
      name: 'viewport',
      content:
      'width=device-width, initial-scale=1.0, maximum-scale=1.0',
    },
    // Open Graph
    { property: 'og:site_name', content: sitename },
    { property: 'og:locale', content: `${locale}_${country?.toUpperCase()}` },
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: targetUrl },

    // Mobile App Bookmarks
    { name: 'application-name', content: sitename },
    // Favicons
    ...faviconMetas,
  ]

  const facebookAppID = config.facebook.appId

  if (facebookAppID) {
    baseMetas.push({ property: 'fb:app_id', content: facebookAppID.toString() })
  }

  return baseMetas
}

export const getJDPDescriptionContent = (
  data: Object,
  localize: Function
): string => {
  const ids = {
    location: get(data, 'location.address'),
    title: get(data, 'title'),
    working_hours: get(data, 'working_hours_type'),
    company_name: data.company ? findCompanyName(data.company) : '',
  }

  return truncate(localize('job_details_page_meta_description', ids), {
    length: 160,
  })
}

export const getJDPKeywordContent = (
  data: Object,
  localize: Function
): string => {
  const type = get(data, 'type.localized_name', '')
  const workingHours = get(data, 'working_hours_type', '')
  const location = get(data, 'location.address', '')

  return join(filter([type, workingHours, location], (i) => !!i), ', ')
}

const metas = (
  routeName: PrefixedRouteName,
  intl: $FlowTODO,
  messages: $FlowTODO,
  include: Include,
  data: Object
): $FlowTODO[] => {
  const name = snakeCase(unPrefixRouteName(routeName))

  log.info(`retrieving metas for ${name}`, { route: { name } })

  if (name) {
    const isJDP = name === 'job'
    const localize = (id, ids = {}) => intl.formatMessage({ id }, { ...ids })
    const descriptionKey = `site_description_${name}`
    const keywordsKey = `site_keywords_${name}`
    const descriptionMessage = isJDP
      ? getJDPDescriptionContent(data, localize)
      : messages[descriptionKey]
    const keywordMessage = isJDP
      ? getJDPKeywordContent(data, localize)
      : messages[keywordsKey]
    const tags = []

    if (include.description && descriptionMessage) {
      tags.push({ name: 'description', content: descriptionMessage })
    }
    if (include.keywords && keywordMessage) {
      tags.push({ name: 'keywords', content: keywordMessage })
    }
    return tags
  }
  return []
}

const title = (routeName: PrefixedRouteName, intl: IntlShape, messages: $FlowTODO): string => {
  const name = snakeCase(unPrefixRouteName(routeName))
  if (name) {
    const localize = (id) => intl.formatMessage({ id })
    const titleKey = `site_title_${name}`
    if (messages[titleKey]) {
      return localize(titleKey)
    }
  }
  return ''
}

export const routeTitle = ({
  route,
  intl,
  messages,
}: RouteTitle = {}): string => {
  return route && intl && messages ? title(route.name, intl, messages) : ''
}

export const routeMetas = ({
  route,
  intl,
  messages,
  include = { description: true, keywords: true },
  data = {},
}: RouteMetas): Array<Object> => {
  return route && intl && messages
    ? metas(route.name, intl, messages, include, data)
    : []
}
