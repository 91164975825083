import React from 'react'

const useScript = (id: string, src: string) => {
  const [isLoaded, setIsLoaded] = React.useState(false)
  const [error, setError] = React.useState(false)

  const loadScript = React.useCallback(() => {
    let $script = document.getElementById(id) as HTMLScriptElement | null

    const loaded = () => {
      setIsLoaded(true)
      setError(false)
    }

    const error = () => {
      setIsLoaded(false)
      setError(true)
    }

    if ($script) return loaded()

    $script = document.createElement('script')

    $script.id = id
    $script.src = src
    $script.onload = loaded
    $script.onerror = error

    document.head?.appendChild($script)

    return $script
  }, [id, src])

  React.useEffect(() => {
    loadScript()
  }, [loadScript])

  return {
    isLoaded,
    error,
    loadScript
  }
}

export default useScript
