import { Close16 } from '@carbon/icons-react'
import clsx from 'clsx'
import * as React from 'react'
import styled from '@emotion/styled'
import { COLORS, globalStyles } from '../theme'
import { BannerProps } from './HBanner.types'
import { HBox } from '../HBox'
import { HIcon } from '../HIcon'
import { HTypography } from '../HTypography'
import { HButton } from '../HButton'

const COLOR_MAP: any = {
  default: {
    background: COLORS.primary_00,
    text: 'primary_50',
  },
  neutral: {
    background: COLORS.surface_gray_00,
    text: 'surface_gray_80',
  },
  success: {
    background: COLORS.support_success_00,
    text: 'support_success_50',
  },
  error: {
    background: COLORS.support_error_00,
    text: 'support_error_50',
  },
  warning: {
    background: COLORS.support_warning_00,
    text: 'support_warning_50',
  },
  help: {
    background: COLORS.support_help_00,
    text: 'support_help_50',
  },
}

const Banner = React.forwardRef((props: BannerProps, ref: any) => {
  const {
    className,
    icon,
    color = 'default',
    action,
    actionProps,
    text,
    onCloseClick,
    children,
    ...others
  } = props

  return (
    <div className={clsx(className, color)} {...others} ref={ref}>
      <HBox pl={6} pr={6} py={action || children ? 3 : 4}>
        <HBox display='flex' alignItems='center' justifyContent='space-between'>
          {!children && (
            <HBox display='flex' alignItems='center'>
              {icon && (
                <HBox mr={2}>
                  <HIcon
                    Icon={icon}
                    color={COLOR_MAP[color].text}
                    size={24}
                  ></HIcon>
                </HBox>
              )}
              <HTypography
                variant='body1'
                weight='regular'
                color={COLOR_MAP[color].text}
              >
                {text}
              </HTypography>
              {action && (
                <HBox ml={4}>
                  <HButton variant='text' color='primary' {...actionProps}>
                    {action}
                  </HButton>
                </HBox>
              )}
            </HBox>
          )}
          {children && (
            <HBox display='flex' alignItems='center'>
              {children}
            </HBox>
          )}

          <HBox ml={2}>
            <HIcon
              onClick={onCloseClick}
              className='btn-close'
              Icon={Close16}
              size={24}
              color={COLOR_MAP[color].text}
            ></HIcon>
          </HBox>
        </HBox>
      </HBox>
    </div>
  )
})

Banner.displayName = 'Banner'

export const HBanner = styled(Banner)`
  background-color: ${({ color = 'default' }) => COLOR_MAP[color].background};
  box-shadow: ${globalStyles.shadow3.boxShadow};
  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding-right: 108px;
    padding-left: 108px;
  }
  .btn-close {
    cursor: pointer;
  }
`
