import React from 'react'
import { HInput, IHTextFieldProps } from './HInput'
import { ViewFilled16, View16 } from '@carbon/icons-react'
import { HIcon } from '../HIcon'

type HInputPasswordProps = IHTextFieldProps & {
  viewPassword?: boolean
}

export const HInputPassword = React.forwardRef(
  (props: HInputPasswordProps, ref: any) => {
    const { viewPassword = false, ...others } = props
    const [showPassword, setShowPassword] = React.useState(viewPassword)

    return (
      <HInput
        {...others}
        ref={ref}
        type={showPassword ? 'text' : 'password'}
        action={
          <HIcon
            Icon={showPassword ? ViewFilled16 : View16}
            color="primary_50"
            onClick={() => setShowPassword((prev) => !prev)}
          />
        }
      />
    )
  })

HInputPassword.displayName = 'HInputPassword'
