import * as React from 'react'
import { CircularProgress, Typography, Button, ButtonGroup } from '@mui/material'
import { Close16, DocumentAttachment16 } from '@carbon/icons-react'
import { classes, FileContainer } from './File.styles'
import clsx from 'clsx'
import { HTypography } from '../HTypography'

export enum FileVariants {
  Active = 'active',
  Plain = 'plain',
}
export interface FileProps {
  file: string
  variant?: FileVariants
  onClick?: () => void
  onRemoveClick?: () => void
  loading?: boolean
  fileExtension?: string
  disabled?: boolean
  error?: boolean
  filePath?: string
}

export const HFile: React.FC<FileProps> = ({
  onRemoveClick,
  file,
  variant,
  disabled,
  loading,
  error,
  filePath,
}) => {
  const className = clsx(
    classes.root,
    variant === FileVariants.Active && classes.active,
    variant === FileVariants.Plain && classes.plain,
    error && classes.error,
  )

  const classNameLeftButton = clsx(
    className,
    variant === FileVariants.Active && classes.shadowClipRight,
  )
  const classNameRightButton = clsx(
    className,
    variant === FileVariants.Active && classes.shadowClipLeft,
  )
  const [fileName, fileExtension] = file.split('.')

  return (
    <FileContainer>
      <ButtonGroup
        className={classes.buttonGroup}
        disableFocusRipple
        data-test-id='h-file'
      >
        <Button
          startIcon={loading
            ? <CircularProgress size={16} data-test-id='h-file-progress-icon' />
            : <DocumentAttachment16 data-test-id='h-file-document-icon' />
          }
          variant="outlined"
          disabled={disabled}
          className={classNameLeftButton}
          href={filePath}
        >
          <HTypography
            variant='body2'
            data-test-id='h-file-name'
            truncated
          >
            {fileName}{fileExtension ? `.${fileExtension}` : ''}
          </HTypography>
        </Button>
        { variant === FileVariants.Active && (
          <Button
            size='small'
            variant="outlined"
            className={classNameRightButton}
            disabled={disabled}
            onClick={onRemoveClick}
          >
            <Close16 />
          </Button>
        )}
      </ButtonGroup>
    </FileContainer>
  )
}
