// @flow
import some from 'lodash/some'
import includes from 'lodash/includes'

type FileError = {
  key: string,
  values: Object
}

type ServerError = {
  code: string,
  key: string
}

type FileErrors = {
  errors: FileError[] | ServerError[]
}

const clientErrors = ['error_file_type', 'error_file_size']
const conversionErrors = ['document_upload_conversion_failure', 'document_upload_conversion_timeout']

export const catchFileError = (error: FileErrors) => {
  const errors = [...clientErrors, ...conversionErrors]

  if (error?.errors) {
    const hasErrors = (error) => includes(errors, error.key) || (error.code && includes(errors, error.code))

    if (some(error.errors, hasErrors)) {
      return
    }
  }
  throw error
}
