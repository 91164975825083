import { useState, useEffect } from 'react'
import * as GrowthBook from '@growthbook/growthbook-react'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../types/applicationState'

const useGrowthbookWithSetup = (requireUserInitialization: boolean) => {
  const growthbook = GrowthBook.useGrowthBook()
  const isUserInitialized = useSelector((state: ApplicationState) => state.user.initialized)

  // We don't want to call the Growthbook tracking callback on the server or without the user
  const isInitializing = requireUserInitialization && !isUserInitialized

  return { isInitializing, growthbook }
}

export const useFeature = <FeatureValue extends GrowthBook.JSONValue = any>(featureName: string, requireUserInitialization: boolean = true) => {
  const { isInitializing, growthbook } = useGrowthbookWithSetup(requireUserInitialization)

  const [feature, setFeature] = useState<GrowthBook.FeatureResult<FeatureValue | null> | null>(null)

  useEffect(() => {
    if (!isInitializing && growthbook) {
      setFeature(growthbook.evalFeature<FeatureValue>(featureName))
    }
  }, [isInitializing, growthbook])

  return feature
}

export const useGrowthBook = (requireUserInitialization: boolean = true) => {
  const { isInitializing, growthbook } = useGrowthbookWithSetup(requireUserInitialization)

  return isInitializing ? null : growthbook
}
