import React, { ElementType, MouseEventHandler, ReactElement, Ref } from 'react'
import clsx from 'clsx'
import Chip, { ChipProps } from '@mui/material/Chip'
import { HTypography } from '../HTypography'
import { IType, IWeight } from '../HTypography/index.types'
import { Close16, Close20 } from '@carbon/icons-react'

export type IChipVariant = NonNullable<ChipProps['variant']>

export type IChipColor = 'accent_pink' | 'gray' | 'blue' | 'success' | 'warning' | 'error' | 'help' | 'selected'

type IChipSize = 'small' | 'large'

type disableProps = 'variant'

export interface IHChipProps {
  variant: IChipVariant
  placeholder: string
  color: IChipColor
  icon?: ReactElement
  onClick?: MouseEventHandler<HTMLDivElement>
  onDelete?: (event: React.MouseEvent) => void
  disabled?: boolean
  className?: string
  size?: IChipSize
  component?: ElementType
  to?: string
}
interface ICHipLabelProps extends
  Omit<React.HTMLProps<HTMLDivElement>, disableProps> {
  textWeight?: IWeight
  textVariant: IType
  children: React.ReactNode
}

const ChipLabel: React.FC<ICHipLabelProps> = ({
  textVariant,
  textWeight,
  ...props
}) => {
  return (
    <HTypography
      component='span'
      weight={textWeight}
      align='center'
      variant={textVariant}
      color='inherit'
    >
      {props.children}
    </HTypography>
  )
}

export const ChipComponent = React.forwardRef(
  (
    {
      variant,
      placeholder,
      color,
      icon,
      onClick,
      onDelete,
      disabled,
      className,
      size = 'small',
      ...others
    }: IHChipProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    const classNames = clsx(color, variant, className, size)
    const textVariant = size === 'small' ? 'body2' : 'body1'
    const closeIcon = size === 'large' ? <Close20 /> : <Close16 />

    return (
      <Chip
        className={classNames}
        ref={ref}
        variant={variant}
        label={<ChipLabel textVariant={textVariant}>{placeholder}</ChipLabel>}
        icon={icon}
        onDelete={onDelete}
        onClick={onClick}
        deleteIcon={
          /**
          * extracting this to a separate component will cause the icon
          * to lose the click event. this is caused due to the automatic
          * binding for onDelete which breaks if we pass a functional component
          * that returns a ReactNode rather than a ReactNode directly
          */
          <div
            className='chip_delete_icon'
          >
            {closeIcon}
          </div>
        }
        onMouseDown={(e: React.MouseEvent) => e.preventDefault()}
        disabled={disabled}
        {...others}
      />
    )
  },
)

ChipComponent.displayName = 'ChipComponent'
