import React, { useState } from 'react'
import { useOnboarding, useOnboardingFlow } from '../common/use-onboarding-flow'
import StepContainer from '../common/step-container'
import { LocationData } from '../types/api-types'
import { OnboardingStep } from '../types/flow'
import { LocationPreferences } from 'user-profile-data'

export type LocationStepProps = {
  value?: LocationData[]
}

const OnboardingLocation: OnboardingStep<LocationStepProps> = ({ value }) => {
  const { locale, country, routeName, autoSuggestionLocationClick, removeTrackingData, hookConfig } = useOnboarding()
  const { saveStepData, setStepState, goNext, onStepClose, stepsProgress, goBack } = useOnboardingFlow()

  const [selectedLocations, setSelectedLocations] = useState<LocationData[]>(value || [])

  const onContinue = () => {
    setStepState('location', { value: selectedLocations })

    saveStepData({
      step_name: 'location',
      step_action: 'filled',
      step_value: selectedLocations
    })

    goNext()
  }

  const onSkip = () => {
    setStepState('location', { value: selectedLocations })

    saveStepData({
      step_name: 'location',
      step_action: 'skipped'
    })

    goNext()
  }

  const onGoBack = () => {
    setStepState('location', { value: selectedLocations })

    goBack()
  }

  return (
    <StepContainer
      title='onboarding_flow_location_step_title'
      progress={stepsProgress}
      validToContinue={selectedLocations.length > 0}
      onClose={() => onStepClose('location')}
      onSkip={onSkip}
      onContinue={onContinue}
      onBack={onGoBack}
    >
      <LocationPreferences
        routeName={routeName}
        autoSuggestionLocationClick={autoSuggestionLocationClick}
        removeTrackingData={removeTrackingData}
        locale={locale}
        country={country}
        selectedLocations={selectedLocations}
        setSelectedLocations={setSelectedLocations}
        variant='onboarding'
        hookConfig={hookConfig}
      />
    </StepContainer>
  )
}

export default OnboardingLocation
