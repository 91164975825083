/* istanbul ignore file */
import { ArrowLeft16, ArrowRight16 } from '@carbon/icons-react'
import React, { MouseEvent, useEffect } from 'react'
import Slider, { Settings, SwipeDirection } from 'react-slick'
import { HRoundIconButton } from '../HButton'
import { COLORS, hStyled } from '../theme'
import { SliderWrapper } from './Carousel.styles'
import { css } from '@emotion/react'
import { Theme, useMediaQuery } from '@mui/material'

const navigationBottomBase = css`
  top: inherit;
  bottom: 0;
  transform: translateY(calc(100% + 16px));
`
const navigationTopBase = css`
  top: 0;
  bottom: inherit;
  transform: translateY(calc(-100% - 16px));
`

const navigationBottom = css`
  &.slick-prev {
    ${navigationBottomBase}
    left: 2px;
  }
  &.slick-next {
    ${navigationBottomBase}
    right: 2px;
  }
`
const navigationTop = css`
  &.slick-prev {
    ${navigationTopBase}
    left: 2px;
  }
  &.slick-next {
    ${navigationTopBase}
    right: 2px;
  }
`

const ArrowButton = hStyled(HRoundIconButton, {
  blackListedProps: ['navButtonsPosition'],
})<Partial<ArrowWithClickProps>>(
  ({ navButtonsPosition }) => css`
    border: 1px solid ${COLORS.primary_50};
    background-color: ${COLORS.surface_white};

    ${navButtonsPosition === 'bottom' && navigationBottom}
    ${navButtonsPosition === 'top' && navigationTop}

    &.slick-disabled {
      cursor: unset;
      border: 1px solid ${COLORS.primary_50}66;

      .MuiButton-label {
        color: ${COLORS.primary_50}66;
      }

      svg {
          color: ${COLORS.primary_50}66;
      }

      :hover {
        background-color: ${COLORS.surface_white};
      }
    }
  `,
)

export interface HCarouselProps extends Settings {
  navButtonsPosition?: 'top' | 'bottom' | 'center'
  prevArrowLabel?: string
  nextArrowLabel?: string
  goToSlide?: number // index of the slide to go to
  onSlide?: (swipeDirection: SwipeDirection) => void
  focusOnMount?: boolean
}

interface ArrowWithClickProps {
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void
  customClickHandler?: (swipeDirection: SwipeDirection) => void
  direction: 'left' | 'right'
  disabled?: boolean
  currentSlide?: number
  slideCount?: number
  navButtonsPosition?: 'top' | 'bottom' | 'center'
}

const ArrowWithClick: React.FC<ArrowWithClickProps> = ({
  onClick,
  direction,
  customClickHandler,
  currentSlide,
  slideCount,
  disabled,
  navButtonsPosition,
  ...other
}) => {
  // https://react-slick.neostack.com/docs/example/custom-arrows
  const onCustomClick = (e: MouseEvent<HTMLButtonElement>): void => {
    customClickHandler?.(direction)
    onClick?.(e)
  }

  const ArrowIcon = direction === 'left' ? ArrowLeft16 : ArrowRight16

  return (
    <ArrowButton
      elevated
      color="primary"
      {...other}
      disabled={disabled}
      onClick={onCustomClick}
    >
      <ArrowIcon />
    </ArrowButton>
  )
}

export const HCarousel: React.FC<HCarouselProps> = ({
  infinite = true,
  speed = 400,
  slidesToScroll = 1,
  slidesToShow = 1,
  prevArrow,
  nextArrow,
  variableWidth = false,
  navButtonsPosition = 'center',
  children,
  prevArrowLabel,
  nextArrowLabel,
  goToSlide,
  onSlide,
  arrows,
  focusOnMount,
  ...others
}) => {
  const sliderRef = React.useRef<Slider>(null)

  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))

  const showArrows = arrows !== undefined ? arrows : isDesktop

  useEffect(() => {
    if (sliderRef.current) {
      if (goToSlide !== undefined) {
        sliderRef.current.slickGoTo(goToSlide)
      }

      if (focusOnMount) {
        const track = sliderRef.current.innerSlider?.list?.querySelector(
          '.slick-track',
        )

        const slide = track?.querySelector('.slick-slide') as HTMLElement

        slide?.focus()
      }
    }
  }, [goToSlide])

  return (
    <SliderWrapper {...others}>
      <Slider
        ref={sliderRef}
        infinite={infinite}
        speed={speed}
        slidesToScroll={slidesToScroll}
        slidesToShow={slidesToShow}
        onSwipe={onSlide}
        arrows={showArrows}
        nextArrow={nextArrow || (
          <ArrowWithClick
            customClickHandler={onSlide}
            direction='right'
            aria-label={nextArrowLabel}
            navButtonsPosition={navButtonsPosition}
          />
        )}
        prevArrow={prevArrow || (
          <ArrowWithClick
            customClickHandler={onSlide}
            direction='left'
            aria-label={prevArrowLabel}
            navButtonsPosition={navButtonsPosition}
          />
        )}
        variableWidth={variableWidth}
        {...others}
      >
        {children}
      </Slider>
    </SliderWrapper>
  )
}
