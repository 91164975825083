import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { IHAutocompleteProps } from '../HAutocomplete/HAutocomplete.types'
import { AutocompleteListBox } from '../HAutocomplete/Listbox'
import { HAutocomplete } from '../HAutocomplete/HAutocomplete'
import Popper from '@mui/material/Popper'

const PopperComponent = styled(Popper)`
  .MuiPaper-root {
    box-shadow: none;
    background: none;
  }

  .MuiAutocomplete-noOptions {
    display: none;
  }
`

const InvisibleAutocompleteList = styled(AutocompleteListBox)`
  background-color: transparent;
  max-height: fit-content !important;
  box-shadow: none;
  padding: 12px 0px;
  li {
    padding: 12px 0px;
  }
`

export const ModalAutoCompleteInputComponent = <
  T,
  Multiple extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  autoCompleteInputRef,
  hideAutoCompleteModal,
  modalTestId,
  ...props
}:
IHAutocompleteProps<T, Multiple, true, FreeSolo> &
{
  autoCompleteInputRef: React.MutableRefObject<HTMLInputElement | null>
  hideAutoCompleteModal: () => void
  modalTestId: string
}): JSX.Element => {
  useEffect(() => {
    autoCompleteInputRef.current?.focus()
  }, [autoCompleteInputRef.current])

  const [selected, setSelected] = React.useState()

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault()
    event.stopPropagation()

    hideAutoCompleteModal()
  }

  React.useEffect(() => {
    if (selected) {
      hideAutoCompleteModal()
    }
  }, [selected])

  return (
    <form
      action=''
      onSubmit={handleSubmit}
    >
      <HAutocomplete
        {...props}
        open
        onChange={(event, value) => {
          props.onChange?.(event, value, 'selectOption')
          setSelected(value)
        }}
        InputProps={{
          ...props.InputProps,
          inputRef: autoCompleteInputRef,
          focused: true,
        }}
        HInputProps={{
          ...props.HInputProps,
          dataTestId: modalTestId + '-input',
        }}
        ListboxComponent={InvisibleAutocompleteList}
        PopperComponent={PopperComponent}
        disablePortal
      />
    </form>

  )
}
