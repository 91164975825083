import { useEffect } from 'react'
import * as Sentry from '@sentry/browser'
import { useAppSelector } from '../hooks/useAppSelector'
import config from '../../config'
import { USER_CENTRIC_SERVICES } from '../cookieConsent/userCentricServices'
import { configureDataDogLogger } from '../../logging/client'
import { configureDatadogRum } from '../../../../jobseeker-portal-next/src/services/monitoring/datadog-rum'
import { configureSentry } from '../../monitoring/sentry'

export const ServiceLoaderWithConsent = () => {
  const hasDatadogConsent = useAppSelector(
    (state) =>
      state.cookieConsent.services[
        USER_CENTRIC_SERVICES.DATADOG_REAL_USER_MONITORING
      ],
  )

  const hasSentryConsent = useAppSelector(
    (state) =>
      state.cookieConsent.services[
        USER_CENTRIC_SERVICES.SENTRY
      ],
  )

  const isBot = useAppSelector(
    (state) =>
      state.request.isBot,
  )

  // Datadog and DataDog RUM
  useEffect(() => {
    if (!hasDatadogConsent) {
      return
    }

    configureDataDogLogger()

    if (!isBot) {
      configureDatadogRum(config)
    }
  }, [hasDatadogConsent, isBot])

  // Sentry
  useEffect(() => {
    if (!hasSentryConsent) {
      return
    }

    configureSentry(
      Sentry,
      'client',
      'https://31a4b7af30a146b2a4db0298e77f73a0@sentry.io/176895',
    )
  }, [hasSentryConsent])

  return null
}
