// @flow

import type { Cookies } from 'react-cookie'

import type { TrackingSchema } from './types'
import type { ApplicationState } from '../../types/applicationState'

type Options = {
  auth_origin?: $FlowTODO,
  email_pw: bool,
  facebook_sso: bool,
  google_sso: bool,
}

export const AUTH_STRATEGY_CONTEXT: 'auth_strategy_context' = 'auth_strategy_context'

// eslint-disable-next-line flowtype/require-types-at-top
export type AuthStrategyType = {
  key: typeof AUTH_STRATEGY_CONTEXT,
  options?: Options
}

export const SCHEMA_NAME = 'iglu:de.heyjobs/auth_strategy_context/jsonschema/1-0-2'

export default (state: ?ApplicationState, cookies: Cookies, options?: Options): Array<TrackingSchema> => {
  return [{
    schema: SCHEMA_NAME,
    data: options,
  }]
}
