// @flow
import type { Job } from '../../types/job'

const buildJobContextTrackingEventData = (job: ?Job) => job
  ? ({
      job_uid: job.id,
      job_job_type: job.type?.codename,
      job_type_id: job.type?.id,
      job_working_hours_type: job.working_hours_type,
      job_is_easy_apply: job.allow_easy_apply,
      job_client_status: job.company.client_status,
      job_link_out_type: job.link_out_type,
      job_price_category: job.price_category,
      job_product_type: job.product_type,
      url_hash: job.url_hash,
    })
  : {}

export default buildJobContextTrackingEventData
