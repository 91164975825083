import * as React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { HNavbar } from 'talent-ui'
import { type HNavBarProps } from 'talent-ui/src/HNavBar/HNavBar.types'
import { useAppSelector } from '../../../hooks/useAppSelector'

import * as actionCreators from '../../../actions/actionCreators'
import { usePathR } from '../../../components/containers/pathr'
import getClientRelativeURI from '../../../helpers/getClientRelativeURI'
import wrap from '../../../tools/imageproxy'
import { LOGIN_MODAL_SOURCE } from '../../../tracking/eventSources'
import { handleNavLinkClick, LinkComponent, getLogoLink, renderTitleText } from './Links'

import { push } from 'connected-react-router'
import { matchPath } from 'react-router-dom'
import * as userActions from '../../../actions/user'
import { RequestState } from '../../../reducers/request'
import { routeForTargetLocale, routeNames } from '../../../routes'
import { unPrefixRouteName } from '../../../routing/routeNamePrefix'
import { RouteLocation } from '../../../types/common'

import { moreJobsLink } from '../../../helpers/url/moreJobsLink'
import { CompleteYourProfileIndicator } from '../../../pages/Profile/components/CompleteYourProfileIndicator'

interface NavbarProps {
  location: RouteLocation
  isJDP?: boolean
}

const urlFor = (request: RequestState, suffix: string): string => (
  request.origin ? `${request.origin}${suffix}` : suffix
)

const isLinkActive = (location: RouteLocation, navLinkPath: string) => {
  if (!navLinkPath) { return false }

  return location.pathname === navLinkPath
}

export const Navbar = ({ location, isJDP }: NavbarProps) => {
  const intl = useIntl()
  const translate = (id: string, values?: any) => intl.formatMessage({ id }, values)
  const company = useAppSelector(state => state.company.current)
  const user = useAppSelector(state => state.user.user)
  const job = useAppSelector(state => state.currentJob)
  const routing = useAppSelector(state => state.routing)
  const abTesting = useAppSelector(state => state.abTesting)
  const locale = useAppSelector(state => state.intlData.locale)
  const country = useAppSelector(state => state.locality.country)
  const request = useAppSelector(state => state.request)
  const bookmarkAnimation = useAppSelector(state => state.bookmarks.animationTimer)

  const dispatch = useDispatch()
  const cpathr = usePathR()

  const companyName = job?.job?.company?.name
  const companyShortName = job?.job?.company?.short_name

  const props = {
    job: job?.job,
    abTesting,
    user,
    company,
    companyName,
    companyShortName,
    locale,
    country,
    routing,
    routeData: {
      location,
    },
    intl,
    cpathr,
  }

  const isSearchPage = matchPath(location.pathname, { path: cpathr.jobSearch() })
  const routeName = isSearchPage ? routeNames.jobSearchKeyword : routeNames.home
  const oppositeLocale = locale === 'en' ? 'de' : 'en'

  const targetRoute = routeForTargetLocale(oppositeLocale,
    undefined,
    unPrefixRouteName(routeName),
    location,
    locale,
    country
  ) || cpathr.index

  const isUserAuthenticated = useAppSelector(state => state.user.isAuthenticated)

  const logoLink = isUserAuthenticated ? cpathr.dashboard : cpathr.index

  const jdpLogoLink = getLogoLink(props)

  const jdpProps = isJDP && job?.job
    ? {
        company: {
          logo: wrap.imageProps(job?.job?.picture_url, {
            size: 56,
            fit: true,
          }),
          name: renderTitleText(props),
          LinkProps: {
            href: jdpLogoLink,
          },
        },
        applyWithLabel: translate('apply_directly_with'),
      }
    : undefined

  const careerPage = company
    ? {
        logoUrl: company.logo_url,
        companyName: company.name,
      }
    : undefined

  const navbarProps: HNavBarProps = {
    jdp: jdpProps,
    careerPage: careerPage,
    Link: LinkComponent,
    logoLink: logoLink,
    logoClickAction: (event) => {
      event.preventDefault()
      event.stopPropagation()

      dispatch(push(logoLink))
    },
    isBookmarkAnimationActive: !!bookmarkAnimation,
    loggedOutMenu: {
      searchButton: (isJDP && job?.job)
        ? {
            text: translate('see_similar_jobs_link'),
            component: LinkComponent,
            componentProps: {
              href: moreJobsLink({ currentJob: job.job, locale, country }),
            },
            clickAction: () => handleNavLinkClick('see_similar_jobs_link'),
          }
        : undefined,
      jobseekerLogin: {
        text: translate('main_menu_login_signup_link'),
        clickAction: () => {
          handleNavLinkClick('main_menu_login_signup_link')
          dispatch(actionCreators.showLoginModal({
            redirect: getClientRelativeURI(),
            activeTab: 0,
            source: LOGIN_MODAL_SOURCE,
          }))
        },
      },
      recruiterLogin: {
        href: `https://www.heyjobs.co/recruiting/${locale === 'en' ? 'en/' : ''}`,
        label: translate('switch_to_employer_link'),
        onClick: () => handleNavLinkClick('switch_to_employer_link'),
      },
      languageChange: {
        MobileMenuLabel: translate(`Button_ChangeLang_${oppositeLocale}`),
        Component: LinkComponent,
        componentProps: { href: targetRoute },
        DesktopMenuLabel: oppositeLocale.toUpperCase(),
      },
    },
    loggedInMenu: {
      menuItems: {
        profile: {
          text: translate('main_menu_profile_link'),
          active: isLinkActive(location, cpathr.profile),
          component: LinkComponent,
          clickAction: () => handleNavLinkClick('main_menu_profile_link'),
          componentProps: {
            href: company ? urlFor(request, cpathr.profile) : undefined,
            to: company ? undefined : cpathr.profile,
          },
        },
        jobSearch: {
          text: translate('find_a_job_link'),
          active: isLinkActive(location, logoLink),
          component: LinkComponent,
          clickAction: () => handleNavLinkClick('find_a_job_link'),
          componentProps: {
            href: company ? urlFor(request, logoLink) : undefined,
            to: company ? undefined : logoLink,
          },
        },
        applications: {
          text: translate('main_menu_applications_link'),
          component: LinkComponent,
          active: isLinkActive(location, cpathr.applications),
          clickAction: () => handleNavLinkClick('main_menu_applications_link'),
          componentProps: {
            href: company ? urlFor(request, cpathr.applications) : cpathr.applications,
          },
        },
        savedJobs: {
          text: translate('main_menu_saved_jobs_link'),
          component: LinkComponent,
          active: isLinkActive(location, cpathr.bookmarkedJobs),
          clickAction: () => handleNavLinkClick('main_menu_saved_jobs_link'),
          componentProps: {
            href: company ? urlFor(request, cpathr.bookmarkedJobs) : cpathr.bookmarkedJobs,
          },
        },
      },
      userProfile: user
        ? {
            IndicatorComp: CompleteYourProfileIndicator,
            user: {
              firstName: user?.first_name,
              lastName: user?.last_name,
              email: user?.email,
            },
            menu: {
              loggedInText: translate('main_menu_section_login_logged_in_as', { email: '' }),
              profile: {
                text: translate('main_menu_profile_link'),
                clickAction: () => handleNavLinkClick('main_menu_profile_link'),
                component: LinkComponent,
                componentProps: {
                  to: company ? undefined : cpathr.profile,
                  href: company ? urlFor(request, cpathr.profile) : undefined,
                },
              },
            },
            logout: {
              label: translate('main_menu_logout'),
              clickAction: async () => {
                handleNavLinkClick('main_menu_logout')
                await dispatch(userActions.logout())
                dispatch(push(cpathr.index))
              },
            },
          }
        : undefined,
    },
  }

  return (
    <HNavbar
      {...navbarProps}
    />
  )
}
