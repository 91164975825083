// @flow

import * as React from 'react'

import { HBox, HTypography, HIcon } from 'talent-ui'
import { WarningAlt16 } from '@carbon/icons-react'

type StyledErrorMessageProps = {|
    dataTestId?: string,
    errorMessage: string
|}

export default ({ errorMessage, dataTestId }: StyledErrorMessageProps) => (
  <HBox
    data-test-id={dataTestId}
    display='flex'
    alignItems='center'
    mt={4}
    mb={8}
  >
    <HBox mr={1}>
      <HIcon
        Icon={WarningAlt16}
        color='support_error_50'
      />
    </HBox>
    <HTypography
      variant='body2'
      color='support_error_50'
    >
      {errorMessage}
    </HTypography>
  </HBox>
)
