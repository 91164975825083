import { ArrowRight16, TrashCan16 } from '@carbon/icons-react'
import React from 'react'
import { HBox } from '../HBox'
import { HButton } from '../HButton'
import { HJobCardProps } from './HJobCard.types'
import { HChip } from '../HChip'

export const UnavailableJobActions: React.FC<HJobCardProps> = ({
  jobNoLongerActiveText,
  size,
  requisitionId,
  removeFromSavedText,
  searchForSimilarText,
  onRemoveFromSavedClick,
  searchForSimilarProps,
}) => {
  const isSmall = size === 'small'

  return (
    <HBox
      display="flex" flexDirection={isSmall ? 'column' : 'row'}
      justifyContent={isSmall ? 'flex-start' : 'flex-end'}
      alignItems={isSmall ? 'flex-start' : 'center'}
      gap={8}
      data-test-id={`unavailable-job-${requisitionId}`}
      mt={4}
    >
      {isSmall && jobNoLongerActiveText && (
        <HBox py={2}>
          <HChip
            color='warning'
            placeholder={jobNoLongerActiveText}
            data-test-id='unavailable-job-title'
            variant='filled'
            size="small"
          />
        </HBox>)}
      <HButton
        variant='text' color="primary"
        data-test-id='unavailable-job-un-bookmark'
        onClick={onRemoveFromSavedClick}
        endIcon={<TrashCan16 />}
      >
        {removeFromSavedText}
      </HButton>
      <HButton
        variant='text'
        color="primary"
        data-test-id='unavailable-job-show-similar-jobs'
        endIcon={<ArrowRight16 />}
        {...searchForSimilarProps}
      >
        {searchForSimilarText}
      </HButton>
    </HBox>
  )
}
