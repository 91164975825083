// @flow

import { routeNames } from '../../routes'

import typeof GetPageTypeType from '../events/pageview/getPageType'
import type { RouteName } from '../../routes'

const getPageType = (routeName: RouteName): ?string => {
  if (typeof window === 'undefined') return

  return (window.__heyjobs_tc.getPageType: GetPageTypeType)(routeName, routeNames)
}

export default getPageType
