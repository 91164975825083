// @flow

import cookies from '../../cookies'

import type { ApplicationState } from '../../types/applicationState'
import type { Cookies } from 'react-cookie'
import type { TrackingSchema } from './types'
import logger from '../../../logging'
import getPageType from '../events/pageview/getPageType'

export const SCHEMA_NAME = 'iglu:de.heyjobs/user_context/jsonschema/2-0-2'

const log = logger('user_context')

const getTrackingToken = (state: ApplicationState, cookieJar: Cookies): ?string => {
  return state.session.device?.tracking_token || // eslint-disable-line camelcase
    cookies.load(cookieJar)(cookies.identifiers.TRACKING_TOKEN)
}

const getCRMStatus = (consentValue: ?bool) => consentValue ? 'consented' : 'not_consented'

export default (state: ApplicationState, cookieJar: Cookies): Array<TrackingSchema> => {
  const deviceToken = getTrackingToken(state, cookieJar)
  if (!deviceToken) {
    log.warn('No device token found for user context', {
      datadogContext: {
        isAuthenticated: state.user.isAuthenticated,
        page_type: getPageType(
          state.pageLoadRouteData.routeName,
          state.pageLoadRouteData.routeNames
        ),
        originAdress: state.request.originAddress,
      },
    })
  }

  return [{
    schema: SCHEMA_NAME,
    data: {
      user_device_token: getTrackingToken(state, cookieJar),
      // FIXME user model in the backend no longer has this attribute as of https://github.com/heyjobs/jobz_backend/pull/8474
      // use the user model existing as a proxy for the user being a jobseeker
      user_type: state.user.isAuthenticated ? 'jobseeker' : null,
      user_gender: state.user.user?.gender || null,
      is_logged_in: !!state.user.isAuthenticated,
      crm_email_status: getCRMStatus(state.user.user?.consented_job_recommendations), // eslint-disable-line camelcase
      crm_whatsapp_status: getCRMStatus(state.user.user?.consented_job_recommendations_whatsapp), // eslint-disable-line camelcase
      profile_sharing_status: getCRMStatus(state.user.user?.consented_profile_sharing), // eslint-disable-line camelcase
    },
  }]
}
