// @flow

import { useSelector } from 'react-redux'

import type { ApplicationState } from '../../../types/applicationState'
import { useIsMounted } from '../../../hooks/useIsMounted'

export const MAX_NO_JOB_ALERT_SEARCHES = 5

function useSaveJobSearchBar () {
  const isMounted = useIsMounted()
  const selectedProps = useSelector((state: ApplicationState) => {
    const { jobSearch, jobAlertSearchQuery } = state
    const searchOptions = jobSearch?.searchOptions
    const location = searchOptions?.location
    const keyword = searchOptions?.keyword
    const isLoading = !!jobAlertSearchQuery?.loading
    const searchQueries = jobAlertSearchQuery?.searchQueries

    const hasSavedQuery = searchQueries?.find((item) => {
      const savedKeyword = item?.query?.toLocaleLowerCase() || ''
      const searchedKeyword = keyword?.toLocaleLowerCase() || ''
      const savedLocation = item?.location?.toLocaleLowerCase() || ''
      const searchedLocation = location?.toLocaleLowerCase() || ''

      return (
        savedKeyword + savedLocation === searchedKeyword + searchedLocation
      )
    })
    const isVisible = !!(keyword || location) && jobSearch?.totalPages > 0
    const shouldFetchJobAlerts = !searchQueries
    const shouldDisableSaveButton =
      hasSavedQuery ||
      isLoading ||
      (searchQueries && searchQueries.length >= MAX_NO_JOB_ALERT_SEARCHES)

    return {
      isLoading,
      shouldDisableSaveButton,
      isVisible,
      searchOptions,
      location,
      keyword,
      shouldFetchJobAlerts,

    }
  })

  return {
    ...selectedProps,
    isMounted,
  }
}

export default useSaveJobSearchBar
