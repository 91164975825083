// @flow

import * as actions from '../constants/actions'
import { FULFILLED, REJECTED, PENDING } from './promiseUtil'
import type { GCTSJob } from '../types/gctsJob'

export type RecommendedJobsState = {|
  error: bool,
  jobs: GCTSJob[],
  loading: bool,
  numJobs: number,
|}

const initialState = {
  jobs: [],
  numJobs: 0,
  loading: false,
  error: false,
}

const reducer = (state: RecommendedJobsState = initialState, action: any): RecommendedJobsState => {
  switch (action.type) {
    case PENDING(actions.FETCH_RECOMMENDED_JOBS):
      return {
        ...state,
        loading: true,
      }
    case FULFILLED(actions.FETCH_RECOMMENDED_JOBS): {
      const jobs = action.payload

      return {
        jobs,
        numJobs: (jobs || []).length,
        loading: false,
        error: false,
      }
    }
    case REJECTED(actions.FETCH_RECOMMENDED_JOBS):
      return {
        ...initialState,
        error: true,
      }
    case FULFILLED(actions.LOGOUT):
      return {
        ...initialState,
      }
    default:
      return state
  }
}

export default reducer
