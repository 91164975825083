export const globalStyles = {
  shadow1: {
    boxShadow:
      '0px 2px 2px rgba(76, 82, 107, 0.02), 0px 2px 2px 2px rgba(60, 105, 190, 0.02), 0px -2px 2px rgba(76, 82, 107, 0.02) !important',
  },
  shadow2: {
    boxShadow:
      '0px 2px 4px rgba(76, 82, 107, 0.06), 0px 2px 4px rgba(60, 105, 190, 0.04), 0px -2px 4px rgba(76, 82, 107, 0.04) !important',
  },
  shadow3: {
    boxShadow:
      '0px 4px 6px rgba(76, 82, 107, 0.08), 0px 2px 2px rgba(60, 105, 190, 0.06), 0px -2px 4px rgba(76, 82, 107, 0.04) !important',
  },
  shadow4: {
    boxShadow:
      '0px 6px 6px rgba(76, 82, 107, 0.08), 0px 4px 6px rgba(60, 105, 190, 0.06), 0px -2px 4px rgba(76, 82, 107, 0.06) !important',
  },
  shadow4Inverted: {
    boxShadow:
      '0px -6px 6px rgba(76, 82, 107, 0.08), 0px -4px 6px rgba(60, 105, 190, 0.06), 0px 2px 4px rgba(76, 82, 107, 0.06)',
  },
  shadow5: {
    boxShadow:
      '0px 8px 10px rgba(76, 82, 107, 0.12), 0px 4px 8px rgba(60, 105, 190, 0.08), 0px -4px 6px rgba(76, 82, 107, 0.1) !important',
  },
  noShadow: {
    boxShadow: 'none !important',
  },
}
