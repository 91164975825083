import { MinimumSalaryPreferences } from 'user-profile-data'
import React from 'react'
import { useForm } from 'react-hook-form'
import StepContainer from '../common/step-container'
import { useOnboardingFlow } from '../common/use-onboarding-flow'
import { Salary } from '../types/api-types'
import { OnboardingStep } from '../types/flow'

export type SalaryStepProps = {
  value?: Salary
}

const OnboardingSalary: OnboardingStep<SalaryStepProps> = ({ value }) => {
  const {
    onStepClose,
    goBack,
    goNext,
    saveStepData,
    setStepState,
    stepsProgress
  } = useOnboardingFlow()
  const form = useForm({
    defaultValues: {
      salary_period: value?.salary_period || 'hour',
      salary_value: value?.salary_value || ''
    }
  })
  const {
    getValues,
    formState: { errors },
    watch
  } = form

  const onContinue = () => {
    setStepState('minimum_salary', {
      value: {
        salary_period: getValues('salary_period'),
        salary_value: getValues('salary_value')
      }
    })
    saveStepData({
      step_name: 'minimum_salary',
      step_action: 'filled',
      step_value: {
        salary_period: getValues('salary_period'),
        salary_value: getValues('salary_value')
      }
    })

    goNext()
  }

  const onSkip = () => {
    setStepState('minimum_salary', {
      value: {
        salary_period: getValues('salary_period'),
        salary_value: getValues('salary_value')
      }
    })

    saveStepData({
      step_name: 'minimum_salary',
      step_action: 'skipped'
    })

    goNext()
  }

  const onBack = () => {
    setStepState('minimum_salary', {
      value: {
        salary_period: getValues('salary_period'),
        salary_value: getValues('salary_value')
      }
    })

    goBack()
  }

  const validToContinue =
    !errors.salary_value && watch('salary_value').length > 0

  return (
    <StepContainer
      title='onboarding_flow_salary_step_title'
      validToContinue={validToContinue}
      progress={stepsProgress}
      onClose={() => onStepClose('minimum_salary')}
      onBack={onBack}
      onSkip={onSkip}
      onContinue={onContinue}
    >
      <MinimumSalaryPreferences
        form={form}
        currentValues={{
          salary_period: getValues('salary_period'),
          salary_value: getValues('salary_value')
        }}
        variant='onboarding'
      />
    </StepContainer>
  )
}

export default OnboardingSalary
