import { combineEpics } from 'redux-observable'
import searchAutoCompleteEpic from './searchAutoCompleteEpic'
import searchEpic from './searchEpic'

const rootEpic = combineEpics(
  searchAutoCompleteEpic,
  searchEpic
)

export default rootEpic
