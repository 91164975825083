import React from 'react'
import TermsAndConditionsDe from './TermsAndConditionsDe'
import { ContentContainer } from './styles'

const TermsAndConditionsPage: React.FC = () => (
  <ContentContainer
    p={6}
    mx='auto'
  >
    <TermsAndConditionsDe />
  </ContentContainer>
)

export default TermsAndConditionsPage
