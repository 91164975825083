import React, { ReactElement } from 'react'
import styled from '@emotion/styled'
import { ChevronDown16 } from '@carbon/icons-react'
import { HSelect } from '../HSelect'

import { HTypography } from '../HTypography'
import { HMenuItem } from '../HMenu'
import { COLORS } from '../theme'
import { HLocationRadiusSelectProps, RadiusOption } from './types'

const HRadiusAutocomplete = styled(HSelect)`
  .MuiBackdrop-root {
    display: none;
  }

  min-width: 80px;
  padding: 0px 0px 0px 8px !important;

  .MuiInput-input {
    margin-top: 2px;
  }

  .MuiInput-input p {
    font-weight: 400 !important;
  }

  .MuiSelect-icon {
    right: 0px;
  }

  .MuiMenu-paper {
    margin-top: 10px;
  }
`

const RADIUS_OPTIONS = [2, 5, 10, 15, 20, 25, 50]

export const DEFAULT_RADIUS: RadiusOption = {
  value: '25',
  label: '25 km',
}

export const HLocationRadiusSelect = (
  {
    radiusNoneLabel,
    value: selectedValue = DEFAULT_RADIUS.value,
    onChange,
    ...props
  }: HLocationRadiusSelectProps,
): ReactElement => {
  const radiusOptions: RadiusOption[] = [
    {
      value: radiusNoneLabel.toLowerCase(),
      label: radiusNoneLabel,
    },
    ...RADIUS_OPTIONS.map((radius) => ({
      value: String(radius),
      label: `${radius} km`,
    })),
  ]

  return (
    <HRadiusAutocomplete
      {...props}
      data-test-id="location-radius-selector"
      disableUnderline={true}
      IconComponent={ChevronDown16}
      onChange={(event) => onChange?.(String(event.target.value))}
      onClick={(e) => e.stopPropagation()}
      value={selectedValue}
      variant="standard"
      MenuProps={{
        style: { marginTop: '10px' },
        disablePortal: false,
      }}
    >
      {radiusOptions?.map(
        ({ value: optionValue, label }: RadiusOption, index: number) => (
          <HMenuItem key={index} value={optionValue}>
            <HTypography
              style={{
                fontWeight: selectedValue === optionValue ? 'bold' : '400',
                color: COLORS.surface_gray_80,
              }}
            >
              {label}
            </HTypography>
          </HMenuItem>
        ))}
    </HRadiusAutocomplete>
  )
}
