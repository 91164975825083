import React, { PropsWithChildren } from 'react'
import { CheckmarkFilled16, ChevronDown16 } from '@carbon/icons-react'
import styled from '@emotion/styled'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import { withStyles } from '@mui/styles'

import { HTypography } from '../HTypography'
import { COLORS, globalStyles } from '../theme'
import { HRoundIconButton } from '../HButton'
import { HTypographyProps } from '../HTypography/index.types'

interface HSummaryProps {
  sectionName: string
}

type IHSummaryProps = AccordionSummaryProps & HSummaryProps

// https://mui.com/material-ui/api/accordion/#css
export const HAccordion = withStyles((theme) => {
  const largeVariant = theme.breakpoints.up('md')

  return {
    root: {
      outline: `1px solid ${COLORS.primary_15}`,
      backgroundColor: COLORS.primary_00,
      boxShadow: globalStyles.shadow1.boxShadow,
      maxWidth: '664px',
      marginBottom: '8px',
      [largeVariant]: {
        marginBottom: '16px',
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: '0px',
        marginBottom: '8px',
        [largeVariant]: {
          marginBottom: '16px',
        },
      },
      borderRadius: '4px',
    },
    expanded: {
      outline: `1px solid ${COLORS.primary_30}`,
      backgroundColor: COLORS.primary_00,
      boxShadow: globalStyles.shadow2.boxShadow,
    },
  }
})(MuiAccordion)

// https://mui.com/material-ui/api/accordion-summary/#css
const StyledAccordionSummary = withStyles((theme) => {
  const largeVariant = theme.breakpoints.up('md')

  return {
    root: {
      padding: '0 8px 0 16px',
      minHeight: '48px',
      '&$expanded': {
        minHeight: '48px',
        [largeVariant]: {
          minHeight: '64px',
        },
      },
      [largeVariant]: {
        minHeight: '64px',
        padding: '0 16px',
      },
    },
    content: {
      margin: 0,
      color: COLORS.primary_50,
      padding: '8px 24px 8px 0',
      '&$expanded': {
        margin: 0,
        color: COLORS.primary_70,
        fontWeight: 500,
      },
      [largeVariant]: {
        padding: '16px 40px 16px 0',
      },
    },
    expanded: {
      margin: 0,
    },
    expandIcon: {
      padding: 0,
      margin: 0,
    },
  }
})(MuiAccordionSummary)

const SummaryIcon = styled(CheckmarkFilled16)`
  color: currentColor;
  margin-right: 8px;
  width: 16px;
  height: 16px;
  align-self: baseline;
  flex-shrink: 0;
  position: relative;
  top: 4px;
`

const ExpandIcon = styled(ChevronDown16)`
  color: currentColor;
`

const ExpandButton: React.FC = () => {
  return (
    <HRoundIconButton
      color='primary'
    >
      <ExpandIcon />
    </HRoundIconButton>
  )
}

export const HAccordionSummary: React.FC<IHSummaryProps> = ({
  sectionName,
  children,
  ...others
}) => {
  return (
    <StyledAccordionSummary
      aria-controls={`panel-${sectionName}-content`}
      expandIcon={<ExpandButton />}
      {...others}
    >
      <SummaryIcon />
      <HTypography
        xs={{
          variant: 'body1',
          color: 'inherit',
          weight: 'inherit',
        }}
        md={{
          variant: 'h6',
        }}
      >
        {children}
      </HTypography>
    </StyledAccordionSummary>
  )
}

// https://mui.com/material-ui/api/accordion-details/#css
export const HAccordionDetails = withStyles((theme) => {
  const largeVariant = theme.breakpoints.up('md')

  return {
    root: {
      padding: '0 8px 24px 16px',
      [largeVariant]: {
        paddingRight: '16px',
        paddingTop: 0,
      },
    },
  }
})(MuiAccordionDetails)

export const HAccordionTypography: React.FC<
PropsWithChildren<HTypographyProps>> = ({
  italic = true,
  children,
  ...props
}) => {
  return (
    <HTypography
      italic={italic}
      sm={{
        color: 'surface_gray_90',
        variant: 'body2',
        ...props,
      }}
      md={{
        variant: 'body1',
      }}
    >
      {children}
    </HTypography>
  )
}
