// @flow

import * as actions from '../constants/actions'

export type CookieConsentState = {|
  isExplicit: bool,
  services: { [key: string]: bool }
|}

export type CookieConsentServiceType = { consent: bool, name: string }

type CookieConsentActionType = typeof actions.SET_COOKIE_CONSENT_SERVICE
type UserExplicitContentActionType = typeof actions.USER_EXPLICIT_CONSENT

type CookieConsentAction = {
  service: CookieConsentServiceType,
  type: CookieConsentActionType
} | {
  isExplicit: bool,
  type: UserExplicitContentActionType
}

const initialState = {
  isExplicit: false,
  services: {},
}

const cookieConsentState = (
  state: CookieConsentState = initialState,
  action: CookieConsentAction
): CookieConsentState => {
  switch (action.type) {
    case actions.SET_COOKIE_CONSENT_SERVICE:
      return {
        ...state,
        services: {
          ...state.services,
          [action.service.name]: action.service.consent,
        },
      }
    case actions.USER_EXPLICIT_CONSENT:
      return {
        ...state,
        isExplicit: true,
      }

    default:
      return state
  }
}

export default cookieConsentState
