// @flow

import routeNamePageNameMap from './routeNamePageNameMap'
import includes from 'lodash/includes'

import type { RouteNameMap } from '../../../routes'

// NOTE: This should be removed once we prefix all
// routeNames with `tp_`
const prefixableRouteNames = [
  'homepage',
]

const getPageType = (routeName: string, routeNames: ?RouteNameMap) => {
  // NOTE: handles newly added pages that don't require name mapping
  if (includes(prefixableRouteNames, routeName)) {
    return `tp_${routeName}`
  }

  if (routeNames) {
    return routeNamePageNameMap(routeName, routeNames)
  } else {
    return routeName
  }
}

export default getPageType
