// @flow

import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CookieStorage,
  ICognitoUserData,
} from 'amazon-cognito-identity-js'
import { type Cookies } from 'react-cookie'
import moment from 'moment'
import { resolveUser, handlePostSignupActions, handlePostSigninActions, logout } from '..'
import config from '../../../../config'
import logger from '../../../../logging'
import * as actions from '../../../constants/actions'
import { postMessageToBroadcastChannel } from '../../../helpers/channel/broadcast-channel.ts'
import { getIdToken, getExpiryDateFromToken, refreshSession, setUserSessionWithTokens, limitCognitoCookiesToOneSession } from '../../../helpers/cognitoHelper.ts'
import { buildUrlFragment } from '../../../helpers/url/query'
import { getSnowplowDomainUid, fireSnowplowEvent } from '../../../tracking/external/trackingClient'
import * as utils from '../../utils/reduxUtils'
import { generatePassword } from './passwordUtils'
import { USER_LOGGED_IN_EVENT } from '../../../tracking/eventNames'
import { getPostSignupDataFromSessionStorage } from '../../../helpers/postSignupUtils.ts'
import { AUTH_PAGE_SOURCE } from '../../../tracking/eventSources'
import type { ApplicationState } from '../../../types/applicationState'
import {
  showSnackBar,
  showTermsAndConditionsModal,
  userIsNotPendingAnUpdate,
  userIsPendingAnUpdate,
} from '../../actionCreators'
import { runningInBrowser } from '../../actions/support/base'
import cookies from '../../../cookies'

export type SocialAuthProvider = 'Google' | 'Facebook'
export type SocialAuthStrategy = 'google_one_tap' | 'google_login' | 'facebook_login' | 'google_signup' | 'facebook_signup'
interface ForgotPasswordParams {
  clientMetaData: {
    authOrigin?: string,
    redirectUrl?: string,
    resetType?: 'mig_initial' | 'initial',
    [string]: string
  },
  email: string
}
interface ConfirmForgotPasswordParams {
  email: string,
  newPassword: string,
  verificationCode: string
}

const log = logger('user')

const storage = new CookieStorage({
  domain: cookies.globalize(runningInBrowser ? window?.location?.hostname : undefined),
})

export const localStorageUserPool = new CognitoUserPool({
  UserPoolId: config.cognito.userPoolId,
  ClientId: config.cognito.userPoolWebClientId,
})

export const userPool = new CognitoUserPool({
  UserPoolId: config.cognito.userPoolId,
  ClientId: config.cognito.userPoolWebClientId,
  Storage: storage,
})

export function buildCognitoUserData (data: { Username: string }): ICognitoUserData {
  return {
    ...data,
    Pool: userPool,
    Storage: storage,
  }
}

const thirtySeconds = 30 * 1000

export const getFreshCognitoIdToken = async () => {
  if (!runningInBrowser) {
    return null
  }

  try {
    const idToken = await getIdToken()

    if (!idToken) {
      return null
    }

    const expiry = moment(getExpiryDateFromToken(idToken))

    const thirtySecondsFromNow = moment(Date.now() + thirtySeconds)

    const isTokenFresh = expiry.isAfter(thirtySecondsFromNow)

    if (isTokenFresh) {
      return idToken
    }

    const session = await refreshSession()

    return session.getIdToken().getJwtToken()
  } catch (err) {
    if (err?.code || !err) { // Cognito errors have the code property, so we know it was the Cognito call that failed. We also reject with null if there is no user or no refresh token.
      await window.__store.dispatch(cognitoLogout())
      await window.__store.dispatch(logout())
    }
  }
}

const cognitoSignUpPromise = (userData, attributeList) =>
  new Promise((resolve, reject) => {
    userPool.signUp(userData.email, userData.password, attributeList, null, (
      err, result
    ) => {
      if (err) reject(err)
      resolve(result.user)
    })
  })

const cognitoGetTokenPromise = (cognitoUser, authenticationDetails, cookieJar: Cookies) =>
  new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        const idToken = result.getIdToken().getJwtToken()
        const refreshToken = result.getRefreshToken().getToken()

        limitCognitoCookiesToOneSession(cookieJar)

        resolve({ idToken, refreshToken })
      },
      onFailure: (e) => {
        reject(e)
      },
    })
  })

const cognitoSocialLoginUrl = (
  identityProvider: SocialAuthProvider,
  authStrategy: SocialAuthStrategy,
  redirectPath: string,
  cognitoCallBackUrl: string,
  googleOneTapEmail?: string
) => {
  const codeVerifier = generatePassword()
  const clientId = config.cognito.userPoolWebClientId
  const scope = 'openid+profile'
  const cognitoAuthUrl = `${config.cognito.customDomain}/oauth2/authorize`

  const encodedAuthState = btoa(JSON.stringify({
    redirect_url: redirectPath,
    code_verifier: codeVerifier,
    auth_strategy: authStrategy,
    google_one_tap_email: googleOneTapEmail,
  }))

  const urlParams = {
    identity_provider: identityProvider,
    // redirect_uri should be predefined at user pool settings
    redirect_uri: cognitoCallBackUrl,
    response_type: 'code',
    client_id: clientId,
    state: encodedAuthState,
    code_verifier: codeVerifier,
  }

  return `${cognitoAuthUrl}${buildUrlFragment(urlParams)}&scope=${scope}`
}

export const cognitoLogout = utils.createPromise(
  actions.COGNITO_LOGOUT, () => async (requestConfig, dispatch, getState) => {
    const currentUser = userPool.getCurrentUser()
    if (!currentUser) return

    try {
      currentUser.signOut()
    } catch (error) {
      const state = getState()
      log.error(`failed logging out cognito user ${state.user.user?.id || 'null'}`, {
        user: { id: state.user.user?.id },
        error,
      })
    }
  })

export const cognitoInitSocialLogin = utils.createPromise(
  actions.COGNITO_INIT_SOCIAL_LOGIN,
  (provider: SocialAuthProvider, strategy: SocialAuthStrategy, googleOneTapEmail?: string, redirectUrl: string) => async (requestConfig, dispatch, getState) => {
    const locale = getState().intlData.locale
    const country = getState().locality.country
    const cognitoCallBackUrl = `${window.location.origin}/${locale}-${country}/auth`

    const urlParams = new URLSearchParams(window.location.search)
    const redirectPath = redirectUrl ?? urlParams.get('redirect_path') ?? window.location.pathname + window.location.search

    /* istanbul ignore next */
    window.location.href = cognitoSocialLoginUrl(provider, strategy, redirectPath, cognitoCallBackUrl, googleOneTapEmail)
  }
)

export const cognitoSignUp = utils.createPromise(
  actions.COGNITO_SIGN_UP,
  (userData) => async (requestConfig, dispatch, getState, [cookieJar]) => {
    const locale = getState().intlData.locale
    const password = userData.password || generatePassword()

    const attributeList = ['email', 'phone_number', ['given_name', 'first_name'], ['family_name', 'last_name']].map((item) => {
      let field

      if (Array.isArray(item)) {
        field = {
          Name: item[0],
          Value: userData[item[1]],
        }
      } else {
        field = {
          Name: item,
          Value: userData[item],
        }
      }

      return new CognitoUserAttribute(field)
    })

    attributeList.push(new CognitoUserAttribute({ Name: 'locale', Value: locale }))
    const authenticationData = { Username: userData.email, Password: password }
    const authenticationDetails = new AuthenticationDetails(authenticationData)
    const cognitoUser = await cognitoSignUpPromise({ ...userData, password }, attributeList)
    await cognitoGetTokenPromise(cognitoUser, authenticationDetails, cookieJar)

    await dispatch(userIsPendingAnUpdate())
    // fetch user record from the backend
    const { value: user } = await dispatch(resolveUser())

    try {
      await dispatch(handlePostSignupActions(user, '', Boolean(userData.password)))
    } catch (error) {
      log.error(`Error ${error.message}`, { error: JSON.stringify(error) })
    }

    await dispatch(userIsNotPendingAnUpdate())
    postMessageToBroadcastChannel({ type: actions.COGNITO_SIGN_UP, payload: user })
    return user
  }
)

export const cognitoSignIn = utils.createPromise(
  actions.COGNITO_SIGN_IN, ({ email, password, authOrigin, source }) =>
    async (requestConfig, dispatch, getState, [cookieJar]) => {
      const userData = buildCognitoUserData({ Username: email })

      const cognitoUser = new CognitoUser(userData)
      const authDetails = new AuthenticationDetails({
        Username: email,
        Password: password,
      })
      await cognitoGetTokenPromise(cognitoUser, authDetails, cookieJar)

      try {
        await dispatch(userIsPendingAnUpdate())

        const { value: user } = await dispatch(resolveUser())

        try {
          await dispatch(handlePostSigninActions(user, true))
        } catch (error) {
          log.error(`Error ${error.message}`, { error: JSON.stringify(error) })
        }

        postMessageToBroadcastChannel({ type: actions.COGNITO_SIGN_IN, payload: user })

        triggerUserLoggedInEvent(user.id, getState(), authOrigin, source)

        await user?.consented_terms_and_conditions
          ? dispatch(showSnackBar('login'))
          : dispatch(showTermsAndConditionsModal())

        return user
      } finally {
        await dispatch(userIsNotPendingAnUpdate())
      }
    })

const triggerUserLoggedInEvent = (userId: string, state: ApplicationState, authOrigin?: string, source?: string) => {
  const location = state.routing.route.name

  if (!source) {
    source = state.loginModal.source
  }

  const isAuthPage = state.routing.route.routePathFromRoutesMap === 'auth'

  if (!authOrigin) {
    const { tracking } = (getPostSignupDataFromSessionStorage() || {})

    authOrigin = tracking?.authLocation
  }

  fireSnowplowEvent(
    USER_LOGGED_IN_EVENT,
    {
      source: source || isAuthPage ? AUTH_PAGE_SOURCE : undefined,
      authentication_method: 'email_pw',
      location,
    },
    [
      {
        key: 'auth_strategy_context',
        options: {
          google_sso: false,
          facebook_sso: false,
          email_pw: true,
          auth_origin: authOrigin || source || location,
        },
      },
    ]
  )
}

export const cognitoForgotPassword = utils.createPromise(
  actions.COGNITO_FORGOT_PASSWORD,
  ({ email, clientMetaData = {} }: ForgotPasswordParams) => (requestConfig, dispatch, getState) => {
    const userData = buildCognitoUserData({ Username: email })

    const state = getState()
    const locale = state.intlData.locale
    const subdomain = state.company.current?.subdomain
    const snowPlowDomainUserId = getSnowplowDomainUid()
    const { tracking } = (getPostSignupDataFromSessionStorage() || {})
    const urlParams = new URLSearchParams(window.location.search)
    const redirectPath = clientMetaData.redirectUrl ?? encodeURIComponent(urlParams.get('redirect_path') ?? window.location.pathname + window.location.search)

    const metaData = { ...clientMetaData, redirectUrl: redirectPath, locale, snowPlowDomainUserId, subdomain, authOrigin: clientMetaData.authOrigin ?? tracking?.authLocation }

    const cognitoUser = new CognitoUser(userData)

    return new Promise((resolve, reject) => {
      cognitoUser.forgotPassword({
        onSuccess: (data) => resolve(data),
        onFailure: (err) => reject(err),
      }, metaData)
    })
  }
)

export const cognitoConfirmForgotPassword = utils.createPromise(
  actions.COGNITO_CONFIRM_FORGOT_PASSWORD,
  ({ email, verificationCode, newPassword }: ConfirmForgotPasswordParams) => (requestConfig, dispatch, getState) => {
    const userData = buildCognitoUserData({ Username: email })

    const cognitoUser = new CognitoUser(userData)

    return new Promise((resolve, reject) => {
      cognitoUser.confirmPassword(verificationCode, newPassword, {
        onSuccess: (data) => resolve(data),
        onFailure: (err) => reject(err),
      })
    })
  }
)

// this function serves the OAuth flow, which gives us a set of token based on
//   an authorization code, which we then need to use to resolve the user
//   and create the cognito user session
export const authenticateCognitoUserByTokens = utils.createPromise(
  actions.COGNITO_AUTHENTICATE_BY_TOKENS,
  (tokens) => async (requestConfig, dispatch) => {
    setUserSessionWithTokens(tokens)

    const { value: user } = await dispatch(resolveUser())

    return user
  }
)

export const signOutFromAllDevices = utils.createPromise(actions.COGNITO_SIGN_OUT_FROM_ALL_DEVICES,
  () => async (requestConfig, dispatch) => {
    const cognitoUser = userPool.getCurrentUser()

    if (!cognitoUser) return

    return new Promise((resolve, reject) => {
      cognitoUser.getSession((err, session) => {
        if (err) {
          reject(err)
        } else {
          // invalidate tokens
          cognitoUser.globalSignOut({
            onSuccess: (msg: string) => {
              dispatch(logout())
              resolve(msg)
            },
            onFailure: (err: Error) => {
              reject(err)
            },
          })
        }
      })
    })
  })

export const changePassword = utils.createPromise(
  actions.COGNITO_CHANGE_PASSWORD,
  ({ oldPassword, newPassword }) => () => {
    const cognitoUser = userPool.getCurrentUser()
    return new Promise((resolve, reject) => {
      cognitoUser.getSession((error, session) => {
        if (error) {
          reject(error)
        } else {
          cognitoUser.changePassword(oldPassword, newPassword, (err, result) => {
            if (err) {
              reject(err)
            } else {
              return resolve(result)
            }
          })
        }
      })
    })
  })

if (typeof window !== 'undefined' && window.Cypress) {
  window.__userActions = {
    ...window.__userActions,
    cognitoSignUp,
  }
}
